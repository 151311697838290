import styled from 'styled-components';

const AuthActionsStyles = styled.div`
  > button:not(:last-child) {
    margin-bottom: 20px;
  }
  .ant-btn {
    width: 100%;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    .anticon {
      margin-right: 5px;
      font-size: 28px;
    }
  }
`;

export default AuthActionsStyles;
