import { useRef } from 'react';
import { Form, AutoComplete } from 'antd';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';
import { splitAddressFromGoogleMap } from 'utils/dataUtils';
import { PLACE_DEFAULT_OPTIONS } from 'configs/constants';
import { FormGoogleAutoCompleteStyles } from './styles';

const FormGoogleAutocomplete = ({
  label,
  required,
  messageRequire,
  placeholder,
  wrapperCol,
  defaultValue,
  name,
  rules,
  onSelectAddress,
  requestOptions,
  ...props
}) => {
  const { t } = useTranslation();

  const {
    suggestions: { status, data },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: { ...PLACE_DEFAULT_OPTIONS, ...requestOptions },
  });

  const handleSearch = (e) => {
    setValue(e);
  };
  const handleSearchDebounce = useRef(debounce(handleSearch, 600), []);

  const handleSelect = async (e) => {
    try {
      const results = await getGeocode({ address: e });

      onSelectAddress({
        address: results?.[0]?.formatted_address || e,
        addressObj: splitAddressFromGoogleMap(results[0].address_components),
      });
    } catch (error) {
      throw new Error(error);
    }
  };

  const renderSuggestions = data.map((suggestion) => ({
    value: suggestion.description,
  }));

  return (
    <FormGoogleAutoCompleteStyles>
      <Form.Item
        wrapperCol={wrapperCol}
        label={t(label)}
        name={name}
        initialValue={defaultValue}
        rules={[
          {
            required,
            message: t(messageRequire),
          },
          ...rules,
        ]}
      >
        <AutoComplete
          options={status === 'OK' ? renderSuggestions : []}
          onSearch={handleSearchDebounce.current}
          onSelect={handleSelect}
          placeholder={t(placeholder)}
          {...props}
        />
      </Form.Item>
    </FormGoogleAutoCompleteStyles>
  );
};

FormGoogleAutocomplete.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  messageRequire: PropTypes.string,
  placeholder: PropTypes.string,
  wrapperCol: PropTypes.object,
  defaultValue: PropTypes.string,
  rules: PropTypes.array,
  onSelectAddress: PropTypes.func,
  requestOptions: PropTypes.object,
};

FormGoogleAutocomplete.defaultProps = {
  messageRequire: 'input.address.validateMsg.required',
  onSelectAddress: () => null,
  requestOptions: {},
  required: false,
  rules: [],
  wrapperCol: { span: 24 },
};

export default FormGoogleAutocomplete;
