import { createDraftSafeSelector } from '@reduxjs/toolkit';

const getRawRehydratedPersistSubsidy = (state) =>
  state.configSubsidy?._persist?.rehydrated;

const getRawServiceTypesSubsidy = (state) =>
  state.configSubsidy.data.serviceTypes;

export const getServiceTypesSubsidy = createDraftSafeSelector(
  [getRawServiceTypesSubsidy],
  (data) => data,
);

export const getRehydratedPersistSubsidy = createDraftSafeSelector(
  [getRawRehydratedPersistSubsidy],
  (data) => data,
);
