import { createSlice } from '@reduxjs/toolkit';
import { getConfigSubsidy } from './actions';

export const initialState = {
  error: null,
  data: {},
};

const { reducer } = createSlice({
  name: 'configSubsidy',
  initialState,
  reducers: {},
  extraReducers: {
    [getConfigSubsidy.fulfilled]: (state, { payload }) => {
      state.data = payload;
    },
  },
});

export default reducer;
