import { baseApi } from '@redux/@rtkQuery/baseApi';

export const centresSuggestionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllCentresSuggestion: builder.query({
      query: (params) => ({
        url: `/api/v1/rich-web/centres/suggestion`,
        params,
      }),
    }),
  }),
});

export const { useGetAllCentresSuggestionQuery } = centresSuggestionApi;

export const { getAllCentresSuggestion } = centresSuggestionApi.endpoints;
