import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const jobsFeaturedApi = rtkCreator(
  'jobsFeatured',
  'job-seekers/screens/posts/featured',
  richWebConfigVer2,
);

export const { useGetAllJobsFeaturedQuery } = jobsFeaturedApi;

export const { getAllJobsFeatured } = jobsFeaturedApi.endpoints;
