import NextLink from 'next/link';
import { ReactNode } from 'react';
import { UrlObject } from 'url';

interface Props {
  externalUrl?: string;
  target?: string;
  children: ReactNode;
  className?: string;
  href: string | UrlObject;
  prefetch?: boolean;
}

const Link = ({
  externalUrl,
  target,
  children,
  className,
  href,
  ...props
}: Props) =>
  externalUrl ? (
    <a target={target} href={externalUrl} className={className} {...props}>
      {children}
    </a>
  ) : (
    <NextLink href={href} {...props}>
      <a className={className}>{children}</a>
    </NextLink>
  );

export default Link;
