import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getJobPostsApi, getJobPostsFilterApi } from 'api/jobPosts';

export const MODEL_NAME = 'jobPosts';

export const getJobPosts = createAsyncThunk(
  `${MODEL_NAME}/getJobPosts`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getJobPostsApi, payload?.data);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getJobPostsFilter = createAsyncThunk(
  `${MODEL_NAME}/getJobPostsFilter`,
  async (payload, thunkAPI) => {
    const location = thunkAPI.getState().config.currentLocation?.geoLocation;

    try {
      const response = await apiWrapper({}, getJobPostsFilterApi, {
        location,
        ...payload,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
