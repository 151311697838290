import { rtkCreator } from '@redux/crudCreator/baseRTK';

export const parentActivityLevelApi = rtkCreator(
  'parentActivityLevel',
  'parentActivityLevel',
);

export const { useGetAllParentActivityLevelQuery } = parentActivityLevelApi;

export const { getAllParentActivityLevel } = parentActivityLevelApi.endpoints;
