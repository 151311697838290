/* eslint-disable guard-for-in */
import dayjs from 'utils/dayUtils';
import {
  debounce,
  dropRight,
  get,
  isEmpty,
  omit,
  reduce,
  round,
  template,
  values,
  pickBy,
} from 'lodash-es';
import { SEO_VAR } from 'configs/seo';
import { i18n } from 'next-i18next';
import slugify from 'slugify';
import { IGNORE_QUERY_PARAMS, PREFIX_RW_DEVICE_ID } from 'configs/constants';
import { nanoid } from '@reduxjs/toolkit';
import {
  BUSINESS_LEVEL,
  EMPTY_SLUG_CHARACTER,
} from 'components/forJobSeekers/constants';
import { MoonIcon, SunIcon } from '@uikit/commons/SVGIcons';
import { AUSTRALIA_COUNTRY } from 'configs/localData';
import { OpUnitType } from 'dayjs';
import { SEO_SUBURB } from 'configs/seo/suburb';

interface ImageUrl {
  src?: string;
  maxWidth?: number;
  maxHeight?: number;
  defaultSrc?: string;
  isPublic?: boolean;
  host?: string;
}

interface CentreItem {
  country?: string;
  state?: string;
  postCode?: string;
  slug?: string;
  id?: string;
  city?: string;
}

interface HandleRedirectInvalidPaging {
  resolvedUrl?: string;
  res?: any;
}

interface SunMoonTime {
  sunColor?: string;
  moonColor?: string;
}

interface ExtraLinkJobs {
  extra?: string;
  extraQuery?: object;
}

export const slugifyUrl = (str) =>
  slugify(str || '', { lower: true, strict: true });

export const slugifySuburb = (suburb) => slugify(suburb || '', { lower: true });

export const upperFirstChar = (str) =>
  str?.[0]?.toUpperCase() + str?.substring(1);

export const capitalize = (str) =>
  str
    ?.split(' ')
    ?.map((s) => {
      const firstLetter = s.split('')[0]?.toUpperCase();
      const restOfStr = s.substr(1, s.length)?.toLowerCase();
      return firstLetter + restOfStr;
    })
    .join(' ');

export const convertSuburbSlugToName = (str) =>
  str
    ?.split('-')
    ?.map((s) => {
      const firstLetter = s.split('')[0].toUpperCase();
      const restOfStr = s.substr(1, s.length).toLowerCase();
      return firstLetter + restOfStr;
    })
    .join(' ');

export const getImageUrl = ({
  src,
  maxWidth,
  maxHeight,
  defaultSrc = '/images/default-centre-cover.png',
  isPublic,
  host = '',
}: ImageUrl) => {
  if (isPublic || src?.indexOf('http') > -1) return src;
  if (src)
    return `${process.env.NEXT_PUBLIC_PHOTO_WITH_RESIZE}/${
      maxWidth ? `${maxWidth || 'AUTO'}x${maxHeight || 'AUTO'}/` : ''
    }${src}`;
  return `${host}${defaultSrc}`;
};

export const getImageShare = (thumbnail) =>
  thumbnail ? getImageUrl({ src: thumbnail, maxWidth: 1400 }) : SEO_VAR.image;

export const trimCommaString = (text) =>
  // eslint-disable-next-line
  text ? text.replace(/(^\,+|\,+$)/gm, '') : null;

const formatRoundNumber = (num, isAlwayShowDecimal) => {
  if (!num) return '0';

  if (Number.isNaN(Number(num))) return '0';

  const roundNum = isAlwayShowDecimal
    ? Number(num).toFixed(2)
    : round(Number(num), 2);

  return roundNum;
};

export const formatMoney = (
  num: number,
  isAlwayShowDecimal?: boolean,
  digits = 3,
) => {
  const roundNum = formatRoundNumber(num, isAlwayShowDecimal);

  const re = `\\B(?=(\\d{${digits}})+(?!\\d))`;

  return String(roundNum).replace(new RegExp(re, 'g'), ',');
};

export const formatMoneyWithCurrency = (
  num,
  currency = '$',
  isAlwayShowDecimal,
  digits = 3,
) => {
  const roundNum = String(formatRoundNumber(num, isAlwayShowDecimal));

  const re = `\\B(?=(\\d{${digits}})+(?!\\d))`;

  if (currency !== 'AUD' && currency !== '$' && currency !== null) {
    return `${roundNum.replace(new RegExp(re, 'g'), `$&,`)} ${currency}`;
  }

  return `$${roundNum.replace(new RegExp(re, 'g'), `$&,`)}`;
};

export const formatMoneySymbol = (num, digits = 3) => {
  if (!num) return '0';

  const si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'B' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (Math.abs(Number(num)) >= si[i].value) {
      break;
    }
  }
  const resNum =
    (Math.abs(Number(num)) / si[i].value).toFixed(digits).replace(rx, '$1') +
    si[i].symbol;
  return num < 0 ? `-${resNum}` : resNum;
};

export const formatCostPerDay = (num) => {
  const cost = formatMoney(num, true);
  return Number(cost) ? cost : num;
};

export const formatDateFromNow = (date) => {
  if (dayjs().isAfter(date)) {
    const diffDays = dayjs().diff(dayjs(date), 'days');
    if (diffDays < 1) {
      const hourString = dayjs(date).fromNow();
      if (hourString === 'a day ago') {
        return `${dayjs().diff(dayjs(date), 'hours')} hours ago`;
      }
      return dayjs(date).fromNow();
    }
    const dateFormatted = formatDateTimeMessage(date);
    const timeFormatted = formatTimeOpening(date);
    return `${dateFormatted} at ${timeFormatted}`;
  }
  return 'a few seconds ago';
};

export const formatDateTimeMessage = (date) =>
  date ? dayjs(date).format('DD MMM, YYYY') : null;

export const formatTimeOpening = (time) =>
  time ? dayjs(time).format('HH:mm') : '';

export const formatDateTimeEvent = (time) =>
  time ? dayjs(time).format('MMMM Do, YYYY') : null;

export const formatDateTimeWorking = (date) =>
  date ? dayjs(date).format('MMM YYYY') : null;

export const formatDateTimeInboxItem = (date) =>
  date ? dayjs(date).format('DD MMM') : null;

const searchAsyncRequest = (func, callback) => (data) => {
  func(data).then((res) => callback(res));
};

export const searchAsync = (func, callback) => {
  let task = null;
  if (task) {
    task.cancel();
  }
  task = debounce(searchAsyncRequest(func, callback), 500, {});
  return task;
};

export const getValidDataOfObj = ({
  data: obj,
  isCreateEdit = false,
  isTrimStr = true,
}) => {
  const validData = reduce(
    obj,
    (result, value, key) => {
      if (Array.isArray(value)) {
        return value.length > 0 ? { ...result, [key]: value } : result;
      }
      if (typeof value === 'object' && !isEmpty(value)) {
        const formatChildValue = getValidDataOfObj({
          data: value,
          isCreateEdit,
          isTrimStr,
        });
        return !isEmpty(formatChildValue)
          ? { ...result, [key]: formatChildValue }
          : result;
      }

      if (value || value === false || value === 0) {
        if (typeof value === 'string') {
          const trimValue = isTrimStr ? value.trim() : value;
          if (trimValue) return { ...result, [key]: trimValue };
          if (isCreateEdit) {
            return { ...result, [key]: null };
          }
          return result;
        }

        return { ...result, [key]: value };
      }

      if (isCreateEdit && (value === '' || value === null)) {
        return { ...result, [key]: null };
      }

      return result;
    },
    {},
  );
  return validData;
};

export const convertObjToSearchStr = (params) =>
  Object.keys(params)
    .map((key) =>
      params[key] !== undefined && params[key] !== null
        ? `${encodeURIComponent(key)}=${params[key]}`
        : '',
    )
    .filter((data) => data !== '')
    .join('&');

export const formatCuisines = (cuisines, data) =>
  data
    ?.map((e) => cuisines[e]?.name)
    .join(',')
    .toUpperCase();

export const changeStringNoSign = (alias) => {
  if (!alias) return null;
  let str = alias;
  str = str?.toLowerCase();
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ {2}|ặ|ẳ|ẵ/g, 'a');
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
  str = str.replace(/đ|ð/g, 'd');
  return str;
};

export const formatI18nData = (data, key) =>
  typeof data === 'string'
    ? data
    : data?.[i18n?.language]?.[key] || values(data).find((e) => !!e)?.[key];

export function containsAll(haystack = [], needles = []) {
  const tmp = haystack ? haystack.map((e) => `${e}`) : [];
  for (let i = 0; i < needles.length; i += 1) {
    if (!tmp?.includes(`${needles[i]}`)) return false;
  }
  return true;
}

export function randomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getCurrentYear = () => new Date().getFullYear();

export const convertObjToQueryRouter = (obj) => {
  const validData = reduce(
    obj,
    (result, value, key) => {
      if (typeof value === 'object' && !isEmpty(value)) {
        return { ...result, [key]: JSON.stringify(value) };
      }

      if (value || value === false || value === 0) {
        if (typeof value === 'string') {
          const trimValue = value.trim();
          if (trimValue) return { ...result, [key]: trimValue };
          return { ...result, [key]: null };
        }

        return { ...result, [key]: value };
      }

      return { ...result, [key]: null };
    },
    {},
  );
  return validData;
};

export const removeEmptyObject = (obj) => {
  const validData = reduce(
    obj,
    (result, value, key) => {
      if (typeof value === 'object' && isEmpty(value)) {
        return result;
      }

      return { ...result, [key]: value };
    },
    {},
  );
  return validData;
};

export const getQueryParamsFromUrl = (query) => {
  const validateQuery = omit(query, IGNORE_QUERY_PARAMS);
  const parsed = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in validateQuery) {
    try {
      parsed[key] = JSON.parse(validateQuery[key]);
    } catch (error) {
      // eslint-disable-next-line prefer-destructuring
      parsed[key] = validateQuery[key];
    }
  }
  return parsed;
};

export const onSearch = (data, inputValue) =>
  !!inputValue && data?.toLowerCase()?.search(inputValue?.toLowerCase()) !== -1;

export const getCurrentLink = () =>
  typeof window !== 'undefined' && window.location.href;
export const getLinkCentreDetail = (item: CentreItem, extra?: string) => ({
  pathname: `/childcare/centres/[country]/[state]/[postcode]/[suburb]/[id]${
    extra || ''
  }`,
  query: {
    country: item?.country ? slugify(capitalize(item.country)) : 'Australia',
    state: item?.state || 'NSW',
    postcode: item?.postCode || '2092',
    suburb: item?.city ? slugify(item?.city, { lower: true }) : 'seaforth',
    id: item?.slug || item?.id,
  },
});

export const getLinkDetail = (item) => {
  const linkCentreDetail = getLinkCentreDetail(item);
  const protocol = typeof window !== 'undefined' && window.location.protocol;
  const host = typeof window !== 'undefined' && window.location.host;
  const link = `${protocol}//${host}/childcare/centres/${linkCentreDetail?.query?.country}/${linkCentreDetail?.query?.state}/${linkCentreDetail?.query?.postcode}/${linkCentreDetail?.query?.suburb}/${linkCentreDetail?.query?.id}`;
  return link;
};

export const getLinkCentresSuburb = (item: CentreItem, extra?: string) => ({
  pathname: `/childcare/centres/[country]/[state]/[postcode]/[suburb]${
    extra || ''
  }`,
  query: {
    country: item?.country ? slugify(item?.country) : 'Australia',
    state: item?.state || 'NSW',
    postcode: item?.postCode || '2092',
    suburb: item?.city ? formatSlug(item.city) : 'seaforth',
  },
});

export const getLinkSuburbProfile = (item: CentreItem, extra?: string) => ({
  pathname: `/childcare/centres/[country]/[state]/[postcode]/[suburb]/suburb-profile${
    extra || ''
  }`,
  query: {
    country: item?.country ? slugify(item.country) : 'Australia',
    state: item?.state ? slugify(item.state) : 'NSW',
    postcode: item?.postCode ? formatSlug(item.postCode) : '2092',
    suburb: item?.city ? formatSlug(item.city) : 'seaforth',
  },
});

export const getLinkCentresPostcode = (item: CentreItem, extra?: string) => ({
  pathname: `/childcare/centres/[country]/[state]/[postcode]${extra || ''}`,
  query: {
    country: item?.country ? slugify(item?.country) : 'Australia',
    state: item?.state || 'NSW',
    postcode: item?.postCode || '2092',
  },
});

export const getLinkCentresState = (item, extra) => ({
  pathname: `/childcare/centres/[country]/[state]${extra || ''}`,
  query: {
    country: item?.country ? slugify(item?.country) : 'Australia',
    state: item?.state || 'NSW',
  },
});

export const getPathnameSplitPage = (route) => route?.split('/page')?.[0];

export const getBreadcrumbCentreDetail = (item) => [
  {
    title: item?.country,
  },
  {
    title: item?.state,
  },
  {
    title: item?.postCode,
    href: getLinkCentresPostcode(item),
  },
  {
    title: capitalize(item?.city),
    href: getLinkCentresSuburb(item),
  },
  {
    title: item?.name,
  },
];

export const getGoogleMapLinkDirection = (dest, destPlaceId) =>
  `https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${encodeURIComponent(
    dest,
  )}&destination_place_id=${destPlaceId}`;

export const getPercent = (value, min, max) => {
  if (typeof value !== 'number') {
    return 0;
  }

  const percent =
    min === max ? 100 : Number((value - min) / ((max - min) / 100)).toFixed();

  return Number(percent) < 0 || Number(percent) > 100 ? 0 : percent;
};

export const formatCostWithCurrency = (num) => `$${formatCostPerDay(num)}`;

export const getYoutubeEmbedURL = (url) => {
  const regex =
    /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
  const videoId = regex.exec(url)?.[3];

  if (videoId) {
    return `https://www.youtube.com/embed/${videoId}`;
  }
  return null;
};

export const formatRatingNumber = (num, digits = 1) =>
  num ? Number(num).toFixed(digits) : 0;

export const matchYoutubeUrl = (url) => {
  const pattern = /(?:youtube\.com|youtu\.be)/;
  return pattern.test(url);
};

export const formatSuburbFilter = (query) =>
  `${query.suburb}-${query.state?.toLowerCase() || ''}-${query.postcode}`;

export const checkSunTime = (time) => {
  const format = 'HH:mm';
  const beforeTime = dayjs('05:59', format);
  const afterTime = dayjs('18:00', format);
  if (
    dayjs(dayjs(time).format(format), format).isBetween(beforeTime, afterTime)
  )
    return true;
  return false;
};

export const getSunMoonTime = (
  time: Date,
  { sunColor, moonColor }: SunMoonTime = {},
) => {
  if (!time) return null;
  return checkSunTime(time) ? (
    <SunIcon
      style={{ fontSize: 20, color: sunColor ?? 'orange', marginLeft: 5 }}
    />
  ) : (
    <MoonIcon
      style={{ fontSize: 20, color: moonColor ?? 'black', marginLeft: 5 }}
    />
  );
};

export function hasValidSubsidyCost(item) {
  return !!(
    item?.bestService?.subsidyCost &&
    item?.bestService?.subsidyCost < item?.bestService?.costPerDay
  );
}

export function removeUndefined(state) {
  if (typeof state === 'undefined') return null;
  if (Array.isArray(state)) return state.map(removeUndefined);
  if (typeof state === 'object' && state !== null) {
    return Object.entries(state).reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: removeUndefined(value),
      }),
      {},
    );
  }

  return state;
}

export const isMobile = () =>
  typeof window !== 'undefined' && window.innerWidth <= 576;

export const isMediumDevice = () =>
  typeof window !== 'undefined' && window.innerWidth <= 767;

export function formatCurrency(value) {
  if (typeof value === 'number') {
    const parts = value.toFixed(2).split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `$${parts.join('.')}`;
  }

  return '$0.00';
}

export const getLinkCompare = (pathname) => {
  const arrPathname = pathname?.split('/');
  if (arrPathname?.[arrPathname?.length - 2] === 'page') {
    return dropRight(arrPathname, 2).join('/');
  }
  return pathname;
};

export const getDisplayFeeRange = (min, max, isSubsidyCost) => {
  let minCost;
  let maxCost;

  if (min === max && min === null) {
    return 'N/A';
  }

  if (min || typeof min === 'number') {
    minCost = `$${formatCostPerDay(min)}${isSubsidyCost ? '*' : ''}`;
  }
  if (max) {
    maxCost = `$${formatCostPerDay(max)}${isSubsidyCost ? '*' : ''}`;
  }

  if (min === max && minCost) {
    return minCost;
  }

  if (minCost && maxCost) {
    return `${minCost} - ${maxCost}`;
  }

  return minCost || maxCost || 'N/A';
};

export const getATagHref = (url) => {
  if (!url) return '';
  if (url.startsWith('tel:') || url.startsWith('mailto:')) {
    return url;
  }
  return `https://${url.replace(/^(https:\/\/)|(http:\/\/)/g, '')}`;
};

export const openExternalLink = (url, target, isCorrectUrl = false) => {
  const normalizeUrl = isCorrectUrl ? url : getATagHref(url);

  if (normalizeUrl && typeof window !== 'undefined') {
    window.open(normalizeUrl, target);
  }
};

export const getDeviceId = () => {
  if (typeof window !== 'undefined') {
    const deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
      const generatedDeviceId = `${PREFIX_RW_DEVICE_ID}-${nanoid()}`;
      localStorage.setItem('deviceId', generatedDeviceId);
      return generatedDeviceId;
    }
    return deviceId;
  }
  return null;
};

export const getRecordData = (record, source) => get(record, source);

export const disabledSelectBeforeToday = (current) =>
  current && current < dayjs().startOf('day');

export const generateSlugHasId = (slug, id) => `${slug ?? ''}-${id ?? ''}`;

export const getLinkJobDetail = (item, extra) => {
  const suburb = item?.suburb || item?.city;

  return {
    pathname: `/childcare/jobs/australia/[state]/[postcode]/[suburb]/[company]/[brand]/[centre]/[jobSlug]${
      extra || ''
    }`,
    query: {
      state: item?.state || 'NSW',
      postcode: item?.postCode || item?.postcode || '2000',
      suburb: suburb ? slugifySuburb(suburb) : 'sydney',
      company: item?.company ? slugifyUrl(item.company) : EMPTY_SLUG_CHARACTER,
      brand: item?.brand ? slugifyUrl(item.brand) : EMPTY_SLUG_CHARACTER,
      centre: item?.centre ? slugifyUrl(item.centre) : EMPTY_SLUG_CHARACTER,
      jobSlug: item?.slug || item?.id,
    },
  };
};

export const getLinkJobsByCentre = (
  item,
  { extra, extraQuery }: ExtraLinkJobs = {},
  companyId,
  organizationId,
) => {
  const suburb = item?.suburb || item?.city;
  const companySlug = item?.company
    ? slugifyUrl(item.company)
    : EMPTY_SLUG_CHARACTER;

  const company = companyId
    ? generateSlugHasId(companySlug, companyId)
    : companySlug;

  const centreSlug = item?.centre
    ? slugifyUrl(item.centre)
    : EMPTY_SLUG_CHARACTER;

  const centre = organizationId
    ? generateSlugHasId(centreSlug, organizationId)
    : centreSlug;

  return {
    pathname: `/childcare/jobs/australia/[state]/[postcode]/[suburb]/[company]/[brand]/[centre]${
      extra || ''
    }`,
    query: {
      state: item?.state || 'NSW',
      postcode: item?.postCode || item?.postcode || '2000',
      suburb: suburb ? slugifySuburb(suburb) : 'sydney',
      company,
      brand: item?.brand ? slugifyUrl(item.brand) : EMPTY_SLUG_CHARACTER,
      centre,
      ...extraQuery,
    },
  };
};

export const getLinkJobsByCompany = (
  item,
  { extra, extraQuery }: ExtraLinkJobs = {},
  companyId,
) => {
  const companySlug = item?.company
    ? slugifyUrl(item.company)
    : EMPTY_SLUG_CHARACTER;

  const company = companyId
    ? generateSlugHasId(companySlug, companyId)
    : companySlug;

  return {
    pathname: `/childcare/jobs/company/[company]${extra || ''}`,
    query: {
      company,
      ...extraQuery,
    },
  };
};

export const getLinkJobsByBrand = (
  item,
  { extra, extraQuery }: ExtraLinkJobs = {},
  brandId,
) => {
  const brandSlug = item?.brand ? slugifyUrl(item.brand) : EMPTY_SLUG_CHARACTER;
  const brand = brandId ? generateSlugHasId(brandSlug, brandId) : brandSlug;

  return {
    pathname: `/childcare/jobs/company/[company]/[brand]${extra || ''}`,
    query: {
      company: item?.company ? slugifyUrl(item.company) : EMPTY_SLUG_CHARACTER,
      brand,
      ...extraQuery,
    },
  };
};

export const getLinkJobsBySuburb = (
  item,
  { extra, extraQuery }: ExtraLinkJobs = {},
) => {
  const suburb = item?.suburb || item?.city;

  return {
    pathname: `/childcare/jobs/australia/[state]/[postcode]/[suburb]${
      extra || ''
    }`,
    query: {
      state: item?.state || 'NSW',
      postcode: item?.postCode || item?.postcode || '2000',
      suburb: suburb ? slugifySuburb(suburb) : 'sydney',
      ...extraQuery,
    },
  };
};

export const getLinkJobsByPostcode = (item, extra) => ({
  pathname: `/childcare/jobs/australia/[state]/[postcode]${extra || ''}`,
  query: {
    state: item?.state || 'NSW',
    postcode: item?.postCode || item?.postcode || '2000',
  },
});

export const getLinkSearchByJobTitle = (jobTitle, extra) => ({
  pathname: `/childcare/jobs/[jobTitle]${extra || ''}`,
  query: {
    jobTitle: slugifyUrl(jobTitle),
    title: jobTitle,
  },
});

export const getLinkSearchByJobRole = (jobRole, jobRoleId, extra) => {
  const jobRoleSlug = slugifyUrl(jobRole);

  return {
    pathname: `/childcare/jobs-role/[jobRole]${extra || ''}`,
    query: {
      jobRole: generateSlugHasId(jobRoleSlug, jobRoleId),
    },
  };
};

export const getRawLinkFromUrlObject = ({ pathname, query }) => {
  const interpolateDelimiter = /\[(.+?)\]/g;

  const compilePathnameFn = template(pathname, {
    interpolate: interpolateDelimiter,
  });

  return compilePathnameFn(query);
};

export const disabledDateAfter = (current: Date, type: OpUnitType = 'month') =>
  current && dayjs(current).isAfter(dayjs(), type);

export const getSiteLink = () => {
  const protocol = typeof window !== 'undefined' && window.location.protocol;
  const host = typeof window !== 'undefined' && window.location.host;
  return `${protocol}//${host}`;
};

export const handleOpenEmail = (e, email, push) => {
  e.preventDefault();
  email &&
    push(
      {
        pathname: `mailto:${email ?? ''}`,
      },
      undefined,
      { shallow: true },
    );
};

export const handlePhoneCall = (e, phone, push) => {
  e.preventDefault();
  push(
    {
      pathname: `tel:${phone ?? ''}`,
    },
    undefined,
    { shallow: true },
  );
};

export const getAustraliaAlpha2Code = (country) => {
  const lowercaseCountry = country?.trim()?.toLowerCase();

  if (lowercaseCountry === AUSTRALIA_COUNTRY.lowercaseValue) {
    return AUSTRALIA_COUNTRY.alpha2CountryCode;
  }

  return country;
};

export const getPostalAddressOfJob = (organization) => {
  if (organization?.businessLevel === BUSINESS_LEVEL.CENTRE) {
    return pickBy({
      streetAddress: organization?.centre?.addressL1,
      addressLocality: organization?.centre?.city,
      postalCode: organization?.centre?.postCode,
      addressRegion: organization?.centre?.state,
      addressCountry: getAustraliaAlpha2Code(organization?.centre?.country),
    });
  }
  return pickBy({
    streetAddress: organization?.company?.street,
    addressLocality: organization?.company?.suburb,
    postalCode: organization?.company?.postCode,
    addressRegion: organization?.company?.state,
    addressCountry: getAustraliaAlpha2Code(organization?.company?.country),
  });
};

export const omitFalsy = (obj = {}) => {
  const result = {};

  Object.entries(obj).forEach(([key, value]) => {
    if (value && typeof value === 'object' && !Array.isArray(value)) {
      result[key] = omitFalsy(value);
    } else if (value) {
      result[key] = value;
    }
  });

  return result;
};

export const handleRedirectInvalidPaging = ({
  resolvedUrl,
  res,
}: HandleRedirectInvalidPaging = {}) => {
  const originalPageUrl = getPathnameSplitPage(resolvedUrl);
  res.setHeader('X-Robots-Tag', 'noindex');

  return {
    redirect: {
      destination: originalPageUrl,
      permanent: false,
    },
  };
};

export const isInvalidPagingJobPage = (response, currentPage) => {
  const totalPage = Math.ceil(
    response?.data?.paging?.total / response?.data?.paging?.size,
  );

  return !response?.data?.results?.length || Number(currentPage) > totalPage;
};

export const checkImageDomain = (url = '', whitelist = []) => {
  if (!url) return false;
  return whitelist.some((domain) => url.includes(domain));
};

export const getSuburbMetaSeo = (postcode) =>
  SEO_SUBURB.find(
    (item) =>
      postcode >= item.rangPostcode[0] && postcode <= item.rangPostcode[1],
  );

export const checkWordWithVowel = (word) => {
  const VOWELS = ['u', 'e', 'o', 'a', 'i'];
  return VOWELS.some((item) => word.toLowerCase().startsWith(item));
};

export const formatSlug = (text: string) => {
  if (!text) {
    return null;
  }

  let slug = slugify(text, { lower: true });
  slug = slug.replace(/[^a-zA-Z0-9\s-']/g, '');
  return slug;
};
export const pluralizeNoun = (count, noun) => {
  const plural = `${noun
    .replace(/(?:s|x|z|ch|sh)$/i, '$&e')
    .replace(/([^aeiou])y$/i, '$1ie')}s`.replace(/i?e?s$/i, (match) => {
    const isTailLowerCase = noun.slice(-1) === noun.slice(-1).toLowerCase();
    return isTailLowerCase ? match.toLowerCase() : match.toUpperCase();
  });

  return Math.abs(count) === 1 ? noun : plural;
};

export const convertStringToNumber = (str: string) => {
  if (!str) {
    return 0;
  }

  const num = Number(str);
  return Number.isNaN(num) ? str : num;
};
