import styled from 'styled-components';

const FormAvatarUploadStyles = styled.div`
  margin-bottom: 20px;
  .ant-avatar {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--avatar-bg);
    color: var(--avatar-color);
  }
  .user-icon {
    font-size: 70px;
  }
  .loading-icon {
    font-size: 30px;
    color: var(--primary);
  }
  .error-icon {
    font-size: 30px;
    color: var(--color-red);
  }
  .upload-photo {
    margin-top: 10px;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .image-uploader .image-hover .image-hover-icon {
    font-size: 25px;
    -webkit-transition: font-size 0.5s;
    transition: font-size 0.5s;
    color: transparent;
  }

  .image-uploader:hover .image-hover .image-hover-icon {
    font-size: 30px;
    color: #fff;
  }

  .image-uploader div.image-hover {
    cursor: pointer;
    background: transparent;
    -webkit-transition: background 0.2s;
    transition: background 0.2s;
  }
  .image-uploader:hover div.image-hover {
    background: rgba(0, 0, 0, 0.5);
  }

  .image-uploader {
    position: relative;
    .image-hover {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: transparent;
      border-radius: 50%;
    }
  }

  .image-uploader:hover {
    .image-hover {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export default FormAvatarUploadStyles;
