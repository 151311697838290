import { createSlice } from '@reduxjs/toolkit';
import {
  getMyJobsWithViewedTab,
  getMyJobsWithAppliedTab,
  getMyJobsWithFavoritesTab,
} from './actions';

const initialState = {
  viewed: {
    data: [],
    loading: false,
    error: null,
    total: null,
    page: 1,
    size: 10,
  },
  applied: {
    data: [],
    loading: false,
    error: null,
    total: null,
    page: 1,
    size: 10,
    applicationStatusFilters: {},
    compareAllButton: false,
  },
  favorites: {
    data: [],
    loading: false,
    error: null,
    total: null,
    page: 1,
    size: 10,
  },
};

const filterJobCard = (jobs) =>
  jobs?.filter((job) => job._typename === 'JobCard') || [];

const myJobSlice = createSlice({
  name: 'jobDetails',
  initialState,
  reducers: {
    deleteFavoriteJobsSuccess: (state, { payload: id }) => {
      state.favorites.data = state.favorites.data?.filter(
        (item) => item?.id !== id,
      );
    },
  },
  extraReducers: {
    [getMyJobsWithViewedTab.pending]: (state) => {
      state.viewed.loading = true;
    },
    [getMyJobsWithViewedTab.fulfilled]: (state, { payload }) => {
      state.viewed.data = filterJobCard(payload?.results);
      state.viewed.loading = false;
      state.viewed.total = payload?.paging.total ?? 0;
      state.viewed.page = payload?.paging.page ?? 1;
      state.viewed.size = payload?.paging.size ?? 10;
    },
    [getMyJobsWithViewedTab.rejected]: (state, { payload }) => {
      state.viewed.error = payload || null;
      state.viewed.loading = false;
    },

    [getMyJobsWithAppliedTab.pending]: (state) => {
      state.applied.loading = true;
    },
    [getMyJobsWithAppliedTab.fulfilled]: (state, { payload }) => {
      state.applied.data = filterJobCard(payload?.results);
      state.applied.loading = false;
      state.applied.total = payload?.paging.total ?? 0;
      state.applied.page = payload?.paging.page ?? 1;
      state.applied.size = payload?.paging.size ?? 10;
      state.applied.applicationStatusFilters =
        payload?.applicationStatusFilters;
      state.applied.compareAllButton = payload?.compareAllButton;
    },
    [getMyJobsWithAppliedTab.rejected]: (state, { payload }) => {
      state.applied.error = payload || null;
      state.applied.loading = false;
    },

    [getMyJobsWithFavoritesTab.pending]: (state) => {
      state.favorites.loading = true;
    },
    [getMyJobsWithFavoritesTab.fulfilled]: (state, { payload }) => {
      state.favorites.data = filterJobCard(payload?.results);
      state.favorites.loading = false;
      state.favorites.total = payload?.paging.total ?? 0;
      state.favorites.page = payload?.paging.page ?? 1;
      state.favorites.size = payload?.paging.size ?? 10;
    },
    [getMyJobsWithFavoritesTab.rejected]: (state, { payload }) => {
      state.favorites.error = payload || null;
      state.favorites.loading = false;
    },
  },
});
export const { deleteFavoriteJobsSuccess } = myJobSlice.actions;

export default myJobSlice.reducer;
