import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { getCentreDetailsBasicInfoSelector } from '@redux/centreDetails/selectors';
import { applyApplication } from '@redux/applications/actions';
import { CheckCircleFilled } from '@ant-design/icons';
import { resetDataApplyApplication } from '@redux/applyApplicationStep/slice';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';

const Step4Footer = ({ onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataApply = useSelector(
    (state) => state.applyApplicationStep?.dataApply,
  );
  const [loading, setLoading] = useState(false);
  const { data } = useSelector(getCentreDetailsBasicInfoSelector) || {};

  const onSubmit = () => {
    setLoading(true);
    dispatch(
      applyApplication({
        data: {
          centreId: data?.id,
          ...dataApply,
        },
      }),
    )
      .then(({ payload }) => {
        setLoading(false);
        if (payload?.success) {
          onCancel();
          dispatch(resetDataApplyApplication());
          Modal.success({
            icon: <CheckCircleFilled />,
            title: (
              <div className="fw-bold size-18">
                {t('applyApplications.successModal.title')}
              </div>
            ),
            content: t('applyApplications.successModal.desc'),
            okText: t('button.done'),
          });
          dispatch(
            trackingEvent({
              eventName: EVENT_TRACKING.applicationSubmitted,
              eventData: {
                centreId: data?.id,
                applicationId: payload?.data?.id,
              },
            }),
          );
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return (
    <div className="flex-center-between flex-wrap step4-footer">
      <div style={{ color: '#857E7F' }}>
        {t('applyApplications.step4.footerText')}
        <a
          href="/terms-and-conditions"
          target="_blank"
          className="ml-5 text-highlight fw-500"
        >
          {t('applyApplications.step4.termsPolicy')}
        </a>
      </div>
      <Button type="primary" onClick={onSubmit} loading={loading}>
        {t('button.apply')}
      </Button>
    </div>
  );
};

Step4Footer.propTypes = {
  onCancel: PropTypes.func,
};

export default Step4Footer;
