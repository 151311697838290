/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { goToNextStep } from '@redux/applyApplicationStep/slice';
import Step1Footer from './Step1Footer';
import Step2Footer from './Step2Footer';
import Step3Footer from './Step3Footer';
import Step4Footer from './Step4Footer';

const FooterSteps = ({ currentStep = 0, form, onCancel }) => {
  const dispatch = useDispatch();

  const onNextStep = () => dispatch(goToNextStep());

  const footerContent = useMemo(() => {
    switch (currentStep + 1) {
      case 1:
        return <Step1Footer form={form} onNextStep={onNextStep} />;
      case 2:
        return <Step2Footer form={form} onNextStep={onNextStep} />;
      case 3:
        return <Step3Footer form={form} onNextStep={onNextStep} />;
      case 4:
        return <Step4Footer form={form} onCancel={onCancel} />;
      default:
        return <div />;
    }
  }, [currentStep]);

  return <div>{footerContent}</div>;
};

FooterSteps.propTypes = {
  currentStep: PropTypes.number,
  form: PropTypes.object,
  onCancel: PropTypes.func,
};

export default FooterSteps;
