import PropTypes from 'prop-types';
import Image from 'components/Image';
import { getFeaturedImage } from 'utils/dataUtils';
import KindiRating from '@uikit/commons/KindiRating';
import BoxCentreInfo from '@uikit/commons/BoxCentreInfo';
import { CentreInfoStyles } from './styles';

const CentreInfo = ({ centre, isComplex }) => (
  <CentreInfoStyles
    className={`${isComplex ? 'centre-info-complex' : 'centre-info-simple'}`}
  >
    <Image
      className="centre-info__image"
      alt="kindicare"
      src={centre?.featuredImageUrl || getFeaturedImage(centre?.images)}
    />
    <KindiRating
      size="large"
      className="centre-info__rating"
      kindiCareRating={centre?.kindiCareRating}
      kindiCareRatingImage={centre?.kindiCareRatingImage}
      width={48}
      height={48}
    />
    {centre?.NQSRType?.image && (
      <Image
        className="centre-info__NQSRType-img"
        alt="kindicare"
        src={centre?.NQSRType?.image}
      />
    )}

    <BoxCentreInfo centre={centre} />
  </CentreInfoStyles>
);

CentreInfo.propTypes = {
  centre: PropTypes.object,
  isComplex: PropTypes.bool,
};

export default CentreInfo;
