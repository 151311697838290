import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { PREFIX_URL_API } from 'configs/constants';
import { getBaseURLApi } from 'utils';

export const brandsApi = rtkCreator('brands', 'brands', {
  baseURL: getBaseURLApi(PREFIX_URL_API.public),
});

export const { useGetAllBrandsQuery } = brandsApi;

export const { getAllBrands } = brandsApi.endpoints;
