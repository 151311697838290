import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const companiesFeaturedApi = rtkCreator(
  'companiesFeatured',
  'job-seekers/screens/posts/featured-companies',
  richWebConfigVer2,
);

export const { useGetAllCompaniesFeaturedQuery } = companiesFeaturedApi;

export const { getAllCompaniesFeatured } = companiesFeaturedApi.endpoints;
