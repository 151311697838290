import { pick } from 'lodash-es';
import { formatDateOfBirthSubmit } from 'utils/dataUtils';

export const formatOnSubmitCareOptions = ({ careOptions, ...values }) => {
  const careOptionsFormat = [];
  careOptions?.forEach(({ startDate, child, isSelected, ...values }) => {
    if (isSelected) {
      careOptionsFormat.push({
        ...values,
        childId: child?.id,
        ...(startDate && {
          startDay: formatDateOfBirthSubmit(startDate),
        }),
        ...pick(child, [
          'firstName',
          'lastName',
          'gender',
          'centrelinkCRN',
          'allergies',
          'specialNeeds',
          'requiredDays',
        ]),
        dateOfBirth: formatDateOfBirthSubmit(child?.dateOfBirth),
      });
    }
  });
  return {
    ...values,
    careOptions: careOptionsFormat,
  };
};
