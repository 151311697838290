import { last, mapKeys } from 'lodash-es';

export const getJobSorterConfig = (data) => {
  const sortsConfig = data?.topBar?.right?.items?.map((item) => ({
    ...item,
    value: item?.params?.sorts?.[0],
  }));

  return sortsConfig;
};

export const convertJobFilterConfigs = (data) => {
  const filters = data?.map((item) => ({
    title: item?.title,
    input: item?.input,
    key: last(item?.state?.split('.')),
  }));

  return mapKeys(filters, 'key');
};
export const convertJobDetailsToJobCard = (
  jobDetails = {},
  workPlace = {},
) => ({
  _typename: 'JobCard',
  action: {
    _typename: 'Navigate',
    params: {
      postId: jobDetails?.id,
    },
    screen: 'JobDetails',
    title: jobDetails?.jobTitle,
    url: '/thor/v2/job-seekers/screens/posts/details',
    loggingData: {
      _typename: 'LoggingData',
      eventData: {
        postId: jobDetails?.id,
      },
      eventName: 'job_post_details_viewed',
    },
  },
  address: jobDetails?.organization?.address,
  distance: workPlace?.distance,
  discount: jobDetails?.snapshot?.discount?.value,
  discountIconUrl: jobDetails?.snapshot?.discount?.iconUrl,
  heading: {
    bottomLeft: [
      {
        _typename: 'OrganizationLogo',
        url: jobDetails?.organization?.logoUrl,
      },
    ],
    bottomRight:
      jobDetails?.organization?.businessLevel === 'centre'
        ? [
            {
              _typename: 'KindiCareRating',
              kindiCareRating: jobDetails?.organization?.kindiCareRating,
            },
            {
              _typename: 'NQSRating',
              url: jobDetails?.organization?.NQSRatingImageUrl,
            },
          ]
        : undefined,
    topLeft: [
      {
        _typename: 'JobMatchProgressCircle',
        action: {
          _typename: 'Navigate',
          params: {},
          screen: 'EducatorProfile',
          title: null,
          url: '/thor/v2/job-seekers/me',
          loggingData: null,
        },
        score: jobDetails?.jobMatching?.score,
        unit: '%',
      },
    ],
    topRight: [
      {
        _typename: 'CardFavoriteButton',
        data: {
          postId: '5047',
        },
        state: false,
        style: {},
        url: '/hulk/v2/job-seekers/posts/favorites',
      },
    ],
    featuredPhoto: jobDetails?.organization?.featuredPhotoUrl,
  },
  slug: jobDetails?.slug,
  company: jobDetails?.organization?.company?.name,
  brand: jobDetails?.organization?.centre?.brand?.name,
  centre: jobDetails?.organization?.centre?.name,
  id: jobDetails?.id,
  jobTitle: jobDetails?.snapshot?.jobTitle,
  jobType: jobDetails?.snapshot?.jobCategoryType?.value,
  location: null,
  organizationName: jobDetails?.organization?.name,
  postedTime: jobDetails?.snapshot?.postedTime?.value,
  salary: jobDetails?.snapshot?.salary?.value,
});

export const convertJobCardDistanceToInt = (distanceNeedToConvert) =>
  distanceNeedToConvert?.toString().split(' ')[0] * 1000;
