import { createSlice } from '@reduxjs/toolkit';
import { getStatisticsElectionPoll } from './actions';

export const initialState = {};

const { reducer } = createSlice({
  name: 'pollVotes',
  initialState,
  reducers: {},
  extraReducers: {
    [getStatisticsElectionPoll.pending]: (state) => {
      state.loading = true;
    },
    [getStatisticsElectionPoll.fulfilled]: (state, { payload }) => {
      state.data = payload;
      state.loading = false;
    },
    [getStatisticsElectionPoll.rejected]: (state) => {
      state.loading = false;
    },
  },
});

export default reducer;
