import { convertRequestParams } from '@redux/crudCreator/dataProvider';
import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getCentreServicesSummariesApi } from 'api/centreServices';

export const MODEL_NAME = 'centreServices';

export const getCentreServicesSummaries = createAsyncThunk(
  'centreServices/getCentreServicesSummaries',
  async (payload, thunkAPI) => {
    try {
      const convertRequest = convertRequestParams('GET_ALL', payload?.data, {
        isDefaultOrderBy: false,
      });

      const response = await apiWrapper(
        {},
        getCentreServicesSummariesApi,
        convertRequest,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
