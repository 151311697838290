import { MenuOutlined } from '@ant-design/icons';
import { HeartFilledSecondIcon, MailFilledIcon } from '@uikit/commons/SVGIcons';
import { Anchor, Badge, Button, Layout, Space } from 'antd';
import { DEFAULT_INBOX_ID } from 'configs/constants';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useSelector } from 'react-redux';
import { isMobile } from 'utils/tools';
import NextImage from '@uikit/commons/NextImage';
import SmartBannerCustom from '@uikit/banners/SmartBannerCustom';
import Link from '@uikit/commons/Link';
import DrawerMenuMobile from './DrawerMenuMobile';
import GroupActionsHeader from './GroupActionsHeader';
import MenuHeader from './MenuHeader';
import { HeaderStyles } from './styles';
import UserInfo from './UserInfo';

const Header = ({
  isStickHeader = true,
  isHeaderPinkBackground = false,
  hideHeader,
}) => {
  const mobileMenuModalRef = useRef();
  const { push, pathname } = useRouter();

  const isAuth = useSelector((state) => state.auth.isAuthenticated);

  const handleClickMenuIcon = () => {
    mobileMenuModalRef?.current && mobileMenuModalRef.current.openModal();
  };

  const totalUnreadMess = useSelector((state) => state.inboxes.totalUnread);

  const handleClickMailBox = () => {
    if (isMobile()) {
      push('/m/mailbox');
    } else {
      push(`/mailbox/${DEFAULT_INBOX_ID}`);
    }
  };

  const handleClickFavourites = () => {
    push('/favourites-centers');
  };

  const mailBoxBtn = (
    <Button
      onClick={handleClickMailBox}
      className={`header-btn-icon ${
        pathname?.includes('/mailbox') ? 'header-btn-icon-selected' : ''
      }`}
      shape="circle"
      icon={<MailFilledIcon />}
    />
  );

  const showRightHeader = () => {
    if (isAuth) {
      if (pathname?.includes('jobs')) {
        return (
          <div className="user-info">
            <UserInfo />
          </div>
        );
      }
      return (
        <div className="header-right d-flex items-center">
          <Space size={20} className="header-space-group-btn">
            <Button
              onClick={handleClickFavourites}
              className={`header-btn-icon ${
                pathname?.includes('/favourites-centers')
                  ? 'header-btn-icon-selected'
                  : ''
              }`}
              shape="circle"
              icon={<HeartFilledSecondIcon />}
            />
            <div className="mail-box-auth">
              {!totalUnreadMess ? (
                mailBoxBtn
              ) : (
                <Badge count={totalUnreadMess || 0}>{mailBoxBtn}</Badge>
              )}
            </div>
            <div className="user-info">
              <UserInfo />
            </div>
          </Space>
        </div>
      );
    }
    return (
      <div className="header-right d-flex items-center">
        <div className="group-header ml-20">
          <GroupActionsHeader />
        </div>
      </div>
    );
  };

  const renderSmartBannerWhenNotStick = () =>
    !isStickHeader && <SmartBannerCustom className="sticky z-100 w-full" />;

  return (
    <>
      {renderSmartBannerWhenNotStick()}
      <Anchor affix={isStickHeader} top={0} left={0}>
        {isStickHeader && <SmartBannerCustom />}
        <HeaderStyles
          className={isHeaderPinkBackground ? 'header-pink-bg' : ''}
          hideHeader={hideHeader}
        >
          <Layout.Header className="mainHeader wrapper-container">
            <div className="header-wrapper w-full flex-center-between">
              <div className="header-left d-flex flex-1">
                <div className="d-flex items-center">
                  <Link href="/" className="d-flex pb-10">
                    <NextImage
                      className="object-contain"
                      src="/images/logo.png"
                      width={138}
                      height={40}
                      priority
                      alt="kindicare logo"
                    />
                  </Link>
                </div>

                <MenuHeader />
              </div>
              {showRightHeader()}
              <Button
                onClick={handleClickMenuIcon}
                className="mobile-menu-icon"
                type="text"
                size="large"
                icon={<MenuOutlined />}
              />
            </div>
          </Layout.Header>
        </HeaderStyles>
        <DrawerMenuMobile ref={mobileMenuModalRef} />
      </Anchor>
    </>
  );
};

Header.propTypes = {
  isStickHeader: PropTypes.bool,
  isHeaderPinkBackground: PropTypes.bool,
  hideHeader: PropTypes.object,
};

export default Header;
