import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const fieldOfStudyApi = rtkCreator(
  'fieldOfStudy',
  'job-seekers/fields-of-study',
  richWebConfigVer2,
);

export const { useGetAllFieldOfStudyQuery } = fieldOfStudyApi;
