import { setFetchedConfigSubsidy } from '@redux/configLocal/slice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConfigSubsidyApi } from 'api/config';

export const getConfigSubsidy = createAsyncThunk(
  'configSubsidy/getConfigSubsidy',
  async (payload, thunkAPI) => {
    try {
      const data = await getConfigSubsidyApi();
      thunkAPI.dispatch(setFetchedConfigSubsidy(true));
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
