import React from 'react';
import { Space } from 'antd';
import { CheckOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import useWindowSize from 'hooks/useWindowSize';
import { useTranslation } from 'react-i18next';
import { StatisticsReview } from 'types/parentReview';
import { FilterReviewStyle, SelectedItemStyle } from './styles';

interface Props {
  summaries?: StatisticsReview[];
  handleFilterChange?: (value?: number) => void;
  checkIsSelected?: (value?: number) => boolean;
}
const FilterReviews = ({
  summaries,
  handleFilterChange,
  checkIsSelected,
}: Props) => {
  const { isMobile } = useWindowSize();
  const { t } = useTranslation();
  return (
    <div>
      <FilterReviewStyle>
        {summaries?.map((item) => (
          <SelectedItemStyle
            className="border-light py-3 px-4 xs:rounded-100 rounded-8"
            key={item?.rating}
            onClick={() => handleFilterChange(item?.rating)}
            checkIsSelected={checkIsSelected(item?.rating)}
          >
            <Space size={8} className="pointer">
              {isMobile ? (
                <>
                  {checkIsSelected(item?.rating) && <CheckOutlined />}
                  {item?.rating}
                  <div className="ic-star flex-center">
                    {checkIsSelected(item?.rating) ? (
                      <StarFilled />
                    ) : (
                      <StarOutlined className="text-grey-l1 size-14" />
                    )}
                  </div>
                </>
              ) : (
                <>{`${item?.rating} ${
                  item?.rating === 1 ? t('common.star') : t('common.stars')
                } (${item?.count})`}</>
              )}
            </Space>
          </SelectedItemStyle>
        ))}
      </FilterReviewStyle>
    </div>
  );
};

export default FilterReviews;
