import { getMessagesByInboxIdApi, postMessageApi } from 'api/inboxes';
import { getAllCustom } from '@redux/crudCreator/actions';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { apiWrapper } from '@redux/reduxUtils';
import { updateInboxesListAfterSendMessages } from '@redux/inboxes/slice';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';

export const MODEL_NAME = 'messages';

export const getMessagesByInboxId = getAllCustom(
  MODEL_NAME,
  getMessagesByInboxIdApi,
);

export const sendMessage = createAsyncThunk(
  'messages/sendMessage',
  async (payload = {}, thunkApi) => {
    try {
      const response = await apiWrapper(
        {},
        postMessageApi,
        payload.id,
        payload.data,
      );
      thunkApi.dispatch(updateInboxesListAfterSendMessages(response?.data));
      thunkApi.dispatch(
        trackingEvent({
          eventName: EVENT_TRACKING.messageSent,
          eventData: {
            centreId: response.data?.centreId,
            messageId: response.data?.id,
          },
        }),
      );
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.id, data: error });
    }
  },
);
