import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const jobRelatedArticlesApi = rtkCreator(
  'jobRelatedArticles',
  'job-seekers/screens/articles/relative',
  richWebConfigVer2,
);

export const { useGetAllJobRelatedArticlesQuery } = jobRelatedArticlesApi;
