import { Breadcrumb, Menu, Row } from 'antd';
import { CentreDetailsStyles } from 'components/centres/Details/styles';
import styled from 'styled-components';
import { ReviewSectionStyles } from 'components/centres/Details/ReviewSection';

export const JobDetailStyles = styled(CentreDetailsStyles)`
  margin-bottom: 80px;
  .services-slide-wrapper {
    position: sticky !important;
    position: -webkit-sticky !important;
    top: 100px !important;
  }

  .map-direction > a {
    :hover {
      color: var(--primary) !important;
    }
  }
`;

export const JobHighlightsBenefitsStyles = styled.div`
  .diamond {
    width: 10px;
    height: 10px;
    background-color: var(--text-green);
    transform: rotate(45deg);
  }

  .interaction-wrapper {
    background-color: var(--bg-pastel-green-l100);
  }

  .interaction-text {
    margin-left: 14px;
    color: var(--primary);
    line-height: 24px;
  }
`;

export const AboutYouStyles = styled.div`
  margin-top: 24px;
  font-size: 18px;
  .about-you-icon {
    margin-top: 3px;
    &__check-icon {
      color: var(--text-green);
    }
    &__close-icon {
      color: var(--text-gray-neutral-l400);
    }
  }
`;

export const JobDetailSlideStyles = styled.div`
  border-radius: 20px;
  box-shadow: 0px 8px 40px rgba(39, 40, 51, 0.12);
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 24px;
  margin-top: 23px;
  .job-image {
    top: -25px;
  }

  .job-title {
    line-height: 28px;
    color: var(--text-black-l8);
  }

  .job-time {
    line-height: 28px;
    color: var(--text-green);
    &__text-job {
      color: var(--color-pastel-green);
    }
  }

  .job-type {
    padding: 2px 8px;
    border-radius: 8px;
  }

  .apply-time {
    padding: 2px 8px;
    line-height: 24px;
    border-radius: 8px;
  }

  .button-group {
    .ant-btn {
      border-radius: 10px;
      font-weight: 700;
    }
    .ant-btn-default:not([disabled]) {
      border-color: var(--text-green);
      color: var(--text-green);
    }
  }

  .ant-btn-primary {
    background: var(--text-green);
    border-color: var(--text-green);
    :hover {
      color: var(--text-white);
    }
  }
`;

export const JodDetailItemStyles = styled.div`
  .job-item {
    line-height: 24px;
    color: var(--text-black-l8);
  }
`;

export const ProfileMatchJobStyles = styled.div`
  box-shadow: 0px 8px 40px rgba(39, 40, 51, 0.12);
  background: var(--bg-pastel-green-l100);
  border-radius: 12px;
  border: 1px solid var(--bg-pastel-green-l100);
  margin-top: 32px;

  .content-match-job {
    color: var(--color-pastel-green);
  }
`;

export const AboutUsStyles = styled.div`
  .anticon {
    font-size: 20px !important;
    margin-top: 2px !important;
  }
  .frame-youtube {
    height: 500px;
  }
`;

export const AboutTheCentreStyles = styled.div`
  .anticon {
    font-size: 20px !important;
    margin-top: 4px !important;
  }
`;

export const ConnectUsStyles = styled.div`
  .anticon {
    font-size: 20px !important;
    color: var(--text-green);
  }
`;

export const BreadcrumbMenuStyle = styled(Breadcrumb)`
  color: var(--color-grey-l2) !important;
  .ant-dropdown-trigger > .anticon.anticon-down,
  .ant-dropdown-link > .anticon.anticon-down,
  .ant-dropdown-button > .anticon.anticon-down {
    display: none !important;
  }

  .ant-breadcrumb-link {
    a {
      color: var(--color-grey-l2) !important;
    }
  }
  .ant-breadcrumb-link:hover a {
    color: var(--text-green) !important;
  }

  .ant-dropdown-menu-title-content {
    color: var(--color-grey-l2) !important;
  }
  .ant-dropdown-menu-title-content {
    .a {
      color: var(--color-grey-l2) !important;
    }
  }

  .read-more {
    display: inline-block;
  }
`;

export const MenuStyle = styled(Menu)`
  .ant-dropdown-menu-item,
  .ant-dropdown-menu-submenu-title {
    color: var(--color-grey-l2) !important;
  }
`;

export const ReviewSectionCustom = styled(ReviewSectionStyles)`
  .rating-summary-wrapper {
    background-color: var(--text-green-white);
    border: 1px solid rgba(43, 173, 167, 0.5);
  }
  .ant-radio-button-wrapper-checked {
    background-color: var(--bg-pastel-green) !important;
    color: var(--text-green);
    border: 1px solid var(--text-green) !important;
    :focus-within {
      box-shadow: 0 0 0 3px var(--color-green-neutral) !important;
    }
  }
  .ant-radio-button-wrapper:hover {
    color: var(--text-green);
  }
  .btn-write-review {
    display: none;
  }
`;

export const okButtonStyles = {
  backgroundColor: 'var(--text-green)',
  borderColor: 'var(--text-green)',
};

export const JobDetailSlideWrapper = styled(Row)`
  @media only screen and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;
