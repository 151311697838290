import { createDraftSafeSelector } from '@reduxjs/toolkit';

const getRawRehydratedPersistUser = (state) => state.auth?._persist?.rehydrated;
const getRawRehydratedPersistConfig = (state) =>
  state.config?._persist?.rehydrated;

const getRawCurrentUser = (state) => state.auth?.data;

const getRawAuthenticated = (state) => state.auth?.isAuthenticated;

const getRawPersistUser = (state) => state.auth?._persist;

export const getHouseholdTaxableIncomeSelector = createDraftSafeSelector(
  [getRawCurrentUser],
  (data) => (data?.isSearchedWithSubsidy ? data.householdTaxableIncome : null),
);

export const getUserParamsFilterSelector = createDraftSafeSelector(
  [getRawCurrentUser],
  (data) =>
    data?.isSearchedWithSubsidy
      ? {
          householdTaxableIncome: data.householdTaxableIncome,
          parentActivityLevelId: data.parentActivityLevelId,
        }
      : null,
);

export const getRehydratedPersistUser = createDraftSafeSelector(
  [getRawRehydratedPersistUser],
  (data) => data,
);

export const getCurrentUserSelector = createDraftSafeSelector(
  [getRawCurrentUser],
  (data) => data,
);

export const getIsSkipGetCentres = createDraftSafeSelector(
  [getRawRehydratedPersistUser, getRawRehydratedPersistConfig],
  (persistUser, persistConfig) => !persistUser || !persistConfig,
);

export const getIsAuthenticated = createDraftSafeSelector(
  [getRawAuthenticated],
  (data) => data,
);

export const getPersistUser = createDraftSafeSelector(
  [getRawPersistUser],
  (data) => data,
);
