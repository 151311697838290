import { richWebConfig } from 'utils';
import request from 'utils/request';

export async function voteElectionPollSubsidyApi(data) {
  return request.post('poll-votes/subsidy-election', data, richWebConfig);
}

export async function getStatisticsElectionPollApi() {
  return request.get('poll-votes/subsidy-election/statistics', richWebConfig);
}
