import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { NamePath } from 'antd/lib/form/interface';
import { Rule } from 'antd/lib/form';
import FormInputStyles from './styles';

interface ContentComponentProps {
  placeholder?: string;
}

interface Props {
  name?: NamePath | string;
  label?: string;
  required?: boolean;
  messageRequire?: string;
  placeholder?: string;
  rules?: Rule[];
  ContentComponent?: React.ComponentType<ContentComponentProps>;
  dependencies?: NamePath[];
  valuePropName?: string;
  combineInputLabel?: boolean;
  formProps?: object;
  validateFirst?: boolean;
  rows?: number;
  className?: string;
  [props: string]: unknown;
}

const FormInput = ({
  name,
  label,
  required,
  messageRequire = 'error.required',
  placeholder,
  rules = [],
  ContentComponent,
  dependencies,
  valuePropName,
  combineInputLabel,
  formProps,
  validateFirst,
  ...props
}: Props) => {
  const { t } = useTranslation();
  return (
    <FormInputStyles>
      <Form.Item
        className={combineInputLabel ? 'combine-input-label' : ''}
        label={t(label)}
        name={name}
        rules={[
          {
            required,
            message: t(messageRequire),
          },
          ...rules,
        ]}
        dependencies={dependencies}
        valuePropName={valuePropName}
        validateFirst={validateFirst}
        {...formProps}
      >
        <ContentComponent placeholder={t(placeholder)} {...props} />
      </Form.Item>
    </FormInputStyles>
  );
};

FormInput.defaultProps = {
  ContentComponent: Input,
};

export default FormInput;
