import styled from 'styled-components';

export const StepTitleStyles = styled.div`
  .step-title-wrapper {
    display: flex;
    align-items: center;
  }

  .icon-wrapper {
    color: var(--primary);
    font-size: 20px;
    margin-right: 16px;
  }

  &.icon-rounded .icon-wrapper {
    width: 48px;
    height: 48px;
    min-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff0fb;
    border-radius: 50%;
  }

  .step-title {
    font-weight: 700;
    font-size: ${({ titleSize }) => titleSize || 24}px;
    line-height: 32px;
    color: #000000;
  }

  .step-desc {
    font-size: 16px;
    line-height: 32px;
    color: var(--text-secondary);
    padding: 16px 64px;
  }

  sup {
    color: red;
  }

  @media only screen and (max-width: 576px) {
    .icon-wrapper {
      display: none !important;
    }

    .step-title {
      line-height: 24px;
      font-size: 17px;
      color: #000000;
    }

    .step-desc {
      font-size: 13px;
      padding: 8px 0 8px 0;
      line-height: 22px;
    }
  }
`;
