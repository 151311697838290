import styled from 'styled-components';

export const ReviewItemStyles = styled.div`
  .date {
    color: var(--color-grey-l2);
    font-weight: 600;
  }
  .review {
    &__author {
      color: var(--text-primary);
      font-weight: 700;
      font-size: 16px;
    }

    &__type {
      color: var(--primary);
      font-weight: 700;

      .google-review {
        color: var(--secondary);
      }
    }

    &__content {
      color: var(--color-grey);
      font-size: 16px;
      line-height: 32px;
      margin-right: 40px;
    }
  }

  .dote {
    font-size: 8px;
    color: #bebebe;
    margin: 0 8px;
  }

  .review-infor {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .ant-rate-star {
    font-size: 14px !important;
  }

  .divide-line {
    margin: 32px 0 26px;
  }

  @media only screen and (max-width: 992px) {
    .review {
      &__content {
        margin-right: 0;
      }
    }
  }

  @media only screen and (min-width: 1440px) {
    .date {
      font-size: 16px;
    }
    .review {
      &__author {
        font-size: 18px;
      }

      &__type {
        font-size: 16px;
      }

      &__content {
        font-size: 18px;
      }
    }

    .ant-rate-star {
      font-size: 16px !important;
    }

    .ant-avatar {
      width: 51px !important;
      height: 51px !important;
      line-height: 51px !important;
    }
  }

  @media only screen and (max-width: 576px) {
    .date {
      font-size: 14px;
    }
    .review {
      &__author,
      &__content {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 0;
      }

      &__type {
        font-size: 14px;
      }
    }

    .dote {
      line-height: 26px;
    }

    .divide-line {
      margin: 24px 0 36px;
    }

    .ant-rate-star {
      font-size: 14px !important;
    }

    .ant-avatar {
      width: 38px !important;
      height: 38px !important;
      line-height: 38px !important;
    }
  }
`;
