export const ICTickCheck = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 1C3.245 1 1 3.245 1 6C1 8.755 3.245 11 6 11C8.755 11 11 8.755 11 6C11 3.245 8.755 1 6 1ZM8.39 4.85L5.555 7.685C5.485 7.755 5.39 7.795 5.29 7.795C5.19 7.795 5.095 7.755 5.025 7.685L3.61 6.27C3.465 6.125 3.465 5.885 3.61 5.74C3.755 5.595 3.995 5.595 4.14 5.74L5.29 6.89L7.86 4.32C8.005 4.175 8.245 4.175 8.39 4.32C8.535 4.465 8.535 4.7 8.39 4.85Z"
      fill="currentColor"
    />
  </svg>
);

export const ICUserProfile = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.10573 7.74927C8.08573 7.74927 8.0724 7.74927 8.0524 7.74927C8.01906 7.7426 7.9724 7.7426 7.9324 7.74927C5.99906 7.68927 4.53906 6.16927 4.53906 4.29594C4.53906 2.38927 6.0924 0.835938 7.99906 0.835938C9.90573 0.835938 11.4591 2.38927 11.4591 4.29594C11.4524 6.16927 9.98573 7.68927 8.12573 7.74927C8.11906 7.74927 8.1124 7.74927 8.10573 7.74927ZM7.99906 1.83594C6.64573 1.83594 5.53906 2.9426 5.53906 4.29594C5.53906 5.62927 6.57906 6.7026 7.90573 6.74927C7.93906 6.7426 8.0324 6.7426 8.11906 6.74927C9.42573 6.68927 10.4524 5.61594 10.4591 4.29594C10.4591 2.9426 9.3524 1.83594 7.99906 1.83594Z"
      fill="currentColor"
    />
    <path
      d="M8.11307 15.0307C6.80641 15.0307 5.49307 14.6974 4.49974 14.0307C3.57307 13.4174 3.06641 12.5774 3.06641 11.6641C3.06641 10.7507 3.57307 9.90406 4.49974 9.28406C6.49974 7.9574 9.73974 7.9574 11.7264 9.28406C12.6464 9.8974 13.1597 10.7374 13.1597 11.6507C13.1597 12.5641 12.6531 13.4107 11.7264 14.0307C10.7264 14.6974 9.41974 15.0307 8.11307 15.0307ZM5.05307 10.1241C4.41307 10.5507 4.06641 11.0974 4.06641 11.6707C4.06641 12.2374 4.41974 12.7841 5.05307 13.2041C6.71307 14.3174 9.51307 14.3174 11.1731 13.2041C11.8131 12.7774 12.1597 12.2307 12.1597 11.6574C12.1597 11.0907 11.8064 10.5441 11.1731 10.1241C9.51307 9.0174 6.71307 9.0174 5.05307 10.1241Z"
      fill="currentColor"
    />
  </svg>
);

export const ICBriefCase = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.6662 15.1678H5.33283C2.25283 15.1678 1.6795 13.7345 1.53283 12.3411L1.03283 7.00115C0.959501 6.30115 0.939501 5.26781 1.63283 4.49448C2.23283 3.82781 3.22617 3.50781 4.66617 3.50781H11.3328C12.7795 3.50781 13.7728 3.83448 14.3662 4.49448C15.0595 5.26781 15.0395 6.30115 14.9662 7.00781L14.4662 12.3345C14.3195 13.7345 13.7462 15.1678 10.6662 15.1678ZM4.66617 4.50115C3.5395 4.50115 2.76617 4.72115 2.37283 5.16115C2.04617 5.52115 1.9395 6.07448 2.02617 6.90115L2.52617 12.2411C2.6395 13.2945 2.92617 14.1678 5.33283 14.1678H10.6662C13.0662 14.1678 13.3595 13.2945 13.4728 12.2345L13.9728 6.90781C14.0595 6.07448 13.9528 5.52115 13.6262 5.16115C13.2328 4.72115 12.4595 4.50115 11.3328 4.50115H4.66617Z"
      fill="currentColor"
    />
    <path
      d="M10.6673 4.5026C10.394 4.5026 10.1673 4.27594 10.1673 4.0026V3.46927C10.1673 2.2826 10.1673 1.83594 8.53398 1.83594H7.46732C5.83398 1.83594 5.83398 2.2826 5.83398 3.46927V4.0026C5.83398 4.27594 5.60732 4.5026 5.33398 4.5026C5.06065 4.5026 4.83398 4.27594 4.83398 4.0026V3.46927C4.83398 2.29594 4.83398 0.835938 7.46732 0.835938H8.53398C11.1673 0.835938 11.1673 2.29594 11.1673 3.46927V4.0026C11.1673 4.27594 10.9407 4.5026 10.6673 4.5026Z"
      fill="currentColor"
    />
    <path
      d="M7.99935 11.1667C6.16602 11.1667 6.16602 10.0333 6.16602 9.35333V8.66667C6.16602 7.72667 6.39268 7.5 7.33268 7.5H8.66602C9.60602 7.5 9.83268 7.72667 9.83268 8.66667V9.33333C9.83268 10.0267 9.83268 11.1667 7.99935 11.1667ZM7.16602 8.5C7.16602 8.55333 7.16602 8.61333 7.16602 8.66667V9.35333C7.16602 10.04 7.16602 10.1667 7.99935 10.1667C8.83268 10.1667 8.83268 10.06 8.83268 9.34667V8.66667C8.83268 8.61333 8.83268 8.55333 8.83268 8.5C8.77935 8.5 8.71935 8.5 8.66602 8.5H7.33268C7.27935 8.5 7.21935 8.5 7.16602 8.5Z"
      fill="currentColor"
    />
    <path
      d="M9.33301 9.84552C9.08634 9.84552 8.86634 9.65886 8.83967 9.40552C8.80634 9.13219 8.99967 8.87886 9.27301 8.84552C11.033 8.62552 12.7197 7.95886 14.1397 6.92552C14.3597 6.75886 14.673 6.81219 14.8397 7.03886C14.9997 7.25886 14.953 7.57219 14.7263 7.73886C13.1663 8.87219 11.3263 9.59886 9.39301 9.84552C9.37301 9.84552 9.35301 9.84552 9.33301 9.84552Z"
      fill="currentColor"
    />
    <path
      d="M6.66715 9.85392C6.64715 9.85392 6.62715 9.85392 6.60715 9.85392C4.78048 9.64725 3.00048 8.98059 1.46048 7.92725C1.23382 7.77392 1.17382 7.46059 1.32715 7.23392C1.48048 7.00725 1.79382 6.94725 2.02048 7.10059C3.42715 8.06059 5.04715 8.66725 6.71381 8.86059C6.98715 8.89392 7.18715 9.14059 7.15381 9.41392C7.13381 9.66725 6.92048 9.85392 6.66715 9.85392Z"
      fill="currentColor"
    />
  </svg>
);

export const ICHat = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00622 11.3368C7.43956 11.3368 6.86622 11.1901 6.41956 10.9034L2.40622 8.28344C1.65956 7.79677 1.21289 6.97677 1.21289 6.08344C1.21289 5.19011 1.65956 4.37011 2.40622 3.88344L6.42622 1.27011C7.31956 0.690108 8.71289 0.690108 9.59956 1.27677L13.5929 3.89677C14.3329 4.38344 14.7796 5.20344 14.7796 6.09011C14.7796 6.97677 14.3329 7.79677 13.5929 8.28344L9.59956 10.9034C9.15289 11.1968 8.57956 11.3368 8.00622 11.3368ZM8.00622 1.83677C7.62622 1.83677 7.24622 1.92344 6.97289 2.11011L2.95956 4.72344C2.49289 5.03011 2.21956 5.52344 2.21956 6.08344C2.21956 6.64344 2.48622 7.13677 2.95956 7.44344L6.97289 10.0634C7.52622 10.4234 8.49956 10.4234 9.05289 10.0634L13.0462 7.44344C13.5129 7.13677 13.7796 6.64344 13.7796 6.08344C13.7796 5.52344 13.5129 5.03011 13.0462 4.72344L9.05289 2.10344C8.77289 1.93011 8.39289 1.83677 8.00622 1.83677Z"
      fill="currentColor"
    />
    <path
      d="M7.99947 15.1654C7.70614 15.1654 7.40614 15.1254 7.16614 15.0454L5.03947 14.3388C4.0328 14.0054 3.23947 12.9054 3.24614 11.8454L3.2528 8.71875C3.2528 8.44542 3.47947 8.21875 3.7528 8.21875C4.02614 8.21875 4.2528 8.44542 4.2528 8.71875L4.24614 11.8454C4.24614 12.4721 4.76614 13.1921 5.35947 13.3921L7.48614 14.0987C7.7528 14.1854 8.24614 14.1854 8.5128 14.0987L10.6395 13.3921C11.2328 13.1921 11.7528 12.4721 11.7528 11.8521V8.75875C11.7528 8.48542 11.9795 8.25875 12.2528 8.25875C12.5261 8.25875 12.7528 8.48542 12.7528 8.75875V11.8521C12.7528 12.9121 11.9661 14.0054 10.9595 14.3454L8.8328 15.0521C8.5928 15.1254 8.2928 15.1654 7.99947 15.1654Z"
      fill="currentColor"
    />
    <path
      d="M14.2676 10.5C13.9942 10.5 13.7676 10.2733 13.7676 10V6C13.7676 5.72667 13.9942 5.5 14.2676 5.5C14.5409 5.5 14.7676 5.72667 14.7676 6V10C14.7676 10.2733 14.5409 10.5 14.2676 10.5Z"
      fill="currentColor"
    />
  </svg>
);

export const ICLoading = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99805 10.25C9.34526 10.25 11.248 8.34721 11.248 6C11.248 3.65279 9.34526 1.75 6.99805 1.75C4.65084 1.75 2.74805 3.65279 2.74805 6C2.74805 8.34721 4.65084 10.25 6.99805 10.25ZM6.99805 11C9.75947 11 11.998 8.76142 11.998 6C11.998 3.23858 9.75947 1 6.99805 1C4.23662 1 1.99805 3.23858 1.99805 6C1.99805 8.76142 4.23662 11 6.99805 11Z"
      fill="#F98437"
    />
    <path
      d="M6.99805 2.5C7.61553 2.5 8.22203 2.66336 8.75598 2.9735C9.28992 3.28364 9.73232 3.72953 10.0383 4.26589C10.3442 4.80226 10.5028 5.41002 10.4979 6.02749C10.4931 6.64496 10.325 7.25015 10.0106 7.78164C9.69632 8.31314 9.24698 8.75202 8.70822 9.05374C8.16947 9.35545 7.56048 9.50927 6.94307 9.49957C6.32566 9.48987 5.72181 9.317 5.1928 8.99851C4.66379 8.68002 4.22845 8.22725 3.93097 7.68614L6.99805 6V2.5Z"
      fill="currentColor"
    />
  </svg>
);

export const ICArrowRight = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.3314 0.664062C6.98471 0.664062 0.998047 6.65073 0.998047 13.9974C0.998047 21.3441 6.98471 27.3307 14.3314 27.3307C21.678 27.3307 27.6647 21.3441 27.6647 13.9974C27.6647 6.65073 21.678 0.664062 14.3314 0.664062ZM18.0514 14.7041L13.3447 19.4107C13.1447 19.6107 12.8914 19.7041 12.638 19.7041C12.3847 19.7041 12.1314 19.6107 11.9314 19.4107C11.5447 19.0241 11.5447 18.3841 11.9314 17.9974L15.9314 13.9974L11.9314 9.9974C11.5447 9.61073 11.5447 8.97073 11.9314 8.58406C12.318 8.1974 12.958 8.1974 13.3447 8.58406L18.0514 13.2907C18.4514 13.6774 18.4514 14.3174 18.0514 14.7041Z"
      fill="currentColor"
    />
  </svg>
);

export const ICBook = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.191 2C19.28 2 21 3.78 21 6.83V17.16C21 20.26 19.28 22 16.191 22H7.81C4.77 22 3 20.26 3 17.16V6.83C3 3.78 4.77 2 7.81 2H16.191ZM8.08 15.74C7.78 15.71 7.49 15.85 7.33 16.11C7.17 16.36 7.17 16.69 7.33 16.95C7.49 17.2 7.78 17.35 8.08 17.31H15.92C16.319 17.27 16.62 16.929 16.62 16.53C16.62 16.12 16.319 15.78 15.92 15.74H8.08ZM15.92 11.179H8.08C7.649 11.179 7.3 11.53 7.3 11.96C7.3 12.39 7.649 12.74 8.08 12.74H15.92C16.35 12.74 16.7 12.39 16.7 11.96C16.7 11.53 16.35 11.179 15.92 11.179ZM11.069 6.65H8.08V6.66C7.649 6.66 7.3 7.01 7.3 7.44C7.3 7.87 7.649 8.22 8.08 8.22H11.069C11.5 8.22 11.85 7.87 11.85 7.429C11.85 7 11.5 6.65 11.069 6.65Z"
      fill="currentColor"
    />
  </svg>
);

export const ICBookEducation = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill="white" />
    <path
      d="M22.6667 11.2333V19.16C22.6667 19.8067 22.14 20.4 21.4933 20.48L21.2867 20.5067C20.1933 20.6533 18.6533 21.1067 17.4133 21.6267C16.98 21.8067 16.5 21.48 16.5 21.0067V11.7333C16.5 11.4867 16.64 11.26 16.86 11.14C18.08 10.48 19.9267 9.89335 21.18 9.78668H21.22C22.02 9.78668 22.6667 10.4333 22.6667 11.2333Z"
      fill="#87C9FB"
    />
    <path
      d="M15.14 11.14C13.92 10.48 12.0733 9.89335 10.82 9.78668H10.7733C9.97333 9.78668 9.32666 10.4333 9.32666 11.2333V19.16C9.32666 19.8067 9.85333 20.4 10.5 20.48L10.7067 20.5067C11.8 20.6533 13.34 21.1067 14.58 21.6267C15.0133 21.8067 15.4933 21.48 15.4933 21.0067V11.7333C15.4933 11.48 15.36 11.26 15.14 11.14ZM11.3333 13.16H12.8333C13.1067 13.16 13.3333 13.3867 13.3333 13.66C13.3333 13.94 13.1067 14.16 12.8333 14.16H11.3333C11.06 14.16 10.8333 13.94 10.8333 13.66C10.8333 13.3867 11.06 13.16 11.3333 13.16ZM13.3333 16.16H11.3333C11.06 16.16 10.8333 15.94 10.8333 15.66C10.8333 15.3867 11.06 15.16 11.3333 15.16H13.3333C13.6067 15.16 13.8333 15.3867 13.8333 15.66C13.8333 15.94 13.6067 16.16 13.3333 16.16Z"
      fill="#87C9FB"
    />
    <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#EBECF0" />
  </svg>
);

export const ICPointDot = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 4 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="2" cy="2" r="2" fill="#D0D5DD" />
  </svg>
);

export const ICHatEducation = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.025 13.0333C14.5833 12.6667 15.3167 13.0667 15.3167 13.7333V14.8083C15.3167 15.8667 14.4917 17 13.5 17.3333L10.8417 18.2167C10.375 18.375 9.61668 18.375 9.15835 18.2167L6.50002 17.3333C5.50002 17 4.68335 15.8667 4.68335 14.8083V13.725C4.68335 13.0667 5.41668 12.6667 5.96668 13.025L7.68335 14.1417C8.34168 14.5833 9.17502 14.8 10.0083 14.8C10.8417 14.8 11.675 14.5833 12.3333 14.1417L14.025 13.0333Z"
      fill="#DB147F"
    />
    <path
      d="M16.65 5.38336L11.6583 2.10836C10.7583 1.5167 9.27502 1.5167 8.37502 2.10836L3.35835 5.38336C1.75002 6.42503 1.75002 8.78336 3.35835 9.83336L4.69168 10.7L8.37502 13.1C9.27502 13.6917 10.7583 13.6917 11.6583 13.1L15.3167 10.7L16.4583 9.95003V12.5C16.4583 12.8417 16.7417 13.125 17.0833 13.125C17.425 13.125 17.7083 12.8417 17.7083 12.5V8.40003C18.0417 7.32503 17.7 6.07503 16.65 5.38336Z"
      fill="#DB147F"
    />
  </svg>
);

export const ICAwardTitleIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.8095 5.49V6.23L14.2695 4.18C12.9295 3.41 11.0595 3.41 9.72945 4.18L6.18945 6.24V5.49C6.18945 3.24 7.41945 2 9.66945 2H14.3295C16.5795 2 17.8095 3.24 17.8095 5.49Z"
      fill="#DB147F"
    />
    <path
      d="M17.84 7.97219L17.7 7.90219L16.34 7.12219L13.52 5.49219C12.66 4.99219 11.34 4.99219 10.48 5.49219L7.66 7.11219L6.3 7.91219L6.12 8.00219C4.37 9.18219 4.25 9.40219 4.25 11.2922V15.8122C4.25 17.7022 4.37 17.9222 6.16 19.1322L10.48 21.6222C10.91 21.8822 11.45 21.9922 12 21.9922C12.54 21.9922 13.09 21.8722 13.52 21.6222L17.88 19.1022C19.64 17.9222 19.75 17.7122 19.75 15.8122V11.2922C19.75 9.40219 19.63 9.18219 17.84 7.97219ZM14.79 13.5022L14.18 14.2522C14.08 14.3622 14.01 14.5722 14.02 14.7222L14.08 15.6822C14.12 16.2722 13.7 16.5722 13.15 16.3622L12.26 16.0022C12.12 15.9522 11.89 15.9522 11.75 16.0022L10.86 16.3522C10.31 16.5722 9.89 16.2622 9.93 15.6722L9.99 14.7122C10 14.5622 9.93 14.3522 9.83 14.2422L9.21 13.5022C8.83 13.0522 9 12.5522 9.57 12.4022L10.5 12.1622C10.65 12.1222 10.82 11.9822 10.9 11.8622L11.42 11.0622C11.74 10.5622 12.25 10.5622 12.58 11.0622L13.1 11.8622C13.18 11.9922 13.36 12.1222 13.5 12.1622L14.43 12.4022C15 12.5522 15.17 13.0522 14.79 13.5022Z"
      fill="#DB147F"
    />
  </svg>
);

export const ICAwardIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
    <path
      d="M25.8095 13.49V14.23L22.2695 12.18C20.9295 11.41 19.0595 11.41 17.7295 12.18L14.1895 14.24V13.49C14.1895 11.24 15.4195 10 17.6695 10H22.3295C24.5795 10 25.8095 11.24 25.8095 13.49Z"
      fill="#87C9FB"
    />
    <path
      d="M25.84 15.9722L25.7 15.9022L24.34 15.1222L21.52 13.4922C20.66 12.9922 19.34 12.9922 18.48 13.4922L15.66 15.1122L14.3 15.9122L14.12 16.0022C12.37 17.1822 12.25 17.4022 12.25 19.2922V23.8122C12.25 25.7022 12.37 25.9222 14.16 27.1322L18.48 29.6222C18.91 29.8822 19.45 29.9922 20 29.9922C20.54 29.9922 21.09 29.8722 21.52 29.6222L25.88 27.1022C27.64 25.9222 27.75 25.7122 27.75 23.8122V19.2922C27.75 17.4022 27.63 17.1822 25.84 15.9722ZM22.79 21.5022L22.18 22.2522C22.08 22.3622 22.01 22.5722 22.02 22.7222L22.08 23.6822C22.12 24.2722 21.7 24.5722 21.15 24.3622L20.26 24.0022C20.12 23.9522 19.89 23.9522 19.75 24.0022L18.86 24.3522C18.31 24.5722 17.89 24.2622 17.93 23.6722L17.99 22.7122C18 22.5622 17.93 22.3522 17.83 22.2422L17.21 21.5022C16.83 21.0522 17 20.5522 17.57 20.4022L18.5 20.1622C18.65 20.1222 18.82 19.9822 18.9 19.8622L19.42 19.0622C19.74 18.5622 20.25 18.5622 20.58 19.0622L21.1 19.8622C21.18 19.9922 21.36 20.1222 21.5 20.1622L22.43 20.4022C23 20.5522 23.17 21.0522 22.79 21.5022Z"
      fill="#87C9FB"
    />
    <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="#EBECF0" />
  </svg>
);

export const ICArrowLeft = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711L10.4142 12L15.7071 17.2929C16.0976 17.6834 16.0976 18.3166 15.7071 18.7071C15.3166 19.0976 14.6834 19.0976 14.2929 18.7071L8.29289 12.7071C7.90237 12.3166 7.90237 11.6834 8.29289 11.2929L14.2929 5.29289C14.6834 4.90237 15.3166 4.90237 15.7071 5.29289Z"
      fill="#38465C"
    />
  </svg>
);

export const ICArrowPrevious = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4721 3.5312C10.7324 3.79155 10.7324 4.21366 10.4721 4.47401L6.94346 8.0026L10.4721 11.5312C10.7324 11.7915 10.7324 12.2137 10.4721 12.474C10.2117 12.7344 9.7896 12.7344 9.52925 12.474L5.52925 8.47401C5.2689 8.21366 5.2689 7.79155 5.52925 7.5312L9.52925 3.5312C9.7896 3.27085 10.2117 3.27085 10.4721 3.5312Z"
      fill="#DB147F"
    />
  </svg>
);

export const ICArrowNext = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.02925 3.5312C6.2896 3.27085 6.71171 3.27085 6.97206 3.5312L10.9721 7.5312C11.2324 7.79155 11.2324 8.21366 10.9721 8.47401L6.97206 12.474C6.71171 12.7344 6.2896 12.7344 6.02925 12.474C5.7689 12.2137 5.7689 11.7915 6.02925 11.5312L9.55784 8.0026L6.02925 4.47401C5.7689 4.21366 5.7689 3.79155 6.02925 3.5312Z"
      fill="#DB147F"
    />
  </svg>
);
