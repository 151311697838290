import { createSlice } from '@reduxjs/toolkit';
import {
  getJobDetailsByPostId,
  getJobDetailsInfo,
  getWorkplaceJobDetail,
  jobSeekersApply,
} from './actions';

const initialState = {
  jobInfo: {
    data: null,
    loading: false,
    applyLoading: false,
    error: null,
  },
  workPlace: {
    data: null,
    loading: false,
  },
};

const { reducer } = createSlice({
  name: 'jobDetails',
  initialState,
  extraReducers: {
    [getJobDetailsInfo.pending]: (state) => {
      state.jobInfo.loading = true;
    },
    [getJobDetailsInfo.fulfilled]: (state, { payload }) => {
      state.jobInfo.data = payload;
      state.jobInfo.loading = false;
    },
    [getJobDetailsInfo.rejected]: (state, { payload }) => {
      state.jobInfo.error = payload || null;
      state.jobInfo.loading = false;
    },

    [getWorkplaceJobDetail.fulfilled]: (state, { payload }) => {
      state.workPlace.data = payload;
      state.workPlace.loading = false;
    },
    [getWorkplaceJobDetail.pending]: (state) => {
      state.workPlace.loading = true;
    },

    [getJobDetailsByPostId.fulfilled]: (state, { payload }) => {
      state.jobInfo.data = payload;
      state.jobInfo.loading = false;
    },

    [jobSeekersApply.pending]: (state) => {
      state.jobInfo.applyLoading = true;
    },

    [jobSeekersApply.fulfilled]: (state) => {
      state.jobInfo.applyLoading = false;
    },

    [jobSeekersApply.rejected]: (state) => {
      state.jobInfo.applyLoading = false;
    },
  },
});

export default reducer;
