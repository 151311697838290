import PropTypes from 'prop-types';
import { Avatar, Space } from 'antd';
import { KEYS_DAYS_IN_WEEK } from 'configs/localData';
import { useTranslation } from 'react-i18next';
import { RequiredDaysStyles } from './styles';

const RequiredDays = ({ value, onChange, disabled }) => {
  const { t } = useTranslation();

  const onSelectedItem = (day, isSelected) => {
    let newValue = [];
    if (isSelected) newValue = value.filter((dayItem) => dayItem !== day);
    else newValue = [...(value || []), day];
    onChange(newValue);
  };

  return (
    <RequiredDaysStyles className={disabled ? 'required-day-disabled' : ''}>
      <Space size={6} align="centre" wrap>
        {KEYS_DAYS_IN_WEEK.map((item, index) => {
          const isSelected = value?.includes(item.value);
          return (
            <Avatar
              size={40}
              key={String(index)}
              className={`day-item pointer ${
                isSelected ? 'avatar-selected' : 'avatar-none-select'
              }`}
              onClick={() => onSelectedItem(item.value, isSelected)}
            >
              {t(item.text)}
            </Avatar>
          );
        })}
      </Space>
    </RequiredDaysStyles>
  );
};

RequiredDays.propTypes = {
  value: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.func,
};

export default RequiredDays;
