import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMyJobsWithViewedTabApi,
  getMyJobsWithAppliedTabApi,
  getMyJobsWithFavoritesTabApi,
} from 'api/myJobs';

export const MODEL_NAME = 'myJobs';

export const getMyJobsWithViewedTab = createAsyncThunk(
  `${MODEL_NAME}/getMyJobsWithViewedTab`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getMyJobsWithViewedTabApi, payload);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getMyJobsWithAppliedTab = createAsyncThunk(
  `${MODEL_NAME}/getMyJobsWithAppliedTab`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getMyJobsWithAppliedTabApi,
        payload,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getMyJobsWithFavoritesTab = createAsyncThunk(
  `${MODEL_NAME}/getMyJobsWithFavoritesTab`,
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getMyJobsWithFavoritesTabApi,
        payload,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
