import { getAllCustom } from '@redux/crudCreator/actions';
import { getAllCentreComparisonsApi } from 'api/centresComparison';

export const MODEL_NAME = 'centresComparison';

export const getAllCentresComparison = getAllCustom(
  MODEL_NAME,
  getAllCentreComparisonsApi,
  null,
);
