import { Space } from 'antd';
import styled, { css } from 'styled-components';
import { respondTo } from 'utils/variables';

export const FilterReviewStyle = styled(Space)`
  overflow-x: scroll;
  width: 100%;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const SelectedItemStyle = styled.div`
  background: var(--text-white);

  ${({ checkIsSelected }) =>
    checkIsSelected &&
    css`
      border: 1px solid var(--primary);
      color: var(--primary) !important;
      font-weight: bold;

      .ic-star {
        color: var(--text-graph-yellow);
      }
    `}
  ${respondTo.xs`
    background: ${({ checkIsSelected }) =>
      checkIsSelected ? 'var(--text-white)' : 'var(--bg-grey-l1)'};
    color: var(--text-grey-l1);
  `}
`;
