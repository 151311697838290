import { getCurrentUser } from '@redux/auth/actions';
import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  postSubsidyCalculatorApi,
  saveSubsidyCalculatorApi,
} from 'api/subsidy';

export interface SubmitSubsidyCalculator {
  data: {
    income: number;
    activityLevel: string;
    postCode: string;
    suburb: string;
    state: string;
    location: Location;
    children: Children[];
  };
}

export interface Location {
  latitude: number;
  longitude: number;
}

export interface Children {
  sessionFee: number;
  age: number;
  attendingDays: number;
  serviceType: string;
  dailyHours: number;
}

export interface SaveSubsidyCalculator {
  data: {
    results: unknown;
    location: Location;
  };
  options: {
    successDescription: string;
  };
}

export const submitSubsidyCalculator = createAsyncThunk(
  'subsidy/submitSubsidyCalculator',
  async (payload: SubmitSubsidyCalculator, thunkAPI: any) => {
    try {
      const response = await apiWrapper(
        {},
        postSubsidyCalculatorApi,
        payload.data,
      );
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const saveSubsidyCalculator = createAsyncThunk(
  'subsidy/saveSubsidyCalculator',
  async (payload: SaveSubsidyCalculator, thunkAPI: any) => {
    try {
      const response = await apiWrapper(
        { isShowSuccess: true, ...payload.options },
        saveSubsidyCalculatorApi,
        payload.data,
      );
      thunkAPI.dispatch(getCurrentUser());
      return response?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
