import { useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { GENDERS } from 'configs/localData';
import { Button } from 'antd';
import { EditOutlineIcon } from '@uikit/commons/SVGIcons';
import dayjs from 'utils/dayUtils';
import { useTranslation } from 'react-i18next';

const ChildInfo = ({ item, onEditChild, onSelectedChild }) => {
  const { t } = useTranslation();

  const genderItem = GENDERS.find((gender) => gender.value === item?.gender);
  const getYesNo = useCallback(
    (value) => {
      const text = value?.trim();
      return text ? t('common.yes') : t('common.no');
    },
    [t],
  );

  const handleEditChild = (e) => {
    e.stopPropagation();
    onEditChild(item);
  };

  return (
    <ChildInfoStyles className="child-info" onClick={onSelectedChild}>
      <div className="child-info__header flex-center-between">
        <div className="child-info__name-wrapper">
          <span className="size-18 mr-10 fw-bold">
            {`${item?.firstName || ''} ${item?.lastName || ''}`}
          </span>
          {genderItem?.icon && (
            <genderItem.icon
              style={{ color: genderItem.color, fontSize: 16 }}
            />
          )}
        </div>

        <Button
          icon={<EditOutlineIcon />}
          shape="circle"
          className="btn-line-primary"
          onClick={handleEditChild}
        />
      </div>
      <div className="child-info__content">
        {item?.dateOfBirth && (
          <div className="">
            {dayjs(item?.dateOfBirth).format('DD-MM-YYYY')}
          </div>
        )}
        <div className="flex-center-between">
          <div>
            {t('children.allergies')}
            {': '}
            {getYesNo(item?.allergies)}
          </div>

          <div>
            {t('children.specialNeeds')}
            {': '}
            {getYesNo(item?.specialNeeds)}
          </div>
        </div>
      </div>
    </ChildInfoStyles>
  );
};

ChildInfo.propTypes = {
  item: PropTypes.object,
  onEditChild: PropTypes.func,
  onSelectedChild: PropTypes.func,
};

export default ChildInfo;

const ChildInfoStyles = styled.div`
  .child-info {
    &__content {
      color: var(--color-grey-l2);
      > div:not(:last-child) {
        margin-bottom: 5px;
      }
    }
  }
`;
