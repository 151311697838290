import {
  setFetchedTotalUnreadInbox,
  setFetchedUserMe,
} from '@redux/configLocal/slice';
import { getTotalUnreadEnquires } from '@redux/inboxes/action';
import {
  convertRequestParams,
  REQUEST_TYPE,
} from '@redux/crudCreator/dataProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  changePasswordApi,
  editChildrenOfUserApi,
  editUserApi,
  forgotPasswordApi,
  getChildrenOfUserApi,
  getInfoApi,
  loginApi,
  loginAppleApi,
  loginFacebookApi,
  loginGoogleApi,
  postChildrenOfUserApi,
  registerApi,
  resendCodeResetPasswordApi,
  resendVerifyEmailApi,
  resetPasswordApi,
  verifyCodeResetPasswordApi,
  verifyEmailApi,
} from 'api/auth';
import { addFavoriteCentresApi, deleteFavoriteCentresApi } from 'api/centres';
import { handlerSentryUserLogged } from 'api/sentry';
import { handleRequestError } from 'utils/requestErrorUtils';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';
import { setInitHeader } from 'utils/request';
import { removeCentreFavourites } from '@redux/centresFavourites/slice';
import { apiWrapper } from '@redux/reduxUtils';
import { deleteToken, setServerCookie, setToken } from 'utils';
import { resetAuthState } from './slice';

export const login = createAsyncThunk(
  'auth/login',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowLoading: true },
        loginApi,
        payload,
      );

      if (response.data.token) {
        setToken(response.data.token, response.data.refreshToken);
        await setServerCookie(response.data.token, response.data.refreshToken);
        handlerSentryUserLogged(response.data.data);
        thunkAPI.dispatch(
          trackingEvent({
            eventName: EVENT_TRACKING.loggedIn,
            eventData: {
              userId: response.data.data?.id,
              platform: 'email',
            },
          }),
        );
        return response.data;
      }
      return thunkAPI.rejectWithValue();
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const logout = createAsyncThunk(
  'auth/logout',
  async (payload, thunkAPI) => {
    try {
      setInitHeader(true);
      await deleteToken();
      if (thunkAPI.getState().auth.installationId) {
        thunkAPI.dispatch(deleteInstallation());
      }
      thunkAPI.dispatch(resetAuthState());
      thunkAPI.dispatch(setFetchedUserMe(false));
      thunkAPI.dispatch(setFetchedTotalUnreadInbox(false));
      return payload;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const register = createAsyncThunk(
  'auth/register',
  async (payload, thunkAPI) => {
    try {
      const { data, options } = payload;
      const response = await apiWrapper(
        { ...options, isCheckError: true },
        registerApi,
        data,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getCurrentUser = createAsyncThunk(
  'auth/getCurrentUser',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowLoading: true },
        getInfoApi,
        payload,
      );
      if (response) {
        handlerSentryUserLogged(response?.data?.data);
        thunkAPI.dispatch(setFetchedUserMe(true));
        thunkAPI.dispatch(getTotalUnreadEnquires());
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const updateCurrentUser = createAsyncThunk(
  'auth/updateCurrentUser',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccess: true,
          isShowLoading: false,
          ...payload?.options,
        },
        editUserApi,
        payload.data,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowLoading: false },
        changePasswordApi,
        payload,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowLoading: false, ...payload.options },
        forgotPasswordApi,
        payload.data,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const loginWithGoogle = createAsyncThunk(
  'auth/loginWithGoogle',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, loginGoogleApi, payload);

      if (response.data.token) {
        setToken(response.data.token, response.data.refreshToken);
        thunkAPI.dispatch(
          trackingEvent({
            eventName: EVENT_TRACKING.loggedInGoogle,
            eventData: {
              userId: response.data.data?.id,
            },
          }),
        );
        return response.data;
      }
      return thunkAPI.rejectWithValue();
    } catch (error) {
      handleRequestError();
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const loginWithFacebook = createAsyncThunk(
  'auth/loginWithFacebook',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowLoading: false },
        loginFacebookApi,
        payload,
      );

      if (response.data.token) {
        setToken(response.data.token, response.data.refreshToken);
        await setServerCookie(response.data.token, response.data.refreshToken);
        thunkAPI.dispatch(
          trackingEvent({
            eventName: EVENT_TRACKING.loggedInFacebook,
            eventData: {
              userId: response.data.data?.id,
            },
          }),
        );
        return response.data;
      }
      return thunkAPI.rejectWithValue();
    } catch (error) {
      handleRequestError();
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const loginWithApple = createAsyncThunk(
  'auth/loginWithApple',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, loginAppleApi, payload);

      if (response.data.token) {
        setToken(response.data.token, response.data.refreshToken);
        await setServerCookie(response.data.token, response.data.refreshToken);
        thunkAPI.dispatch(
          trackingEvent({
            eventName: EVENT_TRACKING.loggedInApple,
            eventData: {
              userId: response.data.data?.id,
            },
          }),
        );
        return response.data;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      handleRequestError();
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const createInstallation = createAsyncThunk(
  'auth/createInstallation',
  async (payload, thunkAPI) => {
    try {
      return payload;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const deleteInstallation = createAsyncThunk(
  'auth/deleteInstallation',
  async (payload, thunkAPI) => {
    try {
      return payload;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const verifyCodeResetPassword = createAsyncThunk(
  'auth/verifyCodeResetPassword',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowLoading: false, isCheckError: true },
        verifyCodeResetPasswordApi,
        payload,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const resendCodeResetPassword = createAsyncThunk(
  'auth/resendCodeResetPassword',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowLoading: false },
        resendCodeResetPasswordApi,
        payload,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const resetPassword = createAsyncThunk(
  'auth/resetPassword',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowLoading: false },
        resetPasswordApi,
        payload,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const verifyEmail = createAsyncThunk(
  'config/verifyEmail',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isCheckError: true },
        verifyEmailApi,
        payload,
      );
      if (response.data.token) {
        setToken(response.data.token, response.data.refreshToken);
        thunkAPI.dispatch(
          trackingEvent({
            eventName: EVENT_TRACKING.accountCreated,
            eventData: {
              userId: response.data.data?.id,
              platform: 'email',
            },
          }),
        );
        return response.data;
      }
      return thunkAPI.rejectWithValue(response);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const resendVerifyEmail = createAsyncThunk(
  'config/resendVerifyEmail',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isShowSuccess: true },
        resendVerifyEmailApi,
        payload.data,
      );
      if (response.data.token) {
        setToken(response.data.token, response.data.refreshToken);
        return response.data;
      }
      return thunkAPI.rejectWithValue();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const addFavoriteCentres = createAsyncThunk(
  'auth/addFavoriteCentres',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, addFavoriteCentresApi, payload);
      thunkAPI.dispatch(
        trackingEvent({
          eventName: EVENT_TRACKING.centreFavourited,
          eventData: { centreId: payload },
        }),
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const deleteFavoriteCentres = createAsyncThunk(
  'auth/deleteFavoriteCentres',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, deleteFavoriteCentresApi, payload);
      thunkAPI.dispatch(removeCentreFavourites(payload));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getChildrenOfUser = createAsyncThunk(
  'auth/getChildrenOfUser',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getChildrenOfUserApi, payload);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const createChildrenOfUser = createAsyncThunk(
  'auth/createChildrenOfUser',
  async ({ options, ...payload }, thunkAPI) => {
    try {
      const convertRequest = convertRequestParams(
        REQUEST_TYPE.CREATE,
        payload,
        {
          isTrimStr: true,
        },
      );

      const response = await apiWrapper(
        {
          isShowSuccess: true,
          isShowLoading: false,
          ...options,
        },
        postChildrenOfUserApi,
        convertRequest,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const editChildrenOfUser = createAsyncThunk(
  'auth/editChildrenOfUser',
  async (payload, thunkAPI) => {
    try {
      const convertRequest = convertRequestParams(
        REQUEST_TYPE.EDIT,
        payload.data,
        {
          isTrimStr: true,
        },
      );

      const response = await apiWrapper(
        {
          isShowSuccess: true,
          isShowLoading: false,
          ...payload?.options,
        },
        editChildrenOfUserApi,
        payload.id,
        convertRequest,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
