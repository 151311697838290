import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import ModalCustom from '@uikit/commons/ModalCustom';
import { Form } from 'antd';
import { RestInputContext } from '@uikit/form/RestInputContext';
import { useTranslation } from 'react-i18next';
import dayjs from 'utils/dayUtils';
import { useDispatch } from 'react-redux';
import { editChildrenOfUser } from '@redux/auth/actions';
import { formatDateOfBirthSubmit } from 'utils/dataUtils';
import ChildForm from './ChildForm';

const EditChildModal = forwardRef(({ onSuccessAction = () => null }, ref) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation(['common', 'profile']);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [child, setChild] = useState({});

  const successDescription = t('profile:profile.children.updateSuccess');
  const toggleModal = () => setVisible((v) => !v);

  const onCancel = () => {
    toggleModal();
    form.resetFields();
  };

  useImperativeHandle(
    ref,
    () => ({
      open: ({ dateOfBirth, ...data }) => {
        toggleModal();
        setChild(data);
        form.setFieldsValue({
          ...data,
          dateOfBirth: dayjs(dateOfBirth),
        });
      },
    }),
    [form],
  );

  const onOk = () => {
    if (child?.id) {
      form
        .validateFields()
        .then(({ dateOfBirth, ...values }) => {
          setLoading(true);
          dispatch(
            editChildrenOfUser({
              id: child.id,
              data: {
                ...values,
                dateOfBirth: formatDateOfBirthSubmit(dateOfBirth),
              },
              options: {
                successDescription,
              },
            }),
          )
            .then(({ payload }) => {
              setLoading(false);
              if (payload?.id) {
                onSuccessAction(payload);
                onCancel();
              }
            })
            .catch(() => {
              setLoading(false);
            });
        })
        .catch(() => null);
    }
  };

  return (
    <ModalCustom
      visible={visible}
      title={t('children.editChildModalTitle')}
      onCancel={onCancel}
      onOk={onOk}
      isScrollY
      width={807}
      okText={t('button.save')}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <RestInputContext.Provider value={{ form, record: child }}>
          <ChildForm />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
});

EditChildModal.propTypes = {
  onSuccessAction: PropTypes.func,
};

export default EditChildModal;
