import { getFeaturedImageDefault } from 'utils/dataUtils';
import KindiRating from '@uikit/commons/KindiRating';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import { CentreInfoStyles } from './styles';

const CentreInfo = ({ data }) => (
  <CentreInfoStyles>
    <div className="centre-image-wrapper">
      <img
        src={getFeaturedImageDefault(data?.images)}
        alt="centre"
        className="centre-image"
      />
      <KindiRating
        kindiCareRatingImage={data?.kindiCareRatingImage}
        kindiCareRating={data?.kindiCareRating}
        className="centre-rating"
      />
    </div>
    <div>
      <Typography.Title level={4} className="centre-name ellipsis-2-t">
        {data?.name}
      </Typography.Title>
      <Typography.Paragraph className="centre-address ellipsis-2-t">
        {data?.displayAddress}
      </Typography.Paragraph>
    </div>
  </CentreInfoStyles>
);

CentreInfo.propTypes = {
  data: PropTypes.object,
};

export default CentreInfo;
