import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const jobSearchKeywordApi = rtkCreator(
  'jobSearchKeyword',
  'job-seekers/posts/key-search',
  richWebConfigVer2,
);

export const {
  useGetAllJobSearchKeywordQuery,
  useLazyGetAllJobSearchKeywordQuery,
} = jobSearchKeywordApi;
