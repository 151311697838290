import { forwardRef, useImperativeHandle, useState } from 'react';
import { Form, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH } from 'components/forJobSeekers/constants';
import { useRouter } from 'next/router';
import { FlashFilledIcon } from '@uikit/commons/SVGIcons';
import PropTypes from 'prop-types';
import ModalJobSeekerCustom from '.';
import { UpdateProfileStyle } from './styles';

const UpdateProfileModal = (
  { firstRequirementAllowEdit, ...props } = {},
  ref,
) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [responseApply, setResponseApply] = useState();
  const onCancel = () => setVisible(false);
  const { push } = useRouter();

  const updateJobProfile = () => {
    if (firstRequirementAllowEdit && document) {
      const requirementElm = document.getElementById(firstRequirementAllowEdit);
      requirementElm?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });

      onCancel();
      return;
    }

    push({
      pathname: FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH,
      query: { t: 'jobProfile', status: 'apply-to-job' },
    });
  };

  useImperativeHandle(
    ref,
    () => ({
      toggleVisible: (response) => {
        setResponseApply(response);
        setVisible(!visible);
      },
    }),
    [visible],
  );

  return (
    <ModalJobSeekerCustom
      visible={visible}
      onCancel={onCancel}
      closable={false}
      onOk={updateJobProfile}
      okText={t('button.update')}
      isJobSeekerBlockBtn
      {...props}
    >
      <Form layout="vertical" form={form}>
        <UpdateProfileStyle className="size-16">
          <Space>
            <FlashFilledIcon />
            <span className="size-24 fw-bold">
              {t('forJobSeekers.details.applyFlow.updateProfileTitle')}
            </span>
          </Space>
          <div
            dangerouslySetInnerHTML={{
              __html: responseApply?.content?.content,
            }}
          />
        </UpdateProfileStyle>
      </Form>
    </ModalJobSeekerCustom>
  );
};

UpdateProfileModal.propTypes = {
  firstRequirementAllowEdit: PropTypes.string,
};

export default forwardRef(UpdateProfileModal);
