import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { loginWithFacebook } from '@redux/auth/actions';
import { Button, notification } from 'antd';
import { FacebookLogoIcon } from '@uikit/commons/SVGIcons';
import { getDeviceId } from 'utils/tools';
import { getUrlAfterLogin } from 'utils/dataUtils';
import { useRouter } from 'next/router';

const LoginFacebookBtn = ({ onCancel }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loadingFacebook, setLoadingFacebook] = useState(false);
  const loginByRole = useSelector((state) => state.auth.loginByRole);
  const { push, asPath } = useRouter();
  const { fetchedUserMe } = useSelector((state) => state.configLocal);
  const [isFinished, setIsFinished] = useState(false);

  const responseFacebook = async (responseFacebook) => {
    setLoadingFacebook(true);

    const response = await dispatch(
      loginWithFacebook({
        access_token: responseFacebook.accessToken,
        deviceId: getDeviceId(),
      }),
    );

    setLoadingFacebook(false);

    if (response?.payload?.token) {
      onCancel();
      setIsFinished(true);
    }
  };

  useEffect(() => {
    if (fetchedUserMe && isFinished) {
      const redirectUrl = getUrlAfterLogin(loginByRole, asPath);
      setIsFinished(false);
      if (redirectUrl) {
        push(redirectUrl);
      }
    }
  }, [fetchedUserMe, isFinished, push, loginByRole, asPath]);

  const onFailure = (payload) => {
    notification.error({
      message: t('error.title'),
      description: payload.error || t('error.description'),
    });
  };
  return (
    <FacebookLogin
      appId={process.browser && process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
      disableMobileRedirect
      render={(renderProps) => (
        <Button
          className="auth-actions__facebook"
          icon={<FacebookLogoIcon />}
          loading={loadingFacebook}
          onClick={renderProps.onClick}
          size="large"
        >
          {t('button.connectWithFacebook')}
        </Button>
      )}
      onFailure={onFailure}
      callback={responseFacebook}
    />
  );
};

LoginFacebookBtn.propTypes = {
  onCancel: PropTypes.func,
};

export default LoginFacebookBtn;
