import { useStore } from '@redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { appWithTranslation } from 'next-i18next';
import { Provider } from 'react-redux';
import Head from 'next/head';
import 'styles/globals.css';
import PropTypes from 'prop-types';
import PublicLayout from '@uikit/layouts/PublicLayout';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { setCurrentLocation } from '@redux/config/slice';
import { HOST_NAME, LOCATION_DEFAULT } from 'configs/constants';
import { logout } from '@redux/auth/actions';
import Loading from '@uikit/commons/Loading';
import RequestLocationModal from '@uikit/modals/RequestLocationModal';
import { setModalConfig } from '@redux/config/actions';
import axios from 'axios';
import TagManager from 'react-gtm-module';
import ConfigProvider from '@uikit/commons/ConfigProvider';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';
import { getDeviceId } from 'utils/tools';
import { CANONICAL_OVERRIDE_KEY } from 'configs/localData';
import { GoogleAnalytics } from 'nextjs-google-analytics';

const DefaultWrapper = (props) => <body {...props} />;

const PersitGateComponent = process.browser ? PersistGate : DefaultWrapper;

const App = ({ Component, pageProps, router }) => {
  const { initialReduxState, isCustomLayout, ...restPageProps } =
    pageProps || {};

  const { store, persistor } = useStore(initialReduxState);

  const storeRef = useRef(store);

  const getCurrentPosition = useCallback((isTrackingEvent) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const currentGeoLocation =
          storeRef.current.getState().config?.currentLocation?.geoLocation;
        if (
          currentGeoLocation?.latitude !== position.coords.latitude ||
          currentGeoLocation?.longitude !== position.coords.longitude
        ) {
          axios(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.NEXT_PUBLIC_KEY_GOOGLE_MAP}`,
          ).then((res) => {
            storeRef.current.dispatch(
              setCurrentLocation({
                formattedAddress: res.data?.results?.[0]?.formatted_address,
                geoLocation: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                },
              }),
            );
            isTrackingEvent &&
              storeRef.current.dispatch(
                trackingEvent({
                  eventName: EVENT_TRACKING.locationAccepted,
                  eventData: {
                    geolocation: {
                      latitude: position.coords.latitude,
                      longitude: position.coords.longitude,
                    },
                  },
                }),
              );
          });
        }
      },
      () => {
        storeRef.current.dispatch(setCurrentLocation(LOCATION_DEFAULT));
      },
    );
  }, []);

  useEffect(() => {
    // TODO: Refactor this
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
    });

    getDeviceId();

    if (navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        switch (result.state) {
          case 'granted':
            getCurrentPosition();
            break;

          case 'prompt':
            storeRef.current.dispatch(
              setModalConfig({ requestLocationModal: true }),
            );
            break;

          default:
            storeRef.current.dispatch(setCurrentLocation(LOCATION_DEFAULT));
        }
      });
    } else {
      getCurrentPosition(true);
    }

    typeof window !== 'undefined' &&
      window.addEventListener('refresh-token-fail', () => {
        storeRef.current.dispatch(logout());
      });
  }, [getCurrentPosition]);

  const canonicalLink = useMemo(
    () => `${HOST_NAME || ''}${router?.asPath || ''}`,
    [router?.asPath],
  );
  return (
    <>
      <Head>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin="true"
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
          rel="stylesheet"
        />
        <script
          src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_KEY_GOOGLE_MAP}&libraries=places`}
        />
        <link
          rel="canonical"
          href={canonicalLink}
          key={CANONICAL_OVERRIDE_KEY}
        />
        <script src="/appsFlyerSmartBanner.js" />
      </Head>
      <GoogleAnalytics
        trackPageViews
        gaMeasurementId={process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID}
      />
      <Provider store={store}>
        <ConfigProvider>
          <PersitGateComponent loading={null} persistor={persistor}>
            {isCustomLayout ? (
              <Component {...restPageProps} />
            ) : (
              <PublicLayout
                isStickHeader={pageProps.isStickHeader}
                isHeaderPinkBackground={pageProps.isHeaderPinkBackground}
                isShowFooter={pageProps.isShowFooter}
                hideHeader={pageProps.hideHeader}
                isShowHeader={pageProps.isShowHeader}
                hasJobSidebar={pageProps.hasJobSidebar}
                hasParentSidebar={pageProps.hasParentSidebar}
              >
                <Component {...restPageProps} />
              </PublicLayout>
            )}
            <Loading />
            {pageProps.isHideRequestLocationModal ? null : (
              <RequestLocationModal />
            )}
          </PersitGateComponent>
        </ConfigProvider>
      </Provider>
    </>
  );
};

App.propTypes = {
  Component: PropTypes.any,
  pageProps: PropTypes.object,
  router: PropTypes.object,
  host: PropTypes.string,
};

export default appWithTranslation(App);
