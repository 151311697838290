import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const jobsNearbyApi = rtkCreator(
  'jobsNearby',
  'job-seekers/screens/posts/nearby',
  richWebConfigVer2,
);
export const { useGetAllJobsNearbyQuery } = jobsNearbyApi;

export const { getAllJobsNearby } = jobsNearbyApi.endpoints;
