import { createGlobalStyle } from 'styled-components';

const AntOverwriteStyle = createGlobalStyle`
  ::-webkit-scrollbar-thumb {
    border-radius: 3px !important;
    background: var(--color-gray-neutral) !important;
  }
  ::-webkit-scrollbar-track {
    background: var(--scrollbar-track) !important;
  }
  ::-webkit-scrollbar-thumb:hover {
    border-radius: 3px !important;
    background: var(--scrollbar-thumb) !important;
  }
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background: var(--scrollbar-track) !important;
  }
  .loading {
    .ant-modal-content {
      background-color: transparent;
      box-shadow: none;
    }
  }
  .border-color-btn {
    border-color: var(--primary);
  }
  .ant-layout {
    background-color: var(--bg-content);
  }
  .ant-slider-track {
    background-color: var(--primary);
  }
  .ant-slider-handle {
    border-color: var(--primary);
  }
  .ant-slider-rail {
    background-color: #e1e1e1 !important;
    height: 5px;
  }
  .ant-slider-handle {
    width: 20px;
    height: 20px;
    background-color: var(--primary);
    border-color: var(--primary);
    transform: translate(-50%,-2px) !important;
  }

  @keyframes slideFromBottom {
    from {
      transform: translate(0px,100vh);
    }
    to {
      transform: translate(0px,0px);
    }
  }

  @keyframes noAnim {
    from {
    }
    to {
    }
  } 

  @keyframes slideFromTopToBottom {
    from {
      transform: translate(0px,0px);
    }
    to {
      transform: translate(0px,100vh);
    }
  }

  
  .ant-modal-header {
    border-bottom: 0;
    .ant-modal-title {
      font-size:24px;
      font-weight: bold;
    }
  }
  .ant-form-item-label {
    font-weight: bold;
    font-size: 14px;
  }

    .ant-divider-horizontal {
      margin:15px 0;
    }

    .ant-breadcrumb {
      font-size: var(--font-size-s);
      color: var(--text-primary);
      a {
        color: var(--text-primary);
      }
    }
  .ant-anchor {
    padding-left: 0;
  }
  .ant-anchor-ink {
    display: none;
  }
  .ant-drawer-header {
    .ant-drawer-title {
      text-transform: uppercase;
      font-weight: 700;
      font-size: 22px;
    }
  } 

  .ant-drawer-body {
    padding: 10px 0;
    .drawer-title-item {
      padding: 10px 24px;
      font-weight: 700;
    }
    .menu-item {
      margin: 0;
      font-size: var(--font-size-l);
    }
  }

  .ant-form-item-label > label.ant-form-item-required {
    display: inline;
  }

  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    display: inline-block;
    margin-right: 4px;
    color: #ff4d4f;
    font-size: 14px;
    font-family: var(--font-family);
    line-height: 1;
    content: '*';
  }
  .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    display: none !important;
    content: '' !important;
  }
  
  .ant-layout,.ant-layout-content {
    max-width: 100vw;
    overflow-x: visible; 
  }

  .ant-image-preview-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-image-preview-mask {
    background: black !important;
    
  }
  .ant-image-preview-operations {
    .anticon-rotate-left, .anticon-rotate-right {
      display: none;
    }
  }

  .ant-empty-img-default {
    max-width: 100%;
  }

  .popup-overflow-menu-button {
    .ant-menu-item:not(.ant-menu-item-selected) {
      button {
        background: #ffffff;
        border: 1px solid var(--border-color-light);
        box-sizing: border-box;
        border-radius: 32px;
        &:hover {
          border-color: var(--primary) !important;
        }
      }
      
    }
    .ant-menu-item-selected {
      background-color: #fff !important;
      button {
        background: var(--bg-pastel-pink) !important;
        border: 1px solid var(--primary) !important;
        border-radius: 32px;
        color: var(--primary) !important;
      }
    }
  }

  .popup-overflow-filter-job-menu {
    .ant-menu-item-selected {
      background-color: #fff !important;

      button {
        margin-bottom: 20px;
        background: var(--bg-pastel-green-l100) !important;
        border: 1px solid var(--text-green) !important;
        border-radius: 32px;
        color: var(--text-green) !important;
      }
    }

    .ant-menu-item {
      button:hover, button:focus {
          border-color: var(--text-green) !important;
          color: var(--text-green) !important;
      }

      &:not(.ant-menu-item-selected) button{
        background: #ffffff;
        border: 1px solid var(--border-color-light);
        box-sizing: border-box;
        border-radius: 32px;
        margin-bottom: 20px;
      }
    }
  }

  .popup-overflow-header-menu {
    .ant-menu-sub {
      padding-top: 10px;
    }
    .header-menu {
      &__item {
        display: flex;
        align-items: center;
      }
      &__item-flag {
        margin-left: 5px;
        height: 20px;
      }
    }
  }
  .modal-screen {
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export default AntOverwriteStyle;
