import {
  addFavoriteJobs,
  deleteFavoriteJobs,
} from '@redux/jobsModule/jobDetail/actions';
import { createSlice } from '@reduxjs/toolkit';
import {
  getMyJobsWithAppliedTab,
  getMyJobsWithFavoritesTab,
  getMyJobsWithViewedTab,
} from '@redux/jobsModule/myJobs/actions';
import {
  getCurrentUser,
  login,
  logout,
  updateCurrentUser,
  createInstallation,
  deleteInstallation,
  loginWithGoogle,
  loginWithFacebook,
  verifyEmail,
  loginWithApple,
  addFavoriteCentres,
  deleteFavoriteCentres,
  getChildrenOfUser,
  createChildrenOfUser,
  editChildrenOfUser,
} from './actions';

export const initialState = {
  isAuthenticated: false,
  data: {},
  role: null,
  loginError: false,
  loginSuccess: false,
  permissionData: null,
  token: null,
  isSkipLogin: false,
  refreshToken: null,
  children: {
    loading: false,
    data: [],
  },
  loginByRole: null,
};

const handleGetFavoriteJobIds = (payload) =>
  payload?.results
    ?.map((item) => (item?.heading?.topRight?.[0]?.state ? item.id : ''))
    .filter(Boolean) ?? [];

const loginSuccess = (state, { payload }) => {
  state.token = payload.token;
  state.isAuthenticated = true;
  state.data = payload.data;
  state.loginError = false;
  state.refreshToken = payload.refreshToken;
};

const { actions, reducer } = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginByRole: (state, { payload }) => {
      state.loginByRole = payload;
    },
    resetAuthState: () => initialState,
  },
  extraReducers: {
    [login.fulfilled]: (state, { payload }) => {
      state.isAuthenticated = true;
      state.data = payload.data;
      state.token = payload.token;
      state.loginError = false;
      state.loginSuccess = true;
      state.refreshToken = payload.refreshToken;
    },
    [login.rejected]: (state) => {
      state.isAuthenticated = false;
    },

    [loginWithGoogle.fulfilled]: loginSuccess,

    [loginWithFacebook.fulfilled]: loginSuccess,

    [loginWithApple.fulfilled]: loginSuccess,

    [verifyEmail.fulfilled]: loginSuccess,

    [getCurrentUser.fulfilled]: (state, { payload }) => {
      state.data = payload?.data;
      state.role = payload?.data?.role;
    },

    [logout.fulfilled]: () => {
      if (window.location.pathname !== '/') {
        window.location.href = '/';
      }
    },

    [updateCurrentUser.pending]: (state) => {
      state.loadingUser = true;
    },
    [updateCurrentUser.fulfilled]: (state, { payload }) => {
      state.data = payload?.data;
      state.loadingUser = false;
    },
    [updateCurrentUser.rejected]: (state) => {
      state.loadingUser = false;
    },

    [createInstallation.fulfilled]: (state, { payload }) => {
      state.installationId = payload.id;
    },
    [deleteInstallation.fulfilled]: (state) => {
      state.installationId = null;
    },

    [addFavoriteCentres.fulfilled]: (state, { meta: { arg: payload } }) => {
      state.data.favoriteCentreIds = [
        ...(state.data.favoriteCentreIds || []),
        payload,
      ];
    },

    [deleteFavoriteCentres.fulfilled]: (state, { meta: { arg: payload } }) => {
      state.data.favoriteCentreIds =
        state.data.favoriteCentreIds?.filter((id) => id !== payload) || [];
    },

    [getChildrenOfUser.pending]: (state) => {
      state.children.loading = true;
    },
    [getChildrenOfUser.fulfilled]: (state, { payload }) => {
      state.children.data = payload?.results;
      state.children.loading = false;
    },
    [getChildrenOfUser.rejected]: (state) => {
      state.children.loading = false;
    },

    [createChildrenOfUser.fulfilled]: (state, { payload }) => {
      state.children.data = [...(state.children.data || []), payload];
    },
    [editChildrenOfUser.fulfilled]: (state, { payload }) => {
      const matchIdx = state.children.data?.findIndex(
        (item) => item?.id === payload?.id,
      );
      if (matchIdx !== -1) {
        state.children.data[matchIdx] = payload;
      }
    },
    [addFavoriteJobs.fulfilled]: (state, { meta: { arg: payload } }) => {
      state.data.favoriteJobIds = [
        ...(state.data.favoriteJobIds || []),
        payload,
      ];
    },

    [deleteFavoriteJobs.fulfilled]: (state, { meta: { arg: payload } }) => {
      state.data.favoriteJobIds =
        state.data.favoriteJobIds?.filter((id) => id !== payload) || [];
    },

    [getMyJobsWithViewedTab.fulfilled]: (state, { payload }) => {
      state.data.favoriteJobIds = [
        ...(state.data.favoriteJobIds || []),
        ...handleGetFavoriteJobIds(payload),
      ];
    },

    [getMyJobsWithAppliedTab.fulfilled]: (state, { payload }) => {
      state.data.favoriteJobIds = [
        ...(state.data.favoriteJobIds || []),
        ...handleGetFavoriteJobIds(payload),
      ];
    },

    [getMyJobsWithFavoritesTab.fulfilled]: (state, { payload }) => {
      state.data.favoriteJobIds = [
        ...(state.data.favoriteJobIds || []),
        ...handleGetFavoriteJobIds(payload),
      ];
    },

    [addFavoriteJobs.fulfilled]: (state, { meta: { arg: payload } }) => {
      state.data.favoriteJobIds = [
        ...(state.data.favoriteJobIds || []),
        payload,
      ];
    },

    [deleteFavoriteJobs.fulfilled]: (state, { meta: { arg: payload } }) => {
      state.data.favoriteJobIds =
        state.data.favoriteJobIds?.filter((id) => id !== payload) || [];
    },
  },
});

export const { setLoginByRole, resetAuthState } = actions;

export default reducer;
