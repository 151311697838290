import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentStep: 0,
  dataApply: {},
  currentCareOptions: [],
};

const applyApplicationStep = createSlice({
  name: 'applyApplicationStep',
  initialState,
  reducers: {
    goToNextStep: (state) => {
      const newStep = state.currentStep + 1;
      if (newStep > 0 || newStep < 3) {
        state.currentStep = newStep;
      }
    },
    goToPrevStep: (state) => {
      const newStep = state.currentStep - 1;
      if (newStep > 0 || newStep < 3) {
        state.currentStep = newStep;
      }
    },
    setDataApply: (state, { payload }) => {
      state.dataApply = { ...state.dataApply, ...payload };
    },
    setCurrentOptions: (state, { payload }) => {
      state.currentCareOptions = payload;
    },
    resetDataApplyApplication: (state) => {
      state.currentStep = 0;
      state.dataApply = {};
      state.currentCareOptions = {};
    },
  },
});

export const {
  goToNextStep,
  goToPrevStep,
  resetDataApplyApplication,
  setDataApply,
  setCurrentOptions,
} = applyApplicationStep.actions;

export default applyApplicationStep.reducer;
