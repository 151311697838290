import styled from 'styled-components';

export const BoxCentreInfoStyles = styled.div`
  display: flex;
  .box-centre-info {
    &__prefix-line {
      background: var(--primary);
      border-radius: 4px;
      width: 2px;
      margin-top: 12px;
      margin-bottom: 12px;
    }
    &__content {
      background: linear-gradient(
        0deg,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 345.22%
      );
      backdrop-filter: blur(200px);
      border-radius: 16px;
      padding: 16px;
      flex: 1;
    }
    &__name {
      font-weight: bold;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 12px;
    }
  }
`;
