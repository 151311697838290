import { createSlice } from '@reduxjs/toolkit';
import { submitSubsidyCalculator } from './actions';

export const initialState = {
  error: null,
  data: {},
};

const { reducer } = createSlice({
  name: 'subsidy',
  initialState,
  reducers: {},
  extraReducers: {
    [submitSubsidyCalculator.fulfilled]: (state, { payload, meta }) => {
      state.data = payload;
      state.subsidyCalculatorInput = meta?.arg?.data;
    },
  },
});

export default reducer;
