import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { requirementsApi } from 'api/jobProfile';

export const MODEL_NAME = 'jobCampaign';

export const getJobRequirements = createAsyncThunk(
  `${MODEL_NAME}/getJobRequirement`,
  async (payload: { postId: string }, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: false,
          isShowSuccess: false,
          isCheckError: false,
        },
        requirementsApi,
        payload,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
