import { breakpoints } from 'utils/variables';

export const toRem = (input, rootSize = 16) => `${input / rootSize}rem`;

export const CSS_RESET = `
  html {
    box-sizing: border-box;
  }
  *,
  *::before,
  *::after {
   box-sizing: inherit;
  }
  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  pre,
  blockquote,
  figure,
  img,
  hr {
   margin: 0;
   padding: 0;
  }
  ul {
    margin: 0;
  }
  
  embed,
  iframe,
  img,
  object,
  video {
   display: block;
   max-width: 100%;
  }
  
  table {
   table-layout: fixed;
   width: 100%;
  }
  
  [hidden] {
   display: none;
  }
  
`;

export const CSS_COMMON = `

  /*----------------------height----------------------*/
  .h-32 {
    height: 32px;
  }
  .h-45 {
    height: 45px;
  }
  
  .h-48 {
    height: 48px !important;
  }
  .h-51 {
    height: 51px;
  }
  
  .h-12 {
    height: 3rem;
  }
  
  .h-full {
    height: 100%;
  }
  
  .h-150vh {
    height: 150vh;
  }

  .h-screen {
    height: 100vh;
  }

  .h-0 {
    height: 0;
  }

  .h-2 {
    height: 2px;
  }

  .h-64 {
    height: 64px;
  }

  .h-192 {
    height: 192px;
  }

  .h-432 {
    height: 432px;
  }
  
  .h-772 {
    height: 772px
  }

  .h-4\\/6 {
    height: 66.666667%;
  }
  
  .h-2\\/6 {
    height: 33.333333%;;
  }

  /*----------------------width----------------------*/
  .w-51 {
    width: 51px;
  }
  .w-56 {
    width: 56px;
  }
  
  .w-68 {
    width: 68px;
  }
  
  .w-100 {
    width: 100%;
  }

  
  .width-99 {
    width: 99%;
  }

  .w-132 {
    width: 132px;
  }

  .w-140 {
    width: 140px;
  }
  
  .w-120 {
    width: 120px;
  }

  .w-209 {
    width: 209px;
  }

  .w-220 {
    width: 220px
  }

  .w-101 {
    width: 101px;
  }

  .w-257 {
    width: 257px
  }

  .w-full {
    width: 100%;
  }
  .w-80p {
    width: 80%;
  }

  .w-unset {
    width: unset;
  }  
  
  .w-auto {
    width: auto;
  }

  .w-unset-important {
    width: unset !important;
  }
  
  .width-65p {
    width: 65%;
  }

  .xs\\:w-176 {
    @media only screen and (max-width: 576px) {
      width: 176px !important;
    }
  }
  
  /*----------------------max-width----------------------*/
  .max-w-full {
    max-width: 100%;
  }

  .xs\\:w-full {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      width: 100% !important;
    }
  }

  /*----------------------padding----------------------*/
  .p-5 {
    padding: 5px;
  }
  
  .pt-2 {
    padding-top: 2px;
  }
  
  .pt-5 {
    padding-top: 5px;
  }
  
  .pt-9 {
    padding-top: 9px;
  }
  
  .p-t-30 {
    padding-top: 30px;
  }

  .p-t-26 {
    padding-top: 26px;
  }

  .pt-10 {
    padding-top: 10px;
  }

  .pt-15 {
    padding-top: 15px;
  }
  .pt-16 {
    padding-top: 16px;
  }
  .pt-20 {
    padding-top: 20px;
  }
  
  .pt-24 {
    padding-top: 24px;
  }
  
  .pt-30 {
    padding-top: 30px;
  }

  .xs\\:pt-9 {
    @media only screen and (max-width: 576px) {
      padding-top: 2.25rem !important;
    }
  }

  .pt-32 {
    padding-top: 32px;
  }
  
  .pt-80 {
    padding-top: 80px;
  }
  
  .pb-6 {
    padding-bottom: 6px;
  }

  .pb-10 { 
    padding-bottom: 10px;
  }

  .pb-20 { 
    padding-bottom: 20px;
  }
  
  .pb-32 {
    padding-bottom: 32px;
  }
  
  .pb-60 {
    padding-bottom: 60px;
  }
  
  .pb-80 {
    padding-bottom: 80px;
  }
  
  .px-50 {
    padding: 50px 0;
  }

  
  .p-0 {
    padding:0px;
  }

  .p-4 {
    padding: 4px;
  }

  .p-8 {
    padding: 8px;
  }
  .p-10 {
    padding: 10px;
  }
  .p-12 {
    padding: 12px;
  }
  .p-16 {
    padding: 16px;
  }
  .p-20 {
    padding: 20px;
  }
  .pl-2	{
    padding-left:  0.5rem;
  }
  .xs\\:p-16 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      padding: 16px !important;
    }
  }

  .xs\\:p-24 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      padding: 24px !important;
    }
  }

  .xs\\:pb-9 {
    @media only screen and (max-width: 576px) {
      padding-bottom: 2.25rem !important;
    }
  }
  
  .pl-0 {
    padding-left: 0 !important;
  }
  
  .pl-80 {
    padding-left: 80px !important;
  }
  
  .pr-0 {
    padding-right: 0 !important;
  }
  
  .pr-24 {
    padding-right: 24px !important;
  }

  .lg\\:pl-0 {
    @media only screen and (max-width: 992px) {
      padding-left: 0 !important;
    }
  }
  .pl-16 {
    padding-left:  16px;
  }
  
  .pl-20	{
    padding-left:  20px;
  }
  .pl-60 {
    padding-left:  60px;
  }
  .pr-2	{
    padding-left:  0.5rem;
  }
  .pr-3	{
    padding-right: 0.75rem;
  }
  .pr-16 {
    padding-right: 16px;
  }
  .pr-20	{
    padding-right: 20px;
  }
  .pr-28	{
    padding-right: 28px;
  }
  .pr-40 {
    padding-right: 40px;
  }
  .py-3 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .py-6 {
    padding: 0 6px;
  }
  .px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .py-3\\.5	{
    padding-top: 0.875rem; 
    padding-bottom: 0.875rem; 
  }
  .xs\\:px-3 {
    @media only screen and (max-width: 576px) {
      padding-left: 0.75rem !important;
      padding-right: 0.75rem !important;
    }
  }
  .xs\\:px-6 {
    @media only screen and (max-width: 576px) {
      padding-left: 1.5rem !important;
      padding-right: 1.5rem !important;
    }
  }
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .px-6 {
    padding-left: 1.5rem;
    padding-right: 1,5rem;
  }	

  .px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .px-24 {
    padding-left: 24px;
    padding-right: 24px;
  }
  .px-14 {
    padding-left: 14px;
    padding-right: 14px;
  }
  .px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .px-80 {
    padding-left: 80px;
    padding-right: 80px;
  }
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }	
  .py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }	
  .py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }	
  .py-12 {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }	
  .py-24 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }

  /*----------------------font-weight----------------------*/
  .fw-semi-bold {
    font-weight: 600;
  }
  .fw-medium {
    font-weight: 500;
  }

  .fw-bold {
    font-weight: bold;
  }

  .fw-300 {
    font-weight: 300;
  }
  
  .fw-400 {
    font-weight: 400;
  }

  .fw-500 {
    font-weight: 500;
  }

  .fw-600 {
    font-weight: 600;
  }

  .fw-700 {
    font-weight: 700 !important;
  }
  
  .fw-800 {
    font-weight: 800;
  }
  
  .fw-normal {
    font-weight: normal;
  }
  
  
  /*----------------------font-size----------------------*/

  .size-10 {
    font-size: 10px;
  }
  
  .size-11 {
    font-size: 11px;
  }

  .size-12 {
    font-size: 12px;
  }
  
  .size-14 {
    font-size: 14px !important;
  }
  
  .size-16 {
    font-size: 16px;
  }
  
  .xxs\\:size-16 {
    @media only screen and (max-width: 375px) {
      font-size: 16px !important;
    }
  }

  .size-18 {
    font-size: 18px;
  }

  .size-20 {
    font-size: 20px;
  }

  .xs\\:size-12 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      font-size: 12px !important;
    }
  }

  .xs\\:size-14 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      font-size: 14px !important;
    }
  }
  .sm\\:size-28 {
    @media only screen and (max-width: ${breakpoints.sm}px) {
      font-size: 28px !important;
    }
  }
  .xs\\:size-16 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      font-size: 16px !important;
    }
  }
  .xs\\:size-18 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      font-size: 18px !important;
    }
  }
  .xs\\:size-20 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      font-size: 20px !important;
    }
  }

  .xs\\:size-24 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      font-size: 24px !important;
    }
  }
  .xs\\:size-60 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      font-size: 60px !important;
    }
  }

  .lg\\:size-20 {
    @media only screen and (max-width: 992px) {
      font-size: 20px !important;
    }
  }

  .lg\\:size-14 {
    @media only screen and (max-width: 992px) {
      font-size: 14px !important;
    }
  }

  .lg\\:size-12 {
    @media only screen and (max-width: 992px) {
      font-size: 12px !important;
    }
  }
  
  .size-20 {
    font-size: 20px;
  }
  
  .size-22 {
    font-size: 22px;
  }

  .size-24 {
    font-size: 24px;
  }
  
  .size-25 {
    font-size: 25px;
  }

  .size-26 {
    font-size: 26px;
  }
  .size-28 {
    font-size: 28px;
  }

  .size-30 {
    font-size: 30px;
  }
  
  .size-32 {
    font-size: 32px;
  }

  .\\!size-32 {
    font-size: 32px !important;
  }

  .size-34 {
    font-size: 34px;
  }

  .size-36 {
    font-size: 36px;
  }

  .size-38 {
    font-size: 38px;
  }

  .size-40 {
    font-size: 40px;
  }
  
  .size-48 {
    font-size: 48px;
  }
  .size-68 {
    font-size: 68px;
  }
  .size-100 {
    font-size: 100px;
  }
  
  .xs\\:size-20 {
    @media only screen and (max-width: 576px) {
      font-size: 20px !important;
    }
  }
  
  .xs\\:size-24 {
    @media only screen and (max-width: 576px) {
      font-size: 24px !important;
    }
  }
  
  .size-56 {
    font-size: 56px;
  }


  /*----------------------font-style----------------------*/
  .italic {
    font-style: italic;
  }


  /*----------------------line-height----------------------*/
  .lh-32 {
    line-height: 32px;
  }

  .lh-34 {
    line-height: 34px;
  }
  .lh-36 {
    line-height: 36px;
  }
  .lh-18 {
    line-height: 18px;
  }

  .lh-28 {
    line-height: 28px;
  }

  .lh-40 {
    line-height: 40px;
  }
  
  .lh-50 {
    line-height: 50px;
  }

  .lh-52 {
    line-height: 52px;
  }
  .lh-54 {
    line-height: 54px;
  }
  
  .lh-24 {
    line-height: 24px;
  }

  .sm\\:lh-48 {
    @media only screen and (max-width: ${breakpoints.sm}px) {
      line-height: 48px !important; 
    }
  }

  .xs\\:lh-30 {
    @media only screen and (max-width: 576px) {
      line-height: 30px !important;
    }
  }
  
  .xs\\:lh-24 {
    @media only screen and (max-width: 576px) {
      line-height: 24px !important;
    }
  }
  
  .xs\\:lh-32 {
    @media only screen and (max-width: 576px) {
      line-height: 32px !important;
    }
  }

  .lg\\:lh-32 {
    @media only screen and (max-width: 992px) {
      line-height: 32px !important;
    }
  }
  
  /*----------------------margin----------------------*/
  .mt-2 {
    margin-top: 2px;
  }

  .mt-6 {
    margin-top: 6px;
  }
  
  .mt-12 {
    margin-top: 12px;
  }
  
  .mt-35 {
    margin-top: 35px;
  }

  .mt-26 {
    margin-top: 26px;
  }

  .mt-40 {
    margin-top: 40px;
  }

  .mt-30 {
    margin-top: 30px;
  }
  .mt-20 {
    margin-top: 20px;
  }
  .mt-5 {
    margin-top: 5px;
  }
  .mt-8 {
    margin-top: 8px;
  }
  .mt-10 {
    margin-top: 10px;
  }
  
  .mt-15 {
    margin-top: 15px;
  }
  
  .mt-50 {
    margin-top: 50px;
  }

  .xs\\:mt-12 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      margin-top: 12px !important; 
    }
  }

  .xs\\:mt-16 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      margin-top: 16px !important; 
    }
  }
  .xs\\:mt-20 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      margin-top: 20px !important; 
    }
  }

  .mb-8 {
    margin-bottom: 8px;
  }

  .mb-12 {
    margin-bottom: 12px !important;
  }

  .mb-15 {
    margin-bottom: 15px;
  }

  .mb-16 {
    margin-bottom: 16px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }
  .mb-36 {
    margin-bottom: 36px;
  }

  .mb-5 {
    margin-bottom: 5px;
  }

  .mb-10 {
    margin-bottom: 10px;
  }
  .xs\\:mb-16 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      margin-bottom: 16px !important; 
    }
  }
  .xs\\:mt-40 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      margin-top: 40px !important; 
    }
  }
  .ml-auto {
    margin-left: auto; 
  }

  .ml-4 {
    margin-left: 4px; 
  }
  
  .ml-5 {
    margin-left: 5px; 
  }

  .ml-8 {
    margin-left: 8px; 
  }

  .ml-10 {
    margin-left: 10px; 
  }
  .ml-12 {
    margin-left: 12px; 
  }
  .ml-15 {
    margin-left: 15px; 
  }
  .ml-16 {
    margin-left: 16px; 
  }
  .ml-20 {
    margin-left: 20px;
  }
  .ml-32 {
    margin-left: 32px;
  }
  .ml-40 {
    margin-left: 40px;
  }

  .xs\\:mr-4 {
    @media only screen and (max-width: 576px) {
      margin-right: 4px !important; 
    }
  }

  .mr-5 {
    margin-right: 5px; 
  }

  .mr-8 {
    margin-right: 8px; 
  }

  .mr-10 {
    margin-right: 10px; 
  }
  .mr-15 {
    margin-right: 15px; 
  }
  .mr-20 {
    margin-right: 20px;
  }
  .mr-24 {
    margin-right: 24px;
  }
  .mr-26 {
    margin-right: 26px;
  }
  .mr-40 {
    margin-right: 40px;
  }

  .mr-64 {
    margin-right: 64px;
  }

  .xs\\:mr-12 {
    @media only screen and (max-width: 576px) {
      margin-right: 12px !important; 
    }
  }

  .mb-11 {
    margin-bottom: 11px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }

  .mb-20 {
    margin-bottom: 20px;
  }

  .mb-25 {
    margin-bottom: 25px;
  }

  .mt-24 {
    margin-top: 24px;
  }

  .mt-30 {
    margin-top: 30px;
  }
  
  .mt-80 {
    margin-top: 80px;
  }

  .mt-13 {
    margin-top: 13px;
  }
  .mt-16 {
    margin-top: 16px;
  }

  .mb-32 {
    margin-bottom: 32px;
  }

  .mb-30 {
    margin-bottom: 30px;
  }

  .mb-40 {
    margin-bottom: 40px;
  }
  .mb-48 {
    margin-bottom: 48px;
  }

  .mb-48 {
    margin-bottom: 48px;
  }

  .mt-3 {
    margin-top: 3px;
  }

  .mt-8 {
    margin-top: 8px;
  }

  .mt-45 {
    margin-top: 45px;
  }

  .mt-50 {
    margin-top: 50px;
  }

  .mt-60 {
    margin-top: 60px;
  }

  .mt-80 {
    margin-top: 80px;
  }
  .mt-72 {
     margin-top: 72px;
  }


  .mt-100 {
    margin-top: 100px;
  }

  .mb-50 {
    margin-bottom: 50px;
  }

  .mb-100 {
    margin-bottom: 100px;
  }

  .-m-10 {
    margin: -10px;
  }
  .m-auto {
    margin: auto;
  }

  .m-0 {
    margin: 0;
  }
  
  .m-0-auto {
    margin: 0 auto;
  }
  
  .max-w-52 {
    max-width: 52px;
  }

  .max-w-64 {
    max-width: 64px;
  }
  
  .max-w-600 {
    max-width: 600px;
  }
  
  .max-w-800 {
    max-width: 800px;
  }
  
  .max-w-1000 {
    max-width: 1000px;
  }
  
  .max-w-935 {
    max-width: 935px;
  }
  
  .max-w-screen {
    max-width: 100vw;
  }

  .max-h-52 {
    max-height: 52px;
  }
  
  .max-h-36 {
    max-height: 36px;
  }

  .max-h-65vh {
    max-height: 65vh;
  }

  .min-w-20 {
    min-width: 20px;
  }

  .min-h-120 {
    min-height: 120px !important;
  }

  .min-w-36 {
    min-width: 36px;
  }
  
  .min-w-56 {
    min-width: 56px;
  }

  .min-w-64 {
    min-width: 64px;
  }

  .xs\\:min-w-32 {
    @media only screen and (max-width: 576px) {
      min-width: 32px !important;
    }
  }

  .mx-15 {
    margin-left: 15px;
    margin-right: 15px;
  }

  .my-10 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .my-16 {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .my-33 {
    margin-top: 33px;
  }

  .my-48 {
    margin-top: 48px;
    margin-bottom: 48px;
  }

  /*----------------------display----------------------*/

  .d-block {
    display:block;
  }  
  
  .d-relative {
    display: relative;
  }
  
  .d-grid {
    display: grid;
  }

  .d-none {
    display: none;
  }
  
  .xs\\:d-none {
    @media only screen and (max-width: 576px) {
      display: none;
    }
  }

  .\\!xs\\:d-none {
    @media only screen and (max-width: 576px) {
      display: none !important;
    }
  }

  .d-inline-flex {
    display: inline-flex;
  }

  .d-inline-block {
    display: inline-block;
  }

  .d-hidden {
    visibility: hidden;
    pointer-events: none;
    *  {
      visibility: hidden;
      pointer-events: none;
    }
  }

  /*----------------------flex----------------------*/
  .d-flex {
    display: flex
  }
  .column {
    flex-direction: column;
  }

  .space-between {
    justify-content:space-between;
  }

  .flex-1 {
    flex: 1;
  }

  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-end {
    align-items:flex-end;
    justify-content:flex-end;
  }

  .flex-column {
    flex-direction:column;
  }

  .flex-row	{
    flex-direction: row;
  }

  .flex-row-reverse {
    flex-direction: row-reverse;
  }

  .flex-center-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .flex-center-end {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .flex-center-start {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .flex-end-center {
    display: flex;
    justify-content: center !important;
    align-items: flex-end !important;
  }
  
  .flex-wrap {
    flex-wrap: wrap;
  }
  
  .items-center {
    align-items: center;
  }
  
  .items-baseline {
    align-items: baseline;
  }

  .align-items-stretch {
    align-items: stretch;
  }

  .items-end {
    align-items: flex-end;
  }
  
  .items-base {
    align-items: baseline;
  }
  
  .items-start {
    align-items: flex-start;
  }

  .justify-content-between {
    justify-content: space-between;
  }

  .justify-content-center {
    justify-content: center
  }

  .justify-content-end {
    justify-content: flex-end;
  }

  .flex-auto {
    flex: 1 1 auto;
  }

  .justify-self-end	{
    justify-self: end;
  }

  .self-end {
    align-self: flex-end;
  }
  
  .order-1 {
    order: 1;
  }
  
  /*----------------------gap----------------------*/
  .gap-4 {
    gap: 4px;
  }
  
  .gap-5 {
    gap: 5px;
  }
  
  .gap-8 {
    gap: 8px;
  }
  
  .gap-10 {
    gap: 10px;
  }
  
  .gap-12 {
    gap: 12px;
  }
  
  .gap-16 {
    gap: 16px
  }
  
  .gap-20 {
    gap: 20px;
  }
  
  .gap-24 {
    gap: 24px;
  }

  /*----------------------text----------------------*/

  .text-center {
    text-align: center
  }

  .text-end {
    text-align: end
  }

  .text-left {
    text-align:left;
  }

  .text-right {
    text-align:right;
  }

  .text-justify {
    text-align: justify;
  }

  .text-underline {
    text-decoration: underline;
  }
  
  .text-uppercase {
    text-transform: uppercase;
  }

  .text-lowercase {
    text-transform: lowercase;
  }

  .text-capitalize {
    text-transform: capitalize;
  }

  .text-pre-line {
    white-space: pre-line;
  }
  
  .text-no-wrap {
  	white-space: nowrap;
  }

  .text-pre-wrap {
    white-space: pre-wrap;
  }
  .whitespace-normal {
    white-space: normal;
  }

  .break-all {
    word-break: break-all;
  }

  .hover-underline {
    &:hover {
      text-decoration: underline;
    }
  }
  
  .ellipsis-default {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ellipsis-3-t {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .ellipsis-2-t {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .ellipsis-1-t {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-word;
  }

  .ellipsis-t {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  /*----------------------position----------------------*/

  .position-relative {
    position: relative;
  }

  .position-absolute {
    position: absolute;
  }
  
  .position-fixed {
    position: fixed;
  }

  .position-sticky {
    position: sticky;
  }
  
  .top-1\\/2 {
    top: 50%;
  }

  .top-0 {
    top: 0;
  }

  .top-40 {
    top: 40px;
  }

  .top-90 {
    top: 90px;
  }

  .left-36 {
    left: 36px;
  }
  
  .top-10 {
    top: 2.5rem; 
  }
  .top-2 {
    top: 0.5rem; 
  }

  .right-0 {
    right: 0;
  }
  
  .right-12 {
    right: 12px;
  }

  .top-1\\/2 {
    top: 50%;
  }

  .left-96 {
    left: 24rem;
  }

  .left-2\\.5 {
    left: 0.625rem;
  }
  
  .bottom-0 {
    bottom: 0;
  }

  .bottom-32 {
    bottom: 32px
  }
  
  .bottom-50 {
    bottom: 50px
  }
  
  .-left-bottom-corner-20 {
    bottom: -20px;
    left: -20px;
  }
  
  .-right-25 {
    right: -25px;
  }
  
  .-top-40 {
    top: -40px;
  }
  
  /*----------------------opacity----------------------*/

  .opacity-05 {
    opacity: 0.5;
  }

  .opacity-0 {
    opacity: 0;
  }

  .opacity-1 {
    opacity: 1;
  }

  /*----------------------other----------------------*/

  .object-contain {
    object-fit: contain
  }

  .object-cover {
    object-fit: cover;
  }

  .pointer {
    cursor: pointer
  }

  .none-pointer {
    pointer-events: none;
  }

  .not-allowed {
    cursor: not-allowed;
  }    

  .break-inside-avoid {
    break-inside: avoid;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    background: rgba(255,255,255,100);
  }
  .popover-sort-enquiries {
    .ant-popover-inner-content {
      padding: 4px 0;
      border-radius: 8px;
      width: 273px;
    }
    .sort-value {
      padding: 0 16px;
      font-weight: 400;
      height: 40px;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      letter-spacing: 0.02em;
      color: #44444F;
    }
    .selected {
      background: #F7F8F9;
    }
  }
  .align-center {
    text-align: center;
  }
  .add-centre-modal {
    width: 1000px !important;
  }

/*----------------------Font Smoothing----------------------*/
  .antialiased	{
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
/*----------------------Border Radius----------------------*/
  .rounded-full {
    border-radius: 50%;
  }

  .\\!rounded-full {
    border-radius: 50% !important;
  }

  .rounded-lg	{
    border-radius: 0.5rem;
  }
  
  .rounded-6 {
    border-radius: 6px !important;
  }
  
  .rounded-20 {
    border-radius: 20px;
  }

  .rounded-24 {
    border-radius: 24px;
  }

  .rounded-64 {
    border-radius: 64px;
  }

  .rounded-1\\/2 {
    border-radius: 50% !important;
  }

  .xs\\:rounded-100 {
    @media only screen and (max-width: ${breakpoints.xs}px) {
      border-radius: 100px !important;
    }
  }

  .rounded-8 {
    border-radius: 8px
  }
  
  .rounded-12 {
    border-radius: 12px
  }
  
  .rounded-16 {
    border-radius: 16px
  }

  .xs\\:rounded-32 {
    @media only screen and (max-width: 576px) {
      border-radius: 32px;
    }
  }

  .\\!rounded-xl {
    border-radius: 0.75rem !important;
  }
  
  .border-none {
    border: none
  }
  .rounded-top-left-right-radius-8 {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

/*----------------------Background----------------------*/

.bg-transparent {
  background-color: transparent;
}
.bg-white {
  background-color: #fff;
}
.bg-gray {
  background: var(--bg-grey-l1);
}
.bg-border-light {
  background: var(--border-color-light);
}
.bg-pastel-green-l100 {
  background-color: var(--bg-pastel-green-l100);
}
.bg-green {
  background-color: var(--text-green);
}
.bg-secondary {
  background-color: var(--secondary);
}
.bg-light-pink {
  background-color: var(--color-light-pink);
}
.bg-pastel-pink {
  background-color: var(--bg-pastel-pink);
}
.bg-pink-primary {
  background-color: var(--primary);
}

/*----------------------Color----------------------*/

.text-white {
  color: #fff !important;
}

.text-success {
  color: #52c41a !important;
}

.text-error {
  color: #E22B30 !important;
}

.text-grey {
  color: var(--color-grey-l3)
}

.text-pink-primary {
  color: var(--primary);
}

.text-blue-secondary {
  color: var(--secondary);
}

.text-grey-l1 {
  color: var(--text-grey-l1);
}

.text-color-warning-default {
  color: var(--color-warning-default);
}

.text-black-neutral {
  color: var(--text-black-neutral);
}

.text-black-l8 {
  color: var(--text-black-l8);
}

.require-red {
  color: red;
}

.text-graph-yellow {
  color: var(--text-graph-yellow);
}

/*----------------------z-index----------------------*/
.z-1 {
  z-index: 1
}
.z-2 {
  z-index: 2
}
.z-100 {
  z-index: 100
}
.z-1000 {
  z-index: 1000
}

/*----------------------BorderColor----------------------*/
.border-green {
  border-color: var(--text-green);
}
.border-gray {
  border-color: var(--color-gray-neutral) !important;
}

  .border-light {
    border: 1px solid var(--border-color-light) !important;
  }

  .border-left-light {
    border-left: 1px solid var(--border-color-light);
  }

  .border-bottom-light {
    border-bottom: 1px solid var(--border-color-light);
  }

 .border-primary {
    border: 1px solid var(--primary);
 } 
  
 .border-secondary {
    border: 1px solid var(--secondary);
 }

/*----------------------Color----------------------*/
.text-gray {
  color: var(--text-gray-neutral-l400) !important;
}

.hover:text-gray {
  color: var(--text-gray-neutral-l400) !important;
}

/*----------------------Overflow----------------------*/
.overflow-scroll {
  overflow: scroll
}
.overflow-hidden {
  overflow: hidden;
}
/*----------------------Transform----------------------*/
.rotate-x-180 {
  transform: rotateX(180deg);
  transform: rotateY(10deg);
}
`;
