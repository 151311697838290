import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import LoadingStyles from './styles';

const Loading = () => {
  const router = useRouter();
  const [loading, setLoading] = useState();

  const handleRouteChange = () => {
    setLoading(true);
  };

  const handleRouteError = () => {
    setLoading(false);
  };

  const handleRouteChangeComplete = () => {
    setLoading(false);
    window &&
      window.scroll({
        top: 0,
        left: 0,
      });
    const snapView = document.getElementsByClassName('scroll-snap-container');
    if (snapView.length) {
      snapView[0].scrollTop = 10;
    }
  };

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteError);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
      router.events.off('routeChangeError', handleRouteError);
    };
  }, [router.events]);

  return (
    <LoadingStyles className={`${loading ? 'flex-center' : 'd-none'}`}>
      <div className="loading-image-wrapper">
        <img src="/images/loading.gif" alt="" />
      </div>
    </LoadingStyles>
  );
};

export default Loading;
