import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { REQUEST_TYPE } from '@redux/crudCreator/dataProvider';
import { richWebConfig } from 'utils';

export const articlesApi = rtkCreator(
  'articles',
  'articles',
  richWebConfig,
  (builder) => ({
    getArticleRandom: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_BY_ID,
        data: {
          id: 'random',
        },
      }),
      extraOptions: { maxRetries: 1 },
    }),
  }),
);

export const {
  useGetAllArticlesQuery,
  useGetByIdArticlesQuery,
  useGetArticleRandomQuery,
} = articlesApi;

export const { getByIdArticles } = articlesApi.endpoints;
