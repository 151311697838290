import { getCentreDetailsBasicInfo } from '@redux/centreDetails/actions';
import { getCentreDetailsBasicInfoSelector } from '@redux/centreDetails/selectors';
import { useGetByIdCentreDetailsServicesQuery } from '@redux/centres';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { CheckCircleOutlineIcon } from '@uikit/commons/SVGIcons';
import ApplicationInfo from '@uikit/sections/ApplicationInfo';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ContentSteps from './ContentSteps';

const Step4 = ({ centreSlug }) => {
  const { query } = useRouter();
  const { data: centreDetail } = useSelector(getCentreDetailsBasicInfoSelector);
  const dispatch = useDispatch();
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  useEffect(() => {
    if (centreSlug) {
      dispatch(
        getCentreDetailsBasicInfo({
          id: query?.id || centreSlug,
          data: currentGeoLocation,
        }),
      );
    }
  }, [centreSlug, query?.id, currentGeoLocation, dispatch]);
  const dataApply = useSelector(
    (state) => state.applyApplicationStep?.dataApply,
  );

  const { data: centreServices } = useGetByIdCentreDetailsServicesQuery({
    data: {
      id: query?.id || centreDetail?.id,
    },
  });

  return (
    <ContentSteps
      icon={<CheckCircleOutlineIcon />}
      title="applyApplications.step4.title"
      description="applyApplications.step4.desc"
    >
      <ApplicationInfo
        centre={centreDetail}
        centreServices={centreServices?.services}
        dataApply={dataApply}
        fee={
          centreDetail?.applicationFeeAmount
            ? centreDetail?.applicationFeeAmount *
              dataApply?.careOptions?.length
            : null
        }
      />
    </ContentSteps>
  );
};

Step4.propTypes = {
  centreSlug: PropTypes.string,
};

export default Step4;
