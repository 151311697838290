import request from 'utils/request';
import { showNotifyError } from 'utils/requestErrorUtils';

export async function getUrl(data) {
  return request.post('/signedUrlS3', data);
}

export async function uploadMedia(url, data) {
  return fetch(url, {
    method: 'PUT',
    body: data,
  });
}

export const customRequestUploadFile = async (
  { onSuccess, onError, file },
  onChange,
  prefixKey,
) => {
  try {
    const signUrlResponse = await getUrl({
      key: `${prefixKey || ''}${file.name}`,
      type: file.type,
    });

    const response = await uploadMedia(signUrlResponse?.data?.uploadUrl, file);

    if (response?.status === 200) {
      onSuccess(signUrlResponse?.data?.url, file);
      onChange && onChange(signUrlResponse?.data?.url);
    } else {
      onError(null);
      showNotifyError('error.errorUploadFile');
    }
  } catch (error) {
    onError(error);
    showNotifyError('error.description');
  }
};
