import PropTypes from 'prop-types';

import AuthActionsStyles from './styles';
import LoginFacebookBtn from './LoginFacebookBtn';
import LoginGoogleBtn from './LoginGoogleBtn';

const AuthActions = ({ onCancel }) => (
  <AuthActionsStyles className="auth-actions">
    <LoginFacebookBtn onCancel={onCancel} />
    <LoginGoogleBtn onCancel={onCancel} />
  </AuthActionsStyles>
);

AuthActions.propTypes = {
  onCancel: PropTypes.func,
};

export default AuthActions;
