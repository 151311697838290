import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addFavoriteJobApi,
  deleteFavoriteJobsApi,
  getJobDetailsApi,
  getWorkplaceJobDetailApi,
  getJobDetailsByPostIdApi,
  jobSeekerViewedApi,
  jobSeekersApplyApi,
  showingPopupApplyApi,
  cancelJobApplicationApi,
} from 'api/jobDetails';
import { deleteFavoriteJobsSuccess } from '@redux/jobsModule/myJobs/slice';

export const MODEL_NAME = 'jobDetails';

interface PayloadJobDetailsInfo {
  slug: string;
  data: {
    location: string;
  };
}

interface PayloadJobDetailsByPostId {
  postId: string;
  data: {
    location: string;
  };
}

interface PayloadWorkplaceJobDetails {
  id: string;
  data: {
    location: string;
  };
}

interface PayloadJobSeekerViewed {
  postId: string;
  data: {
    viewedAt: object;
  };
}

interface PayloadPopupApply {
  isShowingPopup: boolean;
  data: {
    postId: string;
  };
}

export const getJobDetailsInfo = createAsyncThunk(
  `${MODEL_NAME}/getJobDetails`,
  async ({ slug, data }: PayloadJobDetailsInfo, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: false,
          isShowSuccess: false,
          isCheckError: false,
        },
        getJobDetailsApi,
        slug,
        data,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getJobDetailsByPostId = createAsyncThunk(
  `${MODEL_NAME}/getJobDetailsByPostId`,
  async ({ postId, data }: PayloadJobDetailsByPostId, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: false,
          isShowSuccess: false,
          isCheckError: false,
        },
        getJobDetailsByPostIdApi,
        postId,
        data,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getWorkplaceJobDetail = createAsyncThunk(
  `${MODEL_NAME}/getWorkplaceJobDetail`,
  async ({ id, data }: PayloadWorkplaceJobDetails, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: false,
          isShowSuccess: false,
          isCheckError: false,
        },
        getWorkplaceJobDetailApi,
        id,
        data,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const addFavoriteJobs = createAsyncThunk(
  `${MODEL_NAME}/addFavoriteJobs`,
  async (payload, thunkAPI: any) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: false,
          isShowSuccess: false,
          isCheckError: false,
        },
        addFavoriteJobApi,
        {
          postId: payload,
        },
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const deleteFavoriteJobs = createAsyncThunk(
  `${MODEL_NAME}/deleteFavoriteJobs`,
  async (payload, thunkAPI: any) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: false,
          isShowSuccess: false,
          isCheckError: false,
        },
        deleteFavoriteJobsApi,
        payload,
      );
      thunkAPI.dispatch(deleteFavoriteJobsSuccess(payload));
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const jobSeekerViewed = createAsyncThunk(
  `${MODEL_NAME}/jobSeekerViewed`,
  async ({ postId, data }: PayloadJobSeekerViewed, thunkAPI: any) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: false,
          isShowSuccess: false,
          isCheckError: false,
        },
        jobSeekerViewedApi,
        postId,
        data,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const jobSeekersApply = createAsyncThunk(
  'applications/jobSeekersApply',
  async ({ postId }: { postId: string }, thunkApi: any) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: false,
          isShowSuccess: false,
          isCheckError: false,
        },
        jobSeekersApplyApi,
        { postId },
      );
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue();
    }
  },
);

export const showingPopupApply = createAsyncThunk(
  'applications/showingPopupApply',
  async ({ isShowingPopup, data }: PayloadPopupApply, thunkApi: any) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: false,
          isShowSuccess: false,
          isCheckError: false,
        },
        showingPopupApplyApi,
        isShowingPopup,
        data,
      );
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue();
    }
  },
);

export const cancelJobApplication = createAsyncThunk(
  'applications/cancelJobApplication',
  async (applicationId: string, thunkApi: any) => {
    try {
      const response = await apiWrapper(
        {
          isShowLoading: false,
          isShowSuccess: false,
          isCheckError: false,
        },
        cancelJobApplicationApi,
        {
          applicationId,
        },
      );
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue();
    }
  },
);
