import { createDraftSafeSelector } from '@reduxjs/toolkit';

const getRawJobDetailsBasicInfo = (state) => state.jobDetails.jobInfo;
const getRawWorkplacesJobDetails = (state) => state.jobDetails.workPlace;

export const getJobDetailsInfoSelector = createDraftSafeSelector(
  [getRawJobDetailsBasicInfo],
  (data) => data,
);
export const getWorkplacesJobDetails = createDraftSafeSelector(
  [getRawWorkplacesJobDetails],
  (data) => data,
);
