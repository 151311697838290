/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, notification, Statistic } from 'antd';
import OtpInput from 'react-otp-input';
import { useDispatch } from 'react-redux';
import { resendVerifyEmail } from '@redux/auth/actions';
import styled from 'styled-components';
import { RESET_PASSWORD_TIME } from 'configs/constants';

const { Countdown } = Statistic;

const VerifyCodeForm = ({
  emailVerify,
  resendCodeAction = resendVerifyEmail,
}) => {
  const [loadingResendCode, setLoadingResendCode] = useState(false);
  const dispatch = useDispatch();
  const [isCountdown, setIsCountdown] = useState(true);

  const { t } = useTranslation();

  const resendVerifyEmailCode = async () => {
    setLoadingResendCode(true);
    const response = await dispatch(
      resendCodeAction({
        data: {
          email: emailVerify,
        },
      }),
    );
    if (response?.payload?.success) {
      setIsCountdown(true);
      notification.success({
        message: t('success.title'),
        description: t('auth.resendCodeSuccessDesc'),
      });
    }
    setLoadingResendCode(false);
  };

  const onFinishCountdown = () => {
    setIsCountdown(false);
  };

  const countdownNumber = useMemo(
    () => Date.now() + RESET_PASSWORD_TIME * 1000,
    [isCountdown],
  );

  return (
    <VerifyCodeFormStyles>
      <Form.Item
        name="verifyEmailCode"
        rules={[
          {
            required: true,
            message: t('input.verifyEmailCode.validateMsg.required'),
          },
        ]}
      >
        <OtpInput
          numInputs={6}
          separator={<span />}
          shouldAutoFocus
          containerStyle={{
            justifyContent: 'space-between',
          }}
          inputStyle={{
            width: '64px',
            height: '64px',
            border: '1px solid var(--border-color-light)',
            borderRadius: '8px',
            fontSize: '30px',
          }}
          focusStyle={{
            border: '1px solid var(--primary)',
            outline: 'none',
            boxShadow: '0 0 0 2px rgba(171, 35, 40, 0.2)',
          }}
        />
      </Form.Item>
      {isCountdown ? (
        <div className="d-flex size-16 mt-30 text-secondary">
          <span className="mr-5">{t('auth.resendCodeCountdown.l1')}</span>
          <Countdown
            value={countdownNumber}
            format="ss[s]"
            onFinish={onFinishCountdown}
          />
          <span className="ml-5">{t('auth.resendCodeCountdown.l2')}</span>
        </div>
      ) : (
        <div className="auth__received-code mt-30 size-l d-flex items-center">
          {`${t('auth.questionReceivedCode')}`}
          <Button
            className="auth__resend-code-btn fw-bold size-l"
            type="text"
            loading={loadingResendCode}
            onClick={resendVerifyEmailCode}
          >
            {t('auth.sendItAgain')}
          </Button>
        </div>
      )}
    </VerifyCodeFormStyles>
  );
};

VerifyCodeForm.propTypes = {
  emailVerify: PropTypes.string,
  resendCodeAction: PropTypes.func,
};

export default VerifyCodeForm;

const VerifyCodeFormStyles = styled.div`
  .auth__resend-code-btn {
    padding: 0 !important;
    margin-left: 5px;
    color: var(--primary);
  }
  .ant-statistic-content {
    font-size: 16px !important;
    color: var(--text-secondary) !important;
  }
`;
