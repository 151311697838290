import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const jobsNewestApi = rtkCreator(
  'jobsNewest',
  'job-seekers/screens/posts/newest',
  richWebConfigVer2,
);

export const { useGetAllJobsNewestQuery } = jobsNewestApi;

export const { getAllJobsNewest } = jobsNewestApi.endpoints;
