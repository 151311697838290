import { richWebConfigVer2 } from 'utils';
import request from 'utils/request';

export async function getJobMailboxInboxesApi({ page, size }) {
  return request.get(
    `/job-seekers/chatboxes?page=${page}&size=${size}`,
    richWebConfigVer2,
  );
}

export async function getJobMailboxMessagesApi(params) {
  return request.get(`/job-seekers/chat-messages`, {
    params,
    ...richWebConfigVer2,
  });
}

export async function sendJobMailboxMessageApi({
  chatBoxId,
  content,
  files,
} = {}) {
  return request.post(
    '/job-seekers/chat-messages',
    { chatBoxId, content, files },
    richWebConfigVer2,
  );
}

export async function getJobDetailApi({ postId } = {}) {
  return request.get(
    `job-seekers/posts/details?postId=${postId}`,
    richWebConfigVer2,
  );
}

export async function deleteJobMailboxApi(chatBoxId) {
  return request.delete(
    `job-seekers/chatboxes?chatBoxId=${chatBoxId}`,
    richWebConfigVer2,
  );
}
