import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
} from '@redux/crudCreator/utils';
import { createSlice } from '@reduxjs/toolkit';
import { uniq } from 'lodash-es';
import { getAllFavoriteCentresComparison } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
  favouriteCentres: [],
  idsDeleted: [],
};

const { actions, reducer } = createSlice({
  name: 'centres',
  initialState,
  reducers: {
    saveFavouriteCentresSelected: (state, { payload }) => {
      state.favouriteCentres = payload;
    },
    addFavouriteCentre: (state, { payload }) => {
      state.favouriteCentres = [payload, ...state.favouriteCentres];
      state.idsDeleted = state.idsDeleted?.filter((id) => id !== payload?.id);
    },
    removeItemInFavoriteList: (state, { payload }) => {
      state.favouriteCentres = state.favouriteCentres.filter(
        (item) => item?.id !== payload,
      );
    },
    saveIdsFavorite: (state, { payload }) => {
      state.idsDeleted = uniq([payload, ...state.idsDeleted]);
    },
    clearAllFavouriteCentres: (state) => {
      state.favouriteCentres = [];
    },
    clearListIdsDeleted: (state) => {
      state.idsDeleted = [];
    },
  },
  extraReducers: {
    [getAllFavoriteCentresComparison.pending]: getAllCustomPending,
    [getAllFavoriteCentresComparison.fulfilled]: getAllCustomSuccess,
    [getAllFavoriteCentresComparison.rejected]: getAllCustomFail,
  },
});

export const {
  saveFavouriteCentresSelected,
  removeItemInFavoriteList,
  clearAllFavouriteCentres,
  addFavouriteCentre,
  clearListIdsDeleted,
  saveIdsFavorite,
} = actions;

export default reducer;
