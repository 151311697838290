import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { APPLICATION_PATH } from 'configs/applications';
import PropTypes, { object } from 'prop-types';
import { EVENT_TRACKING } from 'configs/eventTracking';
import { trackingEvent } from '@redux/eventTracking/actions';
import { getJobDetailsInfo } from '@redux/jobsModule/jobDetail/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { isEmpty } from 'lodash-es';
import FormShowSuccess from './FormShowSuccess/index';
import ModalJobSeekerCustom from './index';

const SuccessfullyModal = ({ successfullyModal, setSuccessfullyModal }) => {
  const { t } = useTranslation();
  const { push, query } = useRouter();
  const dispatch = useDispatch();
  const currentGeoLocation = useSelector(getCurrentGeoLocation);
  if (!successfullyModal?.inVisible || isEmpty(successfullyModal?.results)) {
    return null;
  }

  const handleViewJobDetails = () => {
    setSuccessfullyModal({ ...successfullyModal, inVisible: false });
    dispatch(
      getJobDetailsInfo({
        slug: query?.jobSlug,
        data: {
          location: currentGeoLocation,
        },
      }),
    );
  };

  const onViewMyApplication = () => {
    setSuccessfullyModal({ ...successfullyModal, inVisible: false });
    const applicationId =
      successfullyModal?.results?.buttons[0]?.action?.params?.applicationId;
    if (applicationId) {
      push(`${APPLICATION_PATH.DETAIL}/${applicationId}`);
      dispatch(
        trackingEvent({
          eventName: EVENT_TRACKING.applicationDetailsViewed,
          eventData: { postId: successfullyModal?.results?.postId },
        }),
      );
    }
  };

  return (
    <ModalJobSeekerCustom
      onOk={onViewMyApplication}
      visible={successfullyModal?.inVisible}
      onCancel={handleViewJobDetails}
      okText={t('button.viewMyApplication')}
      cancelText={t('button.viewJobDetails')}
      isJobSeekerBlockBtn
    >
      <FormShowSuccess
        organizationImageUrl={successfullyModal?.results?.organizationImageUrl}
        seekerImageUrl={successfullyModal?.results?.seekerImageUrl}
      />
    </ModalJobSeekerCustom>
  );
};

SuccessfullyModal.propTypes = {
  setSuccessfullyModal: PropTypes.func,
  successfullyModal: object,
};

export default SuccessfullyModal;
