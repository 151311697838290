import { Col, Row } from 'antd';
import FormInput from '@uikit/form/FormInput';
import { useTranslation } from 'react-i18next';
import FormDatePicker from '@uikit/form/FormDatePicker';
import dayjs from 'utils/dayUtils';
import FormSelect from '@uikit/form/FormSelect';
import { GENDERS } from 'configs/localData';
import FormUploadAvatar from '@uikit/form/FormUploadAvatar';
import { getFirstOrLastNameRules, validateRegex } from 'utils/validate';

const ChildForm = () => {
  const { t } = useTranslation();
  const disabledDateOfBirth = (current) => {
    if (!current) return false;
    const futureMoment = dayjs().add(10, 'months');
    if (dayjs(current).isAfter(futureMoment, 'day')) return true;

    const pastMoment = dayjs().subtract(18, 'years');
    if (dayjs(current).isBefore(pastMoment, 'day')) return true;
    return false;
  };

  return (
    <div>
      <Row gutter={16}>
        <Col span={24}>
          <FormUploadAvatar className="flex-center" name="avatar" />
        </Col>
        <Col lg={12} md={12} xs={24}>
          <FormInput
            required
            name="firstName"
            label="input.firstName.label"
            messageRequire="input.firstName.validateMsg.required"
            rules={getFirstOrLastNameRules('firstName')}
            placeholder="input.firstName.placeholder"
          />
        </Col>
        <Col lg={12} md={12} xs={24}>
          <FormInput
            required
            name="lastName"
            label="input.lastName.label"
            messageRequire="input.lastName.validateMsg.required"
            rules={getFirstOrLastNameRules('lastName')}
            placeholder="input.lastName.placeholder"
          />
        </Col>
        <Col lg={12} md={12} xs={24}>
          <FormSelect
            required
            label="input.gender.label"
            name="gender"
            resourceData={GENDERS}
            valueProp="value"
            titleProp="textChild"
            formatText={(name) => t(name)}
          />
        </Col>

        <Col lg={12} md={12} xs={24}>
          <FormDatePicker
            required
            name="dateOfBirth"
            label="input.dateOfBirth.label"
            placeholder="input.dateOfBirth.placeholder"
            disabledDate={disabledDateOfBirth}
          />
        </Col>

        <Col lg={12} md={12} xs={24}>
          <FormInput
            name="centrelinkCRN"
            label="input.centrelinkCRN.label"
            placeholder="input.centrelinkCRN.placeholder"
            rules={[
              {
                pattern: validateRegex.centreLinkCRN,
                message: t('input.centrelinkCRN.validateMsg.invalid'),
              },
            ]}
          />
        </Col>

        <Col lg={12} md={12} xs={24}>
          <FormInput
            name="allergies"
            label="input.allergiesForChild.label"
            rules={[
              {
                whitespace: true,
                message: t('input.empty'),
              },
            ]}
            placeholder="input.allergiesForChild.placeholder"
          />
        </Col>

        <Col span={24}>
          <FormInput
            name="specialNeeds"
            label="input.specialNeedForChild.label"
            rules={[
              {
                whitespace: true,
                message: t('input.empty'),
              },
            ]}
            placeholder="input.specialNeedForChild.placeholder"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ChildForm;
