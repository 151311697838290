import { Avatar, Rate, Typography, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatDate, roundHalf } from 'utils/textUtils';
import {
  CaretDownFilled,
  CaretUpFilled,
  UserOutlined,
} from '@ant-design/icons';
import { isEmpty } from 'lodash-es';
import { useState } from 'react';
import RepliesItem from 'components/centres/Details/mobile/ParentReviews/RepliesItem';
import { ReviewsItem } from 'types/parentReview';
import { ReviewItemStyles } from './styles';

interface Props {
  item?: ReviewsItem;
  centreName?: string;
}

const ReviewItem = ({ item, centreName }: Props) => {
  const { t } = useTranslation();

  const [isShowReplyReview, setIsShowReplyReview] = useState(false);

  const handleShowReply = () => {
    setIsShowReplyReview(!isShowReplyReview);
  };

  return (
    <ReviewItemStyles>
      <div className="d-flex items-center">
        <a href={item?.authorUrl?.url} target="_blank" rel="noreferrer">
          <Avatar
            src={item?.avatar?.url}
            icon={!item?.avatar?.url ? <UserOutlined /> : null}
            size={48}
            alt="author-img"
          />
        </a>
        <div className="ml-15">
          <div className="review-infor">
            <a
              href={item?.authorUrl?.url}
              target="_blank"
              rel="noreferrer"
              className="review__author"
            >
              {item?.authorName}
            </a>
            <span className="dote">●</span>
            <Rate allowHalf disabled value={roundHalf(item?.rating)} />
          </div>
          <div className="review__meta">
            <span className="review__type">
              {item?.isInternalRating ? (
                t('reviews.kindiReview')
              ) : (
                <a
                  href={item?.metadata?.review_link}
                  target="_blank"
                  rel="noreferrer"
                  className="google-review"
                >
                  {t('reviews.googleReview')}
                </a>
              )}
            </span>
            <span className="ml-10 date">
              {formatDate(item?.reviewedAt, 'MMMM DD, YYYY')}
            </span>
          </div>
        </div>
      </div>
      <Typography.Paragraph className="mt-15 mb-15 review__content">
        {item?.comment}
      </Typography.Paragraph>
      {!isEmpty(item?.replies) && (
        <>
          <div
            className="d-flex items-center text-pink-primary mt-12"
            onClick={handleShowReply}
            role="presentation"
          >
            {isShowReplyReview ? <CaretUpFilled /> : <CaretDownFilled />}
            <div className="size-14 pointer fw-bold lh-24">{`${
              item?.totalReplies
            } ${
              Number(item?.totalReplies) === 1
                ? t('text.reply')
                : t('text.replies')
            }`}</div>
          </div>
          {isShowReplyReview && (
            <div className="border-left-light">
              <div className="ml-16">
                {item?.replies?.map((replyItem) => (
                  <RepliesItem replyItem={replyItem} centreName={centreName} />
                ))}
              </div>
            </div>
          )}
        </>
      )}
      <Divider className="divide-line" />
    </ReviewItemStyles>
  );
};

export default ReviewItem;
