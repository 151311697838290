import {
  convertRequestParams,
  REQUEST_TYPE,
} from '@redux/crudCreator/dataProvider';
import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  applyApplicationApi,
  cancelApplicationApi,
  getAllApplicationsApi,
  getByIdApplicationsApi,
} from 'api/applications';
import { i18n } from 'next-i18next';

export interface ApplicationPayload {
  data?: {
    filter: object;
    limit: number;
    offset: number;
  };
}

export const getApplicationsBoard = createAsyncThunk(
  'applications/getApplicationsBoard',
  async (payload: ApplicationPayload, thunkApi) => {
    try {
      const convertRequest = convertRequestParams(
        REQUEST_TYPE.GET_ALL,
        payload.data,
      );
      const response = await apiWrapper(
        {},
        getAllApplicationsApi,
        convertRequest,
      );

      return { data: response?.data, success: true };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const applyApplication = createAsyncThunk(
  'applications/applyApplication',
  async (payload: ApplicationPayload, thunkApi) => {
    try {
      const response = await apiWrapper({}, applyApplicationApi, payload.data);

      return { data: response?.data, success: true };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const cancelApplication = createAsyncThunk(
  'applications/cancelApplication',
  async (id: string, thunkApi) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccess: true,
          successDescription: i18n.t(
            'applications.applicationDetails.cancelApplicationSuccess',
          ),
        },
        cancelApplicationApi,
        id,
      );

      return { data: response?.data, success: true };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getByIdApplications = createAsyncThunk(
  'applications/getByIdApplications',
  async (id: string, thunkApi) => {
    try {
      const response = await apiWrapper({}, getByIdApplicationsApi, id);

      return { data: response?.data, success: true };
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
