import {
  configureStore,
  ThunkAction,
  Action,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { useMemo } from 'react';
import {
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import { isEmpty, omit } from 'lodash-es';
import { removeUndefined } from 'utils/tools';
import {
  jobFeaturedBrandLogosApi,
  jobForSeekersApi,
} from '@redux/jobsModule/jobSeeker';
import {
  getPrivacyPolicyApi,
  getTermsConditionsApi,
} from '@redux/legalDocument';
import { setInitHeaderSSR } from 'utils/request';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import { generateReduxPersist } from './persist';
import auth from './auth/slice';
import config from './config/slice';
import centres from './centres/slice';
import centreServices from './centreServices/slice';
import centreDetails from './centreDetails/slice';
import statistics from './statistics/slice';
import configLocal from './configLocal/slice';
import inboxes from './inboxes/slice';
import configSubsidy from './configSubsidy/slice';
import subsidy from './subsidy/slice';
import messages from './messages/slice';
import pollVotes from './pollVotes/slice';
import applyApplicationStep from './applyApplicationStep/slice';
import centresCompare from './centresCompare/slice';
import centresComparison from './centresComparison/slice';
import centresFavoriteComparison from './centresFavoriteComparison/slice';
import myJobs from './jobsModule/myJobs/slice';
import jobApplication from './jobsModule/jobApplication/slice';
import jobPosts from './jobsModule/jobPosts/slice';
import jobDetails from './jobsModule/jobDetail/slice';
import jobMailbox from './jobsModule/jobMailbox/slice';
import centresFavourites from './centresFavourites/slice';
import jobCompare from './jobsModule/jobCompare/slice';
import jobRequestParams from './jobsModule/jobRequestParams/slice';

import { centresNearbyApi } from './centresNearby';
import { centresContactedApi } from './centresContacted';
import { centresFavouritesApi } from './centresFavourites';
import { centresPopularApi } from './centresPopular';
import { centresRecentlyApi } from './centresRecently';
import { centresTopPicksApi } from './centresTopPicks';
import { centresWellKnownBrandsApi } from './centresWellKnownBrands';
import { centresRecommendServicesApi } from './centresRecommendServices';
import { centresRecentSearchesApi } from './centresRecentSearches';
import { centresSuggestionApi } from './centresSuggestion';
import { centresFeaturedApi } from './centresFeatured';
import { centresNearbyPopularApi } from './centresNearbyPopular';
import { centresApi } from './centres';
import { brandsApi } from './brands';
import { statesApi } from './states';
import { articlesApi } from './articles';
import { residentialApi } from './residential';
import { citiesApi } from './cities';
import { crmPackagesApi } from './crmPackages';
import { centresClaimsApi } from './centresClaims';
import { inboxesApi } from './inboxes';
import { categoriesApi } from './categories';
import { parentActivityLevelApi } from './parentActivityLevel';
import { jobProfileApi } from './jobsModule/jobProfile';
import { jobPostsApi } from './jobsModule/jobList';
import { jobsNearbyApi } from './jobsModule/jobList/jobsNearby';
import { jobsNewestApi } from './jobsModule/jobList/jobsNewest';
import { jobsFeaturedApi } from './jobsModule/jobList/jobsFeatured';
import { brandsFeaturedApi } from './jobsModule/jobList/brandsFeatured';
import { companiesFeaturedApi } from './jobsModule/jobList/companiesFeatured';
import { organizationsDiscountApi } from './jobsModule/jobList/organizationsDiscount';
import { jobArticlesApi } from './jobsModule/jobArticles';
import { organizationsApi } from './jobsModule/jobList/organizations';
import { jobMatchesApi } from './jobsModule/jobMatches';
import { jobSearchKeywordApi } from './jobsModule/jobSearchKeyword';
import {
  jobSearchSuggestionApi,
  searchOrganizationsSuggestionsApi,
} from './jobsModule/jobSearchSuggestion';
import { fieldOfStudyApi } from './jobsModule/fieldOfStudy';
import { jobRelatedArticlesApi } from './jobsModule/jobRelatedArticles';
import { baseApi } from './@rtkQuery/baseApi';
import './@rtkQuery/enhanceGeneratedApi';

let store;

const initialState = {};

function initStore(preloadedState = initialState) {
  const _store = configureStore({
    reducer: {
      // Add the generated reducer as a specific top-level slice
      config: persistReducer(generateReduxPersist('config'), config),
      auth: persistReducer(generateReduxPersist('auth'), auth),
      configSubsidy: persistReducer(
        generateReduxPersist('configSubsidy'),
        configSubsidy,
      ),
      centres,
      centreServices,
      centreDetails,
      jobDetails,
      statistics: persistReducer(
        generateReduxPersist('statistics'),
        statistics,
      ),
      configLocal,
      inboxes: persistReducer(generateReduxPersist('inboxes'), inboxes),
      subsidy,
      messages: persistReducer(generateReduxPersist('messages'), messages),
      centresCompare: persistReducer(
        generateReduxPersist('centresCompare'),
        centresCompare,
      ),
      centresComparison,
      centresFavoriteComparison,
      pollVotes,
      applyApplicationStep,
      myJobs,
      jobApplication,
      jobPosts,
      jobMailbox,
      jobCompare: persistReducer(
        generateReduxPersist('jobCompare'),
        jobCompare,
      ),
      jobRequestParams: persistReducer(
        generateReduxPersist('jobRequestParams'),
        jobRequestParams,
      ),
      centresFavourites,
      [baseApi.reducerPath]: baseApi.reducer,
      [centresNearbyApi.reducerPath]: centresNearbyApi.reducer,
      [centresContactedApi.reducerPath]: centresContactedApi.reducer,
      [centresFavouritesApi.reducerPath]: centresFavouritesApi.reducer,
      [centresPopularApi.reducerPath]: centresPopularApi.reducer,
      [centresRecentlyApi.reducerPath]: centresRecentlyApi.reducer,
      [centresTopPicksApi.reducerPath]: centresTopPicksApi.reducer,
      [centresWellKnownBrandsApi.reducerPath]:
        centresWellKnownBrandsApi.reducer,
      [centresRecentSearchesApi.reducerPath]: centresRecentSearchesApi.reducer,
      [centresRecommendServicesApi.reducerPath]:
        centresRecommendServicesApi.reducer,
      [centresSuggestionApi.reducerPath]: centresSuggestionApi.reducer,
      [centresFeaturedApi.reducerPath]: centresFeaturedApi.reducer,
      [centresNearbyPopularApi.reducerPath]: centresNearbyPopularApi.reducer,
      [centresApi.reducerPath]: centresApi.reducer,
      [brandsApi.reducerPath]: brandsApi.reducer,
      [statesApi.reducerPath]: statesApi.reducer,
      [citiesApi.reducerPath]: citiesApi.reducer,
      [articlesApi.reducerPath]: articlesApi.reducer,
      [residentialApi.reducerPath]: residentialApi.reducer,
      [crmPackagesApi.reducerPath]: crmPackagesApi.reducer,
      [centresClaimsApi.reducerPath]: centresClaimsApi.reducer,
      [inboxesApi.reducerPath]: inboxesApi.reducer,
      [categoriesApi.reducerPath]: categoriesApi.reducer,
      [parentActivityLevelApi.reducerPath]: parentActivityLevelApi.reducer,
      [jobProfileApi.reducerPath]: jobProfileApi.reducer,
      [jobForSeekersApi.reducerPath]: jobForSeekersApi.reducer,
      [jobPostsApi.reducerPath]: jobPostsApi.reducer,
      [jobsNearbyApi.reducerPath]: jobsNearbyApi.reducer,
      [jobsNewestApi.reducerPath]: jobsNewestApi.reducer,
      [jobsFeaturedApi.reducerPath]: jobsFeaturedApi.reducer,
      [brandsFeaturedApi.reducerPath]: brandsFeaturedApi.reducer,
      [companiesFeaturedApi.reducerPath]: companiesFeaturedApi.reducer,
      [organizationsDiscountApi.reducerPath]: organizationsDiscountApi.reducer,
      [jobArticlesApi.reducerPath]: jobArticlesApi.reducer,
      [organizationsApi.reducerPath]: organizationsApi.reducer,
      [jobMatchesApi.reducerPath]: jobMatchesApi.reducer,
      [jobSearchKeywordApi.reducerPath]: jobSearchKeywordApi.reducer,
      [jobSearchSuggestionApi.reducerPath]: jobSearchSuggestionApi.reducer,
      [searchOrganizationsSuggestionsApi.reducerPath]:
        searchOrganizationsSuggestionsApi.reducer,
      [jobFeaturedBrandLogosApi.reducerPath]: jobFeaturedBrandLogosApi.reducer,
      [getPrivacyPolicyApi.reducerPath]: getPrivacyPolicyApi.reducer,
      [getTermsConditionsApi.reducerPath]: getTermsConditionsApi.reducer,
      [fieldOfStudyApi.reducerPath]: fieldOfStudyApi.reducer,
      [jobRelatedArticlesApi.reducerPath]: jobRelatedArticlesApi.reducer,
    },
    preloadedState,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(
        baseApi.middleware,
        jobProfileApi.middleware,
        jobForSeekersApi.middleware,
        jobPostsApi.middleware,
        jobsNearbyApi.middleware,
        jobsNewestApi.middleware,
        jobsFeaturedApi.middleware,
        brandsFeaturedApi.middleware,
        companiesFeaturedApi.middleware,
        organizationsDiscountApi.middleware,
        jobArticlesApi.middleware,
        organizationsApi.middleware,
        jobSearchKeywordApi.middleware,
        jobSearchSuggestionApi.middleware,
        searchOrganizationsSuggestionsApi.middleware,
        jobFeaturedBrandLogosApi.middleware,
        jobRelatedArticlesApi.middleware,
        fieldOfStudyApi.middleware,
        jobRelatedArticlesApi.middleware,
      ) as any,
  });

  setupListeners(_store.dispatch);

  return _store;
}

export const initializeStore = (preloadedState?: object) => {
  let _store = store ?? initStore(preloadedState);

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    _store = initStore({
      ...store.getState(),
      ...preloadedState,
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
};

export function useStore(initialState) {
  const store = useMemo(() => initializeStore(initialState), [initialState]);
  const persistor = persistStore(store);
  return {
    store,
    persistor,
  };
}

export function getStateChangedOnServer(state) {
  if (typeof state === 'undefined') return null;
  if (Array.isArray(state)) return state.map(removeUndefined);
  if (typeof state === 'object' && state !== null) {
    return Object.entries<{ config: object }>(state).reduce(
      (acc, [key, value]) => {
        if (key === 'configLocal') return acc;
        if (value?.config) {
          const restValue = omit(value, 'config');
          const isAllEmpty = Object.values(restValue)?.every((item) =>
            isEmpty(item),
          );
          if (isAllEmpty) return acc;
          return {
            ...acc,
            [key]: value,
          };
        }
        return {
          ...acc,
          [key]: value,
        };
      },
      {},
    );
  }

  return state;
}

export const getServerSidePropsGenerator = (asyncFunc) => async (ctx) => {
  const store = initializeStore();
  const isIsomorphic = ctx.req.headers['sec-fetch-mode'] === 'cors';
  const { token } = ctx.req.cookies;
  setInitHeaderSSR(token);

  const { notFound, redirect, ...extraSSRProps } =
    (await asyncFunc(ctx, store, isIsomorphic)) || {};

  return {
    notFound,
    redirect,
    props: {
      initialReduxState: getStateChangedOnServer(store.getState()),
      ...(extraSSRProps || {}),
      host: ctx?.req?.headers?.host,
    },
  };
};

export type AppStore = ReturnType<typeof initStore>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppDispatch = ThunkDispatch<AppState, unknown, Action>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action
>;
