import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const jobProfileApi = rtkCreator(
  'jobProfile',
  'job-seekers/me',
  richWebConfigVer2,
);

export const {
  useGetAllJobProfileQuery,
  useCreateJobProfileMutation,
  useDelJobProfileMutation,
  useEditJobProfileMutation,
  useGetByIdJobProfileQuery,
  useLazyGetAllJobProfileQuery,
  useLazyGetByIdJobProfileQuery,
  usePatchJobProfileMutation,
} = jobProfileApi;
