import PropTypes from 'prop-types';
import { Form } from 'antd';
import ChildrenForm from './ChildrenForm';

const ChildItemBox = ({
  field,
  centreServices,
  parentName,
  onEditChild,
  isApplication,
}) => {
  const { name } = field || { name: 0 };

  return (
    <Form.Item
      shouldUpdate={(p, c) =>
        p?.[parentName]?.[name] !== c?.[parentName]?.[name]
      }
    >
      {() => (
        <ChildrenForm
          field={field}
          centreServices={centreServices}
          parentName={parentName}
          onEditChild={onEditChild}
          isApplication={isApplication}
        />
      )}
    </Form.Item>
  );
};

ChildItemBox.propTypes = {
  field: PropTypes.object,
  centreServices: PropTypes.array,
  parentName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onEditChild: PropTypes.func,
  isApplication: PropTypes.bool,
};

export default ChildItemBox;
