import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';
import RequiredDays from './RequiredDays';

const FormRequiredDays = ({ name, label, rules, disabled }) => {
  const { t } = useTranslation();

  return (
    <Form.Item name={name} label={t(label)} rules={rules}>
      <RequiredDays disabled={disabled} />
    </Form.Item>
  );
};

FormRequiredDays.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  label: PropTypes.string,
  rules: PropTypes.array,
  disabled: PropTypes.bool,
};

export default FormRequiredDays;
