import { InfoCircleOutlined } from '@ant-design/icons';
import FormSelect from '@uikit/form/FormSelect';
import { PREFERRED_CONTACT_METHOD } from 'configs/localData';
import { useTranslation } from 'react-i18next';
import FormInput from '@uikit/form/FormInput';
import { Col, Input, Row } from 'antd';
import FeatureTitleIcon from '@uikit/commons/FeatureTitleIcon';
import { getPhoneNumberRules } from 'utils/validate';
import EnquiryTypeSelect from './EnquiryTypeSelect';

const GeneralInformationForm = () => {
  const { t } = useTranslation();

  return (
    <div>
      <FeatureTitleIcon
        IconCPN={InfoCircleOutlined}
        title="title.generalInformation"
        className="mb-20"
      />
      <div className="complex-enquiry__general-info">
        <EnquiryTypeSelect />
        <FormInput
          required
          name="message"
          label="input.enquiryDetails.label"
          messageRequire="input.enquiryDetails.validateMsg.required"
          rules={[
            {
              whitespace: true,
              message: t('input.empty'),
            },
          ]}
          placeholder="input.enquiryDetails.placeholder"
          ContentComponent={Input.TextArea}
        />

        <Row gutter={20}>
          <Col md={12} xs={24}>
            <FormInput
              required
              name="phoneNumber"
              label="input.phoneNumber.label"
              messageRequire="input.phoneNumber.validateMsg.required"
              rules={getPhoneNumberRules()}
              placeholder="input.phoneNumber.placeholder"
            />
          </Col>

          <Col md={12} xs={24}>
            <FormSelect
              required
              initialValue={PREFERRED_CONTACT_METHOD[0].value}
              label="input.preferredContactMethod.label"
              name="preferredContactMethod"
              resourceData={PREFERRED_CONTACT_METHOD}
              valueProp="value"
              titleProp="text"
              formatText={(name) => t(name)}
              getPopupContainer={(trigger) => trigger.parentNode}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GeneralInformationForm;
