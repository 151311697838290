import { useTranslation } from 'react-i18next';
import { Form, Input, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getEnquiryTypes } from '@redux/config/selectors';
import { CheckCircleFilled } from '@ant-design/icons';
import { useEffect } from 'react';
import { useCreateInboxesMutation } from '@redux/inboxes';
import RequiredAuthButton from 'components/rest/RequiredAuthButton';
import FormSelect from '@uikit/form/FormSelect';
import {
  ENQUIRY_MODE_CONST,
  PREFERRED_CONTACT_METHOD,
  PREFERRED_CONTACT_METHOD_CONST,
} from 'configs/localData';
import FormInput from '@uikit/form/FormInput';
import { getPhoneNumberRules } from 'utils/validate';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';
import {
  getCentreDetailConnectButtons,
  getCentreDetailsApplicableButtons,
} from '@redux/centreDetails/actions';
import { useRouter } from 'next/router';
import { CRM_PACKAGES } from 'configs/constants';
import { isEmpty } from 'lodash-es';
import { SimpleEnquiryFormStyles } from './styles';
import EnquiryTypeSelect from './EnquiryTypeSelect';

interface Props {
  centreId: string;
  onCancel: () => void;
  subscription?: string;
  claimStatus?: string;
}

const SimpleEnquiryForm = ({
  centreId,
  onCancel,
  subscription,
  claimStatus,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { query } = useRouter();

  const dispatch = useDispatch();
  const enquiryTypes = useSelector(getEnquiryTypes);

  const fieldsBeenOperated = form?.isFieldsTouched();
  const errorMessagesByFieldsName =
    form.getFieldsError()?.filter(({ errors }) => errors.length)?.length > 0;

  useEffect(() => {
    form.setFieldsValue({
      enquiryTypeId: enquiryTypes?.[0]?.id,
    });
  }, [enquiryTypes, form]);

  const [createInboxes, { isLoading }] = useCreateInboxesMutation();

  const onFinish = (values) => {
    createInboxes({
      data: {
        centreId,
        ...values,
      },
    }).then((res) => {
      if (res?.data?.id) {
        if (
          subscription === CRM_PACKAGES.kindicareBasic ||
          isEmpty(claimStatus)
        ) {
          dispatch(getCentreDetailsApplicableButtons(String(query?.id)));
          dispatch(getCentreDetailConnectButtons({ centreId }));
        }
        onCancel();
        dispatch(
          trackingEvent({
            eventName: EVENT_TRACKING.enquirySubmitted,
            eventData: {
              centreId,
              enquiryId: res.data.id,
              enquiryType: enquiryTypes?.find(
                (item) => item.id === values.enquiryTypeId,
              )?.name,
              enquiryMode: ENQUIRY_MODE_CONST.simple,
            },
          }),
        );
        Modal.success({
          icon: <CheckCircleFilled />,
          title: (
            <div className="fw-bold size-18">{t('enquiries.complete')}</div>
          ),
          content: t('enquiries.success'),
          okText: t('button.done'),
        });
      }
    });
  };

  return (
    <SimpleEnquiryFormStyles>
      <div className="simple-enquiry__title">{t('makeEnquiry.title')}</div>

      <Form layout="vertical" form={form} onFinish={onFinish}>
        <div>
          <EnquiryTypeSelect />

          <Form.Item
            label={t('input.enquiryDetails.label')}
            name="message"
            rules={[
              {
                required: true,
                message: t('input.enquiryDetails.validateMsg.required'),
              },
              {
                whitespace: true,
                message: t('input.empty'),
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder={t('input.enquiryDetails.placeholder')}
            />
          </Form.Item>

          <FormSelect
            required
            initialValue={PREFERRED_CONTACT_METHOD[0].value}
            label="input.preferredContactMethod.label"
            name="preferredContactMethod"
            resourceData={PREFERRED_CONTACT_METHOD}
            valueProp="value"
            titleProp="text"
            formatText={(name) => t(name)}
            getPopupContainer={(trigger) => trigger.parentNode}
          />

          <Form.Item
            noStyle
            shouldUpdate={(p, c) =>
              p.preferredContactMethod !== c.preferredContactMethod
            }
          >
            {({ getFieldValue }) =>
              getFieldValue('preferredContactMethod') ===
              PREFERRED_CONTACT_METHOD_CONST.phone.value ? (
                <FormInput
                  required
                  name="phoneNumber"
                  label="input.phoneNumber.label"
                  messageRequire="input.phoneNumber.validateMsg.required"
                  rules={getPhoneNumberRules()}
                  placeholder="input.phoneNumber.placeholder"
                />
              ) : null
            }
          </Form.Item>
        </div>

        <RequiredAuthButton
          size="large"
          className="w-full simple-enquiry__submit-btn"
          type="primary"
          onClick={form.submit}
          loading={isLoading}
          disabled={fieldsBeenOperated || errorMessagesByFieldsName}
        >
          {t('button.sendEnquiry')}
        </RequiredAuthButton>
      </Form>
    </SimpleEnquiryFormStyles>
  );
};

export default SimpleEnquiryForm;
