import Link from '@uikit/commons/Link';
import NextImage from '@uikit/commons/NextImage';
import { Col, Layout, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { FOOTER_MENUS, SOCIAL_LINKS } from 'configs/layout';
import { EMAIL_CONTACT } from 'configs/constants';
import { FooterStyles } from './styles';

interface Props {
  hasParentSidebar?: boolean;
}

const Footer = ({ hasParentSidebar }: Props) => {
  const { t } = useTranslation();

  const renderFooterMenu = (item, index) => {
    if (item?.mainMenu) {
      return (
        <Col key={index} lg={6} sm={12} xs={24} className="footer-menu-col">
          {item?.mainMenu?.map((e, i) => (
            <div className="mb-40" key={i}>
              <div className="fw-bold size-18">{t(e.title)}</div>
              <Space
                className="menu-space-item mt-20"
                direction="vertical"
                size={20}
              >
                {e.subMenus?.map((subMenuItem) => (
                  <a key={e.title} href={subMenuItem.href} rel="noreferrer">
                    <span className="link size-l text-secondary">
                      {t(subMenuItem.title)}
                    </span>
                  </a>
                ))}
              </Space>
            </div>
          ))}
        </Col>
      );
    }
    return (
      <Col key={index} lg={6} sm={12} xs={24} className="footer-menu-col">
        <div className="fw-bold size-18">{t(item.title)}</div>
        <Space className="menu-space-item mt-20" direction="vertical" size={20}>
          {item.subMenus?.map((subMenuItem) =>
            subMenuItem.href ? (
              <Link key={item.title} href={subMenuItem.href}>
                <span className="link size-l text-secondary">
                  {t(subMenuItem.title)}
                </span>
              </Link>
            ) : (
              <a
                key={item.title}
                href={`mailto:${EMAIL_CONTACT}`}
                className="footer-email"
              >
                <span className="link size-l text-secondary">
                  {t(subMenuItem.title)}
                </span>
              </a>
            ),
          )}
        </Space>
      </Col>
    );
  };

  return (
    <FooterStyles hasParentSidebar={hasParentSidebar}>
      <Layout.Footer className="mainFooter">
        <div className="wrapper-container">
          <Row gutter={[24, 24]} className="footer-row-content">
            <Col lg={6} md={24} sm={24} xs={24} className="col-footer-left">
              <div>
                <NextImage
                  src="/images/logo.png"
                  alt="KindiCare logo"
                  width={192}
                  height={60}
                />
              </div>
              <Space size={16} className="mt-30 footer-social">
                {SOCIAL_LINKS.map(({ IconCPN, href }, index) => (
                  <a
                    key={String(index)}
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="footer-social-icon">
                      <IconCPN />
                    </div>
                  </a>
                ))}
              </Space>
            </Col>
            <Col lg={18} md={24} sm={24} xs={24} className="col-footer-menu">
              <Row gutter={[16, 44]}>
                {FOOTER_MENUS.map((item, index) =>
                  renderFooterMenu(item, index),
                )}
              </Row>
            </Col>
          </Row>
        </div>
      </Layout.Footer>
    </FooterStyles>
  );
};

export default Footer;
