import { INITIAL_STATE_CUSTOM } from '@redux/crudCreator/utils';
import { createSlice } from '@reduxjs/toolkit';
import { getFavouriteList } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const getFavouriteListPending = (state) => {
  state.loading = true;
};

const getFavouriteListSuccess = (state, { payload }) => {
  state.loading = false;
  state.data = payload?.results;
  state.resourceData.total = payload?.total;
};

const getFavouriteListFail = (state, { payload }) => {
  state.loading = false;
  state.error = payload;
};

const { actions, reducer } = createSlice({
  name: 'centresFavourites',
  initialState,
  reducers: {
    removeCentreFavourites(state, { payload }) {
      state.data = state.data.filter((item) => item.id !== payload);
    },
  },
  extraReducers: {
    [getFavouriteList.pending]: getFavouriteListPending,
    [getFavouriteList.fulfilled]: getFavouriteListSuccess,
    [getFavouriteList.rejected]: getFavouriteListFail,
  },
});

export const { removeCentreFavourites } = actions;

export default reducer;
