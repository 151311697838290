import { Steps } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { times } from 'lodash-es';
import { ApplicationStepsStyles } from './styles';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';

const { Step } = Steps;

const NUMBER_OF_STEPS = 4;

const ApplicationSteps = ({ currentStep = 0, ...props }) => {
  const { t } = useTranslation();

  const content = useMemo(() => {
    switch (currentStep + 1) {
      case 1:
        return <Step1 />;
      case 2:
        return <Step2 />;
      case 3:
        return <Step3 centreSlug={props.centreSlug} />;
      case 4:
        return <Step4 centreSlug={props.centreSlug} />;
      default:
        return <div />;
    }
  }, [currentStep, props.centreSlug]);

  return (
    <ApplicationStepsStyles>
      <Steps current={currentStep} labelPlacement="vertical">
        {times(NUMBER_OF_STEPS).map((index) => (
          <Step
            key={String(index)}
            title={t('applyApplications.step', { stepIndex: index + 1 })}
          />
        ))}
      </Steps>
      <div className="steps-content">{content}</div>
    </ApplicationStepsStyles>
  );
};

ApplicationSteps.propTypes = {
  currentStep: PropTypes.number,
  centreSlug: PropTypes.string,
};

export default ApplicationSteps;
