import styled from 'styled-components';
import { Modal } from 'antd';
import { respondTo } from 'utils/variables';

export const ModalWrapper = styled(Modal)`
  --primary: ${({ primaryColor }) => primaryColor};
  min-height: 30%;
  max-width: calc(100vw - 50px);

  &.reset-padding-top-body .ant-modal-body {
    padding-top: 0 !important;
  }

  &.reset-padding-bottom-body .ant-modal-body {
    padding-bottom: 0;
  }

  &.modal-scroll-y .ant-modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }

  .ant-modal-content {
    overflow: visible;
  }

  .ant-modal-header {
    border-bottom: 1px solid var(--border-color-light);
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    padding: 16px;
  }

  .ant-modal-close,
  .ant-modal-close-icon,
  .ant-modal-close-x > .anticon {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
  }

  .ant-modal-close {
    border-radius: 50%;
    background: #ffffff;
    box-shadow: 0 8px 16px rgb(39 40 51 / 16%);
    top: -16px;
    right: -16px;
    &-x {
      width: 48px !important;
      height: 48px !important;
      line-height: 60px !important;
      color: var(--color-grey);
    }
  }

  .multi-upload-wrapper .ant-form-item:not(:first-child) {
    display: none;
  }
  .multi-upload-wrapper .ant-form-item:first-child {
    margin-bottom: 10px;
  }

  textarea,
  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selection,
  .ant-input-number,
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu,
  .ant-select-dropdown,
  .ant-select-clear-icon,
  .ant-select-dropdown-menu-vertical,
  .ant-picker,
  .ant-checkbox-inner {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid var(--primary);
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-picker:hover,
  .ant-picker-focused,
  .ant-radio-checked .ant-radio-inner,
  .ant-input:focus {
    border: 1px solid var(--primary);
    box-shadow: none;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid var(--primary);
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-radio-inner::after {
    background-color: var(--primary);
    border: 1px solid var(--primary);
  }
  .ant-checkbox-checked .ant-checkbox-inner:hover {
    border: none !important;
  }

  .ant-input-affix-wrapper {
    border-radius: var(--border-radius);
    .ant-input {
      border: none;
    }
    .ant-input:active {
      border: none;
    }
  }

  .ant-input-affix-wrapper-focused {
    border: 1px solid var(--primary) !important;
  }

  .ant-select-selection__clear {
    background-color: transparent;
    color: white;
    border-radius: 5px;
  }
  .ant-select-arrow-icon {
    background-color: transparent;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ant-modal-footer {
    border: 1px solid var(--border-color-light);
    padding: 16px;
    .ant-btn-primary:not(:disabled) {
      background-color: var(--primary);
    }
    .ant-btn-default:hover {
      color: var(--primary);
    }
    > button {
      min-width: 100px;
      &:not(:first-child) {
        margin-left: 16px !important;
      }
    }
    .ant-btn-default {
      border: 1px solid var(--primary);
    }
    .ant-btn-primary {
      border: none;
    }
  }

  .ant-calendar-picker,
  .ant-select {
    width: 100%;
  }

  .ant-form-item-control {
    line-height: 2;
  }

  .ant-form-item-label {
    line-height: 1.5em;
    padding-bottom: 5px;
  }
  .ant-input-number,
  .ant-picker {
    width: 100%;
  }
  .ant-form-item .ant-form-item-explain {
    margin-top: 3px;
  }
  .ant-input-number {
    border-radius: var(--border-radius);
  }
  .add-centre-modal {
    .ant-modal-title {
      font-size: 24px;
      font-weight: bold;
    }
  }
`;

interface ModalScreenProps {
  bodyWidth?: string;
}

export const ModalScreenWrapper = styled<ModalScreenProps>(Modal)`
  --primary: ${({ primaryColor }) => primaryColor};
  --header-height: 100px;
  min-width: 100vw;
  padding: 0;
  top: 0;
  margin: 0 auto !important;

  ${respondTo.sm`
     --header-height: 48px
  `}

  .ant-modal-content {
    overflow: visible;
    border-radius: 0;
    background-color: var(--text-white);
    min-height: 100vh !important;
    padding-top: var(--header-height);
  }

  .ant-modal-header {
    border-bottom: 1px solid var(--border-color-light);
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    position: fixed;
    width: 100%;
    z-index: 1;
    border-radius: 0;
    top: 0;
    right: 0;
    left: 0;
    height: var(--header-height);
    display: flex;
    justify-content: center;
    align-items: center;
    ${respondTo.md`
      padding: 30px 20px;
    `}
    ${respondTo.sm`
      font-size: 16px;
      padding: 12px 20px
    `}
  }

  .ant-modal-title {
    font-size: 24px;
    ${respondTo.sm`
      font-size: 16px !important;
    `}
  }

  .ant-modal-close {
    left: 110px;
    ${respondTo.md`
       left: 20px;
    `}
  }

  .ant-modal-close-x {
    width: var(--header-height) !important;
    height: var(--header-height) !important;
    line-height: unset;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    ${respondTo.sm`
      width: 24px !important;
    `}
    .back-btn {
      display: flex;
      flex-direction: row;
      gap: 8px;
      border: 1px solid #d0d5dd;
      height: max-content;
      padding: 10px 16px;
      border-radius: 100px;
      color: var(--text-gray-white);
      font-size: 14px;
      ${respondTo.sm`
        border: unset;
        border-radius: unset;
        .anticon {
          font-size: 14px;
        }
      `}
    }
  }

  .ant-modal-body {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: ${({ bodyWidth }) => bodyWidth ?? '1220px'};
    position: relative;
    width: 90%;
    overflow-y: auto;
    ${respondTo.sm`
      padding: 0;
      margin: 0;
      width: 100vw;
    `}
  }

  .multi-upload-wrapper .ant-form-item:not(:first-child) {
    display: none;
  }
  .multi-upload-wrapper .ant-form-item:first-child {
    margin-bottom: 10px;
  }

  textarea,
  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selection,
  .ant-input-number,
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu,
  .ant-select-dropdown,
  .ant-select-clear-icon,
  .ant-select-dropdown-menu-vertical,
  .ant-picker,
  .ant-checkbox-inner {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid var(--primary);
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-picker:hover,
  .ant-picker-focused,
  .ant-radio-checked .ant-radio-inner,
  .ant-input:focus {
    border: 1px solid var(--primary);
    box-shadow: none;
  }
  .ant-checkbox-checked::after {
    border: none;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border: 1px solid var(--primary);
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-radio-inner::after {
    background-color: var(--primary);
    border: 1px solid var(--primary);
  }
  .ant-checkbox-checked .ant-checkbox-inner:hover {
    border: none !important;
  }

  .ant-input-affix-wrapper {
    border-radius: var(--border-radius);
    .ant-input {
      border: none;
    }
    .ant-input:active {
      border: none;
    }
  }

  .ant-input-affix-wrapper-focused {
    border: 1px solid var(--primary) !important;
  }

  .ant-select-selection__clear {
    background-color: transparent;
    color: white;
    border-radius: 5px;
  }
  .ant-select-arrow-icon {
    background-color: transparent;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .ant-calendar-picker,
  .ant-select {
    width: 100%;
  }

  .ant-form-item-control {
    line-height: 2;
  }

  .ant-form-item-label {
    line-height: 1.5em;
    padding-bottom: 5px;
  }
  .ant-input-number,
  .ant-picker {
    width: 100%;
  }
  .ant-form-item .ant-form-item-explain {
    margin-top: 3px;
  }
  .ant-input-number {
    border-radius: var(--border-radius);
  }
`;
