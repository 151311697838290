import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const jobForSeekersApi = rtkCreator(
  'jobForSeekersHomepage',
  'job-seekers/screens/homepages',
  richWebConfigVer2,
);

export const {
  useGetAllJobForSeekersHomepageQuery,
  useLazyGetAllJobForSeekersHomepageQuery,
} = jobForSeekersApi;

export const jobFeaturedBrandLogosApi = rtkCreator(
  'jobFeaturedBrandLogos',
  'job-seekers/brands/featured-logos',
  richWebConfigVer2,
);

export const { useGetAllJobFeaturedBrandLogosQuery } = jobFeaturedBrandLogosApi;
