import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox } from 'antd';
import { useDispatch } from 'react-redux';
import { showingPopupApply } from '@redux/jobsModule/jobDetail/actions';
import ModalJobSeekerCustom from '.';
import SuccessfullyModal from './SuccessfullyModal';

const AskAgainModal = (props = {}, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [applySuccess, setApplySuccess] = useState();
  const [successfullyModal, setSuccessfullyModal] = useState({
    inVisible: false,
    results: {},
  });

  const onCancel = () => setVisible(false);
  useImperativeHandle(
    ref,
    () => ({
      toggleVisible: (applyResponse) => {
        setApplySuccess(applyResponse);
        setVisible(!visible);
      },
    }),
    [visible],
  );

  const handleAgree = () => {
    dispatch(
      showingPopupApply({
        isShowingPopup: false,
        data: {
          postId: applySuccess?.buttons[1]?.action?.data?.postId,
        },
      }),
    ).then((res) => {
      const response = res?.payload?.action;
      setSuccessfullyModal({
        inVisible: true,
        results: response,
      });
      setVisible(!visible);
    });
  };

  return (
    <>
      <ModalJobSeekerCustom
        visible={visible}
        onCancel={onCancel}
        closable={false}
        okText={t('button.agree')}
        onOk={handleAgree}
        {...props}
      >
        <div className="size-16">
          <div className="lh-32">{applySuccess?.description}</div>
          <Checkbox defaultChecked>
            {t('forJobSeekers.details.applyFlow.noAskAgain')}
          </Checkbox>
        </div>
      </ModalJobSeekerCustom>
      <SuccessfullyModal
        successfullyModal={successfullyModal}
        setSuccessfullyModal={setSuccessfullyModal}
      />
    </>
  );
};

export default forwardRef(AskAgainModal);
