import { richWebConfigVer2 } from 'utils';
import request from 'utils/request';

export async function getJobPostsApi(params) {
  return request.get(`/job-seekers/screens/posts`, {
    params,
    ...richWebConfigVer2,
  });
}

export async function getJobPostsFilterApi(params) {
  return request.get(`/job-seekers/screens/posts/filter-config`, {
    params,
    ...richWebConfigVer2,
  });
}
