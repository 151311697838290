import { forwardRef, useImperativeHandle, useState } from 'react';
import { Space } from 'antd';
import { useSelector } from 'react-redux';

import Link from 'next/link';
import GroupActionsHeader from './GroupActionsHeader';
import { DrawerMenuMobileStyles } from './styles';
import MenuHeader from './MenuHeader';

const DrawerMenuMobile = forwardRef((_props, ref) => {
  const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const [visible, setVisible] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      openModal: () => {
        setVisible(true);
      },
    }),
    [],
  );

  const onCancel = () => setVisible(false);

  return (
    <DrawerMenuMobileStyles
      width="100vw"
      visible={visible}
      onClose={onCancel}
      title=""
      extra={
        <div className="d-flex items-center">
          <Link href="/">
            <img
              className="header-logo"
              src="/images/logo.png"
              alt="vindicate"
            />
          </Link>
        </div>
      }
    >
      <Space size={20} direction="vertical" className="space-wrapper-modal">
        <MenuHeader
          mode="vertical"
          className="header-mobile-menu"
          handleCancelDrawer={onCancel}
        />
        {!isAuth && (
          <GroupActionsHeader
            direction="vertical"
            size={20}
            handleCancelDrawer={onCancel}
          />
        )}
      </Space>
    </DrawerMenuMobileStyles>
  );
});

export default DrawerMenuMobile;
