import { richWebConfig, richWebConfigVer2 } from 'utils';
import request from 'utils/request';

export async function getCentreDetailsApi(slug, params) {
  return request.get(`/centres/${slug}/details-basic-info`, {
    ...richWebConfig,
    params,
  });
}

export async function getCentreReviewsSummariesApi(slug) {
  return request.get(`/centres/${slug}/reviews-summaries`, richWebConfig);
}

export async function getCentreDetailsRatingsApi(slug) {
  return request.get(`/centres/${slug}/details-ratings`, richWebConfig);
}

export async function getKindiRatingSummaryApi(slug) {
  return request.get(
    `/parent/centres/details/rating-summary?slug=${slug}`,
    richWebConfigVer2,
  );
}

export async function getCentreDetailsServicesApi(slug, params) {
  return request.get(`/centres/${slug}/details-services`, {
    ...richWebConfig,
    params,
  });
}

export async function getCentreDetailsApplicableButtonsApi(slug) {
  return request.get(
    `/centres/${slug}/details-applicable-buttons`,
    richWebConfig,
  );
}

export async function getCentreDetailsReviewsApi(slug, params) {
  return request.get(`/centres/${slug}/reviews`, {
    ...richWebConfig,
    params,
  });
}

export async function submitUserReviewApi(id, body) {
  return request.post(`/centres/${id}/ratings`, body);
}

export async function getCentreDetailConnectButtonsApi(params) {
  return request.get(`/parent/centres/details/cta-buttons`, {
    params,
    ...richWebConfigVer2,
  });
}

export async function getGetStaffProfilesApi(centerId, page, size) {
  return request.get(
    `/parent/centre/educator-profiles?centreId=${centerId}&page=${page}&size=${size}`,
    {
      ...richWebConfigVer2,
    },
  );
}

export async function getGetStaffProfileDetailApi(centerId, currentOffset) {
  return request.get(
    `/parent/centre/educator-profiles?centreId=${centerId}&currentOffset=${currentOffset}`,
    {
      ...richWebConfigVer2,
    },
  );
}

export async function getProgramsApi(
  centerId: string,
  page: number,
  size: number,
) {
  return request.get(
    `/parent/centre/program?centreId=${centerId}&page=${page}&size=${size}`,
    {
      ...richWebConfigVer2,
    },
  );
}

export async function getProgramDetailApi(id: string) {
  return request.get(`/parent/centre/program/detail?id=${id}`, {
    ...richWebConfigVer2,
  });
}
