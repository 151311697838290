import { useGetByIdCentreDetailsApplicableButtonsQuery } from '@redux/centres';
import { getEnquiryTypes } from '@redux/config/selectors';
import { CENTRE_APP_SETTING_CONST } from 'configs/constants';
import { ENQUIRY_TYPES_CONST } from 'configs/localData';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const useEnquiryTypes = () => {
  const { query } = useRouter();

  const enquiryTypes = useSelector(getEnquiryTypes);

  const { data } = useGetByIdCentreDetailsApplicableButtonsQuery({
    data: {
      id: query?.id,
    },
  });

  const { isAllowedBookingTour, centreAppSettings } = data || {};

  const restEnquiryTypesData = useMemo(() => {
    if (
      isAllowedBookingTour &&
      centreAppSettings?.find(
        (item) => item.key === CENTRE_APP_SETTING_CONST.bookATourOrVisitUrl,
      )?.value
    ) {
      return enquiryTypes?.filter(
        (item) => item.name !== ENQUIRY_TYPES_CONST.BOOK_A_TOUR_OR_VISIT.value,
      );
    }
    return enquiryTypes;
  }, [isAllowedBookingTour, centreAppSettings, enquiryTypes]);

  return restEnquiryTypesData;
};

export default useEnquiryTypes;
