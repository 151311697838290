export const SEO_SUBURB = [
  {
    rangPostcode: [200, 499],
    title: 'seo.suburbPostCodeLv1.title',
    description: 'seo.suburbPostCodeLv1.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv1.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv1.description',
  },
  {
    rangPostcode: [599, 999],
    title: 'seo.suburbPostCodeLv2.title',
    description: 'seo.suburbPostCodeLv2.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv2.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv2.description',
  },
  {
    rangPostcode: [1000, 1499],
    title: 'seo.suburbPostCodeLv3.title',
    description: 'seo.suburbPostCodeLv3.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv3.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv3.description',
  },
  {
    rangPostcode: [1500, 1999],
    title: 'seo.suburbPostCodeLv4.title',
    description: 'seo.suburbPostCodeLv4.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv4.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv4.description',
  },
  {
    rangPostcode: [2000, 2599],
    title: 'seo.suburbPostCodeLv5.title',
    description: 'seo.suburbPostCodeLv5.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv5.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv5.description',
  },
  {
    rangPostcode: [2600, 2999],
    title: 'seo.suburbPostCodeLv6.title',
    description: 'seo.suburbPostCodeLv6.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv6.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv6.description',
  },
  {
    rangPostcode: [3000, 3499],
    title: 'seo.suburbPostCodeLv7.title',
    description: 'seo.suburbPostCodeLv7.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv7.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv7.description',
  },
  {
    rangPostcode: [3500, 3999],
    title: 'seo.suburbPostCodeLv8.title',
    description: 'seo.suburbPostCodeLv8.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv8.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv8.description',
  },
  {
    rangPostcode: [4000, 4599],
    title: 'seo.suburbPostCodeLv9.title',
    description: 'seo.suburbPostCodeLv9.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv9.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv9.description',
  },
  {
    rangPostcode: [4600, 4999],
    title: 'seo.suburbPostCodeLv10.title',
    description: 'seo.suburbPostCodeLv10.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv10.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv10.description',
  },
  {
    rangPostcode: [5000, 5599],
    title: 'seo.suburbPostCodeLv11.title',
    description: 'seo.suburbPostCodeLv11.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv11.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv11.description',
  },
  {
    rangPostcode: [5600, 5999],
    title: 'seo.suburbPostCodeLv12.title',
    description: 'seo.suburbPostCodeLv12.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv12.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv12.description',
  },
  {
    rangPostcode: [6000, 6799],
    title: 'seo.suburbPostCodeLv13.title',
    description: 'seo.suburbPostCodeLv13.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv13.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv13.description',
  },
  {
    rangPostcode: [6800, 6999],
    title: 'seo.suburbPostCodeLv14.title',
    description: 'seo.suburbPostCodeLv14.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv14.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv14.description',
  },
  {
    rangPostcode: [7000, 7599],
    title: 'seo.suburbPostCodeLv15.title',
    description: 'seo.suburbPostCodeLv15.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv15.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv15.description',
  },
  {
    rangPostcode: [7600, 7999],
    title: 'seo.suburbPostCodeLv16.title',
    description: 'seo.suburbPostCodeLv16.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv16.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv16.description',
  },
  {
    rangPostcode: [8000, 8499],
    title: 'seo.suburbPostCodeLv17.title',
    description: 'seo.suburbPostCodeLv17.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv17.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv17.description',
  },
  {
    rangPostcode: [8500, 8999],
    title: 'seo.suburbPostCodeLv18.title',
    description: 'seo.suburbPostCodeLv18.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv18.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv18.description',
  },
  {
    rangPostcode: [9000, 9599],
    title: 'seo.suburbPostCodeLv19.title',
    description: 'seo.suburbPostCodeLv19.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv19.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv19.description',
  },
  {
    rangPostcode: [9600, 9999],
    title: 'seo.suburbPostCodeLv20.title',
    description: 'seo.suburbPostCodeLv20.description',
    suburbProfileTitle: 'seo.suburbProfilePostCodeLv20.title',
    suburbProfileDescription: 'seo.suburbProfilePostCodeLv20.description',
  },
];
