import { forwardRef, useImperativeHandle, useState } from 'react';
import ModalCustom from '@uikit/commons/ModalCustom';
import { Skeleton } from 'antd';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { getCurrentGeoLocation } from '@redux/config/selectors';
import { useGetByIdCentreBasicInfoQuery } from '@redux/centres';
import styled from 'styled-components';
import ComplexEnquiry from './ComplexEnquiry';
import SimpleEnquiry from './SimpleEnquiry';

interface Props {
  centreSlug?: string;
}

const MakeEnquiryModal = (props: Props, ref) => {
  const [visible, setVisible] = useState(false);

  const onCancel = () => setVisible(false);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setVisible(true);
      },
    }),
    [],
  );
  const { query } = useRouter();

  const currentGeoLocation = useSelector(getCurrentGeoLocation);

  const { data: centre, isLoading } = useGetByIdCentreBasicInfoQuery(
    {
      data: {
        id: query?.id || props.centreSlug,
      },
      options: {
        extraParams: currentGeoLocation,
      },
    },
    { skip: !query?.id },
  );

  return (
    <MakeEnquiryModalStyles
      visible={visible}
      footer={null}
      onCancel={onCancel}
      width={1075}
      destroyOnClose
    >
      <Skeleton active loading={isLoading}>
        {centre?.isEnquiryComplex ? (
          <ComplexEnquiry centre={centre} onCancel={onCancel} />
        ) : (
          <SimpleEnquiry centre={centre} onCancel={onCancel} />
        )}
      </Skeleton>
    </MakeEnquiryModalStyles>
  );
};

export default forwardRef(MakeEnquiryModal);

const MakeEnquiryModalStyles = styled(ModalCustom)`
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-body {
    padding: 0 !important;
  }
`;
