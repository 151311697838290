import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const jobPostsApi = rtkCreator(
  'jobPosts',
  'job-seekers/screens/posts',
  richWebConfigVer2,
);

export const { useGetAllJobPostsQuery } = jobPostsApi;

export const { getAllJobPosts } = jobPostsApi.endpoints;
