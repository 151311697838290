import { useMemo, useCallback, ReactNode } from 'react';
import { Form, Select, Spin, Tooltip } from 'antd';
import { map, isObject, get } from 'lodash-es';
import { Waypoint } from 'react-waypoint';
import { useTranslation } from 'react-i18next';
import { onSearch as onChangeSearch } from 'utils/tools';
import { Rule } from 'antd/lib/form';

const { Option } = Select;
interface ResourceData {
  [key: string]: unknown;
}

interface Props {
  name: string | string[];
  label?: string;
  labelCustom?: ReactNode;
  required?: boolean;
  messageRequire?: string;
  placeholder?: string;
  placeholderCustom?: ReactNode;
  rules?: Rule[];
  resourceData?: ResourceData[];
  valueProp?: string;
  titleProp?: string;
  isFilterOption?: boolean;
  formatText?: (value: string, data?: object) => string;
  formatValue?: (value: string, data?: object) => string;
  searchKey?: string;
  loading?: boolean;
  onEnter?: () => void;
  enableWaypoint?: boolean;
  initialValue?: string;
  formItemProps?: object;
  isShowTooltip?: boolean;
  onChangeGetSelectedItem?: (value: string, findItem: object) => void;
  onChange?: () => void;
  [props: string]: unknown;
}

const FormSelect = ({
  name,
  label = '',
  labelCustom,
  required,
  messageRequire = 'error.required',
  placeholder = 'input.selectPlaceholder',
  placeholderCustom,
  rules = [],
  resourceData,
  valueProp = 'id',
  titleProp = 'name',
  isFilterOption = true,
  formatText = (value) => value,
  formatValue = (value) => value,
  searchKey = 'name',
  loading,
  onEnter,
  enableWaypoint,
  initialValue,
  formItemProps,
  isShowTooltip,
  onChangeGetSelectedItem,
  onChange,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const onSelectOption = useCallback(
    (inputValue, option) => {
      if (
        onChangeSearch(
          isObject(option.children)
            ? get(option.children.props?.record, searchKey)
            : option.children,
          inputValue,
        )
      ) {
        return option.value;
      }
      return null;
    },
    [searchKey],
  );

  const optionWaypoint = (
    <Option
      className="loading-select-option"
      disabled
      value="waypointTracking"
      key="waypoint"
    >
      <div style={{ height: 1 }}>
        <Waypoint onEnter={onEnter} />
      </div>
    </Option>
  );

  const optionLoading = useMemo(
    () => (
      <Option
        className="loading-select-option"
        disabled
        value="loadingTracking"
        key="loading"
      >
        <div className="loading-select">
          <Spin />
        </div>
      </Option>
    ),
    [],
  );

  const handleChange = (value) => {
    if (!onChangeGetSelectedItem) return;
    const findItem = resourceData?.find(
      (item) => get(item, valueProp) === value,
    );

    onChangeGetSelectedItem(value, findItem);
  };

  const formItem = (
    <Form.Item
      label={labelCustom || t(label)}
      name={name}
      rules={[
        {
          required,
          message: t(messageRequire),
        },
        ...rules,
      ]}
      initialValue={initialValue}
      {...formItemProps}
    >
      <Select
        placeholder={placeholderCustom || t(placeholder)}
        filterOption={isFilterOption ? onSelectOption : false}
        {...props}
        onChange={onChange || handleChange}
      >
        {map(resourceData, (data, index) => (
          <Option
            key={String(index)}
            value={formatValue(valueProp ? get(data, valueProp) : data, data)}
          >
            {formatText(titleProp ? get(data, titleProp) : data, data)}
          </Option>
        ))}
        {enableWaypoint && optionWaypoint}
        {loading && optionLoading}
      </Select>
    </Form.Item>
  );

  return isShowTooltip ? (
    <Tooltip title={placeholder ? t(placeholder) : ''}>{formItem}</Tooltip>
  ) : (
    formItem
  );
};

export default FormSelect;
