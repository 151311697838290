import { apiWrapper } from '@redux/reduxUtils';
import { createApi, retry, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  delApi,
  getAllApi,
  getDataByIdApi,
  patchApi,
  postApi,
  putApi,
} from 'api/crud';
import { setInitHeader } from 'utils/request';
import { upperFirstChar } from 'utils/tools';
import { convertRequestParams, REQUEST_TYPE } from './dataProvider';

export const emptySplitApi = createApi({
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: () => ({}),
});

export const rtkCreator = (
  resource,
  customApiResource,
  config,
  extraApis,
  extraApiHandler = () => ({}),
) => {
  const apiResource = customApiResource || resource;

  return createApi({
    overrideExisting: false,
    reducerPath: `${resource}Api`,
    tagTypes: [resource],
    baseQuery: retry(
      async (payload) => {
        try {
          setInitHeader();
          const requestParams = convertRequestParams(
            payload.type,
            payload.data,
            payload?.options,
          );

          const apiUrl = payload.suffixApi
            ? `${apiResource}${payload.suffixApi}`
            : payload.customApiResource || apiResource;

          const apiConfig = {
            ...config,
            ...payload.config,
          };

          switch (payload.type) {
            case REQUEST_TYPE.GET_ALL:
              return {
                data: await getAllApi(apiUrl, requestParams, apiConfig),
              };
            case REQUEST_TYPE.GET_BY_ID:
              return {
                data: await getDataByIdApi(
                  apiUrl,
                  payload.data?.id,
                  requestParams,
                  payload.suffixApiResource,
                  apiConfig,
                ),
              };
            case REQUEST_TYPE.CREATE:
              return {
                data: await apiWrapper(
                  payload?.options,
                  postApi,
                  apiUrl,
                  requestParams,
                  apiConfig,
                ),
              };
            case REQUEST_TYPE.PATCH:
              return {
                data: {
                  id: payload?.data?.id || payload?.id,
                  ...(await apiWrapper(
                    payload?.options,
                    patchApi,
                    apiUrl,
                    payload?.data?.id,
                    requestParams,
                    apiConfig,
                  )),
                },
              };
            case REQUEST_TYPE.EDIT:
              return {
                data: {
                  id: payload?.data?.id || payload?.id,
                  ...(await apiWrapper(
                    payload?.options,
                    putApi,
                    apiUrl,
                    payload?.data?.id,
                    requestParams,
                    apiConfig,
                  )),
                },
              };
            case REQUEST_TYPE.DELETE:
              return {
                data: {
                  ...payload.data,
                  ...(await apiWrapper(
                    payload?.options,
                    delApi,
                    apiUrl,
                    payload?.data?.id,
                    apiConfig,
                  )),
                },
              };
            default:
              return extraApiHandler(payload, apiResource);
          }
        } catch (error) {
          return error;
        }
      },
      { maxRetries: 0 },
    ),
    endpoints: (builder) => ({
      [`getAll${upperFirstChar(resource)}`]: builder.query({
        query: (payload = { data: {}, options: {} }) => ({
          ...payload,
          type: REQUEST_TYPE.GET_ALL,
        }),
        providesTags: (result) =>
          result?.data
            ? [
                ...result.data.map(({ id }) => ({ type: resource, id })),
                { type: resource, id: 'LIST' },
              ]
            : [{ type: resource, id: 'LIST' }],
      }),
      [`getById${upperFirstChar(resource)}`]: builder.query({
        query: (payload = { data: {}, options: {} }) => ({
          ...payload,
          type: REQUEST_TYPE.GET_BY_ID,
        }),
        extraOptions: { maxRetries: 1 },
      }),
      [`create${upperFirstChar(resource)}`]: builder.mutation({
        query: (payload = { data: {}, options: {} }) => ({
          ...payload,
          method: 'POST',
          type: REQUEST_TYPE.CREATE,
        }),
        invalidatesTags: [{ type: resource, id: 'LIST' }],
      }),
      [`patch${upperFirstChar(resource)}`]: builder.mutation({
        query: (payload = { data: {}, options: {} }) => ({
          ...payload,
          method: 'PATCH',
          type: REQUEST_TYPE.PATCH,
        }),
        invalidatesTags: (result, error, { id }) => [{ type: resource, id }],
      }),
      [`edit${upperFirstChar(resource)}`]: builder.mutation({
        query: (
          payload = {
            data: {},
            options: {},
          },
        ) => ({
          ...payload,
          method: 'PUT',
          type: REQUEST_TYPE.EDIT,
        }),
        invalidatesTags: (result, error, { id }) => [{ type: resource, id }],
      }),
      [`del${upperFirstChar(resource)}`]: builder.mutation({
        query: (payload = { data: {}, options: {} }) => ({
          ...payload,
          method: 'DELETE',
          type: REQUEST_TYPE.DELETE,
        }),
        invalidatesTags: (result, error, id) => [
          { type: resource, id: id?.data?.id || result?.id || id },
        ],
      }),
      ...extraApis?.(builder),
    }),
  });
};
