import { richWebConfigVer2 } from 'utils';
import request from 'utils/request';

export async function updateJobSeekerProfile(params) {
  return request.put('/job-seekers/me/profile', params, richWebConfigVer2);
}

export async function requirementsApi(params) {
  return request.get('/job-seekers/posts/requirements', {
    params,
    ...richWebConfigVer2,
  });
}
