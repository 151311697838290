import PropTypes from 'prop-types';
import { Image as AntImage } from 'antd';
import { getImageUrl } from 'utils/tools';

const Image = ({
  maxWidth,
  maxHeight,
  preview,
  style,
  src,
  isPublic,
  ...props
}) =>
  preview ? (
    <AntImage
      alt=""
      preview={false}
      style={{ objectFit: 'contain', ...style }}
      {...props}
      src={getImageUrl({ src, maxWidth, maxHeight, isPublic })}
    />
  ) : (
    <img
      alt=""
      style={style}
      {...props}
      src={getImageUrl({ src, maxWidth, maxHeight, isPublic })}
    />
  );

Image.propTypes = {
  src: PropTypes.string,
  maxWidth: PropTypes.any,
  preview: PropTypes.bool,
  maxHeight: PropTypes.number,
  style: PropTypes.object,
  isPublic: PropTypes.bool,
};

Image.defaultProps = {
  preview: false,
};

export default Image;
