import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getCentreDetailsBasicInfoSelector } from '@redux/centreDetails/selectors';
import { submitUserReview } from '@redux/centreDetails/actions';
import { Avatar, Button, Form, Input, Modal, Typography } from 'antd';
import {
  CloseOutlineIcon,
  HeartFilledSecondIcon,
  HeartOutlineSecondIcon,
  LeftOutlineIcon,
} from '@uikit/commons/SVGIcons';
import FormInput from '@uikit/form/FormInput';
import { useEffect, useState } from 'react';
import { getCurrentUserSelector } from '@redux/auth/selectors';
import { getFeaturedImageDefault, getFullNameUser } from 'utils/dataUtils';
import { CheckCircleFilled, UserOutlined } from '@ant-design/icons';
import useWindowSize from 'hooks/useWindowSize';
import useAppDispatch from 'hooks/useAppDispatch';
import { StyledModal, WriteReviewModalStyles } from './styles';
import HeartRating from './HeartRating';

interface Props {
  onCancel?: () => void;
  visible?: boolean;
}

const WriteReviewModal = ({ onCancel, ...props }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { data } = useSelector(getCentreDetailsBasicInfoSelector);
  const user = useSelector(getCurrentUserSelector);
  const [isRecommended, setIsRecommended] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { isMobile } = useWindowSize();

  const onFinish = async (values) => {
    setLoading(true);
    await dispatch(
      submitUserReview({
        id: data?.id,
        body: values,
        slug: data?.slug,
        isMobile,
      }),
    ).then(({ payload }) => {
      if (payload?.id) {
        isMobile &&
          Modal.success({
            icon: <CheckCircleFilled />,
            content: `${t('writeCentreView.submitReviewSuccess')}!`,
            okText: t('button.done'),
            centered: true,
            width: 300,
            wrapClassName: 'success-modal-confirm',
          });
      }
    });
    setLoading(false);
    onCancel();
    form.resetFields();
  };

  useEffect(() => {
    form?.resetFields();
  }, [data, form]);

  return (
    <StyledModal
      {...props}
      title={t('writeCentreView.title')}
      centered
      onCancel={onCancel}
      afterClose={() => form.resetFields()}
      closeIcon={isMobile ? <LeftOutlineIcon /> : <CloseOutlineIcon />}
      footer={
        <div className="footer-btn">
          <Button
            type="primary"
            className="fw-bold size-16"
            onClick={form.submit}
            block
            loading={loading}
          >
            {t('button.submitReview')}
          </Button>
        </div>
      }
      wrapClassName="modal-screen"
    >
      <WriteReviewModalStyles>
        <div className="d-flex items-center">
          <div>
            <Avatar
              src={getFeaturedImageDefault(data?.images)}
              size={48}
              className="mr-20"
            />
          </div>
          <Typography.Title className="centre-name">
            {data?.name}
          </Typography.Title>
        </div>
        <Form
          layout="vertical"
          autoComplete="off"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            name="rating"
            label={t('writeCentreView.rateQuestion')}
            className="rating-field"
            rules={[
              {
                required: true,
                message: t('input.rating.validateMsg.required'),
              },
            ]}
          >
            <HeartRating />
          </Form.Item>
          <FormInput
            name="comment"
            label="writeCentreView.writeReview"
            placeholder="writeCentreView.writeReviewDesc"
            required
            messageRequire="input.comment.validateMsg.required"
            ContentComponent={Input.TextArea}
            rows={4}
            className="comment-textarea"
            formProps={{ className: 'comment-field' }}
          />

          <h3 className="field-title">
            {t('writeCentreView.recommendQuestion')}
          </h3>
          <Form.Item
            label={
              <div className="recommend-checkbox-label">
                {isRecommended ? (
                  <HeartFilledSecondIcon style={{ color: 'var(--primary)' }} />
                ) : (
                  <HeartOutlineSecondIcon />
                )}
                {t('writeCentreView.recommendDesc')}
              </div>
            }
            name="isRecommended"
            valuePropName="checked"
            htmlFor="is-recommend-checkbox"
            initialValue={false}
            className="recommend-checkbox"
          >
            <input
              type="checkbox"
              id="is-recommend-checkbox"
              checked={isRecommended}
              onChange={(e) => setIsRecommended(e.target.checked)}
            />
          </Form.Item>
          <h3 className="field-title">{t('writeCentreView.chooseName')}</h3>
          <Form.Item
            name="authorName"
            label={t('writeCentreView.chooseNameDesc')}
            className="author-name-field"
            initialValue={getFullNameUser(user)}
            rules={[
              {
                required: true,
                message: t('input.name.validateMsg1.required'),
              },
              {
                whitespace: true,
                message: t('input.name.validateMsg1.required'),
              },
            ]}
          >
            <div className="author-name">
              <div>
                <Avatar
                  size={48}
                  src={user?.avatar}
                  icon={!user?.avatar ? <UserOutlined /> : null}
                />
              </div>
              <Input className="size-16" defaultValue={getFullNameUser(user)} />
            </div>
          </Form.Item>
        </Form>
      </WriteReviewModalStyles>
    </StyledModal>
  );
};

export default WriteReviewModal;
