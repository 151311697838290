import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { PREFIX_URL_API } from 'configs/constants';
import { getBaseURLApi } from 'utils';

export const centresPopularApi = rtkCreator(
  'centresPopular',
  'centres/popular',
  {
    baseURL: getBaseURLApi(PREFIX_URL_API.public),
  },
);

export const { useGetAllCentresPopularQuery } = centresPopularApi;
