import { DEFAULT_CENTRE_COVER } from 'configs/localData';
import Image, { ImageProps } from 'next/image';
import React, { CSSProperties, useState } from 'react';

interface Props extends ImageProps {
  style?: CSSProperties;
  fallbackSrc?: string;
}

const NextImage = ({
  src,
  fallbackSrc = DEFAULT_CENTRE_COVER,
  ...props
}: Props) => {
  const [imgSrc, setImageSrc] = useState(src);
  return (
    <Image
      unoptimized={process.env.NEXT_PUBLIC_ENVIRONMENT === 'development'}
      src={imgSrc ?? fallbackSrc}
      onError={() => setImageSrc(fallbackSrc)}
      {...props}
    />
  );
};

export default NextImage;
