import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const brandsFeaturedApi = rtkCreator(
  'brandsFeatured',
  'job-seekers/screens/posts/featured-brands',
  richWebConfigVer2,
);

export const { useGetAllBrandsFeaturedQuery } = brandsFeaturedApi;

export const { getAllBrandsFeatured } = brandsFeaturedApi.endpoints;
