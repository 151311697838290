export const ICChildOutline = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 1.75C5.44365 1.75 1.75 5.44365 1.75 10C1.75 14.5563 5.44365 18.25 10 18.25C14.5563 18.25 18.25 14.5563 18.25 10C18.25 5.44365 14.5563 1.75 10 1.75ZM0.25 10C0.25 4.61522 4.61522 0.25 10 0.25C15.3848 0.25 19.75 4.61522 19.75 10C19.75 15.3848 15.3848 19.75 10 19.75C4.61522 19.75 0.25 15.3848 0.25 10Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 8C6.25 7.58579 6.58579 7.25 7 7.25H7.01C7.42421 7.25 7.76 7.58579 7.76 8C7.76 8.41421 7.42421 8.75 7.01 8.75H7C6.58579 8.75 6.25 8.41421 6.25 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.25 8C12.25 7.58579 12.5858 7.25 13 7.25H13.01C13.4242 7.25 13.76 7.58579 13.76 8C13.76 8.41421 13.4242 8.75 13.01 8.75H13C12.5858 8.75 12.25 8.41421 12.25 8Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.97511 12.4643C7.27098 12.1744 7.74583 12.1792 8.03571 12.4751C8.29176 12.7364 8.59738 12.944 8.93467 13.0858C9.27196 13.2275 9.63414 13.3005 10 13.3005C10.3659 13.3005 10.728 13.2275 11.0653 13.0858C11.4026 12.944 11.7082 12.7364 11.9643 12.4751C12.2542 12.1792 12.729 12.1744 13.0249 12.4643C13.3208 12.7542 13.3256 13.229 13.0357 13.5249C12.64 13.9288 12.1677 14.2496 11.6464 14.4686C11.1252 14.6877 10.5654 14.8005 10 14.8005C9.43458 14.8005 8.87485 14.6877 8.35358 14.4686C7.83232 14.2496 7.36 13.9288 6.96429 13.5249C6.6744 13.229 6.67924 12.7542 6.97511 12.4643Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.05546 1.05546C8.57118 0.539731 9.27065 0.25 10 0.25C10.4142 0.25 10.75 0.585786 10.75 1C10.75 1.41421 10.4142 1.75 10 1.75C9.66848 1.75 9.35054 1.8817 9.11612 2.11612C8.8817 2.35054 8.75 2.66848 8.75 3C8.75 3.33152 8.8817 3.64946 9.11612 3.88388C9.35054 4.1183 9.66848 4.25 10 4.25C10.4142 4.25 10.75 4.58579 10.75 5C10.75 5.41421 10.4142 5.75 10 5.75C9.27065 5.75 8.57118 5.46027 8.05546 4.94454C7.53973 4.42882 7.25 3.72935 7.25 3C7.25 2.27065 7.53973 1.57118 8.05546 1.05546Z"
      fill="currentColor"
    />
  </svg>
);

export const ICApplication = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.501 5.5C13.501 5.43306 13.4876 5.3668 13.4615 5.30518C13.4354 5.24355 13.3971 5.18782 13.3489 5.14131L9.85419 1.64656C9.8426 1.63498 9.83048 1.624 9.81781 1.61363C9.81487 1.61119 9.81169 1.60913 9.80869 1.60675C9.79888 1.59894 9.78879 1.5915 9.77844 1.58444C9.77569 1.58256 9.77281 1.58106 9.77006 1.57931C9.7591 1.57216 9.74787 1.56545 9.73637 1.55919C9.73438 1.55813 9.73231 1.55731 9.73031 1.55625C9.71786 1.5497 9.70514 1.54367 9.69219 1.53819C9.69075 1.53756 9.68931 1.53719 9.68787 1.53663C9.67421 1.53103 9.6603 1.52604 9.64619 1.52169L9.6415 1.5205C9.62745 1.51631 9.61323 1.51275 9.59888 1.50981C9.59487 1.509 9.59075 1.50863 9.58675 1.50794C9.57475 1.50588 9.56275 1.50375 9.55044 1.5025C9.53394 1.50083 9.51729 1.5 9.5005 1.5H3.5C3.23487 1.5003 2.98069 1.60575 2.79322 1.79322C2.60575 1.98069 2.5003 2.23487 2.5 2.5V13.5C2.5003 13.7651 2.60575 14.0193 2.79322 14.2068C2.98069 14.3942 3.23487 14.4997 3.5 14.5H12.5005C12.7656 14.4997 13.0198 14.3942 13.2073 14.2068C13.3947 14.0193 13.5002 13.7651 13.5005 13.5V5.51C13.5006 5.50663 13.501 5.50338 13.501 5.5ZM10.0005 3.20712L11.7934 5H10.0005V3.20712ZM12.5005 13.5H3.5V2.5H9.0005V5.5C9.0005 5.63261 9.05318 5.75979 9.14695 5.85355C9.24071 5.94732 9.36789 6 9.5005 6H12.5005L12.5011 13.5H12.5005Z"
      fill="currentColor"
    />
  </svg>
);

export const ICEnquiries = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 14.9782C7.89429 14.9779 7.78932 14.9606 7.68913 14.9269C6.73913 14.6119 2 12.7574 2 7.16669V3.5C2.0003 3.23487 2.10575 2.98069 2.29322 2.79322C2.48069 2.60575 2.73487 2.5003 3 2.5H13C13.2651 2.5003 13.5193 2.60575 13.7068 2.79322C13.8942 2.98069 13.9997 3.23487 14 3.5V7.16669C14 12.7575 9.26087 14.6119 8.31087 14.9269C8.21068 14.9606 8.10571 14.9779 8 14.9782ZM3 3.50006V7.16669C3 12.0625 7.155 13.6955 7.99988 13.9764C8.85269 13.6925 12.9999 12.0581 12.9999 7.16669V3.5L3 3.50006Z"
      fill="currentColor"
    />
    <path
      d="M8.55437 11.2111C8.62762 11.1042 8.66672 10.9785 8.66672 10.85C8.66672 10.6776 8.59648 10.5122 8.47146 10.3903C8.34644 10.2684 8.17687 10.1999 8.00006 10.1999C7.8682 10.1999 7.73931 10.2381 7.62968 10.3095C7.52004 10.3809 7.43459 10.4824 7.38414 10.6012C7.33368 10.72 7.32048 10.8507 7.3462 10.9768C7.37192 11.1029 7.43542 11.2187 7.52865 11.3096C7.62189 11.4005 7.74067 11.4624 7.87 11.4875C7.99932 11.5126 8.13336 11.4997 8.25518 11.4505C8.377 11.4013 8.48111 11.318 8.55437 11.2111Z"
      fill="currentColor"
    />
    <path
      d="M9.56276 8.1657C9.84527 7.82035 9.9994 7.39183 10 6.95008C10 6.69399 9.94827 6.44041 9.84776 6.20382C9.74725 5.96722 9.59993 5.75225 9.41421 5.57117C9.2285 5.39008 9.00802 5.24644 8.76537 5.14844C8.52272 5.05044 8.26264 5 8 5C7.73736 5 7.47728 5.05044 7.23463 5.14844C6.99198 5.24644 6.7715 5.39008 6.58579 5.57117C6.40007 5.75225 6.25275 5.96722 6.15224 6.20382C6.05173 6.44041 6 6.69399 6 6.95008C6 7.06501 6.04683 7.17524 6.13017 7.25651C6.21352 7.33778 6.32657 7.38343 6.44444 7.38343C6.56232 7.38343 6.67536 7.33778 6.75871 7.25651C6.84206 7.17524 6.88889 7.06501 6.88889 6.95008C6.88889 6.73581 6.95405 6.52635 7.07614 6.34819C7.19824 6.17003 7.37177 6.03117 7.5748 5.94917C7.77783 5.86717 8.00123 5.84572 8.21677 5.88752C8.4323 5.92932 8.63028 6.0325 8.78567 6.18402C8.94107 6.33553 9.04689 6.52857 9.08976 6.73872C9.13263 6.94888 9.11063 7.16671 9.02653 7.36467C8.94244 7.56263 8.80002 7.73183 8.6173 7.85088C8.43458 7.96992 8.21976 8.03346 8 8.03346C7.88213 8.03346 7.76908 8.07912 7.68573 8.16039C7.60238 8.24165 7.55556 8.35188 7.55556 8.46681V8.90016C7.55556 9.01509 7.60238 9.12532 7.68573 9.20659C7.76908 9.28786 7.88213 9.33351 8 9.33351C8.11787 9.33351 8.23092 9.28786 8.31427 9.20659C8.39762 9.12532 8.44444 9.01509 8.44444 8.90016V8.85163C8.88603 8.75284 9.28025 8.51105 9.56276 8.1657Z"
      fill="currentColor"
    />
  </svg>
);

export const ICFilterMessage = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 16.1265C21 16.3254 20.921 16.5162 20.7803 16.6568C20.6397 16.7975 20.4489 16.8765 20.25 16.8765H18.2625C18.0993 17.4158 17.7669 17.8883 17.3144 18.2241C16.862 18.5599 16.3135 18.7412 15.75 18.7412C15.1865 18.7412 14.638 18.5599 14.1856 18.2241C13.7331 17.8883 13.4007 17.4158 13.2375 16.8765H3.75C3.55109 16.8765 3.36032 16.7975 3.21967 16.6568C3.07902 16.5162 3 16.3254 3 16.1265C3 15.9276 3.07902 15.7368 3.21967 15.5961C3.36032 15.4555 3.55109 15.3765 3.75 15.3765H13.2375C13.4007 14.8372 13.7331 14.3647 14.1856 14.0288C14.638 13.693 15.1865 13.5117 15.75 13.5117C16.3135 13.5117 16.862 13.693 17.3144 14.0288C17.7669 14.3647 18.0993 14.8372 18.2625 15.3765H20.25C20.4489 15.3765 20.6397 15.4555 20.7803 15.5961C20.921 15.7368 21 15.9276 21 16.1265ZM3.75 8.62648H7.2375C7.40069 9.1658 7.73309 9.63829 8.18555 9.97411C8.63802 10.3099 9.18653 10.4912 9.75 10.4912C10.3135 10.4912 10.862 10.3099 11.3144 9.97411C11.7669 9.63829 12.0993 9.1658 12.2625 8.62648H20.25C20.4489 8.62648 20.6397 8.54746 20.7803 8.40681C20.921 8.26615 21 8.07539 21 7.87648C21 7.67756 20.921 7.4868 20.7803 7.34615C20.6397 7.20549 20.4489 7.12648 20.25 7.12648H12.2625C12.0993 6.58716 11.7669 6.11466 11.3144 5.77885C10.862 5.44303 10.3135 5.26172 9.75 5.26172C9.18653 5.26172 8.63802 5.44303 8.18555 5.77885C7.73309 6.11466 7.40069 6.58716 7.2375 7.12648H3.75C3.55109 7.12648 3.36032 7.20549 3.21967 7.34615C3.07902 7.4868 3 7.67756 3 7.87648C3 8.07539 3.07902 8.26615 3.21967 8.40681C3.36032 8.54746 3.55109 8.62648 3.75 8.62648Z"
      fill="#5C595A"
    />
  </svg>
);

export const ICRefresh = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5811 3.59904C13.6823 2.04904 12.2841 0.899038 10.5363 0.349038C8.83854 -0.200962 6.99091 -0.100962 5.34303 0.649038C5.09335 0.749038 4.99348 1.04904 5.09335 1.29904C5.19323 1.54904 5.49284 1.64904 5.74252 1.54904C7.14072 0.899038 8.73866 0.849038 10.2367 1.29904C11.7348 1.74904 12.9333 2.74904 13.6823 4.09904C14.4313 5.39904 14.6311 6.94904 14.2815 8.44904C13.932 9.89904 12.9832 11.199 11.6849 12.049C10.3865 12.899 8.83854 13.199 7.29053 12.999C5.74252 12.749 4.39425 11.949 3.39554 10.749C2.4967 9.49904 2.04728 7.99904 2.14715 6.49904C2.24702 5.39904 2.6465 4.34904 3.29567 3.49904V5.29904C3.29567 5.59904 3.49541 5.79904 3.79503 5.79904C4.09464 5.79904 4.29438 5.59904 4.29438 5.29904V2.19904V2.14904C4.29438 2.09904 4.29438 2.09904 4.29438 2.04904C4.29438 1.99904 4.24445 1.99904 4.24445 1.94904C4.24445 1.94904 4.24445 1.89904 4.19451 1.89904C4.19451 1.84904 4.14458 1.84904 4.09464 1.84904C4.0447 1.79904 4.0447 1.74904 3.99477 1.74904H3.94483C3.8949 1.74904 3.84496 1.74904 3.84496 1.74904H0.998625C0.69901 1.74904 0.499268 1.94904 0.499268 2.24904C0.499268 2.54904 0.69901 2.74904 0.998625 2.74904H2.6465C1.7976 3.79904 1.29824 5.09904 1.14843 6.44904C0.998625 8.19904 1.54792 9.94904 2.6465 11.349C3.74509 12.749 5.34303 13.649 7.14072 13.949C7.54021 13.999 7.88976 14.049 8.28924 14.049C9.68744 14.049 11.0357 13.649 12.2342 12.899C13.7322 11.899 14.8308 10.449 15.2303 8.69904C15.7297 6.94904 15.48 5.14904 14.5811 3.59904Z"
      fill="currentColor"
    />
  </svg>
);

export const ICAttactment = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0644 2.53545C15.5194 2.347 16.007 2.25 16.4995 2.25C16.9919 2.25 17.4795 2.347 17.9345 2.53545C18.3895 2.72391 18.8029 3.00013 19.1511 3.34835C19.4993 3.69657 19.7755 4.10997 19.964 4.56494C20.1525 5.01991 20.2495 5.50754 20.2495 6C20.2495 6.49246 20.1525 6.98009 19.964 7.43506C19.7759 7.8892 19.5003 8.30192 19.153 8.64974C19.1524 8.65038 19.1517 8.65102 19.1511 8.65165L9.841 18.0915C9.41905 18.5134 8.84674 18.7505 8.25 18.7505C7.65326 18.7505 7.08097 18.5134 6.65901 18.0914C6.23705 17.6695 6 17.0972 6 16.5005C6 15.9049 6.23615 15.3336 6.65661 14.9119L14.4648 6.97405C14.7552 6.67876 15.2301 6.67485 15.5254 6.96532C15.8207 7.25579 15.8246 7.73065 15.5341 8.02595L7.71969 15.9701C7.57904 16.1108 7.5 16.3015 7.5 16.5005C7.5 16.6994 7.57902 16.8901 7.71967 17.0308C7.86032 17.1714 8.05109 17.2505 8.25 17.2505C8.4482 17.2505 8.6383 17.172 8.77881 17.0323C8.77932 17.0318 8.77983 17.0313 8.78033 17.0308L18.0904 7.59098C18.2994 7.38205 18.4651 7.13402 18.5782 6.86104C18.6913 6.58806 18.7495 6.29547 18.7495 6C18.7495 5.70452 18.6913 5.41194 18.5782 5.13896C18.4651 4.86598 18.2994 4.61794 18.0904 4.40901C17.8815 4.20008 17.6335 4.03434 17.3605 3.92127C17.0875 3.8082 16.7949 3.75 16.4995 3.75C16.204 3.75 15.9114 3.8082 15.6384 3.92127C15.3662 4.03403 15.1188 4.19917 14.9102 4.40729L5.59836 13.8488C4.8951 14.5521 4.5 15.5059 4.5 16.5005C4.5 17.495 4.89509 18.4488 5.59835 19.1521C6.30161 19.8554 7.25544 20.2505 8.25 20.2505C9.24456 20.2505 10.1984 19.8554 10.9017 19.1521L18.5945 11.4693C18.8876 11.1766 19.3624 11.1769 19.6551 11.47C19.9478 11.7631 19.9475 12.238 19.6544 12.5307L11.9623 20.2128C10.9777 21.1973 9.64239 21.7505 8.25 21.7505C6.85761 21.7505 5.52226 21.1973 4.53769 20.2128C3.55312 19.2282 3 17.8928 3 16.5005C3 15.109 3.55241 13.7744 4.53579 12.7901L13.8478 3.34834C14.196 3.00012 14.6094 2.72391 15.0644 2.53545Z"
      fill="#DB147F"
    />
  </svg>
);

export const ICSend = (props) => (
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M31.4219 16.038L7.66822 2.73605C7.26854 2.51225 6.81008 2.41577 6.35411 2.4595C5.89813 2.50323 5.46636 2.68508 5.11651 2.98076C4.76665 3.27644 4.51538 3.67186 4.39626 4.11416C4.27714 4.55647 4.29586 5.0246 4.44991 5.45598L8.52858 16.8763H19.125C19.4234 16.8763 19.7095 16.9948 19.9205 17.2058C20.1315 17.4168 20.25 17.7029 20.25 18.0013C20.25 18.2996 20.1315 18.5858 19.9205 18.7968C19.7095 19.0077 19.4234 19.1263 19.125 19.1263H8.52858L4.44991 30.5466C4.32933 30.886 4.29182 31.2495 4.34051 31.6064C4.38921 31.9633 4.52269 32.3034 4.72979 32.5982C4.93689 32.893 5.21159 33.1339 5.53089 33.3007C5.85019 33.4675 6.2048 33.5554 6.56505 33.5571C6.95164 33.5564 7.33156 33.4563 7.66835 33.2665L31.4218 19.9645C31.7707 19.7691 32.0613 19.4842 32.2635 19.1391C32.4658 18.794 32.5724 18.4013 32.5724 18.0013C32.5724 17.6013 32.4659 17.2086 32.2636 16.8635C32.0614 16.5184 31.7708 16.2335 31.4219 16.038Z"
      fill="#DB147F"
    />
  </svg>
);

export const ICEmail = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.2309 15.75V8.325C23.3058 7.425 23.156 6.6 22.8562 5.775C22.5565 4.95 22.1069 4.2 21.5823 3.6C20.4583 2.25 18.8097 1.5 17.0861 1.5H6.81984C3.37277 1.5 0.75 4.425 0.75 8.25V15.75C0.75 19.65 3.37277 22.5 6.81984 22.5H17.0861C17.9104 22.5 18.7347 22.275 19.559 21.975C20.3084 21.6 20.9828 21.075 21.5823 20.475C22.1818 19.8 22.5565 19.125 22.8562 18.3C23.156 17.4 23.3058 16.575 23.2309 15.75ZM11.9905 13.8C11.2411 13.8 10.5667 13.575 10.0421 13.125L5.32112 9.225C5.02137 8.925 4.94643 8.4 5.24618 8.1C5.54593 7.8 5.99554 7.725 6.29529 8.025L11.0163 11.925C11.6158 12.375 12.4401 12.375 13.0396 11.925L17.6856 8.025C17.9854 7.725 18.435 7.8 18.7347 8.1C19.0345 8.4 18.9595 8.925 18.6598 9.15L14.0137 13.05C13.4142 13.5 12.7398 13.8 11.9905 13.8Z"
      fill="#DB147F"
    />
  </svg>
);

export const ICDanger = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.76 15.92L15.36 4.4C14.5 2.85 13.31 2 12 2C10.69 2 9.49998 2.85 8.63998 4.4L2.23998 15.92C1.42998 17.39 1.33998 18.8 1.98998 19.91C2.63998 21.02 3.91998 21.63 5.59998 21.63H18.4C20.08 21.63 21.36 21.02 22.01 19.91C22.66 18.8 22.57 17.38 21.76 15.92ZM11.25 9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V14C12.75 14.41 12.41 14.75 12 14.75C11.59 14.75 11.25 14.41 11.25 14V9ZM12.71 17.71C12.66 17.75 12.61 17.79 12.56 17.83C12.5 17.87 12.44 17.9 12.38 17.92C12.32 17.95 12.26 17.97 12.19 17.98C12.13 17.99 12.06 18 12 18C11.94 18 11.87 17.99 11.8 17.98C11.74 17.97 11.68 17.95 11.62 17.92C11.56 17.9 11.5 17.87 11.44 17.83C11.39 17.79 11.34 17.75 11.29 17.71C11.11 17.52 11 17.26 11 17C11 16.74 11.11 16.48 11.29 16.29C11.34 16.25 11.39 16.21 11.44 16.17C11.5 16.13 11.56 16.1 11.62 16.08C11.68 16.05 11.74 16.03 11.8 16.02C11.93 15.99 12.07 15.99 12.19 16.02C12.26 16.03 12.32 16.05 12.38 16.08C12.44 16.1 12.5 16.13 12.56 16.17C12.61 16.21 12.66 16.25 12.71 16.29C12.89 16.48 13 16.74 13 17C13 17.26 12.89 17.52 12.71 17.71Z"
      fill="#FB8429"
    />
  </svg>
);

export const ICFileInbox = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 17 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.30187 21.624H14.9711C16.019 21.624 16.8714 20.7716 16.8714 19.7237V6.33655H12.4372C11.3893 6.33655 10.5368 5.48408 10.5368 4.43617V0.00195312H2.30187C1.25396 0.00195312 0.401489 0.85442 0.401489 1.90233V19.7237C0.401489 20.7716 1.25396 21.624 2.30187 21.624ZM4.8357 8.91261H12.4372C12.7874 8.91261 13.0707 9.19594 13.0707 9.54607C13.0707 9.89621 12.7874 10.1795 12.4372 10.1795H4.8357C4.48557 10.1795 4.20225 9.89621 4.20225 9.54607C4.20225 9.19594 4.48557 8.91261 4.8357 8.91261ZM4.8357 11.4465H12.4372C12.7874 11.4465 13.0707 11.7298 13.0707 12.0799C13.0707 12.43 12.7874 12.7134 12.4372 12.7134H4.8357C4.48557 12.7134 4.20225 12.43 4.20225 12.0799C4.20225 11.7298 4.48557 11.4465 4.8357 11.4465ZM4.8357 13.9803H12.4372C12.7874 13.9803 13.0707 14.2636 13.0707 14.6137C13.0707 14.9639 12.7874 15.2472 12.4372 15.2472H4.8357C4.48557 15.2472 4.20225 14.9639 4.20225 14.6137C4.20225 14.2636 4.48557 13.9803 4.8357 13.9803ZM4.8357 16.5141H9.90338C10.2535 16.5141 10.5368 16.7975 10.5368 17.1476C10.5368 17.4977 10.2535 17.781 9.90338 17.781H4.8357C4.48557 17.781 4.20225 17.4977 4.20225 17.1476C4.20225 16.7975 4.48557 16.5141 4.8357 16.5141Z"
      fill="currentColor"
    />
    <path
      d="M12.4373 5.06565H16.5003L11.8038 0.369141V4.43219C11.8038 4.78169 12.0878 5.06565 12.4373 5.06565Z"
      fill="currentColor"
    />
  </svg>
);

export const ICGreyMail = (props) => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.499023"
      y="0.5"
      width="39"
      height="39"
      rx="19.5"
      fill="#F7F9FF"
    />
    <path
      d="M29.3581 23.125V16.9375C29.4206 16.1875 29.2957 15.5 29.0459 14.8125C28.7961 14.125 28.4214 13.5 27.9843 13C27.0476 11.875 25.6737 11.25 24.2375 11.25H15.6822C12.8097 11.25 10.624 13.6875 10.624 16.875V23.125C10.624 26.375 12.8097 28.75 15.6822 28.75H24.2375C24.9244 28.75 25.6113 28.5625 26.2982 28.3125C26.9227 28 27.4847 27.5625 27.9843 27.0625C28.4839 26.5 28.7961 25.9375 29.0459 25.25C29.2957 24.5 29.4206 23.8125 29.3581 23.125ZM19.9911 21.5C19.3666 21.5 18.8046 21.3125 18.3674 20.9375L14.4333 17.6875C14.1835 17.4375 14.1211 17 14.3708 16.75C14.6206 16.5 14.9953 16.4375 15.2451 16.6875L19.1793 19.9375C19.6788 20.3125 20.3657 20.3125 20.8653 19.9375L24.737 16.6875C24.9868 16.4375 25.3615 16.5 25.6113 16.75C25.8611 17 25.7986 17.4375 25.5488 17.625L21.6771 20.875C21.1776 21.25 20.6155 21.5 19.9911 21.5Z"
      fill="#5C595A"
    />
    <rect
      x="0.499023"
      y="0.5"
      width="39"
      height="39"
      rx="19.5"
      stroke="#E9ECF7"
    />
  </svg>
);

export const ICDollarCircleFill = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.99942 0.734375C3.98592 0.734375 0.732422 3.98787 0.732422 8.00138C0.732422 12.0149 3.98592 15.2684 7.99942 15.2684C12.0129 15.2684 15.2664 12.0149 15.2664 8.00138C15.2664 3.98787 12.0129 0.734375 7.99942 0.734375ZM8.62692 11.9414V12.9649H7.64792V11.9529C6.04142 11.7329 5.33792 10.4129 5.33792 10.4129L6.33792 9.57638C6.33792 9.57638 6.97642 10.6879 8.13142 10.6879C8.76942 10.6879 9.25342 10.3464 9.25342 9.76288C9.25342 8.39888 5.57842 8.56437 5.57842 6.03387C5.57842 4.93388 6.44842 4.14138 7.64742 3.95388V2.93138H8.62642V3.95388C9.46242 4.06387 10.4524 4.50388 10.4524 5.45038V6.17638H9.15442V5.82438C9.15442 5.46138 8.69192 5.21938 8.17442 5.21938C7.51442 5.21938 7.03092 5.54938 7.03092 6.01138C7.03092 7.40838 10.7059 7.06738 10.7059 9.71887C10.7059 10.8089 9.89192 11.7539 8.62692 11.9414Z"
      fill="currentColor"
    />
  </svg>
);

export const ICFileFill = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.0869 6.11786H6.62662C6.22105 6.11786 5.83209 5.95675 5.5453 5.66996C5.25852 5.38318 5.0974 4.99421 5.0974 4.58864V0.128412C5.0974 0.0946137 5.08398 0.0622002 5.06008 0.0383015C5.03618 0.0144027 5.00377 0.000976562 4.96997 0.000976562H2.03896C1.4982 0.000976563 0.979577 0.215795 0.597198 0.598174C0.214818 0.980554 0 1.49917 0 2.03994V12.2347C0 12.7755 0.214818 13.2941 0.597198 13.6765C0.979577 14.0589 1.4982 14.2737 2.03896 14.2737H9.17533C9.71609 14.2737 10.2347 14.0589 10.6171 13.6765C10.9995 13.2941 11.2143 12.7755 11.2143 12.2347V6.24529C11.2143 6.2115 11.2009 6.17908 11.177 6.15518C11.1531 6.13129 11.1206 6.11786 11.0869 6.11786Z"
      fill="currentColor"
    />
    <path
      d="M10.8065 4.98881L6.22485 0.407201C6.21594 0.398344 6.20461 0.392318 6.19229 0.389882C6.17996 0.387445 6.16719 0.388707 6.15558 0.393507C6.14397 0.398308 6.13404 0.406433 6.12703 0.416862C6.12003 0.427292 6.11626 0.439559 6.11621 0.452122V4.58771C6.11621 4.7229 6.16992 4.85255 6.26551 4.94815C6.3611 5.04374 6.49076 5.09745 6.62595 5.09745H10.7615C10.7741 5.0974 10.7864 5.09363 10.7968 5.08663C10.8072 5.07962 10.8154 5.06969 10.8202 5.05808C10.825 5.04647 10.8262 5.0337 10.8238 5.02137C10.8213 5.00905 10.8153 4.99772 10.8065 4.98881V4.98881Z"
      fill="currentColor"
    />
  </svg>
);

export const ICMore = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 4 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.00033 3.33366C2.73366 3.33366 3.33366 2.73366 3.33366 2.00033C3.33366 1.26699 2.73366 0.666992 2.00033 0.666992C1.26699 0.666992 0.666992 1.26699 0.666992 2.00033C0.666992 2.73366 1.26699 3.33366 2.00033 3.33366ZM2.00033 4.66699C1.26699 4.66699 0.666992 5.26699 0.666992 6.00033C0.666992 6.73366 1.26699 7.33366 2.00033 7.33366C2.73366 7.33366 3.33366 6.73366 3.33366 6.00033C3.33366 5.26699 2.73366 4.66699 2.00033 4.66699ZM2.00033 8.66699C1.26699 8.66699 0.666992 9.26699 0.666992 10.0003C0.666992 10.7337 1.26699 11.3337 2.00033 11.3337C2.73366 11.3337 3.33366 10.7337 3.33366 10.0003C3.33366 9.26699 2.73366 8.66699 2.00033 8.66699Z"
      fill="#00DAF7"
    />
  </svg>
);

export const ICVacanciesFill = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.66667 5.99935C7.13943 5.99935 8.33333 4.80544 8.33333 3.33268C8.33333 1.85992 7.13943 0.666016 5.66667 0.666016C4.19391 0.666016 3 1.85992 3 3.33268C3 4.80544 4.19391 5.99935 5.66667 5.99935Z"
      fill="currentColor"
    />
    <path
      d="M5.89967 7.33937C4.07967 7.2727 0.333008 8.17937 0.333008 9.99937V10.666C0.333008 11.0327 0.633008 11.3327 0.999674 11.3327H6.69301C5.04634 9.4927 5.87301 7.40604 5.89967 7.33937ZM11.953 10.0127C12.2663 9.47937 12.4197 8.8327 12.273 8.1327C12.0463 7.03937 11.1263 6.16604 10.0197 6.02604C8.26634 5.79937 6.78634 7.2727 7.01967 9.02604C7.16634 10.1327 8.03301 11.0527 9.12634 11.2794C9.82634 11.426 10.473 11.2727 11.0063 10.9594L12.2463 12.1994C12.371 12.324 12.5401 12.394 12.7163 12.394C12.8926 12.394 13.0617 12.324 13.1863 12.1994C13.311 12.0747 13.381 11.9057 13.381 11.7294C13.381 11.5531 13.311 11.384 13.1863 11.2594L11.953 10.0127ZM9.66634 9.99937C8.93301 9.99937 8.33301 9.39937 8.33301 8.66604C8.33301 7.9327 8.93301 7.3327 9.66634 7.3327C10.3997 7.3327 10.9997 7.9327 10.9997 8.66604C10.9997 9.39937 10.3997 9.99937 9.66634 9.99937Z"
      fill="currentColor"
    />
  </svg>
);

export const ICFlagFill = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0 0.799203V11.9744C0 12.1861 0.0840986 12.3891 0.233795 12.5388C0.383492 12.6885 0.586524 12.7726 0.798226 12.7726C1.00993 12.7726 1.21296 12.6885 1.36266 12.5388C1.51235 12.3891 1.59645 12.1861 1.59645 11.9744V0.799203C1.59645 0.5875 1.51235 0.384468 1.36266 0.234772C1.21296 0.0850751 1.00993 0.000976563 0.798226 0.000976562C0.586524 0.000976563 0.383492 0.0850751 0.233795 0.234772C0.0840986 0.384468 0 0.5875 0 0.799203Z"
      fill="currentColor"
    />
    <path
      d="M2.6505 7.90621C2.72871 7.95369 2.81784 7.9802 2.90928 7.98318C3.00073 7.98617 3.0914 7.96552 3.17254 7.92324L9.29227 4.73034C9.37862 4.68539 9.45098 4.61761 9.50149 4.53438C9.55199 4.45116 9.57869 4.35567 9.57869 4.25832C9.57869 4.16097 9.55199 4.06549 9.50149 3.98226C9.45098 3.89904 9.37862 3.83126 9.29227 3.7863L3.17254 0.5934C3.09142 0.551145 3.00078 0.530509 2.90937 0.533485C2.81795 0.536461 2.72885 0.562948 2.65065 0.610389C2.57246 0.657831 2.50781 0.724627 2.46295 0.80433C2.41808 0.884034 2.39452 0.973955 2.39453 1.06542V7.45123C2.39451 7.54267 2.41805 7.63257 2.46289 7.71227C2.50772 7.79196 2.57233 7.85876 2.6505 7.90621Z"
      fill="currentColor"
    />
  </svg>
);
