export const ICCircle = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5Z"
      fill="#FF80C4"
    />
    <rect x="9" y="9" width="6" height="6" rx="3" fill="#FFF0FB" />
  </svg>
);

export const ICMyDocument = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 7.80983V16.1898C22 19.3998 19.4 21.9998 16.19 21.9998H7.81C4.6 21.9998 2 19.3998 2 16.1898V7.80983C2 5.31983 3.02 3.50983 4.83 2.62983C5.49 2.30983 6.25 2.80983 6.25 3.53983V12.4198C6.25 13.6098 6.71 14.5598 7.54 15.0398C8.38 15.5098 9.44 15.4098 10.52 14.7598L11.82 13.9798C11.9 13.9398 12.1 13.9398 12.16 13.9698L13.48 14.7598C14.2 15.1898 14.82 15.3298 15.32 15.3298C15.84 15.3298 16.24 15.1698 16.48 15.0298C17.29 14.5598 17.75 13.6098 17.75 12.4198V3.53983C17.75 2.80983 18.52 2.30983 19.17 2.62983C20.98 3.50983 22 5.31983 22 7.80983Z"
      fill="#1FADA8"
    />
    <path
      d="M15.25 2C15.8 2 16.25 2.45 16.25 3V12.42C16.25 13.06 16.06 13.54 15.73 13.73C15.39 13.93 14.85 13.83 14.25 13.47L12.93 12.68C12.42 12.37 11.58 12.37 11.07 12.68L9.75 13.47C9.15 13.83 8.61 13.92 8.27 13.73C7.94 13.54 7.75 13.06 7.75 12.42V3C7.75 2.45 8.2 2 8.75 2H15.25Z"
      fill="#1FADA8"
    />
  </svg>
);

export const ICQualification = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="56"
    height="56"
    viewBox="0 0 56 57"
    fill="none"
    {...props}
  >
    <rect x="0.5" y="1.01953" width="55" height="55" rx="27.5" fill="white" />
    <path
      d="M38 21.3692V33.2592C38 34.2292 37.21 35.1192 36.24 35.2392L35.93 35.2792C34.29 35.4992 31.98 36.1792 30.12 36.9592C29.47 37.2292 28.75 36.7392 28.75 36.0292V22.1192C28.75 21.7492 28.96 21.4092 29.29 21.2292C31.12 20.2392 33.89 19.3592 35.77 19.1992H35.83C37.03 19.1992 38 20.1692 38 21.3692Z"
      fill="#FF80C4"
    />
    <path
      d="M26.7122 21.2292C24.8822 20.2392 22.1122 19.3592 20.2322 19.1992H20.1622C18.9622 19.1992 17.9922 20.1692 17.9922 21.3692V33.2592C17.9922 34.2292 18.7822 35.1192 19.7522 35.2392L20.0622 35.2792C21.7022 35.4992 24.0122 36.1792 25.8722 36.9592C26.5222 37.2292 27.2422 36.7392 27.2422 36.0292V22.1192C27.2422 21.7392 27.0422 21.4092 26.7122 21.2292ZM21.0022 24.2592H23.2522C23.6622 24.2592 24.0022 24.5992 24.0022 25.0092C24.0022 25.4292 23.6622 25.7592 23.2522 25.7592H21.0022C20.5922 25.7592 20.2522 25.4292 20.2522 25.0092C20.2522 24.5992 20.5922 24.2592 21.0022 24.2592ZM24.0022 28.7592H21.0022C20.5922 28.7592 20.2522 28.4292 20.2522 28.0092C20.2522 27.5992 20.5922 27.2592 21.0022 27.2592H24.0022C24.4122 27.2592 24.7522 27.5992 24.7522 28.0092C24.7522 28.4292 24.4122 28.7592 24.0022 28.7592Z"
      fill="#FF80C4"
    />
    <rect
      x="0.5"
      y="1.01953"
      width="55"
      height="55"
      rx="27.5"
      stroke="#EBECF0"
    />
  </svg>
);

export const ICCalendar = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.33203 3.83594C5.0587 3.83594 4.83203 3.60927 4.83203 3.33594V1.33594C4.83203 1.0626 5.0587 0.835938 5.33203 0.835938C5.60536 0.835938 5.83203 1.0626 5.83203 1.33594V3.33594C5.83203 3.60927 5.60536 3.83594 5.33203 3.83594Z"
      fill="#2BADA7"
    />
    <path
      d="M10.668 3.83594C10.3946 3.83594 10.168 3.60927 10.168 3.33594V1.33594C10.168 1.0626 10.3946 0.835938 10.668 0.835938C10.9413 0.835938 11.168 1.0626 11.168 1.33594V3.33594C11.168 3.60927 10.9413 3.83594 10.668 3.83594Z"
      fill="#2BADA7"
    />
    <path
      d="M5.66667 9.66557C5.58 9.66557 5.49333 9.64558 5.41333 9.61225C5.32667 9.57891 5.26 9.53223 5.19333 9.47223C5.07333 9.34557 5 9.17891 5 8.99891C5 8.91224 5.02 8.82557 5.05333 8.74557C5.08667 8.66557 5.13333 8.59224 5.19333 8.52558C5.26 8.46558 5.32667 8.4189 5.41333 8.38556C5.65333 8.28556 5.95333 8.33891 6.14 8.52558C6.26 8.65224 6.33333 8.82557 6.33333 8.99891C6.33333 9.03891 6.32667 9.08558 6.32 9.13225C6.31333 9.17225 6.3 9.21224 6.28 9.25224C6.26667 9.29224 6.24667 9.33224 6.22 9.37224C6.2 9.40557 6.16667 9.4389 6.14 9.47223C6.01333 9.59223 5.84 9.66557 5.66667 9.66557Z"
      fill="#2BADA7"
    />
    <path
      d="M7.9987 9.66854C7.91203 9.66854 7.82536 9.64854 7.74536 9.61521C7.6587 9.58188 7.59203 9.5352 7.52537 9.4752C7.40537 9.34853 7.33203 9.18187 7.33203 9.00187C7.33203 8.9152 7.35203 8.82853 7.38537 8.74853C7.4187 8.66853 7.46537 8.59521 7.52537 8.52854C7.59203 8.46854 7.6587 8.42186 7.74536 8.38853C7.98536 8.28186 8.28536 8.34187 8.47203 8.52854C8.59203 8.65521 8.66536 8.82854 8.66536 9.00187C8.66536 9.04187 8.6587 9.08854 8.65203 9.13521C8.64536 9.17521 8.63203 9.21521 8.61203 9.25521C8.5987 9.29521 8.5787 9.3352 8.55203 9.3752C8.53203 9.40853 8.4987 9.44186 8.47203 9.4752C8.34536 9.5952 8.17203 9.66854 7.9987 9.66854Z"
      fill="#2BADA7"
    />
    <path
      d="M10.3346 9.66854C10.248 9.66854 10.1613 9.64854 10.0813 9.61521C9.99463 9.58188 9.92797 9.5352 9.8613 9.4752C9.83464 9.44186 9.80797 9.40853 9.7813 9.3752C9.75463 9.3352 9.73464 9.29521 9.7213 9.25521C9.7013 9.21521 9.68797 9.17521 9.6813 9.13521C9.67464 9.08854 9.66797 9.04187 9.66797 9.00187C9.66797 8.82854 9.7413 8.65521 9.8613 8.52854C9.92797 8.46854 9.99463 8.42186 10.0813 8.38853C10.328 8.28186 10.6213 8.34187 10.808 8.52854C10.928 8.65521 11.0013 8.82854 11.0013 9.00187C11.0013 9.04187 10.9946 9.08854 10.988 9.13521C10.9813 9.17521 10.968 9.21521 10.948 9.25521C10.9346 9.29521 10.9146 9.3352 10.888 9.3752C10.868 9.40853 10.8346 9.44186 10.808 9.4752C10.6813 9.5952 10.508 9.66854 10.3346 9.66854Z"
      fill="#2BADA7"
    />
    <path
      d="M5.66667 11.9969C5.58 11.9969 5.49333 11.9769 5.41333 11.9435C5.33333 11.9102 5.26 11.8635 5.19333 11.8035C5.07333 11.6769 5 11.5035 5 11.3302C5 11.2435 5.02 11.1569 5.05333 11.0769C5.08667 10.9902 5.13333 10.9169 5.19333 10.8569C5.44 10.6102 5.89333 10.6102 6.14 10.8569C6.26 10.9835 6.33333 11.1569 6.33333 11.3302C6.33333 11.5035 6.26 11.6769 6.14 11.8035C6.01333 11.9235 5.84 11.9969 5.66667 11.9969Z"
      fill="#2BADA7"
    />
    <path
      d="M7.9987 11.9969C7.82536 11.9969 7.65203 11.9235 7.52537 11.8035C7.40537 11.6769 7.33203 11.5035 7.33203 11.3302C7.33203 11.2435 7.35203 11.1569 7.38537 11.0769C7.4187 10.9902 7.46537 10.9169 7.52537 10.8569C7.77203 10.6102 8.22536 10.6102 8.47203 10.8569C8.53203 10.9169 8.5787 10.9902 8.61203 11.0769C8.64536 11.1569 8.66536 11.2435 8.66536 11.3302C8.66536 11.5035 8.59203 11.6769 8.47203 11.8035C8.34536 11.9235 8.17203 11.9969 7.9987 11.9969Z"
      fill="#2BADA7"
    />
    <path
      d="M10.3346 11.998C10.1613 11.998 9.98797 11.9246 9.8613 11.8046C9.8013 11.7446 9.75464 11.6713 9.7213 11.5847C9.68797 11.5047 9.66797 11.418 9.66797 11.3313C9.66797 11.2447 9.68797 11.158 9.7213 11.078C9.75464 10.9913 9.8013 10.918 9.8613 10.858C10.0146 10.7047 10.248 10.6313 10.4613 10.678C10.508 10.6846 10.548 10.698 10.588 10.718C10.628 10.7313 10.668 10.7513 10.708 10.778C10.7413 10.798 10.7746 10.8313 10.808 10.858C10.928 10.9847 11.0013 11.158 11.0013 11.3313C11.0013 11.5047 10.928 11.678 10.808 11.8046C10.6813 11.9246 10.508 11.998 10.3346 11.998Z"
      fill="#2BADA7"
    />
    <path
      d="M13.6654 6.5625H2.33203C2.0587 6.5625 1.83203 6.33583 1.83203 6.0625C1.83203 5.78917 2.0587 5.5625 2.33203 5.5625H13.6654C13.9387 5.5625 14.1654 5.78917 14.1654 6.0625C14.1654 6.33583 13.9387 6.5625 13.6654 6.5625Z"
      fill="#2BADA7"
    />
    <path
      d="M10.6667 15.1693H5.33333C2.9 15.1693 1.5 13.7693 1.5 11.3359V5.66927C1.5 3.23594 2.9 1.83594 5.33333 1.83594H10.6667C13.1 1.83594 14.5 3.23594 14.5 5.66927V11.3359C14.5 13.7693 13.1 15.1693 10.6667 15.1693ZM5.33333 2.83594C3.42667 2.83594 2.5 3.7626 2.5 5.66927V11.3359C2.5 13.2426 3.42667 14.1693 5.33333 14.1693H10.6667C12.5733 14.1693 13.5 13.2426 13.5 11.3359V5.66927C13.5 3.7626 12.5733 2.83594 10.6667 2.83594H5.33333Z"
      fill="#2BADA7"
    />
  </svg>
);

export const ICCertification = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.8114 5.49V6.23L14.2714 4.18C12.9314 3.41 11.0614 3.41 9.73141 4.18L6.19141 6.24V5.49C6.19141 3.24 7.42141 2 9.67141 2H14.3314C16.5814 2 17.8114 3.24 17.8114 5.49Z"
      fill="currentColor"
    />
    <path
      d="M17.84 7.97219L17.7 7.90219L16.34 7.12219L13.52 5.49219C12.66 4.99219 11.34 4.99219 10.48 5.49219L7.66 7.11219L6.3 7.91219L6.12 8.00219C4.37 9.18219 4.25 9.40219 4.25 11.2922V15.8122C4.25 17.7022 4.37 17.9222 6.16 19.1322L10.48 21.6222C10.91 21.8822 11.45 21.9922 12 21.9922C12.54 21.9922 13.09 21.8722 13.52 21.6222L17.88 19.1022C19.64 17.9222 19.75 17.7122 19.75 15.8122V11.2922C19.75 9.40219 19.63 9.18219 17.84 7.97219ZM14.79 13.5022L14.18 14.2522C14.08 14.3622 14.01 14.5722 14.02 14.7222L14.08 15.6822C14.12 16.2722 13.7 16.5722 13.15 16.3622L12.26 16.0022C12.12 15.9522 11.89 15.9522 11.75 16.0022L10.86 16.3522C10.31 16.5722 9.89 16.2622 9.93 15.6722L9.99 14.7122C10 14.5622 9.93 14.3522 9.83 14.2422L9.21 13.5022C8.83 13.0522 9 12.5522 9.57 12.4022L10.5 12.1622C10.65 12.1222 10.82 11.9822 10.9 11.8622L11.42 11.0622C11.74 10.5622 12.25 10.5622 12.58 11.0622L13.1 11.8622C13.18 11.9922 13.36 12.1222 13.5 12.1622L14.43 12.4022C15 12.5522 15.17 13.0522 14.79 13.5022Z"
      fill="currentColor"
    />
  </svg>
);

export const ICExperience = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.091 6.98344C20.241 6.04344 18.821 5.57344 16.761 5.57344H16.521V5.53344C16.521 3.85344 16.521 1.77344 12.761 1.77344H11.241C7.48101 1.77344 7.48101 3.86344 7.48101 5.53344V5.58344H7.24101C5.17101 5.58344 3.76101 6.05344 2.91101 6.99344C1.92101 8.09344 1.95101 9.57344 2.05101 10.5834L2.06101 10.6534L2.13847 11.4667C2.15273 11.6165 2.2334 11.7518 2.35929 11.8342C2.59909 11.9911 3.00044 12.2498 3.24101 12.3834C3.38101 12.4734 3.53101 12.5534 3.68101 12.6334C5.39101 13.5734 7.27101 14.2034 9.18101 14.5134C9.27101 15.4534 9.68101 16.5534 11.871 16.5534C14.061 16.5534 14.491 15.4634 14.561 14.4934C16.601 14.1634 18.571 13.4534 20.351 12.4134C20.411 12.3834 20.451 12.3534 20.501 12.3234C20.8977 12.0992 21.3093 11.8229 21.6845 11.5523C21.7975 11.4708 21.8698 11.3447 21.8852 11.2062L21.901 11.0634L21.951 10.5934C21.961 10.5334 21.961 10.4834 21.971 10.4134C22.051 9.40344 22.031 8.02344 21.091 6.98344ZM13.091 13.8334C13.091 14.8934 13.091 15.0534 11.861 15.0534C10.631 15.0534 10.631 14.8634 10.631 13.8434V12.5834H13.091V13.8334ZM8.91101 5.57344V5.53344C8.91101 3.83344 8.91101 3.20344 11.241 3.20344H12.761C15.091 3.20344 15.091 3.84344 15.091 5.53344V5.58344H8.91101V5.57344Z"
      fill="#1FADA8"
    />
    <path
      d="M20.8733 13.7349C21.2269 13.5666 21.6342 13.8469 21.5988 14.2369L21.2398 18.1907C21.0298 20.1907 20.2098 22.2307 15.8098 22.2307H8.18984C3.78984 22.2307 2.96984 20.1907 2.75984 18.2007L2.41913 14.4529C2.38409 14.0674 2.78205 13.7874 3.13468 13.947C4.2741 14.4625 6.37724 15.3771 7.67641 15.7174C7.84072 15.7604 7.97361 15.878 8.04556 16.0319C8.65253 17.33 9.96896 18.0207 11.8698 18.0207C13.752 18.0207 15.085 17.3034 15.694 16.0021C15.766 15.8481 15.8991 15.7305 16.0635 15.6873C17.443 15.3243 19.6816 14.3019 20.8733 13.7349Z"
      fill="#1FADA8"
    />
  </svg>
);

export const ICEducation = (props) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.8311 15.6402C17.5011 15.2002 18.3811 15.6802 18.3811 16.4802V17.7702C18.3811 19.0402 17.3911 20.4002 16.2011 20.8002L13.0111 21.8602C12.4511 22.0502 11.5411 22.0502 10.9911 21.8602L7.80109 20.8002C6.60109 20.4002 5.62109 19.0402 5.62109 17.7702V16.4702C5.62109 15.6802 6.50109 15.2002 7.16109 15.6302L9.22109 16.9702C10.0111 17.5002 11.0111 17.7602 12.0111 17.7602C13.0111 17.7602 14.0111 17.5002 14.8011 16.9702L16.8311 15.6402Z"
      fill="#1FADA8"
    />
    <path
      d="M19.9795 6.4625L13.9895 2.5325C12.9095 1.8225 11.1295 1.8225 10.0495 2.5325L4.02953 6.4625C2.09953 7.7125 2.09953 10.5425 4.02953 11.8025L5.62953 12.8425L10.0495 15.7225C11.1295 16.4325 12.9095 16.4325 13.9895 15.7225L18.3795 12.8425L19.7495 11.9425V15.0025C19.7495 15.4125 20.0895 15.7525 20.4995 15.7525C20.9095 15.7525 21.2495 15.4125 21.2495 15.0025V10.0825C21.6495 8.7925 21.2395 7.2925 19.9795 6.4625Z"
      fill="#1FADA8"
    />
  </svg>
);

export const ICNext = (props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.52729 3.5312C5.78764 3.27085 6.20975 3.27085 6.4701 3.5312L10.4701 7.5312C10.7305 7.79155 10.7305 8.21366 10.4701 8.47401L6.4701 12.474C6.20975 12.7344 5.78764 12.7344 5.52729 12.474C5.26694 12.2137 5.26694 11.7915 5.52729 11.5312L9.05589 8.0026L5.52729 4.47401C5.26694 4.21366 5.26694 3.79155 5.52729 3.5312Z"
      fill="#2BADA7"
    />
  </svg>
);

export const ICSkill = (props) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" fill="white" />
    <path
      d="M31.2592 37.9984C31.1992 37.9984 31.1292 37.9884 31.0692 37.9684C29.0592 37.3984 26.9492 37.3984 24.9392 37.9684C24.5692 38.0684 24.1792 37.8584 24.0792 37.4884C23.9692 37.1184 24.1892 36.7284 24.5592 36.6284C26.8192 35.9884 29.1992 35.9884 31.4592 36.6284C31.8292 36.7384 32.0492 37.1184 31.9392 37.4884C31.8392 37.7984 31.5592 37.9984 31.2592 37.9984Z"
      fill="#FD82C3"
    />
    <path
      d="M35.2087 22.3576C34.1687 20.2576 32.1587 18.7076 29.8287 18.1976C27.3887 17.6576 24.8888 18.2376 22.9787 19.7776C21.0588 21.3076 19.9688 23.5976 19.9688 26.0476C19.9688 28.6376 21.5188 31.3476 23.8588 32.9176V33.7476C23.8488 34.0276 23.8388 34.4576 24.1788 34.8076C24.5287 35.1676 25.0487 35.2076 25.4587 35.2076H30.5887C31.1287 35.2076 31.5387 35.0576 31.8187 34.7776C32.1987 34.3876 32.1888 33.8876 32.1788 33.6176V32.9176C35.2787 30.8276 37.2287 26.4176 35.2087 22.3576ZM29.7188 27.6176L28.6487 29.4776C28.5087 29.7176 28.2587 29.8576 27.9987 29.8576C27.8687 29.8576 27.7387 29.8276 27.6287 29.7576C27.2687 29.5476 27.1488 29.0876 27.3488 28.7376L28.1987 27.2576H27.3587C26.8587 27.2576 26.4487 27.0376 26.2287 26.6676C26.0087 26.2876 26.0287 25.8276 26.2787 25.3876L27.3488 23.5276C27.5588 23.1676 28.0187 23.0476 28.3687 23.2476C28.7287 23.4576 28.8487 23.9176 28.6487 24.2676L27.7987 25.7476H28.6388C29.1388 25.7476 29.5487 25.9676 29.7688 26.3376C29.9888 26.7176 29.9688 27.1876 29.7188 27.6176Z"
      fill="#FD82C3"
    />
    <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="#EBECF0" />
  </svg>
);

export const ICImmunisations = (props) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" fill="white" />
    <path
      d="M38 23.81V28.5H33.92C33.8 28.49 33.54 28.34 33.48 28.23L32.44 26.26C32.03 25.48 31.32 25.04 30.56 25.08C29.8 25.12 29.15 25.63 28.82 26.46L27.44 29.92L27.24 29.4C26.75 28.13 25.35 27.17 23.97 27.17L18 27.2V23.81C18 20.17 20.17 18 23.81 18H32.19C35.83 18 38 20.17 38 23.81Z"
      fill="#FD82C3"
    />
    <path
      d="M38 32.1887V29.9987H33.92C33.25 29.9987 32.46 29.5187 32.15 28.9287L31.11 26.9587C30.83 26.4287 30.43 26.4587 30.21 27.0087L27.91 32.8187C27.66 33.4687 27.24 33.4687 26.98 32.8187L25.84 29.9387C25.57 29.2387 24.73 28.6687 23.98 28.6687L18 28.6987V32.1887C18 35.7687 20.1 37.9287 23.63 37.9887C23.74 37.9987 23.86 37.9987 23.97 37.9987H31.97C32.12 37.9987 32.27 37.9987 32.41 37.9887C35.92 37.9087 38 35.7587 38 32.1887Z"
      fill="#FD82C3"
    />
    <path
      d="M17.9987 28.7031V32.0131C17.9787 31.6931 17.9688 31.3531 17.9688 31.0031V28.7031H17.9987Z"
      fill="#38465C"
    />
    <rect x="0.5" y="0.5" width="55" height="55" rx="27.5" stroke="#EBECF0" />
  </svg>
);
