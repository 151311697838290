import Link from '@uikit/commons/Link';
import { Menu } from 'antd';
import { HEADER_MENUS, SEARCH_MENU } from 'configs/layout';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';
import { useDispatch } from 'react-redux';
import {
  HeaderMenuStyles,
  SubMenuItemStyle,
  SubMenuStyle,
  TagStyle,
} from './styles';

const MenuHeader = ({
  mode = 'horizontal',
  className = 'header-desktop-menu',
  handleCancelDrawer,
}) => {
  const { pathname } = useRouter();
  const { t } = useTranslation();
  const isMobile = mode === 'vertical';
  const dispatch = useDispatch();

  const triggerEventTracking = (menuTitle) => {
    if (menuTitle === 'menu.searchForChildcare') {
      dispatch(
        trackingEvent({
          eventName:
            EVENT_TRACKING.SUBSIDY_CALCULATOR
              .searchForChildcareButtonHeaderClicked,
          eventData: {},
        }),
      );
    }
  };

  const isHighlightMenu = useCallback(
    (href) => {
      if (href === '/') {
        if (isMobile) return ['/'].includes(pathname);
        return ['/', '/for-childcare-providers'].includes(pathname);
      }
      return pathname.includes(href);
    },
    [pathname, isMobile],
  );
  const MENU = isMobile
    ? [
        ...SEARCH_MENU,
        ...HEADER_MENUS.filter((menu) => menu.title !== 'menu.jobs'),
      ]
    : HEADER_MENUS;

  return (
    <HeaderMenuStyles className="d-flex flex-1">
      <Menu
        className={`${className} header-menu flex-1`}
        selectedKeys={[pathname]}
        mode={mode}
        overflowedIndicatorPopupClassName="popup-overflow-header-menu"
      >
        {!isMobile && (
          <SubMenuStyle
            title={'Search'}
            key={'searchMenu'}
            isHighlight={isHighlightMenu('/')}
            className="size-16"
          >
            {SEARCH_MENU.map((menu, index) => (
              <SubMenuItemStyle
                key={menu.title}
                onClick={
                  handleCancelDrawer ?? (() => triggerEventTracking(menu.title))
                }
                icon={<menu.icon />}
                disableBorderBottom={index === SEARCH_MENU.length - 1}
              >
                <Link key={menu.title} href={menu.href}>
                  <span className="size-16">{t(menu.title)}</span>
                  {menu?.tag && (
                    <TagStyle className="ml-8 rounded-8 border-none size-16 fw-bold">
                      {menu?.tag}
                    </TagStyle>
                  )}
                </Link>
              </SubMenuItemStyle>
            ))}
          </SubMenuStyle>
        )}

        {MENU.map((menu) => (
          <Menu.Item
            key={menu.title}
            onClick={() => {
              handleCancelDrawer?.();
              triggerEventTracking(menu.title);
            }}
            className={isHighlightMenu(menu.href) ? 'active-menu-item' : ''}
          >
            <Link key={menu.title} href={menu.href}>
              <div
                className={`header-menu__item size-l ${
                  isHighlightMenu(menu.href)
                    ? 'text-highlight fw-bold active-menu'
                    : ''
                }`}
              >
                {t(menu.title)}
                {menu?.logo && (
                  <img
                    className="header-menu__item-flag"
                    src="/images/subsidy/flag-au.png"
                    alt="kindicare"
                  />
                )}
                {menu?.tag && (
                  <TagStyle className="ml-8 rounded-8 border-none size-16 fw-bold">
                    {menu?.tag}
                  </TagStyle>
                )}
              </div>
            </Link>
          </Menu.Item>
        ))}
      </Menu>
    </HeaderMenuStyles>
  );
};

MenuHeader.propTypes = {
  mode: PropTypes.oneOf(['horizontal', 'vertical', 'inline']),
  className: PropTypes.string,
  handleCancelDrawer: PropTypes.func,
};

export default MenuHeader;
