import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { REQUEST_TYPE } from '@redux/crudCreator/dataProvider';
import { richWebConfig } from 'utils';

export const centresTopPicksApi = rtkCreator(
  'centresTopPicks',
  'centres/top-picks',
  richWebConfig,
  (builder) => ({
    getAllCentresTopPicksComparison: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_ALL,
        customApiResource: 'centres/top-picks/comparison',
      }),
    }),
  }),
);

export const { getAllCentresTopPicks } = centresTopPicksApi.endpoints;
export const {
  useGetAllCentresTopPicksQuery,
  useGetAllCentresTopPicksComparisonQuery,
} = centresTopPicksApi;
