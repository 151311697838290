import { forwardRef, useImperativeHandle, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LeftSecondOutlineIcon } from '@uikit/commons/SVGIcons';
import { Button, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getApplicationCurrentStepSelector } from '@redux/applyApplicationStep/selectors';
import { goToPrevStep } from '@redux/applyApplicationStep/slice';
import { RestInputContext } from '@uikit/form/RestInputContext';
import PropTypes from 'prop-types';
import ApplicationSteps from './ApplicationSteps';
import { ApplyApplicationModalStyles } from './styles';
import FooterSteps from './ApplicationSteps/FooterSteps';

const ApplyApplicationModal = (props, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const currentStep = useSelector(getApplicationCurrentStepSelector);
  const [form] = Form.useForm();

  const handleGoToPrevStep = () => dispatch(goToPrevStep());

  const onCancel = () => setVisible(false);

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setVisible(true);
      },
    }),
    [],
  );

  return (
    <ApplyApplicationModalStyles
      visible={visible}
      onCancel={onCancel}
      isScrollY
      title={
        <div className="modal-title">
          {currentStep > 0 && (
            <Button
              className="back-step"
              type="text"
              shape="circle"
              icon={<LeftSecondOutlineIcon />}
              onClick={handleGoToPrevStep}
            />
          )}
          <span>{t('applyApplications.title')}</span>
        </div>
      }
      width={1075}
      destroyOnClose
      centered={false}
      footer={
        <FooterSteps
          currentStep={currentStep}
          form={form}
          onCancel={onCancel}
        />
      }
    >
      <Form layout="vertical" form={form}>
        <RestInputContext.Provider value={{ form }}>
          <ApplicationSteps
            currentStep={currentStep}
            centreSlug={props.centreSlug}
          />
        </RestInputContext.Provider>
      </Form>
    </ApplyApplicationModalStyles>
  );
};
ApplyApplicationModal.propTypes = {
  centreSlug: PropTypes.string,
};

export default forwardRef(ApplyApplicationModal);
