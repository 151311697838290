export const SEO_VAR = {
  title: 'KindiCare | Find and Compare Child Care Centres Nearby',
  description:
    'Find &amp; compare childcare easily with thousands of childcare centres &amp; early education services. Start your early childhood education &amp; care journey here!',
  image: 'https://files.kindicare.com/uploads/static/app-free.png',
  home: {
    title: 'seo.home.title',
    description: 'seo.home.description',
    image: 'https://files.kindicare.com/uploads/static/app-free.png',
  },
  notFound404: {
    title: 'seo.notFound404.title',
  },
  aboutUs: {
    title: 'seo.aboutUs.title',
    description: 'seo.aboutUs.description',
  },
  forgotPassword: {
    title: 'seo.resetPassword.title',
  },
  resetPassword: {
    title: 'seo.resetPassword.title',
  },

  searchByBrand: {
    title: 'seo.searchByBrand.title',
    description: 'seo.searchByBrand.description',
  },
  childCareNearby: {
    title: 'seo.childCareNearby.title',
    description: 'seo.childCareNearby.description',
  },
  topPicks: {
    title: 'seo.topPicks.title',
    description: 'seo.topPicks.description',
  },
  featuresSpecialOffers: {
    title: 'seo.featuresSpecialOffers.title',
    description: 'seo.featuresSpecialOffers.description',
  },
  hintsTipsStories: {
    title: 'seo.hintsTipsStories.title',
    description: 'seo.hintsTipsStories.description',
  },
  wellKnownBrands: {
    title: 'seo.wellKnownBrands.title',
    description: 'seo.wellKnownBrands.description',
  },
  popularNearYou: {
    title: 'seo.popularNearYou.title',
    description: 'seo.popularNearYou.description',
  },
  recommendedServices: {
    title: 'seo.recommendedServices.title',
    description: 'seo.recommendedServices.description',
  },
  featuredProperties: {
    title: 'seo.featuredProperties.title',
    description: 'seo.featuredProperties.description',
  },
  yourFavourite: {
    title: 'seo.yourFavourite.title',
    description: 'seo.yourFavourite.description',
  },
  centresContacted: {
    title: 'seo.centresContacted.title',
    description: 'seo.centresContacted.description',
  },
  centresRecentlyViewed: {
    title: 'seo.centresRecentlyViewed.title',
    description: 'seo.centresRecentlyViewed.description',
  },
  forChildcareProviders: {
    title: 'seo.forChildcareProviders.title',
    description: 'seo.forChildcareProviders.description',
  },
  media: {
    title: 'seo.media.title',
    description: 'seo.media.description',
  },
  awards: {
    title: 'seo.awards.title',
    description: 'seo.awards.description',
  },
  contactUs: {
    title: 'seo.contactUs.title',
    description: 'seo.contactUs.description',
  },
  forParentsGuardians: {
    title: 'seo.forParentsGuardians.title',
    description: 'seo.forParentsGuardians.description',
  },
  ourPledge: {
    title: 'seo.ourPledge.title',
    description: 'seo.ourPledge.description',
  },
  theKindicareRating: {
    title: 'seo.theKindicareRating.title',
    description: 'seo.theKindicareRating.description',
  },
  childCareSubsidyCalculator: {
    title: 'seo.childCareSubsidyCalculator.title',
    description: 'seo.childCareSubsidyCalculator.description',
  },
  faq: {
    title: 'seo.faq.title',
    description: 'seo.faq.description',
  },
  termsAndConditions: {
    title: 'seo.termsAndConditions.title',
    description: 'seo.termsAndConditions.description',
  },
  privacyPolicy: {
    title: 'seo.privacyPolicy.title',
    description: 'seo.privacyPolicy.description',
  },
  centres: {
    title: 'seo.centres.title',
    description: 'seo.centres.description',
  },
  centresPaging: {
    title: 'seo.centresPaging.title',
  },
  centresSuburb: {
    title: 'seo.centresSuburb.title',
    description: 'seo.centresSuburb.description',
  },
  centresSuburbPaging: {
    title: 'seo.centresSuburbPaging.title',
  },
  centresPostcode: {
    title: 'seo.centresPostcode.title',
    description: 'seo.centresPostcode.description',
  },
  centresPostcodePaging: {
    title: 'seo.centresPostcodePaging.title',
  },
  centresState: {
    title: 'seo.centresState.title',
    description: 'seo.centresState.description',
  },
  centresStatePaging: {
    title: 'seo.centresStatePaging.title',
  },
  centreDetails: {
    title: 'seo.centreDetails.title',
    description: 'seo.centreDetails.description',
  },
  actDayCentreRatings: {
    title: 'seo.actDayCentreRatings.title',
    description: 'seo.actDayCentreRatings.description',
  },
  nswChildcareCentreRatings: {
    title: 'seo.nswChildcareCentreRatings.title',
    description: 'seo.nswChildcareCentreRatings.description',
  },
  ntChildcareCentreRatings: {
    title: 'seo.ntChildcareCentreRatings.title',
    description: 'seo.ntChildcareCentreRatings.description',
  },
  qldChildcareCentreRatings: {
    title: 'seo.qldChildcareCentreRatings.title',
    description: 'seo.qldChildcareCentreRatings.description',
  },
  saChildcareCentreRatings: {
    title: 'seo.saChildcareCentreRatings.title',
    description: 'seo.saChildcareCentreRatings.description',
  },
  tasmaniaChildcareCentreRatings: {
    title: 'seo.tasmaniaChildcareCentreRatings.title',
    description: 'seo.tasmaniaChildcareCentreRatings.description',
  },
  victoriaChildcareCentreRatings: {
    title: 'seo.victoriaChildcareCentreRatings.title',
    description: 'seo.victoriaChildcareCentreRatings.description',
  },
  waChildcareCentreRatings: {
    title: 'seo.waChildcareCentreRatings.title',
    description: 'seo.waChildcareCentreRatings.description',
  },
  mailbox: {
    title: 'seo.mailbox.title',
  },
  governmentChildcareSubsidy: {
    title: 'seo.governmentChildcareSubsidy.title',
    description: 'seo.governmentChildcareSubsidy.description',
  },
  hintsTipsStoriesPaging: {
    title: 'seo.hintsTipsStoriesPaging.title',
  },
  unsubscribeProviders: {
    title: 'seo.unsubscribeProviders.title',
    description: 'seo.unsubscribeProviders.description',
  },
  valueForMoneyRatingExplained: {
    title: 'seo.valueForMoneyRatingExplained.title',
    description: 'seo.valueForMoneyRatingExplained.description',
  },
  comparison: {
    title: 'seo.comparison.title',
  },
  jobComparison: {
    title: 'seo.jobComparison.title',
  },
  articleDetails: {
    title: 'seo.articleDetails.title',
    description: 'seo.articleDetails.description',
  },
  ECAConference: {
    title: '',
    description: '',
  },
  forJobSeekers: {
    title: 'seo.forJobSeekers.title',
  },
  jobs: {
    title: 'seo.jobs.title',
    description: 'seo.jobs.description',
    image:
      'https://files.kindicare.com/uploads/static/assets/rich-web/For-Job-Seekers-Cover.png',
  },
  jobsNearby: {
    title: 'seo.jobsNearby.title',
    description: 'seo.jobsNearby.description',
  },
  jobMatchSuggestions: {
    title: 'seo.jobMatchSuggestions.title',
    description: 'seo.jobMatchSuggestions.description',
  },
  jobsNewest: {
    title: 'seo.jobsNewest.title',
    description: 'seo.jobsNewest.description',
  },
  jobsFeatured: {
    title: 'seo.jobsFeatured.title',
    description: 'seo.jobsFeatured.description',
  },
  educatorAndStaffDiscount: {
    title: 'seo.educatorAndStaffDiscount.title',
    description: 'seo.educatorAndStaffDiscount.description',
  },
  companiesFeatured: {
    title: 'seo.companiesFeatured.title',
    description: 'seo.companiesFeatured.description',
  },
  brandsFeatured: {
    title: 'seo.brandsFeatured.title',
    description: 'seo.brandsFeatured.description',
  },
  jobDetails: {
    title: 'seo.jobDetails.title',
    description: 'seo.jobDetails.description',
  },
  educatorAndStaffDiscountDetails: {
    title: 'seo.educatorAndStaffDiscountDetails.title',
    description: 'seo.educatorAndStaffDiscountDetails.description',
  },
  articleJobDetails: {
    title: 'seo.articleJobDetails.title',
  },
  careerAdvice: {
    title: 'seo.careerAdvice.title',
    description: 'seo.careerAdvice.description',
  },
  scholarshipsAndIncentives: {
    title: 'seo.scholarshipsAndIncentives.title',
    description: 'seo.scholarshipsAndIncentives.description',
  },
  jobHintsTipsStories: {
    title: 'seo.jobHintsTipsStories.title',
    description: 'seo.jobHintsTipsStories.description',
  },
  searchJobByJobTitle: {
    title: 'seo.searchJobByJobTitle.title',
    description: 'seo.searchJobByJobTitle.description',
  },
  searchJobByJobRole: {
    title: 'seo.searchJobByJobRole.title',
    description: 'seo.searchJobByJobRole.description',
  },
  searchJobByLocation: {
    title: 'seo.searchJobByLocation.title',
    description: 'seo.searchJobByLocation.description',
  },
  searchJobByPostcode: {
    title: 'seo.searchJobByPostcode.title',
    description: 'seo.searchJobByPostcode.description',
  },
  searchJobByBrand: {
    title: 'seo.searchJobByBrand.title',
    description: 'seo.searchJobByBrand.description',
  },
  searchJobByCentre: {
    title: 'seo.searchJobByCentre.title',
    description: 'seo.searchJobByCentre.description',
  },
  searchJobByCompany: {
    title: 'seo.searchJobByCompany.title',
    description: 'seo.searchJobByCompany.description',
  },
  costLivingSurvey: {
    title: 'KindiCare Cost of Living Survey 2023',
    description:
      "Australian families are facing rising inflation, interest rate hikes, rent rises and redundancies occurring across many industries. The KindiCare Cost of Living Survey is the opportunity for you to share your views on what's impacting your family the most.",
    image:
      'https://files.kindicare.com/uploads/static/assets/rich-web/annual-cost-living-survey-preview.png',
  },
  greatOffers: {
    title: 'seo.greatOffers.title',
  },
  subscriptionPricing: {
    title: 'seo.subcriptionPricing.title',
    description: 'seo.subcriptionPricing.description',
  },
  ccsCalculator: {
    title: 'seo.ccsCalculator.title',
    description: 'seo.ccsCalculator.description',
    image:
      'https://s3.ap-southeast-2.amazonaws.com/files.kindicare.com/uploads/static/assets/rich-web/preview-images/og-ccs-calculator.png',
  },
  awardsNew: {
    title: 'seo.awardsNew.title',
    description: 'seo.awardsNew.description',
  },
  awardNSW: {
    title: 'seo.awardNSW.title',
    description: 'seo.awardNSW.description',
  },
  awardVIC: {
    title: 'seo.awardVIC.title',
    description: 'seo.awardVIC.description',
  },
  awardQLD: {
    title: 'seo.awardQLD.title',
    description: 'seo.awardQLD.description',
  },
  awardNT: {
    title: 'seo.awardNT.title',
    description: 'seo.awardNT.description',
  },
  awardWA: {
    title: 'seo.awardWA.title',
    description: 'seo.awardWA.description',
  },
  awardTAS: {
    title: 'seo.awardTAS.title',
    description: 'seo.awardTAS.description',
  },
  awardACT: {
    title: 'seo.awardACT.title',
    description: 'seo.awardACT.description',
  },
  awardSA: {
    title: 'seo.awardSA.title',
    description: 'seo.awardSA.description',
  },
  centreChildcareNearby: {
    title: 'seo.centreChildcareNearby.title',
    titlePaging: 'seo.centreChildcareNearby.titlePaging',
    description: 'seo.centreChildcareNearby.description',
  },
  centreTopRated: {
    title: 'seo.centreTopRated.title',
    titlePaging: 'seo.centreTopRated.titlePaging',
    description: 'seo.centreTopRated.description',
  },
  centrePopularNear: {
    title: 'seo.centrePopularNear.title',
    titlePaging: 'seo.centrePopularNear.titlePaging',
    description: 'seo.centrePopularNear.description',
  },
  centreFeaturedAndSpecial: {
    title: 'seo.centreFeaturedAndSpecial.title',
    titlePaging: 'seo.centreFeaturedAndSpecial.titlePaging',
    description: 'seo.centreFeaturedAndSpecial.description',
  },
  suburbNearby: {
    title: 'seo.suburbNearby.title',
    description: 'seo.suburbNearby.description',
  },
  awardPressRelease: {
    title: 'seo.awardPressRelease.title',
    description: 'seo.awardPressRelease.description',
  },
};
