import styled from 'styled-components';

export const SmartBannerContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 56px;
  padding: 8px 16px;
  background: #f7f8f9;
  border-bottom: 1px solid #ebecf0;
  line-height: 19px;

  .ant-image {
    width: 32px;
    height: 32px;
    margin: 0 12px;
  }

  .ant-image-img {
    background: #ffffff;
    border: 0.5px solid #ebecf0;
    border-radius: 7px;
  }

  .ant-btn {
    padding: 2px 13px;
    width: 60px;
    height: 28px;
    border-radius: 12px;
    box-shadow: none;
  }

  @media (min-width: 577px) {
    display: none;
  }
`;
