import ModalCustom from '@uikit/commons/ModalCustom';
import styled from 'styled-components';

const RequestLocationModalStyles = styled(ModalCustom)`
  .ant-modal-body {
    padding: 0 !important;
  }
  .request-location {
    &__img {
      width: 100%;
      height: 300px;
      object-fit: cover;
    }
    &__content {
      padding: 20px 32px 32px 32px;
    }
    &__title {
      font-weight: bold;
      font-size: 22px;
    }
    &__desc {
      font-size: 16px;
      margin-bottom: 20px;
      margin-top: 10px;
    }
    &__group-btn {
      column-gap: 20px;
      display: grid;
      grid-template-columns: auto auto;
    }
  }
`;

export default RequestLocationModalStyles;
