import { Layout } from 'antd';
import { ReactNode } from 'react';
import DefaultFooter from '@uikit/footers/DefaultFooter';
import Header from './Header';
import JobSidebarMenu from './JobSidebarMenu';
import { HeaderStyle, PublicLayoutStyles } from './styles';
import ParentSidebarMenu from './ParentSidebarMenu';

interface Props {
  children?: ReactNode;
  isShowFooter?: boolean;
  isStickHeader: boolean;
  isHeaderPinkBackground: boolean;
  hideHeader: object;
  isShowHeader: boolean;
  hasJobSidebar: boolean;
  hasParentSidebar: boolean;
}

const PublicLayout = ({
  children,
  isStickHeader = true,
  isShowFooter = true,
  isHeaderPinkBackground = false,
  hideHeader,
  isShowHeader = true,
  hasJobSidebar,
  hasParentSidebar,
}: Props) => (
  <PublicLayoutStyles>
    {hasJobSidebar && <JobSidebarMenu />}
    {hasParentSidebar && <ParentSidebarMenu />}
    <HeaderStyle hasParentSidebar={hasParentSidebar} className="w-full">
      {isShowHeader && (
        <Header
          isStickHeader={isStickHeader}
          isHeaderPinkBackground={isHeaderPinkBackground}
          hideHeader={hideHeader}
        />
      )}
      <Layout>
        <Layout.Content className="layout-content-style">
          {children}
        </Layout.Content>
      </Layout>
      {isShowFooter && <DefaultFooter hasParentSidebar={hasParentSidebar} />}
    </HeaderStyle>
  </PublicLayoutStyles>
);

export default PublicLayout;
