import { CloseOutlineIcon } from '@uikit/commons/SVGIcons';
import { ReactNode } from 'react';
import { ModalWrapper } from './styles';

interface ModalJobSeekerCustom {
  className?: string;
  isScrollY?: boolean;
  resetPaddingTopBody?: boolean;
  resetPaddingBottomBody?: boolean;
  cancelButtonProps?: object;
  isBorderModal?: boolean;
  children?: ReactNode;
  onOk?: () => void;
  okText?: string;
  onCancel?: () => void;
  isJobSeekerBlockBtn?: boolean;
  visible: boolean;
  footer?: ReactNode;
}

const ModalJobSeekerCustom = ({
  className,
  isScrollY,
  resetPaddingTopBody,
  resetPaddingBottomBody,
  cancelButtonProps,
  isBorderModal,
  children,
  onOk,
  onCancel,
  isJobSeekerBlockBtn,
  visible,
  footer,
  ...props
}: ModalJobSeekerCustom) => (
  <ModalWrapper
    className={`${className || ''} ${isScrollY ? 'modal-scroll-y' : ''} ${
      resetPaddingBottomBody ? 'reset-padding-bottom-body' : ''
    } ${resetPaddingTopBody ? 'reset-padding-top-body' : ''}`}
    width={500}
    maskClosable={false}
    destroyOnClose
    closeIcon={<CloseOutlineIcon />}
    cancelButtonProps={{ ...cancelButtonProps, className: 'text-green' }}
    style={{ top: 20 }}
    centered
    isBorderModal={isBorderModal}
    onOk={onOk}
    onCancel={onCancel}
    isJobSeekerBlockBtn={isJobSeekerBlockBtn}
    visible={visible}
    footer={footer}
    {...props}
  >
    {children}
  </ModalWrapper>
);

export default ModalJobSeekerCustom;
