import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import StepTitle from '@uikit/commons/StepTitle';
import { ProfileInfoStyles } from './styles';

const ProfileInfo = ({ data, title, icon }) => {
  const { t } = useTranslation();

  return (
    <ProfileInfoStyles>
      <StepTitle
        icon={icon}
        iconRounded={false}
        title={t(title)}
        titleSize={20}
        className="mb-24"
      />
      <Row gutter={[24, 24]}>
        {data?.map((item) => (
          <Col
            {...(item?.breakpoints
              ? item.breakpoints
              : { xs: 24, sm: 12, lg: 8 })}
            key={item.label}
          >
            <div className="step-data-label">{t(item.label)}</div>
            <div className="step-data-value">
              {item?.value || t('common.notAvailable')}
            </div>
          </Col>
        ))}
      </Row>
    </ProfileInfoStyles>
  );
};

ProfileInfo.propTypes = {
  data: PropTypes.array,
  icon: PropTypes.element,
  title: PropTypes.string,
};

export default ProfileInfo;
