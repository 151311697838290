import React from 'react';
import { Divider, Empty, List, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash-es';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { getCentreReviewsSelector } from '@redux/centreDetails/selectors';
import RequiredAuthButton from 'components/rest/RequiredAuthButton';
import {
  ReviewSummaries,
  ReviewsItem,
  StatisticsReview,
} from 'types/parentReview';
import { LeftOutlineIcon, PencilFilledIcon } from '@uikit/commons/SVGIcons';
import { StyledModal } from 'components/centres/Details/WriteReviewModal/styles';
import FilterReviews from 'components/centres/Details/ParentReviews/FilterReviews';
import { renderEmptyReviewDescription } from 'components/centres/Details/utils';
import { ListReviewItemStyle, ParentReviewStyle } from './styles';
import ReviewSummary from './ReviewSummary';
import ReviewItem from './ReviewItem';

interface Props {
  onCancel?: () => void;
  summaries?: StatisticsReview[];
  reviewSummaries?: ReviewSummaries;
  handleFilterChange?: (value?: number) => void;
  checkIsSelected?: (value?: number) => boolean;
  page?: number;
  setPage?: (value?: number) => void;
  handleShowReview?: () => void;
  visible?: boolean;
  setLimit?: (value?: number) => void;
  centreName?: string;
  selectedValues?: number[];
  isJobSeeker?: boolean;
}

const ViewAllReviews = ({
  onCancel,
  summaries,
  reviewSummaries,
  handleFilterChange,
  checkIsSelected,
  page,
  setPage,
  handleShowReview,
  setLimit,
  centreName,
  selectedValues,
  isJobSeeker,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { data, total } = useSelector(getCentreReviewsSelector);

  const fetchMoreData = () => {
    if (data?.length < total) {
      setLimit(20);
      setPage(page + 1);
    }
  };

  const emptyDescriptionValue = renderEmptyReviewDescription(
    selectedValues,
    isJobSeeker,
  );

  return (
    <StyledModal
      {...props}
      title={t('centres.detail.allReviews')}
      centered
      onCancel={onCancel}
      footer={
        isJobSeeker ? null : (
          <RequiredAuthButton
            onClick={handleShowReview}
            type="primary"
            block
            icon={<PencilFilledIcon />}
            className="h-48 fw-bold"
          >
            {t('button.writeReview')}
          </RequiredAuthButton>
        )
      }
      closeIcon={<LeftOutlineIcon />}
      wrapClassName="modal-screen"
    >
      <ParentReviewStyle>
        <ListReviewItemStyle isJobSeeker={isJobSeeker} id="reviewListScroll">
          <InfiniteScroll
            dataLength={data?.length}
            next={fetchMoreData}
            hasMore={data?.length < total}
            loader={
              <div className="flex-center">
                <Spin />
              </div>
            }
            scrollableTarget="reviewListScroll"
          >
            <div className="mt-20">
              <ReviewSummary
                reviewSummaries={reviewSummaries}
                summaries={summaries}
              />
            </div>
            <FilterReviews
              summaries={summaries}
              handleFilterChange={handleFilterChange}
              checkIsSelected={checkIsSelected}
            />
            <Divider />
            <div>
              {isEmpty(data) ? (
                <Empty
                  image="/images/empty-review.png"
                  description={t(emptyDescriptionValue)}
                  className="text-grey-l1 size-14 flex-center mt-26 flex-column"
                />
              ) : (
                <List
                  dataSource={data}
                  renderItem={(item: ReviewsItem) => (
                    <List.Item id={item?.id} key={item.id}>
                      <div className="w-full">
                        <ReviewItem
                          item={item}
                          key={item?.id}
                          centreName={centreName}
                        />
                      </div>
                    </List.Item>
                  )}
                />
              )}
            </div>
          </InfiniteScroll>
        </ListReviewItemStyle>
      </ParentReviewStyle>
    </StyledModal>
  );
};

export default ViewAllReviews;
