import styled from 'styled-components';

export const ApplicationInfoStyles = styled.div`
  .ant-divider {
    margin: 24px 0;
  }

  .step-data-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-grey-l2);
    margin-bottom: 8px;
  }

  .step-data-value {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-grey);
    overflow-wrap: break-word;
  }

  .application-fee {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-grey);
  }

  @media only screen and (max-width: 576px) {
    .step-data-label {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
    }

    .step-data-value {
      font-size: 14px;
      line-height: 24px;
    }

    .application-fee {
      font-size: 14px;
      line-height: 20px;
    }
  }
`;

export const CentreInfoStyles = styled.div`
  display: flex;
  align-items: center;
  .centre-image-wrapper {
    position: relative;
    margin-right: 20px;
    .centre-image {
      width: 100px;
      height: 100px;
      min-width: 100px;
      border-radius: 12px;
      object-fit: cover;
    }
  }

  .centre-rating {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 36px;
    height: 36px;
    border-radius: 6px;
    object-fit: contain;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .centre-name {
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: var(--color-grey);
    margin-bottom: 8px;
  }

  .centre-address {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-secondary);
  }

  @media only screen and (max-width: 576px) {
    .centre-image-wrapper {
      position: relative;
      margin-right: 12px;
      .centre-image {
        width: 70px;
        height: 70px;
        min-width: 70px;
        border-radius: 12px;
        object-fit: cover;
      }
    }

    .centre-rating {
      top: 7px;
      left: 7px;
      width: 24px;
      height: 24px;
      font-size: 12px;
      border-radius: 4px;
    }

    .centre-name {
      font-size: 14px;
      line-height: 20px;
      margin: 8px 0 4px;
    }

    .centre-address {
      font-size: 13px !important;
      line-height: 18px;
    }
  }
`;

export const ProfileInfoStyles = styled.div`
  .ant-row {
    row-gap: 16px !important;
  }
`;
