import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import axios from 'axios';
import {
  AUTHENTICATION_PASSWORD,
  AUTHENTICATION_USERNAME,
  PREFIX_URL_API,
  SITE_URL,
  STORAGE_KEY,
  STORAGE_REFRESH_TOKEN,
} from 'configs/constants';

export function getToken() {
  return getCookie(STORAGE_KEY);
}

export function getRefreshToken() {
  return getCookie(STORAGE_REFRESH_TOKEN);
}

export function setToken(token, refreshToken) {
  setCookie(STORAGE_KEY, token);
  refreshToken && setCookie(STORAGE_REFRESH_TOKEN, refreshToken);
}

export async function setServerCookie(token, refreshToken) {
  const isBasicAuth = window && window.location.origin !== SITE_URL;

  await axios(`/api/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: isBasicAuth
        ? `Basic ${btoa(
            `${AUTHENTICATION_USERNAME}:${AUTHENTICATION_PASSWORD}`,
          )}`
        : undefined,
    },
    data: {
      token,
      refreshToken,
    },
  });
}

export async function deleteToken() {
  deleteCookie(STORAGE_KEY);
  deleteCookie(STORAGE_REFRESH_TOKEN);
  const isBasicAuth = window && window.location.origin !== SITE_URL;

  await axios(`/api/logout`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      authorization: isBasicAuth
        ? `Basic ${btoa(
            `${AUTHENTICATION_USERNAME}:${AUTHENTICATION_PASSWORD}`,
          )}`
        : undefined,
    },
  });
}

export const uuidv4 = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

export const getBaseURLApi = (
  prefixUrl = '/rich-web',
  version = '/v1',
  customPath = 'api',
) => `${process.env.NEXT_PUBLIC_API_URL}/${customPath}${version}${prefixUrl}`;

export const richWebConfig = {
  baseURL: getBaseURLApi(PREFIX_URL_API.richWeb),
};

export const richWebConfigVer2 = {
  baseURL: getBaseURLApi('', '/v2', 'thor'),
};
