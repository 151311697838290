import dayjs from 'utils/dayUtils';

export const formatOnSubmitParentRequestDetails = ({
  parentRequestDetails,
  ...values
}) => {
  const parentRequestDetailsFormat = [];
  parentRequestDetails?.forEach(
    ({ startDate, child, isSelected, ...values }) => {
      if (isSelected) {
        parentRequestDetailsFormat.push({
          ...values,
          childId: child?.id,
          careDays: child?.requiredDays,
          ...(startDate && {
            startDate: dayjs(startDate).toISOString(),
          }),
        });
      }
    },
  );
  return {
    ...values,
    parentRequestDetails: parentRequestDetailsFormat,
  };
};
