import styled from 'styled-components';
import { Modal } from 'antd';

export const ModalWrapper = styled(Modal)`
  --text-green: ${({ greenColor }) => greenColor};
  min-height: 30%;
  max-width: calc(100vw - 50px);

  &.reset-padding-top-body .ant-modal-body {
    padding-top: 0 !important;
  }

  &.reset-padding-bottom-body .ant-modal-body {
    padding-bottom: 0;
  }

  &.modal-scroll-y .ant-modal-body {
    max-height: 70vh;
    overflow-y: auto;
  }

  .ant-modal-content {
    overflow: visible;
  }

  .ant-modal-header {
    border-bottom: 1px solid var(--border-color-light);
    text-align: center;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    padding: 16px;
  }

  .ant-modal-close,
  .ant-modal-close-icon,
  .ant-modal-close-x > .anticon {
    font-size: 18px;
  }

  .ant-modal-close {
    border-radius: 50%;
    background: var(--bg-content);
    box-shadow: 0px 8px 16px rgb(39 40 51 / 16%);
    top: -16px;
    right: -16px;
    &-x {
      width: 60px !important;
      height: 60px !important;
      line-height: 60px !important;
      color: var(--color-grey);
    }
  }

  .multi-upload-wrapper .ant-form-item:not(:first-child) {
    display: none;
  }
  .multi-upload-wrapper .ant-form-item:first-child {
    margin-bottom: 10px;
  }

  textarea,
  .ant-input,
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-selection,
  .ant-input-number,
  .ant-select-dropdown-menu-item,
  .ant-select-dropdown-menu,
  .ant-select-dropdown,
  .ant-select-clear-icon,
  .ant-select-dropdown-menu-vertical,
  .ant-picker,
  .ant-checkbox-inner {
    &:hover,
    &:focus,
    &:active {
      border: 1px solid var(--text-green);
    }
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-picker:hover,
  .ant-picker-focused,
  .ant-radio-checked .ant-radio-inner,
  .ant-input:focus {
    border: 1px solid var(--text-green);
    box-shadow: none;
  }

  .ant-checkbox-checked .ant-checkbox-inner,
  .ant-radio-inner::after {
    border: var(--text-green);
    background-color: var(--text-green);
  }

  .ant-checkbox-checked .ant-checkbox-inner:hover {
    border: none !important;
  }

  .ant-input-affix-wrapper {
    border-radius: var(--border-radius);
    .ant-input {
      border: none;
    }
    .ant-input:active {
      border: none;
    }
  }

  .ant-input-affix-wrapper-focused {
    border: 1px solid var(--text-green) !important;
  }

  .ant-select-selection__clear {
    background-color: transparent;
    color: white;
    border-radius: 5px;
  }
  .ant-select-arrow-icon {
    background-color: transparent;
  }
  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .ant-modal-footer {
    border: 1px solid var(--border-color-light);
    padding: 24px;
    .ant-btn-primary {
      background-color: var(--text-green) !important;
    }
    .ant-btn-default:hover {
      color: var(--text-green);
    }
    > button {
      min-width: 100px;
      border: 1px solid var(--text-green);
      &:not(:first-child) {
        margin-left: 16px !important;
      }
    }
  }

  .ant-calendar-picker,
  .ant-select {
    width: 100%;
  }

  .ant-form-item-control {
    line-height: 2;
  }

  .ant-form-item-label {
    line-height: 1.5em;
    padding-bottom: 5px;
  }
  .ant-input-number,
  .ant-picker {
    width: 100%;
  }
  .ant-form-item .ant-form-item-explain {
    margin-top: 3px;
  }
  .ant-input-number {
    border-radius: var(--border-radius);
  }
  .add-centre-modal {
    .ant-modal-title {
      font-size: 24px;
      font-weight: bold;
    }
  }
  .ant-modal-footer {
    border: none !important;
    padding-top: 0px !important;
    display: ${({ isJobSeekerBlockBtn }) =>
      isJobSeekerBlockBtn ? 'flex' : ''} !important;
    justify-content: ${({ isJobSeekerBlockBtn }) =>
      isJobSeekerBlockBtn ? 'center' : ''} !important;
  }
  .ant-modal-footer > button {
    font-weight: bold;
    min-width: ${({ isJobSeekerBlockBtn }) =>
      isJobSeekerBlockBtn ? '48%' : ''} !important;
  }

  @media only screen and (max-width: 390px) {
    .ant-modal-footer {
      margin-top: 55px;

      & > button {
        font-size: 11px;
      }
    }

    .ant-image-img {
      width: 250px;
    }
  }
`;

export const ApplySuccessFullyStyle = styled.div`
  .avatar_double {
    margin: 100px 0 80px 0;
    position: relative;
    .ant-image-img {
      object-fit: cover;
      border-radius: 50%;
    }
    .avatar--user {
      display: flex;
      justify-content: center;
      position: absolute;
      right: 47%;
      background-color: #1fada91f;
      padding: 15px;
      border-radius: 50%;
    }
    .avatar--organization {
      position: absolute;
      right: 25%;
      display: flex;
      justify-content: center;
      background-color: #ff8ad126;
      padding: 15px;
      border-radius: 50%;
    }
    .avatar--user-image {
      border: 5px solid #ff8ad126;
    }
    .avatar--organization-image {
      border: 5px solid #1fada91f;
    }
  }

  @media only screen and (max-width: 390px) {
    .avatar_double {
      margin-left: 35px;
    }
  }
`;

export const UpdateProfileStyle = styled.div`
  .anticon {
    color: var(--color-warning-default);
    font-size: 24px;
  }
`;
