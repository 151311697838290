import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, useState } from 'react';
import ModalCustom from '@uikit/commons/ModalCustom';
import { Form } from 'antd';
import { RestInputContext } from '@uikit/form/RestInputContext';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { createChildrenOfUser } from '@redux/auth/actions';
import { formatDateOfBirthSubmit } from 'utils/dataUtils';
import ChildForm from './ChildForm';

const CreateChildModal = forwardRef(({ onSuccessAction = () => null }, ref) => {
  const [visible, setVisible] = useState(false);
  const { t } = useTranslation(['common', 'profile']);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const successDescription = t('profile:profile.children.createSuccess');
  const toggleModal = () => setVisible((v) => !v);

  const onCancel = () => {
    toggleModal();
    form.resetFields();
  };

  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        toggleModal();
      },
    }),
    [],
  );

  const onOk = () => {
    form
      .validateFields()
      .then(({ dateOfBirth, ...values }) => {
        setLoading(true);
        dispatch(
          createChildrenOfUser({
            ...values,
            dateOfBirth: formatDateOfBirthSubmit(dateOfBirth),
            options: {
              successDescription,
            },
          }),
        )
          .then(({ payload }) => {
            setLoading(false);
            if (payload?.id) {
              onSuccessAction(payload);
              onCancel();
            }
          })
          .catch(() => {
            setLoading(false);
          });
      })
      .catch(() => null);
  };

  return (
    <ModalCustom
      visible={visible}
      title={t('children.createChildModalTitle')}
      onCancel={onCancel}
      onOk={onOk}
      isScrollY
      width={807}
      okText={t('button.addNew')}
      okButtonProps={{ loading }}
    >
      <Form form={form} layout="vertical">
        <RestInputContext.Provider value={{ form }}>
          <ChildForm />
        </RestInputContext.Provider>
      </Form>
    </ModalCustom>
  );
});

CreateChildModal.propTypes = {
  onSuccessAction: PropTypes.func,
};

export default CreateChildModal;
