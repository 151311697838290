import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useTranslation } from 'react-i18next';

import React, { useContext, useRef } from 'react';
import { RestInputContext } from '@uikit/form/RestInputContext';
import { ChildOutlineIcon } from '@uikit/commons/SVGIcons';
import FeatureTitleIcon from '@uikit/commons/FeatureTitleIcon';
import CreateChildModal from '@uikit/modals/ChildModal/CreateChildModal';
import EditChildModal from '@uikit/modals/ChildModal/EditChildModal';
import FormStyles from './styles';

const FormAdditionChildren = ({
  children,
  name = 'parentRequestDetails',
  className,
  showHeader = true,
}) => {
  const { t } = useTranslation();
  const { form } = useContext(RestInputContext);

  const addChildModalRef = useRef();
  const editChildModalRef = useRef();

  const onAddChild = () => {
    addChildModalRef.current.open();
  };

  const onEditChild = (record) => {
    editChildModalRef.current.open(record);
  };

  const onAddNewChildSuccess = (data) => {
    const newValues = [
      ...(form.getFieldValue(name) || []),
      {
        child: data,
      },
    ];
    form.setFields([
      {
        name,
        value: newValues,
      },
    ]);
  };

  const onEditChildSuccess = (data) => {
    const matchIdx = form
      .getFieldValue(name)
      ?.findIndex((item) => item.child?.id === data?.id);

    if (matchIdx !== -1) {
      form.setFields([
        {
          name: [name, matchIdx, 'child'],
          value: data,
        },
      ]);
    }
  };

  return (
    <FormStyles className={`${className || ''} form-list__list-wrapper`}>
      {showHeader && (
        <FeatureTitleIcon
          IconCPN={ChildOutlineIcon}
          title="title.childrenInformation"
        />
      )}

      <div className="form-list__content">
        {showHeader && (
          <div className="form-list__desc mb-30 size-16">
            {t('makeEnquiry.childrenInfo.desc')}
          </div>
        )}

        <div className="form-list__list">
          <Form.List name={name} initialValue={[]}>
            {(fields) => (
              <>
                {fields.map((field) => (
                  <div key={field.key} className="form-list__list-item-wrapper">
                    <div className="form-list__list-item">
                      {React.cloneElement(children, {
                        field,
                        onEditChild,
                        parentName: name,
                      })}
                    </div>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </div>

        <div
          role="presentation"
          className="fw-bold pointer form-list__add-new text-highlight text-underline"
          onClick={onAddChild}
        >
          {'+ '}
          {t('button.addNewChild')}
        </div>
      </div>
      <CreateChildModal
        ref={addChildModalRef}
        onSuccessAction={onAddNewChildSuccess}
      />
      <EditChildModal
        ref={editChildModalRef}
        onSuccessAction={onEditChildSuccess}
      />
    </FormStyles>
  );
};

FormAdditionChildren.propTypes = {
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  children: PropTypes.any,
  className: PropTypes.string,
  showHeader: PropTypes.bool,
};

export default FormAdditionChildren;
