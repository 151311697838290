import { useEffect } from 'react';
import DefaultHeader from '@uikit/headers/DefaultHeader';

import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from '@redux/auth/actions';
import { getConfig } from '@redux/config/actions';
import { setInitHeader } from 'utils/request';
import { getConfigSubsidy } from '@redux/configSubsidy/actions';
import {
  getIsAuthenticated,
  getRehydratedPersistUser,
} from '@redux/auth/selectors';
import { getRehydratedPersistConfig } from '@redux/config/selectors';
import { getRehydratedPersistSubsidy } from '@redux/configSubsidy/selectors';
import { getConfigLocal } from '@redux/configLocal/selectors';
import AuthModal from '@uikit/modals/AuthModal';

interface Props {
  isStickHeader: boolean;
  isHeaderPinkBackground: boolean;
  hideHeader: object;
}

interface ConfigLocal {
  fetchedConfig: boolean;
  fetchedUserMe: boolean;
  fetchedConfigSubsidy: boolean;
}

const Header = ({
  isStickHeader,
  isHeaderPinkBackground,
  hideHeader,
}: Props) => {
  const isAuth: boolean = useSelector(getIsAuthenticated);
  const { fetchedConfig, fetchedUserMe, fetchedConfigSubsidy }: ConfigLocal =
    useSelector(getConfigLocal);

  const persist: boolean = useSelector(getRehydratedPersistConfig);
  const persistUser: boolean = useSelector(getRehydratedPersistUser);
  const persistConfigSubsidy: boolean = useSelector(
    getRehydratedPersistSubsidy,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth && !fetchedUserMe && persistUser) {
      setInitHeader();
      dispatch(getCurrentUser());
    }
  }, [dispatch, fetchedUserMe, isAuth, persistUser]);

  useEffect(() => {
    if (persist && !fetchedConfig) {
      dispatch(getConfig());
    }
  }, [dispatch, fetchedConfig, persist]);

  useEffect(() => {
    if (persistConfigSubsidy && !fetchedConfigSubsidy) {
      dispatch(getConfigSubsidy());
    }
  }, [dispatch, fetchedConfigSubsidy, persistConfigSubsidy]);

  return (
    <>
      <DefaultHeader
        isStickHeader={isStickHeader}
        isHeaderPinkBackground={isHeaderPinkBackground}
        hideHeader={hideHeader}
      />
      <AuthModal />
    </>
  );
};

export default Header;
