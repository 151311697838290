import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { REQUEST_TYPE } from '@redux/crudCreator/dataProvider';
import { richWebConfig } from 'utils';

export const centresWellKnownBrandsApi = rtkCreator(
  'centresWellKnownBrands',
  'centres/well-known',
  richWebConfig,
  (builder) => ({
    getAllCentresWellKnownComparison: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_ALL,
        customApiResource: 'centres/well-known/comparison',
      }),
    }),
  }),
);

export const {
  useGetAllCentresWellKnownBrandsQuery,
  useGetAllCentresWellKnownComparisonQuery,
} = centresWellKnownBrandsApi;
