import styled from 'styled-components';

const KindiRatingStyles = styled.div`
  --primary: ${({ primaryColor }) => primaryColor};
  font-size: 20px;
  color: #ffffff;
  padding: ${({ isRatingImage }) => (isRatingImage ? '0 !important' : '4px')};
  background: ${({ isRatingImage }) => (isRatingImage ? '' : 'var(--primary)')};
  border-radius: 8px;
  display: inline-flex;

  img {
    object-fit: contain;
  }
`;

export default KindiRatingStyles;
