export const ICChildcareServiceType = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.3738 9.7573L9.33243 8.0013L12.3738 6.2453C12.5256 6.15627 12.6361 6.01078 12.6811 5.84058C12.7261 5.67038 12.702 5.4893 12.6139 5.33684C12.5259 5.18438 12.3812 5.07293 12.2113 5.0268C12.0414 4.98068 11.8601 5.00362 11.7071 5.09064L8.66576 6.84663V3.33464C8.66576 3.15782 8.59552 2.98826 8.4705 2.86323C8.34547 2.73821 8.1759 2.66797 7.99909 2.66797C7.82228 2.66797 7.65271 2.73821 7.52769 2.86323C7.40267 2.98826 7.33243 3.15782 7.33243 3.33464V6.84663L4.29109 5.09064C4.13806 5.00362 3.95681 4.98068 3.78692 5.0268C3.61702 5.07293 3.47227 5.18438 3.38424 5.33684C3.29622 5.4893 3.27207 5.67038 3.31707 5.84058C3.36207 6.01078 3.47256 6.15627 3.62443 6.2453L6.66576 8.0013L3.62443 9.7573C3.47256 9.84634 3.36207 9.99183 3.31707 10.162C3.27207 10.3322 3.29622 10.5133 3.38424 10.6658C3.47227 10.8182 3.61702 10.9297 3.78692 10.9758C3.95681 11.0219 4.13806 10.999 4.29109 10.912L7.33243 9.15597V12.668C7.33243 12.8448 7.40267 13.0143 7.52769 13.1394C7.65271 13.2644 7.82228 13.3346 7.99909 13.3346C8.1759 13.3346 8.34547 13.2644 8.4705 13.1394C8.59552 13.0143 8.66576 12.8448 8.66576 12.668V9.15597L11.7071 10.912C11.8601 10.999 12.0414 11.0219 12.2113 10.9758C12.3812 10.9297 12.5259 10.8182 12.6139 10.6658C12.702 10.5133 12.7261 10.3322 12.6811 10.162C12.6361 9.99183 12.5256 9.84634 12.3738 9.7573Z"
      fill="#DB147F"
    />
  </svg>
);
