import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  params: {
    size: 0,
    page: 1,
    location: {
      longitude: '',
      latitude: '',
    },
    filter: {
      excludedPosts: [],
    },
    sorts: [],
  },
};

const JobRequestParams = createSlice({
  name: 'jobRequestParams',
  initialState,
  reducers: {
    saveRequestParams: (state, { payload }) => {
      state.params = payload.params;
    },
  },
});

export const { saveRequestParams } = JobRequestParams.actions;
export default JobRequestParams.reducer;
