import * as Sentry from '@sentry/nextjs';
import { pick } from 'lodash-es';

export const handlerSentryUserLogged = (user) => {
  if (process.env.NODE_ENV === 'development') return;
  Sentry.setUser(pick(user, ['email', 'firstName', 'lastName', 'phoneNumber']));
};

export const handleSentryRequestError = (error) => {
  if (process.env.NODE_ENV === 'development') return;
  const response = error?.response;
  Sentry.captureMessage(
    `Error ${response?.status || ''}: ${response?.data?.message || ''}`,
    {
      extra: { config: response?.config, error: response?.data },
    },
  );
};
