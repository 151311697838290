import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedCentres: [],
  initialSlide: 0,
  resourceFilter: {},
};

const centresCompare = createSlice({
  name: 'centresCompare',
  initialState,
  reducers: {
    addCentreCompare: (state, { payload }) => {
      state.selectedCentres = [payload, ...state.selectedCentres];
      state.initialSlide = 0;
    },
    removeCentreCompare: (state, { payload }) => {
      const centreIndex = state.selectedCentres.findIndex(
        (item) => item?.id === payload,
      );

      state.selectedCentres = state.selectedCentres.filter(
        (item) => item?.id !== payload,
      );

      state.initialSlide =
        centreIndex > 0 && centreIndex <= state.selectedCentres.length
          ? centreIndex - 1
          : 0;
    },
    clearAllCentresCompare: (state) => {
      state.selectedCentres = [];
    },
    saveResourceFilter: (state, { payload }) => {
      state.resourceFilter = payload;
    },
  },
});

export const {
  addCentreCompare,
  removeCentreCompare,
  clearAllCentresCompare,
  saveResourceFilter,
} = centresCompare.actions;

export default centresCompare.reducer;
