import React, { useEffect } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { setModalConfig } from '@redux/config/actions';
import { setInitHeader } from 'utils/request';
import styled from 'styled-components';
import { trackingEvent } from '@redux/eventTracking/actions';
import { getIsAuthenticated, getPersistUser } from '@redux/auth/selectors';
import { getConfigLocal } from '@redux/configLocal/selectors';

export const StyledButton = styled(Button)`
  color: ${({ textColor }) => textColor ?? ''} !important;
  background-color: ${({ backgroundColor }) =>
    backgroundColor ?? ''} !important;
  border-color: ${({ borderColor }) => borderColor ?? ''} !important;
`;

interface Props {
  htmlType?: string;
  onClick?: (e: unknown) => void;
  isStopPropagation?: boolean;
  isAllowedToEventTrack?: boolean;
  eventTrackPayload?: {
    eventName: string;
    eventData: object;
  };
  [restProps: string]: unknown;
}

const RequiredAuthButton = ({ ...props }: Props) => {
  const {
    htmlType,
    onClick,
    isStopPropagation = true,
    isAllowedToEventTrack = false,
    eventTrackPayload,
    ...restProps
  } = props;
  const dispatch = useDispatch();
  const isAuth = useSelector(getIsAuthenticated);
  const { fetchedUserMe } = useSelector(getConfigLocal);
  const persistUser = useSelector(getPersistUser);

  useEffect(() => {
    if (isAuth && !fetchedUserMe && persistUser?.rehydrated) {
      setInitHeader();
    }
  }, [fetchedUserMe, isAuth, persistUser]);

  const handleOnClick = (e) => {
    isAuth && isStopPropagation ? e.stopPropagation() : e.preventDefault();

    if (!isAuth) {
      if (isAllowedToEventTrack) {
        dispatch(trackingEvent({ ...eventTrackPayload }));
      }

      dispatch(
        setModalConfig({
          loginModal: true,
        }),
      );
      return;
    }
    onClick && onClick(e);
  };

  return (
    <StyledButton
      {...restProps}
      {...(isAuth && { htmlType })}
      onClick={handleOnClick}
    />
  );
};

export default RequiredAuthButton;
