import { useTranslation } from 'react-i18next';
import { Form, Modal } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import { useCreateInboxesMutation } from '@redux/inboxes';
import { RestInputContext } from '@uikit/form/RestInputContext';
import RequiredAuthButton from 'components/rest/RequiredAuthButton';
import { useDispatch, useSelector } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';
import { getEnquiryTypes } from '@redux/config/selectors';
import { ENQUIRY_MODE_CONST } from 'configs/localData';
import { useRouter } from 'next/router';
import {
  getCentreDetailConnectButtons,
  getCentreDetailsApplicableButtons,
} from '@redux/centreDetails/actions';
import { CRM_PACKAGES } from 'configs/constants';
import { isEmpty } from 'lodash-es';
import { ComplexEnquiryFormStyles } from './styles';
import ChildrenInformation from './ChildrenInformation';
import GeneralInformationForm from './GeneralInformationForm';
import { formatOnSubmitParentRequestDetails } from './utils';

interface Props {
  centreId: string;
  onCancel: () => void;
  subscription?: string;
  claimStatus?: string;
}

const ComplexEnquiryForm = ({
  centreId,
  onCancel,
  subscription,
  claimStatus,
}: Props) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [createInboxes, { isLoading }] = useCreateInboxesMutation();
  const dispatch = useDispatch();
  const enquiryTypes = useSelector(getEnquiryTypes);
  const { query } = useRouter();

  const onFinish = (values) => {
    if (centreId) {
      createInboxes({
        data: {
          centreId,
          ...formatOnSubmitParentRequestDetails(values),
        },
      }).then((res) => {
        if (res?.data?.id) {
          if (
            subscription === CRM_PACKAGES.kindicareBasic ||
            isEmpty(claimStatus)
          ) {
            dispatch(getCentreDetailsApplicableButtons(String(query?.id)));
            dispatch(getCentreDetailConnectButtons({ centreId }));
          }
          onCancel();
          dispatch(
            trackingEvent({
              eventName: EVENT_TRACKING.enquirySubmitted,
              eventData: {
                centreId,
                enquiryId: res.data.id,
                enquiryType: enquiryTypes?.find(
                  (item) => item.id === values.enquiryTypeId,
                )?.name,
                enquiryMode: ENQUIRY_MODE_CONST.complex,
              },
            }),
          );
          Modal.success({
            icon: <CheckCircleFilled />,
            title: (
              <div className="fw-bold size-18">{t('enquiries.complete')}</div>
            ),
            content: t('enquiries.success'),
            okText: t('button.done'),
          });
        }
      });
    }
  };

  return (
    <ComplexEnquiryFormStyles>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <RestInputContext.Provider value={{ form }}>
          <div>
            <div className="complex-enquiry__title">
              {t('makeEnquiry.title')}
            </div>

            <GeneralInformationForm />

            <ChildrenInformation />
          </div>

          <div className="flex-center">
            <RequiredAuthButton
              size="large"
              className="complex-enquiry__submit-btn"
              type="primary"
              onClick={form.submit}
              loading={isLoading}
            >
              {t('button.sendEnquiry')}
            </RequiredAuthButton>
          </div>
        </RestInputContext.Provider>
      </Form>
    </ComplexEnquiryFormStyles>
  );
};

export default ComplexEnquiryForm;
