import { SVGProps } from 'react';
import useWindowSize from 'hooks/useWindowSize';

export const ICMessage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8851 0.667969C16.6601 0.667969 18.7526 2.93214 18.7526 5.93464V11.658C18.7526 13.1946 18.2093 14.583 17.2218 15.568C16.3359 16.4505 15.1868 16.918 13.8984 16.918H5.6876C4.40177 16.918 3.25344 16.4513 2.36677 15.568C1.37927 14.583 0.835938 13.1946 0.835938 11.658V5.93464C0.835938 2.93214 2.92844 0.667969 5.70344 0.667969H13.8851ZM13.8851 1.91797H5.70344C3.6076 1.91797 2.08594 3.60714 2.08594 5.93464V11.658C2.08594 12.8605 2.49927 13.9346 3.24927 14.6821C3.89594 15.328 4.7401 15.668 5.6901 15.668H13.8851C13.8868 15.6663 13.8934 15.668 13.8984 15.668C14.8493 15.668 15.6926 15.328 16.3393 14.6821C17.0901 13.9346 17.5026 12.8605 17.5026 11.658V5.93464C17.5026 3.60714 15.9809 1.91797 13.8851 1.91797ZM15.1984 5.7753C15.4159 6.0428 15.3751 6.43614 15.1076 6.65447L11.4043 9.66447C10.9359 10.0361 10.3759 10.222 9.81677 10.222C9.25927 10.222 8.70344 10.0378 8.23844 9.66947L4.50094 6.65614C4.23177 6.43947 4.1901 6.0453 4.40594 5.77697C4.62344 5.50947 5.01677 5.46697 5.2851 5.6828L9.01927 8.6928C9.48844 9.06447 10.1493 9.06447 10.6218 8.68947L14.3184 5.68447C14.5868 5.4653 14.9801 5.50697 15.1984 5.7753Z"
      fill="currentColor"
    />
  </svg>
);

export const ICUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9 2.89062C6.38 2.89062 4.25 5.02063 4.25 7.64062C4.25 10.2106 6.26 12.2906 8.88 12.3806C8.96 12.3706 9.04 12.3706 9.1 12.3806C9.12 12.3806 9.13 12.3806 9.15 12.3806C9.16 12.3806 9.16 12.3806 9.17 12.3806C11.73 12.2906 13.74 10.2106 13.75 7.64062C13.75 5.02063 11.62 2.89062 9 2.89062Z"
      fill="currentColor"
    />
    <path
      d="M14.0809 15.0395C11.2909 13.1795 6.74094 13.1795 3.93094 15.0395C2.66094 15.8895 1.96094 17.0395 1.96094 18.2695C1.96094 19.4995 2.66094 20.6395 3.92094 21.4795C5.32094 22.4195 7.16094 22.8895 9.00094 22.8895C10.8409 22.8895 12.6809 22.4195 14.0809 21.4795C15.3409 20.6295 16.0409 19.4895 16.0409 18.2495C16.0309 17.0195 15.3409 15.8795 14.0809 15.0395Z"
      fill="currentColor"
    />
    <path
      d="M19.9894 8.22877C20.1494 10.1688 18.7694 11.8688 16.8594 12.0988C16.8494 12.0988 16.8494 12.0988 16.8394 12.0988H16.8094C16.7494 12.0988 16.6894 12.0988 16.6394 12.1188C15.6694 12.1688 14.7794 11.8588 14.1094 11.2888C15.1394 10.3688 15.7294 8.98877 15.6094 7.48877C15.5394 6.67877 15.2594 5.93877 14.8394 5.30877C15.2194 5.11877 15.6594 4.99877 16.1094 4.95877C18.0694 4.78877 19.8194 6.24877 19.9894 8.22877Z"
      fill="currentColor"
    />
    <path
      d="M21.9922 17.481C21.9122 18.451 21.2922 19.291 20.2522 19.861C19.2522 20.411 17.9922 20.671 16.7422 20.641C17.4622 19.991 17.8822 19.181 17.9622 18.321C18.0622 17.081 17.4722 15.891 16.2922 14.941C15.6222 14.411 14.8422 13.991 13.9922 13.681C16.2022 13.041 18.9822 13.471 20.6922 14.851C21.6122 15.591 22.0822 16.521 21.9922 17.481Z"
      fill="currentColor"
    />
  </svg>
);

export const ICVolume = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.0003 17.6409C17.8403 17.6409 17.6903 17.5909 17.5503 17.4909C17.2203 17.2409 17.1503 16.7709 17.4003 16.4409C18.9703 14.3509 18.9703 11.4309 17.4003 9.3409C17.1503 9.0109 17.2203 8.5409 17.5503 8.2909C17.8803 8.0409 18.3503 8.1109 18.6003 8.4409C20.5603 11.0609 20.5603 14.7209 18.6003 17.3409C18.4503 17.5409 18.2303 17.6409 18.0003 17.6409Z"
      fill="currentColor"
    />
    <path
      d="M19.8284 20.1409C19.6684 20.1409 19.5184 20.0909 19.3784 19.9909C19.0484 19.7409 18.9784 19.2709 19.2284 18.9409C21.8984 15.3809 21.8984 10.4009 19.2284 6.8409C18.9784 6.5109 19.0484 6.0409 19.3784 5.7909C19.7084 5.5409 20.1784 5.6109 20.4284 5.9409C23.4984 10.0309 23.4984 15.7509 20.4284 19.8409C20.2884 20.0409 20.0584 20.1409 19.8284 20.1409Z"
      fill="currentColor"
    />
    <path
      d="M14.02 4.6723C12.9 4.0523 11.47 4.2123 10.01 5.1223L7.09 6.9523C6.89 7.0723 6.66 7.1423 6.43 7.1423H5.5H5C2.58 7.1423 1.25 8.4723 1.25 10.8923V14.8923C1.25 17.3123 2.58 18.6423 5 18.6423H5.5H6.43C6.66 18.6423 6.89 18.7123 7.09 18.8323L10.01 20.6623C10.89 21.2123 11.75 21.4823 12.55 21.4823C13.07 21.4823 13.57 21.3623 14.02 21.1123C15.13 20.4923 15.75 19.2023 15.75 17.4823V8.3023C15.75 6.5823 15.13 5.2923 14.02 4.6723Z"
      fill="currentColor"
    />
  </svg>
);

export const ICB2BBriefcase = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.0871 7.87016C20.2371 6.93016 18.8171 6.46016 16.7571 6.46016H16.5171V6.42016C16.5171 4.74016 16.5171 2.66016 12.7571 2.66016H11.2371C7.47711 2.66016 7.47711 4.75016 7.47711 6.42016V6.47016H7.23711C5.16711 6.47016 3.75711 6.94016 2.90711 7.88016C1.91711 8.98016 1.94711 10.4602 2.04711 11.4702L2.05711 11.5402L2.13456 12.3534C2.14883 12.5032 2.22949 12.6385 2.35538 12.7209C2.59519 12.8778 2.99653 13.1365 3.23711 13.2702C3.37711 13.3602 3.52711 13.4402 3.67711 13.5202C5.38711 14.4602 7.26711 15.0902 9.17711 15.4002C9.26711 16.3402 9.67711 17.4402 11.8671 17.4402C14.0571 17.4402 14.4871 16.3502 14.5571 15.3802C16.5971 15.0502 18.5671 14.3402 20.3471 13.3002C20.4071 13.2702 20.4471 13.2402 20.4971 13.2102C20.8938 12.9859 21.3054 12.7096 21.6806 12.439C21.7936 12.3575 21.8659 12.2314 21.8812 12.0929L21.8971 11.9502L21.9471 11.4802C21.9571 11.4202 21.9571 11.3702 21.9671 11.3002C22.0471 10.2902 22.0271 8.91016 21.0871 7.87016ZM13.0871 14.7202C13.0871 15.7802 13.0871 15.9402 11.8571 15.9402C10.6271 15.9402 10.6271 15.7502 10.6271 14.7302V13.4702H13.0871V14.7202ZM8.90711 6.46016V6.42016C8.90711 4.72016 8.90711 4.09016 11.2371 4.09016H12.7571C15.0871 4.09016 15.0871 4.73016 15.0871 6.42016V6.47016H8.90711V6.46016Z"
      fill="currentColor"
    />
    <path
      d="M20.8772 14.6255C21.2308 14.4572 21.6381 14.7375 21.6027 15.1275L21.2438 19.0813C21.0338 21.0813 20.2138 23.1213 15.8138 23.1213H8.19375C3.79375 23.1213 2.97375 21.0813 2.76375 19.0913L2.42304 15.3435C2.388 14.958 2.78595 14.6781 3.13859 14.8376C4.27801 15.3531 6.38115 16.2677 7.68032 16.608C7.84463 16.651 7.97752 16.7686 8.04946 16.9225C8.65643 18.2206 9.97286 18.9113 11.8737 18.9113C13.7559 18.9113 15.0889 18.194 15.6979 16.8927C15.7699 16.7387 15.903 16.6212 16.0674 16.5779C17.4469 16.2149 19.6855 15.1926 20.8772 14.6255Z"
      fill="currentColor"
    />
  </svg>
);

export const ICB2BChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22 22.8906H2C1.59 22.8906 1.25 22.5506 1.25 22.1406C1.25 21.7306 1.59 21.3906 2 21.3906H22C22.41 21.3906 22.75 21.7306 22.75 22.1406C22.75 22.5506 22.41 22.8906 22 22.8906Z"
      fill="currentColor"
    />
    <path
      d="M9.75 4.89062V22.8906H14.25V4.89062C14.25 3.79062 13.8 2.89062 12.45 2.89062H11.55C10.2 2.89062 9.75 3.79062 9.75 4.89062Z"
      fill="currentColor"
    />
    <path
      d="M3 10.8906V22.8906H7V10.8906C7 9.79062 6.6 8.89062 5.4 8.89062H4.6C3.4 8.89062 3 9.79062 3 10.8906Z"
      fill="currentColor"
    />
    <path
      d="M17 15.8906V22.8906H21V15.8906C21 14.7906 20.6 13.8906 19.4 13.8906H18.6C17.4 13.8906 17 14.7906 17 15.8906Z"
      fill="currentColor"
    />
  </svg>
);

export const ICB2BChartFavorite = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.1199 15.832L19.4399 16.482C19.5999 16.802 20.0099 17.102 20.3499 17.162L20.7799 17.232C22.0899 17.452 22.3899 18.412 21.4599 19.352L21.0599 19.752C20.7899 20.022 20.6499 20.542 20.7299 20.922L20.7799 21.162C21.1399 22.742 20.2999 23.352 18.9299 22.522L18.6399 22.342C18.2899 22.132 17.7099 22.132 17.3599 22.342L17.0699 22.522C15.6899 23.352 14.8599 22.742 15.2199 21.162L15.2699 20.922C15.3499 20.552 15.2099 20.022 14.9399 19.752L14.5399 19.352C13.6099 18.402 13.9099 17.452 15.2199 17.232L15.6499 17.162C15.9999 17.102 16.3999 16.802 16.5599 16.482L16.8799 15.832C17.4999 14.582 18.4999 14.582 19.1199 15.832Z"
      fill="currentColor"
    />
    <path
      d="M16.19 2.89062H7.81C4.17 2.89062 2 5.06062 2 8.70062V17.0806C2 20.7206 4.17 22.8906 7.81 22.8906H13.09C13.44 22.8906 13.69 22.5306 13.65 22.1806C13.61 21.8006 13.63 21.3506 13.75 20.8306C13.77 20.7606 13.75 20.6806 13.69 20.6206L13.47 20.4006C12.62 19.5406 12.31 18.5006 12.61 17.5506C12.92 16.6106 13.78 15.9506 14.97 15.7506L15.27 15.7006L15.54 15.1606C16.09 14.0406 16.99 13.3906 18 13.3906C19.01 13.3906 19.91 14.0406 20.46 15.1606L20.61 15.4706C20.68 15.6206 20.82 15.7206 20.98 15.7506C21.07 15.7706 21.16 15.7906 21.25 15.8106C21.6 15.9006 22 15.6206 22 15.2506V8.70062C22 5.06062 19.83 2.89062 16.19 2.89062ZM16.26 9.85063L13.95 12.8306C13.66 13.2006 13.25 13.4406 12.78 13.4906C12.32 13.5506 11.85 13.4206 11.49 13.1306L9.66 11.7106C9.59 11.6506 9.51 11.6506 9.47 11.6606C9.43 11.6606 9.36 11.6806 9.3 11.7606L6.92 14.8506C6.77 15.0406 6.55 15.1406 6.32 15.1406C6.16 15.1406 6 15.0906 5.86 14.9806C5.53 14.7306 5.47 14.2606 5.72 13.9306L8.1 10.8406C8.39 10.4706 8.8 10.2306 9.27 10.1706C9.74 10.1106 10.2 10.2406 10.57 10.5306L12.4 11.9706C12.47 12.0306 12.54 12.0206 12.59 12.0206C12.63 12.0206 12.7 12.0006 12.76 11.9206L15.07 8.94063C15.32 8.61063 15.79 8.55062 16.12 8.81063C16.46 9.06063 16.51 9.53062 16.26 9.85063Z"
      fill="currentColor"
    />
  </svg>
);

export const ICB2BStarCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.890625" width="44" height="44" rx="22" fill="white" />
    <path
      d="M25.3929 16.1006L26.8029 18.9206C26.9929 19.3106 27.5029 19.6806 27.9329 19.7606L30.4829 20.1806C32.1129 20.4506 32.4929 21.6306 31.3229 22.8106L29.3329 24.8006C29.0029 25.1306 28.8129 25.7806 28.9229 26.2506L29.4929 28.7106C29.9429 30.6506 28.9029 31.4106 27.1929 30.3906L24.8029 28.9706C24.3729 28.7106 23.6529 28.7106 23.2229 28.9706L20.8329 30.3906C19.1229 31.4006 18.0829 30.6506 18.5329 28.7106L19.1029 26.2506C19.2129 25.7906 19.0229 25.1406 18.6929 24.8006L16.7029 22.8106C15.5329 21.6406 15.9129 20.4606 17.5429 20.1806L20.0929 19.7606C20.5229 19.6906 21.0329 19.3106 21.2229 18.9206L22.6329 16.1006C23.3829 14.5706 24.6229 14.5706 25.3929 16.1006Z"
      fill="#32A4FC"
    />
    <path
      d="M18 16.6406H12C11.59 16.6406 11.25 16.3006 11.25 15.8906C11.25 15.4806 11.59 15.1406 12 15.1406H18C18.41 15.1406 18.75 15.4806 18.75 15.8906C18.75 16.3006 18.41 16.6406 18 16.6406Z"
      fill="#FD82C3"
    />
    <path
      d="M15 30.6406H12C11.59 30.6406 11.25 30.3006 11.25 29.8906C11.25 29.4806 11.59 29.1406 12 29.1406H15C15.41 29.1406 15.75 29.4806 15.75 29.8906C15.75 30.3006 15.41 30.6406 15 30.6406Z"
      fill="#FD82C3"
    />
    <path
      d="M13 23.6406H12C11.59 23.6406 11.25 23.3006 11.25 22.8906C11.25 22.4806 11.59 22.1406 12 22.1406H13C13.41 22.1406 13.75 22.4806 13.75 22.8906C13.75 23.3006 13.41 23.6406 13 23.6406Z"
      fill="#FD82C3"
    />
  </svg>
);

export const ICB2BMailCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.890625" width="44" height="44" rx="22" fill="white" />
    <rect x="12" y="14.8906" width="20" height="16" rx="3" fill="#32A4FC" />
    <path
      d="M11.5 17.3906V17.662L11.7275 17.8098L20.6375 23.6014C21.466 24.1399 22.534 24.1399 23.3625 23.6014L32.2725 17.8098L32.5 17.662V17.3906C32.5 15.7338 31.1569 14.3906 29.5 14.3906H14.5C12.8431 14.3906 11.5 15.7338 11.5 17.3906Z"
      fill="#FD82C3"
      stroke="white"
    />
  </svg>
);

export const ICB2BVolumeCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.890625" width="44" height="44" rx="22" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.3575 16.0749L18.6453 16.2596V24.7347L20.4054 24.9269L27.3276 27.1455C27.4691 27.1906 27.6167 27.2136 27.7653 27.2136C28.5606 27.2136 29.2053 26.5689 29.2053 25.7736V15.2138C29.2053 15.0647 29.1821 14.9165 29.1366 14.7745C28.894 14.0171 28.0833 13.5998 27.326 13.8425L26.3253 14.1631V19.0534C26.3253 19.3185 26.1104 19.5334 25.8453 19.5334C25.5802 19.5334 25.3653 19.3185 25.3653 19.0534V14.4706L20.3575 16.0749ZM17.6897 24.6302V16.3634L14.9294 16.6611C13.2231 16.8452 11.9297 18.2856 11.9297 20.0018V20.9881C11.9297 22.7026 13.2206 24.1421 14.9249 24.3283L17.6897 24.6302Z"
      fill="#32A4FC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.6024 20.4901C31.6024 19.5012 31.0296 18.6417 30.1624 18.125V22.8552C31.0296 22.3385 31.6024 21.479 31.6024 20.4901ZM14.8153 25.2802C14.6524 25.2624 14.4925 25.2357 14.3359 25.2006L16.2636 30.1367C16.4794 30.6892 17.0118 31.0529 17.605 31.0529H18.1421C18.2277 31.0529 18.3131 31.0452 18.3973 31.0301C19.18 30.8891 19.7003 30.1404 19.5593 29.3577L18.9055 25.7268L14.8153 25.2802Z"
      fill="#FF8AD1"
    />
  </svg>
);

export const ICB2BLockCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.5" width="44" height="44" rx="22" fill="white" />
    <path
      d="M21.9972 27.8498C22.8974 27.8498 23.6272 27.1201 23.6272 26.2198C23.6272 25.3196 22.8974 24.5898 21.9972 24.5898C21.097 24.5898 20.3672 25.3196 20.3672 26.2198C20.3672 27.1201 21.097 27.8498 21.9972 27.8498Z"
      fill="#FD82C3"
    />
    <path
      d="M26.65 19.9414H17.35C13.25 19.9414 12 21.1914 12 25.2914V27.1514C12 31.2514 13.25 32.5014 17.35 32.5014H26.65C30.75 32.5014 32 31.2514 32 27.1514V25.2914C32 21.1914 30.75 19.9414 26.65 19.9414ZM22 29.2414C20.33 29.2414 18.98 27.8814 18.98 26.2214C18.98 24.5614 20.33 23.2014 22 23.2014C23.67 23.2014 25.02 24.5614 25.02 26.2214C25.02 27.8814 23.67 29.2414 22 29.2414Z"
      fill="#32A4FC"
    />
    <path
      d="M17.1188 19.95V18.78C17.1188 15.85 17.9488 13.9 21.9988 13.9C26.3288 13.9 26.8787 16.01 26.8787 17.85C26.8787 18.24 27.1888 18.55 27.5788 18.55C27.9688 18.55 28.2788 18.24 28.2788 17.85C28.2788 14.3 26.1688 12.5 21.9988 12.5C16.3688 12.5 15.7188 16.08 15.7188 18.78V20.03C16.1387 19.98 16.6088 19.95 17.1188 19.95Z"
      fill="#FD82C3"
    />
  </svg>
);

export const ICB2ChartCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.5" width="44" height="44" rx="22" fill="white" />
    <path
      d="M30.97 26.5H28.03C26.76 26.5 26 27.26 26 28.53V31.47C26 32.74 26.76 33.5 28.03 33.5H30.97C32.24 33.5 33 32.74 33 31.47V28.53C33 27.26 32.24 26.5 30.97 26.5ZM31.63 28.95L29.26 31.72C29.16 31.83 29.03 31.9 28.88 31.9C28.88 31.9 28.87 31.9 28.86 31.9C28.73 31.9 28.59 31.85 28.5 31.75L27.4 30.67C27.2 30.47 27.2 30.14 27.4 29.93C27.6 29.73 27.93 29.73 28.14 29.93L28.83 30.62L30.84 28.28C31.02 28.06 31.35 28.03 31.57 28.22C31.79 28.41 31.81 28.74 31.63 28.95Z"
      fill="#38465C"
    />
    <path
      d="M30.97 26.5H28.03C26.76 26.5 26 27.26 26 28.53V31.47C26 32.74 26.76 33.5 28.03 33.5H30.97C32.24 33.5 33 32.74 33 31.47V28.53C33 27.26 32.24 26.5 30.97 26.5ZM31.63 28.95L29.26 31.72C29.16 31.83 29.03 31.9 28.88 31.9C28.88 31.9 28.87 31.9 28.86 31.9C28.73 31.9 28.59 31.85 28.5 31.75L27.4 30.67C27.2 30.47 27.2 30.14 27.4 29.93C27.6 29.73 27.93 29.73 28.14 29.93L28.83 30.62L30.84 28.28C31.02 28.06 31.35 28.03 31.57 28.22C31.79 28.41 31.81 28.74 31.63 28.95Z"
      fill="#FD82C3"
    />
    <path
      d="M31.97 17.71C31.69 14.98 29.52 12.81 26.79 12.53C26.59 12.51 26.39 12.5 26.19 12.5H17.81C17.61 12.5 17.41 12.51 17.21 12.53C14.48 12.81 12.31 14.98 12.03 17.71C12.01 17.91 12 18.11 12 18.31V26.69C12 26.89 12.01 27.09 12.03 27.29C12.31 30.02 14.48 32.19 17.21 32.47C17.41 32.49 17.61 32.5 17.81 32.5H24C24.55 32.5 25 32.05 25 31.5V28.53C25 26.86 26.36 25.5 28.03 25.5H31C31.55 25.5 32 25.05 32 24.5V18.31C32 18.11 31.99 17.91 31.97 17.71ZM17.75 24C17.75 24.41 17.41 24.75 17 24.75C16.59 24.75 16.25 24.41 16.25 24V21C16.25 20.59 16.59 20.25 17 20.25C17.41 20.25 17.75 20.59 17.75 21V24ZM22.75 24C22.75 24.41 22.41 24.75 22 24.75C21.59 24.75 21.25 24.41 21.25 24V21C21.25 20.59 21.59 20.25 22 20.25C22.41 20.25 22.75 20.59 22.75 21V24Z"
      fill="#32A4FC"
    />
  </svg>
);

export const ICB2BCurrencyCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.890625" width="44" height="44" rx="22" fill="white" />
    <path
      d="M19.99 28.8706C24.4028 28.8706 27.98 25.2934 27.98 20.8806C27.98 16.4679 24.4028 12.8906 19.99 12.8906C15.5772 12.8906 12 16.4679 12 20.8806C12 25.2934 15.5772 28.8706 19.99 28.8706Z"
      fill="#32A4FC"
    />
    <path
      d="M31.9681 26.8787C31.9681 30.1788 29.2881 32.8587 25.9881 32.8587C23.9481 32.8587 22.1581 31.8387 21.0781 30.2887C25.4381 29.7987 28.9081 26.3288 29.3981 21.9688C30.9481 23.0488 31.9681 24.8387 31.9681 26.8787Z"
      fill="#FD82C3"
    />
    <path
      d="M21.45 20.6008L19.05 19.7608C18.81 19.6808 18.76 19.6608 18.76 19.3108C18.76 19.0508 18.94 18.8408 19.17 18.8408H20.67C20.99 18.8408 21.25 19.1308 21.25 19.4908C21.25 19.9008 21.59 20.2408 22 20.2408C22.41 20.2408 22.75 19.9008 22.75 19.4908C22.75 18.3408 21.86 17.4008 20.75 17.3508V17.3008C20.75 16.8908 20.41 16.5508 20 16.5508C19.59 16.5508 19.25 16.8808 19.25 17.3008V17.3508H19.16C18.11 17.3508 17.25 18.2308 17.25 19.3208C17.25 20.2708 17.67 20.8808 18.54 21.1808L20.95 22.0208C21.19 22.1008 21.24 22.1208 21.24 22.4708C21.24 22.7308 21.06 22.9408 20.83 22.9408H19.33C19.01 22.9408 18.75 22.6508 18.75 22.2908C18.75 21.8808 18.41 21.5408 18 21.5408C17.59 21.5408 17.25 21.8808 17.25 22.2908C17.25 23.4408 18.14 24.3808 19.25 24.4308V24.4908C19.25 24.9008 19.59 25.2408 20 25.2408C20.41 25.2408 20.75 24.9008 20.75 24.4908V24.4408H20.84C21.89 24.4408 22.75 23.5608 22.75 22.4708C22.75 21.5208 22.32 20.9108 21.45 20.6008Z"
      fill="white"
    />
  </svg>
);

export const ICB2BHandCurrencyCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.890625" width="44" height="44" rx="22" fill="white" />
    <path
      d="M22.6302 24.1041C25.7267 24.1041 28.237 21.5939 28.237 18.4974C28.237 15.4009 25.7267 12.8906 22.6302 12.8906C19.5337 12.8906 17.0234 15.4009 17.0234 18.4974C17.0234 21.5939 19.5337 24.1041 22.6302 24.1041Z"
      fill="#32A4FC"
    />
    <path
      d="M29.6323 16.2491C29.4876 15.9597 29.6049 15.6077 29.8943 15.463L31.1496 14.8354C31.4391 14.6907 31.7909 14.808 31.9357 15.0975C32.0804 15.3869 31.9631 15.7389 31.6736 15.8836L30.4184 16.5112C30.1301 16.6552 29.7776 16.5397 29.6323 16.2491Z"
      fill="#FD82C3"
    />
    <path
      d="M31.1496 22.1596L29.8943 21.532C29.6049 21.3873 29.4876 21.0353 29.6323 20.7459C29.777 20.4564 30.1291 20.3392 30.4184 20.4838L31.6736 21.1114C31.9631 21.2561 32.0804 21.6081 31.9357 21.8975C31.7907 22.1873 31.4386 22.3041 31.1496 22.1596Z"
      fill="#FD82C3"
    />
    <path
      d="M24.4713 19.4362C24.4713 18.594 23.7862 17.9089 22.9439 17.9089H22.3164C22.1204 17.9089 21.9609 17.7494 21.9609 17.5534C21.9609 17.3573 22.1204 17.1979 22.3164 17.1979H23.2578C23.5814 17.1979 23.8437 16.9355 23.8437 16.612C23.8437 16.2884 23.5814 16.026 23.2578 16.026H23.2161V15.9844C23.2161 15.6608 22.9538 15.3984 22.6302 15.3984C22.3066 15.3984 22.0443 15.6608 22.0443 15.9844V16.0508C21.3316 16.1796 20.7891 16.8041 20.7891 17.5534C20.7891 18.3956 21.4742 19.0807 22.3164 19.0807H22.944C23.14 19.0807 23.2995 19.2402 23.2995 19.4362C23.2995 19.6323 23.14 19.7917 22.944 19.7917H22.0026C21.679 19.7917 21.4166 20.0541 21.4166 20.3777C21.4166 20.7012 21.679 20.9636 22.0026 20.9636H22.0442V21.0052C22.0442 21.3288 22.3066 21.5912 22.6302 21.5912C22.9537 21.5912 23.2161 21.3288 23.2161 21.0052V20.9388C23.9289 20.81 24.4713 20.1855 24.4713 19.4362Z"
      fill="white"
    />
    <path
      d="M30.573 25.7478C29.7939 24.9708 28.5848 25.0202 27.8558 25.7492L25.0219 28.5832C24.6737 28.931 24.2116 29.1223 23.7204 29.1223H21.5939C21.2784 29.1223 21.0069 28.8799 20.992 28.5647C20.9762 28.2282 21.2443 27.9505 21.5773 27.9505H23.4748C24.168 27.9505 24.73 27.3885 24.73 26.6953C24.73 26.0021 24.168 25.4401 23.4748 25.4401H19.6532C17.8757 25.4401 17.3125 26.9374 17.2191 27.3229H15.7266C15.403 27.3229 15.1406 27.5852 15.1406 27.9088V31.0468C15.1406 31.3704 15.403 31.6328 15.7266 31.6328H24.3923C25.1209 31.6328 25.8061 31.3489 26.3218 30.8335L30.5758 26.5793C30.7984 26.3567 30.812 25.9862 30.573 25.7478Z"
      fill="#FD82C3"
    />
    <path
      d="M15.7239 32.8867H12.5859C12.2623 32.8867 12 32.6244 12 32.3008V26.6523C12 26.3287 12.2623 26.0664 12.5859 26.0664H15.7239C16.0475 26.0664 16.3099 26.3287 16.3099 26.6523V32.3008C16.3099 32.6244 16.0476 32.8867 15.7239 32.8867Z"
      fill="#32A4FC"
    />
    <path
      d="M15.6232 16.2492C15.7679 15.9597 15.6506 15.6077 15.3611 15.4631L14.1059 14.8354C13.8164 14.6907 13.4645 14.808 13.3198 15.0975C13.1751 15.3869 13.2924 15.7389 13.5818 15.8836L14.8371 16.5112C15.1254 16.6553 15.4779 16.5397 15.6232 16.2492Z"
      fill="#FD82C3"
    />
    <path
      d="M14.1059 22.1596L15.3611 21.532C15.6506 21.3873 15.7679 21.0353 15.6232 20.7459C15.4784 20.4564 15.1264 20.3392 14.8371 20.4838L13.5818 21.1114C13.2924 21.2561 13.1751 21.6081 13.3198 21.8975C13.4647 22.1873 13.8168 22.3041 14.1059 22.1596Z"
      fill="#FD82C3"
    />
  </svg>
);

export const ICB2ChartIndicesCircle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 44 45"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.890625" width="44" height="44" rx="22" fill="white" />
    <path
      d="M17.8343 21.5195H14.7121C14.3792 21.5195 14.1094 21.7895 14.1094 22.1223V32.289C14.1094 32.6218 14.3792 32.8917 14.7121 32.8917H17.8343C18.1673 32.8917 18.4371 32.6218 18.4371 32.289V22.1223C18.4371 21.7893 18.1673 21.5195 17.8343 21.5195Z"
      fill="#32A4FC"
    />
    <path
      d="M29.4595 18.1133H26.3371C26.0043 18.1133 25.7344 18.3832 25.7344 18.716V32.2899C25.7344 32.6227 26.0043 32.8926 26.3371 32.8926H29.4595C29.7923 32.8926 30.0622 32.6227 30.0622 32.2899V18.716C30.0622 18.3832 29.7923 18.1133 29.4595 18.1133Z"
      fill="#32A4FC"
    />
    <path
      d="M23.647 22.625H20.5246C20.1918 22.625 19.9219 22.8949 19.9219 23.2277V32.2875C19.9219 32.6203 20.1918 32.8902 20.5246 32.8902H23.647C23.9798 32.8902 24.2497 32.6203 24.2497 32.2875V23.2277C24.2497 22.8949 23.9798 22.625 23.647 22.625Z"
      fill="#32A4FC"
    />
    <path
      d="M30.5682 32.889H13.6027C13.2699 32.889 13 32.6191 13 32.2863C13 31.9535 13.2699 31.6836 13.6027 31.6836H30.5682C30.9011 31.6836 31.1709 31.9535 31.1709 32.2863C31.1709 32.6191 30.9011 32.889 30.5682 32.889Z"
      fill="#FD82C3"
    />
    <path
      d="M27.5324 12.8906H25.1525C24.8197 12.8906 24.5498 13.1606 24.5498 13.4933C24.5498 13.8261 24.8197 14.0961 25.1525 14.0961H26.076C23.9558 16.1533 21.6552 17.2491 20.0945 17.8064C18.3836 18.4175 17.1626 18.5339 17.1505 18.5352C16.8194 18.5648 16.5746 18.8571 16.6039 19.1884C16.6316 19.5019 16.8946 19.7382 17.2036 19.7382C17.2213 19.7382 17.2393 19.7374 17.2572 19.7359C17.3116 19.731 18.6118 19.6107 20.4575 18.9568C22.1459 18.3586 24.6365 17.1776 26.9297 14.9474V15.8733C26.9297 16.2062 27.1995 16.476 27.5324 16.476C27.8652 16.476 28.1351 16.2062 28.1351 15.8733V13.4933C28.1351 13.1606 27.8654 12.8906 27.5324 12.8906Z"
      fill="#FD82C3"
    />
  </svg>
);

export const ICCurve = (props: SVGProps<SVGSVGElement>) => {
  const { width } = useWindowSize();
  return (
    <svg
      width="100%"
      height={width >= 1920 ? 250 : null}
      viewBox="0 0 1440 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio={width >= 1920 ? 'none' : null}
      {...props}
    >
      <path
        d="M1440 225.5H0V221.465C0 221.465 408.487 -0.000305176 738.744 -0.000305176C1069 -0.000305176 1440 221.465 1440 221.465V225.5Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const ICKindicareLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 105 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M63.4604 24.262C63.1265 24.9182 62.641 25.5445 62.0643 26.0814C59.5758 28.3482 55.6912 28.378 53.1723 26.1709C50.2588 23.6058 50.2588 19.1318 53.1419 16.5668C55.6608 14.3298 59.5454 14.3298 62.034 16.5966C62.641 17.1335 63.0962 17.7598 63.43 18.416C63.6728 18.8932 64.1887 19.1915 64.735 19.1915H66.3738C67.4057 19.1915 68.1037 18.1476 67.7091 17.1931C67.1629 15.8808 66.3131 14.6579 65.2206 13.614C60.9414 9.52778 54.022 9.58743 49.8036 13.7631C45.5245 17.9984 45.5548 24.8287 49.8339 29.0342C54.0524 33.18 61.0021 33.2099 65.2509 29.0938C66.3435 28.0499 67.1629 26.827 67.7091 25.5147C68.1037 24.5602 67.4057 23.5163 66.3738 23.5163H64.735C64.2191 23.4865 63.7032 23.7848 63.4604 24.262Z"
      fill="white"
    />
    <path
      d="M57.5724 8.81369C60.0027 8.81369 61.9729 6.87741 61.9729 4.48888C61.9729 2.10035 60.0027 0.164062 57.5724 0.164062C55.1421 0.164062 53.1719 2.10035 53.1719 4.48888C53.1719 6.87741 55.1421 8.81369 57.5724 8.81369Z"
      fill="white"
    />
    <path
      d="M57.5736 23.8184C58.9648 23.8184 60.0925 22.71 60.0925 21.3428C60.0925 19.9755 58.9648 18.8672 57.5736 18.8672C56.1824 18.8672 55.0547 19.9755 55.0547 21.3428C55.0547 22.71 56.1824 23.8184 57.5736 23.8184Z"
      fill="white"
    />
    <path
      d="M2.64031 27.8431H0V14.8984H2.64031V21.3707L7.55675 14.8984H10.5612L6.37316 20.3567L10.5309 27.8431H7.5264L4.6433 22.5936L2.64031 25.2482V27.8431Z"
      fill="white"
    />
    <path d="M14.3903 27.8431H11.75V14.8984H14.3903V27.8431Z" fill="white" />
    <path
      d="M27.3746 27.8431H24.5522L19.4841 19.3127V27.8431H16.8438V14.8984H19.6662L24.7343 23.4288V14.8984H27.3746V27.8431Z"
      fill="white"
    />
    <path
      d="M40.3659 21.3707C40.3659 21.9673 40.2749 22.534 40.1231 23.1007C39.9714 23.6375 39.759 24.1744 39.4555 24.6516C39.1823 25.1289 38.8182 25.5763 38.4236 25.964C38.0291 26.3517 37.5739 26.7097 37.0883 26.9781C36.6027 27.2465 36.0868 27.4851 35.5102 27.6343C34.9639 27.7834 34.3569 27.8729 33.75 27.8729H29.8047V14.8984H33.7803C34.3873 14.8984 34.9639 14.9879 35.5405 15.137C36.0868 15.2862 36.6331 15.495 37.1187 15.7932C37.6042 16.0617 38.0595 16.4196 38.454 16.8073C38.8485 17.1951 39.1823 17.6425 39.4858 18.1197C39.759 18.5969 40.0018 19.1039 40.1535 19.6706C40.3052 20.2075 40.3659 20.7742 40.3659 21.3707ZM37.7256 21.3707C37.7256 20.8339 37.6346 20.3268 37.4221 19.8496C37.2097 19.3724 36.9366 18.9548 36.5724 18.6267C36.2082 18.2688 35.7833 18.0004 35.3281 17.7916C34.8425 17.5828 34.3266 17.4933 33.7803 17.4933H32.4753V25.2482H33.7803C34.3266 25.2482 34.8425 25.1587 35.3281 24.9499C35.8137 24.7411 36.2386 24.4727 36.5724 24.1148C36.9366 23.7569 37.2097 23.3393 37.4221 22.8919C37.6346 22.4147 37.7256 21.9076 37.7256 21.3707Z"
      fill="white"
    />
    <path d="M44.7653 27.8431H42.125V14.8984H44.7653V27.8431Z" fill="white" />
    <path
      d="M80.6081 27.8431H77.9678V25.2482H72.6872V27.8431H70.0469V20.0882C70.0469 19.3724 70.1986 18.6864 70.4718 18.06C70.7449 17.4337 71.1091 16.8968 71.5946 16.4196C72.0802 15.9424 72.6265 15.5844 73.2638 15.316C73.9011 15.0476 74.5991 14.8984 75.3275 14.8984C76.0559 14.8984 76.7539 15.0476 77.3912 15.316C78.0285 15.5844 78.5748 15.9424 79.0603 16.4196C79.5459 16.8968 79.9101 17.4337 80.1832 18.06C80.4564 18.6864 80.6081 19.3724 80.6081 20.0882V27.8431ZM77.9678 22.6533V20.0584C77.9678 19.7005 77.9071 19.3724 77.7554 19.0443C77.6036 18.7162 77.4215 18.4478 77.1787 18.2092C76.936 17.9705 76.6628 17.7916 76.329 17.6425C75.9952 17.4933 75.6613 17.4337 75.2971 17.4337C74.933 17.4337 74.5991 17.4933 74.2653 17.6425C73.9315 17.7916 73.6583 17.9705 73.4155 18.2092C73.1728 18.4478 72.9907 18.7162 72.8389 19.0443C72.6872 19.3724 72.6265 19.7005 72.6265 20.0584V22.6533H77.9678Z"
      fill="white"
    />
    <path
      d="M85.5231 27.8431H82.8828V14.8984H88.1634C88.649 14.8984 89.1042 14.9581 89.5595 15.0774C90.0147 15.1967 90.4092 15.3757 90.8037 15.6143C91.1983 15.8529 91.5625 16.1213 91.8659 16.4196C92.1998 16.7477 92.4729 17.0758 92.6853 17.4635C92.9281 17.8512 93.0799 18.2688 93.2316 18.6864C93.353 19.1338 93.4137 19.5812 93.4137 20.0584C93.4137 20.5058 93.353 20.9234 93.2316 21.3707C93.1102 21.7883 92.9585 22.2059 92.746 22.5638C92.5336 22.9515 92.2908 23.2796 91.957 23.6077C91.6535 23.9358 91.3197 24.2042 90.9251 24.4429L92.3819 27.8431H89.5898L88.4366 25.2482L85.4928 25.278V27.8431H85.5231ZM85.5231 17.4933V22.6831H88.1634C88.5276 22.6831 88.8614 22.6235 89.1953 22.4743C89.4988 22.3252 89.8022 22.1462 90.045 21.9076C90.2878 21.669 90.4699 21.4006 90.6217 21.0725C90.7734 20.7444 90.8341 20.4163 90.8341 20.0584C90.8341 19.7005 90.7734 19.3724 90.6217 19.0443C90.4699 18.7162 90.2878 18.4478 90.045 18.2092C89.8022 17.9705 89.5291 17.7916 89.1953 17.6425C88.8918 17.4933 88.5276 17.4337 88.1634 17.4337H85.5231V17.4933Z"
      fill="white"
    />
    <path
      d="M104.184 27.8431H95.1406V14.8984H104.184V17.4933H97.7809V20.0882H102.121V22.6831H97.7809V25.278H104.184V27.8431Z"
      fill="white"
    />
  </svg>
);

export const ICArrowUp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.1047 15.4802C26.8197 15.4802 26.5347 15.3752 26.3097 15.1502L17.9997 6.84016L9.68969 15.1502C9.25469 15.5852 8.53469 15.5852 8.09969 15.1502C7.66469 14.7152 7.66469 13.9952 8.09969 13.5602L17.2047 4.45516C17.6397 4.02016 18.3597 4.02016 18.7947 4.45516L27.8997 13.5602C28.3347 13.9952 28.3347 14.7152 27.8997 15.1502C27.6897 15.3752 27.3897 15.4802 27.1047 15.4802Z"
      fill="url(#paint0_linear_23526_13844)"
    />
    <path
      d="M18 31.8739C17.385 31.8739 16.875 31.3639 16.875 30.7489V5.50391C16.875 4.88891 17.385 4.37891 18 4.37891C18.615 4.37891 19.125 4.88891 19.125 5.50391V30.7489C19.125 31.3639 18.615 31.8739 18 31.8739Z"
      fill="url(#paint1_linear_23526_13844)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_23526_13844"
        x1="17.9997"
        y1="4.12891"
        x2="17.9997"
        y2="15.4802"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.432292" stopColor="#2299FC" />
        <stop offset="0.786458" stopColor="#2299FC" stopOpacity="0.574713" />
        <stop offset="1" stopColor="#2299FC" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_23526_13844"
        x1="18"
        y1="4.37891"
        x2="18"
        y2="31.8739"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.432292" stopColor="#2299FC" />
        <stop offset="0.786458" stopColor="#2299FC" stopOpacity="0.574713" />
        <stop offset="1" stopColor="#2299FC" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const ICMouseLine = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="255"
    height="89"
    viewBox="0 0 255 89"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      opacity="0.5"
      d="M6.125 86.5039C35.5673 40.8091 120.484 -32.5929 224.61 39.3576"
      stroke="url(#paint0_linear_23052_564787)"
      strokeWidth="6.29623"
    />
    <path
      d="M250.74 43.9422C250.78 54.6432 242.128 63.3607 231.427 63.4008C220.726 63.441 212.008 54.7887 211.968 44.0876C211.928 33.3866 220.58 24.6691 231.281 24.629C241.982 24.5888 250.7 33.2411 250.74 43.9422ZM229.715 35.9758L228.603 39.3338C228.296 40.2655 227.582 40.9854 226.652 41.2991L223.303 42.4361C220.418 43.3968 220.491 47.4676 223.383 48.3486L235.572 52.0831C237.998 52.8107 240.219 50.573 239.473 48.1913L235.647 36.0117C234.725 33.1266 230.654 33.1031 229.715 35.9758Z"
      fill="#FEB2E0"
    />
    <defs>
      <linearGradient
        id="paint0_linear_23052_564787"
        x1="5.53116"
        y1="80.1962"
        x2="229.297"
        y2="24.8428"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.52" />
        <stop offset="0.145833" stopColor="#CAE8FF" />
        <stop offset="0.3125" stopColor="#26C1FC" />
        <stop offset="0.46875" stopColor="#86C9FC" />
        <stop offset="0.755208" stopColor="#FF8AD1" />
        <stop offset="0.911458" stopColor="#FFD2EA" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export const ICPause = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9583 0.667969C6.59833 0.667969 0.625 6.6413 0.625 14.0013C0.625 21.3613 6.59833 27.3346 13.9583 27.3346C21.3183 27.3346 27.2917 21.3613 27.2917 14.0013C27.2917 6.6413 21.3317 0.667969 13.9583 0.667969ZM12.2917 18.0413C12.2917 18.6813 12.025 18.9346 11.345 18.9346H9.61167C8.93167 18.9346 8.665 18.6813 8.665 18.0413V9.9613C8.665 9.3213 8.93167 9.06797 9.61167 9.06797H11.3317C12.0117 9.06797 12.2783 9.3213 12.2783 9.9613V18.0413H12.2917ZM19.3317 18.0413C19.3317 18.6813 19.065 18.9346 18.385 18.9346H16.665C15.985 18.9346 15.7183 18.6813 15.7183 18.0413V9.9613C15.7183 9.3213 15.985 9.06797 16.665 9.06797H18.385C19.065 9.06797 19.3317 9.3213 19.3317 9.9613V18.0413Z"
      fill="currentColor"
    />
  </svg>
);

export const ICPlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.9583 0.667969C6.59833 0.667969 0.625 6.6413 0.625 14.0013C0.625 21.3613 6.59833 27.3346 13.9583 27.3346C21.3183 27.3346 27.2917 21.3613 27.2917 14.0013C27.2917 6.6413 21.3317 0.667969 13.9583 0.667969ZM17.9583 16.9746L14.0917 19.2013C13.6117 19.4813 13.0783 19.6146 12.5583 19.6146C12.025 19.6146 11.505 19.4813 11.025 19.2013C10.065 18.6413 9.49167 17.6546 9.49167 16.5346V12.068C9.49167 10.9613 10.065 9.9613 11.025 9.4013C11.985 8.8413 13.1317 8.8413 14.105 9.4013L17.9717 11.628C18.9317 12.188 19.505 13.1746 19.505 14.2946C19.505 15.4146 18.9317 16.4146 17.9583 16.9746Z"
      fill="currentColor"
    />
  </svg>
);

export const ICMailRoundedOutline = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8851 0.667969C16.6601 0.667969 18.7526 2.93214 18.7526 5.93464V11.658C18.7526 13.1946 18.2093 14.583 17.2218 15.568C16.3359 16.4505 15.1868 16.918 13.8984 16.918H5.6876C4.40177 16.918 3.25344 16.4513 2.36677 15.568C1.37927 14.583 0.835938 13.1946 0.835938 11.658V5.93464C0.835938 2.93214 2.92844 0.667969 5.70344 0.667969H13.8851ZM13.8851 1.91797H5.70344C3.6076 1.91797 2.08594 3.60714 2.08594 5.93464V11.658C2.08594 12.8605 2.49927 13.9346 3.24927 14.6821C3.89594 15.328 4.7401 15.668 5.6901 15.668H13.8851C13.8868 15.6663 13.8934 15.668 13.8984 15.668C14.8493 15.668 15.6926 15.328 16.3393 14.6821C17.0901 13.9346 17.5026 12.8605 17.5026 11.658V5.93464C17.5026 3.60714 15.9809 1.91797 13.8851 1.91797ZM15.1984 5.7753C15.4159 6.0428 15.3751 6.43614 15.1076 6.65447L11.4043 9.66447C10.9359 10.0361 10.3759 10.222 9.81677 10.222C9.25927 10.222 8.70344 10.0378 8.23844 9.66947L4.50094 6.65614C4.23177 6.43947 4.1901 6.0453 4.40594 5.77697C4.62344 5.50947 5.01677 5.46697 5.2851 5.6828L9.01927 8.6928C9.48844 9.06447 10.1493 9.06447 10.6218 8.68947L14.3184 5.68447C14.5868 5.4653 14.9801 5.50697 15.1984 5.7753Z"
      fill="currentColor"
    />
  </svg>
);

export const ICChart = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.3307 18.332H1.66406C1.3224 18.332 1.03906 18.0487 1.03906 17.707C1.03906 17.3654 1.3224 17.082 1.66406 17.082H18.3307C18.6724 17.082 18.9557 17.3654 18.9557 17.707C18.9557 18.0487 18.6724 18.332 18.3307 18.332Z"
      fill="currentColor"
    />
    <path
      d="M8.125 3.33464V18.3346H11.875V3.33464C11.875 2.41797 11.5 1.66797 10.375 1.66797H9.625C8.5 1.66797 8.125 2.41797 8.125 3.33464Z"
      fill="currentColor"
    />
    <path
      d="M2.5 8.33464V18.3346H5.83333V8.33464C5.83333 7.41797 5.5 6.66797 4.5 6.66797H3.83333C2.83333 6.66797 2.5 7.41797 2.5 8.33464Z"
      fill="currentColor"
    />
    <path
      d="M14.1641 12.4987V18.332H17.4974V12.4987C17.4974 11.582 17.1641 10.832 16.1641 10.832H15.4974C14.4974 10.832 14.1641 11.582 14.1641 12.4987Z"
      fill="currentColor"
    />
  </svg>
);

export const ICVolumeHigh = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.0002 13.9599C14.8669 13.9599 14.7419 13.9182 14.6252 13.8349C14.3502 13.6265 14.2919 13.2349 14.5002 12.9599C15.8086 11.2182 15.8086 8.78486 14.5002 7.0432C14.2919 6.7682 14.3502 6.37653 14.6252 6.1682C14.9002 5.95986 15.2919 6.0182 15.5002 6.2932C17.1336 8.47653 17.1336 11.5265 15.5002 13.7099C15.3752 13.8765 15.1919 13.9599 15.0002 13.9599Z"
      fill="currentColor"
    />
    <path
      d="M16.5237 16.0406C16.3903 16.0406 16.2653 15.9989 16.1487 15.9156C15.8737 15.7073 15.8153 15.3156 16.0237 15.0406C18.2487 12.0739 18.2487 7.92392 16.0237 4.95726C15.8153 4.68226 15.8737 4.29059 16.1487 4.08226C16.4237 3.87393 16.8153 3.93226 17.0237 4.20726C19.582 7.61559 19.582 12.3823 17.0237 15.7906C16.907 15.9573 16.7153 16.0406 16.5237 16.0406Z"
      fill="currentColor"
    />
    <path
      d="M11.6807 3.14944C10.7474 2.63278 9.55573 2.76611 8.33906 3.52444L5.90573 5.04944C5.73906 5.14944 5.5474 5.20778 5.35573 5.20778H4.58073H4.16406C2.1474 5.20778 1.03906 6.31611 1.03906 8.33278V11.6661C1.03906 13.6828 2.1474 14.7911 4.16406 14.7911H4.58073H5.35573C5.5474 14.7911 5.73906 14.8494 5.90573 14.9494L8.33906 16.4744C9.0724 16.9328 9.78906 17.1578 10.4557 17.1578C10.8891 17.1578 11.3057 17.0578 11.6807 16.8494C12.6057 16.3328 13.1224 15.2578 13.1224 13.8244V6.17444C13.1224 4.74111 12.6057 3.66611 11.6807 3.14944Z"
      fill="currentColor"
    />
  </svg>
);

export const ICProfileUser = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.4974 1.66797C5.31406 1.66797 3.53906 3.44297 3.53906 5.6263C3.53906 7.76797 5.21406 9.5013 7.3974 9.5763C7.46406 9.56797 7.53073 9.56797 7.58073 9.5763C7.5974 9.5763 7.60573 9.5763 7.6224 9.5763C7.63073 9.5763 7.63073 9.5763 7.63906 9.5763C9.7724 9.5013 11.4474 7.76797 11.4557 5.6263C11.4557 3.44297 9.68073 1.66797 7.4974 1.66797Z"
      fill="white"
    />
    <path
      d="M11.7328 11.7914C9.40781 10.2414 5.61615 10.2414 3.27448 11.7914C2.21615 12.4997 1.63281 13.4581 1.63281 14.4831C1.63281 15.5081 2.21615 16.4581 3.26615 17.1581C4.43281 17.9414 5.96615 18.3331 7.49948 18.3331C9.03281 18.3331 10.5661 17.9414 11.7328 17.1581C12.7828 16.4497 13.3661 15.4997 13.3661 14.4664C13.3578 13.4414 12.7828 12.4914 11.7328 11.7914Z"
      fill="white"
    />
    <path
      d="M16.6578 6.11512C16.7911 7.73179 15.6411 9.14846 14.0495 9.34012C14.0411 9.34012 14.0411 9.34012 14.0328 9.34012H14.0078C13.9578 9.34012 13.9078 9.34012 13.8661 9.35679C13.0578 9.39846 12.3161 9.14012 11.7578 8.66512C12.6161 7.89846 13.1078 6.74846 13.0078 5.49846C12.9495 4.82346 12.7161 4.20679 12.3661 3.68179C12.6828 3.52346 13.0495 3.42346 13.4245 3.39012C15.0578 3.24846 16.5161 4.46512 16.6578 6.11512Z"
      fill="white"
    />
    <path
      d="M18.3229 13.8266C18.2562 14.635 17.7396 15.335 16.8729 15.81C16.0396 16.2683 14.9896 16.485 13.9479 16.46C14.5479 15.9183 14.8979 15.2433 14.9646 14.5266C15.0479 13.4933 14.5563 12.5016 13.5729 11.71C13.0146 11.2683 12.3646 10.9183 11.6562 10.66C13.4979 10.1266 15.8146 10.485 17.2396 11.635C18.0062 12.2516 18.3979 13.0266 18.3229 13.8266Z"
      fill="white"
    />
  </svg>
);

export const ICQuoteGreen = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.29997 21.5538C6.5575 23.4212 5.38752 25.2663 3.82376 27.0438C3.32876 27.6063 3.26126 28.4163 3.66625 29.0463C3.98128 29.5413 4.49873 29.8112 5.06123 29.8112C5.21875 29.8112 5.37627 29.8 5.53378 29.7437C8.84121 28.7763 16.57 25.345 16.7838 14.3425C16.8625 10.1013 13.7575 6.4563 9.71875 6.04005C7.48001 5.81504 5.25253 6.54624 3.59875 8.03125C1.94503 9.52753 0.999999 11.665 0.999999 13.8925C0.999998 17.605 3.63253 20.8563 7.29997 21.5538Z"
      fill="url(#paint0_linear_23663_73758)"
    />
    <path
      d="M27.6763 6.04005C25.4488 5.81504 23.2213 6.54624 21.5676 8.03125C19.9138 9.52753 18.9687 11.665 18.9687 13.8925C18.9687 17.605 21.6013 20.8563 25.2688 21.5538C24.5263 23.4212 23.3563 25.2663 21.7925 27.0438C21.2975 27.6063 21.23 28.4162 21.6351 29.0462C21.95 29.5412 22.4676 29.8112 23.0301 29.8112C23.1875 29.8112 23.345 29.8 23.5025 29.7437C26.81 28.7762 34.5388 25.345 34.7525 14.3425L34.7525 14.185C34.7525 10.0113 31.6813 6.4563 27.6763 6.04005Z"
      fill="url(#paint1_linear_23663_73758)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_23663_73758"
        x1="8.89262"
        y1="6"
        x2="8.89262"
        y2="29.8112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8EE2DE" />
        <stop offset="1" stopColor="#2BADA7" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_23663_73758"
        x1="26.8606"
        y1="6"
        x2="26.8606"
        y2="29.8112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#8EE2DE" />
        <stop offset="1" stopColor="#2BADA7" />
      </linearGradient>
    </defs>
  </svg>
);

export const ICQuoteOrange = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.29997 21.5538C6.5575 23.4212 5.38752 25.2663 3.82376 27.0438C3.32876 27.6063 3.26126 28.4163 3.66625 29.0463C3.98128 29.5413 4.49873 29.8112 5.06123 29.8112C5.21875 29.8112 5.37627 29.8 5.53378 29.7437C8.84121 28.7763 16.57 25.345 16.7838 14.3425C16.8625 10.1013 13.7575 6.4563 9.71875 6.04005C7.48001 5.81504 5.25253 6.54624 3.59875 8.03125C1.94503 9.52753 0.999999 11.665 0.999999 13.8925C0.999998 17.605 3.63253 20.8563 7.29997 21.5538Z"
      fill="url(#paint0_linear_23663_103674)"
    />
    <path
      d="M27.6763 6.04005C25.4488 5.81504 23.2213 6.54624 21.5676 8.03125C19.9138 9.52753 18.9687 11.665 18.9687 13.8925C18.9687 17.605 21.6013 20.8563 25.2688 21.5538C24.5263 23.4212 23.3563 25.2663 21.7925 27.0438C21.2975 27.6063 21.23 28.4162 21.6351 29.0462C21.95 29.5412 22.4676 29.8112 23.0301 29.8112C23.1875 29.8112 23.345 29.8 23.5025 29.7437C26.81 28.7762 34.5388 25.345 34.7525 14.3425L34.7525 14.185C34.7525 10.0113 31.6813 6.4563 27.6763 6.04005Z"
      fill="url(#paint1_linear_23663_103674)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_23663_103674"
        x1="8.89262"
        y1="6"
        x2="8.89262"
        y2="29.8112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC6A0" />
        <stop offset="1" stopColor="#F98437" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_23663_103674"
        x1="26.8606"
        y1="6"
        x2="26.8606"
        y2="29.8112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC6A0" />
        <stop offset="1" stopColor="#F98437" />
      </linearGradient>
    </defs>
  </svg>
);

export const ICQuotePink = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.29997 21.5538C6.5575 23.4212 5.38752 25.2663 3.82376 27.0438C3.32876 27.6063 3.26126 28.4163 3.66625 29.0463C3.98128 29.5413 4.49873 29.8112 5.06123 29.8112C5.21875 29.8112 5.37627 29.8 5.53378 29.7437C8.84121 28.7763 16.57 25.345 16.7838 14.3425C16.8625 10.1013 13.7575 6.4563 9.71875 6.04005C7.48001 5.81504 5.25253 6.54624 3.59875 8.03125C1.94503 9.52753 0.999999 11.665 0.999999 13.8925C0.999998 17.605 3.63253 20.8563 7.29997 21.5538Z"
      fill="url(#paint0_linear_23663_86236)"
    />
    <path
      d="M27.6763 6.04005C25.4488 5.81504 23.2213 6.54624 21.5676 8.03125C19.9138 9.52753 18.9687 11.665 18.9687 13.8925C18.9687 17.605 21.6013 20.8563 25.2688 21.5538C24.5263 23.4212 23.3563 25.2663 21.7925 27.0438C21.2975 27.6063 21.23 28.4162 21.6351 29.0462C21.95 29.5412 22.4676 29.8112 23.0301 29.8112C23.1875 29.8112 23.345 29.8 23.5025 29.7437C26.81 28.7762 34.5388 25.345 34.7525 14.3425L34.7525 14.185C34.7525 10.0113 31.6813 6.4563 27.6763 6.04005Z"
      fill="url(#paint1_linear_23663_86236)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_23663_86236"
        x1="8.89262"
        y1="6"
        x2="8.89262"
        y2="29.8112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFABD7" />
        <stop offset="1" stopColor="#FF0087" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_23663_86236"
        x1="26.8606"
        y1="6"
        x2="26.8606"
        y2="29.8112"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFABD7" />
        <stop offset="1" stopColor="#FF0087" />
      </linearGradient>
    </defs>
  </svg>
);
