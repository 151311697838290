import { Card } from 'antd';
import { ViewAllCardStyles } from 'components/forJobSeekers/CardItems/ViewAllCard/styles';
import styled from 'styled-components';
import { respondTo } from 'utils/variables';

export const ReviewSummaryStyles = styled.div`
  .ant-progress-success-bg,
  .ant-progress-bg {
    background: var(--text-graph-yellow);
    height: 3px !important;
  }
  .ant-progress-text {
    color: var(--text-grey-l1);
  }
`;

export const ListReviewItemStyle = styled.div`
  max-height: ${({ isJobSeeker }) =>
    isJobSeeker ? 'calc(100vh - 50px)' : 'calc(100vh - 120px)'};
  height: min-content;
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: 'contain';
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const ParentReviewStyle = styled.div`
  .ant-rate-star {
    color: var(--text-graph-yellow);
  }
  .ant-rate-star:not(:last-child) {
    margin-right: 4px;
  }
  .ant-progress-inner {
    height: 3px !important;
  }
`;

export const CarouselReviewStyle = styled(Card)`
  min-width: 290px;
  margin-right: 12px;
  &::before {
    content: '';
    background: transparent !important;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .ant-rate-star {
    font-size: 14px !important;
  }
`;

export const ReplyItemStyle = styled(Card)`
  border: none;
`;

export const SlideStyle = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  ${ViewAllCardStyles} {
    ${respondTo.xs`
      width: 118px;
      box-shadow: none;
      &:hover {
      border: 1px solid var(--border-color-light);
      box-shadow: none;
    }
 `}
  }
`;

export const ReviewItemStyle = styled.div`
  .ant-rate {
    font-size: 14px !important;
  }
`;
