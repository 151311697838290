import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const jobArticlesApi = rtkCreator(
  'jobArticles',
  'job-seekers/screens/articles',
  richWebConfigVer2,
);

export const { useGetAllJobArticlesQuery, useGetByIdJobArticlesQuery } =
  jobArticlesApi;

export const { getByIdJobArticles, getAllJobArticles } =
  jobArticlesApi.endpoints;
