import styled from 'styled-components';

export const CentreAddressDistanceStyles = styled.div`
  max-width: 100%;
  &.centre-address-distance__ellipsis {
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    .centre-address-distance__value {
      white-space: nowrap;
    }
    .centre-address-distance__address {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .dot {
    font-size: 6px;
    color: #d0d5dd;
    vertical-align: middle;
    margin: 0 3px;
  }

  @media only screen and (max-width: 576px) {
    .centre-address-distance__value {
      font-size: 14px !important;
    }
    .centre-address-distance__address {
      font-size: 14px !important;
    }
  }
`;
