import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { REQUEST_TYPE } from '@redux/crudCreator/dataProvider';
import { richWebConfig } from 'utils';

export const RESOURCE_CENTRES_FAVORITES = 'centresFavourites';

export const centresFavouritesApi = rtkCreator(
  RESOURCE_CENTRES_FAVORITES,
  'centres/favorite',
  richWebConfig,
  (builder) => ({
    getAllCentresFavouriteComparison: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_ALL,
        customApiResource: 'centres/favorite/comparison',
      }),
    }),
  }),
);

export const {
  useGetAllCentresFavouritesQuery,
  useGetAllCentresFavouriteComparisonQuery,
  util: utilCentresFavourites,
} = centresFavouritesApi;

export const { getAllCentresFavourites, getAllCentresFavouriteComparison } =
  centresFavouritesApi.endpoints;
