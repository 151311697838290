import { baseApi as api } from '../@redux/@rtkQuery/baseApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    adsPostCarouselCentreDetailControllerGetCarouselGreatOfferAdsPostInCentreDetail:
      build.query<
        AdsPostCarouselCentreDetailControllerGetCarouselGreatOfferAdsPostInCentreDetailApiResponse,
        AdsPostCarouselCentreDetailControllerGetCarouselGreatOfferAdsPostInCentreDetailApiArg
      >({
        query: (queryArg) => ({
          url: `/thor/v2/parent/ads/posts/carousel/centre-detail`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            limit: queryArg.limit,
            offset: queryArg.offset,
            location: queryArg.location,
          },
        }),
      }),
    adsPostCarouselHomeControllerGetCarouselGreatOfferAdsPostInHomePage:
      build.query<
        AdsPostCarouselHomeControllerGetCarouselGreatOfferAdsPostInHomePageApiResponse,
        AdsPostCarouselHomeControllerGetCarouselGreatOfferAdsPostInHomePageApiArg
      >({
        query: (queryArg) => ({
          url: `/thor/v2/parent/ads/posts/carousel/home`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            limit: queryArg.limit,
            offset: queryArg.offset,
            location: queryArg.location,
          },
        }),
      }),
    adsPostControllerGetAdsPostsListView: build.query<
      AdsPostControllerGetAdsPostsListViewApiResponse,
      AdsPostControllerGetAdsPostsListViewApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/ads/posts`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sessionId: queryArg.sessionId,
        },
      }),
    }),
    randomAdsSideBarControllerGetRandomAdsSideBar: build.query<
      RandomAdsSideBarControllerGetRandomAdsSideBarApiResponse,
      RandomAdsSideBarControllerGetRandomAdsSideBarApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/ads/posts/random`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    applicationShortFormControllerGetApplicationShortFormAttrs: build.query<
      ApplicationShortFormControllerGetApplicationShortFormAttrsApiResponse,
      ApplicationShortFormControllerGetApplicationShortFormAttrsApiArg
    >({
      query: () => ({ url: `/thor/v2/parent/applications/short-form` }),
    }),
    rwCentreFeaturesControllerGetChartData: build.query<
      RwCentreFeaturesControllerGetChartDataApiResponse,
      RwCentreFeaturesControllerGetChartDataApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centre/details/features`,
        params: {
          centreId: queryArg.centreId,
          centreSlug: queryArg.centreSlug,
        },
      }),
    }),
    parentEducatorProfileControllerGetEducatorProfiles: build.query<
      ParentEducatorProfileControllerGetEducatorProfilesApiResponse,
      ParentEducatorProfileControllerGetEducatorProfilesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centre/educator-profiles`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          centreId: queryArg.centreId,
          currentOffset: queryArg.currentOffset,
        },
      }),
    }),
    suburbProfileHighestRateControllerGetHighestRateCentres: build.query<
      SuburbProfileHighestRateControllerGetHighestRateCentresApiResponse,
      SuburbProfileHighestRateControllerGetHighestRateCentresApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centre/highest-rate`,
        params: { suburb: queryArg.suburb },
      }),
    }),
    parentThorProgramControllerGetParentProgram: build.query<
      ParentThorProgramControllerGetParentProgramApiResponse,
      ParentThorProgramControllerGetParentProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centre/program`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          centreId: queryArg.centreId,
        },
      }),
    }),
    parentThorProgramControllerGetParentDetailProgram: build.query<
      ParentThorProgramControllerGetParentDetailProgramApiResponse,
      ParentThorProgramControllerGetParentDetailProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centre/program/detail`,
        params: { id: queryArg.id },
      }),
    }),
    kindiCareAvgRatingOfSuburbProfileControllerGetKindiCareAvgRatingOfSuburbProfile:
      build.query<
        KindiCareAvgRatingOfSuburbProfileControllerGetKindiCareAvgRatingOfSuburbProfileApiResponse,
        KindiCareAvgRatingOfSuburbProfileControllerGetKindiCareAvgRatingOfSuburbProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/thor/v2/parent/centre/suburb-profile/average-rating`,
          params: { suburb: queryArg.suburb },
        }),
      }),
    thorSuburbProfileCarouselControllerGetParentCentreProgram: build.query<
      ThorSuburbProfileCarouselControllerGetParentCentreProgramApiResponse,
      ThorSuburbProfileCarouselControllerGetParentCentreProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centre/suburb-profile/carousel`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          location: queryArg.location,
          suburb: queryArg.suburb,
        },
      }),
    }),
    centreServicesSuburbProfileControllerGetCentreServiceSuburbProfile:
      build.query<
        CentreServicesSuburbProfileControllerGetCentreServiceSuburbProfileApiResponse,
        CentreServicesSuburbProfileControllerGetCentreServiceSuburbProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/thor/v2/parent/centre/suburb-profile/centre-services`,
          params: { suburb: queryArg.suburb },
        }),
      }),
    suburbProfileChartControllerGetSuburbProfileDescription: build.query<
      SuburbProfileChartControllerGetSuburbProfileDescriptionApiResponse,
      SuburbProfileChartControllerGetSuburbProfileDescriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centre/suburb-profile/chart`,
        params: { suburb: queryArg.suburb },
      }),
    }),
    suburbProfileDescriptionControllerGetSuburbProfileDescription: build.query<
      SuburbProfileDescriptionControllerGetSuburbProfileDescriptionApiResponse,
      SuburbProfileDescriptionControllerGetSuburbProfileDescriptionApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centre/suburb-profile`,
        params: { suburb: queryArg.suburb },
      }),
    }),
    thorSuburbProfileListControllerGetParentCentreProgram: build.query<
      ThorSuburbProfileListControllerGetParentCentreProgramApiResponse,
      ThorSuburbProfileListControllerGetParentCentreProgramApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centre/suburb-profile/list`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          location: queryArg.location,
          suburb: queryArg.suburb,
        },
      }),
    }),
    parentReviewsOfSuburbProfileControllerGetParentReviewsOfSuburbProfile:
      build.query<
        ParentReviewsOfSuburbProfileControllerGetParentReviewsOfSuburbProfileApiResponse,
        ParentReviewsOfSuburbProfileControllerGetParentReviewsOfSuburbProfileApiArg
      >({
        query: (queryArg) => ({
          url: `/thor/v2/parent/centre/suburb-profile/parent-reviews`,
          params: { suburb: queryArg.suburb },
        }),
      }),
    suburbProfilesSuggestionControllerSuburbProfilesSuggestion: build.query<
      SuburbProfilesSuggestionControllerSuburbProfilesSuggestionApiResponse,
      SuburbProfilesSuggestionControllerSuburbProfilesSuggestionApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centre/suggestion/suburb-profile`,
        params: { text: queryArg.text },
      }),
    }),
    marketingsCentresControllerGetMarketingsCentres: build.query<
      MarketingsCentresControllerGetMarketingsCentresApiResponse,
      MarketingsCentresControllerGetMarketingsCentresApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centres/marketings`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    nearbyCentresControllerGetNearbyCentres: build.query<
      NearbyCentresControllerGetNearbyCentresApiResponse,
      NearbyCentresControllerGetNearbyCentresApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centres/nearby`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    popularNearbyCentresControllerGetPopularNearbyCentres: build.query<
      PopularNearbyCentresControllerGetPopularNearbyCentresApiResponse,
      PopularNearbyCentresControllerGetPopularNearbyCentresApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centres/nearby/popular`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    recommendedServicesCentresControllerGetRecommendedServicesCentres:
      build.query<
        RecommendedServicesCentresControllerGetRecommendedServicesCentresApiResponse,
        RecommendedServicesCentresControllerGetRecommendedServicesCentresApiArg
      >({
        query: (queryArg) => ({
          url: `/thor/v2/parent/centres/nearby/recommended-services`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
      }),
    topPicksCentresControllerGetTopPicksCentres: build.query<
      TopPicksCentresControllerGetTopPicksCentresApiResponse,
      TopPicksCentresControllerGetTopPicksCentresApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centres/top-picks`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    wellKnownCentresControllerGetWellKnownCentres: build.query<
      WellKnownCentresControllerGetWellKnownCentresApiResponse,
      WellKnownCentresControllerGetWellKnownCentresApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centres/well-known`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    getCentresInSuburbControllerGetCentresInSuburb: build.query<
      GetCentresInSuburbControllerGetCentresInSuburbApiResponse,
      GetCentresInSuburbControllerGetCentresInSuburbApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/suburb`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          location: queryArg.location,
          suburb: queryArg.suburb,
        },
      }),
    }),
    parentConfigControllerGetPageTermConditions: build.query<
      ParentConfigControllerGetPageTermConditionsApiResponse,
      ParentConfigControllerGetPageTermConditionsApiArg
    >({
      query: () => ({ url: `/thor/v2/parent/config/page-term-conditions` }),
    }),
    parentConfigControllerGetPagePrivacyPolicy: build.query<
      ParentConfigControllerGetPagePrivacyPolicyApiResponse,
      ParentConfigControllerGetPagePrivacyPolicyApiArg
    >({
      query: () => ({ url: `/thor/v2/parent/config/page-privacy-policy` }),
    }),
    brandControllerFindAll: build.query<
      BrandControllerFindAllApiResponse,
      BrandControllerFindAllApiArg
    >({
      query: () => ({ url: `/thor/v2/parent/brands` }),
    }),
    brandControllerFindOne: build.query<
      BrandControllerFindOneApiResponse,
      BrandControllerFindOneApiArg
    >({
      query: (queryArg) => ({ url: `/thor/v2/parent/brands/${queryArg.id}` }),
    }),
    suggestionControllerSearchSuggestion: build.query<
      SuggestionControllerSearchSuggestionApiResponse,
      SuggestionControllerSearchSuggestionApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centres/suggestion`,
        params: { text: queryArg.text },
      }),
    }),
    ctaButtonControllerGetCtaButtons: build.query<
      CtaButtonControllerGetCtaButtonsApiResponse,
      CtaButtonControllerGetCtaButtonsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centres/details/cta-buttons`,
        params: { centreId: queryArg.centreId },
      }),
    }),
    ratingSummaryControllerGetRatingSummary: build.query<
      RatingSummaryControllerGetRatingSummaryApiResponse,
      RatingSummaryControllerGetRatingSummaryApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centres/details/rating-summary`,
        params: { slug: queryArg.slug },
      }),
    }),
    virtualTourControllerGetCentreVirtualTour: build.query<
      VirtualTourControllerGetCentreVirtualTourApiResponse,
      VirtualTourControllerGetCentreVirtualTourApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/parent/centres/details/virtual-tour`,
        params: { centreId: queryArg.centreId },
      }),
    }),
    seekerControllerGetJobSeekerProfile: build.query<
      SeekerControllerGetJobSeekerProfileApiResponse,
      SeekerControllerGetJobSeekerProfileApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me` }),
    }),
    seekerControllerUpdateJobSeekerProfile: build.mutation<
      SeekerControllerUpdateJobSeekerProfileApiResponse,
      SeekerControllerUpdateJobSeekerProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me`,
        method: 'PUT',
        body: queryArg.updateSeekerProfile,
      }),
    }),
    profileControllerUpdateShowEditProfileTooltip: build.mutation<
      ProfileControllerUpdateShowEditProfileTooltipApiResponse,
      ProfileControllerUpdateShowEditProfileTooltipApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/profile`,
        method: 'PUT',
        body: queryArg.seekerProfileDto,
      }),
    }),
    profileExperienceControllerGetMany: build.query<
      ProfileExperienceControllerGetManyApiResponse,
      ProfileExperienceControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/experiences` }),
    }),
    profileExperienceControllerCreateOne: build.mutation<
      ProfileExperienceControllerCreateOneApiResponse,
      ProfileExperienceControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/experiences`,
        method: 'POST',
        body: queryArg.createSeekerProfileExperience,
      }),
    }),
    profileExperienceControllerUpdateOne: build.mutation<
      ProfileExperienceControllerUpdateOneApiResponse,
      ProfileExperienceControllerUpdateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/experiences`,
        method: 'PUT',
        body: queryArg.updateSeekerProfileExperience,
        params: { id: queryArg.id },
      }),
    }),
    profileExperienceControllerDeleteOne: build.mutation<
      ProfileExperienceControllerDeleteOneApiResponse,
      ProfileExperienceControllerDeleteOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/experiences`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    jobRoleControllerGetMany: build.query<
      JobRoleControllerGetManyApiResponse,
      JobRoleControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/experiences/roles` }),
    }),
    jobTypeControllerGetMany: build.query<
      JobTypeControllerGetManyApiResponse,
      JobTypeControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/experiences/role-types` }),
    }),
    jobExperienceOrganizationControllerGetMany: build.query<
      JobExperienceOrganizationControllerGetManyApiResponse,
      JobExperienceOrganizationControllerGetManyApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/experiences/organizations`,
        params: { q: queryArg.q },
      }),
    }),
    profileCertificationControllerGetMany: build.query<
      ProfileCertificationControllerGetManyApiResponse,
      ProfileCertificationControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/certifications` }),
    }),
    profileCertificationControllerCreateOne: build.mutation<
      ProfileCertificationControllerCreateOneApiResponse,
      ProfileCertificationControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/certifications`,
        method: 'POST',
        body: queryArg.createSeekerProfileCertification,
      }),
    }),
    profileCertificationControllerUpdateOne: build.mutation<
      ProfileCertificationControllerUpdateOneApiResponse,
      ProfileCertificationControllerUpdateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/certifications`,
        method: 'PUT',
        body: queryArg.updateSeekerProfileCertification,
        params: { id: queryArg.id },
      }),
    }),
    profileCertificationControllerDeleteOne: build.mutation<
      ProfileCertificationControllerDeleteOneApiResponse,
      ProfileCertificationControllerDeleteOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/certifications`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    jobCertificationControllerGetMany: build.query<
      JobCertificationControllerGetManyApiResponse,
      JobCertificationControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/certifications/list` }),
    }),
    profileDocumentControllerGetMany: build.query<
      ProfileDocumentControllerGetManyApiResponse,
      ProfileDocumentControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/documents` }),
    }),
    profileDocumentControllerCreateOne: build.mutation<
      ProfileDocumentControllerCreateOneApiResponse,
      ProfileDocumentControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/documents`,
        method: 'POST',
        body: queryArg.createSeekerProfileDocument,
      }),
    }),
    profileImmunisationControllerGetMany: build.query<
      ProfileImmunisationControllerGetManyApiResponse,
      ProfileImmunisationControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/immunisations` }),
    }),
    profileImmunisationControllerCreateOne: build.mutation<
      ProfileImmunisationControllerCreateOneApiResponse,
      ProfileImmunisationControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/immunisations`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    profileImmunisationControllerDeleteOne: build.mutation<
      ProfileImmunisationControllerDeleteOneApiResponse,
      ProfileImmunisationControllerDeleteOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/immunisations`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    jobImmunisationtrollerGetMany: build.query<
      JobImmunisationtrollerGetManyApiResponse,
      JobImmunisationtrollerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/immunisations/list` }),
    }),
    profileSkillControllerGetMany: build.query<
      ProfileSkillControllerGetManyApiResponse,
      ProfileSkillControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/skills` }),
    }),
    profileSkillControllerCreateOne: build.mutation<
      ProfileSkillControllerCreateOneApiResponse,
      ProfileSkillControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/skills`,
        method: 'PUT',
        body: queryArg.body,
      }),
    }),
    profileSkillControllerDeleteOne: build.mutation<
      ProfileSkillControllerDeleteOneApiResponse,
      ProfileSkillControllerDeleteOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/skills`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    jobSkillControllerGetMany: build.query<
      JobSkillControllerGetManyApiResponse,
      JobSkillControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/skills/list` }),
    }),
    jobSkillLevelControllerGetMany: build.query<
      JobSkillLevelControllerGetManyApiResponse,
      JobSkillLevelControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/skills/levels` }),
    }),
    profileQualificationControllerGetMany: build.query<
      ProfileQualificationControllerGetManyApiResponse,
      ProfileQualificationControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/qualifications` }),
    }),
    profileQualificationControllerCreateOne: build.mutation<
      ProfileQualificationControllerCreateOneApiResponse,
      ProfileQualificationControllerCreateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/qualifications`,
        method: 'POST',
        body: queryArg.createSeekerProfileQualification,
      }),
    }),
    profileQualificationControllerUpdateOne: build.mutation<
      ProfileQualificationControllerUpdateOneApiResponse,
      ProfileQualificationControllerUpdateOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/qualifications`,
        method: 'PUT',
        body: queryArg.updateSeekerProfileQualification,
        params: { id: queryArg.id },
      }),
    }),
    profileQualificationControllerDeleteOne: build.mutation<
      ProfileQualificationControllerDeleteOneApiResponse,
      ProfileQualificationControllerDeleteOneApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/qualifications`,
        method: 'DELETE',
        params: { id: queryArg.id },
      }),
    }),
    jobQualificationControllerGetMany: build.query<
      JobQualificationControllerGetManyApiResponse,
      JobQualificationControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/qualifications/list` }),
    }),
    jobWorkEligibilityControllerGetMany: build.query<
      JobWorkEligibilityControllerGetManyApiResponse,
      JobWorkEligibilityControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/work-eligibilities` }),
    }),
    jobLanguageControllerGetMany: build.query<
      JobLanguageControllerGetManyApiResponse,
      JobLanguageControllerGetManyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/me/languages` }),
    }),
    jobSeekerCareerPreferenceControllerUpdateJobSeekerProfile: build.mutation<
      JobSeekerCareerPreferenceControllerUpdateJobSeekerProfileApiResponse,
      JobSeekerCareerPreferenceControllerUpdateJobSeekerProfileApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/me/career-preferences`,
        method: 'PUT',
        body: queryArg.updateCareerPreferences,
      }),
    }),
    ignoredPostsControllerCreateOneIgnoredPost: build.mutation<
      IgnoredPostsControllerCreateOneIgnoredPostApiResponse,
      IgnoredPostsControllerCreateOneIgnoredPostApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/posts/ignored`,
        method: 'POST',
        body: queryArg.createIgnoredPostDto,
      }),
    }),
    ignoredPostsControllerGetIgnoredJobsOfSeeker: build.query<
      IgnoredPostsControllerGetIgnoredJobsOfSeekerApiResponse,
      IgnoredPostsControllerGetIgnoredJobsOfSeekerApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/posts/ignored`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    searchSuggestionJobPostsControllerGetSuggestionPosts: build.query<
      SearchSuggestionJobPostsControllerGetSuggestionPostsApiResponse,
      SearchSuggestionJobPostsControllerGetSuggestionPostsApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/posts/search-suggestion` }),
    }),
    searchSuggestionJobPostsControllerDeleteRecentSearch: build.mutation<
      SearchSuggestionJobPostsControllerDeleteRecentSearchApiResponse,
      SearchSuggestionJobPostsControllerDeleteRecentSearchApiArg
    >({
      query: () => ({
        url: `/thor/v2/job-seekers/posts/search-suggestion`,
        method: 'DELETE',
      }),
    }),
    recentViewJobPostsControllerAddUserRecentView: build.mutation<
      RecentViewJobPostsControllerAddUserRecentViewApiResponse,
      RecentViewJobPostsControllerAddUserRecentViewApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/posts/viewed`,
        method: 'PUT',
        body: queryArg.userViewedJobPostDto,
        params: { postId: queryArg.postId },
      }),
    }),
    favoriteControllerAddJobFavorite: build.mutation<
      FavoriteControllerAddJobFavoriteApiResponse,
      FavoriteControllerAddJobFavoriteApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/posts/favorites`,
        method: 'POST',
        body: queryArg.jobPostFavoriteDto,
      }),
    }),
    favoriteControllerRemoveJobFavorite: build.mutation<
      FavoriteControllerRemoveJobFavoriteApiResponse,
      FavoriteControllerRemoveJobFavoriteApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/posts/favorites`,
        method: 'DELETE',
        params: { postId: queryArg.postId },
      }),
    }),
    jobMatchesControllerGetJobMatches: build.query<
      JobMatchesControllerGetJobMatchesApiResponse,
      JobMatchesControllerGetJobMatchesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/posts/matches`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    keySearchJobPostsControllerKeySearch: build.query<
      KeySearchJobPostsControllerKeySearchApiResponse,
      KeySearchJobPostsControllerKeySearchApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/posts/key-search` }),
    }),
    comparisonJobPostControllerGetComparisonJobPosts: build.query<
      ComparisonJobPostControllerGetComparisonJobPostsApiResponse,
      ComparisonJobPostControllerGetComparisonJobPostsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/posts/comparison`,
        params: {
          isCompareAllFavorite: queryArg.isCompareAllFavorite,
          isCompareAllApplied: queryArg.isCompareAllApplied,
        },
      }),
    }),
    jobRequirementsControllerGetJobPostNotMatchRequirements: build.query<
      JobRequirementsControllerGetJobPostNotMatchRequirementsApiResponse,
      JobRequirementsControllerGetJobPostNotMatchRequirementsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/posts/requirements`,
        params: { postId: queryArg.postId },
      }),
    }),
    workplaceControllerGetWorkplaceInfo: build.query<
      WorkplaceControllerGetWorkplaceInfoApiResponse,
      WorkplaceControllerGetWorkplaceInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/posts/details/workplace`,
        params: { postId: queryArg.postId, location: queryArg.location },
      }),
    }),
    applicationsControllerCreateOneApplication: build.mutation<
      ApplicationsControllerCreateOneApplicationApiResponse,
      ApplicationsControllerCreateOneApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/applications`,
        method: 'POST',
        body: queryArg.applyPostDto,
        params: {
          isShowingPopup: queryArg.isShowingPopup,
          isSwiping: queryArg.isSwiping,
        },
      }),
    }),
    applicationJobCardControllerGetApplicationCardInfo: build.query<
      ApplicationJobCardControllerGetApplicationCardInfoApiResponse,
      ApplicationJobCardControllerGetApplicationCardInfoApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/applications/card-info`,
        params: {
          applicationId: queryArg.applicationId,
          location: queryArg.location,
        },
      }),
    }),
    cancelApplicationControllerCancelApplication: build.mutation<
      CancelApplicationControllerCancelApplicationApiResponse,
      CancelApplicationControllerCancelApplicationApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/applications/cancel`,
        method: 'DELETE',
        params: { applicationId: queryArg.applicationId },
      }),
    }),
    homepageControllerJobHomepage: build.query<
      HomepageControllerJobHomepageApiResponse,
      HomepageControllerJobHomepageApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/homepages`,
        params: { longitude: queryArg.longitude, latitude: queryArg.latitude },
      }),
    }),
    organizationJobPostsControllerGetOrganizationsPosts: build.query<
      OrganizationJobPostsControllerGetOrganizationsPostsApiResponse,
      OrganizationJobPostsControllerGetOrganizationsPostsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/posts/organizations`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          isSearching: queryArg.isSearching,
          recordSearchAction: queryArg.recordSearchAction,
          canAddToCompare: queryArg.canAddToCompare,
          showAddToCompare: queryArg.showAddToCompare,
        },
      }),
    }),
    jobPostControllerGetJobPosts: build.query<
      JobPostControllerGetJobPostsApiResponse,
      JobPostControllerGetJobPostsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/posts`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          isSearching: queryArg.isSearching,
          recordSearchAction: queryArg.recordSearchAction,
          canAddToCompare: queryArg.canAddToCompare,
          showAddToCompare: queryArg.showAddToCompare,
        },
      }),
    }),
    nearbyJobPostsControllerGetNearbyJobPosts: build.query<
      NearbyJobPostsControllerGetNearbyJobPostsApiResponse,
      NearbyJobPostsControllerGetNearbyJobPostsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/posts/nearby`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          isSearching: queryArg.isSearching,
          recordSearchAction: queryArg.recordSearchAction,
          canAddToCompare: queryArg.canAddToCompare,
          showAddToCompare: queryArg.showAddToCompare,
        },
      }),
    }),
    newestJobPostsControllerGetNewestPosts: build.query<
      NewestJobPostsControllerGetNewestPostsApiResponse,
      NewestJobPostsControllerGetNewestPostsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/posts/newest`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          isSearching: queryArg.isSearching,
          recordSearchAction: queryArg.recordSearchAction,
          canAddToCompare: queryArg.canAddToCompare,
          showAddToCompare: queryArg.showAddToCompare,
        },
      }),
    }),
    featuredBrandJobPostsControllerGetJobPosts: build.query<
      FeaturedBrandJobPostsControllerGetJobPostsApiResponse,
      FeaturedBrandJobPostsControllerGetJobPostsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/posts/featured-brands`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          isSearching: queryArg.isSearching,
          recordSearchAction: queryArg.recordSearchAction,
          canAddToCompare: queryArg.canAddToCompare,
          showAddToCompare: queryArg.showAddToCompare,
        },
      }),
    }),
    featuredCompaniesJobPostsControllerGetJobPosts: build.query<
      FeaturedCompaniesJobPostsControllerGetJobPostsApiResponse,
      FeaturedCompaniesJobPostsControllerGetJobPostsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/posts/featured-companies`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          isSearching: queryArg.isSearching,
          recordSearchAction: queryArg.recordSearchAction,
          canAddToCompare: queryArg.canAddToCompare,
          showAddToCompare: queryArg.showAddToCompare,
        },
      }),
    }),
    featuredJobPostsControllerGetFeaturedPosts: build.query<
      FeaturedJobPostsControllerGetFeaturedPostsApiResponse,
      FeaturedJobPostsControllerGetFeaturedPostsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/posts/featured`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          isSearching: queryArg.isSearching,
          recordSearchAction: queryArg.recordSearchAction,
          canAddToCompare: queryArg.canAddToCompare,
          showAddToCompare: queryArg.showAddToCompare,
        },
      }),
    }),
    postFilterConfigControllerGetJobPostFilterConfig: build.query<
      PostFilterConfigControllerGetJobPostFilterConfigApiResponse,
      PostFilterConfigControllerGetJobPostFilterConfigApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/posts/filter-config`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          isSearching: queryArg.isSearching,
          recordSearchAction: queryArg.recordSearchAction,
          canAddToCompare: queryArg.canAddToCompare,
          showAddToCompare: queryArg.showAddToCompare,
        },
      }),
    }),
    suggestionJobPostsControllerGetSuggestionJobPosts: build.query<
      SuggestionJobPostsControllerGetSuggestionJobPostsApiResponse,
      SuggestionJobPostsControllerGetSuggestionJobPostsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/posts/suggestion`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sorts: queryArg.sorts,
          isSearching: queryArg.isSearching,
          recordSearchAction: queryArg.recordSearchAction,
          canAddToCompare: queryArg.canAddToCompare,
          showAddToCompare: queryArg.showAddToCompare,
        },
      }),
    }),
    adsPostsControllerGetJobPosts: build.query<
      AdsPostsControllerGetJobPostsApiResponse,
      AdsPostsControllerGetJobPostsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/posts/ads`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          sessionId: queryArg.sessionId,
        },
      }),
    }),
    adsPostsControllerGetRandomAdsSideBar: build.query<
      AdsPostsControllerGetRandomAdsSideBarApiResponse,
      AdsPostsControllerGetRandomAdsSideBarApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/posts/ads/random`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    appliedControllerGetAppliedJobs: build.query<
      AppliedControllerGetAppliedJobsApiResponse,
      AppliedControllerGetAppliedJobsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/my-jobs/tabs/applied`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          applicationStatus: queryArg.applicationStatus,
          sorts: queryArg.sorts,
          excludedPosts: queryArg.excludedPosts,
          isComparable: queryArg.isComparable,
          excludeDeletedJobForComparison:
            queryArg.excludeDeletedJobForComparison,
        },
      }),
    }),
    viewedControllerGetViewedJobs: build.query<
      ViewedControllerGetViewedJobsApiResponse,
      ViewedControllerGetViewedJobsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/my-jobs/tabs/viewed`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          applicationStatus: queryArg.applicationStatus,
          sorts: queryArg.sorts,
          excludedPosts: queryArg.excludedPosts,
          isComparable: queryArg.isComparable,
          excludeDeletedJobForComparison:
            queryArg.excludeDeletedJobForComparison,
        },
      }),
    }),
    favoritesControllerGetFavoritesJobs: build.query<
      FavoritesControllerGetFavoritesJobsApiResponse,
      FavoritesControllerGetFavoritesJobsApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/my-jobs/tabs/favorites`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          applicationStatus: queryArg.applicationStatus,
          sorts: queryArg.sorts,
          excludedPosts: queryArg.excludedPosts,
          isComparable: queryArg.isComparable,
          excludeDeletedJobForComparison:
            queryArg.excludeDeletedJobForComparison,
        },
      }),
    }),
    organizationControllerGetOrganizationDiscount: build.query<
      OrganizationControllerGetOrganizationDiscountApiResponse,
      OrganizationControllerGetOrganizationDiscountApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/organizations/discount`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    articleControllerGetArticleListView: build.query<
      ArticleControllerGetArticleListViewApiResponse,
      ArticleControllerGetArticleListViewApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/articles`,
        params: { categories: queryArg.categories },
      }),
    }),
    articleControllerGetArticleBySlug: build.query<
      ArticleControllerGetArticleBySlugApiResponse,
      ArticleControllerGetArticleBySlugApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/articles/details`,
        params: { slug: queryArg.slug },
      }),
    }),
    articleRelativeControllerGetArticleListViewRelated: build.query<
      ArticleRelativeControllerGetArticleListViewRelatedApiResponse,
      ArticleRelativeControllerGetArticleListViewRelatedApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/screens/articles/relative`,
        params: { categories: queryArg.categories },
      }),
    }),
    jobChatMessageControllerGetOwnedChatMessages: build.query<
      JobChatMessageControllerGetOwnedChatMessagesApiResponse,
      JobChatMessageControllerGetOwnedChatMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/chat-messages`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
          chatBoxId: queryArg.chatBoxId,
        },
      }),
    }),
    jobChatMessageControllerSendMessages: build.mutation<
      JobChatMessageControllerSendMessagesApiResponse,
      JobChatMessageControllerSendMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/chat-messages`,
        method: 'POST',
        body: queryArg.sendMessageDto,
      }),
    }),
    s3PreSignedUrlControllerGetOwnedChatMessages: build.mutation<
      S3PreSignedUrlControllerGetOwnedChatMessagesApiResponse,
      S3PreSignedUrlControllerGetOwnedChatMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/chat-messages/s3-presigned-url`,
        method: 'POST',
        body: queryArg.getS3PreSignedUrlDto,
      }),
    }),
    jobChatboxControllerGetOwnedChatboxes: build.query<
      JobChatboxControllerGetOwnedChatboxesApiResponse,
      JobChatboxControllerGetOwnedChatboxesApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/chatboxes`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          limit: queryArg.limit,
          offset: queryArg.offset,
        },
      }),
    }),
    jobChatboxControllerSoftDeleteOwnedChatbox: build.mutation<
      JobChatboxControllerSoftDeleteOwnedChatboxApiResponse,
      JobChatboxControllerSoftDeleteOwnedChatboxApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/chatboxes`,
        method: 'DELETE',
        params: { chatBoxId: queryArg.chatBoxId },
      }),
    }),
    jobFieldsOfStudyControllerGetFieldsOfStudy: build.query<
      JobFieldsOfStudyControllerGetFieldsOfStudyApiResponse,
      JobFieldsOfStudyControllerGetFieldsOfStudyApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/fields-of-study` }),
    }),
    featuredBrandLogosControllerFeaturedBrandLogos: build.query<
      FeaturedBrandLogosControllerFeaturedBrandLogosApiResponse,
      FeaturedBrandLogosControllerFeaturedBrandLogosApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/brands/featured-logos` }),
    }),
    brandSeoControllerGetBrands: build.query<
      BrandSeoControllerGetBrandsApiResponse,
      BrandSeoControllerGetBrandsApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/seo/brands` }),
    }),
    centreDetailSeoControllerGetCentreDetail: build.query<
      CentreDetailSeoControllerGetCentreDetailApiResponse,
      CentreDetailSeoControllerGetCentreDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/seo/centre-detail`,
        params: { organizationId: queryArg.organizationId },
      }),
    }),
    companySeoControllerGetCompanies: build.query<
      CompanySeoControllerGetCompaniesApiResponse,
      CompanySeoControllerGetCompaniesApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/seo/companies` }),
    }),
    companyDetailSeoControllerGetCompanyDetail: build.query<
      CompanyDetailSeoControllerGetCompanyDetailApiResponse,
      CompanyDetailSeoControllerGetCompanyDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/seo/company-detail`,
        params: { id: queryArg.id },
      }),
    }),
    jobRoleSeoControllerGetJobRoles: build.query<
      JobRoleSeoControllerGetJobRolesApiResponse,
      JobRoleSeoControllerGetJobRolesApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/seo/job-roles` }),
    }),
    postCodeSeoControllerGetPostCodes: build.query<
      PostCodeSeoControllerGetPostCodesApiResponse,
      PostCodeSeoControllerGetPostCodesApiArg
    >({
      query: () => ({ url: `/thor/v2/job-seekers/seo/postcode` }),
    }),
    roleDetailSeoControllerGetRoleDetail: build.query<
      RoleDetailSeoControllerGetRoleDetailApiResponse,
      RoleDetailSeoControllerGetRoleDetailApiArg
    >({
      query: (queryArg) => ({
        url: `/thor/v2/job-seekers/seo/role-detail`,
        params: { id: queryArg.id },
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AdsPostCarouselCentreDetailControllerGetCarouselGreatOfferAdsPostInCentreDetailApiResponse =
  unknown;
export type AdsPostCarouselCentreDetailControllerGetCarouselGreatOfferAdsPostInCentreDetailApiArg =
  {
    page?: number;
    size?: number;
    limit?: number;
    offset?: number;
    location?: {
      longitude?: number;
      latitude?: number;
    };
  };
export type AdsPostCarouselHomeControllerGetCarouselGreatOfferAdsPostInHomePageApiResponse =
  unknown;
export type AdsPostCarouselHomeControllerGetCarouselGreatOfferAdsPostInHomePageApiArg =
  {
    page?: number;
    size?: number;
    limit?: number;
    offset?: number;
    location?: {
      longitude?: number;
      latitude?: number;
    };
  };
export type AdsPostControllerGetAdsPostsListViewApiResponse = unknown;
export type AdsPostControllerGetAdsPostsListViewApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sessionId?: string;
};
export type RandomAdsSideBarControllerGetRandomAdsSideBarApiResponse = unknown;
export type RandomAdsSideBarControllerGetRandomAdsSideBarApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type ApplicationShortFormControllerGetApplicationShortFormAttrsApiResponse =
  /** status 200  */ ApplicationShortFormResponse;
export type ApplicationShortFormControllerGetApplicationShortFormAttrsApiArg =
  void;
export type RwCentreFeaturesControllerGetChartDataApiResponse = unknown;
export type RwCentreFeaturesControllerGetChartDataApiArg = {
  centreId: string;
  centreSlug: string;
};
export type ParentEducatorProfileControllerGetEducatorProfilesApiResponse =
  unknown;
export type ParentEducatorProfileControllerGetEducatorProfilesApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  centreId?: string;
  currentOffset?: number;
};
export type SuburbProfileHighestRateControllerGetHighestRateCentresApiResponse =
  /** status 200  */ SuburbProfileHighestRateResp;
export type SuburbProfileHighestRateControllerGetHighestRateCentresApiArg = {
  suburb: string;
};
export type ParentThorProgramControllerGetParentProgramApiResponse =
  /** status 201  */ IParentProgramResponse;
export type ParentThorProgramControllerGetParentProgramApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  centreId?: string;
};
export type ParentThorProgramControllerGetParentDetailProgramApiResponse =
  /** status 201  */ ResultWrapperProgram;
export type ParentThorProgramControllerGetParentDetailProgramApiArg = {
  id: string;
};
export type KindiCareAvgRatingOfSuburbProfileControllerGetKindiCareAvgRatingOfSuburbProfileApiResponse =
  /** status 200  */ SuburbProfileKcRatingResp;
export type KindiCareAvgRatingOfSuburbProfileControllerGetKindiCareAvgRatingOfSuburbProfileApiArg =
  {
    suburb: string;
  };
export type ThorSuburbProfileCarouselControllerGetParentCentreProgramApiResponse =
  /** status 200  */ NearbySuburbCarouselResp;
export type ThorSuburbProfileCarouselControllerGetParentCentreProgramApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  location?: {
    longitude?: number;
    latitude?: number;
  };
  suburb?: string;
};
export type CentreServicesSuburbProfileControllerGetCentreServiceSuburbProfileApiResponse =
  /** status 200  */ CentresByCareTypeResp;
export type CentreServicesSuburbProfileControllerGetCentreServiceSuburbProfileApiArg =
  {
    suburb: string;
  };
export type SuburbProfileChartControllerGetSuburbProfileDescriptionApiResponse =
  /** status 200  */ ResultWrapperISuburbProfileNqsChart;
export type SuburbProfileChartControllerGetSuburbProfileDescriptionApiArg = {
  suburb: string;
};
export type SuburbProfileDescriptionControllerGetSuburbProfileDescriptionApiResponse =
  /** status 200  */ SuburbProfileDetailResp;
export type SuburbProfileDescriptionControllerGetSuburbProfileDescriptionApiArg =
  {
    suburb: string;
  };
export type ThorSuburbProfileListControllerGetParentCentreProgramApiResponse =
  /** status 200  */ NearbySuburbListResp;
export type ThorSuburbProfileListControllerGetParentCentreProgramApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  location?: {
    longitude?: number;
    latitude?: number;
  };
  suburb?: string;
};
export type ParentReviewsOfSuburbProfileControllerGetParentReviewsOfSuburbProfileApiResponse =
  /** status 200  */ SuburbProfileParentReviewsResp;
export type ParentReviewsOfSuburbProfileControllerGetParentReviewsOfSuburbProfileApiArg =
  {
    suburb: string;
  };
export type SuburbProfilesSuggestionControllerSuburbProfilesSuggestionApiResponse =
  /** status 200  */ SuburbProfileSuggestionResp;
export type SuburbProfilesSuggestionControllerSuburbProfilesSuggestionApiArg = {
  text: string;
};
export type MarketingsCentresControllerGetMarketingsCentresApiResponse =
  unknown;
export type MarketingsCentresControllerGetMarketingsCentresApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type NearbyCentresControllerGetNearbyCentresApiResponse = unknown;
export type NearbyCentresControllerGetNearbyCentresApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type PopularNearbyCentresControllerGetPopularNearbyCentresApiResponse =
  unknown;
export type PopularNearbyCentresControllerGetPopularNearbyCentresApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type RecommendedServicesCentresControllerGetRecommendedServicesCentresApiResponse =
  unknown;
export type RecommendedServicesCentresControllerGetRecommendedServicesCentresApiArg =
  {
    page?: number;
    size?: number;
    limit?: number;
    offset?: number;
  };
export type TopPicksCentresControllerGetTopPicksCentresApiResponse = unknown;
export type TopPicksCentresControllerGetTopPicksCentresApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type WellKnownCentresControllerGetWellKnownCentresApiResponse = unknown;
export type WellKnownCentresControllerGetWellKnownCentresApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type GetCentresInSuburbControllerGetCentresInSuburbApiResponse =
  /** status 200  */ CentresInSuburbResp;
export type GetCentresInSuburbControllerGetCentresInSuburbApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  location?: {
    longitude?: number;
    latitude?: number;
  };
  suburb: string;
};
export type ParentConfigControllerGetPageTermConditionsApiResponse = unknown;
export type ParentConfigControllerGetPageTermConditionsApiArg = void;
export type ParentConfigControllerGetPagePrivacyPolicyApiResponse = unknown;
export type ParentConfigControllerGetPagePrivacyPolicyApiArg = void;
export type BrandControllerFindAllApiResponse = unknown;
export type BrandControllerFindAllApiArg = void;
export type BrandControllerFindOneApiResponse = unknown;
export type BrandControllerFindOneApiArg = {
  id: string;
};
export type SuggestionControllerSearchSuggestionApiResponse = unknown;
export type SuggestionControllerSearchSuggestionApiArg = {
  text: string;
};
export type CtaButtonControllerGetCtaButtonsApiResponse = unknown;
export type CtaButtonControllerGetCtaButtonsApiArg = {
  centreId: string;
};
export type RatingSummaryControllerGetRatingSummaryApiResponse = unknown;
export type RatingSummaryControllerGetRatingSummaryApiArg = {
  slug: string;
};
export type VirtualTourControllerGetCentreVirtualTourApiResponse = unknown;
export type VirtualTourControllerGetCentreVirtualTourApiArg = {
  centreId: string;
};
export type SeekerControllerGetJobSeekerProfileApiResponse = unknown;
export type SeekerControllerGetJobSeekerProfileApiArg = void;
export type SeekerControllerUpdateJobSeekerProfileApiResponse = unknown;
export type SeekerControllerUpdateJobSeekerProfileApiArg = {
  updateSeekerProfile: UpdateSeekerProfile;
};
export type ProfileControllerUpdateShowEditProfileTooltipApiResponse = unknown;
export type ProfileControllerUpdateShowEditProfileTooltipApiArg = {
  seekerProfileDto: SeekerProfileDto;
};
export type ProfileExperienceControllerGetManyApiResponse = unknown;
export type ProfileExperienceControllerGetManyApiArg = void;
export type ProfileExperienceControllerCreateOneApiResponse = unknown;
export type ProfileExperienceControllerCreateOneApiArg = {
  createSeekerProfileExperience: CreateSeekerProfileExperience;
};
export type ProfileExperienceControllerUpdateOneApiResponse = unknown;
export type ProfileExperienceControllerUpdateOneApiArg = {
  id: string;
  updateSeekerProfileExperience: UpdateSeekerProfileExperience;
};
export type ProfileExperienceControllerDeleteOneApiResponse = unknown;
export type ProfileExperienceControllerDeleteOneApiArg = {
  id: string;
};
export type JobRoleControllerGetManyApiResponse = unknown;
export type JobRoleControllerGetManyApiArg = void;
export type JobTypeControllerGetManyApiResponse = unknown;
export type JobTypeControllerGetManyApiArg = void;
export type JobExperienceOrganizationControllerGetManyApiResponse = unknown;
export type JobExperienceOrganizationControllerGetManyApiArg = {
  q: string;
};
export type ProfileCertificationControllerGetManyApiResponse = unknown;
export type ProfileCertificationControllerGetManyApiArg = void;
export type ProfileCertificationControllerCreateOneApiResponse = unknown;
export type ProfileCertificationControllerCreateOneApiArg = {
  createSeekerProfileCertification: CreateSeekerProfileCertification;
};
export type ProfileCertificationControllerUpdateOneApiResponse = unknown;
export type ProfileCertificationControllerUpdateOneApiArg = {
  id: string;
  updateSeekerProfileCertification: UpdateSeekerProfileCertification;
};
export type ProfileCertificationControllerDeleteOneApiResponse = unknown;
export type ProfileCertificationControllerDeleteOneApiArg = {
  id: string;
};
export type JobCertificationControllerGetManyApiResponse = unknown;
export type JobCertificationControllerGetManyApiArg = void;
export type ProfileDocumentControllerGetManyApiResponse = unknown;
export type ProfileDocumentControllerGetManyApiArg = void;
export type ProfileDocumentControllerCreateOneApiResponse = unknown;
export type ProfileDocumentControllerCreateOneApiArg = {
  createSeekerProfileDocument: CreateSeekerProfileDocument;
};
export type ProfileImmunisationControllerGetManyApiResponse = unknown;
export type ProfileImmunisationControllerGetManyApiArg = void;
export type ProfileImmunisationControllerCreateOneApiResponse = unknown;
export type ProfileImmunisationControllerCreateOneApiArg = {
  body: string[];
};
export type ProfileImmunisationControllerDeleteOneApiResponse = unknown;
export type ProfileImmunisationControllerDeleteOneApiArg = {
  id: string;
};
export type JobImmunisationtrollerGetManyApiResponse = unknown;
export type JobImmunisationtrollerGetManyApiArg = void;
export type ProfileSkillControllerGetManyApiResponse = unknown;
export type ProfileSkillControllerGetManyApiArg = void;
export type ProfileSkillControllerCreateOneApiResponse = unknown;
export type ProfileSkillControllerCreateOneApiArg = {
  body: string[];
};
export type ProfileSkillControllerDeleteOneApiResponse = unknown;
export type ProfileSkillControllerDeleteOneApiArg = {
  id: string;
};
export type JobSkillControllerGetManyApiResponse = unknown;
export type JobSkillControllerGetManyApiArg = void;
export type JobSkillLevelControllerGetManyApiResponse = unknown;
export type JobSkillLevelControllerGetManyApiArg = void;
export type ProfileQualificationControllerGetManyApiResponse = unknown;
export type ProfileQualificationControllerGetManyApiArg = void;
export type ProfileQualificationControllerCreateOneApiResponse = unknown;
export type ProfileQualificationControllerCreateOneApiArg = {
  createSeekerProfileQualification: CreateSeekerProfileQualification;
};
export type ProfileQualificationControllerUpdateOneApiResponse = unknown;
export type ProfileQualificationControllerUpdateOneApiArg = {
  id: string;
  updateSeekerProfileQualification: UpdateSeekerProfileQualification;
};
export type ProfileQualificationControllerDeleteOneApiResponse = unknown;
export type ProfileQualificationControllerDeleteOneApiArg = {
  id: string;
};
export type JobQualificationControllerGetManyApiResponse = unknown;
export type JobQualificationControllerGetManyApiArg = void;
export type JobWorkEligibilityControllerGetManyApiResponse = unknown;
export type JobWorkEligibilityControllerGetManyApiArg = void;
export type JobLanguageControllerGetManyApiResponse = unknown;
export type JobLanguageControllerGetManyApiArg = void;
export type JobSeekerCareerPreferenceControllerUpdateJobSeekerProfileApiResponse =
  unknown;
export type JobSeekerCareerPreferenceControllerUpdateJobSeekerProfileApiArg = {
  updateCareerPreferences: UpdateCareerPreferences;
};
export type IgnoredPostsControllerCreateOneIgnoredPostApiResponse = unknown;
export type IgnoredPostsControllerCreateOneIgnoredPostApiArg = {
  createIgnoredPostDto: CreateIgnoredPostDto;
};
export type IgnoredPostsControllerGetIgnoredJobsOfSeekerApiResponse = unknown;
export type IgnoredPostsControllerGetIgnoredJobsOfSeekerApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type SearchSuggestionJobPostsControllerGetSuggestionPostsApiResponse =
  unknown;
export type SearchSuggestionJobPostsControllerGetSuggestionPostsApiArg = void;
export type SearchSuggestionJobPostsControllerDeleteRecentSearchApiResponse =
  unknown;
export type SearchSuggestionJobPostsControllerDeleteRecentSearchApiArg = void;
export type RecentViewJobPostsControllerAddUserRecentViewApiResponse = unknown;
export type RecentViewJobPostsControllerAddUserRecentViewApiArg = {
  postId: string;
  userViewedJobPostDto: UserViewedJobPostDto;
};
export type FavoriteControllerAddJobFavoriteApiResponse = unknown;
export type FavoriteControllerAddJobFavoriteApiArg = {
  jobPostFavoriteDto: JobPostFavoriteDto;
};
export type FavoriteControllerRemoveJobFavoriteApiResponse = unknown;
export type FavoriteControllerRemoveJobFavoriteApiArg = {
  postId: string;
};
export type JobMatchesControllerGetJobMatchesApiResponse = unknown;
export type JobMatchesControllerGetJobMatchesApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type KeySearchJobPostsControllerKeySearchApiResponse = unknown;
export type KeySearchJobPostsControllerKeySearchApiArg = void;
export type ComparisonJobPostControllerGetComparisonJobPostsApiResponse =
  /** status 200 Posts comparison result */ IComparisonJobPostResp;
export type ComparisonJobPostControllerGetComparisonJobPostsApiArg = {
  isCompareAllFavorite: boolean;
  isCompareAllApplied: boolean;
};
export type JobRequirementsControllerGetJobPostNotMatchRequirementsApiResponse =
  /** status 200 Job Requirements result */ JobRequirements;
export type JobRequirementsControllerGetJobPostNotMatchRequirementsApiArg = {
  postId: string;
};
export type WorkplaceControllerGetWorkplaceInfoApiResponse = unknown;
export type WorkplaceControllerGetWorkplaceInfoApiArg = {
  postId: string;
  location?: {
    longitude?: number;
    latitude?: number;
  };
};
export type ApplicationsControllerCreateOneApplicationApiResponse = unknown;
export type ApplicationsControllerCreateOneApplicationApiArg = {
  isShowingPopup?: boolean;
  isSwiping?: boolean;
  applyPostDto: ApplyPostDto;
};
export type ApplicationJobCardControllerGetApplicationCardInfoApiResponse =
  unknown;
export type ApplicationJobCardControllerGetApplicationCardInfoApiArg = {
  applicationId: string;
  location?: {
    longitude?: number;
    latitude?: number;
  };
};
export type CancelApplicationControllerCancelApplicationApiResponse = unknown;
export type CancelApplicationControllerCancelApplicationApiArg = {
  applicationId: string;
};
export type HomepageControllerJobHomepageApiResponse = unknown;
export type HomepageControllerJobHomepageApiArg = {
  longitude: number;
  latitude: number;
};
export type OrganizationJobPostsControllerGetOrganizationsPostsApiResponse =
  unknown;
export type OrganizationJobPostsControllerGetOrganizationsPostsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  isSearching?: boolean;
  recordSearchAction?: string;
  canAddToCompare?: boolean;
  showAddToCompare?: boolean;
};
export type JobPostControllerGetJobPostsApiResponse = unknown;
export type JobPostControllerGetJobPostsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  isSearching?: boolean;
  recordSearchAction?: string;
  canAddToCompare?: boolean;
  showAddToCompare?: boolean;
};
export type NearbyJobPostsControllerGetNearbyJobPostsApiResponse = unknown;
export type NearbyJobPostsControllerGetNearbyJobPostsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  isSearching?: boolean;
  recordSearchAction?: string;
  canAddToCompare?: boolean;
  showAddToCompare?: boolean;
};
export type NewestJobPostsControllerGetNewestPostsApiResponse = unknown;
export type NewestJobPostsControllerGetNewestPostsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  isSearching?: boolean;
  recordSearchAction?: string;
  canAddToCompare?: boolean;
  showAddToCompare?: boolean;
};
export type FeaturedBrandJobPostsControllerGetJobPostsApiResponse = unknown;
export type FeaturedBrandJobPostsControllerGetJobPostsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  isSearching?: boolean;
  recordSearchAction?: string;
  canAddToCompare?: boolean;
  showAddToCompare?: boolean;
};
export type FeaturedCompaniesJobPostsControllerGetJobPostsApiResponse = unknown;
export type FeaturedCompaniesJobPostsControllerGetJobPostsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  isSearching?: boolean;
  recordSearchAction?: string;
  canAddToCompare?: boolean;
  showAddToCompare?: boolean;
};
export type FeaturedJobPostsControllerGetFeaturedPostsApiResponse = unknown;
export type FeaturedJobPostsControllerGetFeaturedPostsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  isSearching?: boolean;
  recordSearchAction?: string;
  canAddToCompare?: boolean;
  showAddToCompare?: boolean;
};
export type PostFilterConfigControllerGetJobPostFilterConfigApiResponse =
  unknown;
export type PostFilterConfigControllerGetJobPostFilterConfigApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  isSearching?: boolean;
  recordSearchAction?: string;
  canAddToCompare?: boolean;
  showAddToCompare?: boolean;
};
export type SuggestionJobPostsControllerGetSuggestionJobPostsApiResponse =
  unknown;
export type SuggestionJobPostsControllerGetSuggestionJobPostsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sorts: string[];
  isSearching?: boolean;
  recordSearchAction?: string;
  canAddToCompare?: boolean;
  showAddToCompare?: boolean;
};
export type AdsPostsControllerGetJobPostsApiResponse = unknown;
export type AdsPostsControllerGetJobPostsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  sessionId?: string;
};
export type AdsPostsControllerGetRandomAdsSideBarApiResponse = unknown;
export type AdsPostsControllerGetRandomAdsSideBarApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type AppliedControllerGetAppliedJobsApiResponse = unknown;
export type AppliedControllerGetAppliedJobsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  applicationStatus?: string;
  sorts: string[];
  excludedPosts?: any;
  isComparable?: boolean;
  excludeDeletedJobForComparison?: boolean;
};
export type ViewedControllerGetViewedJobsApiResponse = unknown;
export type ViewedControllerGetViewedJobsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  applicationStatus?: string;
  sorts: string[];
  excludedPosts?: any;
  isComparable?: boolean;
  excludeDeletedJobForComparison?: boolean;
};
export type FavoritesControllerGetFavoritesJobsApiResponse = unknown;
export type FavoritesControllerGetFavoritesJobsApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  applicationStatus?: string;
  sorts: string[];
  excludedPosts?: any;
  isComparable?: boolean;
  excludeDeletedJobForComparison?: boolean;
};
export type OrganizationControllerGetOrganizationDiscountApiResponse = unknown;
export type OrganizationControllerGetOrganizationDiscountApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type ArticleControllerGetArticleListViewApiResponse = unknown;
export type ArticleControllerGetArticleListViewApiArg = {
  categories: string[];
};
export type ArticleControllerGetArticleBySlugApiResponse = unknown;
export type ArticleControllerGetArticleBySlugApiArg = {
  slug: string;
};
export type ArticleRelativeControllerGetArticleListViewRelatedApiResponse =
  unknown;
export type ArticleRelativeControllerGetArticleListViewRelatedApiArg = {
  categories: string[];
};
export type JobChatMessageControllerGetOwnedChatMessagesApiResponse = unknown;
export type JobChatMessageControllerGetOwnedChatMessagesApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
  chatBoxId: string;
};
export type JobChatMessageControllerSendMessagesApiResponse = unknown;
export type JobChatMessageControllerSendMessagesApiArg = {
  sendMessageDto: SendMessageDto;
};
export type S3PreSignedUrlControllerGetOwnedChatMessagesApiResponse = unknown;
export type S3PreSignedUrlControllerGetOwnedChatMessagesApiArg = {
  getS3PreSignedUrlDto: GetS3PreSignedUrlDto;
};
export type JobChatboxControllerGetOwnedChatboxesApiResponse = unknown;
export type JobChatboxControllerGetOwnedChatboxesApiArg = {
  page?: number;
  size?: number;
  limit?: number;
  offset?: number;
};
export type JobChatboxControllerSoftDeleteOwnedChatboxApiResponse = unknown;
export type JobChatboxControllerSoftDeleteOwnedChatboxApiArg = {
  chatBoxId: string;
};
export type JobFieldsOfStudyControllerGetFieldsOfStudyApiResponse = unknown;
export type JobFieldsOfStudyControllerGetFieldsOfStudyApiArg = void;
export type FeaturedBrandLogosControllerFeaturedBrandLogosApiResponse = unknown;
export type FeaturedBrandLogosControllerFeaturedBrandLogosApiArg = void;
export type BrandSeoControllerGetBrandsApiResponse = unknown;
export type BrandSeoControllerGetBrandsApiArg = void;
export type CentreDetailSeoControllerGetCentreDetailApiResponse = unknown;
export type CentreDetailSeoControllerGetCentreDetailApiArg = {
  organizationId: string;
};
export type CompanySeoControllerGetCompaniesApiResponse = unknown;
export type CompanySeoControllerGetCompaniesApiArg = void;
export type CompanyDetailSeoControllerGetCompanyDetailApiResponse = unknown;
export type CompanyDetailSeoControllerGetCompanyDetailApiArg = {
  id: string;
};
export type JobRoleSeoControllerGetJobRolesApiResponse = unknown;
export type JobRoleSeoControllerGetJobRolesApiArg = void;
export type PostCodeSeoControllerGetPostCodesApiResponse = unknown;
export type PostCodeSeoControllerGetPostCodesApiArg = void;
export type RoleDetailSeoControllerGetRoleDetailApiResponse = unknown;
export type RoleDetailSeoControllerGetRoleDetailApiArg = {
  id: string;
};
export type ApplicationShortFormResult = {
  shouldUseShortForm: boolean;
};
export type ApplicationShortFormResponse = {
  result: ApplicationShortFormResult;
};
export type CentreRate = {
  icon: string;
  image: string;
  name: string;
  rating: number;
  state: string;
  postCode: string;
  city: string;
  slug: string;
  country: string;
  id: string;
};
export type SuburbProfileHighestRateResp = {
  results: CentreRate[];
};
export type ServiceType = {
  id?: string | null;
  externalId?: string | null;
  name?: string | null;
  icon?: string | null;
  description?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  isActive?: boolean | null;
  sortOrder?: number | null;
  weekPerYear?: number | null;
  hourlyFeeCap?: number | null;
  isShow?: boolean | null;
  primaryChildCareServiceId?: number | null;
};
export type ServiceAgeGroup = {
  type?: string;
  id: string;
  name?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  order?: number | null;
  isActive?: boolean | null;
  textColor?: string | null;
  backgroundColor?: string | null;
  logo?: string | null;
  services: string[];
  conditionHasServiceAgeGroups?: object;
};
export type SessionType = {
  id: string;
  name?: string | null;
  type?: object;
  isActive?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  order?: number | null;
  services?: string[];
  displayName?: object;
};
export type Program = {
  serviceType?: ServiceType | null;
  ageGroup?: ServiceAgeGroup[];
  sessionType?: SessionType[];
  id: string;
  name?: string | null;
  companyId?: string | null;
  serviceTypeId?: string | null;
  image?: string | null;
  about?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  providerIds?: object | null;
  services?: string[];
};
export type IParentProgramResponse = {
  results: Program[] | null;
  paging: {
    page?: number;
    size?: number;
    total?: number;
  };
};
export type ResultWrapperProgram = {
  result: Program;
};
export type SuburbProfileKcRatingResp = {
  type: string;
  rating: string;
  description: string;
};
export type HeadLineCarousel = {
  content: string;
  verticalLine: boolean;
};
export type CentreCity = {
  id: string;
  city?: string | null;
  state?: string | null;
  postCode?: string | null;
  country?: string | null;
  geolocation?: object | null;
  centreCount?: number;
  highestKindiCareRatingCentreId?: string | null;
  highestKindiCareRatingCentre?: object | null;
  newestDateAppliedCentreId?: string | null;
  newestDateAppliedCentre?: object | null;
  bestValueForMoneyCentreId?: string | null;
  bestValueForMoneyCentre?: object | null;
  totalReviewsAllYear?: number | null;
  totalReviewsPreviousYear?: number | null;
  suburbSlug?: string | null;
  createdAt?: string | null;
  updatedAt?: string | null;
  image?: string | null;
  minCost?: number | null;
  maxCost?: number | null;
  avgKindicareRating?: string | null;
  avgReviewStar?: string | null;
  distance?: number | null;
};
export type NearbySuburbCarouselResp = {
  headline: HeadLineCarousel;
  subHeadline: string;
  results: CentreCity[];
  total: number;
};
export type Geolocation = {
  latitude: number;
  longitude: number;
};
export type Suburb = {
  suburbName: string;
  postCode: string;
  country: string;
  state: string;
  suburbSlug: string;
  geolocation: Geolocation;
  image: string;
};
export type CentreByCareType = {
  serviceTypeName: object;
  centreCount: string;
  avgCost: string;
  minCost: object;
  maxCost: object;
};
export type CentresByCareTypeResp = {
  suburb: Suburb;
  results: CentreByCareType[];
};
export type ILegend = {
  name: string;
  backgroundColor: string;
};
export type InqsStatistics = {
  countSuburb: number;
  percentSuburb: number;
  countNation: number;
  percentNation: number;
  nqsTypeName: string;
  color: string;
};
export type ISuburbProfileNqsChart = {
  suburb: object;
  legend: ILegend[];
  nqsNationCount: number;
  nqsCount: number;
  nqsStatistics: InqsStatistics[];
};
export type ResultWrapperISuburbProfileNqsChart = {
  result: ISuburbProfileNqsChart;
};
export type HighestKcRatingCentre = {
  slug: string;
  centreName: string;
  kindiCareRating: string;
  NQSRating: string;
};
export type NewestApprovedCentre = {
  slug: string;
  centreName: string;
  approvedDate: string;
};
export type Centre = {
  slug: string;
  centreName: string;
};
export type SuburbProfileDetailResp = {
  totalCentre: string;
  currentSuburb: Suburb;
  serviceTypes: string[];
  highestKCRatingCentre: HighestKcRatingCentre;
  newestApprovedCentre: NewestApprovedCentre;
  bestValueForMoneyCentre: Centre;
  totalReviewsAllYear: string;
  totalReviewsPreviousYear: string;
  suburbsInRadius: Suburb[];
  minCost: string;
  maxCost: string;
};
export type PaginationResponse = {
  page: number;
  size: number;
  total: number;
};
export type NearbySuburbListResp = {
  results: CentreCity[];
  paging: PaginationResponse;
};
export type SuburbProfileParentReviewsResp = {
  totalReviews: string;
  avgRating: string;
};
export type SuburbProfileSuggestion = {
  city: string;
  state: string;
  postCode: string;
  country: string;
  slug: string;
  geolocation: Geolocation;
  type: string;
};
export type SuburbProfileSuggestionResp = {
  results: SuburbProfileSuggestion[];
  total: number;
};
export type Brand = {
  logo: string;
};
export type NqrsType = {
  image: string;
};
export type BestService = {
  centreValueForMoney: string;
  costPerDay: number;
  subsidyCost: number;
};
export type CentreInSuburb = {
  id: string;
  name: string;
  kindiCareRating: number;
  displayAddress: string;
  brand: Brand;
  slug: string;
  isFeatured: boolean;
  isSpecialOffer: boolean;
  reviewCount: number;
  reviewStarAverage: number;
  geolocation: Geolocation;
  isPopular: boolean;
  ratingCount: number;
  isFavoriteCentre: boolean;
  distance: number;
  NQSRType: NqrsType;
  bestService: BestService;
  image: string;
};
export type CentresInSuburbResp = {
  suburb: Suburb;
  results: CentreInSuburb[];
};
export type UpdateSeekerProfile = {
  workEligibilityId: string;
  coverPhotoUrl: string;
  avatar: string;
  pronouns: string;
  customPronouns: string;
  languageIds: string[];
  isLookingForNewJob: boolean;
  isOpenForNewJob: boolean;
  documentId: string;
  address: string;
  country: string;
  dateOfBirth: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  postCode: string;
  state: string;
  suburb: string;
};
export type SeekerProfileDto = {};
export type CreateSeekerProfileExperience = {
  roleId: string;
  organizationName: string;
  startDate: string;
  endDate: string;
  description: string;
  isCurrentlyWorking: boolean;
  typeId: string;
};
export type UpdateSeekerProfileExperience = {
  roleId: string;
  organizationName: string;
  startDate: string;
  endDate: string;
  description: string;
  isCurrentlyWorking: boolean;
  typeId: string;
};
export type CreateSeekerProfileCertification = {
  certificationId: string;
  issuingOrganization: string;
  issuerId: string;
  issuerUrl: string;
  startDate: string;
  endDate: string;
  isNotExpired: boolean;
};
export type UpdateSeekerProfileCertification = {
  certificationId: string;
  issuingOrganization: string;
  issuerId: string;
  issuerUrl: string;
  startDate: string;
  endDate: string;
  isNotExpired: boolean;
};
export type CreateSeekerProfileDocument = {
  videoUrl: string;
  resumeUrl: string;
  coverLetterUrl: string;
  id?: string;
};
export type CreateSeekerProfileQualification = {};
export type UpdateSeekerProfileQualification = {};
export type UpdateCareerPreferences = {
  distanceForWork: string;
  specificStartDate: string;
  isImmediateStart: boolean;
  desiredSalaryMin: string;
  desiredSalaryMax: string;
  roleIds: string[];
  excludeBrandIds: string[];
  excludeCompanyIds: string[];
  workingTypeIds: string[];
  workingDays: string[];
};
export type CreateIgnoredPostDto = {
  postId: string;
};
export type UserViewedJobPostDto = {
  viewedAt: string;
};
export type JobPostFavoriteDto = {
  postId: string;
};
export type JobMatchScore = {
  score: object;
  unit: object;
};
export type Workplace = {
  name: object;
  photo: object;
  logo: object;
};
export type Distance = {
  address: object;
  value: object;
};
export type JobType = {
  name: object;
};
export type Salary = {
  value: object;
};
export type EducatorAndStaffDiscount = {
  icon: object;
  value: object;
};
export type Experience = {
  value: object;
};
export type MinimumQualificationRequired = {
  value: object;
};
export type JobStartDate = {
  value: object;
};
export type PostedTime = {
  value: object;
};
export type TimeLeftToApply = {
  value: object;
  color: object;
  backgroundColor: object;
};
export type IComparisonJobPostItem = {
  id: string;
  companyId: string;
  organizationId: string;
  seekerId: string;
  title: string;
  isFavorite: boolean;
  slug: string;
  state: string;
  city: string;
  postCode: string;
  jobMatchScore: JobMatchScore;
  workplace: Workplace;
  distance: Distance;
  jobType: JobType;
  salary: Salary;
  educatorAndStaffDiscount: EducatorAndStaffDiscount;
  experience: Experience;
  minimumQualificationRequired: MinimumQualificationRequired;
  jobStartDate: JobStartDate;
  postedTime: PostedTime;
  timeLeftToApply: TimeLeftToApply;
  centre: object;
  brand: object;
  company: object;
  jobContactPhone: object;
  jobContactEmail: object;
  application: object;
};
export type TitleByField = {
  jobMatchScore: string;
  workplace: string;
  distance: string;
  jobType: string;
  salary: string;
  educatorAndStaffDiscount: string;
  experience: string;
  minimumQualificationRequired: string;
  jobStartDate: string;
  postedTime: string;
  timeLeftToApply: string;
  company: string;
};
export type ItemOfTitleByField = {
  name: string;
  icon: string;
  _typename: string;
};
export type TitleByFieldConfig = {
  jobMatchScore: ItemOfTitleByField;
  workplace: ItemOfTitleByField;
  distance: ItemOfTitleByField;
  jobType: ItemOfTitleByField;
  salary: ItemOfTitleByField;
  educatorAndStaffDiscount: ItemOfTitleByField;
  experience: ItemOfTitleByField;
  minimumQualificationRequired: ItemOfTitleByField;
  jobStartDate: ItemOfTitleByField;
  postedTime: ItemOfTitleByField;
  timeLeftToApply: ItemOfTitleByField;
  company: ItemOfTitleByField;
};
export type IComparisonJobPostResp = {
  posts: IComparisonJobPostItem[];
  titleByField: TitleByField;
  titleByFieldConfig: TitleByFieldConfig;
};
export type JobRequirements = {
  id: object;
  notMatchMandatoryRequirements: object;
  notMatchPreferredRequirements: object;
  matchingRequirementDescription: object;
  jobMatching: object;
  isOneClickApplyApplicable: boolean;
  applyJobDescription: object;
};
export type ApplyPostDto = {
  postId: string;
};
export type SendMessageDto = {
  chatBoxId: object;
  content: string;
  files: string[];
};
export type GetS3PreSignedUrlDto = {
  chatBoxId: string;
  filename: string;
};
export const {
  useAdsPostCarouselCentreDetailControllerGetCarouselGreatOfferAdsPostInCentreDetailQuery,
  useLazyAdsPostCarouselCentreDetailControllerGetCarouselGreatOfferAdsPostInCentreDetailQuery,
  useAdsPostCarouselHomeControllerGetCarouselGreatOfferAdsPostInHomePageQuery,
  useLazyAdsPostCarouselHomeControllerGetCarouselGreatOfferAdsPostInHomePageQuery,
  useAdsPostControllerGetAdsPostsListViewQuery,
  useLazyAdsPostControllerGetAdsPostsListViewQuery,
  useRandomAdsSideBarControllerGetRandomAdsSideBarQuery,
  useLazyRandomAdsSideBarControllerGetRandomAdsSideBarQuery,
  useApplicationShortFormControllerGetApplicationShortFormAttrsQuery,
  useLazyApplicationShortFormControllerGetApplicationShortFormAttrsQuery,
  useRwCentreFeaturesControllerGetChartDataQuery,
  useLazyRwCentreFeaturesControllerGetChartDataQuery,
  useParentEducatorProfileControllerGetEducatorProfilesQuery,
  useLazyParentEducatorProfileControllerGetEducatorProfilesQuery,
  useSuburbProfileHighestRateControllerGetHighestRateCentresQuery,
  useLazySuburbProfileHighestRateControllerGetHighestRateCentresQuery,
  useParentThorProgramControllerGetParentProgramQuery,
  useLazyParentThorProgramControllerGetParentProgramQuery,
  useParentThorProgramControllerGetParentDetailProgramQuery,
  useLazyParentThorProgramControllerGetParentDetailProgramQuery,
  useKindiCareAvgRatingOfSuburbProfileControllerGetKindiCareAvgRatingOfSuburbProfileQuery,
  useLazyKindiCareAvgRatingOfSuburbProfileControllerGetKindiCareAvgRatingOfSuburbProfileQuery,
  useThorSuburbProfileCarouselControllerGetParentCentreProgramQuery,
  useLazyThorSuburbProfileCarouselControllerGetParentCentreProgramQuery,
  useCentreServicesSuburbProfileControllerGetCentreServiceSuburbProfileQuery,
  useLazyCentreServicesSuburbProfileControllerGetCentreServiceSuburbProfileQuery,
  useSuburbProfileChartControllerGetSuburbProfileDescriptionQuery,
  useLazySuburbProfileChartControllerGetSuburbProfileDescriptionQuery,
  useSuburbProfileDescriptionControllerGetSuburbProfileDescriptionQuery,
  useLazySuburbProfileDescriptionControllerGetSuburbProfileDescriptionQuery,
  useThorSuburbProfileListControllerGetParentCentreProgramQuery,
  useLazyThorSuburbProfileListControllerGetParentCentreProgramQuery,
  useParentReviewsOfSuburbProfileControllerGetParentReviewsOfSuburbProfileQuery,
  useLazyParentReviewsOfSuburbProfileControllerGetParentReviewsOfSuburbProfileQuery,
  useSuburbProfilesSuggestionControllerSuburbProfilesSuggestionQuery,
  useLazySuburbProfilesSuggestionControllerSuburbProfilesSuggestionQuery,
  useMarketingsCentresControllerGetMarketingsCentresQuery,
  useLazyMarketingsCentresControllerGetMarketingsCentresQuery,
  useNearbyCentresControllerGetNearbyCentresQuery,
  useLazyNearbyCentresControllerGetNearbyCentresQuery,
  usePopularNearbyCentresControllerGetPopularNearbyCentresQuery,
  useLazyPopularNearbyCentresControllerGetPopularNearbyCentresQuery,
  useRecommendedServicesCentresControllerGetRecommendedServicesCentresQuery,
  useLazyRecommendedServicesCentresControllerGetRecommendedServicesCentresQuery,
  useTopPicksCentresControllerGetTopPicksCentresQuery,
  useLazyTopPicksCentresControllerGetTopPicksCentresQuery,
  useWellKnownCentresControllerGetWellKnownCentresQuery,
  useLazyWellKnownCentresControllerGetWellKnownCentresQuery,
  useGetCentresInSuburbControllerGetCentresInSuburbQuery,
  useLazyGetCentresInSuburbControllerGetCentresInSuburbQuery,
  useParentConfigControllerGetPageTermConditionsQuery,
  useLazyParentConfigControllerGetPageTermConditionsQuery,
  useParentConfigControllerGetPagePrivacyPolicyQuery,
  useLazyParentConfigControllerGetPagePrivacyPolicyQuery,
  useBrandControllerFindAllQuery,
  useLazyBrandControllerFindAllQuery,
  useBrandControllerFindOneQuery,
  useLazyBrandControllerFindOneQuery,
  useSuggestionControllerSearchSuggestionQuery,
  useLazySuggestionControllerSearchSuggestionQuery,
  useCtaButtonControllerGetCtaButtonsQuery,
  useLazyCtaButtonControllerGetCtaButtonsQuery,
  useRatingSummaryControllerGetRatingSummaryQuery,
  useLazyRatingSummaryControllerGetRatingSummaryQuery,
  useVirtualTourControllerGetCentreVirtualTourQuery,
  useLazyVirtualTourControllerGetCentreVirtualTourQuery,
  useSeekerControllerGetJobSeekerProfileQuery,
  useLazySeekerControllerGetJobSeekerProfileQuery,
  useSeekerControllerUpdateJobSeekerProfileMutation,
  useProfileControllerUpdateShowEditProfileTooltipMutation,
  useProfileExperienceControllerGetManyQuery,
  useLazyProfileExperienceControllerGetManyQuery,
  useProfileExperienceControllerCreateOneMutation,
  useProfileExperienceControllerUpdateOneMutation,
  useProfileExperienceControllerDeleteOneMutation,
  useJobRoleControllerGetManyQuery,
  useLazyJobRoleControllerGetManyQuery,
  useJobTypeControllerGetManyQuery,
  useLazyJobTypeControllerGetManyQuery,
  useJobExperienceOrganizationControllerGetManyQuery,
  useLazyJobExperienceOrganizationControllerGetManyQuery,
  useProfileCertificationControllerGetManyQuery,
  useLazyProfileCertificationControllerGetManyQuery,
  useProfileCertificationControllerCreateOneMutation,
  useProfileCertificationControllerUpdateOneMutation,
  useProfileCertificationControllerDeleteOneMutation,
  useJobCertificationControllerGetManyQuery,
  useLazyJobCertificationControllerGetManyQuery,
  useProfileDocumentControllerGetManyQuery,
  useLazyProfileDocumentControllerGetManyQuery,
  useProfileDocumentControllerCreateOneMutation,
  useProfileImmunisationControllerGetManyQuery,
  useLazyProfileImmunisationControllerGetManyQuery,
  useProfileImmunisationControllerCreateOneMutation,
  useProfileImmunisationControllerDeleteOneMutation,
  useJobImmunisationtrollerGetManyQuery,
  useLazyJobImmunisationtrollerGetManyQuery,
  useProfileSkillControllerGetManyQuery,
  useLazyProfileSkillControllerGetManyQuery,
  useProfileSkillControllerCreateOneMutation,
  useProfileSkillControllerDeleteOneMutation,
  useJobSkillControllerGetManyQuery,
  useLazyJobSkillControllerGetManyQuery,
  useJobSkillLevelControllerGetManyQuery,
  useLazyJobSkillLevelControllerGetManyQuery,
  useProfileQualificationControllerGetManyQuery,
  useLazyProfileQualificationControllerGetManyQuery,
  useProfileQualificationControllerCreateOneMutation,
  useProfileQualificationControllerUpdateOneMutation,
  useProfileQualificationControllerDeleteOneMutation,
  useJobQualificationControllerGetManyQuery,
  useLazyJobQualificationControllerGetManyQuery,
  useJobWorkEligibilityControllerGetManyQuery,
  useLazyJobWorkEligibilityControllerGetManyQuery,
  useJobLanguageControllerGetManyQuery,
  useLazyJobLanguageControllerGetManyQuery,
  useJobSeekerCareerPreferenceControllerUpdateJobSeekerProfileMutation,
  useIgnoredPostsControllerCreateOneIgnoredPostMutation,
  useIgnoredPostsControllerGetIgnoredJobsOfSeekerQuery,
  useLazyIgnoredPostsControllerGetIgnoredJobsOfSeekerQuery,
  useSearchSuggestionJobPostsControllerGetSuggestionPostsQuery,
  useLazySearchSuggestionJobPostsControllerGetSuggestionPostsQuery,
  useSearchSuggestionJobPostsControllerDeleteRecentSearchMutation,
  useRecentViewJobPostsControllerAddUserRecentViewMutation,
  useFavoriteControllerAddJobFavoriteMutation,
  useFavoriteControllerRemoveJobFavoriteMutation,
  useJobMatchesControllerGetJobMatchesQuery,
  useLazyJobMatchesControllerGetJobMatchesQuery,
  useKeySearchJobPostsControllerKeySearchQuery,
  useLazyKeySearchJobPostsControllerKeySearchQuery,
  useComparisonJobPostControllerGetComparisonJobPostsQuery,
  useLazyComparisonJobPostControllerGetComparisonJobPostsQuery,
  useJobRequirementsControllerGetJobPostNotMatchRequirementsQuery,
  useLazyJobRequirementsControllerGetJobPostNotMatchRequirementsQuery,
  useWorkplaceControllerGetWorkplaceInfoQuery,
  useLazyWorkplaceControllerGetWorkplaceInfoQuery,
  useApplicationsControllerCreateOneApplicationMutation,
  useApplicationJobCardControllerGetApplicationCardInfoQuery,
  useLazyApplicationJobCardControllerGetApplicationCardInfoQuery,
  useCancelApplicationControllerCancelApplicationMutation,
  useHomepageControllerJobHomepageQuery,
  useLazyHomepageControllerJobHomepageQuery,
  useOrganizationJobPostsControllerGetOrganizationsPostsQuery,
  useLazyOrganizationJobPostsControllerGetOrganizationsPostsQuery,
  useJobPostControllerGetJobPostsQuery,
  useLazyJobPostControllerGetJobPostsQuery,
  useNearbyJobPostsControllerGetNearbyJobPostsQuery,
  useLazyNearbyJobPostsControllerGetNearbyJobPostsQuery,
  useNewestJobPostsControllerGetNewestPostsQuery,
  useLazyNewestJobPostsControllerGetNewestPostsQuery,
  useFeaturedBrandJobPostsControllerGetJobPostsQuery,
  useLazyFeaturedBrandJobPostsControllerGetJobPostsQuery,
  useFeaturedCompaniesJobPostsControllerGetJobPostsQuery,
  useLazyFeaturedCompaniesJobPostsControllerGetJobPostsQuery,
  useFeaturedJobPostsControllerGetFeaturedPostsQuery,
  useLazyFeaturedJobPostsControllerGetFeaturedPostsQuery,
  usePostFilterConfigControllerGetJobPostFilterConfigQuery,
  useLazyPostFilterConfigControllerGetJobPostFilterConfigQuery,
  useSuggestionJobPostsControllerGetSuggestionJobPostsQuery,
  useLazySuggestionJobPostsControllerGetSuggestionJobPostsQuery,
  useAdsPostsControllerGetJobPostsQuery,
  useLazyAdsPostsControllerGetJobPostsQuery,
  useAdsPostsControllerGetRandomAdsSideBarQuery,
  useLazyAdsPostsControllerGetRandomAdsSideBarQuery,
  useAppliedControllerGetAppliedJobsQuery,
  useLazyAppliedControllerGetAppliedJobsQuery,
  useViewedControllerGetViewedJobsQuery,
  useLazyViewedControllerGetViewedJobsQuery,
  useFavoritesControllerGetFavoritesJobsQuery,
  useLazyFavoritesControllerGetFavoritesJobsQuery,
  useOrganizationControllerGetOrganizationDiscountQuery,
  useLazyOrganizationControllerGetOrganizationDiscountQuery,
  useArticleControllerGetArticleListViewQuery,
  useLazyArticleControllerGetArticleListViewQuery,
  useArticleControllerGetArticleBySlugQuery,
  useLazyArticleControllerGetArticleBySlugQuery,
  useArticleRelativeControllerGetArticleListViewRelatedQuery,
  useLazyArticleRelativeControllerGetArticleListViewRelatedQuery,
  useJobChatMessageControllerGetOwnedChatMessagesQuery,
  useLazyJobChatMessageControllerGetOwnedChatMessagesQuery,
  useJobChatMessageControllerSendMessagesMutation,
  useS3PreSignedUrlControllerGetOwnedChatMessagesMutation,
  useJobChatboxControllerGetOwnedChatboxesQuery,
  useLazyJobChatboxControllerGetOwnedChatboxesQuery,
  useJobChatboxControllerSoftDeleteOwnedChatboxMutation,
  useJobFieldsOfStudyControllerGetFieldsOfStudyQuery,
  useLazyJobFieldsOfStudyControllerGetFieldsOfStudyQuery,
  useFeaturedBrandLogosControllerFeaturedBrandLogosQuery,
  useLazyFeaturedBrandLogosControllerFeaturedBrandLogosQuery,
  useBrandSeoControllerGetBrandsQuery,
  useLazyBrandSeoControllerGetBrandsQuery,
  useCentreDetailSeoControllerGetCentreDetailQuery,
  useLazyCentreDetailSeoControllerGetCentreDetailQuery,
  useCompanySeoControllerGetCompaniesQuery,
  useLazyCompanySeoControllerGetCompaniesQuery,
  useCompanyDetailSeoControllerGetCompanyDetailQuery,
  useLazyCompanyDetailSeoControllerGetCompanyDetailQuery,
  useJobRoleSeoControllerGetJobRolesQuery,
  useLazyJobRoleSeoControllerGetJobRolesQuery,
  usePostCodeSeoControllerGetPostCodesQuery,
  useLazyPostCodeSeoControllerGetPostCodesQuery,
  useRoleDetailSeoControllerGetRoleDetailQuery,
  useLazyRoleDetailSeoControllerGetRoleDetailQuery,
} = injectedRtkApi;
