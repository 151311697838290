import styled from 'styled-components';
import { respondTo } from 'utils/variables';

export const ViewAllCardStyles = styled.a`
  --primaryColor: ${({ primaryColor }) => primaryColor || 'var(--bg-green)'};

  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid var(--border-color-light);
  border-radius: 16px;
  box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.1),
    0px 4px 20px -2px rgba(50, 50, 71, 0.08);
  transition: box-shadow 0.3s, border-color 0.3s;

  &:hover {
    border-color: transparent;
    box-shadow: 0 1px 2px -2px rgb(0 0 0 / 16%), 0 3px 6px 0 rgb(0 0 0 / 12%),
      0 5px 12px 4px rgb(0 0 0 / 9%);
  }

  .view-all {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: var(--primaryColor);
    margin-top: 14px;
  }

  .ant-btn-circle {
    border-color: var(--primaryColor);
    background: var(--primaryColor);
    width: 27px;
    min-width: 27px;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    .anticon {
      font-size: 12px;
    }
  }

  ${respondTo.xs`
    .ant-btn-circle .anticon {
      font-size: 10px;
    }
  `}
`;
