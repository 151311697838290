import FormSelect from '@uikit/form/FormSelect';
import { ENQUIRY_TYPES_CONST } from 'configs/localData';
import { useTranslation } from 'react-i18next';
import useEnquiryTypes from './useEnquiryTypes';

const EnquiryTypeSelect = () => {
  const enquiryTypes = useEnquiryTypes();
  const { t } = useTranslation();

  return (
    <FormSelect
      required
      label="input.enquiryReason.label"
      name="enquiryTypeId"
      resourceData={enquiryTypes}
      valueProp="id"
      titleProp="name"
      formatText={(name) => t(ENQUIRY_TYPES_CONST[name]?.text) || name}
      messageRequire="input.enquiryReason.validateMsg.required"
      getPopupContainer={(trigger) => trigger.parentNode}
    />
  );
};

export default EnquiryTypeSelect;
