import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { trackingEventApi } from 'api/eventTracking';
import dayjs from 'utils/dayUtils';
import { getDeviceId } from 'utils/tools';

export const MODEL_NAME = 'trackingEvent';

export const trackingEvent = createAsyncThunk(
  'trackingEvent/postTrackingEvent',
  async (payload: TrackingEvent, thunkAPI: any) => {
    try {
      const currentGeoLocation =
        thunkAPI.getState().config?.currentLocation?.geoLocation;
      const currentUser = thunkAPI.getState().auth?.data;

      const convertRequest = {
        userId: currentUser?.id,
        platform: 'rich-web',
        geolocation: currentGeoLocation,
        deviceId: getDeviceId(),
        eventTime: dayjs().toISOString(),
        ...payload,
        centreId: payload?.eventData?.centreId,
        eventData: JSON.stringify(payload?.eventData || {}),
      };

      const response = await apiWrapper(
        {
          isShowLoading: false,
          isShowSuccess: false,
          isCheckError: false,
        },
        trackingEventApi,
        convertRequest,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
