import { rtkCreator } from '@redux/crudCreator//baseRTK';
import { richWebConfigVer2 } from 'utils';

export const organizationsApi = rtkCreator(
  'organizations',
  'job-seekers/screens/posts/organizations',
  richWebConfigVer2,
);

export const { useGetAllOrganizationsQuery } = organizationsApi;

export const { getAllOrganizations } = organizationsApi.endpoints;
