import React from 'react';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { RightOutlineIcon } from '@uikit/commons/SVGIcons';
import { useDispatch } from 'react-redux';
import { trackingEvent } from '@redux/eventTracking/actions';
import Link from 'next/link';
import { omit } from 'lodash-es';
import { ViewAllCardStyles } from './styles';

interface Actions {
  loggingData?: unknown;
}

interface Props {
  viewAllHref?: string | object;
  action?: Actions;
  primaryColor?: string;
  onClick?: () => void;
  className?: string;
}

const ViewAllCard = ({
  viewAllHref,
  action,
  primaryColor,
  onClick,
  className,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onViewJobDetail = () => {
    const eventPayload = action?.loggingData;
    dispatch(trackingEvent(omit(eventPayload, '_typename')));
  };

  const renderViewAllCard = () => (
    <ViewAllCardStyles
      className={className}
      primaryColor={primaryColor}
      onClick={onViewJobDetail}
    >
      <div className="flex-center flex-column">
        <Button
          type="primary"
          shape="circle"
          className="xs:size-14"
          icon={<RightOutlineIcon />}
        />
        <div className="view-all xs:size-14">{t('button.viewAll')}</div>
      </div>
    </ViewAllCardStyles>
  );
  return (
    <>
      {onClick ? (
        <div className="h-full" onClick={onClick} role="presentation">
          {renderViewAllCard()}
        </div>
      ) : (
        <Link href={viewAllHref} passHref>
          {renderViewAllCard()}
        </Link>
      )}
    </>
  );
};

export default ViewAllCard;
