import KindiRating from '@uikit/commons/KindiRating';
import ModalCustom from '@uikit/commons/ModalCustom';
import styled from 'styled-components';
import { respondTo } from 'utils/variables';

export const CentreDetailsStyles = styled.div`
  --primary: ${({ primaryColor }) => primaryColor};

  margin-top: 36px;

  .centre-address:hover {
    color: var(--primary) !important;
  }

  .review-count:hover {
    color: var(--primary) !important;
  }

  .centre-section-title {
    font-size: 28px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 30px;
  }

  .toggle-paragraph {
    .ant-typography-expand {
      color: var(--primary) !important;
    }
  }

  .centre__title-wrapper {
    display: flex;
    justify-content: space-between;

    .ant-btn {
      background: #f7f8f9;
      border: 2px solid #e9ebef;
      color: #7d8294;
      width: 48px;
      height: 48px;

      + .ant-btn {
        margin-left: 10px;
      }

      .anticon {
        font-size: 20px;
      }
    }

    .centre__title {
      font-size: 32px;
      font-weight: 800;
      line-height: 40px;
      color: var(--color-grey);
      margin-bottom: 0;
      margin-right: 36px;
      align-self: flex-start;
    }

    .dot-title {
      margin-right: 0;
    }

    .centre__title-btn {
      display: flex;
      white-space: nowrap;
    }
  }

  .back-button {
    border-radius: 40px;
    border: 1px solid var(--color-grey-l5);
    padding: 10px 18px;
    color: var(--text-secondary);
    font-weight: bold;
    display: flex;
    align-items: center;
  }

  .sub-title {
    display: flex;
    align-items: center;
    color: var(--text-secondary);
    font-size: 16px;

    &__rating {
      background-color: var(--primary);
      border-radius: 8px;
      font-weight: 700;
      color: #fff;
      display: flex;
      width: 48px;
      height: 48px;
      align-items: center;
      justify-content: center;

      .centre-item__rating-img {
        padding: 12px 0 6px 0;
      }
    }

    .anticon {
      font-size: 20px;
    }

    .ant-space-item {
      display: flex;
      align-items: center;
    }
  }

  .divide-line {
    margin: 48px 0;
  }

  .feature-title__title-wrapper {
    display: flex;
    align-items: center;
  }

  .feature-title {
    &__title {
      font-size: 28px !important;
      line-height: 36px !important;
    }

    &__subTitle {
      display: none;
    }
  }

  .feature-title-wrapper {
    margin-bottom: 24px;
    ${respondTo.xs`
    margin-bottom: 12px;
    margin-top: 0
  `}
  }

  .services-slide-wrapper {
    @media only screen and (max-width: 576px) {
      padding: 0 14px !important;
    }
  }

  .centre-description {
    font-size: 16px;
    line-height: 1.8;
    white-space: pre-line;
    margin-right: 40px;
    color: var(--text-black-l8);
  }

  .centre-addtional-description {
    font-size: 16px;
    line-height: 1.8;
    word-break: break-word;
    margin-right: 40px;

    iframe {
      min-height: 314px;
      border: none;
    }

    blockquote {
      border-left: 2px solid #ccc;
      margin-left: 1.5rem;
      padding-left: 1rem;
    }

    h1,
    h2,
    h3,
    h5,
    h6,
    p {
      margin: 16px 0;
    }

    img {
      object-fit: contain !important;
      height: auto;
    }

    a:not(.btn-toggle) {
      text-decoration: underline;
      color: var(--primary) !important;
    }

    a:hover {
      color: blue;
    }
  }

  .mobile-centre-group-btn {
    display: none;
  }

  .interaction-wrapper {
    padding: 12px 12px 12px 22px;
    margin-bottom: 28px;
    background: var(--bg-pastel-pink);
    border-radius: 8px;

    .anticon {
      color: var(--primary);
      font-size: 20px;
    }
  }

  .interation-text {
    margin-left: 14px;
    color: var(--primary);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  @media only screen and (max-width: 992px) {
    .centre-description,
    .centre-addtional-description {
      margin-right: 0;
    }

    .centre__header-wrapper {
      margin-top: 15px;
    }

    .centre__title-wrapper {
      flex-wrap: wrap;
      flex-direction: column-reverse;

      .centre__title {
        margin-top: 12px;
        font-size: 30px;
        margin-right: 0;
      }
    }

    .centre__header {
      flex-wrap: wrap;

      .ant-breadcrumb {
        margin-top: 20px;
      }
    }

    .centre-section-title {
      font-size: 24px;
      line-height: 32px;
    }

    .feature-title__title {
      font-size: 24px !important;
      line-height: 32px !important;
    }

    .sub-title {
      flex-wrap: wrap;

      .ant-space-item:last-child {
        flex-basis: 100%;
      }

      .dot-hidden {
        display: none;
      }
    }

    .centre-address {
      margin-top: 4px;
    }
  }

  @media only screen and (max-width: 768px) {
    .centre__title-wrapper {
      .centre__title {
        font-size: 24px !important;
        line-height: 32px;
      }

      .dot-title {
        margin-left: 6px;

        > div {
          width: 6px;
          height: 6px;
        }
      }
    }

    .centre-section-title {
      font-size: 22px;
      line-height: 28px;
    }

    .feature-title__title {
      font-size: 22px !important;
      line-height: 28px !important;
    }

    .centre-addtional-description {
      iframe {
        min-height: 250px;
      }
    }
  }

  @media only screen and (max-width: 576px) {
    margin-top: 0;
    .interaction-wrapper {
      padding: 8px 12px;
      margin-bottom: 16px;

      .anticon {
        font-size: 16px;
        margin-top: 3px;
      }
    }

    .interation-text {
      margin-left: 4px;
    }

    .divide-line {
      margin: 32px 0;
    }

    .centre__title-wrapper {
      h1.centre__title {
        font-size: 22px !important;
        line-height: 32px;
        margin-top: 5px;
      }
    }

    .centre-section-title {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 16px;
    }

    .feature-title__title {
      font-size: 20px !important;
      line-height: 26px !important;
    }

    .mobile-centre-group-btn {
      display: block;

      .ant-btn {
        background: #f7f8f9;
        border: 2px solid #e9ebef;
        color: #7d8294;
        width: 44px !important;
        height: 44px !important;
        min-width: 44px;

        + .ant-btn {
          margin-left: 16px;
        }

        .anticon {
          font-size: 17px;
        }
      }
    }

    .back-button {
      border: none;
      font-size: 16px;
      padding: 0;
      margin: 0;
    }

    .centre__title-btn,
    .ant-breadcrumb {
      display: none !important;
    }

    .centre-description {
      margin-top: 24px;
    }

    .centre-description,
    .centre-addtional-description,
    .btn-toggle {
      line-height: 24px;
    }

    .slick-arrow-btn {
      &.slick-next__custom {
        right: 8px !important;
      }

      &.slick-prev__custom {
        left: 8px !important;
      }
    }
  }
`;

export const EmptyServiceStyles = styled.div`
  color: var(--text-black-l8);
  position: sticky;
  top: 80px;

  .ant-card-bordered {
    box-shadow: 0px 8px 40px rgba(39, 40, 51, 0.12);
  }
`;

export const AboutTheCentreStyles = styled.div`
  ${respondTo.xs` 
    .ant-avatar {
      width: 50px !important;
      height: 50px !important;
    }
  `}
`;

export const TitleCategoryStyles = styled.span`
  ${respondTo.md` 
    font-size: 24px;
  `}
  ${respondTo.sm` 
    font-size: 22px;
  `}
`;

export const StyledModal = styled(ModalCustom)`
  margin: 80px auto;

  .ant-modal-header {
    border-bottom: 1px solid #f0f0f0;
    text-align: center;
  }

  .ant-modal-title {
    font-size: 24px;
    font-weight: 700;
  }

  .ant-modal-footer {
    border-top: 1px solid #f0f0f0;
    text-align: center;
  }
`;

export const RatingItemStyles = styled.div`
  .anticon {
    color: var(--color-grey-l2);
  }

  .kindicare-rating {
    padding: 14px 16px;
    border-radius: 12px;
    .centre-item__rating-img {
      border: none;
      background-color: var(--primary);
      padding: 14px 4px 6px !important;
    }
  }

  ${respondTo.xs`
    .rating-wrapper {
      padding-right: 0;
    }
    .kindicare-rating {
      width: 30px;
      height: 30px;
      padding: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .ant-btn-icon-only {
      margin-left: 8px;
      width: 20px;
      height: 20px;
      .anticon svg {
        font-size: 12px;
      }
    }
  `}
`;

export const KindiRatingStyled = styled(KindiRating)`
  img {
    object-fit: ${({ objectFit }) => objectFit};
    border-radius: 8px;
  }
`;

export const CentrePopularTagStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 48px;
  width: 95px;
  text-align: center;
  padding: 12px 10px;
  background: #fff4ec;
  opacity: 0.9;
  border: 1px solid #ffffff;
  backdrop-filter: blur(58.5px);
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  color: #f98437;

  .anticon {
    color: currentColor;
    font-size: 13px;
  }
  ${respondTo.sm`
    gap: 4px;
    padding: 6px 8px;
    height: 36px;
    width: 93px;
  `}
`;
