import { ThemeProvider } from 'styled-components';
import AntOverwriteStyle from '@uikit/AntOverwriteStyle';
import GlobalStyle from '@uikit/GlobalStyle';

export const ThemeProviderUI = (props) => (
  <ThemeProvider {...props} theme={{}}>
    <AntOverwriteStyle />
    <GlobalStyle />
    {props.children}
  </ThemeProvider>
);
