import React, { useEffect, useRef, useState } from 'react';
import { JobsStyles } from 'components/jobs/styles';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { JOBS_PROFILE_MENUS, JOBS_PROFILE_MENUS_CONST } from 'configs/layout';
import {
  APPLY_JOB_SEEKERS_STATUS,
  FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH,
} from 'components/forJobSeekers/constants';
import { Layout, Menu, Modal } from 'antd';
import useWindowSize from 'hooks/useWindowSize';
import styled from 'styled-components';
import RequiredAuthButton from 'components/rest/RequiredAuthButton';
import { useSelector } from 'react-redux';
import useAppDispatch from 'hooks/useAppDispatch';
import { getJobDetailsInfoSelector } from '@redux/jobsModule/jobDetail/selectors';
import { WarningFilled } from '@ant-design/icons';
import { jobSeekersApply } from '@redux/jobsModule/jobDetail/actions';
import { trackingEvent } from '@redux/eventTracking/actions';
import { okButtonStyles } from 'components/forJobSeekers/Detail/styles';
import SuccessfullyModal from 'components/applyJobSeeker/Modals/SuccessfullyModal';
import UpdateProfileModal from 'components/applyJobSeeker/Modals/UpdateProfileModal';
import AskAgainModal from 'components/applyJobSeeker/Modals/AskAgainModal';
import { getFirstJobRequirementAllowEdit } from 'components/forJobSeekers/utils';
import { JOB_DETAIL_PATH } from 'configs/constants';
import { JobContainerMobileStyles } from 'components/mobile/jobs/styles';

const ApplyButtonContainerStyles = styled.div`
  border-top: 1px solid var(--border-light-gray);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  padding: 16px 20px;
  background: #ffffff;

  .ant-btn {
    margin-top: 0;
    border-color: unset;
  }
`;
const JobSidebarMenu = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { push, query, route } = useRouter();
  const { isMobile } = useWindowSize();
  const { data: jobDetail } = useSelector(getJobDetailsInfoSelector);
  const [jobInfo, setJobInfo] = useState(jobDetail?.sections?.[0]);
  const [successfullyModal, setSuccessfullyModal] = useState({
    inVisible: false,
    results: {},
  });
  const askAgainModalRef = useRef(null);
  const updateProfileModalRef = useRef(null);
  const { applyLoading } = useSelector(getJobDetailsInfoSelector);
  const applyText = jobInfo?.floatingFooterComponents?.[0]?.text;
  const { firstRequirementAllowEdit } =
    getFirstJobRequirementAllowEdit(jobInfo);
  const tabActive =
    query?.t ||
    (route !== FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH &&
      query?.t !== JOBS_PROFILE_MENUS_CONST.browseJobs.key)
      ? query?.t
      : JOBS_PROFILE_MENUS_CONST.browseJobs.key;

  const handleChangeTab = (e) => {
    if (isMobile && e.key === JOBS_PROFILE_MENUS_CONST.jobMailbox.key) {
      return push(`/m${FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH}?t=${e?.key}`);
    }
    return push(`${FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH}?t=${e?.key}`);
  };

  const [isCollapsedMenu, setIsCollapsedMenu] = useState(true);

  const PopupDoneModal = (description) => {
    Modal.warning({
      title: description,
      icon: <WarningFilled />,
      okText: t('button.done'),
      okButtonProps: { style: okButtonStyles },
    });
  };

  const openApplyModal = () => {
    dispatch(
      jobSeekersApply({
        postId: jobInfo?.id,
      }),
    ).then((res) => {
      const response = res?.payload?.action;
      const buttonText = response?.buttons[1]?.text;
      if (buttonText === APPLY_JOB_SEEKERS_STATUS?.AGREE) {
        askAgainModalRef.current?.toggleVisible(response);
        return;
      }
      if (buttonText === APPLY_JOB_SEEKERS_STATUS?.UPDATE) {
        updateProfileModalRef.current?.toggleVisible(response);
        return;
      }
      if (
        response?.screen === APPLY_JOB_SEEKERS_STATUS?.SUCCESSFULAPPLICATION
      ) {
        setSuccessfullyModal({
          inVisible: true,
          results: response,
        });
        dispatch(
          trackingEvent({
            eventName: response?.loggingData?.eventName,
            eventData: response?.loggingData?.eventData,
          }),
        );
        return;
      }
      if (response?.buttons[0]?.text === APPLY_JOB_SEEKERS_STATUS?.DONE) {
        PopupDoneModal(response?.description);
      }
    });
  };

  const checkDisableButton = () =>
    !jobInfo?.floatingFooterComponents[0]?.action;

  const isMailboxDetail =
    query?.t === JOBS_PROFILE_MENUS_CONST.jobMailbox.key && !!query?.id;

  useEffect(() => {
    setJobInfo(jobDetail?.sections?.[0]);
  }, [jobDetail?.sections]);

  return (
    <>
      {isMobile ? (
        <>
          {route === JOB_DETAIL_PATH ? (
            <ApplyButtonContainerStyles>
              <RequiredAuthButton
                backgroundColor={
                  jobInfo?.floatingFooterComponents[0]?.style?.backgroundColor
                }
                textColor={
                  jobInfo?.floatingFooterComponents[0]?.textStyle?.color
                }
                borderColor={
                  jobInfo?.floatingFooterComponents[0]?.style?.borderColor
                }
                className="mt-24"
                loading={applyLoading}
                type="primary"
                block
                onClick={openApplyModal}
                disabled={checkDisableButton()}
              >
                {applyText}
              </RequiredAuthButton>
            </ApplyButtonContainerStyles>
          ) : (
            <JobContainerMobileStyles>
              {!isMailboxDetail && (
                <div className="job-m__bottom-navigate">
                  <Menu
                    mode="horizontal"
                    selectedKeys={[tabActive as string]}
                    onSelect={handleChangeTab}
                  >
                    {JOBS_PROFILE_MENUS.map((menu) => (
                      <Menu.Item key={menu.key} icon={<menu.IconCPN />}>
                        {t(menu.title)}
                      </Menu.Item>
                    ))}
                  </Menu>
                </div>
              )}
            </JobContainerMobileStyles>
          )}
        </>
      ) : (
        <Layout.Sider
          collapsed={isCollapsedMenu}
          className="position-fixed z-1000"
        >
          <JobsStyles isCollapsedMenu={isCollapsedMenu}>
            <Menu
              className="sider__menu"
              mode="inline"
              onSelect={handleChangeTab}
              selectedKeys={[tabActive] as string[]}
              inlineCollapsed={isCollapsedMenu}
              onMouseEnter={() => setIsCollapsedMenu(false)}
              onMouseLeave={() => setIsCollapsedMenu(true)}
            >
              {JOBS_PROFILE_MENUS.map((menu) => (
                <Menu.Item key={menu.key} icon={<menu.IconCPN />}>
                  {t(menu.title)}
                </Menu.Item>
              ))}
            </Menu>
          </JobsStyles>
        </Layout.Sider>
      )}
      <SuccessfullyModal
        successfullyModal={successfullyModal}
        setSuccessfullyModal={setSuccessfullyModal}
      />
      <UpdateProfileModal
        ref={updateProfileModalRef}
        firstRequirementAllowEdit={firstRequirementAllowEdit}
      />
      <AskAgainModal ref={askAgainModalRef} />
    </>
  );
};

export default JobSidebarMenu;
