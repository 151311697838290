import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';
import {
  ICArrowUp,
  ICB2BBriefcase,
  ICB2BChart,
  ICB2BChartFavorite,
  ICB2BCurrencyCircle,
  ICB2BHandCurrencyCircle,
  ICB2BLockCircle,
  ICB2BMailCircle,
  ICB2BStarCircle,
  ICB2BVolumeCircle,
  ICB2ChartCircle,
  ICB2ChartIndicesCircle,
  ICChart,
  ICCurve,
  ICKindicareLogo,
  ICMailRoundedOutline,
  ICMessage,
  ICMouseLine,
  ICPause,
  ICPlay,
  ICProfileUser,
  ICQuoteGreen,
  ICQuoteOrange,
  ICQuotePink,
  ICUser,
  ICVolume,
  ICVolumeHigh,
} from '@uikit/commons/SVGIcons/icons/svg-b2b';
import {
  ICApplicationOutLine,
  ICBackOutline,
  ICBookATourOrVisitOutLine,
  ICCloseOutline,
  ICEditFilled,
  ICEditOutline,
  ICLogoutFilled,
  ICMessageOutline,
} from './icons/svg-actions';
import {
  ICDownOutline,
  ICLeftLightArrow,
  ICLeftOutline,
  ICLeftSecondOutline,
  ICRightLightArrow,
  ICRightOutline,
  ICRightSpecial,
  ICUpOutline,
} from './icons/svg-arrow';
import {
  ICAbc,
  ICBackToPageOutline,
  ICCalendarOutline,
  ICClockOutline,
  ICCurrencyCircle,
  ICDollarCircleOutline,
  ICGlobeOutline,
  ICKidOutline,
  ICLocationRounded,
  ICPencilOutline,
  ICPhoneOutline,
  ICPopular,
  ICRatingOutline,
  ICShareOutline,
  ICShareSocialOutline,
  ICShieldTickOutline,
} from './icons/svg-centre';
import {
  ICAdditional,
  ICAustraliaFlag,
  ICBack,
  ICBackToTop,
  ICBriefCase,
  ICCheckCircleOutline,
  ICClock,
  ICClose,
  ICCopyOutline,
  ICCurrency,
  ICCurrentLocation,
  ICDevices,
  ICDollarOutline,
  ICDollarSquare,
  ICEducatorDiscount,
  ICExport,
  ICFlashFilled,
  ICGreyInfo,
  ICHeartFilled,
  ICHeartFilledSecond,
  ICHeartOutline,
  ICHeartOutlineSecond,
  ICInfoOutline,
  ICLocationMarker,
  ICLocationPinFilled,
  ICLocationUnderline,
  ICMailFilled,
  ICMapPinFilled,
  ICMoney,
  ICPhotoOutline,
  ICPinkBubbleChat,
  ICPinkChart,
  ICPinkSearch,
  ICPinkStar,
  ICPlayVIdeo,
  ICPromotion,
  ICRightArrow,
  ICRowVertical,
  ICShare,
  ICSliderHorizontal,
  ICStaffDiscount,
  ICStarFilled,
  ICTicked,
  ICVideoOutline,
  ICYellowMagicStar,
  ICYellowStarRating,
  ICKindiCareLogo,
  ICPencilFilled,
  ICEmbed,
  ICMagnifyingGlass,
} from './icons/svg-common';
import {
  ICApplication,
  ICAttactment,
  ICChildOutline,
  ICDanger,
  ICDollarCircleFill,
  ICEmail,
  ICEnquiries,
  ICFileFill,
  ICFileInbox,
  ICFilterMessage,
  ICFlagFill,
  ICGreyMail,
  ICMore,
  ICRefresh,
  ICSend,
  ICVacanciesFill,
} from './icons/svg-enquiries';
import {
  ICAcreageOutline,
  ICBathroomsOutline,
  ICBedroomsOutline,
  ICCarSpacesOutline,
} from './icons/svg-facilities';
import { ICBellOutline, ICMailOutline } from './icons/svg-layouts';
import {
  ICAppleLogo,
  ICAppleSecondLogo,
  ICCopyTwoTone,
  ICFacebookFill,
  ICFacebookLogo,
  ICFacebookTwoTone,
  ICGmailLogo,
  ICGoogleFill,
  ICGoogleLogo,
  ICGooglePlayLogo,
  ICInstagramFill,
  ICLinkedinFill,
  ICMailTwoTone,
  ICTwitterLogo,
  ICYoutubeFill,
} from './icons/svg-social';
import { ICUserCircle, ICUserFill, ICUserPlusFill } from './icons/svg-user';
import { ICListView, ICMapView } from './icons/svg-view-mode';
import {
  ICAddFill,
  ICApplicationFilled,
  ICBabyFill,
  ICBabyPrimaryFill,
  ICBriefcaseFill,
  ICDollarFill,
  ICProfileFill,
  ICShopFill,
} from './icons/svg-profile';
import { ICChildcareServiceType } from './icons/svg-childcare-service-type';
import { ICQuoteUp } from './icons/svg-decors';
import {
  ICAsteriskIcon,
  ICCalendar,
  ICClockJob,
  ICCompany,
  ICDollarEdgeOutline,
  ICEducator,
  ICEmailJob,
  ICEmptyInbox,
  ICGraduationOutline,
  ICGraduatorDiscount,
  ICInfoFilled,
  ICLink,
  ICPaperclip,
  ICPaperPlanRightFilled,
  ICSearch,
  ICTableEventOutline,
  ICTeacher,
  ICTimer,
  ICTimerOutline,
  ICTrashTwoTone,
  ICViewDetailTwoTone,
  ICWallet,
  ICWalletOutline,
} from './icons/svg-job-seekers';
import {
  ICAttachCircle,
  ICAward,
  ICBookFill,
  ICBookmark,
  ICBriefcase,
  ICClipBoard,
  ICCloseFill,
  ICCreateJobProfile,
  ICDeleteFilled,
  ICDocument,
  ICDocumentText,
  ICDot,
  ICEducation,
  ICEmptySearch,
  ICFire,
  ICFireRed,
  ICHealth,
  ICLampCharge,
  ICLanguage,
  ICLocation,
  ICMagicStar,
  ICMessages,
  ICMoon,
  ICNebulas,
  ICNote,
  ICNoteFavorite,
  ICPersonalCard,
  ICPrice,
  ICReturn,
  ICRouting,
  ICSearchNormal,
  ICShieldTick,
  ICSun,
  ICTool,
  ICTrash,
  ICVideo,
} from './icons/svg-jobs';
import {
  ICCertification,
  ICCircle,
  ICExperience,
  ICImmunisations,
  ICMyDocument,
  ICNext,
  ICQualification,
  ICSkill,
} from './icons/svg-application-detail';
import {
  ICArrowLeft,
  ICArrowNext,
  ICArrowPrevious,
  ICArrowRight,
  ICAwardIcon,
  ICAwardTitleIcon,
  ICBook,
  ICBookEducation,
  ICHat,
  ICHatEducation,
  ICLoading,
  ICPointDot,
  ICTickCheck,
  ICUserProfile,
} from './icons/svg-staff-profile';
import { ICHandHeart, ICProfileUserIcon } from './icons/svg-programs-learning';

const IconWrapper = ({ SVGComponent, svgProps, ...props }) => (
  <Icon
    {...props}
    component={() => (
      <SVGComponent {...svgProps} fill={props?.fill || 'none'} />
    )}
  />
);

IconWrapper.propTypes = {
  SVGComponent: PropTypes.any,
  svgProps: PropTypes.object,
};

export const RightOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRightOutline} />
);

export const LeftOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLeftOutline} />
);

export const DownOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDownOutline} />
);

export const UpOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUpOutline} />
);

export const MailOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMailOutline} />
);

export const BellOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBellOutline} />
);

export const FacebookTwoToneIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFacebookTwoTone} />
);

export const MailTwoToneIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMailTwoTone} />
);

export const CopyTwoToneIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCopyTwoTone} />
);

export const FacebookFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFacebookFill} />
);

export const GoogleFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICGoogleFill} />
);

export const LinkedinFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLinkedinFill} />
);

export const YoutubeFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICYoutubeFill} />
);

export const RatingOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRatingOutline} />
);

export const BedroomsOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBedroomsOutline} />
);

export const BathroomsOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBathroomsOutline} />
);

export const CarSpacesOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCarSpacesOutline} />
);

export const AcreageOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAcreageOutline} />
);

export const FacebookLogoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFacebookLogo} />
);

export const GoogleLogoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICGoogleLogo} />
);

export const AppleLogoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAppleLogo} />
);

export const AppleSecondLogoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAppleSecondLogo} />
);

export const GooglePlayLogoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICGooglePlayLogo} />
);

export const CloseOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCloseOutline} />
);

export const BackOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBackOutline} />
);

export const SliderHorizontalIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSliderHorizontal} />
);

export const ListViewIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICListView} />
);

export const MapViewIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMapView} />
);

export const StarFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICStarFilled} />
);

export const GlobeOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICGlobeOutline} />
);

export const CalendarOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCalendarOutline} />
);

export const PhoneOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPhoneOutline} />
);

export const KidOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICKidOutline} />
);

export const ClockOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICClockOutline} />
);

export const PhotoOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPhotoOutline} />
);

export const MapPinFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMapPinFilled} />
);

export const LocationMapFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLocationPinFilled} />
);

export const CurrentLocationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCurrentLocation} />
);

export const ShareOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICShareOutline} />
);

export const ShareSocialOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICShareSocialOutline} />
);

export const BackToPageOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBackToPageOutline} />
);

export const LocationUnderlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLocationUnderline} />
);

export const ChildOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICChildOutline} />
);

export const GmailLogoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICGmailLogo} />
);

export const TwitterLogoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTwitterLogo} />
);

export const CopyOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCopyOutline} />
);

export const InfoOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICInfoOutline} />
);

export const RightSpecialIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRightSpecial} />
);

export const HeartOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICHeartOutline} />
);

export const HeartFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICHeartFilled} />
);

export const InstagramFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICInstagramFill} />
);

export const MailFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMailFilled} />
);

export const MailRoundedOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMailRoundedOutline} />
);

export const ApplicationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICApplication} />
);

export const EnquiriesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEnquiries} />
);

export const FilterMessageIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFilterMessage} />
);

export const RefreshIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRefresh} />
);

export const AttachmentIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAttactment} />
);

export const SendIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSend} />
);

export const EmailIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEmail} />
);

export const PencilOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPencilOutline} />
);

export const HeartFilledSecondIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICHeartFilledSecond} />
);

export const HeartOutlineSecondIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICHeartOutlineSecond} />
);

export const EditOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEditOutline} />
);

export const UserFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUserFill} />
);

export const ProfileFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICProfileFill} />
);

export const AddFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAddFill} />
);

export const BabyFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBabyFill} />
);

export const DollarFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDollarFill} />
);

export const UserPlusFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUserPlusFill} />
);

export const BackIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBack} />
);

export const DangerIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDanger} />
);

export const FileInboxIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFileInbox} />
);

export const GreyMailIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICGreyMail} />
);

export const DollarCircleFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDollarCircleFill} />
);

export const FileFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFileFill} />
);

export const MoreIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMore} />
);

export const VacanciesFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICVacanciesFill} />
);

export const FlagFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFlagFill} />
);

export const CurrencyCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCurrencyCircle} />
);

export const UserCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUserCircle} />
);

export const LeftSecondOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLeftSecondOutline} />
);

export const CheckCircleOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCheckCircleOutline} />
);

export const DollarOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDollarOutline} />
);

export const PromotionTagIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPromotion} />
);

export const ShareIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICShare} />
);

export const CloseIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICClose} />
);

export const GreyInfoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICGreyInfo} />
);

export const CurrencyIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCurrency} />
);
export const ChildcareServiceTypeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICChildcareServiceType} />
);

export const ClockIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICClock} />
);

export const ApplicationOutLineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICApplicationOutLine} />
);

export const MessageIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMessageOutline} />
);

export const BookATourOrVisitOutLineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBookATourOrVisitOutLine} />
);

export const QuoteUpIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICQuoteUp} />
);

export const LeftLightArrowIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLeftLightArrow} />
);

export const RightLightArrowIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRightLightArrow} />
);

export const PlayVideoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPlayVIdeo} />
);

export const EmailJobIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEmailJob} />
);

export const CompanyIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCompany} />
);

export const CalendarIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCalendar} />
);

export const ClockJobIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICClockJob} />
);

export const TeacherIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTeacher} />
);

export const EducatorIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEducator} />
);

export const TimerIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTimer} />
);

export const WalletIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICWallet} />
);
export const SearchNormalIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSearchNormal} />
);

export const BriefcaseIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBriefcase} />
);

export const EducationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEducation} />
);

export const FireIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFire} />
);

export const ClipBoardIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICClipBoard} />
);

export const LanguageIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLanguage} />
);

export const PersonalCardIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPersonalCard} />
);

export const EditFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEditFilled} />
);

export const DollarSquareIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDollarSquare} />
);

export const EducatorDiscountIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEducatorDiscount} />
);

export const BriefCaseIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBriefCase} />
);

export const LocationMarkerIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLocationMarker} />
);

export const DotIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDot} />
);

export const AsteriskIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAsteriskIcon} />
);

export const RoutingIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRouting} />
);

export const AwardIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAward} />
);

export const ShieldTickIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICShieldTick} />
);

export const NebulasIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICNebulas} />
);

export const LampChargeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLampCharge} />
);

export const DocumentTextIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDocumentText} />
);

export const VideoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICVideo} />
);

export const NoteFavoriteIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICNoteFavorite} />
);

export const BookmarkIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBookmark} />
);

export const CloseFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCloseFill} />
);

export const FireRedIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFireRed} />
);

export const StaffDiscountIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICStaffDiscount} />
);

export const MessagesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMessages} />
);

export const DeleteFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDeleteFilled} />
);

export const AttachCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAttachCircle} />
);

export const TrashIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTrash} />
);

export const FlashFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICFlashFilled} />
);

export const MagicStarIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMagicStar} />
);

export const HealthIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICHealth} />
);

export const CreateJobProfileIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCreateJobProfile} />
);

export const TrashTwoToneIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTrashTwoTone} />
);

export const ViewDetailTwoToneIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICViewDetailTwoTone} />
);

export const DollarEdgeOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDollarEdgeOutline} />
);

export const GraduationOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICGraduationOutline} />
);

export const TableEventOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTableEventOutline} />
);

export const GraduatorDiscountIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICGraduatorDiscount} />
);

export const WalletOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICWalletOutline} />
);

export const TimerOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTimerOutline} />
);

export const PaperclipIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPaperclip} />
);

export const PaperPlanRightFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPaperPlanRightFilled} />
);

export const InfoFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICInfoFilled} />
);

export const EmptyInboxIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEmptyInbox} />
);

export const DocumentIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDocument} />
);

export const LinkIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLink} />
);

export const ReturnIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICReturn} />
);

export const EmptySearchIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEmptySearch} />
);

export const MoonIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMoon} />
);

export const SunIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSun} />
);

export const ToolIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTool} />
);

export const PriceIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPrice} />
);

export const NoteIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICNote} />
);

export const LocationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLocation} />
);

export const CircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCircle} />
);

export const QualificationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICQualification} />
);

export const CertificationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCertification} />
);

export const ExperienceIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICExperience} />
);

export const NextIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICNext} />
);

export const MyDocumentIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMyDocument} />
);

export const SkillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSkill} />
);

export const ImmunisationsIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICImmunisations} />
);

export const ShopIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICShopFill} />
);

export const BriefcaseFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBriefcaseFill} />
);

export const BabyPrimaryFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBabyPrimaryFill} />
);

export const ApplicationFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICApplicationFilled} />
);

export const LogoutIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLogoutFilled} />
);

export const SearchIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICSearch} />
);

export const ExportIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICExport} />
);

export const BookFillIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBookFill} />
);

export const ChartIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICChart} />
);

export const VolumeHighIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICVolumeHigh} />
);

export const ProfileUserIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICProfileUser} />
);

export const QuoteGreenIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICQuoteGreen} />
);

export const QuoteOrangeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICQuoteOrange} />
);

export const QuotePinkIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICQuotePink} />
);
export const KindicareLogoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICKindicareLogo} />
);

export const ArrowUpIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICArrowUp} />
);

export const MouseLineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMouseLine} />
);

export const MessageOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMessage} />
);

export const PauseIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPause} />
);

export const PlayIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPlay} />
);

export const ProductUserIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUser} />
);

export const ProductVolumeIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICVolume} />
);

export const B2BBriefcaseIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICB2BBriefcase} />
);

export const B2BChartIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICB2BChart} />
);

export const B2BChartFavoriteIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICB2BChartFavorite} />
);

export const B2BStarCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICB2BStarCircle} />
);

export const B2BMailCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICB2BMailCircle} />
);

export const B2BMailVolumeCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICB2BVolumeCircle} />
);

export const B2BLockCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICB2BLockCircle} />
);

export const B2BChartCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICB2ChartCircle} />
);

export const B2BCurrencyCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICB2BCurrencyCircle} />
);

export const B2BHandCurrencyCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICB2BHandCurrencyCircle} />
);

export const B2BChartIndicesCircleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICB2ChartIndicesCircle} />
);

export const B2BCurveIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICCurve} />
);

export const YellowMagicStarIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICYellowMagicStar} />
);

export const TickedIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTicked} />
);

export const RatingStarIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICYellowStarRating} />
);

export const DevicesIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDevices} />
);

export const RowVerticalIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRowVertical} />
);

export const PinkBubbleChatIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPinkBubbleChat} />
);

export const PinkStarIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPinkStar} />
);

export const PinkSearchIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPinkSearch} />
);

export const AdditionalIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAdditional} />
);

export const PinkChartIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPinkChart} />
);

export const RightArrowIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICRightArrow} />
);

export const AustraliaFlagIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAustraliaFlag} />
);

export const MoneyIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMoney} />
);

export const TickCheckIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICTickCheck} />
);

export const UserIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICUserProfile} />
);

export const BriefCaseUserIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBriefCase} />
);

export const HatUserIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICHat} />
);

export const LoadingIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLoading} />
);

export const ArrowRightIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICArrowRight} />
);

export const BookIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBook} />
);

export const BookEducationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBookEducation} />
);

export const BookEducationPontDotIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPointDot} />
);

export const HatEducationIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICHatEducation} />
);

export const AwardTitleIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAwardTitleIcon} />
);
export const StaffAwardIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAwardIcon} />
);

export const ModalBackIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICArrowLeft} />
);

export const NextProfileIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICArrowNext} />
);

export const PreviousProfileIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICArrowPrevious} />
);

export const PopularIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPopular} />
);

export const ShieldTickOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICShieldTickOutline} />
);

export const AbcIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICAbc} />
);

export const KindiCareLogoIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICKindiCareLogo} />
);

export const PencilFilledIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICPencilFilled} />
);

export const LocationRoundedIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICLocationRounded} />
);

export const DollarCircleOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICDollarCircleOutline} />
);

export const VideoOutlineIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICVideoOutline} />
);

export const BackToTopIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICBackToTop} />
);

export const EmbedIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICEmbed} />
);

export const ProgramHandHeartIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICHandHeart} />
);

export const ProgramProfileIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICProfileUserIcon} />
);

export const MagnifyingGlassIcon = (props) => (
  <IconWrapper {...props} SVGComponent={ICMagnifyingGlass} />
);
