import { createSlice } from '@reduxjs/toolkit';
import { unionBy, isEmpty } from 'lodash-es';
import {
  getCentreDetailConnectButtons,
  getCentreDetailsApplicableButtons,
  getCentreDetailsBasicInfo,
  getCentreDetailsRatings,
  getCentreDetailsReviews,
  getCentreDetailsServices,
  getCentreReviewsSummaries,
  getProgramDetail,
  getPrograms,
  getStaffProfileDetail,
  getStaffProfiles,
  getStaffProfilesForCarousel,
} from './actions';

const initialState = {
  basicInfo: {
    data: null,
    loading: false,
    error: null,
  },
  ratings: {
    data: null,
  },
  services: {
    data: null,
    typeOfCare: '',
  },
  reviews: {
    data: null,
    loading: false,
    total: 0,
  },
  reviewsSummaries: {
    data: null,
  },
  applicableButtons: null,
  connectButtons: {
    buttons: [],
    loading: false,
  },
  staffProfilesCarousel: {
    data: null,
    loading: false,
  },
  staffProfiles: {
    data: null,
    loading: false,
    total: 0,
    page: 1,
  },
  staffProfileDetail: {
    data: null,
    loading: false,
  },
  programs: {
    data: null,
    loading: false,
    total: 0,
    page: 1,
  },
  programCarousel: {
    data: null,
    loading: false,
  },
  programDetail: {
    data: null,
    loading: false,
  },
};

const { reducer } = createSlice({
  reducers: {},
  name: 'centreDetails',
  initialState,
  extraReducers: {
    [getCentreDetailsBasicInfo.pending.toString()]: (state) => {
      state.basicInfo.loading = true;
    },
    [getCentreDetailsBasicInfo.fulfilled.toString()]: (state, { payload }) => {
      state.basicInfo.data = payload;
      state.basicInfo.loading = false;
    },
    [getCentreDetailsBasicInfo.rejected.toString()]: (state, { payload }) => {
      state.basicInfo.error = payload || null;
      state.basicInfo.loading = false;
    },
    [getCentreDetailsRatings.fulfilled.toString()]: (state, { payload }) => {
      state.ratings.data = payload;
    },
    [getCentreDetailsServices.fulfilled.toString()]: (state, { payload }) => {
      state.services.data = payload?.data;
      state.services.typeOfCare = payload?.typeOfCare;
    },
    [getCentreReviewsSummaries.fulfilled.toString()]: (state, { payload }) => {
      state.reviewsSummaries.data = payload;
    },
    [getCentreDetailsReviews.pending.toString()]: (state) => {
      state.reviews.loading = true;
    },
    [getCentreDetailsReviews.fulfilled.toString()]: (state, { payload }) => {
      state.reviews.loading = false;
      state.reviews.total = payload.total;
      if (payload?.params?.offset === 0) {
        state.reviews.data = payload.results;
      }
      state.reviews.data = unionBy(
        [...(state.reviews.data || []), ...(payload.results || [])],
        'id',
      );
    },
    [getCentreDetailsReviews.rejected.toString()]: (state) => {
      state.reviews.loading = false;
    },
    [getCentreDetailsApplicableButtons.fulfilled.toString()]: (
      state,
      { payload },
    ) => {
      state.applicableButtons = payload;
    },
    [getCentreDetailConnectButtons.pending.toString()]: (state) => {
      state.connectButtons.loading = true;
    },
    [getCentreDetailConnectButtons.fulfilled.toString()]: (
      state,
      { payload },
    ) => {
      state.connectButtons.loading = false;
      state.connectButtons = payload;
    },
    [getCentreDetailConnectButtons.rejected.toString()]: (state) => {
      state.connectButtons.loading = false;
    },
    [getStaffProfilesForCarousel.pending.toString()]: (state) => {
      state.staffProfilesCarousel.loading = true;
    },
    [getStaffProfilesForCarousel.fulfilled.toString()]: (
      state,
      { payload },
    ) => {
      state.staffProfilesCarousel.loading = false;
      state.staffProfilesCarousel.data = payload.results;
    },
    [getStaffProfilesForCarousel.rejected.toString()]: (state) => {
      state.staffProfilesCarousel.loading = false;
      state.staffProfilesCarousel.data = [];
    },
    [getStaffProfiles.pending.toString()]: (state) => {
      state.staffProfiles.loading = true;
    },
    [getStaffProfiles.fulfilled.toString()]: (state, { payload }) => {
      const { staffProfiles } = state;
      const { paging, results } = payload;
      staffProfiles.loading = false;
      staffProfiles.total = paging?.total;
      staffProfiles.page = paging?.page;
      if (payload.paging?.page === 1) {
        staffProfiles.data = [...results];
      } else if (!isEmpty(results)) {
        const currentData = staffProfiles.data ?? [];
        staffProfiles.data = [...currentData, ...results];
      }
    },
    [getStaffProfiles.rejected.toString()]: (state) => {
      state.staffProfiles.loading = false;
    },
    [getStaffProfileDetail.pending.toString()]: (state) => {
      state.staffProfileDetail.loading = true;
      state.staffProfileDetail.data = null;
    },
    [getStaffProfileDetail.fulfilled.toString()]: (state, { payload }) => {
      state.staffProfileDetail.loading = false;
      state.staffProfileDetail.data = { ...payload.results[0] };
    },
    [getStaffProfileDetail.rejected.toString()]: (state) => {
      state.staffProfileDetail.loading = false;
      state.staffProfileDetail.data = null;
    },
    [getPrograms.pending.toString()]: (state) => {
      state.programs.loading = true;
    },
    [getPrograms.fulfilled.toString()]: (state, { payload }) => {
      const { programs, programCarousel } = state;
      const { type, paging, results } = payload;
      switch (type) {
        case 'list':
          programs.loading = false;
          programs.total = paging?.total;
          if (payload.paging?.page === 1) {
            programs.data = [...results];
          } else if (!isEmpty(results)) {
            const currentData = programs.data ?? [];
            programs.data = [...currentData, ...results];
          }
          break;
        case 'carousel':
          programCarousel.data = results;
          programCarousel.loading = false;
          break;
        default:
          break;
      }
    },
    [getPrograms.rejected.toString()]: (state) => {
      state.programs.loading = false;
      state.programCarousel.loading = false;
    },
    [getProgramDetail.pending.toString()]: (state) => {
      const { programDetail } = state;
      programDetail.loading = true;
      programDetail.data = null;
    },
    [getProgramDetail.fulfilled.toString()]: (state, { payload }) => {
      const { programDetail } = state;
      const { result } = payload;
      programDetail.loading = false;
      programDetail.data = result;
    },
    [getProgramDetail.rejected.toString()]: (state) => {
      const { programDetail } = state;
      programDetail.loading = false;
    },
  },
});

export default reducer;
