import axios from 'axios';
import { getBaseURLApi, getRefreshToken, richWebConfig, setToken } from 'utils';
import request from 'utils/request';

export async function registerApi(data) {
  return request.post('/auth/register', data);
}

export async function loginApi(data) {
  return request.post('/auth/login', data);
}

export async function requestVerificationApi(data) {
  return request.post('/phone-verification/request-verification', data);
}

export async function loginPhoneNumberApi(data) {
  return request.post('/auth/phone-number', data);
}

export async function loginFacebookApi(data) {
  return request.post('/auth/facebook', data, richWebConfig);
}

export async function loginGoogleApi(data) {
  return request.post('/auth/google', data, richWebConfig);
}

export async function loginAppleApi(data) {
  return request.post('/auth/apple', data, richWebConfig);
}

export async function logoutApi() {
  return request.post('/auth/logout');
}

export async function forgotPasswordApi(data) {
  return request.post('/auth/forgotPassword', data);
}

export async function changePasswordApi(data) {
  return request.put('/auth/me/changePassword', data);
}

export async function resetPasswordApi(data) {
  return request.post('/auth/resetPassword', data);
}

export async function verifyCodeResetPasswordApi(data) {
  return request.post('/auth/verifyPasswordCode', data);
}

export async function resendCodeResetPasswordApi(data) {
  return request.post('/auth/resetPassword/resendCode', data);
}

export async function updatePasswordApi(data) {
  return request.post('/users/password/change', data);
}

export async function verifyPasswordTokenApi(data) {
  return request.post('/users/password/verify', data);
}

export async function getInfoApi() {
  return request.get('/users/me');
}

export async function editUserApi(data) {
  return request.put('/users/me', data);
}

export async function firebaseSignInWithPhoneNumberApi() {
  return {};
}

export async function firebaseConfirmCodeApi() {
  return null;
}

export async function createInstallationApi(params) {
  return request.post('/installations', params);
}

export async function updateInstallationApi(id, params) {
  return request.put(`/installations/${id}`, params);
}

export async function deleteInstallationApi(id) {
  return request.delete(`/installations/${id}`);
}

export async function verifyEmailApi(data) {
  return request.post('/auth/verifyEmail', data);
}

export async function resendVerifyEmailApi(data) {
  return request.post('/auth/resendVerifyEmail', data);
}

export async function refreshTokenApi() {
  try {
    const response = await axios.post(
      '/auth/refreshToken',
      {
        token: getRefreshToken(),
      },
      {
        baseURL: getBaseURLApi(),
      },
    );
    if (response.data?.token) {
      setToken(response.data.token);
      return response.data;
    }
    return null;
  } catch (error) {
    return null;
  }
}

export async function getChildrenOfUserApi() {
  return request.get('/users/me/childrens?limit=100');
}

export async function postChildrenOfUserApi(data) {
  return request.post('/users/me/childrens', data);
}

export async function editChildrenOfUserApi(id, data) {
  return request.put(`/users/me/childrens/${id}`, data);
}
