import React from 'react';
import { ModalProps } from 'antd';
import { ModalWrapper } from '@uikit/commons/ModalCustom/styles';
import { CloseOutlineIcon } from '@uikit/commons/SVGIcons';

interface ModalCustomProps extends ModalProps {
  children: React.ReactNode;
  resetPaddingTopBody?: boolean;
  resetPaddingBottomBody?: boolean;
  isScrollY?: boolean;
  className?: string;
  cancelButtonProps?: Record<string, any>;
}

const ModalCustom = ({
  className,
  isScrollY,
  resetPaddingTopBody,
  resetPaddingBottomBody,
  cancelButtonProps,
  ...props
}: ModalCustomProps) => (
  <ModalWrapper
    className={`${className || ''} ${isScrollY ? 'modal-scroll-y' : ''} ${
      resetPaddingBottomBody ? 'reset-padding-bottom-body' : ''
    } ${resetPaddingTopBody ? 'reset-padding-top-body' : ''}`}
    width={600}
    maskClosable={false}
    destroyOnClose
    closeIcon={<CloseOutlineIcon />}
    cancelButtonProps={{ ...cancelButtonProps, className: 'btn-line-primary' }}
    centered
    {...props}
  />
);
export default ModalCustom;
