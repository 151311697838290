import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { REQUEST_TYPE } from '@redux/crudCreator/dataProvider';
import { richWebConfig } from 'utils';

export const centresRecentlyApi = rtkCreator(
  'centresRecently',
  'centres/recent',
  richWebConfig,
  (builder) => ({
    getAllCentresRecentlyComparison: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_ALL,
        customApiResource: 'centres/recent/comparison',
      }),
    }),
  }),
);

export const {
  useGetAllCentresRecentlyQuery,
  useGetAllCentresRecentlyComparisonQuery,
} = centresRecentlyApi;
