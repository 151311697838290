import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
} from '@redux/crudCreator/utils';
import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash-es';
import { getAllCentresComparison } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
  selectedCentres: [],
  totalSelectedCentres: 0,
  lineHovering: null,
};

const { actions, reducer } = createSlice({
  name: 'centresComparison',
  initialState,
  reducers: {
    saveListSelectedCentres: (state, { payload }) => {
      state.selectedCentres = uniqBy(
        [...state.selectedCentres, ...payload?.data],
        'id',
      );
      state.totalSelectedCentres = payload?.total;
    },
    deleteCentreInComparisonList: (state, { payload }) => {
      state.data = state.data?.filter((item) => item?.id !== payload);
      state.selectedCentres = state.selectedCentres?.filter(
        (item) => item?.id !== payload,
      );
      state.totalSelectedCentres -= 1;
    },
    clearAllCentreItemInComparisonList: (state) => {
      state.data = [];
      state.selectedCentres = [];
      state.totalSelectedCentres = 0;
    },
    addCentreItemInComparisonList: (state, { payload }) => {
      state.data = [payload, ...state.data];
      state.selectedCentres = [payload, ...state.selectedCentres];
      state.totalSelectedCentres += 1;
    },
    setCompareLineHovering: (state, { payload }) => {
      state.lineHovering = payload;
    },
  },
  extraReducers: {
    [getAllCentresComparison.pending]: getAllCustomPending,
    [getAllCentresComparison.fulfilled]: getAllCustomSuccess,
    [getAllCentresComparison.rejected]: getAllCustomFail,
  },
});

export const {
  deleteCentreInComparisonList,
  clearAllCentreItemInComparisonList,
  addCentreItemInComparisonList,
  saveListSelectedCentres,
  setCompareLineHovering,
} = actions;

export default reducer;
