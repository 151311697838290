import styled from 'styled-components';

const AuthModalStyles = styled.div`
  width: 482px;
  max-width: 100%;
  margin: auto;
  padding: 20px 0;
  font-size: 16px;
  .auth {
    &__logo {
      width: 100px;
      margin-bottom: 20px;
    }
    &__title {
      text-align: center;
      font-weight: bold;
      font-size: 40px;
      line-height: 40px;
      margin-bottom: 30px;
    }
    &__question {
      font-size: 16px;
      line-height: 24px;
      color: var(--color-grey-l2);
      text-align: center;
      margin-top: 20px;
      b {
        color: var(--primary);
        cursor: pointer;
      }
    }
    &__description {
      color: var(--text-secondary);
    }
    &__resend-code-btn {
      display: flex;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      height: unset !important;
      width: unset !important;
      color: var(--primary) !important;
    }
  }

  .footer-section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .timer-text {
      color: var(--primary);
      font-weight: 600;
    }

    .ant-btn {
      width: auto !important;
      padding-left: 10px;
      padding-right: 10px;
    }

    & > * {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .submit-btn {
    margin-top: 20px;
  }

  .forgot-password {
    font-weight: 500;
  }

  .ant-btn {
    width: 100%;
  }

  .auth-actions {
    margin: 23px 0 20px 0;
  }
`;

export default AuthModalStyles;
