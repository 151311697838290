import { omit, isEmpty } from 'lodash-es';
import qs from 'qs';
import { getValidDataOfObj, removeEmptyObject } from 'utils/tools';

export const PRIMARY_KEY = 'id';

export const REQUEST_TYPE = {
  GET_ALL: 'GET_ALL',
  GET_BY_ID: 'GET_BY_ID',
  EDIT: 'EDIT',
  PATCH: 'PATCH',
  CREATE: 'CREATE',
  DELETE: 'DELETE',
};

const convertQueryString = (params) =>
  Object.keys(params)
    .map(
      (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`,
    )
    .join('&');

const convertJoin = (join = []) => join.map((key) => `join=${key}`).join('&');

const formatOrderBy = (isDefaultOrderBy = true, orderBy) => {
  if (orderBy) return orderBy;

  if (isDefaultOrderBy) return '-createdAt';

  return undefined;
};

export const convertRequestParams = (type, params, options = {}) => {
  switch (type) {
    case 'GET_ALL': {
      if (options?.isParamsForAPIV2) {
        return qs.stringify(params, {
          addQueryPrefix: true,
          arrayFormat: 'brackets',
          skipNulls: true,
        });
      }
      const { filter, join = [], orderBy, ...restParams } = params || {};

      const formattedParams = options?.isNotCheckValidParams
        ? {
            ...restParams,
            orderBy: formatOrderBy(options?.isDefaultOrderBy, orderBy),
          }
        : getValidDataOfObj({
            data: {
              ...restParams,
              count: undefined,
              orderBy: formatOrderBy(options?.isDefaultOrderBy, orderBy),
              perPage: undefined,
              page: undefined,
            },
          });

      const validFilter = removeEmptyObject(
        getValidDataOfObj({
          data: typeof filter === 'string' ? JSON.parse(filter) : filter,
        }),
      );

      return `?${convertQueryString(formattedParams)}${
        !isEmpty(validFilter) ? `&filter=${JSON.stringify(validFilter)}` : ''
      }${!isEmpty(join) ? `&${convertJoin(join)}` : ''}`;
    }

    case 'GET_BY_ID':
      return options?.extraParams || {};

    case 'EDIT':
      return params;
    case 'PATCH':
      return getValidDataOfObj({
        data: omit(params, 'id'),
        isCreateEdit: true,
        isTrimStr: options.isTrimStr,
      });

    case 'CREATE':
      return getValidDataOfObj({
        data: params,
        isCreateEdit: true,
        isTrimStr: options.isTrimStr,
      });

    case 'DELETE':
    default:
      return {};
  }
};
