import ModalCustom from '@uikit/commons/ModalCustom';
import styled from 'styled-components';

export const ApplyApplicationModalStyles = styled(ModalCustom)`
  .ant-modal-header {
    padding: 24px 16px;
  }
  .ant-modal-content {
    border-radius: 20px;
  }
  .ant-modal-body {
    padding: 64px;
  }

  .ant-modal-footer {
    padding: 16px 64px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    .ant-btn {
      padding: 14px 41px;
      display: flex;
      align-items: center;
    }
  }

  .modal-title {
    position: relative;
  }

  .back-step {
    position: absolute;
    top: -50%;
    left: 70px;
    border: 1px solid #ebecf0;
  }

  .form-list__content {
    padding: 0 !important;
  }
  @media only screen and (max-width: 768px) {
    .ant-modal-body {
      padding: 30px;
    }
  }

  @media only screen and (max-width: 900px) {
    .ant-modal-footer {
      text-align: center;
      .step4-footer {
        justify-content: center;
        .ant-btn {
          margin-top: 8px;
        }
      }
    }
  }

  @media only screen and (max-width: 576px) {
    .ant-modal-body {
      padding: 20px 30px;
    }

    .ant-modal-footer {
      padding: 16px 30px;
      .ant-btn {
        padding: 14px 20px;
      }
    }

    .back-step {
      position: absolute;
      top: -50%;
      left: 14px;
      border: 1px solid #ebecf0;
    }

    .modal-title {
      font-size: 22px;
    }
  }
`;
