import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getStatisticsElectionPollApi,
  voteElectionPollSubsidyApi,
} from 'api/pollVotes';

export const voteElectionPollSubsidy = createAsyncThunk(
  'pollVotes/voteElectionPollSubsidy',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccess: true,
          isShowLoading: false,
          ...payload?.options,
        },
        voteElectionPollSubsidyApi,
        payload.data,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);

export const getStatisticsElectionPoll = createAsyncThunk(
  'pollVotes/getStatisticsElectionPoll',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper({}, getStatisticsElectionPollApi);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  },
);
