import PropTypes from 'prop-types';
import styled from 'styled-components';
import StepTitle from '@uikit/commons/StepTitle';

const ContentSteps = ({
  icon,
  required,
  title,
  children,
  description,
  className,
}) => (
  <ContentStepsStyles className="application-step__content-wrapper">
    <StepTitle
      icon={icon}
      title={title}
      required={required}
      description={description}
    />
    <div className={`application-step_content ${className || ''}`}>
      {children}
    </div>
  </ContentStepsStyles>
);

ContentSteps.propTypes = {
  title: PropTypes.string,
  required: PropTypes.bool,
  icon: PropTypes.node,
  description: PropTypes.string,
  className: PropTypes.string,
};

export default ContentSteps;

const ContentStepsStyles = styled.div`
  .application-step_content {
    padding: 20px 64px 0 64px;
  }
  @media only screen and (max-width: 576px) {
    .application-step_content {
      padding: 16px 0 0 0;
    }
  }
`;
