import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfig } from 'utils';

export const crmPackagesApi = rtkCreator(
  'crmPackages',
  'crm-packages',
  richWebConfig,
);

export const { useGetAllCrmPackagesQuery } = crmPackagesApi;
