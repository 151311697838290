import styled from 'styled-components';

export const JobContainerMobileStyles = styled.div`
  --height-bottom-bar: 76px;
  --primaryColor: ${({ primaryColor }) => primaryColor || 'var(--bg-green)'};

  display: flex;
  flex-direction: column;

  .ant-badge {
    .anticon {
      font-size: 16px;
      color: var(--text-grey-l1);
    }
  }
  .job-m {
    &__body {
      flex-grow: 1;
      overflow-y: auto;
      overflow-x: hidden;
    }

    &__bottom-navigate {
      border-top: 1px solid var(--border-light-gray);
      position: fixed;
      bottom: 0;
      width: 100%;
      z-index: 1000;

      .ant-menu-item {
        &:hover,
        &:focus {
          color: var(--text-grey-l1);
        }
      }

      .ant-menu {
        .ant-menu-item-selected {
          color: var(--primaryColor);
          .ant-badge {
            .anticon {
              color: var(--primaryColor);
            }
          }
        }

        &-item {
          width: 20%;
          height: var(--height-bottom-bar);
          display: flex;
          flex-direction: column;
          gap: 4px;
          align-items: center;
          justify-content: center;
          color: var(--text-grey-l1);

          &::after {
            display: none;
          }

          .anticon {
            font-size: 24px;
          }
        }

        &-title-content {
          margin: 0;
          line-height: initial;
          font-size: 10px;
          font-weight: 700;
        }
      }
    }
  }
  .ant-layout-content {
    margin-right: 0;
  }
`;
