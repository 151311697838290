import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FeatureTitleIconStyles } from './styles';

const FeatureTitleIcon = ({
  IconCPN,
  title,
  className,
  backgroundColor,
  isShowEmbed,
}) => {
  const { t } = useTranslation();
  return (
    <FeatureTitleIconStyles
      isShowEmbed={isShowEmbed}
      backgroundColor={backgroundColor}
      className={className || ''}
    >
      {IconCPN && <IconCPN className="feature-title-icon__icon size-20" />}
      <span className="feature-title-icon__name">{t(title)}</span>
    </FeatureTitleIconStyles>
  );
};

FeatureTitleIcon.propTypes = {
  IconCPN: PropTypes.any,
  title: PropTypes.string,
  className: PropTypes.string,
  isShowEmbed: PropTypes.bool,
  backgroundColor: PropTypes.string,
};

export default FeatureTitleIcon;
