import { useDispatch } from 'react-redux';
import { setModalConfig } from '@redux/config/actions';
import { Button, Dropdown, Menu, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  BabyPrimaryFillIcon,
  BriefcaseFillIcon,
  GreyMailIcon,
  ShopIcon,
} from '@uikit/commons/SVGIcons';
import { CLAIM_CENTRE_PATH, LOGIN_BY_ROLE } from 'configs/constants';
import { setLoginByRole } from '@redux/auth/slice';
import useWindowSize from 'hooks/useWindowSize';
import { useState } from 'react';
import LoginOptionsModal from '@uikit/modals/LoginOptionsModal';
import { GroupActionsHeaderStyles, LoginMenuStyled, TagStyle } from './styles';

interface Props {
  direction: 'horizontal' | 'vertical';
  size: number;
  handleCancelDrawer: () => void;
}

const MAX_WIDTH_LOGIN_OPTION_POPUP = 327;

const GroupActionsHeader = ({
  direction = 'horizontal',
  size = 10,
  handleCancelDrawer,
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isOpenLoginOptions, setIsOpenLoginOptions] = useState<boolean>(false);

  const { isMobile } = useWindowSize();

  const sizeByMobileView = isMobile ? 'large' : 'middle';

  const handleClickLogin = (loginByRole, isMobile?) => {
    dispatch(
      setModalConfig({
        loginModal: true,
      }),
    );
    dispatch(setLoginByRole(loginByRole));

    isMobile && handleCancelDrawer();
  };

  const handleCancelOptionModal = () => {
    setIsOpenLoginOptions(false);
  };

  const menu = (
    <LoginMenuStyled>
      <Menu.Item
        key={1}
        className="menu-item-1"
        onClick={() => handleClickLogin(LOGIN_BY_ROLE.parent)}
        icon={<BabyPrimaryFillIcon />}
      >
        {t('menu.asAParent')}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={2} className="menu-item-2" icon={<ShopIcon />}>
        <a href={`${process.env.NEXT_PUBLIC_CRM_WEB_URL}/login`}>
          {t('menu.asAChildcareProvider')}
        </a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item
        key={3}
        className="menu-item-2"
        onClick={() => handleClickLogin(LOGIN_BY_ROLE.jobSeeker)}
        icon={<BriefcaseFillIcon />}
      >
        {t('menu.asAJobSeeker')}
        <TagStyle className="ml-8 rounded-8 border-none size-14 fw-700 p-4">
          {t('common.new')}!
        </TagStyle>
      </Menu.Item>
    </LoginMenuStyled>
  );

  const onClickMailBox = () => {
    dispatch(
      setModalConfig({
        loginModal: true,
      }),
    );
  };

  return (
    <GroupActionsHeaderStyles>
      <Space direction={direction} size={size}>
        {!isMobile && (
          <div className="mail-box">
            <GreyMailIcon onClick={onClickMailBox} />
          </div>
        )}

        <Button
          className="btn-second-primary"
          href={CLAIM_CENTRE_PATH}
          size={sizeByMobileView}
        >
          {t('button.claimYourCentreForFree')}
        </Button>
        <Dropdown
          overlay={menu}
          trigger={isMobile ? [] : ['click']}
          placement="bottomRight"
          className="btn-login"
          overlayStyle={{
            position: 'fixed',
          }}
        >
          <Button
            type="primary"
            size={sizeByMobileView}
            onClick={() => {
              if (isMobile) {
                setIsOpenLoginOptions(true);
              }
            }}
          >
            {t('button.logIn')}
          </Button>
        </Dropdown>
      </Space>
      <LoginOptionsModal
        visible={isOpenLoginOptions}
        onCancel={handleCancelOptionModal}
        footer={null}
        title={t('button.logIn')}
        isMobile={isMobile}
        handleCancelDrawer={handleCancelDrawer}
        setIsOpenLoginOptions={setIsOpenLoginOptions}
        width={MAX_WIDTH_LOGIN_OPTION_POPUP}
      />
    </GroupActionsHeaderStyles>
  );
};

export default GroupActionsHeader;
