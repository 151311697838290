import { DATE_FORMAT, MONTH_DATE_YEAR_INPUT } from 'configs/constants';
import dayjs from 'utils/dayUtils';

export const formatDate = (date: string | Date, formatType = DATE_FORMAT) =>
  date ? dayjs(date).format(formatType) : null;

export const round10 = (number: number) => Math.round(number * 10) / 10;
export const roundHalf = (number: number) =>
  number ? Math.round(number * 2) / 2 : 0;

export const formatWorkingTime = (time: string) => ({
  monday: {
    isOpen: !!time?.includes('workingTimeOnWeek'),
    openTime: 0,
    closeTime: 0,
  },
  tuesday: {
    isOpen: !!time?.includes('workingTimeOnWeek'),
    openTime: 0,
    closeTime: 0,
  },
  wednesday: {
    isOpen: !!time?.includes('workingTimeOnWeek'),
    openTime: 0,
    closeTime: 0,
  },
  thursday: {
    isOpen: !!time?.includes('workingTimeOnWeek'),
    openTime: 0,
    closeTime: 0,
  },
  friday: {
    isOpen: !!time?.includes('workingTimeOnWeek'),
    openTime: 0,
    closeTime: 0,
  },
  saturday: {
    isOpen: !!time?.includes('saturday'),
    openTime: 0,
    closeTime: 0,
  },
  sunday: {
    isOpen: !!time?.includes('sunday'),
    openTime: 0,
    closeTime: 0,
  },
});

export const formatWorkingTimeDisplay = (time = {}) => {
  const output = [];
  Object.keys(time).forEach((key) => {
    if (time[key].isOpen && ['sunday', 'saturday'].includes(key)) {
      output.push(key);
    }
    if (time[key].isOpen && key === 'monday') {
      output.push('workingTimeOnWeek');
    }
  });
  return output;
};

export const formatterInputNumber = (value: string) =>
  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const parserInputNumber = (value: string) =>
  value ? value.replace(/\$\s?|(,*)/g, '') : '';

export const inputNumberFormatter = {
  formatter: formatterInputNumber,
  parser: parserInputNumber,
};

export const formatDateWithSuffix = (d: number) => {
  if (d > 3 && d < 21) return `${d}th`;
  switch (d % 10) {
    case 1:
      return `${d}st`;
    case 2:
      return `${d}nd`;
    case 3:
      return `${d}rd`;
    default:
      return `${d}th`;
  }
};

export const formatNewDate = (date: string) => {
  const newDate = new Date(date);
  const month = formatDate(date, 'D MMM YYYY')?.split(' ')?.[1];
  const day = formatDateWithSuffix(newDate.getDate());
  return `${month} ${day}`;
};

export const inputNumberFormatterWithCurrency = {
  formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: parserInputNumber,
};

export const formatJobDate = (date: string) => {
  if (date) {
    return formatDate(date, MONTH_DATE_YEAR_INPUT);
  }
  return null;
};

export const getFileName = (link: string) => {
  if (!link) {
    return null;
  }
  const fileType = link?.split('.')?.pop();
  const index = link?.lastIndexOf('-');
  const fileName = `${link.slice(0, index).split('/')?.pop()}.${fileType}`;

  return fileName;
};

export const formatTextSearch = (text: string, type: string) =>
  text ? text?.replace(`- ${type}`, '') : '';

export const formatDateTimeMailbox = (date: string) => {
  if (date) {
    const isToday = dayjs(date).isToday();
    if (isToday) {
      return dayjs(date).format('HH:mm');
    }
    const newDate = new Date(date);
    const month = formatDate(date, 'D MMM YYYY')?.split(' ')?.[1];
    const day = formatDateWithSuffix(newDate.getDate());
    return `${month} ${day}`;
  }
  return null;
};
