import React from 'react';
import ViewAllCard from 'components/forJobSeekers/CardItems/ViewAllCard';
import { ReviewsItem } from 'types/parentReview';
import { CarouselReviewStyle } from './styles';
import ReviewItem from './ReviewItem';

interface Props {
  item?: ReviewsItem;
  handleShowViewAllReview?: (value?: string) => void;
  isShowViewAllCard?: boolean;
}

const ParentReviewCard = ({
  item,
  handleShowViewAllReview,
  isShowViewAllCard,
}: Props) => (
  <div className="h-full">
    {isShowViewAllCard ? (
      <>
        <ViewAllCard
          primaryColor="var(--primary)"
          onClick={() => handleShowViewAllReview()}
        />
      </>
    ) : (
      <CarouselReviewStyle
        onClick={() => {
          handleShowViewAllReview(item?.id);
        }}
        className="h-full"
      >
        <ReviewItem item={item} isShowFullReview={false} />
      </CarouselReviewStyle>
    )}
  </div>
);

export default ParentReviewCard;
