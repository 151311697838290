import { notification } from 'antd';
import { isEmpty } from 'lodash-es';
import { i18n } from 'next-i18next';

export const showNotifyError = (description) => {
  notification.error({
    message: i18n.t('error.title'),
    description: i18n.t(description),
  });
};

export const handleRequestError = (error) => {
  process.browser &&
    notification.error({
      message: i18n.t('error.title'),
      description: error.message || error || i18n.t('error.description'),
    });
};

export const handleApiError = (error, form, name, nameInForm) => {
  if (form && !isEmpty(error?.errors)) {
    const fieldData = Object.entries(error?.errors).map(([key, value]) => ({
      name: key === name ? nameInForm : key,
      errors: [value],
    }));
    form.setFields(fieldData);
    form.scrollToField(fieldData[0]?.name);
    form.getFieldInstance(fieldData[0]?.name)?.focus &&
      form.getFieldInstance(fieldData[0].name).focus();
  }
};
