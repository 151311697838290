import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateOfBirthSubmit } from 'utils/dataUtils';
import { setDataApply } from '@redux/applyApplicationStep/slice';
import { updateCurrentUser } from '@redux/auth/actions';

const Step1Footer = ({ form, onNextStep }) => {
  const { t } = useTranslation();
  const loading = useSelector((state) => state.auth.loadingUser);
  const dispatch = useDispatch();

  const onSubmit = () => {
    form
      .validateFields()
      .then(async ({ email, ...values }) => {
        const dataSubmit = {
          ...values,
          dateOfBirth: formatDateOfBirthSubmit(values?.dateOfBirth),
        };

        await dispatch(
          updateCurrentUser({
            data: dataSubmit,
            options: {
              isShowSuccess: false,
            },
          }),
        );
        onNextStep();
        dispatch(setDataApply({ parentInfo: { ...dataSubmit, email } }));
      })
      .catch(() => null);
  };

  return (
    <div className="flex-center-end">
      <Button type="primary" onClick={onSubmit} loading={loading}>
        {t('applyApplications.nextStep')}
      </Button>
    </div>
  );
};

Step1Footer.propTypes = {
  form: PropTypes.object,
  onNextStep: PropTypes.func,
};

export default Step1Footer;
