import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getFavoriteListApi } from 'api/centres';

export const MODEL_NAME = 'centresFavourites';

export const getFavouriteList = createAsyncThunk(
  `${MODEL_NAME}/favoriteList`,
  async (payload = {}, thunkApi) => {
    try {
      const response = await apiWrapper({}, getFavoriteListApi, payload);
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.data.id, data: error });
    }
  },
);
