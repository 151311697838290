import { forgotPassword, verifyEmail } from '@redux/auth/actions';
import { setModalConfig } from '@redux/config/actions';
import ModalCustom from '@uikit/commons/ModalCustom';
import { Button, Form, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleApiError } from 'utils/requestErrorUtils';
import { getDeviceId } from 'utils/tools';
import AuthModalStyles from '@uikit/modals/AuthModal/styles';
import VerifyCodeForm from './VerifyCodeForm';

const VerifyEmailModal = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const verifyEmailModalVisible = useSelector(
    (state) => state.config.modals.verifyEmailModal,
  );
  const emailVerify = useSelector((state) => state.config.emailVerify);

  const [loading, setLoading] = useState(false);
  const [visibleOtpInput, setVisibleOtpInput] = useState(false);

  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(setModalConfig({ verifyEmailModal: false }));
    form.resetFields();
  };

  const onFinish = async (values) => {
    setLoading(true);
    const response = await dispatch(
      verifyEmail({
        email: emailVerify,
        deviceId: getDeviceId(),
        ...values,
      }),
    );

    setLoading(false);

    if (response?.payload?.success) {
      onCancel();
    } else {
      handleApiError(response?.payload, form);
    }
  };

  useEffect(() => {
    if (verifyEmailModalVisible) {
      setTimeout(() => {
        setVisibleOtpInput(true);
      }, 600);
    } else {
      setVisibleOtpInput(false);
    }
  }, [verifyEmailModalVisible]);

  return (
    <ModalCustom
      visible={verifyEmailModalVisible}
      onCancel={onCancel}
      footer={null}
    >
      <AuthModalStyles>
        <div className="auth__header mb-30">
          <div className="auth__title">{t('auth.verifyEmail')}</div>
          <div className="auth__description">{t('auth.verifyEmailDesc')}</div>
        </div>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          {visibleOtpInput ? (
            <VerifyCodeForm
              emailVerify={emailVerify}
              resendCodeAction={forgotPassword}
            />
          ) : (
            <Skeleton />
          )}

          <Button
            className="submit-btn"
            type="primary"
            htmlType="submit"
            loading={loading}
            size="large"
          >
            {t('button.continue')}
          </Button>
        </Form>
      </AuthModalStyles>
    </ModalCustom>
  );
};

export default VerifyEmailModal;
