import React, { useEffect, useRef, useState } from 'react';
import { JobsStyles } from 'components/jobs/styles';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import {
  PARENT_SIDEBAR_MENUS,
  PARENT_SIDEBAR_MENUS_CONST,
} from 'configs/layout';
import { Badge, Layout, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getIsAuthenticated } from '@redux/auth/selectors';
import { getTotalUnread } from '@redux/inboxes/selectors';
import {
  CENTRE_APP_SETTING_CONST,
  CENTRE_DETAIL_PATH,
  DEFAULT_INBOX_ID,
} from 'configs/constants';
import useWindowSize from 'hooks/useWindowSize';
import { JobContainerMobileStyles } from 'components/mobile/jobs/styles';
import styled from 'styled-components';
import RequiredAuthButton from 'components/rest/RequiredAuthButton';
import { EVENT_TRACKING } from 'configs/eventTracking';
import { trackingEvent } from '@redux/eventTracking/actions';
import ApplyApplicationModal from 'components/applyApplication/ApplyApplicationModal';
import MakeEnquiryModal from 'components/makeEnquiry/MakeEnquiryModal';
import {
  getCentreApplicableButtonsSelector,
  getCentreDetailsBasicInfoSelector,
} from '@redux/centreDetails/selectors';
import { getCustomizeLinkBtn } from 'utils/dataUtils';

const ServiceButtonGroupStyles = styled.div`
  border-top: 1px solid var(--border-light-gray);
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  padding: 16px 20px;
  background: var(--text-white);

  .ant-btn {
    font-weight: 700;
  }

  .ant-btn-default:not([disabled]) {
    border-color: var(--primary);
    color: var(--primary);
  }
`;

interface ModalRef {
  open: () => void;
}

const ParentSidebarMenu = () => {
  const { t } = useTranslation();
  const { push, pathname } = useRouter();
  const dispatch = useDispatch();
  const enquiryModalRef = useRef<ModalRef>();
  const applicationModalRef = useRef<ModalRef>();
  const [tabActive, setTabActive] = useState(
    pathname || PARENT_SIDEBAR_MENUS_CONST.searchForChildcare.key,
  );
  const [isCollapsedMenu, setIsCollapsedMenu] = useState(true);

  const { data: centre } = useSelector(getCentreDetailsBasicInfoSelector);
  const btnApps = useSelector(getCentreApplicableButtonsSelector);

  const totalUnreadMess = useSelector(getTotalUnread);
  const isAuth = useSelector(getIsAuthenticated);

  const { isMobile } = useWindowSize();

  const applyUrl: string = getCustomizeLinkBtn(
    btnApps,
    CENTRE_APP_SETTING_CONST.applyButtonUrl,
  );

  const bookingUrl: string = getCustomizeLinkBtn(
    btnApps,
    CENTRE_APP_SETTING_CONST.bookATourOrVisitUrl,
  );

  const handleChangeTab = (e) => {
    setTabActive(e?.key);
    if (e.key === PARENT_SIDEBAR_MENUS_CONST.mailbox.key) {
      if (isMobile) {
        return push(`/m/mailbox/`);
      }
      if (isAuth) {
        return push(`/mailbox/${DEFAULT_INBOX_ID}`);
      }
      return push('/mailbox');
    }
    return push(e?.key);
  };

  const renderMenuIcon = (menu) => {
    if (
      menu?.title === PARENT_SIDEBAR_MENUS_CONST.mailbox.title &&
      totalUnreadMess &&
      isAuth
    ) {
      return (
        <Badge className="mr-5" count={totalUnreadMess}>
          {<menu.IconCPN />}
        </Badge>
      );
    }
    return <menu.IconCPN />;
  };

  useEffect(() => setTabActive(pathname), [pathname]);

  const handleOpenLink = (key, eventName, eventUrlKey = 'url') => {
    const url = getCustomizeLinkBtn(btnApps, key);

    if (url && window) {
      eventName &&
        dispatch(
          trackingEvent({
            eventName,
            eventData: { centreId: centre?.id, [eventUrlKey]: url },
          }),
        );
      window.open(url);
    }
  };
  const openEnquiryModal = () => {
    enquiryModalRef.current.open();
    dispatch(
      trackingEvent({
        eventName: EVENT_TRACKING.enquiryButtonClicked,
        eventData: { centreId: centre?.id },
      }),
    );
  };
  const handleOpenApplication = () => {
    const applyBtnUrlKey = CENTRE_APP_SETTING_CONST.applyButtonUrl;
    if (getCustomizeLinkBtn(btnApps, applyBtnUrlKey)) {
      handleOpenLink(
        applyBtnUrlKey,
        EVENT_TRACKING.customApplyClicked,
        'applyUrl',
      );
    } else {
      applicationModalRef.current.open();
      dispatch(
        trackingEvent({
          eventName: EVENT_TRACKING.applyButtonClicked,
          eventData: { centreId: centre?.id },
        }),
      );
    }
  };

  const handleBookATourOrVisit = () =>
    handleOpenLink(
      CENTRE_APP_SETTING_CONST.bookATourOrVisitUrl,
      EVENT_TRACKING.customBookingClicked,
      'bookingUrl',
    );

  const isShowBookTourBtn = !!(
    btnApps?.isAllowedBookingTour &&
    getCustomizeLinkBtn(btnApps, CENTRE_APP_SETTING_CONST.bookATourOrVisitUrl)
  );

  return (
    <>
      {isMobile ? (
        <JobContainerMobileStyles primaryColor="var(--primary)">
          {pathname === CENTRE_DETAIL_PATH ? (
            <>
              <ServiceButtonGroupStyles>
                <div className="d-flex justify-content-between">
                  <RequiredAuthButton
                    className="mr-8"
                    onClick={openEnquiryModal}
                    block
                    disabled={!btnApps?.isAllowedEnquiries}
                    isAllowedToEventTrack
                    eventTrackPayload={{
                      eventName: EVENT_TRACKING.enquiryButtonClicked,
                      eventData: { centreId: centre?.id },
                    }}
                  >
                    {t('button.makeEnquiry')}
                  </RequiredAuthButton>
                  <RequiredAuthButton
                    type="primary"
                    block
                    onClick={handleOpenApplication}
                    disabled={!btnApps?.isAllowedApplications}
                    isAllowedToEventTrack
                    eventTrackPayload={
                      applyUrl
                        ? {
                            eventName:
                              EVENT_TRACKING.unauthorisedCustomApplyClicked,
                            eventData: { centreId: centre?.id, applyUrl },
                          }
                        : {
                            eventName: EVENT_TRACKING.applyButtonClicked,
                            eventData: { centreId: centre?.id },
                          }
                    }
                  >
                    {t('button.apply')}
                  </RequiredAuthButton>
                </div>
                {isShowBookTourBtn && (
                  <div className="mt-8">
                    <RequiredAuthButton
                      block
                      onClick={handleBookATourOrVisit}
                      isAllowedToEventTrack
                      eventTrackPayload={{
                        eventName:
                          EVENT_TRACKING.unauthorisedCustomBookingClicked,
                        eventData: { centreId: centre?.id, bookingUrl },
                      }}
                    >
                      {t('button.bookATourOrVisit')}
                    </RequiredAuthButton>
                  </div>
                )}
              </ServiceButtonGroupStyles>

              <MakeEnquiryModal ref={enquiryModalRef} />
              <ApplyApplicationModal ref={applicationModalRef} />
            </>
          ) : (
            <div className="job-m__bottom-navigate">
              <Menu
                mode="horizontal"
                selectedKeys={[tabActive as string]}
                onSelect={handleChangeTab}
              >
                {PARENT_SIDEBAR_MENUS.map((menu) => (
                  <Menu.Item key={menu.key} icon={renderMenuIcon(menu)}>
                    {t(menu.title)}
                  </Menu.Item>
                ))}
              </Menu>
            </div>
          )}
        </JobContainerMobileStyles>
      ) : (
        <Layout.Sider
          collapsed={isCollapsedMenu}
          className="position-fixed z-1000"
        >
          <JobsStyles
            primaryColor="var(--primary)"
            secondaryColor="var(--bg-pastel-pink)"
            isCollapsedMenu={isCollapsedMenu}
          >
            <Menu
              className="sider__menu"
              mode="inline"
              onSelect={handleChangeTab}
              selectedKeys={[pathname as string]}
              inlineCollapsed={isCollapsedMenu}
              onMouseEnter={() => setIsCollapsedMenu(false)}
              onMouseLeave={() => setIsCollapsedMenu(true)}
            >
              {PARENT_SIDEBAR_MENUS.map((menu) => (
                <Menu.Item key={menu.key} icon={renderMenuIcon(menu)}>
                  {t(menu.title)}
                </Menu.Item>
              ))}
            </Menu>
          </JobsStyles>
        </Layout.Sider>
      )}
    </>
  );
};

export default ParentSidebarMenu;
