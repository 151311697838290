import { useEffect, useState } from 'react';
import { GoogleLogin } from 'react-google-login';
import { Button, notification } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { loginWithGoogle } from '@redux/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { GoogleLogoIcon } from '@uikit/commons/SVGIcons';
import { getDeviceId } from 'utils/tools';
import { getUrlAfterLogin } from 'utils/dataUtils';
import { useRouter } from 'next/router';

const LoginGoogleBtn = ({ onCancel }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const loginByRole = useSelector((state) => state.auth.loginByRole);
  const { push, asPath } = useRouter();
  const { fetchedUserMe } = useSelector((state) => state.configLocal);
  const [isFinished, setIsFinished] = useState(false);

  const onLoginGoogleSuccess = async (responseGoogle) => {
    setLoadingGoogle(true);
    const response = await dispatch(
      loginWithGoogle({
        access_token: responseGoogle.tokenId,
        deviceId: getDeviceId(),
      }),
    );

    setLoadingGoogle(false);

    if (response?.payload?.token) {
      onCancel();
      setIsFinished(true);
    }
  };

  useEffect(() => {
    if (fetchedUserMe && isFinished) {
      const redirectUrl = getUrlAfterLogin(loginByRole, asPath);
      setIsFinished(false);
      if (redirectUrl) {
        push(redirectUrl);
      }
    }
  }, [fetchedUserMe, isFinished, push, loginByRole, asPath]);

  const onFailure = (payload) => {
    notification.error({
      message: t('error.title'),
      description: payload.details || t('error.description'),
    });
  };

  return (
    <GoogleLogin
      clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
      render={(renderProps) => (
        <Button
          className="auth-actions__google"
          icon={<GoogleLogoIcon />}
          loading={loadingGoogle}
          onClick={renderProps.onClick}
          size="large"
        >
          {t('button.connectWithGoogle')}
        </Button>
      )}
      onFailure={onFailure}
      onSuccess={onLoginGoogleSuccess}
    />
  );
};

LoginGoogleBtn.propTypes = {
  onCancel: PropTypes.func,
};

export default LoginGoogleBtn;
