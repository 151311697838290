/* eslint-disable no-underscore-dangle */
import { createDraftSafeSelector } from '@reduxjs/toolkit';

const getRawServiceTypes = (state) => state.config.data.ServiceType;
const getRawCurrentLocation = (state) => state.config.currentLocation;
const getRawNQSRTypes = (state) => state.config.data.NQSRType;
const getRawStateNamesOfAustralia = (state) =>
  state.config.data?.stateNamesOfAustralia;
const getRawEnquiryTypes = (state) => state.config.data.EnquiryType;
const getRawRehydratedPersistConfig = (state) =>
  state.config?._persist?.rehydrated;

export const getRehydratedPersistConfig = createDraftSafeSelector(
  [getRawRehydratedPersistConfig],
  (data) => data,
);

export const getServiceTypes = createDraftSafeSelector(
  [getRawServiceTypes],
  (data) => data,
);

export const getCurrentGeoLocation = createDraftSafeSelector(
  [getRawCurrentLocation],
  (data) => data?.geoLocation,
);

export const getCurrentLocation = createDraftSafeSelector(
  [getRawCurrentLocation],
  (data) => data,
);

export const getNQSRTypes = createDraftSafeSelector(
  [getRawNQSRTypes],
  (data) => data,
);

export const getEnquiryTypes = createDraftSafeSelector(
  [getRawEnquiryTypes],
  (data) => data,
);

export const getStateNamesOfAustralia = createDraftSafeSelector(
  [getRawStateNamesOfAustralia],
  (data) => data,
);
