export const ICFacebookFill = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.31206 17.1086V9.54859H8.7603L9.16019 6.18859H6.31206V4.55227C6.31206 3.68707 6.33564 2.82859 7.62524 2.82859H8.93144V0.426194C8.93144 0.390074 7.80947 0.308594 6.67441 0.308594C4.30388 0.308594 2.81957 1.70047 2.81957 4.25659V6.18859H0.200195V9.54859H2.81957V17.1086H6.31206Z"
      fill="currentColor"
    />
  </svg>
);

export const ICFacebookTwoTone = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.3327 21.586C29.3327 26.4394 26.4394 29.3327 21.586 29.3327H19.9993C19.266 29.3327 18.666 28.7327 18.666 27.9993V20.306C18.666 19.946 18.9593 19.6394 19.3193 19.6394L21.666 19.5994C21.8527 19.586 22.0127 19.4527 22.0527 19.266L22.5194 16.7194C22.5594 16.4794 22.3727 16.2527 22.1193 16.2527L19.2793 16.2927C18.906 16.2927 18.6127 15.9994 18.5994 15.6394L18.546 12.3727C18.546 12.1593 18.7193 11.9727 18.946 11.9727L22.146 11.9194C22.3727 11.9194 22.546 11.746 22.546 11.5194L22.4927 8.31934C22.4927 8.09267 22.3194 7.91935 22.0927 7.91935L18.4927 7.9727C16.2793 8.0127 14.5194 9.82601 14.5594 12.0393L14.626 15.706C14.6394 16.0793 14.346 16.3727 13.9727 16.386L12.3727 16.4127C12.146 16.4127 11.9727 16.586 11.9727 16.8127L12.0127 19.346C12.0127 19.5727 12.186 19.746 12.4127 19.746L14.0127 19.7194C14.386 19.7194 14.6793 20.0127 14.6927 20.3727L14.8127 27.9727C14.826 28.7194 14.226 29.3327 13.4793 29.3327H10.4127C5.55935 29.3327 2.66602 26.4393 2.66602 21.5727V10.4127C2.66602 5.55935 5.55935 2.66602 10.4127 2.66602H21.586C26.4394 2.66602 29.3327 5.55935 29.3327 10.4127V21.586V21.586Z"
      fill="#316DDE"
    />
  </svg>
);

export const ICMailTwoTone = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M22.5866 4C24.3746 4 26.0946 4.70667 27.36 5.97467C28.6266 7.24 29.3346 8.94667 29.3346 10.7333V21.2667C29.3346 24.9867 26.308 28 22.5866 28H9.41463C5.6933 28 2.66797 24.9867 2.66797 21.2667V10.7333C2.66797 7.01333 5.67997 4 9.41463 4H22.5866ZM24.0946 10.9333C23.8146 10.9187 23.548 11.0133 23.3466 11.2L17.3346 16C16.5613 16.6413 15.4533 16.6413 14.668 16L8.66797 11.2C8.2533 10.8933 7.67997 10.9333 7.33464 11.2933C6.97464 11.6533 6.93464 12.2267 7.23997 12.6267L7.41464 12.8L13.4813 17.5333C14.228 18.12 15.1333 18.44 16.0813 18.44C17.0266 18.44 17.948 18.12 18.6933 17.5333L24.708 12.72L24.8146 12.6133C25.1333 12.2267 25.1333 11.6667 24.8 11.28C24.6146 11.0813 24.36 10.96 24.0946 10.9333Z"
      fill="#FF5F5F"
    />
  </svg>
);

export const ICCopyTwoTone = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21.3327 17.1993V22.7994C21.3327 27.466 19.466 29.3327 14.7993 29.3327H9.19935C4.53268 29.3327 2.66602 27.466 2.66602 22.7994V17.1993C2.66602 12.5327 4.53268 10.666 9.19935 10.666H14.7993C19.466 10.666 21.3327 12.5327 21.3327 17.1993Z"
      fill="#7A7A9D"
    />
    <path
      d="M22.7997 2.66602H17.1997C12.9609 2.66602 11.043 4.21708 10.7262 7.98691C10.6796 8.54186 11.1347 8.99935 11.6916 8.99935H14.7997C20.3997 8.99935 22.9997 11.5993 22.9997 17.1993V20.3074C22.9997 20.8644 23.4572 21.3194 24.0121 21.2728C27.782 20.956 29.333 19.0382 29.333 14.7993V9.19935C29.333 4.53268 27.4664 2.66602 22.7997 2.66602Z"
      fill="#45445F"
    />
  </svg>
);

export const ICGoogleFill = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6699 5.87167H7.75057C7.75057 6.59126 7.75057 8.03045 7.74616 8.75005H11.7557C11.6021 9.46964 11.0573 10.4771 10.2876 10.9844C10.2869 10.9837 10.2862 10.9887 10.2847 10.988C9.26134 11.6637 7.91084 11.817 6.90807 11.6155C5.33629 11.3032 4.09239 10.1633 3.58733 8.71551C3.59027 8.71335 3.59248 8.6932 3.59469 8.69176C3.27856 7.79371 3.27856 6.59126 3.59469 5.87167H3.59395C4.00123 4.54905 5.28262 3.34229 6.85661 3.012C8.12257 2.74359 9.55099 3.03431 10.6015 4.01727C10.7412 3.88055 12.535 2.12906 12.6696 1.98658C9.08049 -1.26383 3.3337 -0.120397 1.18334 4.077H1.18261C1.18261 4.077 1.18334 4.077 1.17893 4.08491C0.11515 6.14655 0.15926 8.57591 1.18629 10.539C1.18334 10.5411 1.18114 10.5426 1.17893 10.5447C2.10965 12.3509 3.80347 13.7361 5.84429 14.2636C8.01229 14.8321 10.7714 14.4435 12.6196 12.7719C12.6203 12.7726 12.621 12.7733 12.6218 12.774C14.1877 11.3636 15.1625 8.96089 14.6699 5.87167Z"
      fill="currentColor"
    />
  </svg>
);

export const ICLinkedinFill = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.1967 13.9083V13.9078H13.2V9.06667C13.2 6.69837 12.6901 4.87402 9.92145 4.87402C8.59045 4.87402 7.69725 5.60442 7.3326 6.29687H7.2941V5.09512H4.66895V13.9078H7.40245V9.54407C7.40245 8.39512 7.62025 7.28412 9.0431 7.28412C10.445 7.28412 10.4659 8.59532 10.4659 9.61777V13.9083H13.1967Z"
      fill="currentColor"
    />
    <path
      d="M0.217773 5.0957H2.95457V13.9083H0.217773V5.0957Z"
      fill="currentColor"
    />
    <path
      d="M1.5851 0.708496C0.71005 0.708496 0 1.41855 0 2.2936C0 3.16865 0.71005 3.89355 1.5851 3.89355C2.46015 3.89355 3.1702 3.16865 3.1702 2.2936C3.16991 1.87329 3.00281 1.47028 2.70561 1.17308C2.40841 0.875882 2.00541 0.708787 1.5851 0.708496V0.708496Z"
      fill="currentColor"
    />
  </svg>
);

export const ICYoutubeFill = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.9744 2.34304C16.9032 2.06708 16.7675 1.81201 16.5783 1.59885C16.3892 1.38569 16.1521 1.22056 15.8866 1.11709C13.3163 0.129411 9.22303 0.137661 8.99961 0.138861C8.77626 0.137061 4.68298 0.129411 2.11273 1.11709C1.8472 1.22054 1.61008 1.38565 1.42093 1.5988C1.23178 1.81195 1.09602 2.06702 1.02486 2.34296C0.830834 3.08779 0.599609 4.44971 0.599609 6.70826C0.599609 8.96681 0.830834 10.3287 1.02486 11.0735C1.096 11.3494 1.23174 11.6045 1.42088 11.8177C1.61002 12.0308 1.84713 12.196 2.11266 12.2994C4.57311 13.245 8.42931 13.2778 8.95018 13.2778H9.04903C9.57028 13.2778 13.4262 13.2448 15.8865 12.2994C16.152 12.196 16.3891 12.0309 16.5783 11.8177C16.7674 11.6046 16.9032 11.3495 16.9744 11.0736C17.1684 10.3287 17.3996 8.96681 17.3996 6.70826C17.3996 4.44971 17.1684 3.08786 16.9744 2.34304ZM11.566 6.95786L7.96603 9.35786C7.92085 9.38798 7.86835 9.40528 7.81411 9.4079C7.75987 9.41053 7.70594 9.39838 7.65806 9.37276C7.61018 9.34714 7.57016 9.30901 7.54225 9.26243C7.51435 9.21584 7.49961 9.16256 7.49961 9.10826V4.30826C7.49961 4.25396 7.51435 4.20068 7.54225 4.15409C7.57016 4.10751 7.61018 4.06938 7.65806 4.04376C7.70594 4.01814 7.75987 4.00599 7.81411 4.00862C7.86835 4.01124 7.92085 4.02854 7.96603 4.05866L11.566 6.45866C11.6071 6.48606 11.6408 6.52317 11.6641 6.56672C11.6874 6.61026 11.6996 6.65888 11.6996 6.70826C11.6996 6.75764 11.6874 6.80626 11.6641 6.84981C11.6408 6.89335 11.6071 6.93046 11.566 6.95786Z"
      fill="currentColor"
    />
  </svg>
);

export const ICFacebookLogo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="16" cy="16" r="14" fill="url(#paint0_linear_2017_34245)" />
    <path
      d="M21.2137 20.2816L21.8356 16.3301H17.9452V13.767C17.9452 12.6857 18.4877 11.6311 20.2302 11.6311H22V8.26699C22 8.26699 20.3945 8 18.8603 8C15.6548 8 13.5617 9.89294 13.5617 13.3184V16.3301H10V20.2816H13.5617V29.8345C14.2767 29.944 15.0082 30 15.7534 30C16.4986 30 17.2302 29.944 17.9452 29.8345V20.2816H21.2137Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2017_34245"
        x1="16"
        y1="2"
        x2="16"
        y2="29.917"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#18ACFE" />
        <stop offset="1" stopColor="#0163E0" />
      </linearGradient>
    </defs>
  </svg>
);

export const ICGoogleLogo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.0033 16.3114C30.0033 15.1603 29.908 14.3203 29.7018 13.4492H16.2891V18.6447H24.162C24.0033 19.9359 23.1462 21.8803 21.2414 23.1869L21.2147 23.3609L25.4555 26.5805L25.7494 26.6092C28.4477 24.167 30.0033 20.5736 30.0033 16.3114"
      fill="#4285F4"
    />
    <path
      d="M16.2862 30C20.1433 30 23.3814 28.7555 25.7465 26.6089L21.2386 23.1865C20.0322 24.011 18.4132 24.5866 16.2862 24.5866C12.5085 24.5866 9.30219 22.1444 8.15923 18.7688L7.9917 18.7827L3.58202 22.1272L3.52435 22.2843C5.87353 26.8577 10.6989 30 16.2862 30Z"
      fill="#34A853"
    />
    <path
      d="M8.15866 18.7699C7.85708 17.8988 7.68255 16.9654 7.68255 16.001C7.68255 15.0365 7.85708 14.1032 8.1428 13.2321L8.13481 13.0466L3.66986 9.64844L3.52377 9.71653C2.55556 11.6143 2 13.7455 2 16.001C2 18.2566 2.55556 20.3876 3.52377 22.2854L8.15866 18.7699"
      fill="#FBBC05"
    />
    <path
      d="M16.2863 7.4133C18.9688 7.4133 20.7783 8.54885 21.8101 9.4978L25.8418 5.64C23.3657 3.38445 20.1434 2 16.2863 2C10.699 2 5.87354 5.1422 3.52435 9.71549L8.14339 13.2311C9.30223 9.85555 12.5086 7.4133 16.2863 7.4133"
      fill="#EB4335"
    />
  </svg>
);

export const ICAppleLogo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.5 16C30.5 23.728 24.235 30 16.5 30C8.765 30 2.5 23.728 2.5 16C2.5 8.265 8.765 2 16.5 2C24.235 2 30.5 8.265 30.5 16Z"
      fill="#283544"
    />
    <path
      d="M23.0621 12.4574C22.9857 12.502 21.1671 13.4425 21.1671 15.5279C21.2528 17.9061 23.4621 18.7401 23.5 18.7401C23.4621 18.7847 23.1665 19.8763 22.2907 21.0205C21.5956 22.0062 20.8242 23 19.6528 23C18.5385 23 18.1385 22.3431 16.8528 22.3431C15.472 22.3431 15.0813 23 14.0242 23C12.8528 23 12.0242 21.953 11.2913 20.9766C10.3391 19.6986 9.52978 17.6931 9.50121 15.7675C9.48195 14.7471 9.69189 13.744 10.2248 12.8921C10.977 11.7026 12.3198 10.8952 13.7863 10.8686C14.9099 10.8333 15.9099 11.5875 16.5956 11.5875C17.2528 11.5875 18.4814 10.8686 19.8714 10.8686C20.4714 10.8692 22.0714 11.0376 23.0621 12.4574ZM16.5006 10.6649C16.3006 9.73303 16.8528 8.80119 17.3671 8.20677C18.0242 7.48792 19.0621 7 19.9571 7C20.0143 7.93185 19.6522 8.84575 19.005 9.51136C18.4242 10.2302 17.4242 10.7714 16.5006 10.6649Z"
      fill="white"
    />
  </svg>
);

export const ICAppleSecondLogo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.153 17.4511C23.1855 14.9296 24.5396 12.5437 26.6878 11.223C25.3326 9.28753 23.0627 8.06038 20.7011 7.9865C18.1822 7.72211 15.7403 9.49374 14.4568 9.49374C13.1483 9.49374 11.1721 8.01274 9.044 8.05653C6.27012 8.14615 3.68417 9.72322 2.33462 12.1483C-0.56638 17.171 1.5975 24.5525 4.37642 28.6125C5.76677 30.6005 7.39169 32.8212 9.51787 32.7424C11.5985 32.6561 12.3755 31.4157 14.887 31.4157C17.3751 31.4157 18.1042 32.7424 20.2735 32.6923C22.506 32.6561 23.9127 30.6955 25.2542 28.6886C26.2532 27.2721 27.0219 25.7065 27.5319 24.0499C24.9082 22.9402 23.1561 20.2998 23.153 17.4511Z"
      fill="currentColor"
    />
    <path
      d="M19.0555 5.3165C20.2728 3.85518 20.8725 1.97692 20.7273 0.0805664C18.8675 0.275897 17.1496 1.16474 15.9159 2.56998C14.7095 3.94298 14.0817 5.7882 14.2003 7.61207C16.0608 7.63122 17.8897 6.76648 19.0555 5.3165Z"
      fill="currentColor"
    />
  </svg>
);

export const ICGooglePlayLogo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 36 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_ii_2309_2035)">
      <path
        d="M1.65397 1.30725C1.21747 1.76925 0.959472 2.48626 0.959472 3.41476V36.5888C0.959472 37.5187 1.21747 38.2343 1.65397 38.6963L1.76497 38.8043L20.3485 20.2208V20.0018V19.7828L1.76497 1.19775L1.65397 1.30725Z"
        fill="url(#paint0_linear_2309_2035)"
      />
      <path
        d="M26.5421 26.4169L20.3486 20.2204V20.0014V19.7824L26.5436 13.5874L26.6831 13.6669L34.0226 17.8369C36.1181 19.0279 36.1181 20.9764 34.0226 22.1689L26.6831 26.3389L26.5421 26.4169Z"
        fill="url(#paint1_linear_2309_2035)"
      />
      <g filter="url(#filter1_i_2309_2035)">
        <path
          d="M26.6828 26.3375L20.3468 20.0015L1.65381 38.696C2.34381 39.428 3.48531 39.518 4.77081 38.789L26.6828 26.3375Z"
          fill="url(#paint2_linear_2309_2035)"
        />
      </g>
      <path
        d="M26.6828 13.6655L4.77081 1.21554C3.48531 0.485039 2.34381 0.576539 1.65381 1.30854L20.3483 20.003L26.6828 13.6655Z"
        fill="url(#paint3_linear_2309_2035)"
      />
    </g>
    <defs>
      <filter
        id="filter0_ii_2309_2035"
        x="0.959473"
        y="0.711426"
        width="34.6353"
        height="38.5811"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2309_2035"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_innerShadow_2309_2035"
          result="effect2_innerShadow_2309_2035"
        />
      </filter>
      <filter
        id="filter1_i_2309_2035"
        x="1.65381"
        y="20.0015"
        width="25.0288"
        height="19.291"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="-0.15" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2309_2035"
        />
      </filter>
      <linearGradient
        id="paint0_linear_2309_2035"
        x1="18.7012"
        y1="3.06375"
        x2="-6.47183"
        y2="28.2368"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00A0FF" />
        <stop offset="0.0066" stopColor="#00A1FF" />
        <stop offset="0.2601" stopColor="#00BEFF" />
        <stop offset="0.5122" stopColor="#00D2FF" />
        <stop offset="0.7604" stopColor="#00DFFF" />
        <stop offset="1" stopColor="#00E3FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_2309_2035"
        x1="36.7504"
        y1="20.0014"
        x2="0.456539"
        y2="20.0014"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE000" />
        <stop offset="0.4087" stopColor="#FFBD00" />
        <stop offset="0.7754" stopColor="#FFA500" />
        <stop offset="1" stopColor="#FF9C00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_2309_2035"
        x1="23.242"
        y1="23.4423"
        x2="-10.8958"
        y2="57.5801"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF3A44" />
        <stop offset="1" stopColor="#C31162" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_2309_2035"
        x1="-3.05409"
        y1="-9.73476"
        x2="12.1894"
        y2="5.50869"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#32A071" />
        <stop offset="0.0685" stopColor="#2DA771" />
        <stop offset="0.4762" stopColor="#15CF74" />
        <stop offset="0.8009" stopColor="#06E775" />
        <stop offset="1" stopColor="#00F076" />
      </linearGradient>
    </defs>
  </svg>
);

export const ICGmailLogo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M17.0525 1.52344L11.0654 6.19586L4.94141 1.52344V1.5247L4.94881 1.53101V8.07367L10.9963 12.8471L17.0525 8.25804V1.52344Z"
      fill="#EA4335"
    />
    <path
      d="M18.6231 0.386385L17.0508 1.52292V8.25752L21.9983 4.45897V2.17074C21.9983 2.17074 21.3978 -1.09742 18.6231 0.386385Z"
      fill="#FBBC05"
    />
    <path
      d="M17.0508 8.25949V16.9944H20.8428C20.8428 16.9944 21.9219 16.8833 21.9995 15.6533V4.46094L17.0508 8.25949Z"
      fill="#34A853"
    />
    <path
      d="M4.94909 17.0035V8.07663L4.94141 8.07031L4.94909 17.0035Z"
      fill="#C5221F"
    />
    <path
      d="M4.94014 1.52453L3.37646 0.394312C0.601794 -1.0895 0 2.17741 0 2.17741V4.46563L4.94014 8.06718V1.52453Z"
      fill="#C5221F"
    />
    <path
      d="M4.94141 1.52344V8.06609L4.94909 8.0724V1.52975L4.94141 1.52344Z"
      fill="#C5221F"
    />
    <path
      d="M0 4.46875V15.6611C0.0764574 16.8923 1.15673 17.0022 1.15673 17.0022H4.94877L4.94014 8.06904L0 4.46875Z"
      fill="#4285F4"
    />
  </svg>
);

export const ICTwitterLogo = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 28 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.78874 23C5.55374 23 2.53817 22.0591 0 20.4356C2.15499 20.5751 5.95807 20.2411 8.32358 17.9848C4.76508 17.8215 3.16026 15.0923 2.95094 13.926C3.25329 14.0426 4.6953 14.1826 5.50934 13.856C1.4159 12.8296 0.787928 9.23732 0.927477 8.14097C1.695 8.67749 2.99745 8.8641 3.50913 8.81744C-0.305207 6.08823 1.06703 1.98276 1.74151 1.09635C4.47882 4.88867 8.5812 7.01857 13.6564 7.13704C13.5607 6.71736 13.5102 6.28042 13.5102 5.83164C13.5102 2.61092 16.1134 0 19.3247 0C21.0025 0 22.5144 0.712754 23.5757 1.85284C24.6969 1.59011 26.3843 0.975068 27.2092 0.443205C26.7934 1.93611 25.4989 3.18149 24.7159 3.64308C24.7224 3.65878 24.7095 3.62731 24.7159 3.64308C25.4037 3.53904 27.2648 3.18137 28 2.68256C27.6364 3.52125 26.264 4.91573 25.1377 5.69642C25.3473 14.9381 18.2765 23 8.78874 23Z"
      fill="#32A4FC"
    />
  </svg>
);

export const ICInstagramFill = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.79325 0.267578H4.20659C1.77992 0.267578 0.333252 1.71424 0.333252 4.14091V9.72091C0.333252 12.1542 1.77992 13.6009 4.20659 13.6009H9.78659C12.2133 13.6009 13.6599 12.1542 13.6599 9.72758V4.14091C13.6666 1.71424 12.2199 0.267578 9.79325 0.267578ZM6.99992 9.52091C5.57325 9.52091 4.41325 8.36091 4.41325 6.93424C4.41325 5.50758 5.57325 4.34758 6.99992 4.34758C8.42659 4.34758 9.58659 5.50758 9.58659 6.93424C9.58659 8.36091 8.42659 9.52091 6.99992 9.52091ZM10.9466 3.52091C10.9133 3.60091 10.8666 3.67424 10.8066 3.74091C10.7399 3.80091 10.6666 3.84758 10.5866 3.88091C10.5066 3.91424 10.4199 3.93424 10.3333 3.93424C10.1533 3.93424 9.98659 3.86758 9.85992 3.74091C9.79992 3.67424 9.75325 3.60091 9.71992 3.52091C9.68659 3.44091 9.66659 3.35424 9.66659 3.26758C9.66659 3.18091 9.68659 3.09424 9.71992 3.01424C9.75325 2.92758 9.79992 2.86091 9.85992 2.79424C10.0133 2.64091 10.2466 2.56758 10.4599 2.61424C10.5066 2.62091 10.5466 2.63424 10.5866 2.65424C10.6266 2.66758 10.6666 2.68758 10.7066 2.71424C10.7399 2.73424 10.7733 2.76758 10.8066 2.79424C10.8666 2.86091 10.9133 2.92758 10.9466 3.01424C10.9799 3.09424 10.9999 3.18091 10.9999 3.26758C10.9999 3.35424 10.9799 3.44091 10.9466 3.52091Z"
      fill="currentColor"
    />
  </svg>
);

export const ICLinkedInCircle = (props) => (
  <svg
    width="1.5em"
    height="1.5em"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="2" y="2" width="28" height="28" rx="14" fill="#1275B1" />
    <path
      d="M12.6186 9.69215C12.6186 10.6267 11.8085 11.3843 10.8093 11.3843C9.81004 11.3843 9 10.6267 9 9.69215C9 8.7576 9.81004 8 10.8093 8C11.8085 8 12.6186 8.7576 12.6186 9.69215Z"
      fill="white"
    />
    <path d="M9.24742 12.6281H12.3402V22H9.24742V12.6281Z" fill="white" />
    <path
      d="M17.3196 12.6281H14.2268V22H17.3196C17.3196 22 17.3196 19.0496 17.3196 17.2049C17.3196 16.0976 17.6977 14.9855 19.2062 14.9855C20.911 14.9855 20.9008 16.4345 20.8928 17.5571C20.8824 19.0244 20.9072 20.5219 20.9072 22H24V17.0537C23.9738 13.8954 23.1508 12.4401 20.4433 12.4401C18.8354 12.4401 17.8387 13.1701 17.3196 13.8305V12.6281Z"
      fill="white"
    />
  </svg>
);
