import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { REQUEST_TYPE } from '@redux/crudCreator/dataProvider';
import { richWebConfig } from 'utils';

export const residentialApi = rtkCreator(
  'residential',
  'residential',
  richWebConfig,
  (builder) => ({
    getAllResidentialRandom: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_ALL,
        customApiResource: 'residential/random',
      }),
    }),
  }),
);

export const { useGetAllResidentialQuery, useGetAllResidentialRandomQuery } =
  residentialApi;
