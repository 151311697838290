import PropTypes from 'prop-types';
import { Row, Col, Checkbox, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import FormDatePicker from '@uikit/form/FormDatePicker';
import FormRequiredDays from '@uikit/form/FormRequiredDays';
import { formatMoneyWithCurrency } from 'utils/tools';
import dayjs from 'utils/dayUtils';
import { useContext } from 'react';
import { RestInputContext } from '@uikit/form/RestInputContext';
import ChildInfo from '@uikit/commons/ChildInfo';
import { ChildrenFormStyles } from './styles';

const ChildrenForm = ({
  field,
  centreServices,
  parentName,
  onEditChild,
  isApplication,
}) => {
  const { t } = useTranslation();
  const { form } = useContext(RestInputContext);

  const { name } = field || { name: 0 };

  const item = form.getFieldValue([parentName, name]);

  const formatServiceName = (item) =>
    `${item?.serviceType?.name || ''} (${
      item?.serviceAgeGroup?.name || ''
    }) - ${formatMoneyWithCurrency(item?.costPerDay)}`;

  const disabledDate = (current) =>
    current && dayjs(current).isBefore(dayjs(), 'day');

  const onSelectedChild = () => {
    form.setFields([
      {
        name: [parentName, name, 'isSelected'],
        value: !item?.isSelected,
      },
    ]);
  };

  return (
    <ChildrenFormStyles>
      {item?.isSelected ? (
        <div className="children-form-box">
          <ChildInfo
            onSelectedChild={onSelectedChild}
            item={item?.child}
            onEditChild={onEditChild}
          />
          <div className="children-form-body">
            <Row gutter={16}>
              <Col xl={12} lg={24} md={24} xs={24}>
                <FormDatePicker
                  required
                  name={[name, 'startDate']}
                  label="input.startDate.placeholder"
                  placeholder="input.startDate.placeholder"
                  disabledDate={disabledDate}
                  messageRequire="input.startDate.validateMsg.required"
                />
              </Col>

              <Col xl={12} lg={24} md={24} xs={24}>
                <FormRequiredDays
                  name={[name, 'child', 'requiredDays']}
                  label="input.careDays.placeholder"
                  rules={[
                    {
                      required: true,
                      message: t('input.careDays.validateMsg.required'),
                    },
                  ]}
                />
              </Col>

              <Col span={24}>
                <Form.Item
                  name={[name, 'serviceIds']}
                  label={t('input.centreServices.placeholder')}
                  {...(isApplication && {
                    rules: [
                      {
                        required: true,
                        message: t('input.centreServices.validateMsg.required'),
                      },
                    ],
                  })}
                >
                  <Checkbox.Group style={{ width: '100%' }}>
                    <Row gutter={[20, 20]}>
                      {centreServices?.map((item) => (
                        <Col key={item.id} lg={12} md={24} xs={24}>
                          <Checkbox className="checkbox-18" value={item.id}>
                            {formatServiceName(item)}
                          </Checkbox>
                        </Col>
                      ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      ) : (
        <div
          role="presentation"
          onClick={onSelectedChild}
          className="child-info-box"
        >
          <ChildInfo
            item={item?.child}
            onEditChild={onEditChild}
            onSelectedChild={onSelectedChild}
          />
        </div>
      )}
    </ChildrenFormStyles>
  );
};

ChildrenForm.propTypes = {
  field: PropTypes.object,
  centreServices: PropTypes.array,
  parentName: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onEditChild: PropTypes.func,
  isApplication: PropTypes.bool,
};

export default ChildrenForm;
