import styled from 'styled-components';

export const JobsStyles = styled.div`
  --primaryColor: ${({ primaryColor }) => primaryColor || 'var(--bg-green)'};
  --secondaryColor: ${({ secondaryColor }) =>
    secondaryColor || 'var(--bg-pastel-green-l100)'};

  margin: 0;
  width: 100%;

  .ant-badge {
    margin-top: 10px !important;
    .anticon {
      font-size: 16px;
      color: var(--text-grey-l1);
    }
  }
  .sider {
    position: relative;

    &__menu {
      height: calc(110vh - 65px);
      padding: 70px 12px;
      width: 240px;

      .ant-menu-item {
        margin: 12px 0;
        width: ${(props) => (props.isCollapsedMenu ? '60px' : '210px')};
        height: 56px;
        display: flex;
        align-items: center;
        padding-left: 20px !important;
        color: var(--text-grey-l1);
        transition-property: width;

        border-radius: 6px;

        &.ant-menu-item-selected {
          position: relative;
          background-color: var(--secondaryColor);
          color: var(--primaryColor);
          font-weight: 700;

          &::before {
            content: '';
            width: 4px;
            height: 100%;
            background-color: var(--primaryColor);
            position: absolute;
            left: 0;
          }

          &::after {
            display: none;
          }
        }

        &-icon.anticon {
          font-size: 20px;
          line-height: 20px;
          width: 20px;
          height: 20px;
          display: inline-block;
          flex-shrink: 0;
        }
      }
    }
  }

  .content {
    height: calc(100vh - 65px);
    overflow: auto;
  }
`;
