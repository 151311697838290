import React from 'react';
import { Card, Col, Progress, Rate, Row, Space } from 'antd';
import {
  formatMoney,
  formatMoneySymbol,
  formatRatingNumber,
} from 'utils/tools';
import { roundHalf } from 'utils/textUtils';
import { useTranslation } from 'react-i18next';
import { StarFilled } from '@ant-design/icons';
import { ReviewSummaries, StatisticsReview } from 'types/parentReview';
import { ReviewSummaryStyles } from './styles';

interface Props {
  reviewSummaries?: ReviewSummaries;
  summaries?: StatisticsReview[];
}

const ReviewSummary = ({ reviewSummaries, summaries }: Props) => {
  const { t } = useTranslation();
  return (
    <ReviewSummaryStyles>
      <Card className="mb-16">
        {!!reviewSummaries?.avgStars && (
          <Row className="flex-center-between">
            <Col span={10} className="text-center pr-16">
              <div>
                <span className="fw-700 size-36">
                  {formatRatingNumber(reviewSummaries?.avgStars)}
                </span>
                <span className="ml-5 size-20 text-grey-l1">/ 5</span>
              </div>
              <Rate
                allowHalf
                disabled
                value={roundHalf(reviewSummaries?.avgStars)}
                className="flex-center"
              />
              <div className="lh-24 text-grey-l1 size-14">
                {`${formatMoney(reviewSummaries?.ratingCount)} ${
                  Number(reviewSummaries?.ratingCount) === 1
                    ? t('common.review').toLocaleLowerCase()
                    : t('common.reviews').toLocaleLowerCase()
                }`}
              </div>
            </Col>
            <Col span={14} className="pl-16 border-left-light">
              {summaries?.map((item) => (
                <div className="d-flex">
                  <Space
                    size={4}
                    className="mr-8 d-flex tex items-center size-12"
                  >
                    <StarFilled className="text-graph-yellow" />
                    {item?.rating}
                  </Space>
                  <Progress
                    className="size-12"
                    percent={(item?.count / reviewSummaries?.ratingCount) * 100}
                    format={() => formatMoneySymbol(item?.count, 1)}
                  />
                </div>
              ))}
            </Col>
          </Row>
        )}
      </Card>
    </ReviewSummaryStyles>
  );
};

export default ReviewSummary;
