import { Col, Row } from 'antd';
import { SimpleAndComplexEnquiry } from 'types/centres';
import CentreInfo from './CentreInfo';
import SimpleEnquiryForm from './SimpleEnquiryForm';

const SimpleEnquiry = ({ centre, onCancel }: SimpleAndComplexEnquiry) => (
  <Row>
    <Col md={12} xs={24}>
      <CentreInfo centre={centre} />
    </Col>
    <Col md={12} xs={24}>
      <SimpleEnquiryForm
        {...centre}
        centreId={centre?.id}
        onCancel={onCancel}
      />
    </Col>
  </Row>
);

export default SimpleEnquiry;
