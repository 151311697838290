import { createSlice } from '@reduxjs/toolkit';
import { LOCATION_DEFAULT } from 'configs/constants';
import { getConfig, setModalConfig } from './actions';

export const initialState = {
  modals: {},
  error: null,
  data: {},
  currentLocation: LOCATION_DEFAULT,
};

const { actions, reducer } = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setCurrentGeoLocation: (state, { payload }) => {
      state.currentLocation.geoLocation = payload;
    },
    setCurrentLocation: (state, { payload }) => {
      state.currentLocation = payload;
    },
    setEmailVerify: (state, { payload }) => {
      state.emailVerify = payload;
    },
  },
  extraReducers: {
    [getConfig.fulfilled]: (state, { payload }) => {
      state.data = payload;
    },
    [getConfig.rejected]: (state, { payload }) => {
      state.error = payload || null;
    },
    [setModalConfig.fulfilled]: (state, { payload }) => {
      state.modals = payload;
    },
  },
});

export const { setCurrentGeoLocation, setCurrentLocation, setEmailVerify } =
  actions;

export default reducer;
