import { getCurrentUserSelector } from '@redux/auth/selectors';
import { UserCircleIcon } from '@uikit/commons/SVGIcons';
import ProfileInfoForm from 'components/applyApplication/ProfileInfoForm';
import { useSelector } from 'react-redux';
import ContentSteps from './ContentSteps';

const Step2 = () => {
  const currentUser = useSelector(getCurrentUserSelector);

  return (
    <ContentSteps
      icon={<UserCircleIcon />}
      title="applyApplications.step2.title"
      description="applyApplications.step2.desc"
    >
      <ProfileInfoForm initialValues={currentUser?.additionalParentInfo} />
    </ContentSteps>
  );
};

export default Step2;
