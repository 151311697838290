export const DATE_FORMAT = 'DD/MM/YYYY';

export const DATE_FORMAT_INPUT = 'DD-MM-YYYY';

export const MONTH_YEAR_FORMAT_INPUT = 'MM/YYYY';

export const DATE_TIME_FORMAT_INPUT = 'DD-MM-YYYY HH:mm';

export const TIME_FORMAT_INPUT = 'HH:mm';

export const MONTH_DATE_YEAR_INPUT = 'MM-DD-YYYY';

export const DATE_TIME_MESSAGE_FORMAT = 'MMM DD, YYYY [at] HH:mm';

export const DATE_DISPLAY_FORMAT = 'DD MMMM YYYY';

export const NAMESPACES_I18N = ['common', 'seo'];

export const DEFAULT_LIMIT = 10;

export const DEFAULT_PAGE = 1;

export const MAX_LIMIT_PER_PAGE = 200;

export const DEFAULT_SIZE_COMPARE = 1;

export const PREFIX_URL_API = {
  public: '/public',
  client: '/client',
  domain: '/domain',
  richWeb: '/rich-web',
  parent: '/parent',
};

export const PREFIX_API_VER_2 = 'thor/v2';

export const CURRENCY_UNIT_SYMBOL = '$';

export const CURRENCY_UNIT = 'AUD';

export const REPORT_DATE_FORMAT = 'YYYY-MM-DD';

export const SEARCH_BY_BRAND_PATH = '/featured-centres';

export const CHILDCARE_NEARBY_PATH = '/childcare-centre-near-me';

export const HINTS_TIPS_STORIES_PATH = '/parenting-tips';

export const CHILDCARE_PROVIDER_PATH = '/for-childcare-providers';

export const EARLY_CHILDHOOD_AND_CHILDCARE_JOB_PATH =
  '/early-childhood-and-childcare-jobs';

export const TOP_PICKS_PATH = '/top-childcare-centres';

export const GREAT_OFFERS_PATH = '/for-parents-families-great-offers';

export const SUBSCRIPTION_PRICING_PATH = '/subscription-pricing';

export const CONTACT_US_PATH = '/contact-us';

export const END_USER_LICENCE_AGREEMENT_PATH = `${process.env.NEXT_PUBLIC_CRM_WEB_URL}/eula`;
export const CLAIM_CENTRE_PATH = `${process.env.NEXT_PUBLIC_CRM_WEB_URL}/claim-centres`;

export const CRM_SUBSCRIPTIONS_URL = `${process.env.NEXT_PUBLIC_CRM_WEB_URL}/subscriptions`;

export const CLAIM_CENTRES_URL = `${process.env.NEXT_PUBLIC_CRM_WEB_URL}/claim-centres`;

export const CRM_LOGIN_URL = `${process.env.NEXT_PUBLIC_CRM_WEB_URL}/login`;

export const HOST_NAME = 'https://kindicare.com';

export const IOS_USER_APP_ID =
  process.env.NEXT_PUBLIC_IOS_USER_APP_ID || '1557199473';

export const ANDROID_USER_APP_ID =
  process.env.NEXT_PUBLIC_ANDROID_USER_APP_ID || 'com.kindicare.app';

export const DOWNLOAD_APP_URL =
  process.env.NEXT_PUBLIC_DOWNLOAD_APP_URL ||
  'https://get.kindicare.com/rk1K/landingpage';

export const NQSRating_URL =
  'https://www.acecqa.gov.au/assessment/assessment-and-rating-process';

export const LEARN_MORE_NQSRating_URL =
  'https://www.acecqa.gov.au/nqf/national-quality-standard';

export const EMAIL_CONTACT = 'customercare@kindicare.com';

export const DEFAULT_INBOX_ID = 'inbox';

export const DEFAULT_GEO_LOCATION = {
  longitude: 150.9502708,
  latitude: -33.7385583,
};

export const LOCATION_DEFAULT = {
  subAdminArea: 'NSW',
  country: 'Australia',
  countryCode: 'AU',
  feature: 'Sydney Opera House',
  formattedAddress: 'Sydney Opera House, Sydney CBD, NSW 2000, Australia',
  locality: 'Sydney CBD',
  geoLocation: { latitude: -33.85712217644495, longitude: 151.21538442448275 },
  postalCode: '2000',
  streetName: 'Dobson Crescent',
  streetNumber: '56-58',
  subLocality: null,
  isDefault: false,
  isCurrentLocation: true,
};

export const COLOR_KINDI_RATING = {
  price: '#DB147F',
  avg: '#32A4FC',
};

export const DISTANCE_DEFAULT = 5000; // (5km)

export const CUSTOM_PRONOUNS_VALUE = 'Custom';

export const CARD_SLIDE_SETTINGS = {
  slidesToShow: 4,
  slidesToScroll: 4,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
      },
    },
  ],
};
export const CARD_SLIDE_FEATURED_SERVICES_SETTINGS = {
  slidesToShow: 2,
  slidesToScroll: 2,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1.2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const FEATURE_PROPERTIES_CARD_SLIDE_SETTINGS = {
  slidesToShow: 4,
  slidesToScroll: 4,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
  ],
};

export const CENTRES_COMAPRE_SLIDE_SETTINGS = {
  slidesToShow: 4,
  slidesToScroll: 4,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 510,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const ARTICLE_SLIDE_SETTINGS = {
  slidesToShow: 3,
  slidesToScroll: 3,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const BRAND_SLIDE_SETTINGS = {
  slidesToShow: 9,
  slidesToScroll: 9,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 7,
        slidesToScroll: 7,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
  ],
};

export const CENTRE_APP_SETTING_CONST = {
  bookATourOrVisitUrl: 'book-visit-and-tour-url',
  applyButtonUrl: 'apply-button-url',
  automatedResponsesCheckForVacancies:
    'automated-responses-check-for-vacancies',
  automatedResponsesFeeInformation: 'automated-responses-fee-information',
  automatedResponsesMoreInformation: 'automated-responses-more-information',
  automatedResponsesBookATourOrVisit:
    'automated-responses-book-a-tour-or-visit',
  allowedEnquiries: 'allowed-enquiries',
};

export const PLACE_DEFAULT_OPTIONS = {
  componentRestrictions: { country: 'au' },
  strictBounds: true,
};

export const CAROUSEL_HOME_KEYS = {
  brands: 'brands',
  childcareNearby: 'childcareNearby',
  topPicks: 'topPicks',
};
export const RESET_PASSWORD_TIME = 45;

export const COMPARISON_SLIDE_SETTINGS = {
  slidesToShow: 4,
  slidesToScroll: 1,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1441,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 680,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const STAFF_PROFILE_SLIDE_SETTINGS = {
  slidesToShow: 3,
  slidesToScroll: 3,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
  ],
};

export const IGNORE_QUERY_PARAMS = [
  'fbclid',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'utm_id',
  'utm_source_platform',
  'utm_creative_format',
  'utm_marketing_tactic',
];

export const PROGRAMS_LEARNING_SLIDE_SETTINGS = {
  slidesToShow: 3,
  slidesToScroll: 3,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1440,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
  ],
};

export const CTA_BUTTONS = {
  MAKE_ENQUIRY: 'make-enquiry',
  APPLY: 'apply',
  BOOK_A_TOUR_OR_VISIT: 'book-a-tour',
  PHONE: 'phone',
};

export const JOB_STATUS = {
  applied: 'Applied',
};

export const JOB_SALARY_RANGE_DEFAULT = [0, 150000];

export const JOB_DISTANCE_DEFAULT = 25;

export const PREFIX_RW_DEVICE_ID = 'rw';

export const LIMIT_SITEMAP_URLS_PER_PAGE = 1000;

export const LIMIT_SUBURB_PROFILE_SITEMAP_URLS_PER_PAGE = 2000;

export const SITE_URL =
  process.env.NEXT_PUBLIC_SITE_URL || 'https://kindicare.com';

export const UPLOAD_STATUS = {
  uploading: 'uploading',
  done: 'done',
  error: 'error',
};

export const LOGIN_BY_ROLE = {
  parent: 'parent',
  jobSeeker: 'jobSeeker',
};

export const STORAGE_KEY = 'token';
export const STORAGE_REFRESH_TOKEN = 'refreshToken';

export const AUTHENTICATION_USERNAME =
  process.env.AUTHENTICATION_USERNAME || 'kindicare';
export const AUTHENTICATION_PASSWORD =
  process.env.AUTHENTICATION_PASSWORD || 'kindicare123';

export const VIEW_ALL_CARD_TYPE = 'ViewAllCard';

export const ADS_CARD_TYPE = 'AdsCard';

export const DESTINATION_TYPE = {
  link: 'Link',
  phoneNumber: 'Phone Number',
  email: 'Email',
};

export const IGNORE_ADS_PARAMS = [
  'adsIds',
  'userPreviousStep',
  'userId',
  'sessionId',
];

export const SHARE_EVENTS_PLATFORM = {
  FACEBOOK: 'facebook',
  GMAIL: 'gmail',
  TWITTER: 'twitter',
  LINKEDIN: 'linkedin',
  COPYLINK: 'copylink',
};

export const DEFAULT_ADS_DELAY_IMPRESSION_TRACKING = 3000;

export const DEFAULT_CENTRES_DELAY_IMPRESSION_TRACKING = 1000;

export const SEARCH_SUGGESTED_TERM = 'search by keyword';

export const PARENT_HOME_PAGE_PATH = '/';

export const CENTRE_DETAIL_PATH =
  '/childcare/centres/[country]/[state]/[postcode]/[suburb]/[id]';

export const JOB_DETAIL_PATH =
  '/childcare/jobs/australia/[state]/[postcode]/[suburb]/[company]/[brand]/[centre]/[jobSlug]';

export const TRIGGER_LIST_VIEW_PATHS = [
  '/childcare-centre-near-me',
  '/childcare-centre-near-me/page/[page]',
  '/top-childcare-centres',
  '/top-childcare-centres/page/[page]',
  '/centres-featured-special-offers',
  '/centres-featured-special-offers/page/[page]',
  '/well-known-centres',
  '/well-known-centres/page/[page]',
  '/popular-childcare-centres-near-me',
  '/popular-childcare-centres-near-me/page/[page]',
  '/recently-view-centres',
  '/recently-view-centres/page/[page]',
  '/recommended-childcare-centres',
  '/recommended-childcare-centres/page/[page]',
  '/favourites-centers',
  '/favourites-centers/page/[page]',
  '/featured-centres',
  '/featured-centres/page/[page]',
  '/centres-popular-nearby',
  '/centres-popular-nearby/page/[page]',
  '/contacted-centres',
  '/contacted-centres/page/[page]',
];

export const TRIGGER_MAP_VIEW_PATHS = [
  '/childcare-centre-near-me/map-view',
  '/top-childcare-centres/map-view',
  '/centres-featured-special-offers/map-view',
  '/well-known-centres/map-view',
  '/popular-childcare-centres-near-me/map-view',
  '/recently-view-centres/map-view',
  '/recommended-childcare-centres/map-view',
  '/favourites-centers/map-view',
  '/featured-centres/map-view',
  '/centres-popular-nearby/map-view',
  '/contacted-centres/map-view',
];

export const VALID_NEARBY_PATHS_LIST_VIEW = [
  '/childcare-centre-near-me',
  '/childcare-centre-near-me/page/[page]',
  '/centres-popular-nearby',
  '/centres-popular-nearby/page/[page]',
];

export const VALID_NEARBY_PATHS_MAP_VIEW = [
  '/childcare-centre-near-me/map-view',
  '/centres-popular-nearby/map-view',
];

export const API_VERSION = {
  VERSION_1: 'v1',
  VERSION_2: 'v2',
};

export const LIST_API_VERSION = Object.values(API_VERSION);

export const DEFAULT_META_IMAGE =
  'https://files.kindicare.com/uploads/static/app-free.png';

export const CHILDCARE_PROVIDER_ITEM = {
  slidesToShow: 7,
  slidesToScroll: 1,
  variableWidth: false,
  responsive: [
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
  ],
};

export const COLOR_EMBED_DEFAULT = {
  primaryColor: '#DB147F',
  secondaryColor: '#EFF7FF',
};

export const AWARDS_BASE_PATH = '/australia-childcare-centre-awards';

export const CRM_PACKAGES = {
  kindicareEnterprise: 'KindiCare Enterprise',
  kindicareEssentials: 'KindiCare Essentials',
  kindicarePremium: 'KindiCare Premium',
  kindicareBasic: 'KindiCare Basic',
};

export const DEFAULT_ZOOM_LEVEL = 15;
