import {
  HeartFilledSecondIcon,
  HeartOutlineSecondIcon,
} from '@uikit/commons/SVGIcons';
import { Radio } from 'antd';
import styled from 'styled-components';

const RadioGroupStyled = styled(Radio.Group)`
  display: flex;

  .rating-heart-wrapper {
    font-size: 24px;
    transition: all 0.05s;
    margin: 0;

    &:hover {
      transform: scale(1.1);
    }

    &:active {
      .anticon {
        color: var(--primary) !important;
      }
    }

    &:first-child span.ant-radio + * {
      padding-left: 0;
    }
  }

  span.ant-radio + * {
    padding-right: 5px;
    padding-left: 5px;
  }

  .ant-radio {
    display: none;
  }
`;

interface Props {
  value?: number;
  onChange?: () => void;
}

const HeartRating = ({ value, onChange, ...restProps }: Props) => (
  <RadioGroupStyled value={value} onChange={onChange} {...restProps}>
    {[...Array(5)].map((_, i) => {
      const ratingValue = i + 1;
      return (
        <Radio
          value={ratingValue}
          className="rating-heart-wrapper"
          key={String(ratingValue)}
        >
          <span className="heart-icon">
            {ratingValue <= value ? (
              <HeartFilledSecondIcon
                style={{
                  color: 'var(--primary)',
                }}
              />
            ) : (
              <HeartOutlineSecondIcon
                style={{
                  color: 'var(--text-secondary)',
                }}
              />
            )}
          </span>
        </Radio>
      );
    })}
  </RadioGroupStyled>
);

export default HeartRating;
