import { richWebConfig } from 'utils';
import request from 'utils/request';

export async function getAllInboxesApi(params) {
  return request.get(`/inboxes${params || ''}`, richWebConfig);
}

export async function getMessagesByInboxIdApi(id, params) {
  return request.get(`/inboxes/${id}/messages${params}`, richWebConfig);
}

export async function postMessageApi(id, data) {
  return request.post(`/inboxes/${id}/messages`, data, richWebConfig);
}

export async function deleteInboxApi(id) {
  return request.delete(`/inboxes/${id}`, richWebConfig);
}

export async function seenMessagesApi(id, data) {
  return request.put(`/inboxes/${id}/read`, data, richWebConfig);
}

export async function getTotalUnreadEnquiresApi(params) {
  return request.get('/inboxes/total-unread', { params, ...richWebConfig });
}

export async function getInboxByIdApi(id, params) {
  return request.get(`/inboxes/${id}`, { params, ...richWebConfig });
}
