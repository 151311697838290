/* eslint-disable react-hooks/exhaustive-deps */
import { ChildOutlineIcon } from '@uikit/commons/SVGIcons';
import { RestInputContext } from '@uikit/form/RestInputContext';
import ChildrenInformation from 'components/makeEnquiry/ChildrenInformation';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ContentSteps from './ContentSteps';

const Step3 = ({ centreSlug }) => {
  const { form } = useContext(RestInputContext);

  const careOptions = useSelector(
    (state) => state.applyApplicationStep.currentCareOptions,
  );

  useEffect(() => {
    if (careOptions?.length) {
      form.setFieldsValue({
        careOptions,
      });
    }
  }, []);

  return (
    <ContentSteps
      icon={<ChildOutlineIcon />}
      title="applyApplications.step3.title"
      description="applyApplications.step3.desc"
      className="step-3-wrapper"
      required
    >
      <ChildrenInformation
        name="careOptions"
        showHeader={false}
        isSetDefaultValue={!careOptions?.length}
        isApplication
        centreSlug={centreSlug}
      />
    </ContentSteps>
  );
};

Step3.propTypes = {
  centreSlug: PropTypes.string,
};

export default Step3;
