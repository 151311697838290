import { createSlice } from '@reduxjs/toolkit';
import { convertJobFilterConfigs } from 'components/forJobSeekers/converters';
import { unionBy } from 'lodash-es';
import { getJobPosts, getJobPostsFilter, MODEL_NAME } from './actions';

const INITIAL_STATE = {
  data: [],
  loading: false,
  resourceParams: {
    page: 1,
    size: 12,
    total: 0,
    filter: {},
  },
  filterConfig: {},
};

const getJobPostsPending = (state, { meta: { arg } }) => {
  state.loading = true;

  if (arg?.options?.isRefresh) {
    state.data = [];
    state.resourceParams = INITIAL_STATE.resourceParams;
  }
};

const getJobPostsSuccess = (state, { payload, meta }) => {
  state.data = unionBy([...state.data, ...(payload?.results || [])], 'id');
  state.resourceParams = {
    ...meta.arg?.data,
    total: payload?.paging?.total,
  };
  state.loading = false;
};

const getJobPostsFail = (state) => {
  state.loading = false;
};

const getJobPostsFilterSuccess = (state, { payload }) => {
  const filters = convertJobFilterConfigs(payload?.sections);
  state.filterConfig = filters;
};

const jobPosts = createSlice({
  name: MODEL_NAME,
  initialState: INITIAL_STATE,
  extraReducers: {
    [getJobPosts.pending]: getJobPostsPending,
    [getJobPosts.fulfilled]: getJobPostsSuccess,
    [getJobPosts.rejected]: getJobPostsFail,

    [getJobPostsFilter.fulfilled]: getJobPostsFilterSuccess,
  },
});

export default jobPosts.reducer;
