import styled from 'styled-components';

const FormStyles = styled.div`
  .form-list {
    &__content {
      padding: 16px 64px;
    }
    &__list-item {
      margin-bottom: 32px;
    }
    &__remove-button {
      font-size: 16px;
      color: var(--text-secondary);
    }
    &__list-item-title {
      font-weight: bold;
      font-size: 20px;
      line-height: 32px;
      text-transform: uppercase;
      color: var(--primary);
    }
    &__desc {
      color: var(--color-grey-l2);
    }
    &__add-new {
      margin-top: 24px;
    }
  }

  .children-form-box,
  .child-info-box {
    box-sizing: border-box;
    border-radius: 24px;
  }
  .children-form-box {
    background: #eff7ff;
    border: 1px solid var(--secondary);
  }
  .child-info-box {
    background: #ffffff;
    border: 1px solid var(--border-color-light);
  }

  .child-info {
    padding: 30px;
    cursor: pointer;
  }

  .children-form-body {
    padding: 0 30px 30px 30px;
  }

  @media only screen and (max-width: 576px) {
    .form-list {
      &__content {
        padding: 20px !important;
      }
      &__list-item {
        padding: 20px !important;
      }
    }
    .child-info {
      padding: 20px;
    }
    .children-form-body {
      padding: 0 20px 20px 20px;
    }
  }
`;

export default FormStyles;

export const ChildrenFormStyles = styled.div`
  input,
  .ant-picker,
  .ant-select-selector {
    border-color: transparent !important;
    background: #fff !important;
  }
  .ant-input[disabled],
  .ant-select-disabled.ant-select:not(.ant-select-customize-input)
    .ant-select-selector,
  .ant-picker-input > input[disabled] {
    color: inherit !important;
  }
`;
