import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { PREFIX_URL_API } from 'configs/constants';
import { getBaseURLApi } from 'utils';

export const centresRecentSearchesApi = rtkCreator(
  'centresRecentSearches',
  'centres/recent-searches',
  {
    baseURL: getBaseURLApi(PREFIX_URL_API.public),
  },
);

export const { useGetAllCentresRecentSearchesQuery } = centresRecentSearchesApi;
