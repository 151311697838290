import { useDispatch, useSelector } from 'react-redux';
import { CaretDownFilled, UserOutlined } from '@ant-design/icons';
import { logout } from '@redux/auth/actions';
import { Dropdown, Menu, Avatar } from 'antd';
import Link from 'next/link';
import { useTranslation } from 'react-i18next';
import { getCurrentUserSelector } from '@redux/auth/selectors';
import {
  ApplicationFilledIcon,
  BabyPrimaryFillIcon,
  BriefcaseFillIcon,
  LogoutIcon,
} from '@uikit/commons/SVGIcons';
import { MenuItemStyled, UserMenuStyled } from './styles';

const PROFILE_MENU = [
  {
    key: 'parentProfile',
    text: 'header.parentProfile',
    href: '/profile',
    Icon: <BabyPrimaryFillIcon />,
  },
  {
    key: 'childcareApplications',
    text: 'header.childcareApplications',
    href: '/applications',
    Icon: <ApplicationFilledIcon className="text-highlight size-20" />,
    hasMenuDivider: true,
  },
  {
    key: 'jobProfile',
    text: 'header.jobProfile',
    href: '/early-childhood-and-childcare-jobs?t=jobProfile',
    Icon: <BriefcaseFillIcon />,
  },
  {
    key: 'jobApplications',
    text: 'header.jobApplications',
    href: '/early-childhood-and-childcare-jobs?t=myJobs&tab=applied',
    Icon: <ApplicationFilledIcon className="text-green size-20" />,
  },
];

const UserInfo = () => {
  const { t } = useTranslation();
  const currentUser = useSelector(getCurrentUserSelector);
  const dispatch = useDispatch();

  const logoutAction = () => dispatch(logout());

  const menu = (
    <UserMenuStyled>
      {PROFILE_MENU.map((menu) => (
        <>
          <MenuItemStyled key={menu.href}>
            {menu.Icon}
            <Link href={menu.href}>{t(menu.text)}</Link>
          </MenuItemStyled>

          {!!menu?.hasMenuDivider && (
            <Menu.Divider className="h-2 bg-border-light" />
          )}
        </>
      ))}
      <Menu.Divider className="h-2 bg-border-light" />
      <MenuItemStyled onClick={logoutAction} key="logout">
        <LogoutIcon className="size-20" />
        {t('header.logout')}
      </MenuItemStyled>
    </UserMenuStyled>
  );

  return (
    <div className="header__user-info">
      <Dropdown
        overlay={menu}
        trigger={['click']}
        placement="bottomRight"
        overlayStyle={{
          position: 'fixed',
        }}
      >
        <div className="dropdown-user">
          <Avatar
            src={currentUser?.avatar}
            size={30}
            icon={<UserOutlined />}
            className={`avatar-user ${currentUser?.avatar ? 'has-avatar' : ''}`}
          />
          <CaretDownFilled className="arrow-down" />
        </div>
      </Dropdown>
    </div>
  );
};

export default UserInfo;
