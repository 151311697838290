import { useState } from 'react';
import { Button, Divider, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { register } from '@redux/auth/actions';
import { setModalConfig } from '@redux/config/actions';
import ModalCustom from '@uikit/commons/ModalCustom';
import { useTranslation } from 'react-i18next';
import { setEmailVerify } from '@redux/config/slice';
import Link from 'next/link';
import { handleApiError } from 'utils/requestErrorUtils';
import {
  getConfirmPasswordRules,
  getFirstOrLastNameRules,
  getPasswordRules,
} from 'utils/validate';
import { useRouter } from 'next/router';
import { omit } from 'lodash-es';
import FormInput from '@uikit/form/FormInput';
import AuthActions from '@uikit/modals/AuthActions';
import AuthModalStyles from '@uikit/modals/AuthModal/styles';
import { getUrlAfterLogin } from 'utils/dataUtils';

const RegisterModal = () => {
  const [form] = Form.useForm();
  const modalVisible = useSelector((state) => state.config.modals);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const loginByRole = useSelector((state) => state.auth.loginByRole);
  const { push, prefetch, asPath } = useRouter();

  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(setModalConfig({ signUpModal: false }));
  };

  const loginNavigate = () => {
    onCancel();
    dispatch(setModalConfig({ loginModal: true, signUpModal: false }));
  };

  const onFinish = (values) => {
    dispatch(register({ data: omit(values, ['confirmPassword']) })).then(
      async (response) => {
        if (response?.payload?.errors) {
          handleApiError(response?.payload, form);
        }
        if (response?.payload?.success) {
          dispatch(
            setModalConfig({
              signUpModal: false,
              verifyEmailModal: true,
            }),
          );
          dispatch(setEmailVerify(values.email));
          const redirectUrl = getUrlAfterLogin(loginByRole, asPath);
          if (redirectUrl) {
            await prefetch(redirectUrl);
            push(redirectUrl);
          }
        }
      },
    );
    setLoading(false);
  };

  const handleHideSignUpForm = () => {
    const config = JSON.parse(localStorage.getItem('persist:config'));
    localStorage.setItem(
      'persist:config',
      JSON.stringify({
        ...config,
        modals: {},
      }),
    );
    window.open('/terms-and-conditions', '_blank');
  };

  return (
    <ModalCustom
      visible={modalVisible.signUpModal}
      onCancel={onCancel}
      footer={null}
    >
      <AuthModalStyles>
        <div className="auth__title">{t('auth.signUpTitle')}</div>
        <Form
          layout="vertical"
          onFinish={onFinish}
          form={form}
          autoComplete="off"
        >
          <FormInput
            name="firstName"
            label="input.firstName.label"
            placeholder="input.firstName.placeholder"
            required
            messageRequire="input.firstName.validateMsg.required"
            rules={getFirstOrLastNameRules('firstName')}
          />
          <FormInput
            name="lastName"
            label="input.lastName.label"
            placeholder="input.lastName.placeholder"
            required
            messageRequire="input.lastName.validateMsg.required"
            rules={getFirstOrLastNameRules('lastName')}
          />
          <FormInput
            name="email"
            label="input.email.label"
            placeholder="input.email.placeholder"
            required
            messageRequire="input.email.validateMsg.required"
            rules={[
              {
                type: 'email',
                message: t('input.email.validateMsg.invalid'),
              },
            ]}
          />
          <FormInput
            name="password"
            required
            label="input.password.label"
            placeholder="input.password.placeholder"
            messageRequire="input.password.validateMsg.required"
            ContentComponent={Input.Password}
            autoComplete="new-password"
            rules={getPasswordRules()}
          />

          <FormInput
            name="confirmPassword"
            label="input.confirmPassword.label"
            placeholder="input.confirmPassword.placeholder"
            messageRequire="input.confirmPassword.validateMsg.required"
            dependencies={['password']}
            required
            ContentComponent={Input.Password}
            rules={getConfirmPasswordRules()}
            autoComplete="new-password"
          />

          <div className="auth__terms-policy mt-20 size-l">
            {`${t('auth.termsPolicy.l1')} `}
            <b className="text-highlight pointer">
              <span onClick={handleHideSignUpForm} role="presentation">
                {t('auth.termsPolicy.l2')}
              </span>{' '}
              & <Link href="/privacy-policy">{t('auth.termsPolicy.l3')}</Link>
            </b>
          </div>

          <Button
            className="submit-btn"
            type="primary"
            htmlType="submit"
            loading={loading}
            size="large"
          >
            {t('button.signUp')}
          </Button>

          <Divider className="size-18">{t('common.or')}</Divider>

          <AuthActions onCancel={onCancel} />
          <div className="auth__question">
            {`${t('auth.questionLogin')} `}
            <b onClick={loginNavigate} role="presentation">
              {t('auth.loginNow')}
            </b>
          </div>
        </Form>
      </AuthModalStyles>
    </ModalCustom>
  );
};

export default RegisterModal;
