import {
  ChildOutlineIcon,
  DollarOutlineIcon,
  InfoOutlineIcon,
  UserCircleIcon,
} from '@uikit/commons/SVGIcons';
import { Divider } from 'antd';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { formatCurrency, upperFirstChar } from 'utils/tools';
import StepTitle from '@uikit/commons/StepTitle';
import PropTypes from 'prop-types';
import { formatDate } from 'utils/textUtils';
import CentreInfo from './CentreInfo';
import ProfileInfo from './ProfileInfo';
import { ApplicationInfoStyles } from './styles';

const ApplicationInfo = ({
  centre,
  centreServices,
  dataApply,
  isFormatDate,
  fee,
}) => {
  const { t } = useTranslation();

  const formatCentreService = useCallback(
    (id) => {
      const item = centreServices?.find((item) => item?.id === id);
      return item
        ? `${item?.serviceType?.name || ''} - ${
            item?.serviceAgeGroup?.name || ''
          }`
        : null;
    },
    [centreServices],
  );

  const getParentInfo = useCallback(
    (data) => [
      {
        label: 'applyApplications.labels.firstName',
        value: data?.firstName,
      },
      {
        label: 'applyApplications.labels.lastName',
        value: data?.lastName,
      },
      {
        label: 'applyApplications.labels.dob',
        value: isFormatDate
          ? formatDate(data?.dateOfBirth, 'DD-MM-YYYY')
          : data?.dateOfBirth,
      },
      {
        label: 'applyApplications.labels.phone',
        value: data?.phoneNumber,
      },
      {
        label: 'applyApplications.labels.email',
        value: data?.email,
      },
      {
        label: 'applyApplications.labels.centrelink',
        value: data?.centrelinkCRN,
      },
      {
        label: 'applyApplications.labels.address',
        value: data?.address,
      },
      {
        label: 'applyApplications.labels.postcode',
        value: data?.postCode,
      },
      {
        label: 'applyApplications.labels.state',
        value: data?.state,
      },
      {
        label: 'applyApplications.labels.suburb',
        value: data?.suburb,
      },
      {
        label: 'applyApplications.labels.country',
        value: data?.country,
      },
    ],
    [isFormatDate],
  );

  const getChildInfo = useCallback(
    (child) => [
      {
        label: 'applyApplications.labels.firstName',
        value: child?.firstName,
      },
      {
        label: 'applyApplications.labels.lastName',
        value: child?.lastName,
      },
      {
        label: 'applyApplications.labels.gender',
        value: child?.gender,
      },
      {
        label: 'applyApplications.labels.dobOrDueDate',
        value: isFormatDate
          ? formatDate(child?.dateOfBirth, 'DD-MM-YYYY')
          : child?.dateOfBirth,
      },
      {
        label: 'applyApplications.labels.allergies',
        value: child?.allergies ? t('common.yes') : t('common.no'),
      },
      {
        label: 'applyApplications.labels.specialNeeds',
        value: child?.specialNeeds ? t('common.yes') : t('common.no'),
      },
      {
        label: 'applyApplications.labels.daysCare',
        value: child?.requiredDays
          ?.map((item) => upperFirstChar(item))
          ?.join(', '),
        breakpoints: { xs: 24, sm: 24, lg: 16 },
      },
      {
        label: 'applyApplications.labels.startDate',
        value: isFormatDate
          ? formatDate(child?.startDay, 'DD-MM-YYYY')
          : child?.startDay,
        breakpoints: { xs: 24, sm: 12, lg: 8 },
      },
      {
        label: 'applyApplications.labels.services',
        value: child?.serviceIds
          ?.map((id) => formatCentreService(id))
          ?.join(', '),
        breakpoints: { xs: 24 },
      },
    ],
    [isFormatDate, t, formatCentreService],
  );

  return (
    <ApplicationInfoStyles>
      <CentreInfo data={centre} />
      <Divider />
      <ProfileInfo
        title="applyApplications.infoTitle.parentGuardian"
        icon={<UserCircleIcon />}
        data={getParentInfo(dataApply?.parentInfo)}
      />
      <Divider />
      {dataApply?.additionalParentInfo?.firstName ? (
        <>
          <ProfileInfo
            title="applyApplications.infoTitle.addtionalParent"
            icon={<InfoOutlineIcon />}
            data={getParentInfo(dataApply?.additionalParentInfo)}
          />
          <Divider />
        </>
      ) : null}

      {dataApply?.careOptions?.map((item, index) => (
        <div key={String(index)}>
          <ProfileInfo
            title={t('applyApplications.infoTitle.child', {
              index: index + 1,
            })}
            icon={<ChildOutlineIcon />}
            data={getChildInfo(item)}
          />
          <Divider />
        </div>
      ))}
      <div>
        <StepTitle
          icon={<DollarOutlineIcon />}
          iconRounded={false}
          title="applyApplications.infoTitle.applicationFee"
          titleSize={20}
          className="mb-24"
        />
        <div className="application-fee">
          {fee
            ? `Fee ${formatCurrency(fee)}`
            : t('applyApplications.applicationFeeDesc')}
        </div>
      </div>
    </ApplicationInfoStyles>
  );
};

ApplicationInfo.propTypes = {
  centreServices: PropTypes.array,
  centre: PropTypes.object,
  dataApply: PropTypes.object,
  isFormatDate: PropTypes.bool,
  fee: PropTypes.number,
};

export default ApplicationInfo;
