import request from 'utils/request';
import { richWebConfigVer2 } from 'utils';

export async function getJobDetailsApi(slug, params) {
  return request.get(`/job-seekers/screens/posts/details?slug=${slug}`, {
    params,
    ...richWebConfigVer2,
  });
}

export async function getJobDetailsByPostIdApi(id, params) {
  return request.get(`/job-seekers/screens/posts/details?postId=${id}`, {
    params,
    ...richWebConfigVer2,
  });
}

export async function getWorkplaceJobDetailApi(id, params) {
  return request.get(`job-seekers/posts/details/workplace?postId=${id}`, {
    params,
    ...richWebConfigVer2,
  });
}

export async function addFavoriteJobApi(data) {
  return request.post(`job-seekers/posts/favorites`, data, richWebConfigVer2);
}

export async function deleteFavoriteJobsApi(id) {
  return request.delete(
    `/job-seekers/posts/favorites?postId=${id}`,
    richWebConfigVer2,
  );
}

export async function jobSeekerViewedApi(postId, data) {
  return request.put(
    `/job-seekers/posts/viewed?postId=${postId}`,
    data,
    richWebConfigVer2,
  );
}

export const jobSeekersApplyApi = ({ postId }) =>
  request.post(`job-seekers/applications`, { postId }, richWebConfigVer2);

export const showingPopupApplyApi = (isShowingPopup, data) =>
  request.post(
    `job-seekers/applications?isShowingPopup=${isShowingPopup}`,
    data,
    richWebConfigVer2,
  );

export const cancelJobApplicationApi = (params) =>
  request.delete(`job-seekers/applications/cancel`, {
    params,
    ...richWebConfigVer2,
  });
