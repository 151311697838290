import React from 'react';
import { DOWNLOAD_APP_URL } from 'configs/constants';
import { CloseOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getIsShowSmartBanner } from '@redux/configLocal/selectors';
import { setShowSmartBanner } from '@redux/configLocal/slice';
import { SmartBannerContainer } from './styles';

interface Props {
  className?: string;
}

const SmartBannerCustom = ({ className }: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isShowSmartBanner = useSelector(getIsShowSmartBanner);

  const handleCloseBanner = () => {
    dispatch(setShowSmartBanner(false));
  };

  return (
    isShowSmartBanner && (
      <SmartBannerContainer className={className}>
        <div className="flex-center">
          <CloseOutlined
            className="text-gray-white size-10"
            onClick={handleCloseBanner}
          />
          <Image src="/app-touch-icon.png" alt="app touch icon" />
          <div>
            <div className="fw-bold">KindiCare</div>
            <div className="text-gray-white size-11">{t('header.openApp')}</div>
          </div>
        </div>
        <Button
          type="primary"
          onClick={handleCloseBanner}
          className="fw-bold rounded-12"
        >
          <a href={DOWNLOAD_APP_URL}> {t('common.view')}</a>
        </Button>
      </SmartBannerContainer>
    )
  );
};

export default SmartBannerCustom;
