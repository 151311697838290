import { richWebConfig } from 'utils';
import request from 'utils/request';

export async function postSubsidyCalculatorApi(data) {
  return request.post('/subsidy/calculator', data, richWebConfig);
}

export async function saveSubsidyCalculatorApi(data) {
  return request.post('/subsidy/details', data, richWebConfig);
}
