import { Drawer, Menu, Tag } from 'antd';
import styled, { css } from 'styled-components';
import { respondTo } from 'utils/variables';

export const HeaderStyles = styled.div`
  overflow: hidden;
  background: white;
  border-bottom: 1px solid #e8eaee;

  &.header-pink-bg {
    background-color: #fff4fc;
    .header-wrapper,
    .header-menu {
      background-color: #fff4fc;
    }
  }

  .ant-layout-header {
    padding: 0 !important;
    border-bottom: none;
  }

  .header-wrapper {
    padding: 10px 0;
    background: #fff;
    height: 100%;

    .header-center {
      height: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 10px;
      cursor: pointer;

      img {
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .header-logo {
    margin-right: 10px;
    img {
      height: 30px;
    }
    cursor: pointer;
  }

  .header-search-input {
    max-width: 350px;
    border-radius: 10px;
    color: white;
    border: none;
    border-radius: 5px;
    .ant-input-suffix {
      color: #111111;
      height: 100%;
      border-left: 1px solid #efefef;
    }
  }

  .mobile-menu-icon {
    display: none;
    cursor: pointer;
    width: 30px;
    &:hover {
      transform: scale(1.1);
    }
  }

  .header-login-btn {
    border-color: inherit;

    &:hover {
      transform: scale(1.05);
    }
  }

  .header-desktop-menu {
    overflow: hidden;
    a {
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .hiddenCheckbox {
    display: none;
  }

  .mail-box {
    padding-top: 29px;
    padding-right: 8px;
  }
  .user-info {
    .ant-avatar {
      width: 40px !important;
      height: 40px !important;
    }
  }

  .mail-box-auth {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header-btn-icon {
    background-color: #f6f9ff;
    width: 40px !important;
    height: 40px !important;
    display: flex;
    align-items: center;
    justify-content: center;

    .anticon {
      font-size: 16px;
      color: var(--text-secondary);
    }
    :hover {
      background-color: #fff0fb;
      .anticon {
        color: var(--primary);
      }
    }
    &-selected {
      background-color: #fff0fb;
      border: none;
      .anticon {
        color: var(--primary);
      }
    }
  }

  @media only screen and (max-width: 1100px) {
    .header-desktop-menu,
    .header-search-input {
      display: none;
    }
    .mobile-menu-icon {
      display: block;
    }
    .ant-layout-header {
      padding: 0px 20px;
    }
    .header-login-btn {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .mobile-menu-icon {
      margin-left: 10px;
    }
  }

  .dropdown-user {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;

    .avatar-user {
      background: #2d1f21;
      border: 2px solid rgba(255, 255, 255, 0.6);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 5px;
      cursor: pointer;

      &.has-avatar {
        background: var(--avatar-bg);
        border: 1px solid var(--border-color-light);
      }

      > .anticon {
        font-size: 16px;
      }
    }

    .arrow-down {
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 576px) {
    .header-center {
      width: 100px;
    }

    .header-right {
      .header-space-group-btn {
        gap: 10px !important;
      }
      .languages-dropdown,
      .group-header {
        display: none !important;
      }
      @media only screen and (max-width: 425px) {
        margin-right: -20px !important;
      }
      @media only screen and (max-width: 1100px) {
        margin-right: 10px;
      }
    }
  }

  ${({ hideHeader }) =>
    hideHeader &&
    css`
      @media only screen and (max-width: ${hideHeader.breakpoint}px) {
        display: none;
      }
    `}
`;

export const DrawerMenuMobileStyles = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .header-group-button {
    margin-top: 30px;
    .ant-btn {
      width: 100%;
    }
  }
  .space-wrapper-modal {
    text-align: center;
    width: 100%;
  }

  .ant-drawer-header {
    border: 0;
    flex-direction: row-reverse;
    align-items: center;

    .ant-drawer-header-title {
      justify-content: flex-end;
    }

    .ant-drawer-extra .header-logo {
      max-height: 37px;
    }

    .ant-drawer-title {
      text-transform: unset;
      text-align: center;
      font-weight: 600;
      font-size: 20px;
    }
  }

  .header-search-input {
    .ant-input-prefix {
      margin-right: 20px;
    }
  }

  .active-menu-item {
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 3px;
      background-color: var(--primary);
      border-radius: 3px;
    }
  }
`;

export const HeaderMenuStyles = styled.div`
  .mainHeader {
    background: transparent;
    transition: all 0.3s linear;
  }
  .header-menu {
    width: 300px;
    border-bottom: none !important;
    .ant-menu-item:not(:hover) {
      color: var(--text-secondary);
    }
    .ant-menu-item {
      display: flex;
      align-items: center;
    }
    &__item {
      display: flex;
      align-items: center;
    }
    &__item-flag {
      margin-left: 5px;
      height: 20px;
    }
  }
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item,
  .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu {
    padding: 0 16px !important;
  }
  .header-mobile-menu {
    .ant-menu-item {
      text-align: center !important;
    }
  }
`;

export const LoginMenuStyled = styled(Menu)`
  padding: 0;
  border-radius: 16px;
  background: #ffffff;

  .ant-dropdown-menu-item {
    padding: 20px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #44444f;
  }

  .menu-item-1 {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .menu-item-2 {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }

  .ant-dropdown-menu-item-divider {
    margin: 0;
    border: 2px solid var(--border-color-light);
  }
`;

export const GroupActionsHeaderStyles = styled.div`
  .mobile-btn-login {
    display: none;
  }

  ${respondTo.xs`
    .ant-btn {
      font-weight: 700;
    }
  
    .login-menu-btn {
      width: 100%;
      font-weight: 700;
    }
    
    .btn-login {
      width: 100%;
    }
    
    .mobile-btn-login {
      display: inline-flex;
    }
  
    > .ant-space > .ant-space-item:nth-child(3) {
      display: none;
    }

    > .ant-space {
      width: 100%;
      padding: 16px;
    }

    .btn-second-primary {
      width: 100%;
    }

    .mobile-btn-login {
      width: 100%;
      .ant-btn {
        width: 100%;
      }
    }
  `}
`;

export const UserMenuStyled = styled(Menu)`
  min-width: 150px;
  padding: 0;
`;

export const MenuItemStyled = styled(Menu.Item)`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #44444f;
  padding: 6px 24px;
  font-weight: 600;

  .ant-dropdown-menu-title-content {
    display: flex;
    align-items: center;

    .anticon {
      margin-right: 14px;
    }
  }

  &.ant-dropdown-menu-item {
    padding: 16px 22px;
    &:first-child {
      border-radius: 12px 12px 0 0;
      padding-top: 20px;
    }

    &:last-child {
      border-radius: 0 0 12px 12px;
      padding-bottom: 20px;
    }
  }

  @media only screen and (max-width: 576px) {
    font-size: 14px;
  }
`;

export const SubMenuItemStyle = styled(Menu.Item)`
  border-bottom: ${(props) =>
    !props.disableBorderBottom && '1px solid var(--border-color-light)'};
  height: 57px !important;
  display: flex;
  align-items: center;
  margin: 0 !important;
`;

export const TagStyle = styled(Tag)`
  background-color: var(--bg-green);
  color: var(--text-white);
`;

export const SubMenuStyle = styled(Menu.SubMenu)`
  font-weight: ${(props) =>
    props.isHighlight ? '700 !important' : '600 !important'};
  color: ${(props) =>
    props.isHighlight ? 'var(--primary)' : 'var(--text-primary)'};
`;
