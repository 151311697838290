import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const organizationsDiscountApi = rtkCreator(
  'organizationsDiscount',
  'job-seekers/screens/organizations/discount',
  richWebConfigVer2,
);

export const { useGetAllOrganizationsDiscountQuery } = organizationsDiscountApi;

export const { getAllOrganizationsDiscount } =
  organizationsDiscountApi.endpoints;
