import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { REQUEST_TYPE } from '@redux/crudCreator/dataProvider';
import { richWebConfig } from 'utils';

export const centresNearbyPopularApi = rtkCreator(
  'centresNearbyPopular',
  'centres/nearby/popular',
  richWebConfig,
  (builder) => ({
    getAllCentresNearbyPopularComparison: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_ALL,
        customApiResource: 'centres/nearby/popular/comparison',
      }),
    }),
  }),
);

export const {
  useGetAllCentresNearbyPopularQuery,
  useGetAllCentresNearbyPopularComparisonQuery,
} = centresNearbyPopularApi;
