import { useContext, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Form, Upload } from 'antd';
import {
  CameraOutlined,
  ExclamationCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash-es';
import { customRequestUploadFile } from 'api/uploadMedia';
import { UserPlusFillIcon } from '@uikit/commons/SVGIcons';
import { RestInputContext } from '@uikit/form/RestInputContext';
import FormAvatarUploadStyles from './styles';

const formatInitialImage = (url) => ({
  response: url,
  uid: new Date().toDateString(),
  name: new Date().toDateString(),
});

const FormUploadAvatar = ({
  name,
  label,
  required,
  messageRequire = 'error.required',
  width = 128,
  height = 128,
  className,
  onChange,
  defaultAvatar,
  prefixKey,
}) => {
  const { t } = useTranslation();
  const { record } = useContext(RestInputContext);

  const [image, setImage] = useState(formatInitialImage(get(record, name)));

  const handleChange = (info) => {
    setImage(info.file);
  };

  const renderImage = useMemo(() => {
    if (image?.status === 'uploading') {
      return (
        <Avatar
          icon={<LoadingOutlined className="loading-icon" />}
          style={{ width, height }}
        />
      );
    }

    if (image.status === 'error') {
      return (
        <Avatar
          icon={<ExclamationCircleOutlined className="error-icon" />}
          style={{ width, height }}
        />
      );
    }

    return (
      <Avatar
        icon={<UserPlusFillIcon className="user-icon" />}
        style={{ width, height }}
        src={image?.response || defaultAvatar}
        className="user-avatar"
      />
    );
  }, [image, defaultAvatar, width, height]);
  const normFile = (e) => e.file?.response;

  return (
    <FormAvatarUploadStyles className={className}>
      <Form.Item
        name={name}
        {...(label && {
          label: t(label),
        })}
        valuePropName="file"
        getValueFromEvent={normFile}
        rules={[
          {
            required,
            message: t(messageRequire),
          },
        ]}
      >
        <Upload
          showUploadList={false}
          accept="image/*"
          name="logo"
          listType="picture"
          onChange={handleChange}
          customRequest={(data) =>
            customRequestUploadFile(data, onChange, prefixKey)
          }
        >
          <div className="image-uploader">
            {renderImage}
            <div className="upload-photo">{t('common.uploadPhoto')}</div>
            <div className="image-hover" style={{ width, height }}>
              <CameraOutlined className="image-hover-icon" />
            </div>
          </div>
        </Upload>
      </Form.Item>
    </FormAvatarUploadStyles>
  );
};

FormUploadAvatar.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.string,
  messageRequire: PropTypes.string,
  onChange: PropTypes.func,
  defaultAvatar: PropTypes.string,
  prefixKey: PropTypes.string,
};

export default FormUploadAvatar;
