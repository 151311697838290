import { Form, Image } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ApplySuccessFullyStyle } from 'components/applyJobSeeker/Modals/styles';

interface Props {
  organizationImageUrl: string;
  seekerImageUrl: string;
}

const FormShowSuccess = ({ organizationImageUrl, seekerImageUrl }: Props) => {
  const { t } = useTranslation();
  return (
    <Form layout="vertical">
      <ApplySuccessFullyStyle className="mb-16">
        <div className="flex-center">
          <Image
            src="/images/job-listings/application-bg.png"
            alt="application-img"
            preview={false}
          />
        </div>
        <div className="lh-32 text-center fw-bold size-24 text-green-teal-900">
          {t('forJobSeekers.details.applyFlow.youSuccessfullyApplied')}
        </div>
        {seekerImageUrl && organizationImageUrl && (
          <div className="flex-center avatar_double">
            <div className="avatar--user">
              <Image
                width={85}
                height={85}
                className="avatar--user-image"
                src={seekerImageUrl}
                alt="application-img"
                preview={false}
              />
            </div>
            <div className="avatar--organization">
              <Image
                width={85}
                height={85}
                className="avatar--organization-image"
                src={organizationImageUrl}
                alt="application-img"
                preview={false}
              />
            </div>
          </div>
        )}
      </ApplySuccessFullyStyle>
    </Form>
  );
};

export default FormShowSuccess;
