import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { StepTitleStyles } from './styles';

const StepTitle = ({
  icon,
  title,
  required,
  description,
  iconRounded = true,
  titleSize,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <StepTitleStyles
      titleSize={titleSize}
      className={`${className ?? ''} ${iconRounded ? 'icon-rounded' : ''}`}
    >
      <div className="step-title-wrapper">
        <div className="icon-wrapper">{icon}</div>
        <h2 className="step-title">
          {t(title)}
          {required && <sup>*</sup>}
        </h2>
      </div>
      {description && <p className="step-desc">{t(description)}</p>}
    </StepTitleStyles>
  );
};

StepTitle.propTypes = {
  icon: PropTypes.element,
  title: PropTypes.string,
  required: PropTypes.bool,
  description: PropTypes.string,
  iconRounded: PropTypes.bool,
  titleSize: PropTypes.number,
  className: PropTypes.string,
};

export default StepTitle;
