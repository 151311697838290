import { createDraftSafeSelector } from '@reduxjs/toolkit';

const getRawIsLoadedTopCarouselHome = (state) =>
  state.configLocal.carouselHomeLoaded.topPicks;

const getRawIsLoadedCarouselByKey = (state, key) =>
  state.configLocal.carouselHomeLoaded[key];

const getRawIsShowSmartBanner = (state) => state.configLocal.isShowSmartBanner;

const getRawFetchedUserMe = (state) => state.configLocal.fetchedUserMe;

const getRawConfigLocal = (state) => state.configLocal;

export const getIsLoadedTopCarouselHome = createDraftSafeSelector(
  [getRawIsLoadedTopCarouselHome],
  (data) => data,
);

export const geIsLoadedCarouselByKey = createDraftSafeSelector(
  [getRawIsLoadedCarouselByKey],
  (data) => data,
);

export const getIsShowSmartBanner = createDraftSafeSelector(
  [getRawIsShowSmartBanner],
  (data) => data,
);

export const getConfigLocal = createDraftSafeSelector(
  [getRawConfigLocal],
  (data) => data,
);

export const getFetchedUserMe = createDraftSafeSelector(
  [getRawFetchedUserMe],
  (data) => data,
);
