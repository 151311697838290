import { createSlice } from '@reduxjs/toolkit';
import { getJobApplicationCardInfo } from './actions';

const initialState = {
  cardInfo: {
    data: {},
    loading: false,
    error: null,
  },
};

const { reducer } = createSlice({
  name: 'jobDetails',
  initialState,
  extraReducers: {
    [getJobApplicationCardInfo.pending]: (state) => {
      state.cardInfo.loading = true;
    },
    [getJobApplicationCardInfo.fulfilled]: (state, { payload }) => {
      state.cardInfo.data = payload?.data;
      state.cardInfo.loading = false;
    },
    [getJobApplicationCardInfo.rejected]: (state, { payload }) => {
      state.cardInfo.error = payload || null;
      state.cardInfo.loading = false;
    },
  },
});

export default reducer;
