import qs, { IStringifyOptions } from 'qs';
import request from 'utils/request';
import { AxiosRequestConfig } from 'axios';

export const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, data, params, headers }: AxiosRequestConfig) => {
    try {
      const result = await request({
        url,
        method,
        data,
        params,
        headers,
        baseURL: baseUrl || process.env.NEXT_PUBLIC_API_URL,
      });
      return { data: result.data };
    } catch (axiosError) {
      const err = axiosError;
      return {
        error: {
          status: err.response?.status,
          data: err.response?.data || err.message,
        },
      };
    }
  };

export const optionQuery: IStringifyOptions = {
  addQueryPrefix: true,
  arrayFormat: 'brackets',
  skipNulls: true,
};

export const stringifyObjectWithBrackets = (
  object: object,
  options: IStringifyOptions,
) =>
  qs.stringify(object, {
    addQueryPrefix: true,
    arrayFormat: 'brackets',
    skipNulls: true,
    ...options,
  });
