import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const jobSearchSuggestionApi = rtkCreator(
  'jobSearchSuggestion',
  'job-seekers/posts/search-suggestion',
  richWebConfigVer2,
);

export const {
  useGetAllJobSearchSuggestionQuery,
  useLazyGetAllJobSearchSuggestionQuery,
} = jobSearchSuggestionApi;

export const searchOrganizationsSuggestionsApi = rtkCreator(
  'searchOrganizationsSuggestions',
  'job-seekers/me/experiences/organizations',
  richWebConfigVer2,
);

export const { useLazyGetAllSearchOrganizationsSuggestionsQuery } =
  searchOrganizationsSuggestionsApi;
