import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfig } from 'utils';

export const categoriesApi = rtkCreator(
  'categories',
  'categories',
  richWebConfig,
);

export const { useGetAllCategoriesQuery, useGetByIdCategoriesQuery } =
  categoriesApi;
