import { convertRequestParams } from '@redux/crudCreator/dataProvider';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getStatisticsCentresApi,
  getStatisticsCentreServicesApi,
  getStatisticsRatingsApi,
} from 'api/statistics';
import { apiWrapper } from '@redux/reduxUtils';

interface PayloadType {
  data?: object;
}

export const getStatisticsRatings = createAsyncThunk(
  'statistics/getStatisticsRatings',
  async (payload: PayloadType, thunkAPI) => {
    try {
      const convertRequest = convertRequestParams('GET_ALL', payload.data, {
        isDefaultOrderBy: false,
      });

      const response = await apiWrapper(
        {},
        getStatisticsRatingsApi,
        convertRequest,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getStatisticsCentres = createAsyncThunk(
  'statistics/getStatisticsCentres',
  async (payload: PayloadType, thunkAPI) => {
    try {
      const convertRequest = convertRequestParams('GET_ALL', payload.data, {
        isDefaultOrderBy: false,
      });

      const response = await apiWrapper(
        {},
        getStatisticsCentresApi,
        convertRequest,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getStatisticsCentreServices = createAsyncThunk(
  'statistics/getStatisticsCentreServices',
  async (payload: PayloadType, thunkAPI) => {
    try {
      const convertRequest = convertRequestParams('GET_ALL', payload.data, {
        isDefaultOrderBy: false,
      });

      const response = await apiWrapper(
        {},
        getStatisticsCentreServicesApi,
        convertRequest,
      );

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);
