import {
  AudioOutlined,
  EnvironmentOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FilePdfOutlined,
  FilePptOutlined,
  FileTextOutlined,
  FileWordOutlined,
  FileZipOutlined,
  ManOutlined,
  RobotOutlined,
  ShopOutlined,
  StarOutlined,
  VideoCameraOutlined,
  WomanOutlined,
} from '@ant-design/icons';
import {
  DollarCircleFillIcon,
  FileFillIcon,
  FlagFillIcon,
  ListViewIcon,
  MapViewIcon,
  MoreIcon,
  VacanciesFillIcon,
} from '@uikit/commons/SVGIcons';
import { FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH } from 'components/forJobSeekers/constants';

export const GENDERS = [
  {
    value: 'Male',
    text: 'gender.male',
    textChild: 'genderChild.male',
    color: '#32A4FC',
    icon: ManOutlined,
    background: '#E4EDFF',
    transform: 'none',
  },
  {
    value: 'Female',
    text: 'gender.female',
    textChild: 'genderChild.female',
    color: '#DB147F',
    icon: WomanOutlined,
    background: '#FFE4F9',
    transform: 'rotate(-27.29deg)',
  },
];

export const YES_NO_SELECT = [
  {
    value: 0,
    text: 'common.yes',
    valueBool: true,
    color: 'var(--primary)',
  },
  {
    value: 1,
    text: 'common.no',
    valueBool: false,
    color: 'var(--secondary)',
  },
];

export const POLL_VOTES_CONST = {
  yes: {
    value: 'YES',
    color: 'var(--primary)',
    text: 'common.yes',
  },
  no: {
    value: 'NO',
    color: 'var(--secondary)',
    text: 'common.no',
  },
};

export const HOME_BANNER_TABS = [
  {
    value: 'forParentsFamilies',
    text: 'menu.forParentsFamilies',
    url: '/',
    activeBackgroundColor: 'var(--primary)',
  },
  {
    value: 'forChildcareProviders',
    text: 'menu.forChildcareProviders',
    url: '/for-childcare-providers',
    activeBackgroundColor: 'var(--secondary)',
  },
  {
    value: 'forJobSeekers',
    text: 'menu.forJobSeekers',
    url: FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH,
    activeBackgroundColor: 'var(--bg-green)',
  },
];

export const CENTRES_VIEW_TABS = [
  {
    value: 'listView',
    text: 'tabs.listView',
    IconCPN: ListViewIcon,
  },
  {
    value: 'mapView',
    text: 'tabs.mapView',
    url: '/map-view',
    IconCPN: MapViewIcon,
  },
];

export const CENTRES_SORTER_CONST = {
  featured: {
    value: 'featured',
    text: 'centresSorter.featured',
    orderBy: '-isFeatured',
  },
  highestKindiCareRating: {
    value: 'highestKindiCareRating',
    text: 'centresSorter.highestKindiCareRating',
    orderBy: '-kindiCareRating',
  },
  shortestDistance: {
    value: 'shortestDistance',
    text: 'centresSorter.shortestDistance',
    orderBy: 'distance',
  },
  highestCostPerDay: {
    value: 'highestCostPerDay',
    text: 'centresSorter.highestCostPerDay',
    orderBy: '-maxCost',
    orderBySubsidy: '-maxSubsidyCost',
  },
  lowestCostPerDay: {
    value: 'lowestCostPerDay',
    text: 'centresSorter.lowestCostPerDay',
    orderBy: 'minCost',
    orderBySubsidy: 'minSubsidyCost',
  },
  mostRecentNQSReview: {
    value: 'mostRecentNQSReview',
    text: 'centresSorter.mostRecentNQSReview',
    orderBy: '-currentOverallNQSRatingDate',
  },
  newestApprovedCentres: {
    value: 'newestApprovedCentres',
    text: 'centresSorter.newestApprovedCentres',
    orderBy: '-dateApplied',
  },
  oldestApprovedCentres: {
    value: 'oldestApprovedCentres',
    text: 'centresSorter.oldestApprovedCentres',
    orderBy: 'dateApplied',
  },
};

export const CENTRES_SORTER = Object.values(CENTRES_SORTER_CONST);

export const CENTRES_SUGGESTION_TYPES_CONST = {
  postcode: {
    value: 'postCode',
    text: 'title.postcode',
    name: 'title.postcode',
    IconCPN: RobotOutlined,
  },
  city: {
    value: 'city',
    text: 'title.locations',
    name: 'title.suburb',
    IconCPN: EnvironmentOutlined,
  },
  suburbProfile: {
    value: 'suburb-profile',
    text: 'suburbProfile.title',
    name: 'title.suburbProfile',
    IconCPN: MapViewIcon,
  },
  brand: {
    value: 'brand',
    text: 'title.brands',
    name: 'title.brand',
    IconCPN: StarOutlined,
  },
  centre: {
    value: 'centre',
    text: 'title.centres',
    name: 'title.centre',
    IconCPN: ShopOutlined,
  },
};

export const CENTRES_SUGGESTION_TYPES = Object.values(
  CENTRES_SUGGESTION_TYPES_CONST,
);

export const SEARCH_TYPES_CONST = {
  ...CENTRES_SUGGESTION_TYPES_CONST,
  state: {
    value: 'state',
    text: 'title.state',
    name: 'title.state',
    IconCPN: RobotOutlined,
  },
  keyword: {
    value: 'keyword',
    text: 'title.keyword',
    name: 'title.keyword',
    IconCPN: EnvironmentOutlined,
  },
  brand: {
    value: 'brand',
  },
};

export const CENTRE_FOR_MONEY = [
  {
    value: 'Good',
    text: 'classification.good',
  },
  {
    value: 'Very Good',
    text: 'classification.veryGood',
  },
  {
    value: 'Excellent',
    text: 'classification.excellent',
  },
];

export const ENQUIRY_TYPES_CONST = {
  APPLICATION: {
    value: 'APPLICATION',
    text: 'enquiryTypes.application',
    color: 'magenta',
    dotColor: '#DB147F',
    backgroundColor: '#FFF3FC',
    IconCPN: FileFillIcon,
  },
  CHECK_FOR_VACANCIES: {
    value: 'CHECK_FOR_VACANCIES',
    text: 'enquiryTypes.checkForVacancies',
    color: 'green',
    dotColor: '#36BF57',
    backgroundColor: '#EDF9F0',
    IconCPN: VacanciesFillIcon,
  },
  BOOK_A_TOUR_OR_VISIT: {
    value: 'BOOK_A_TOUR_OR_VISIT',
    text: 'enquiryTypes.bookATourOrVisit',
    color: 'purple',
    dotColor: '#BF2CF3',
    backgroundColor: '#EDE1FC',
    IconCPN: FlagFillIcon,
  },
  MORE_INFORMATION: {
    value: 'MORE_INFORMATION',
    text: 'enquiryTypes.moreInformation',
    color: 'cyan',
    dotColor: '#00DAF7',
    backgroundColor: '#E8FBFF',
    IconCPN: MoreIcon,
  },
  FEE_INFORMATION: {
    value: 'FEE_INFORMATION',
    text: 'enquiryTypes.feeInformation',
    color: 'orange',
    dotColor: '#FB8E29',
    backgroundColor: '#FFEEDB',
    IconCPN: DollarCircleFillIcon,
  },
};

export const ENQUIRY_TYPES = Object.values(ENQUIRY_TYPES_CONST);

export const PREFERRED_CONTACT_METHOD_CONST = {
  kindiCareAppMessage: {
    value: 'KindiCare App Message',
    text: 'preferredContactMethod.kindiCareAppMessage',
  },
  phone: {
    value: 'Phone',
    text: 'preferredContactMethod.phone',
  },
  email: {
    value: 'Email',
    text: 'preferredContactMethod.email',
  },
};

export const PREFERRED_CONTACT_METHOD = Object.values(
  PREFERRED_CONTACT_METHOD_CONST,
);

export const KEYS_DAYS_IN_WEEK = [
  {
    value: 'mon',
    text: 'requiredDays.mon',
  },
  {
    value: 'tue',
    text: 'requiredDays.tue',
  },
  {
    value: 'wed',
    text: 'requiredDays.wed',
  },
  {
    value: 'thu',
    text: 'requiredDays.thu',
  },
  {
    value: 'fri',
    text: 'requiredDays.fri',
  },
  {
    value: 'sat',
    text: 'requiredDays.sat',
  },
  {
    value: 'sun',
    text: 'requiredDays.sun',
  },
];

export const METHOD_CONTACT = [
  {
    value: 'phone',
    text: 'contactForm.method.phone',
  },
  {
    value: 'email',
    text: 'contactForm.method.email',
  },
];

export const REASON_CONTACT = [
  {
    value: 'Book a Demo',
    text: 'contactForm.reason.bookDemo',
  },
  {
    value: 'Feedback',
    text: 'contactForm.reason.feedback',
  },
  {
    value: 'Support',
    text: 'contactForm.reason.support',
  },
  {
    value: 'Question About KindiCare App',
    text: 'contactForm.reason.questionAboutKindiApp',
  },
  {
    value: 'Question About CRM App',
    text: 'contactForm.reason.questionAboutCRMApp',
  },
  {
    value: 'Media Enquiry',
    text: 'contactForm.reason.mediaEnquiry',
  },
  {
    value: 'Other',
    text: 'contactForm.reason.other',
  },
];

export const LIST_QUESTION_PLEDGE = [
  {
    question: 'pledge.question.question1',
    answer: 'pledge.answer.answer1',
  },
  {
    question: 'pledge.question.question2',
    answer: 'pledge.answer.answer2',
  },
  {
    question: 'pledge.question.question3',
    answer: 'pledge.answer.answer3',
  },
  {
    question: 'pledge.question.question4',
    answer: 'pledge.answer.answer4',
  },
];

export const IMAGE_TYPES = ['png', 'jpg', 'jpeg', 'gif', 'tif', 'tiff'];

export const VIDEO_TYPES = ['mov', 'mp4', 'avi', 'flv', 'wmv'];

export const AUDIO_TYPES = ['mp3', 'wav'];

export const MAX_FILE_SIZE_IN_MB = 2;

export const MAX_FILE_DIMENSIONS = 1280;

export const FILE_TYPES = [
  { value: 'pdf', icon: FilePdfOutlined, color: 'red' },
  { value: 'ppt', icon: FilePptOutlined, color: 'pink' },
  { value: 'pptx', icon: FilePptOutlined, color: 'pink' },
  { value: 'doc', icon: FileWordOutlined, color: 'blue' },
  { value: 'docx', icon: FileWordOutlined, color: 'blue' },
  { value: 'xlsx', icon: FileExcelOutlined, color: 'green' },
  { value: 'xls', icon: FileExcelOutlined, color: 'green' },
  { value: 'csv', icon: FileExcelOutlined, color: 'green' },
  { value: 'zip', icon: FileZipOutlined, color: 'violet' },
  { value: 'zar', icon: FileZipOutlined, color: 'violet' },
  { value: 'txt', icon: FileTextOutlined, color: 'currentColor' },
  { value: 'mov', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'mp4', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'avi', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'flv', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'wmv', icon: VideoCameraOutlined, color: 'currentColor' },
  { value: 'mp3', icon: AudioOutlined, color: 'lightGreen' },
  { value: 'image', icon: FileImageOutlined, color: 'blueSecondary' },
];

export const CONVERSATION_TYPES_CONST = {
  all: {
    value: 'all',
    text: 'enquiries.allConversation',
  },
  enquiry: {
    value: 'enquiry',
    text: 'enquiries.enquiries',
  },
  application: {
    value: 'application',
    text: 'enquiries.applications',
  },
};

export const CONVERSATION_TYPES = Object.values(CONVERSATION_TYPES_CONST);

export const ENQUIRY_MODE_CONST = {
  simple: 'simple',
  complex: 'complex',
};
export const OPENING_HOURS_TYPES_CONST = {
  normal: {
    value: 'normal',
    label: 'openHours.normal',
  },
  schoolTerm: {
    value: 'schoolTerm',
    label: 'openHours.schoolTerm',
  },
  holiday: {
    value: 'holiday',
    label: 'openHours.holiday',
  },
};

export const OPENING_HOURS_TYPES = Object.values(OPENING_HOURS_TYPES_CONST);

export const PRONOUNS_CONST = [
  { value: 'She/Her' },
  { value: 'He/Him' },
  { value: 'They/Them' },
  { value: 'Ze/Zir' },
  { value: 'Custom' },
];

export const WORK_ELIGIBILITY_CONST = [
  { value: 'Australian Citizen' },
  { value: 'Permanent Resident' },
  { value: 'Approved Visa' },
  { value: 'Visa Application Lodged' },
  { value: 'Requires Sponsorship' },
];

export const JOBS = [
  {
    percent: 95,
    rate: 9.5,
    title: 'Early Childhood Educator',
    description: 'Busy Bees at West Pennant Hills',
    distance: '1.52 km',
    address: '1 Kerrs Road, Castle Hill, NSW 2091',
    price: '$40.00 - $49.99/hour (inc. super)',
    jobType: 'Full Time',
    hour: '8 hours ago',
    type: 'Viewed',
    status: 'Submitted',
  },
  {
    percent: 95,
    rate: 9.5,
    title: 'Early Childhood Educator',
    description: 'Busy Bees at West Pennant Hills',
    distance: '1.52 km',
    address: '1 Kerrs Road, Castle Hill, NSW 2091',
    price: '$40.00 - $49.99/hour (inc. super)',
    jobType: 'Full Time',
    hour: '8 hours ago',
    type: 'Viewed',
    status: 'In Progress',
  },
  {
    percent: 25,
    rate: 9.5,
    title: 'Early Childhood Educator',
    description: 'Busy Bees at West Pennant Hills',
    distance: '1.52 km',
    address: '1 Kerrs Road, Castle Hill, NSW 2091',
    price: '$40.00 - $49.99/hour (inc. super)',
    jobType: 'Full Time',
    hour: '8 hours ago',
    type: 'Viewed',
    status: 'Interview',
  },
  {
    percent: 100,
    rate: 9.5,
    title: 'Early Childhood Educator',
    description: 'Busy Bees at West Pennant Hills',
    distance: '1.52 km',
    address: '1 Kerrs Road, Castle Hill, NSW 2091',
    price: '$40.00 - $49.99/hour (inc. super)',
    jobType: 'Full Time',
    hour: '8 hours ago',
    type: 'Viewed',
    status: 'Verified',
  },
  {
    percent: 6,
    rate: 9.5,
    title: 'Early Childhood Educator',
    description: 'Busy Bees at West Pennant Hills',
    distance: '1.52 km',
    address: '1 Kerrs Road, Castle Hill, NSW 2091',
    price: '$40.00 - $49.99/hour (inc. super)',
    jobType: 'Full Time',
    hour: '8 hours ago',
    type: 'Viewed',
    status: 'Offered',
  },
  {
    percent: 55,
    rate: 9.5,
    title: 'Early Childhood Educator',
    description: 'Busy Bees at West Pennant Hills',
    distance: '1.52 km',
    address: '1 Kerrs Road, Castle Hill, NSW 2091',
    price: '$40.00 - $49.99/hour (inc. super)',
    jobType: 'Full Time',
    hour: '8 hours ago',
    type: 'Viewed',
    status: 'Employed',
  },
  {
    percent: 35,
    rate: 9.5,
    title: 'Early Childhood Educator',
    description: 'Busy Bees at West Pennant Hills',
    distance: '1.52 km',
    address: '1 Kerrs Road, Castle Hill, NSW 2091',
    price: '$40.00 - $49.99/hour (inc. super)',
    jobType: 'Full Time',
    hour: '8 hours ago',
    type: 'Viewed',
    status: 'Declined',
  },
  {
    percent: 45,
    rate: 9.5,
    title: 'Early Childhood Educator',
    description: 'Busy Bees at West Pennant Hills',
    distance: '1.52 km',
    address: '1 Kerrs Road, Castle Hill, NSW 2091',
    price: '$40.00 - $49.99/hour (inc. super)',
    jobType: 'Full Time',
    hour: '8 hours ago',
    type: 'Viewed',
    status: 'Cancelled',
  },
  {
    percent: 45,
    rate: 9.5,
    title: 'Early Childhood Educator',
    description: 'Busy Bees at West Pennant Hills',
    distance: '1.52 km',
    address: '1 Kerrs Road, Castle Hill, NSW 2091',
    price: '$40.00 - $49.99/hour (inc. super)',
    jobType: 'Full Time',
    hour: '8 hours ago',
    type: 'Favourites',
    status: 'Cancelled',
  },
  {
    percent: 45,
    rate: 9.5,
    title: 'Early Childhood Educator',
    description: 'Busy Bees at West Pennant Hills',
    distance: '1.52 km',
    address: '1 Kerrs Road, Castle Hill, NSW 2091',
    price: '$40.00 - $49.99/hour (inc. super)',
    jobType: 'Full Time',
    hour: '8 hours ago',
    type: 'Applied',
    status: 'Cancelled',
  },
];

export const JOB_TABS = {
  viewed: 'Viewed',
  favourites: 'Favourites',
  applied: 'Applied',
};

export const JOB_TYPES = {
  submitted: 'Submitted',
  inProgress: 'In Progress',
  interview: 'Interview',
  verified: 'Verified',
  offered: 'Offered',
  employed: 'Employed',
  declined: 'Declined',
  cancelled: 'Cancelled',
};

export const FILTER_JOB_OPTIONS = [
  {
    title: 'All',
  },
  {
    title: 'Submitted',
  },
  {
    title: 'In Progress',
  },
  {
    title: 'Interview',
  },
  {
    title: 'Verified',
  },
  {
    title: 'Employed',
  },
  {
    title: 'Declined',
  },
  {
    title: 'Cancelled',
  },
];

export const JOB_SUGGESTION_TYPES = {
  POSTCODE: 'POSTCODE',
  LOCATIONS: 'LOCATIONS',
  COMPANIES: 'COMPANIES',
  BRANDS: 'BRANDS',
  CENTRES: 'CENTRES',
  JOB_ROLES: 'JOB ROLES',
  JOBS_TITLE: 'JOBS',
};

export const JOB_SEARCH_TYPES = {
  job: 'Job',
  jobRole: 'Job Role',
  suburb: 'Suburb',
  company: 'Company',
  brand: 'Brand',
  centre: 'Centre',
};

export const SEARCH_BY_KEYWORD = 'keyword';

export const DEFAULT_CENTRE_COVER = '/images/default-centre-cover.png';

export const CANONICAL_OVERRIDE_KEY = 'canonical-tag';

export const JOB_REQUIREMENT_TYPES = {
  mandatory: 'mandatory',
  preferred: 'preferred',
};

export const AUSTRALIA_COUNTRY = {
  lowercaseValue: 'australia',
  alpha2CountryCode: 'AU',
};

export const HEIGHT_SMART_BANNER = 55;

export const INITIAL_FILTER_FEATURED_CENTRE = { orderBy: '-isFeatured' };
