import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
} from '@redux/crudCreator/utils';
import { createSlice } from '@reduxjs/toolkit';
import {
  deleteInbox,
  getAllInboxes,
  getInboxById,
  getTotalUnreadEnquires,
  seenMessage,
} from './action';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { actions, reducer } = createSlice({
  name: 'inboxes',
  initialState,
  reducers: {
    updateInboxesListAfterSendMessages: (state, { payload }) => {
      const matchIdx = state.data?.findIndex(
        (item) => item?.id === payload?.inboxId,
      );
      if (matchIdx !== -1) {
        state.data[matchIdx].latestMessageId = payload?.inboxId;
        state.data[matchIdx].latestMessage = payload;
        if (matchIdx > 0) {
          state.data.unshift(state.data.splice(matchIdx, 1)[0]);
        }
      }
    },
    updateMailboxDetailWhenEmptyData: (state) => {
      state.currentData = null;
    },
  },
  extraReducers: {
    [getAllInboxes.pending]: getAllCustomPending,
    [getAllInboxes.fulfilled]: getAllCustomSuccess,
    [getAllInboxes.rejected]: getAllCustomFail,
    [getTotalUnreadEnquires.fulfilled]: (state, { payload }) => {
      state.totalUnread = Number(payload?.totalUnreadInboxes);
    },
    [getInboxById.fulfilled]: (state, { payload }) => {
      state.currentData = payload;
    },
    [seenMessage.fulfilled]: (state, { meta: { arg: payload } }) => {
      state.currentData.clientHasNewMessage = false;
      state.totalUnread = (state.totalUnread || 1) - 1;
      const matchIdx = state.data?.findIndex(
        (item) => item?.id === payload?.id,
      );
      if (matchIdx !== -1) {
        state.data[matchIdx].clientHasNewMessage = false;
      }
    },
    [deleteInbox.fulfilled]: (state, { meta: { arg: payload } }) => {
      state.currentData = {};
      state.data = state.data?.filter((item) => item.id !== payload.id);
    },
  },
});

export const {
  updateInboxesListAfterSendMessages,
  updateMailboxDetailWhenEmptyData,
} = actions;

export default reducer;
