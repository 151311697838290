import { trackingEvent } from '@redux/eventTracking/actions';
import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCentreDetailConnectButtonsApi,
  getCentreDetailsApi,
  getCentreDetailsApplicableButtonsApi,
  getCentreDetailsRatingsApi,
  getCentreDetailsReviewsApi,
  getCentreDetailsServicesApi,
  getCentreReviewsSummariesApi,
  getGetStaffProfileDetailApi,
  getGetStaffProfilesApi,
  getKindiRatingSummaryApi,
  getProgramsApi,
  getProgramDetailApi,
  submitUserReviewApi,
} from 'api/centreDetails';
import { EVENT_TRACKING } from 'configs/eventTracking';
import { i18n } from 'next-i18next';
import {
  IGetStaffProfileDetailRequest,
  IGetStaffProfileDetailResponse,
  IGetStaffProfileListRequest,
  IGetStaffProfileListResponse,
} from 'types/StaffProfile';
import {
  IGetProgramDetailRequestDTO,
  IGetProgramDetailResponseDTO,
  IGetProgramsRequestDTO,
  IGetProgramsResponseDTO,
} from 'types/programs';
import { CentreDetailsApplicableButtons } from 'types/centres';

export const MODEL_NAME = 'centreDetails';

export const getCentreDetailsBasicInfo = createAsyncThunk<any, any>(
  'centreDetails/getCentreDetailsBasicInfo',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isCheckError: false, isShowLoading: true, isShowSuccess: false },
        getCentreDetailsApi,
        payload.id,
        payload.data,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const getCentreDetailsRatings = createAsyncThunk<any, any>(
  'centreDetails/getCentreDetailsRatings',
  async (id, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isCheckError: false, isShowLoading: true, isShowSuccess: false },
        () => getCentreDetailsRatingsApi(id),
      );
      const {
        data: { kindiRatingSummary },
      } = await apiWrapper(
        {
          isCheckError: false,
          isShowLoading: true,
          isShowSuccess: false,
        },
        () => getKindiRatingSummaryApi(id),
      );
      response.data.kindiRatingSummary = { ...kindiRatingSummary };
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const getCentreDetailsServices = createAsyncThunk<any, any>(
  'centreDetails/getCentreDetailsServices',
  async (payload, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isCheckError: false, isShowLoading: true, isShowSuccess: false },
        getCentreDetailsServicesApi,
        payload.id,
        payload.data,
      );
      const data = response.data?.services?.filter((item) => item.isActive);
      return { data, typeOfCare: response.data.typeOfCare };
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const getCentreReviewsSummaries = createAsyncThunk<any, any>(
  'centreDetails/getCentreReviewsSummaries',
  async (id, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isCheckError: false, isShowLoading: true, isShowSuccess: false },
        () => getCentreReviewsSummariesApi(id),
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const getCentreDetailsReviews = createAsyncThunk<any, any>(
  'centreDetails/getCentreDetailsReviews',
  async ({ id, params }, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isCheckError: false, isShowLoading: true, isShowSuccess: false },
        () => getCentreDetailsReviewsApi(id, params),
      );
      return { ...response.data, params };
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const submitUserReview = createAsyncThunk<any, any>(
  'centreDetails/submitUserReview',
  async ({ id, body, slug, isMobile }, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {
          isCheckError: false,
          isShowLoading: true,
          isShowSuccess: !isMobile,
          successDescription: i18n.t('writeCentreView.submitReviewSuccess'),
        },
        submitUserReviewApi,
        id,
        body,
      );

      thunkAPI.dispatch(getCentreReviewsSummaries(slug));
      thunkAPI.dispatch(
        getCentreDetailsReviews({
          id: slug,
          params: {
            offset: 0,
            limit: 10,
            orderBy: '-createdAt',
          },
        }),
      );
      thunkAPI.dispatch(
        trackingEvent({
          eventName: EVENT_TRACKING.reviewSubmitted,
          eventData: {
            centreId: response.data?.centreId,
            reviewId: response.data?.id,
          },
        }),
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);
export const getCentreDetailsApplicableButtons = createAsyncThunk<
  CentreDetailsApplicableButtons,
  string
>('centreDetails/getCentreDetailsApplicableButtons', async (slug, thunkAPI) => {
  try {
    const response = await apiWrapper(
      {
        isShowLoading: true,
        isShowSuccess: false,
        isCheckError: false,
      },
      getCentreDetailsApplicableButtonsApi,
      slug,
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message);
  }
});

export const getCentreDetailConnectButtons = createAsyncThunk<any, any>(
  'centreDetails/getCentreDetailsContactButtons',
  async (params, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isCheckError: false, isShowLoading: true, isShowSuccess: false },
        getCentreDetailConnectButtonsApi,
        params,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const getStaffProfilesForCarousel = createAsyncThunk<
  IGetStaffProfileListResponse,
  IGetStaffProfileListRequest
>(
  'centreDetails/getStaffProfilesForCarousel',
  async ({ centreId, page, size }, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isCheckError: false, isShowLoading: true, isShowSuccess: false },
        getGetStaffProfilesApi,
        centreId,
        page,
        size,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const getStaffProfiles = createAsyncThunk<
  IGetStaffProfileListResponse,
  IGetStaffProfileListRequest
>(
  'centreDetails/getStaffProfiles',
  async ({ centreId, page, size }, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isCheckError: false, isShowLoading: true, isShowSuccess: false },
        getGetStaffProfilesApi,
        centreId,
        page,
        size,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const getStaffProfileDetail = createAsyncThunk<
  IGetStaffProfileDetailResponse,
  IGetStaffProfileDetailRequest
>(
  'centreDetails/getStaffProfileDetail',
  async ({ centreId, currentOffset }, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isCheckError: false, isShowLoading: true, isShowSuccess: false },
        getGetStaffProfileDetailApi,
        centreId,
        currentOffset,
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const getPrograms = createAsyncThunk<
  IGetProgramsResponseDTO,
  IGetProgramsRequestDTO
>(
  'centreDetails/getPrograms',
  async ({ centreId, page, size, type = 'list' }, thunkAPI) => {
    try {
      const response = await apiWrapper(
        { isCheckError: false, isShowLoading: true, isShowSuccess: false },
        getProgramsApi,
        centreId,
        page,
        size,
        type,
      );
      return { ...response.data, type };
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.message);
    }
  },
);

export const getProgramDetail = createAsyncThunk<
  IGetProgramDetailResponseDTO,
  IGetProgramDetailRequestDTO
>('centreDetails/getProgramDetail', async ({ programId }, thunkAPI) => {
  try {
    const response = await apiWrapper(
      { isCheckError: false, isShowLoading: true, isShowSuccess: false },
      getProgramDetailApi,
      programId,
    );
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error?.message);
  }
});
