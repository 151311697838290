import { richWebConfig } from 'utils';
import request from 'utils/request';

export async function getAllCentreComparisonsApi(data) {
  return request.get(`/centres/comparison${data || ''}`, richWebConfig);
}

export async function getAllCentreFavoriteComparisonsApi(data) {
  return request.get(
    `/centres/favorite/comparison${data || ''}`,
    richWebConfig,
  );
}
