import { getDisplayAddress } from 'utils/dataUtils';
import { formatMoney } from 'utils/tools';
import { Tooltip } from 'antd';
import { CentreItem } from 'types/centres';
import { LocationRoundedIcon } from '@uikit/commons/SVGIcons';
import { CentreAddressDistanceStyles } from './styles';

interface Props {
  isInline?: boolean;
  item?: Partial<CentreItem>;
  isEllipsis?: boolean;
}

const CentreAddressDistance = ({
  isInline = true,
  item,
  isEllipsis,
}: Props) => (
  <CentreAddressDistanceStyles
    className={`centre-address-distance ${
      isEllipsis ? 'centre-address-distance__ellipsis' : ''
    }`}
  >
    <LocationRoundedIcon
      className="text-highlight mr-5"
      svgProps={{
        width: 17,
        height: 16,
      }}
    />
    {!!item?.distance && [
      isInline ? (
        <span
          className="text-highlight mr-5 centre-address-distance__value"
          key={item?.id}
        >
          {formatMoney(item?.distance / 1000 || 0, true)} km{' '}
          <span className="dot">&#9679;</span>
        </span>
      ) : (
        <span
          className="text-highlight mr-5 centre-address-distance__value"
          key={item?.id}
        >
          {formatMoney(item?.distance / 1000 || 0, true)}
          km
        </span>
      ),
    ]}
    {isInline ? (
      <span className="centre-address-distance__address" key={item?.id}>
        {item?.displayAddress || getDisplayAddress(item)}
      </span>
    ) : (
      <Tooltip title={item?.displayAddress || getDisplayAddress(item)}>
        <div className="centre-address-distance__address" key={item?.id}>
          {item?.displayAddress || getDisplayAddress(item)}
        </div>
      </Tooltip>
    )}
  </CentreAddressDistanceStyles>
);

export default CentreAddressDistance;
