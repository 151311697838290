import { createSlice } from '@reduxjs/toolkit';
import { isEmpty, unionBy } from 'lodash-es';
import {
  deleteJobMailbox,
  getJobDetailAction,
  getJobMailboxInboxesAction,
  getJobMailboxMessagesAction,
  MODEL_NAME,
} from './actions';

const initialState = {
  inboxes: {
    data: [],
    paging: {},
  },
  loading: false,
  messages: {
    data: [],
    paging: {},
    loading: false,
  },
  jobDetail: {
    loading: false,
  },
};

const getJobMailboxPending = (state, { meta: { arg } }) => {
  state.loading = true;

  if (arg?.options?.isRefresh) {
    state.inboxes.data = [];
  }
};

const getJobMailboxInboxesSuccess = (
  state,
  { payload: { results, paging } = {} },
) => {
  state.inboxes = {
    data: unionBy([...(state.inboxes?.data || []), ...(results || [])], 'id'),
    paging,
  };

  if (!isEmpty(results)) {
    state.inboxes.data[0] = {
      ...state.inboxes.data[0],
      seekerHasNewMessage: false,
    };
  }

  state.loading = false;
};

const getJobMailboxFailed = (state) => {
  state.loading = false;
};

const getJobMailboxMessagesPending = (state, { meta: { arg } }) => {
  if (arg?.options?.isRefresh) {
    state.messages.data = [];
  }
  state.messages.loading = true;
};

const getJobMailboxMessagesSuccess = (
  state,
  { payload: { results, paging } } = {},
) => {
  state.messages = {
    data: unionBy([...(state.messages?.data || []), ...(results || [])], 'id'),
    paging,
  };

  state.messages.loading = false;
};

const getJobMailboxMessagesFailed = (state) => {
  state.messages.loading = false;
};

const getJobDetailPending = (state) => {
  state.jobDetail.loading = true;
};

const getJobDetailSuccess = (state, { payload }) => {
  state.jobDetail = {
    ...payload,
  };

  state.jobDetail.loading = false;
};

const getJobDetailFailed = (state) => {
  state.jobDetail.loading = false;
};

const deleteJobMailboxSuccess = (state, { meta: { arg: payload } }) => {
  state.currentData = {};
  state.inboxes.data = state.data?.filter((item) => item.id !== payload);
  state.messages.data = state.data?.filter(
    (item) => item?.chatBoxId !== payload,
  );
};

export const { actions, reducer } = createSlice({
  name: MODEL_NAME,
  initialState,
  reducers: {
    updateMessagesAfterSent: (state, { payload }) => {
      const currentInbox = state.inboxes.data.find(
        (item) => item.id === payload.chatBoxId,
      );

      const existMessageIndex = state.messages.data.findIndex(
        (item) => item.id === payload.id,
      );

      if (existMessageIndex === -1) {
        state.messages.data = [payload, ...state.messages.data];
        if (payload.files !== null && payload.files.length >= 1) {
          const fileLength = payload.files.length;
          state.inboxes.data = [
            {
              ...currentInbox,
              lastMessageContent: `You sent ${
                fileLength === 1
                  ? 'an attachment.'
                  : `${fileLength} attachments.`
              }`,
              lastMessageTime: payload.createdAt,
            },
            ...state.inboxes.data.filter(
              (inbox) => inbox.id !== currentInbox.id,
            ),
          ];
        } else {
          state.inboxes.data = [
            {
              ...currentInbox,
              lastMessageContent: payload.content,
              lastMessageTime: payload.createdAt,
            },
            ...state.inboxes.data.filter(
              (inbox) => inbox.id !== currentInbox.id,
            ),
          ];
        }
      }
    },
    updateSeekerSeenMessage: (state, { payload }) => {
      const matchIdx = state.inboxes.data.findIndex(
        (item) => item.id === payload?.id,
      );

      state.inboxes.data[matchIdx] = {
        ...payload,
        seekerHasNewMessage: false,
      };
    },
  },
  extraReducers: {
    [getJobMailboxInboxesAction.pending]: getJobMailboxPending,
    [getJobMailboxInboxesAction.fulfilled]: getJobMailboxInboxesSuccess,
    [getJobMailboxInboxesAction.rejected]: getJobMailboxFailed,

    [getJobMailboxMessagesAction.fulfilled]: getJobMailboxMessagesSuccess,
    [getJobMailboxMessagesAction.pending]: getJobMailboxMessagesPending,
    [getJobMailboxMessagesAction.rejected]: getJobMailboxMessagesFailed,

    [getJobDetailAction.fulfilled]: getJobDetailSuccess,
    [getJobDetailAction.pending]: getJobDetailPending,
    [getJobDetailAction.rejected]: getJobDetailFailed,

    [deleteJobMailbox.fulfilled]: deleteJobMailboxSuccess,
  },
});

export const { updateMessagesAfterSent, updateSeekerSeenMessage } = actions;

export default reducer;
