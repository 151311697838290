export const TYPE_OF_WORK_BEST_SUITS = [
  { id: '1', name: 'Full Time' },
  { id: '2', name: 'Part Time' },
  { id: '3', name: 'Contact Full-Time' },
  { id: '4', name: 'Contact Part-Time' },
  { id: '5', name: 'Temporary' },
  { id: '6', name: 'Fixed Term Contract' },
  { id: '7', name: 'Casual' },
];

export const ACTION_ADD = 'add';
export const ACTION_EDIT = 'edit';

export const START_A_NEW_JOB_CONST = {
  immediately: {
    value: 'isImmediateStart',
    text: 'jobs.careerPreferences.immediateStart',
  },
  specific: {
    value: 'specificStartDate',
    text: 'jobs.careerPreferences.specificDate',
  },
};

export const START_A_NEW_JOB = Object.values(START_A_NEW_JOB_CONST);

export const LIMIT_VIDEO_SIZE = 25;
export const LIMIT_FILE_SIZE = 10;

export const DOCUMENT_FORM_VALUE = {
  resumeUrl: 'resumeUrl',
  coverLetterUrl: 'coverLetterUrl',
};
export const YOUTUBE_VALUE = 'youtube';

export const COMPANY_PREFIX = 'Company_';

export const BRAND_PREFIX = 'Brand_';

export const MIN_WORK_DISTANCE = 5;

export const MAX_WORK_DISTANCE = 25;

export const DEFAULT_SALARY_RANGE = [0, 150000];

export const QUALIFICATIONS_WITH_DEGREE = [
  'Bachelor',
  'Post Graduate Diploma',
  'Masters',
  'Doctorate',
];

export const MY_JOB_VIEW = {
  FAVOURITES: 'favourites',
  APPLIED: 'applied',
  VIEWED: 'viewed',
};

export const APPLY_STATUS = {
  oneClickApply: 'One Click Apply',
  apply: 'Apply',
  applied: 'Applied',
};
export const DEFAULT_APPLICATION_STATUS = 'All';
