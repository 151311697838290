export const ICRightOutline = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.391846 14.504L1.80785 15.92L9.72785 8L1.80785 0.0800047L0.391846 1.496L6.89585 8L0.391846 14.504Z"
      fill="currentColor"
    />
  </svg>
);

export const ICLeftOutline = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Arrow-Forward-iOS-1"
        transform="translate(5.000000, 8.000000) scale(-1, 1) translate(-5.000000, -8.000000) "
        fill="currentColor"
      >
        <polygon
          id="Path"
          points="0.391846 14.5041 1.80785 15.9201 9.72785 8.00008 1.80785 0.0800781 0.391846 1.49608 6.89585 8.00008"
        />
      </g>
    </g>
  </svg>
);
export const ICDownOutline = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.41 0.589996L6 5.17L10.59 0.589996L12 2L6 8L0 2L1.41 0.589996Z"
      fill="currentColor"
    />
  </svg>
);

export const ICUpOutline = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 12 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1.41 7.41L6 2.83L10.59 7.41L12 6L6 0L0 6L1.41 7.41Z"
      fill="currentColor"
    />
  </svg>
);

export const ICRightSpecial = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 42 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.0542 6.49912C18.1897 7.67661 13.1079 10.5207 9.43045 13.8755C7.38088 15.8184 5.59485 18.0135 4.11802 20.4047C3.33328 21.6177 2.61072 22.8679 1.95308 24.1508C1.75001 24.5297 1.27912 26.2178 0.842145 26.25C-0.627241 26.3577 2.26738 20.7199 2.62207 20.135C4.1151 17.6617 5.77291 15.2197 7.85064 13.1646C11.3353 9.71692 15.877 6.57536 20.5836 5.05541C23.326 4.16974 26.0638 3.25473 28.9606 2.96931C30.1816 2.84923 31.4096 2.84212 32.6285 2.94806C33.4977 3.01392 34.3612 3.13247 35.2139 3.30304C35.8383 3.43869 36.5649 3.92854 37.1786 3.95755C36.8378 2.9431 35.5492 2.2165 35.1178 1.19664C34.6046 -0.0193142 35.711 0.152783 36.4135 0.679956C37.4982 1.56915 38.5273 2.5217 39.4955 3.5326C40.3495 4.35972 41.6497 5.21333 40.8465 6.45875C40.139 7.5447 38.9811 8.39797 38.081 9.29394C37.4728 9.9003 36.4634 11.5259 35.45 11.3826C32.2849 10.933 38.1204 7.01222 38.4037 6.577C37.9364 6.3822 37.3369 6.40645 36.8385 6.29337C36.117 6.12718 35.4268 5.86188 34.7034 5.6999C33.3209 5.3953 31.9092 5.2259 30.4888 5.19417C27.9586 5.15135 25.4367 5.59331 23.0634 6.49547C21.2934 6.92409 23.3824 6.37172 23.0542 6.49912Z"
      fill="currentColor"
    />
  </svg>
);

export const ICLeftSecondOutline = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 26 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5544 1.07937C10.6397 1.16461 10.7074 1.26583 10.7535 1.37723C10.7997 1.48863 10.8235 1.60803 10.8235 1.72862C10.8235 1.84921 10.7997 1.96862 10.7535 2.08002C10.7074 2.19142 10.6397 2.29263 10.5544 2.37787L3.88341 9.0515H24.3322C24.5758 9.0515 24.8095 9.14829 24.9818 9.32059C25.1541 9.49289 25.2509 9.72658 25.2509 9.97025C25.2509 10.2139 25.1541 10.4476 24.9818 10.6199C24.8095 10.7922 24.5758 10.889 24.3322 10.889H3.88341L10.5544 17.5626C10.7266 17.7348 10.8233 17.9684 10.8233 18.2119C10.8233 18.4554 10.7266 18.6889 10.5544 18.8611C10.3822 19.0333 10.1487 19.1301 9.90516 19.1301C9.66164 19.1301 9.4281 19.0333 9.25591 18.8611L1.01691 10.6195L0.92941 10.5127L0.919784 10.4996L1.01691 10.6195C0.941311 10.5434 0.879705 10.4545 0.83491 10.357L0.821785 10.3307C0.807933 10.2985 0.795955 10.2655 0.78591 10.2319C0.78066 10.2091 0.776285 10.1907 0.77191 10.1715C0.764314 10.1398 0.758472 10.1076 0.75441 10.0752C0.75441 10.0542 0.75441 10.0332 0.74916 10.0131C0.74391 9.993 0.74916 9.98512 0.74916 9.97025V9.92737C0.74916 9.90695 0.75091 9.88654 0.75441 9.86612L0.748285 9.97025C0.748124 9.90107 0.755756 9.83209 0.771035 9.76462C0.771035 9.748 0.77891 9.73137 0.78416 9.71562C0.794069 9.6811 0.806049 9.6472 0.820035 9.61412L0.84191 9.57737C0.885339 9.48264 0.94451 9.39595 1.01691 9.321L9.26028 1.07937C9.43226 0.908441 9.66488 0.8125 9.90735 0.8125C10.1498 0.8125 10.3824 0.908441 10.5544 1.07937Z"
      fill="currentColor"
    />
  </svg>
);

export const ICRightLightArrow = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.0962 15.6463C13.0231 15.5732 12.9651 15.4864 12.9255 15.3909C12.8859 15.2955 12.8656 15.1931 12.8656 15.0898C12.8656 14.9864 12.8859 14.884 12.9255 14.7886C12.9651 14.6931 13.0231 14.6063 13.0962 14.5333L18.8142 8.813L1.28668 8.813C1.07783 8.813 0.877522 8.73003 0.729836 8.58235C0.582152 8.43466 0.499182 8.23436 0.499182 8.0255C0.499182 7.81664 0.582152 7.61634 0.729836 7.46865C0.877522 7.32097 1.07783 7.238 1.28668 7.238L18.8142 7.238L13.0962 1.51775C12.9486 1.37016 12.8657 1.16998 12.8657 0.961253C12.8657 0.752526 12.9486 0.552345 13.0962 0.404753C13.2438 0.257159 13.444 0.174243 13.6527 0.174243C13.8614 0.174243 14.0616 0.25716 14.2092 0.404753L21.2712 7.469L21.3462 7.5605L21.3544 7.57175L21.2712 7.469C21.336 7.53427 21.3888 7.61043 21.4272 7.694L21.4384 7.7165C21.4503 7.74414 21.4606 7.77243 21.4692 7.80125C21.4737 7.82075 21.4774 7.8365 21.4812 7.853C21.4877 7.88021 21.4927 7.90775 21.4962 7.9355C21.4962 7.9535 21.4962 7.9715 21.5007 7.98875C21.5052 8.006 21.5007 8.01275 21.5007 8.0255L21.5007 8.06225C21.5007 8.07975 21.4992 8.09725 21.4962 8.11475L21.5014 8.0255C21.5016 8.0848 21.495 8.14392 21.4819 8.20175C21.4819 8.216 21.4752 8.23025 21.4707 8.24375C21.4622 8.27334 21.4519 8.3024 21.4399 8.33075L21.4212 8.36225C21.384 8.44345 21.3332 8.51775 21.2712 8.582L14.2054 15.6463C14.058 15.7928 13.8586 15.875 13.6508 15.875C13.443 15.875 13.2436 15.7928 13.0962 15.6463Z"
      fill="currentColor"
    />
  </svg>
);

export const ICLeftLightArrow = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.90382 0.353748C8.97693 0.426811 9.03493 0.513565 9.0745 0.609051C9.11407 0.704538 9.13443 0.806886 9.13443 0.910248C9.13443 1.01361 9.11407 1.11596 9.0745 1.21144C9.03493 1.30693 8.97693 1.39368 8.90382 1.46675L3.18581 7.187L20.7133 7.187C20.9222 7.187 21.1225 7.26997 21.2702 7.41765C21.4178 7.56534 21.5008 7.76564 21.5008 7.9745C21.5008 8.18336 21.4178 8.38366 21.2702 8.53134C21.1225 8.67903 20.9222 8.762 20.7133 8.762L3.18581 8.762L8.90382 14.4822C9.05141 14.6298 9.13433 14.83 9.13433 15.0387C9.13433 15.2475 9.05141 15.4477 8.90382 15.5952C8.75622 15.7428 8.55604 15.8258 8.34732 15.8258C8.13859 15.8258 7.93841 15.7428 7.79082 15.5952L0.728814 8.531L0.653814 8.4395L0.645564 8.42825L0.728814 8.531C0.664016 8.46573 0.61121 8.38957 0.572814 8.306L0.561564 8.2835C0.549692 8.25586 0.539425 8.22757 0.530814 8.19875C0.526314 8.17925 0.522564 8.1635 0.518814 8.147C0.512304 8.11979 0.507296 8.09225 0.503814 8.0645C0.503814 8.0465 0.503814 8.0285 0.499314 8.01125C0.494814 7.994 0.499314 7.98725 0.499314 7.9745L0.499314 7.93775C0.499314 7.92025 0.500814 7.90275 0.503814 7.88525L0.498564 7.9745C0.498427 7.9152 0.504968 7.85608 0.518064 7.79825C0.518064 7.784 0.524814 7.76975 0.529314 7.75625C0.537808 7.72666 0.548077 7.6976 0.560064 7.66925L0.578814 7.63775C0.61604 7.55655 0.666758 7.48225 0.728814 7.418L7.79457 0.353748C7.94197 0.207235 8.14136 0.125 8.34919 0.125C8.55702 0.125 8.75641 0.207235 8.90382 0.353748Z"
      fill="currentColor"
    />
  </svg>
);
