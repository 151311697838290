export const EVENT_TRACKING = {
  accountCreatedApple: 'account_created_apple',
  accountCreatedFacebook: 'account_created_facebook',
  accountCreatedGoogle: 'account_created_google',
  accountCreated: 'account_created',
  applyButtonClicked: 'apply_button_clicked',
  applicationSubmitted: 'application_submitted',
  applicationsViewed: 'applications_viewed',
  centreDetailsViewed: 'centre_details_viewed',
  centreFavourited: 'centre_favourited',
  centreShared: 'centre_shared',
  customApplyClicked: 'custom_apply_clicked',
  customBookingClicked: 'custom_booking_clicked',
  domainItemViewed: 'domain_item_viewed',
  domainListViewed: 'domain_list_viewed',
  enquiryButtonClicked: 'enquiry_button_clicked',
  enquirySubmitted: 'enquiry_submitted',
  fileAttached: 'file_attached',
  locationAccepted: 'location_accepted',
  loggedIn: 'logged_in',
  loggedInFacebook: 'logged_in_facebook',
  loggedInGoogle: 'logged_in_google',
  loggedInApple: 'logged_in_apple',
  messageSent: 'message_sent',
  phoneCalled: 'phone_called',
  profileUpdated: 'profile_updated',
  profileViewed: 'profile_viewed',
  centresViewedList: 'centres_viewed_list',
  centresViewedMap: 'centres_viewed_map',
  reviewSubmitted: 'review_submitted',
  searchByBrand: 'search_by_brand',
  searchBySuburb: 'search_by_suburb',
  searchByCentreName: 'search_by_centre_name',
  searchByKeyword: 'search_by_keyword',
  searchByPostcode: 'search_by_postcode',
  websiteOpened: 'website_opened',
  articleViewed: 'article_viewed',
  externalArticleClicked: 'external_article_clicked',
  compareButtonClicked: 'compare_button_clicked',
  compareAllButtonClicked: 'compare_all_button_clicked',
  centreAddedToCompare: 'centre_added_to_compare',
  centreRemovedFromCompare: 'centre_removed_from_compare',
  addCentreToComparePoppedUp: 'add_centre_to_compare_popped_up',
  jobPostDetailsViewed: 'job_post_details_viewed',
  applicationDetailsViewed: 'application_details_viewed',
  jobSearchedByKey: 'job_searched_by_key',
  compareJobCtaEmailClicked: 'compare_job_cta_email_clicked',
  compareJobCtaPhoneClicked: 'compare_job_cta_phone_clicked',
  compareJobCtaApplyClicked: 'compare_job_cta_apply_clicked',
  compareJobCtaOneClickApplyClicked: 'compare_job_cta_one_click_apply_clicked',
  surveySubmitted: 'survey_submitted',
  ADS: {
    CLICK: {
      childcareLandingPageAdsCarouselClicked:
        'childcare_landing_page_ads_carousel_clicked',
      childcareLandingPageAdsCarouselListClicked:
        'childcare_landing_page_ads_carousel_list_clicked',
      centreDetailsAdsGreatOffersCarouselClicked:
        'centre_details_ads_great_offers_carousel_clicked',
      centreDetailsAdsGreatOffersCarouselListClicked:
        'centre_details_ads_great_offers_carousel_list_clicked',
      centreCarouselListAdsClicked: 'centre_carousel_list_ads_clicked',
      searchCentreListAdsClicked: 'search_centre_list_ads_clicked',
      centreListSideBarClicked: 'centre_list_side_bar_clicked',
      jobLandingPageAdsCarouselClicked: 'job_landing_page_ads_carousel_clicked',
      jobLandingPageAdsCarouselListClicked:
        'job_landing_page_ads_carousel_list_clicked',
      jobDetailsAdsGreatOffersCarouselClicked:
        'job_details_ads_great_offers_carousel_clicked',
      jobDetailsAdsGreatOffersCarouselListClicked:
        'job_details_ads_great_offers_carousel_list_clicked',
      jobCarouselListAdsClicked: 'job_carousel_list_ads_clicked',
      jobListSideBarAdsClicked: 'job_list_side_bar_ads_clicked',
      searchJobListAdsClicked: 'search_job_list_ads_clicked',
    },
    SHARED: {
      childcareLandingPageAdsCarouselShared:
        'childcare_landing_page_ads_carousel_shared',
      childcareLandingPageAdsCarouselListShared:
        'childcare_landing_page_ads_carousel_list_shared',
      centreDetailsAdsCarouselShared: 'centre_details_ads_carousel_shared',
      centreDetailsAdsGreatOffersCarouselListShared:
        'centre_details_ads_great_offers_carousel_list_shared',
      centreListAdsAmongShared: 'centre_list_ads_among_shared',
      centreCarouselListAdsShared: 'centre_carousel_list_ads_shared',
      searchCentreListAdsShared: 'search_centre_list_ads_shared',
      centreListSideBarShared: 'centre_list_side_bar_shared',
      jobLandingPageAdsCarouselShared: 'job_landing_page_ads_carousel_shared',
      jobLandingPageAdsCarouselListShared:
        'job_landing_page_ads_carousel_list_shared',
      jobDetailsAdsGreatOffersCarouselShared:
        'job_details_ads_great_offers_carousel_shared',
      jobDetailsAdsGreatOffersCarouselListShared:
        'job_details_ads_great_offers_carousel_list_shared',
      jobCarouselListAdsShared: 'job_carousel_list_ads_shared',
      jobListSideBarShared: 'job_list_side_bar_shared',
      searchJobListAdsShared: 'search_job_list_ads_shared',
    },
    IMPRESSION: {
      childcareLandingPageAdsCarouselImpressed:
        'childcare_landing_page_ads_carousel_impressed',
      childcareLandingPageAdsCarouselListImpressed:
        'childcare_landing_page_ads_carousel_list_impressed',
      centreDetailsAdsGreatOffersCarouselImpressed:
        'centre_details_ads_great_offers_carousel_impressed',
      centreDetailsAdsGreatOffersCarouselListImpressed:
        'centre_details_ads_great_offers_carousel_list_impressed',
      centreCarouselListAdsImpressed: 'centre_carousel_list_ads_impressed',
      searchCentreListAdsImpressed: 'search_centre_list_ads_impressed',
      centreListSideBarImpressed: 'centre_list_side_bar_impressed',
      jobLandingPageAdsCarouselImpressed:
        'job_landing_page_ads_carousel_impressed',
      jobLandingPageAdsCarouselListImpressed:
        'job_landing_page_ads_carousel_list_impressed',
      obDetailsAdsGreatOffersCarouselImpressed:
        'ob_details_ads_great_offers_carousel_impressed',
      jobDetailsAdsGreatOffersCarouselListImpressed:
        'job_details_ads_great_offers_carousel_list_impressed',
      jobCarouselListAdsImpressed: 'job_carousel_list_ads_impressed',
      jobListSideBarImpressed: 'job_list_side_bar_impressed',
      searchJobListAdsImpressed: 'search_job_list_ads_impressed',
    },
  },
  CENTRES: {
    IMPRESSION: {
      centreImpressionSearchResultsMapView:
        'centre_impression_search_results_map_view',
      centreImpressionSearchResultsListView:
        'centre_impression_search_results_list_view',
      centreImpressionListViewFeaturedCentre:
        'centre_impression_list_view_featured_centre',
      centreImpressionCentreDetailsPopularNearbyCarousel:
        'centre_impression_centre_details_popular_nearby_carousel',
      centreImpressionLandingPageCarousel:
        'centre_impression_landing_page_carousel',
      centreImpressionSearchResults: 'centre_impression_search_results',
      centreImpressionCarouselListView: 'centre_impression_carousel_list_view',
      centreImpressionLandingPageCarouselMapView:
        'centre_impression_landing_page_carousel_map_view',
      centreImpressionCentreDetailsNearbyCentreCarousel:
        'centre_impression_centre_details_nearby_centre_carousel',
      centreImpressionLandingPageCarouselListView:
        'centre_impression_landing_page_carousel_list_view',
      centreImpressionCentreDetailsNearbyCentreListView:
        'centre_impression_centre_details_nearby_centre_list_view',
      centreImpressionCentreDetailsNearbyCentreMapView:
        'centre_impression_centre_details_nearby_centre_map_view',
      centreImpressionCentreDetailsPopularNearbyListView:
        'centre_impression_centre_details_popular_nearby_list_view',
      centreImpressionCentreDetailsPopularNearbyMapView:
        'centre_impression_centre_details_popular_nearby_map_view',
    },
    PROFILE: {
      profileCarouselClicked: 'profile_carousel_clicked',
      profileListClicked: 'profile_list_clicked',
      profileViewAllButtonClicked: 'profile_view_all_button_clicked',
      profileViewAllCardClicked: 'profile_view_all_card_clicked',
    },
    PROGRAM: {
      programCarouselCardClicked: 'program_carousel_card_clicked',
      programListCardClicked: 'program_list_card_clicked',
      programViewAllButtonClicked: 'program_view_all_button_clicked',
      programViewAllCardClicked: 'program_view_all_card_clicked',
    },
  },
  unauthorisedCustomApplyClicked: 'unauthorised_custom_apply_clicked',
  unauthorisedCustomBookingClicked: 'unauthorised_custom_booking_clicked',
  SUBSIDY_CALCULATOR: {
    subsidyCalculatorShare: 'RW_subsidy_calculator_share',
    embedLinkCopied: 'RW_subsidy_embed_link_copied',
    searchForChildcareButtonBannerClicked:
      'RW_subsidy_search_for_childcare_banner_clicked',
    getEmbedLinkButtonBannerClicked:
      'RW_subsidy_get_embed_link_button_banner_clicked',
    calculateSubsidyButtonClicked: 'RW_calculate_subsidy_button_clicked',
    searchForChildcareButtonHeaderClicked:
      'RW_search_for_childcare_button_header_clicked',
  },
};
