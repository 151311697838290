import { getAllCustom } from '@redux/crudCreator/actions';
import { getAllCentreFavoriteComparisonsApi } from 'api/centresComparison';

export const MODEL_NAME = 'centresFavoriteComparison';

export const getAllFavoriteCentresComparison = getAllCustom(
  MODEL_NAME,
  getAllCentreFavoriteComparisonsApi,
  null,
);
