import {
  AddFillIcon,
  BabyFillIcon,
  BabyPrimaryFillIcon,
  BriefcaseFillIcon,
  DocumentIcon,
  DollarFillIcon,
  FacebookFillIcon,
  FireIcon,
  HeartFilledIcon,
  InstagramFillIcon,
  LinkedinFillIcon,
  MailFilledIcon,
  ProfileFillIcon,
  SearchNormalIcon,
  ShopIcon,
  YoutubeFillIcon,
} from '@uikit/commons/SVGIcons';
import { FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH } from 'components/forJobSeekers/constants';
import { isMobile } from 'utils/tools';
import {
  CHILDCARE_PROVIDER_PATH,
  CLAIM_CENTRES_URL,
  CONTACT_US_PATH,
  EARLY_CHILDHOOD_AND_CHILDCARE_JOB_PATH,
  END_USER_LICENCE_AGREEMENT_PATH,
  HINTS_TIPS_STORIES_PATH,
  SUBSCRIPTION_PRICING_PATH,
} from './constants';

export const HEADER_MENUS = [
  {
    title: 'menu.jobs',
    href: FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH,
    tag: 'New!',
  },
  {
    title: 'menu.subsidyCalculator',
    href: '/child-care-subsidy-calculator',
  },
  {
    title: 'menu.hintsTipStories',
    href: HINTS_TIPS_STORIES_PATH,
  },
  {
    title: 'menu.awards',
    href: '/australia-childcare-centre-awards-2023',
  },
  {
    title: 'menu.media',
    href: '/media',
  },
  {
    title: 'menu.aboutUs',
    href: '/about-us',
  },
];

export const SEARCH_MENU = [
  {
    title: 'menu.searchForChildcare',
    href: '/',
    icon: BabyPrimaryFillIcon,
  },
  {
    title: 'menu.findAndClaimYourCentre',
    href: `${process.env.NEXT_PUBLIC_CRM_WEB_URL}/claim-centres`,
    icon: ShopIcon,
  },
  {
    title: 'menu.searchForJobs',
    href: FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH,
    icon: BriefcaseFillIcon,
    tag: 'New!',
  },
];

export const SOCIAL_LINKS = [
  {
    IconCPN: FacebookFillIcon,
    href: 'https://www.facebook.com/KindiCare/',
  },
  {
    IconCPN: LinkedinFillIcon,
    href: 'https://www.linkedin.com/company/kindicare',
  },
  {
    IconCPN: InstagramFillIcon,
    href: 'https://www.instagram.com/kindicare/',
  },
  {
    IconCPN: YoutubeFillIcon,
    href: 'https://www.youtube.com/channel/UC3VIhG7Gp_njmCPW6CVOavA',
  },
];

export const FOOTER_MENUS = [
  {
    title: 'menu.aboutKindiCare',
    subMenus: [
      {
        title: 'menu.home',
        href: '/',
      },
      {
        title: 'menu.aboutUs',
        href: '/about-us',
      },
      {
        title: 'menu.ourPledge',
        href: '/our-pledge',
      },
      {
        title: 'menu.contactUs',
        href: '/contact-us',
      },
      {
        title: 'menu.media',
        href: '/media',
      },
    ],
  },
  {
    title: 'menu.helpfulLinks',
    subMenus: [
      {
        title: 'menu.support',
      },
      {
        title: 'menu.theKindiCareRating',
        href: '/the-kindicare-rating',
      },
      {
        title: 'menu.theKindiCareSubsidyCalculator',
        href: '/child-care-subsidy-calculator',
      },
      {
        title: 'menu.governmentChildcareSubsidy',
        href: '/australian-government-childcare-subsidy',
      },
      {
        title: 'menu.valueForMoneyRatingExplained',
        href: '/value-for-money-rating-explained',
      },
      {
        title: 'menu.FAQs',
        href: '/faq',
      },
      {
        title: 'menu.termsConditions',
        href: '/terms-and-conditions',
      },
      {
        title: 'menu.privacyPolicy',
        href: '/privacy-policy',
      },
      {
        title: 'menu.endUserLicenceAgreement',
        href: END_USER_LICENCE_AGREEMENT_PATH,
      },
    ],
  },
  {
    mainMenu: [
      {
        title: 'menu.ourSolutions',
        subMenus: [
          {
            title: 'menu.forParentsFamilies',
            href: '/',
          },
          {
            title: 'menu.forChildcareProviders',
            href: CHILDCARE_PROVIDER_PATH,
          },
          {
            title: 'menu.forJobSeekers',
            href: EARLY_CHILDHOOD_AND_CHILDCARE_JOB_PATH,
          },
          {
            title: 'menu.forAdvertisers',
            href: CHILDCARE_PROVIDER_PATH,
          },
          {
            title: 'menu.forMedia',
            href: CHILDCARE_PROVIDER_PATH,
          },
        ],
      },
      {
        title: 'menu.furtherInformation',
        subMenus: [
          {
            title: 'menu.subscriptionsAndPricing',
            href: SUBSCRIPTION_PRICING_PATH,
          },
          {
            title: 'menu.claimYourCentre',
            href: CLAIM_CENTRES_URL,
          },
          {
            title: 'menu.bookADemo',
            href: `${CONTACT_US_PATH}?reason=Book+a+Demo`,
          },
        ],
      },
    ],
  },
  {
    title: 'menu.awards',
    subMenus: [
      {
        title: 'menu.award2021',
        href: '/australia-childcare-centre-awards',
      },
      {
        title: 'menu.award2022',
        href: '/australia-childcare-centre-awards-2022',
      },
      {
        title: 'menu.award2023',
        href: '/australia-childcare-centre-awards-2023',
      },
    ],
  },
];

export const PROFILE_MENUS_CONST = {
  profile: {
    title: 'profile.menu.profile',
    IconCPN: ProfileFillIcon,
    key: 'profile',
  },
  parentGuardian: {
    title: 'profile.menu.parentGuardian',
    IconCPN: AddFillIcon,
    key: 'parentGuardian',
  },
  myChildren: {
    title: 'profile.menu.myChildren',
    IconCPN: BabyFillIcon,
    key: 'myChildren',
  },
  subsidy: {
    title: 'profile.menu.subsidy',
    IconCPN: DollarFillIcon,
    key: 'subsidy',
  },
};

export const PROFILE_MENUS = Object.values(PROFILE_MENUS_CONST);

export const JOBS_PROFILE_MENUS_CONST = {
  browseJobs: {
    title: isMobile() ? 'menu.search' : 'jobs.menu.searchForJobs',
    IconCPN: SearchNormalIcon,
    key: 'browseJobs',
  },
  myJobs: {
    title: 'jobs.menu.myJobs',
    IconCPN: DocumentIcon,
    key: 'myJobs',
  },
  jobMatch: {
    title: 'jobs.menu.jobMatch',
    IconCPN: FireIcon,
    key: 'jobMatch',
  },
  jobMailbox: {
    title: 'jobs.menu.jobMailbox',
    IconCPN: MailFilledIcon,
    key: 'jobMailbox',
  },
  jobProfile: {
    title: 'jobs.menu.jobProfile',
    IconCPN: ProfileFillIcon,
    key: 'jobProfile',
  },
};

export const JOBS_PROFILE_MENUS = Object.values(JOBS_PROFILE_MENUS_CONST);

export const PARENT_SIDEBAR_MENUS_CONST = {
  searchForChildcare: {
    title: isMobile() ? 'menu.search' : 'Search for Childcare',
    IconCPN: SearchNormalIcon,
    key: '/',
  },
  favourites: {
    title: 'Favourites',
    IconCPN: HeartFilledIcon,
    key: '/favourites-centers',
  },
  applications: {
    title: 'Applications',
    IconCPN: DocumentIcon,
    key: '/applications',
  },
  mailbox: {
    title: 'Mailbox',
    IconCPN: MailFilledIcon,
    key: isMobile() ? '/m/mailbox' : '/mailbox/[id]',
  },
  parentProfile: {
    title: 'Parent Profile',
    IconCPN: ProfileFillIcon,
    key: '/profile',
  },
};

export const PARENT_SIDEBAR_MENUS = Object.values(PARENT_SIDEBAR_MENUS_CONST);
