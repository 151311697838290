import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfig } from 'utils';

export const centresRecommendServicesApi = rtkCreator(
  'centresRecommendServices',
  'centres/nearby/recommended-services',
  richWebConfig,
);

export const { useGetAllCentresRecommendServicesQuery } =
  centresRecommendServicesApi;
