import { i18n } from 'next-i18next';

/* eslint-disable no-useless-escape */
export const validateRegex = {
  phone: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/gs,
  password: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*\d).{6,}$/g,
  username: /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+([_.])$/g,
  fullName: /^[a-z0-9 ]{3,100}$/iu,
  firstOrLastName: /^[a-z]{1,100}$/iu,
  postCode: /^[0-9]{4}$/,
  regions: /(...)*, [a-zA-Z]*/,
  number: /^[-]?[0-9]*$/g,
  numberUnsigned: /^[0-9]*$/g,
  floatNumber: /^[-]?\d*\.?\d*$/,
  floatNumberUnsigned: /^\d*\.?\d*$/,
  hour: /^(2[0-3]|1[0-9]|[0-9])$/,
  minute: /^[1-5]?[0-9]$/,
  week: /^(5[0-3]|[1-4][0-9]|[1-9])$/,
  percentage: /^(100|[1-9]?[0-9])$/,
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  month: /^(1[0-2]|[1-9])$/,
  day: /^(3[01]|[12][0-9]|[1-9])$/,
  web: /((?:https\:\/\/)|(?:http\:\/\/)|(?:www\.))?([a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(?:\??)[a-zA-Z0-9\-\._\?\,\'\/\\\+&%\$#\=~]+)/gi,
  centreLinkCRN: /\d{9}[a-zA-Z]$/g,
  url: /(https?:\/\/[^\s]+)/g,
  youtube:
    /(?:http(?:s)?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:(?:watch)?\?(?:.*&)?v(?:i)?=|(?:embed|v|vi|user)\/))([^\\?&\\"'<> #]+)/,
  latinLetters: /^[a-zA-Z0-9]+$/,
  spaceCharacter: /[\s\uFEFF\xA0]+$/,
  wordCharacter: /\b|(?=\W)/,
};

export const getPhoneNumberRules = () => [
  {
    pattern: validateRegex.phone,
    message: i18n.t('input.phoneNumber.validateMsg.invalid'),
  },
  {
    min: 6,
    message: i18n.t('input.phoneNumber.validateMsg.min'),
  },
  {
    max: 20,
    message: i18n.t('input.phoneNumber.validateMsg.max'),
  },
];

export const getHouseholdEstimatedTaxableIncomeRules = (
  name = 'householdTaxableIncome',
) => [
  {
    type: 'number',
    max: 1000000000,
    message: i18n.t(
      'input.householdEstimatedTaxableIncome.validateMsg.maximum',
    ),
  },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if ((!value && value !== 0) || getFieldValue(name) > 0) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(
          i18n.t('input.householdEstimatedTaxableIncome.validateMsg.greater0'),
        ),
      );
    },
  }),
];

export const getFirstOrLastNameRules = (type) => [
  {
    pattern: validateRegex.firstOrLastName,
    message: i18n.t(`input.${type}.validateMsg.invalid`),
  },
  {
    min: 2,
    message: i18n.t(`input.${type}.validateMsg.min`),
  },
];

export const getPasswordRules = () => [
  {
    min: 6,
    message: i18n.t('input.password.validateMsg.min', { number: 6 }),
  },
  {
    max: 20,
    message: i18n.t('input.password.validateMsg.max', { number: 20 }),
  },
];

export const getConfirmPasswordRules = (name = 'password') => [
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue(name) === value) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(i18n.t('input.password.validateMsg.notMatch')),
      );
    },
  }),
];

export const getVideoURLRules = () => [
  () => ({
    validator(_, value) {
      if (!value || validateRegex.youtube.test(value)) {
        return Promise.resolve();
      }
      return Promise.reject(
        new Error(i18n.t('input.issuerURL.validateMsg.invalid')),
      );
    },
  }),
];
