export const ICQuoteUp = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 55 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18.5395 26.6464H7.79159C7.97492 15.9443 10.0833 14.1798 16.6603 10.2839C17.4166 9.82559 17.6687 8.86309 17.2103 8.08392C16.7749 7.32767 15.7895 7.07559 15.0333 7.53392C7.28742 12.1173 4.58325 14.9131 4.58325 28.2277V40.5798C4.58325 44.4985 7.76867 47.661 11.6645 47.661H18.5395C22.5728 47.661 25.6208 44.6131 25.6208 40.5798V33.7048C25.6208 29.6943 22.5728 26.6464 18.5395 26.6464Z"
      fill="currentColor"
    />
    <path
      d="M43.3354 26.6464H32.5875C32.7708 15.9443 34.8791 14.1798 41.4562 10.2839C42.2125 9.82559 42.4645 8.86309 42.0062 8.08392C41.5479 7.32767 40.5854 7.07559 39.8062 7.53392C32.0604 12.1173 29.3562 14.9131 29.3562 28.2506V40.6027C29.3562 44.5214 32.5416 47.6839 36.4374 47.6839H43.3124C47.3458 47.6839 50.3937 44.636 50.3937 40.6027V33.7277C50.4166 29.6943 47.3687 26.6464 43.3354 26.6464Z"
      fill="currentColor"
    />
  </svg>
);
