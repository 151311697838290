import { richWebConfig } from 'utils';
import request from 'utils/request';

export async function getStatisticsRatingsApi(data) {
  return request.get(`/ratings/suburb-statistics${data || ''}`, richWebConfig);
}

export async function getStatisticsCentresApi(data) {
  return request.get(`/centres/suburb-statistics${data || ''}`, richWebConfig);
}

export async function getStatisticsCentreServicesApi(data) {
  return request.get(
    `/centre-services/suburb-statistics${data || ''}`,
    richWebConfig,
  );
}
