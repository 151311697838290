import * as Sentry from '@sentry/nextjs';

process.env.NODE_ENV !== 'development' &&
  Sentry.init({
    dsn:
      process.env.NEXT_PUBLIC_SENTRY_DSN ||
      'https://6c8362ed9e6444b9b390aae32705aae8@o999378.ingest.sentry.io/6245476',
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV || 'local',
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    integrations: [new Sentry.BrowserTracing({})],
    tracesSampleRate: 0,
  });
