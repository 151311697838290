import LoginModal from '@uikit/modals/LoginModal';
import RegisterModal from '@uikit/modals/RegisterModal';
import ForgotPasswordModal from '@uikit/modals/ForgotPasswordModal';
import VerifyEmailModal from '@uikit/modals/VerifyEmailModal';

const AuthModal = () => (
  <>
    <LoginModal />
    <RegisterModal />
    <ForgotPasswordModal />
    <VerifyEmailModal />
  </>
);

export default AuthModal;
