import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfig, richWebConfigVer2 } from 'utils';
import { stringifyObjectWithBrackets } from 'utils/request';
import { REQUEST_TYPE } from '@redux/crudCreator/dataProvider';

interface PayloadDataCentreFeatures {
  data: {
    centreId: string;
  };
}

export const centresApi = rtkCreator(
  'centres',
  'centres',
  richWebConfig,
  (builder) => ({
    getByIdCentreBasicInfo: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_BY_ID,
        suffixApiResource: 'details-basic-info',
      }),
      extraOptions: { maxRetries: 1 },
    }),
    getByIdCentreDetailsServices: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_BY_ID,
        suffixApiResource: 'details-services',
      }),
      extraOptions: { maxRetries: 1 },
    }),
    getByIdCentreDetailsApplicableButtons: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_BY_ID,
        suffixApiResource: 'details-applicable-buttons',
      }),
      extraOptions: { maxRetries: 1 },
    }),
    getCentreVirtualTour: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_BY_ID,
        config: richWebConfigVer2,
        customApiResource: `parent/centres/details/virtual-tour?${stringifyObjectWithBrackets(
          payload?.data,
        )}`,
      }),
      extraOptions: { maxRetries: 1 },
    }),
    getCentreFeatures: builder.query({
      query: (payload: PayloadDataCentreFeatures) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_BY_ID,
        config: richWebConfigVer2,
        customApiResource: `parent/centre/details/features?centreId=${payload?.data?.centreId}`,
      }),
      extraOptions: { maxRetries: 1 },
    }),
  }),
);

export const {
  useGetAllCentresQuery,
  useGetByIdCentresQuery,
  useGetByIdCentreBasicInfoQuery,
  useGetByIdCentreDetailsServicesQuery,
  useGetByIdCentreDetailsApplicableButtonsQuery,
  useGetCentreVirtualTourQuery,
  useGetCentreFeaturesQuery,
} = centresApi as any;

export const {
  getByIdCentres,
  getByIdCentreBasicInfo,
  getByIdCentreDetailsServices,
} = centresApi.endpoints;
