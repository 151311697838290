import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'antd';
import {
  setCurrentOptions,
  setDataApply,
} from '@redux/applyApplicationStep/slice';
import { useDispatch } from 'react-redux';
import { CloseCircleFilled } from '@ant-design/icons';
import { formatOnSubmitCareOptions } from 'components/applyApplication/utils';

const Step3Footer = ({ form, onNextStep }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (values?.careOptions?.findIndex((item) => item.isSelected) === -1) {
          Modal.error({
            content: t('applyApplications.requiredChild.desc'),
            icon: <CloseCircleFilled />,
          });
        } else {
          onNextStep();
          dispatch(setCurrentOptions(formatOnSubmitCareOptions(values)));
          dispatch(setDataApply(formatOnSubmitCareOptions(values)));
        }
      })
      .catch(() => null);
  };

  return (
    <div className="flex-center-end">
      <Button type="primary" onClick={onSubmit}>
        {t('applyApplications.nextStep')}
      </Button>
    </div>
  );
};

Step3Footer.propTypes = {
  form: PropTypes.object,
  onNextStep: PropTypes.func,
};

export default Step3Footer;
