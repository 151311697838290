import { SimpleAndComplexEnquiry } from 'types/centres';
import CentreInfo from './CentreInfo';
import ComplexEnquiryForm from './ComplexEnquiryForm';

const ComplexEnquiry = ({ centre, onCancel }: SimpleAndComplexEnquiry) => (
  <div>
    <CentreInfo centre={centre} isComplex />
    <ComplexEnquiryForm {...centre} centreId={centre?.id} onCancel={onCancel} />
  </div>
);

export default ComplexEnquiry;
