import PropTypes from 'prop-types';
import CentreAddressDistance from '@uikit/commons/CentreAddressDistance';
import { BoxCentreInfoStyles } from './styles';

const BoxCentreInfo = ({ centre = {} }) => (
  <BoxCentreInfoStyles className="box-centre-info">
    <div className="box-centre-info__prefix-line" />
    <div className="box-centre-info__content">
      <div className="box-centre-info__name">{centre?.name || ''}</div>
      <CentreAddressDistance item={centre} />
    </div>
  </BoxCentreInfoStyles>
);

BoxCentreInfo.propTypes = {
  centre: PropTypes.object,
};

export default BoxCentreInfo;
