import React from 'react';

import { isEmpty } from 'lodash-es';
import RequiredAuthButton from 'components/rest/RequiredAuthButton';
import { PencilOutlineIcon } from '@uikit/commons/SVGIcons';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import {
  ReplyItem,
  ReviewSummaries,
  StatisticsReview,
} from 'types/parentReview';
import ParentReviewSlide from './ParentReviewSlide';
import { ParentReviewStyle } from './styles';
import ReviewSummary from './ReviewSummary';

interface Props {
  reviewSummaries?: ReviewSummaries;
  summaries?: StatisticsReview[];
  data?: ReplyItem[];
  handleShowViewAllReview?: (value?: string) => void;
  handleShowReview?: () => void;
  isJobSeeker?: boolean;
}

const ParentReviews = ({
  reviewSummaries,
  summaries,
  data,
  handleShowViewAllReview,
  handleShowReview,
  isJobSeeker,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      {isEmpty(data) ? (
        <div className="mt-20">
          <Empty
            image="/images/empty-review.png"
            description={
              isJobSeeker
                ? t('reviews.emptyReviewJobSeeker')
                : t('reviews.emptyReview')
            }
            className="text-grey-l1 size-14 flex-center flex-column"
          />
        </div>
      ) : (
        <ParentReviewStyle className="mt-12">
          <ReviewSummary
            reviewSummaries={reviewSummaries}
            summaries={summaries}
          />
          <ParentReviewSlide
            data={data}
            handleShowViewAllReview={handleShowViewAllReview}
          />
        </ParentReviewStyle>
      )}
      {isJobSeeker ? null : (
        <RequiredAuthButton
          onClick={handleShowReview}
          icon={<PencilOutlineIcon />}
          className="mt-20 w-full fw-bold"
          type="primary"
          ghost
        >
          {t('button.writeReview')}
        </RequiredAuthButton>
      )}
    </>
  );
};

export default ParentReviews;
