import { forgotPassword } from '@redux/auth/actions';
import { setModalConfig } from '@redux/config/actions';
import ModalCustom from '@uikit/commons/ModalCustom';
import { Button, Form, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormInput from '@uikit/form/FormInput';
import AuthModalStyles from '@uikit/modals/AuthModal/styles';

const ForgotPasswordModal = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const modalVisible = useSelector((state) => state.config.modals);
  const { push } = useRouter();

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(setModalConfig({ forgotPasswordModal: false }));
    form.resetFields();
  };

  const onFinish = async (values) => {
    setLoading(true);
    const response = await dispatch(forgotPassword(values));
    setLoading(false);
    if (response?.payload?.message) {
      notification.success({
        message: t('success.title'),
        description: response?.payload?.message,
      });
      onCancel();
      push(`/reset-password?email=${encodeURIComponent(values.email)}`);
    }
  };

  return (
    <ModalCustom
      visible={modalVisible.forgotPasswordModal}
      onCancel={onCancel}
      footer={null}
    >
      <AuthModalStyles>
        <div className="auth__header mb-30">
          <div className="auth__title">{t('auth.forgotPassword')}</div>
          <div className="auth__description">
            {t('auth.forgotPasswordDesc')}
          </div>
        </div>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <FormInput
            name="email"
            placeholder="input.email.label"
            required
            rules={[
              {
                type: 'email',
                message: t('input.email.validateMsg.invalid'),
              },
            ]}
          />
          <Button
            className="submit-btn"
            type="primary"
            htmlType="submit"
            loading={loading}
            size="large"
          >
            {t('button.submit')}
          </Button>
        </Form>
      </AuthModalStyles>
    </ModalCustom>
  );
};

export default ForgotPasswordModal;
