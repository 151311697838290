import React from 'react';
import { useTranslation } from 'react-i18next';
import KindiRatingStyles from './styles';
import NextImage from '../NextImage';

interface Props {
  kindiCareRating?: string | number;
  kindiCareRatingImage?: string;
  className?: string;
  width?: number;
  height?: number;
  imgClassName?: string;
}

const KindiRating = ({
  kindiCareRatingImage,
  kindiCareRating,
  className,
  width = 38,
  height = 38,
  imgClassName,
}: Props) => {
  const { t } = useTranslation();

  const formatValue = Number(kindiCareRating)
    ? Number(kindiCareRating).toFixed(1)
    : t('kindicareRating.NR');

  return (
    <KindiRatingStyles
      className={className ?? ''}
      isRatingImage={!!kindiCareRatingImage}
      width={width}
      height={height}
    >
      {kindiCareRatingImage ? (
        <NextImage
          className={imgClassName}
          width={width}
          height={height}
          alt="kindicare-rating"
          src={kindiCareRatingImage}
        />
      ) : (
        formatValue
      )}
    </KindiRatingStyles>
  );
};

export default KindiRating;
