import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { REQUEST_TYPE } from '@redux/crudCreator/dataProvider';
import { richWebConfig } from 'utils';

export const centresNearbyApi = rtkCreator(
  'centresNearby',
  'centres/nearby',
  richWebConfig,
  (builder) => ({
    getAllCentresNearbyComparison: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_ALL,
        customApiResource: 'centres/nearby/comparison',
      }),
    }),
  }),
);

export const {
  useGetAllCentresNearbyQuery,
  useGetAllCentresNearbyComparisonQuery,
} = centresNearbyApi;

export const { getAllCentresNearby } = centresNearbyApi.endpoints;
