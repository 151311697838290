import React from 'react';
import { getFeaturedImage } from 'utils/dataUtils';
import { Avatar, Space } from 'antd';
import { formatDate } from 'utils/textUtils';
import { ReplyItem } from 'types/parentReview';
import { ReplyItemStyle } from './styles';

interface Props {
  replyItem?: ReplyItem;
  centreName?: string;
}

const RepliesItem = ({ replyItem, centreName }: Props) => {
  const featureImage = getFeaturedImage(replyItem?.centre?.images);

  return (
    <ReplyItemStyle className="bg-gray rounded-12 mt-8">
      <Space size={12}>
        <Avatar src={featureImage} />
        <div className="fw-bold">
          <div className="size-16 xs:size-14 text-black-l8 ellipsis-1-t">
            {centreName}
          </div>
          <div className="size-14 xs:size-12 text-grey-l1">
            {formatDate(replyItem?.createdAt, 'DD MMM YYYY')}
          </div>
        </div>
      </Space>
      <div className="mt-8 size-16 xs:size-14 lh-24 text-black-l8 text-pre-wrap">
        {replyItem?.comment}
      </div>
    </ReplyItemStyle>
  );
};

export default RepliesItem;
