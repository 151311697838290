/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
import { useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useGetByIdCentreDetailsServicesQuery } from '@redux/centres';
import { useDispatch, useSelector } from 'react-redux';
import { getChildrenOfUser } from '@redux/auth/actions';
import { Skeleton } from 'antd';
import { RestInputContext } from '@uikit/form/RestInputContext';
import dayjs from 'utils/dayUtils';
import { pick } from 'lodash-es';
import FormAdditionChildren from './FormAdditionChildren';
import ChildItemBox from './FormAdditionChildren/ChildItemBox';

const ChildrenInformation = ({
  name = 'parentRequestDetails',
  showHeader,
  isSetDefaultValue = true,
  isApplication,
  centreSlug,
}) => {
  const { query } = useRouter();
  const dispatch = useDispatch();
  const { form } = useContext(RestInputContext);

  const { loading } = useSelector((state) => state.auth.children);

  const { data: centre } = useGetByIdCentreDetailsServicesQuery({
    data: {
      id: query?.id ?? centreSlug,
    },
  });

  useEffect(() => {
    isSetDefaultValue &&
      dispatch(getChildrenOfUser()).then(({ payload }) => {
        const parentRequestDetails = payload?.results?.map((item) => ({
          child: {
            ...pick(item, [
              'id',
              'firstName',
              'lastName',
              'allergies',
              'specialNeeds',
              'centrelinkCRN',
              'gender',
              'requiredDays',
              'avatar',
            ]),
            dateOfBirth: item.dateOfBirth ? dayjs(item.dateOfBirth) : null,
          },
        }));

        form.setFieldsValue({
          [name]: parentRequestDetails,
        });
      });
  }, [dispatch]);

  return (
    <Skeleton active loading={loading}>
      <FormAdditionChildren
        name={name}
        addMoreTitle="button.addNewChild"
        showHeader={showHeader}
      >
        <ChildItemBox
          centreServices={centre?.services}
          isApplication={isApplication}
        />
      </FormAdditionChildren>
    </Skeleton>
  );
};

ChildrenInformation.propTypes = {
  name: PropTypes.string,
  showHeader: PropTypes.bool,
  isSetDefaultValue: PropTypes.bool,
  isApplication: PropTypes.bool,
  centreSlug: PropTypes.string,
};

export default ChildrenInformation;
