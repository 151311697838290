import { ReviewsItem } from 'types/parentReview';
import ParentReviewCard from './ParentReviewCard';
import { SlideStyle } from './styles';

interface Props {
  data: ReviewsItem[];
  handleShowViewAllReview?: () => void;
}

const ParentReviewSlide = ({ data, handleShowViewAllReview }: Props) => {
  const newData =
    data?.length >= 10 ? [...data, { isShowViewAllCard: true }] : data;

  return data?.length ? (
    <SlideStyle>
      <div className="d-flex h-full">
        {newData?.map((item, index) => (
          <div>
            <ParentReviewCard
              item={item}
              key={index}
              handleShowViewAllReview={handleShowViewAllReview}
              isShowViewAllCard={item?.isShowViewAllCard}
            />
          </div>
        ))}
      </div>
    </SlideStyle>
  ) : null;
};

export default ParentReviewSlide;
