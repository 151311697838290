import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const getPrivacyPolicyApi = rtkCreator(
  'richwebPrivacyPolicy',
  'parent/config/page-privacy-policy',
  richWebConfigVer2,
);

export const getTermsConditionsApi = rtkCreator(
  'richwebTermsConditions',
  'parent/config/page-term-conditions',
  richWebConfigVer2,
);

export const { useGetAllRichwebPrivacyPolicyQuery } = getPrivacyPolicyApi;
export const { useGetAllRichwebTermsConditionsQuery } = getTermsConditionsApi;
