import PropTypes from 'prop-types';
import { DatePicker, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import { DATE_FORMAT_INPUT } from 'configs/constants';

const FormDatePicker = ({
  name,
  label,
  required,
  messageRequire = 'error.required',
  onChange,
  initialValue,
  rules = [],
  placeholder,
  format = DATE_FORMAT_INPUT,
  formItemProps,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Form.Item
      {...(label && {
        label: t(label),
      })}
      name={name}
      rules={[
        {
          required,
          message: t(messageRequire),
        },
        ...rules,
      ]}
      initialValue={initialValue}
      {...formItemProps}
    >
      <DatePicker
        onChange={onChange}
        format={format}
        {...(placeholder && {
          placeholder: t(placeholder),
        })}
        {...props}
      />
    </Form.Item>
  );
};

FormDatePicker.propTypes = {
  name: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  label: PropTypes.string,
  required: PropTypes.bool,
  messageRequire: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  initialValue: PropTypes.any,
  rules: PropTypes.array,
  isShowTooltip: PropTypes.bool,
  format: PropTypes.string,
  formItemProps: PropTypes.object,
};

export default FormDatePicker;
