import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  fetchedConfig: false,
  fetchedUserMe: false,
  fetchedConfigSubsidy: false,
  isLoadedTopCarouselHome: false,
  isShowSmartBanner: true,
  carouselHomeLoaded: {
    brands: false,
    childcareNearby: false,
    topPicks: false,
  },
};

const { actions, reducer } = createSlice({
  name: 'configLocal',
  initialState,
  reducers: {
    setFetchedConfig: (state, { payload }) => {
      state.fetchedConfig = payload;
    },
    setFetchedUserMe: (state, { payload }) => {
      state.fetchedUserMe = payload;
    },
    setFetchedConfigSubsidy: (state, { payload }) => {
      state.fetchedConfigSubsidy = payload;
    },
    setFetchedTotalUnreadInbox: (state, { payload }) => {
      state.fetchedTotalUnreadInbox = payload;
    },
    setCarouselHomeLoadedByKey: (state, { payload }) => {
      state.carouselHomeLoaded[payload.key] = payload.value;
    },
    setShowSmartBanner: (state, { payload: visibleState }) => {
      state.isShowSmartBanner = visibleState;
    },
  },
  extraReducers: {},
});

export const {
  setFetchedConfig,
  setFetchedUserMe,
  setFetchedConfigSubsidy,
  setFetchedTotalUnreadInbox,
  setCarouselHomeLoadedByKey,
  setShowSmartBanner,
} = actions;

export default reducer;
