import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteJobMailboxApi,
  getJobDetailApi,
  getJobMailboxInboxesApi,
  getJobMailboxMessagesApi,
  sendJobMailboxMessageApi,
} from 'api/jobMailbox';
import { updateMessagesAfterSent } from './slice';

export const MODEL_NAME = 'jobMailbox';

export const getJobMailboxInboxesAction = createAsyncThunk(
  `${MODEL_NAME}/getJobMailboxInboxes`,
  async ({ page, size } = {}, thunkApi) => {
    try {
      const response = await apiWrapper({}, getJobMailboxInboxesApi, {
        page,
        size,
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getJobMailboxMessagesAction = createAsyncThunk(
  `${MODEL_NAME}/getJobMailboxMessages`,
  async ({ chatBoxId, page, size } = {}, thunkApi) => {
    try {
      const response = await apiWrapper({}, getJobMailboxMessagesApi, {
        chatBoxId,
        page,
        size,
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getJobDetailAction = createAsyncThunk(
  `${MODEL_NAME}/getJobDetail`,
  async ({ postId } = {}, thunkApi) => {
    try {
      const response = await apiWrapper({}, getJobDetailApi, { postId });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const sendJobMailboxMessageAction = createAsyncThunk(
  `${MODEL_NAME}/sendJobMailboxMessage`,
  async ({ chatBoxId, content, files } = {}, thunkApi) => {
    try {
      const response = await apiWrapper({}, sendJobMailboxMessageApi, {
        chatBoxId,
        content,
        files,
      });

      const newMessage = response?.data?.results?.[0];

      if (newMessage) {
        thunkApi.dispatch(updateMessagesAfterSent(newMessage));
      }

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const deleteJobMailbox = createAsyncThunk(
  `${MODEL_NAME}/deleteJobMailbox`,
  async (chatBoxId, thunkApi) => {
    try {
      const response = await apiWrapper(
        {
          isShowSuccess: true,
        },
        deleteJobMailboxApi,
        chatBoxId,
      );

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
