import { getAllCustom } from '@redux/crudCreator/actions';
import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteInboxApi,
  getAllInboxesApi,
  getInboxByIdApi,
  getTotalUnreadEnquiresApi,
  seenMessagesApi,
} from 'api/inboxes';
import { InboxesResponse, InboxesItem, ReadInboxesItem } from 'types/inboxes';

export interface PayloadParams {
  id?: string;
  firstInboxId?: string;
  data?: {
    ids?: string[];
  };
}

export const MODEL_NAME = 'inboxes';

export const getAllInboxes = getAllCustom(MODEL_NAME, getAllInboxesApi, null);

export const seenMessage = createAsyncThunk<InboxesItem, PayloadParams>(
  'inboxes/seenMessage',
  async (payload, thunkApi) => {
    try {
      const response = await apiWrapper(
        {},
        seenMessagesApi,
        payload.id,
        payload.data,
      );
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.id, data: error });
    }
  },
);

export const getTotalUnreadEnquires = createAsyncThunk(
  'inboxes/getTotalUnreadEnquires',
  async (_payload, thunkApi) => {
    try {
      const response = await apiWrapper({}, getTotalUnreadEnquiresApi);
      return response?.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

export const getInboxById = createAsyncThunk<ReadInboxesItem, PayloadParams>(
  'inboxes/getInboxById',
  async (payload, thunkApi) => {
    try {
      const response = await apiWrapper({}, getInboxByIdApi, payload.id);
      const record = response?.data;
      record?.clientHasNewMessage &&
        thunkApi.dispatch(
          seenMessage({
            id: record.id,
            data: {
              ids: [record.latestMessageId],
            },
          }),
        );
      return record;
    } catch (error) {
      return thunkApi.rejectWithValue({ id: payload.id, data: error });
    }
  },
);

export const deleteInbox = createAsyncThunk<
  { id: string },
  PayloadParams,
  { state: InboxesResponse }
>('inboxes/deleteInbox', async (payload, thunkApi) => {
  try {
    const data = thunkApi.getState().inboxes?.data;

    await apiWrapper({}, deleteInboxApi, payload.id);

    return {
      id: payload.id,
      success: true,
      firstInboxId:
        data?.[0]?.id === payload.id ? data?.[1]?.id : data?.[0]?.id,
    };
  } catch (error) {
    return thunkApi.rejectWithValue({ id: payload.id, data: error });
  }
});
