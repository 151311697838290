import { Typography } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const { Paragraph } = Typography;

const ToggleParagraph = ({
  content,
  rows = 3,
  symbol,
  className,
  descriptionTitle,
}) => {
  const [isExpand, setIsExpand] = useState(false);
  const [counter, setCounter] = useState(0);
  const { t } = useTranslation();

  const onExpand = () => {
    setIsExpand(true);
    setCounter(getNewCounter);
  };
  const onCloseExpand = () => {
    setIsExpand(false);
    setCounter(getNewCounter);
  };

  const getNewCounter = () => (!isExpand ? counter : counter + 1);

  return (
    <div className="toggle-paragraph">
      <h2 className="centre-section-title">{descriptionTitle || ''}</h2>
      <div key={counter}>
        <Paragraph
          ellipsis={{
            rows,
            expandable: true,
            onExpand,
            symbol: symbol || t('button.readMore'),
          }}
          className={`${className} paragraph-read-more`}
        >
          {content}
        </Paragraph>
      </div>
      {isExpand && (
        <span
          role="presentation"
          className="btn-toggle pointer text-highlight size-16"
          onClick={onCloseExpand}
        >
          {t('button.readLess')}
        </span>
      )}
    </div>
  );
};

ToggleParagraph.propTypes = {
  content: PropTypes.string,
  rows: PropTypes.number,
  symbol: PropTypes.string,
  className: PropTypes.string,
  descriptionTitle: PropTypes.string,
};

export default ToggleParagraph;
