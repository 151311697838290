import { enhancedApi } from 'generated/apis';

export const enhanceGeneratedApi = enhancedApi.enhanceEndpoints({
  addTagTypes: [],
  endpoints: {},
});

export const {
  suburbProfileDescriptionControllerGetSuburbProfileDescription,
  centreServicesSuburbProfileControllerGetCentreServiceSuburbProfile,
  kindiCareAvgRatingOfSuburbProfileControllerGetKindiCareAvgRatingOfSuburbProfile,
  parentReviewsOfSuburbProfileControllerGetParentReviewsOfSuburbProfile,
  suburbProfileHighestRateControllerGetHighestRateCentres,
} = enhanceGeneratedApi.endpoints;
