export const CARD_TYPES = {
  JOB_MATCH_CARD: 'JobMatchCard',
  JOB_CARD: 'JobCard',
  FEATURED_BUSINESS_CARD: 'FeaturedBusinessCard',
  ARTICLE_CARD: 'ArticleCard',
  HOME_PAGE_JOB_MATCH_LOCK: 'HomepageJobMatchLock',
  ORGANIZATION_DISCOUNT_CARD: 'OrganizationDiscountCard',
  ADS_CARD: 'AdsCard',
};

export const CAROUSEL_TYPES = {
  JOB_CAROUSEL: 'JOB_CAROUSEL',
  FEATURED_BUSINESS_CAROUSEL: 'FEATURED_BUSINESS_CAROUSEL',
  ARTICLE_CAROUSEL: 'ARTICLE_CAROUSEL',
  JOB_MATCH_CAROUSEL: 'JOB_MATCH_CAROUSEL',
  ORGANIZATION_DISCOUNT_CARD_CAROUSEL: 'ORGANIZATION_DISCOUNT_CARD_CAROUSEL',
  ADS_CAROUSEL: 'ADS_CAROUSEL',
};

export const SMALL_CARD_CAROUSEL_SETTINGS = {
  slidesToShow: 6,
  slidesToScroll: 6,
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 5,
      },
    },
    {
      breakpoint: 900,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 720,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 2.5,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 450,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 350,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
  ],
};

export const MEDIUM_CARD_CAROUSEL_SETTINGS = {
  slidesToShow: 4,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 860,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const LARGE_CARD_CAROUSEL_SETTINGS = {
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 860,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 520,
      settings: {
        slidesToShow: 1.5,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 400,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const STAFF_DISCOUNT = 'Staff Discount';

export const EDUCATOR_DISCOUNT = 'Educator Discount';

export const SKELETON_LOADING_LINES = {
  rows: 20,
};

export const CAROUSELS_VIEW_ALL = {
  JOB_MATCH_SUGGESTIONS: {
    TITLE: 'Job Match Suggestions',
    URL_PATH: '/early-childhood-and-childcare-jobs?t=jobMatch',
  },
  EARLY_LEARNING_JOBS_NEARBY: {
    TITLE: 'Early Learning Jobs Nearby',
    URL_PATH: '/early-learning-jobs-nearby',
  },
  NEWEST_EARLY_LEARNING_JOBS: {
    TITLE: 'Newest Early Learning Jobs',
    URL_PATH: '/newest-early-learning-jobs',
  },
  FEATURED_EARLY_LEARNING_JOBS: {
    TITLE: 'Featured Early Learning Jobs',
    URL_PATH: '/featured-early-learning-jobs',
  },
  GREAT_OFFERS: {
    TITLE: 'Great Offers',
    URL_PATH: '/for-job-seekers-great-offers',
  },
  EDUCATOR_AND_STAFF_DISCOUNT: {
    TITLE: 'Educator & Staff Discounts',
    URL_PATH: '/discounts-for-childcare-workers',
  },
  FEATURED_BRANDS: {
    TITLE: 'Featured Brands',
    URL_PATH: '/featured-childcare-brand-for-job-seekers',
  },
  FEATURED_COMPANIES: {
    TITLE: 'Featured Companies',
    URL_PATH: '/featured-childcare-companies-for-job-seekers',
  },
  CAREER_ADVICE_AND_RESOURCES: {
    TITLE: 'Career Advice & Resources',
    URL_PATH: '/career-advice-for-childcare-job-seekers',
  },
  SCHOLARSHIPS_AND_INCENTIVES: {
    TITLE: 'Scholarships & Incentives',
    URL_PATH: '/early-childhood-scholarships-and-incentives',
  },
  HINTS_TIPS_STORIES: {
    TITLE: 'Hints, Tips & Stories',
    URL_PATH: '/tips-for-childcare-workers',
  },
  EDUCATOR_STORIES: {
    TITLE: 'Educator Stories',
    URL_PATH: '/educators-stories',
  },
  FAVORITE_JOBS: {
    TITLE: 'Your Favourite Jobs',
    URL_PATH: '/early-childhood-and-childcare-jobs?t=myJobs&tab=favourites',
  },
  RECENTLY_VIEWED: {
    TITLE: 'Recently Viewed',
    URL_PATH: '/early-childhood-and-childcare-jobs?t=myJobs&tab=viewed',
  },
};

export const FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH =
  '/early-childhood-and-childcare-jobs';

export const IGNORE_JOB_SEARCH_PARAMS = [
  'ignoreParams',
  'jobTitle',
  'jobRole',
  'state',
  'postcode',
  'suburb',
  'company',
  'brand',
  'centre',
  'textSearch',
  'filter',
  'searchFilter',
  't',
  'title',
  'q',
  'searchType',
];

export const JOB_CATEGORIES = [
  {
    categoryName: 'Career Advice & Resources',
    url: 'career-advice-for-childcare-job-seekers',
    filterCategories: ['Career Advice & Resources'],
  },
  {
    categoryName: 'Scholarships & Incentives',
    url: 'early-childhood-scholarships-and-incentives',
    filterCategories: ['Scholarships & Incentives'],
  },
  {
    categoryName: 'Hints, Tips & Stories',
    url: 'tips-for-childcare-workers',
    filterCategories: [
      'Products',
      'Media',
      'Podcasts',
      'Life Stages',
      'Hints, Tips & Stories',
      'Parenting',
      'Lifestyle',
      'Health',
      'Education',
      'Finance',
    ],
  },
];

export const PROFILE_URL = {
  JOB_PROFILE: '/early-childhood-and-childcare-jobs?t=jobProfile',
  JOB_PROFILE_APPLY_STATUS:
    '/early-childhood-and-childcare-jobs?t=jobProfile&status=apply-to-job',
};

export const APPLY_JOB_SEEKERS_STATUS = {
  APPLIED: 'Applied',
  APPLY_TO_JOB: 'apply-to-job',
  AGREE: 'Agree',
  UPDATE: 'Update',
  SUCCESSFULAPPLICATION: 'SuccessfulApplication',
  DONE: 'Done',
};

export const BUSINESS_LEVEL = {
  CENTRE: 'centre',
  COMPANY: 'company',
};

export const EMPTY_SLUG_CHARACTER = '_';

export const JOBS_SORT_DEFAULT = {
  nearby: 'shortestDistance',
  newest: 'newest',
  greatOffers: 'greatOffers',
  educatorAndStaffDiscount: 'shortestDistance',
  featuredJob: 'featured',
  featuredCompanies: 'shortestDistance',
  featuredBrands: 'shortestDistance',
  shortestDistance: 'shortestDistance',
};
