export const APPLICATION_STATUS_CONSTANT = {
  submitted: 'SUBMITTED',
  opened: 'OPENED',
  waitingResponse: 'AWAITING_RESPONSE',
  inProgress: 'IN_PROGRESS',
  informationRequested: 'INFORMATION_REQUESTED',
  responseReceived: 'RESPONSE_RECEIVED',
  waitListed: 'WAIT_LISTED',
  approved: 'APPROVED',
  accepted: 'ACCEPTED',
  enrolled: 'ENROLLED',
  unsuccessful: 'UNSUCCESSFUL',
  declined: 'DECLINED',
  cancelled: 'CANCELLED',
  visitOrTour: 'VISIT_OR_TOUR',
  completed: 'COMPLETED',
};

export const APPLICATION_PATH = {
  DETAIL: '/application-details',
};

export const APPLICATION_STATUS_CONST_ITEM = {
  submitted: {
    value: APPLICATION_STATUS_CONSTANT.submitted,
    text: 'applicationStatus.submitted',
    dotColor: '#FB8429',
    backgroundColor: '#FFF4EC',
    keyEnrolmentPipelineValue: 'submittedApplicationsValue',
  },
  inProgress: {
    value: APPLICATION_STATUS_CONSTANT.inProgress,
    text: 'applicationStatus.inProgress',
    dotColor: '#36BF57',
    backgroundColor: '#EDF9F0',
    keyEnrolmentPipelineValue: 'inProgressApplicationsValue',
  },
  visitOrTour: {
    value: APPLICATION_STATUS_CONSTANT.visitOrTour,
    text: 'applicationStatus.visitOrTour',
    dotColor: '#BF2CF3',
    backgroundColor: '#EDE0FC',
    keyEnrolmentPipelineValue: 'bookOrTourApplicationsValue',
  },
  waitListed: {
    value: APPLICATION_STATUS_CONSTANT.waitListed,
    text: 'applicationStatus.waitListed',
    dotColor: '#37C5E5',
    backgroundColor: '#E8FBFF',
    keyEnrolmentPipelineValue: 'waitlistedApplicationsValue',
  },
  accepted: {
    value: APPLICATION_STATUS_CONSTANT.accepted,
    text: 'applicationStatus.accepted',
    dotColor: '#DB147F',
    backgroundColor: '#FFF3FC',
    keyEnrolmentPipelineValue: 'acceptedApplicationsValue',
  },
  enrolled: {
    value: APPLICATION_STATUS_CONSTANT.enrolled,
    text: 'applicationStatus.enrolled',
    dotColor: '#316DDE',
    backgroundColor: '#F0F7FF',
    keyEnrolmentPipelineValue: 'enrolledApplicationsValue',
  },
  declined: {
    value: APPLICATION_STATUS_CONSTANT.declined,
    text: 'applicationStatus.declined',
    dotColor: '#857E7F',
    backgroundColor: '#F2F2F2',
  },
  cancelled: {
    value: APPLICATION_STATUS_CONSTANT.cancelled,
    text: 'applicationStatus.cancelled',
    dotColor: '#FF0E0E',
    backgroundColor: '#FFE9E9',
  },
};

export const APPLICATION_STATUS = Object.values(APPLICATION_STATUS_CONST_ITEM);

export const APPLICATION_STATUS_BOARD_CONST = {
  inProgress: {
    value: APPLICATION_STATUS_CONSTANT.inProgress,
    text: 'applicationStatus.inProgress',
    dotColor: '#FB8429',
    backgroundColor: '#FFF4EC',
    status: [
      APPLICATION_STATUS_CONSTANT.submitted,
      APPLICATION_STATUS_CONSTANT.inProgress,
      APPLICATION_STATUS_CONSTANT.visitOrTour,
    ],
  },
  waitListed: {
    value: APPLICATION_STATUS_CONSTANT.waitListed,
    text: 'applicationStatus.waitListed',
    dotColor: '#3BE7F2',
    backgroundColor: '#E8FBFF',
    status: [APPLICATION_STATUS_CONSTANT.waitListed],
  },
  completed: {
    value: APPLICATION_STATUS_CONSTANT.completed,
    text: 'applicationStatus.completed',
    dotColor: '#DB147F',
    backgroundColor: '#FFF3FC',
    status: [
      APPLICATION_STATUS_CONSTANT.enrolled,
      APPLICATION_STATUS_CONSTANT.accepted,
      APPLICATION_STATUS_CONSTANT.cancelled,
      APPLICATION_STATUS_CONSTANT.declined,
    ],
  },
};

export const APPLICATION_STATUS_BOARD = Object.values(
  APPLICATION_STATUS_BOARD_CONST,
);

export const getStatusItem = (status) =>
  APPLICATION_STATUS.find((item) => item.value === status);

export const JOB_APPLICATION_STATUS = {
  DECLINED: 'Declined',
  CANCELLED: 'Cancelled',
  EMPLOYED: 'Employed',
};
