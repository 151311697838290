import axios from 'axios';
import { handleSentryRequestError } from 'api/sentry';
import qs from 'qs';
import { handleExpiredAndRefreshToken } from './apiUtils';
import { getBaseURLApi, getToken } from '.';

const TIME_OUT = 30000;

export const stringifyObjectWithBrackets = (object) =>
  qs.stringify(object, {
    arrayFormat: 'brackets',
    skipNulls: true,
  });

const request = axios.create({
  baseURL: getBaseURLApi(),
  timeout: TIME_OUT,
  paramsSerializer: (params) => stringifyObjectWithBrackets(params),
});

export const setInitHeader = (isResetToken = false) => {
  if (process.browser) {
    const token = getToken();
    if (token) {
      request.defaults.headers.common.authorization = `Bearer ${token}`;
    }
  }

  if (isResetToken) {
    request.defaults.headers.common.authorization = '';
  }

  request.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error?.response || error.message),
  );
};

export const setInitHeaderSSR = (token) => {
  request.defaults.headers.common.authorization = token
    ? `Bearer ${token}`
    : null;
};

request.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error?.config;
    if (error?.response?.status === 401 && !originalRequest?._retry) {
      originalRequest._retry = true;
      return handleExpiredAndRefreshToken({ request, originalRequest });
    }

    handleSentryRequestError(error);

    return Promise.reject(error?.response?.data);
  },
);

export default request;
