import styled from 'styled-components';
import { respondTo } from 'utils/variables';
import ModalCustom from '@uikit/commons/ModalCustom';

export const StyledModal = styled(ModalCustom)`
  margin: 80px auto;

  .ant-modal-header {
    border-bottom: 1px solid #f0f0f0;
    text-align: center;
    padding: 24px !important;
  }

  .ant-modal-title {
    font-size: 24px;
    font-weight: 700;
  }

  .ant-modal-footer {
    border-top: 1px solid #f0f0f0;
    text-align: center;
    padding-left: 48px;
    padding-right: 48px;

    .ant-btn {
      height: 50px;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  ${respondTo.xs`
    min-width: 100vw;
    padding: 0;
    margin: 0 auto !important;

  .ant-modal-content {
    border-radius: 0;
    background-color: var(--text-white);
    height: 100vh !important;
  }

  .ant-modal-header {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    position: fixed;
    width: 100%;
    z-index: 1;
    border-radius: 0;
    top: 0;
    right: 0;
    left: 0;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-modal-title {
    font-size: 16px;
  }
  .ant-modal-close {
    left: 0;
    border-radius: 0;
    box-shadow: none;
    top: 0;
  }
  .ant-modal-close-x {
    height: 48px !important;
    line-height: unset;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-body {
    box-sizing: content-box;
    padding: 48px 20px 0 20px;
    position: relative;
    width: 90%;
    overflow: hide;
  }
  .ant-modal-footer {
    position: fixed;
    width: 100vw;
    bottom: 0;
    padding: 12px 20px;
    background: var(--text-white);
    border-radius: 0;
  }
`}
`;

export const WriteReviewModalStyles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0 24px;

  .centre-name {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: var(--color-grey);
  }

  .ant-rate-star {
    color: var(--primary);
    font-size: 22px !important;
  }

  .recommend-checkbox {
    margin-bottom: 16px;
    .ant-form-item-control {
      display: none;
    }
    .ant-form-item-label label {
      margin-bottom: 0;
    }
  }

  #is-recommend-checkbox {
    display: none;
  }

  .recommend-checkbox-label {
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-secondary);
    .anticon {
      font-size: 20px;
      margin-right: 10px;
    }
  }

  .author-name {
    display: flex;
    margin-top: 24px;

    .ant-avatar {
      margin-right: 24px;
    }
  }

  .ant-form-item-label label,
  .field-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin-bottom: 12px;
  }

  .ant-form-item-label {
    margin: 0;
    padding: 0;

    label {
      padding: 0;
    }
  }

  .comment-textarea {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    padding: 16px;
    &::placeholder {
      color: var(--color-grey-l2);
    }
  }

  .author-name-field {
    margin-bottom: 0;
    .ant-form-item-label label {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-secondary);
    }
  }

  .rating-field {
    margin-bottom: 18px;
    margin-top: 24px;

    .ant-form-item-label label {
      margin: 24px 0 0;
    }
  }

  .comment-field {
    .ant-form-item-label {
      margin-bottom: 8px;
    }

    .ant-form-item-explain-error {
      margin: 8px 0 14px;
    }
  }

  .author-name-field .ant-form-item-explain-error {
    padding-left: 72px !important;
  }

  @media only screen and (max-width: 576px) {
    max-height: calc(100vh - 160px);
    height: min-content;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 12px;
    overscroll-behavior: 'contain';
    ::-webkit-scrollbar {
      display: none;
    }
    padding: 20px 0;
    .centre-name {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: var(--color-grey);
    }

    .rate-question {
      font-size: 14px;
    }

    .recommend-checkbox-label {
      font-size: 14px;
    }

    .author-name {
      font-size: 14px;
    }

    .ant-form-item-label label,
    .field-title {
      font-size: 14px;
    }

    .ant-form-item-label {
      font-size: 14px;
    }

    .comment-textarea {
      font-size: 14px;
    }

    .author-name-field {
      font-size: 14px;

      .ant-form-item-label label {
        font-size: 14px;
      }
    }

    .author-name {
      .ant-avatar {
        margin-right: 8px;
      }
    }
    .author-name-field .ant-form-item-explain-error {
      padding-left: 55px !important;
    }
  }
`;
