import request from 'utils/request';

export const getAllApplicationsApi = (data) =>
  request.get(`/applications${data}`);

export const getByIdApplicationsApi = (id) =>
  request.get(`/applications/${id}`);

export const applyApplicationApi = (data) =>
  request.post('/applications', data);

export const cancelApplicationApi = (id) =>
  request.put(`/applications/${id}/CANCELLED`);
