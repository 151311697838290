import { createSlice } from '@reduxjs/toolkit';
import { getJobRequirements } from './actions';

const initialState = {
  selectedJobs: [],
  initialSlide: 0,
  jobRequirements: {
    loading: false,
    data: {},
  },
};

const jobCompare = createSlice({
  name: 'jobCompare',
  initialState,
  reducers: {
    addJobToCompare: (state, { payload }) => {
      state.selectedJobs = [payload.job, ...state.selectedJobs];
      state.initialSlide = 0;
    },
    addJobListToCompare: (state, { payload }) => {
      state.selectedJobs = [...payload.jobList, ...state.selectedJobs];
    },
    removeJobFromCompare: (state, { payload }) => {
      const removedIndex = state.selectedJobs.findIndex(
        (job) => job.id === payload.id,
      );
      state.selectedJobs = state.selectedJobs.filter(
        (job) => job.id !== payload.id,
      );
      state.initialSlide =
        removedIndex > 0 && removedIndex <= state.selectedJobs.length
          ? removedIndex - 1
          : 0;
    },
    removeAllJobs: (state) => {
      state.selectedJobs = [];
      state.initialSlide = 0;
    },
  },
  extraReducers: {
    [getJobRequirements.pending]: (state) => {
      state.jobRequirements.loading = true;
    },
    [getJobRequirements.fulfilled]: (state, { payload }) => {
      state.jobRequirements.data = payload;
      state.jobRequirements.loading = false;
    },
    [getJobRequirements.rejected]: (state, { payload }) => {
      state.jobRequirements.loading = false;
      state.jobRequirements.error = payload || null;
    },
  },
});

export const {
  addJobToCompare,
  addJobListToCompare,
  removeJobFromCompare,
  removeAllJobs,
} = jobCompare.actions;
export default jobCompare.reducer;
