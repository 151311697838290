import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfig } from 'utils';

export const inboxesApi = rtkCreator('inboxes', 'inboxes', richWebConfig);

export const {
  useGetAllInboxesQuery,
  useGetByIdInboxesQuery,
  useCreateInboxesMutation,
} = inboxesApi;

export const { getAllInboxes, getByIdInboxes, createInboxes } =
  inboxesApi.endpoints;
