import { flatten } from 'lodash-es';
import { get, post, put, del, patch } from './utils';

export async function getAllApi(resource, data, config) {
  return get(`/${resource}${data || ''}`, null, config);
}

export async function getFullStaticDataApi(resource) {
  const firstLoad = await get(`/${resource}?fields=id&fields=id&offset=0`);
  const urls = [];
  if (firstLoad.total > 10) {
    for (let i = 10; i < firstLoad.total; i += 10) {
      urls.push(get(`/${resource}?fields=id&fields=id&offset=${i}`));
    }
  }

  const data = await Promise.all(urls);
  return flatten([firstLoad.data, ...data.map((e) => e.data)]);
}

export async function getDataByIdApi(
  resource,
  id,
  data,
  suffixApiResource,
  config,
) {
  if (id) {
    return get(
      `/${resource}/${id}${suffixApiResource ? `/${suffixApiResource}` : ''}`,
      data,
      config,
    );
  }

  return get(
    `/${resource}${suffixApiResource ? `/${suffixApiResource}` : ''}`,
    data,
    config,
  );
}

export async function delApi(resource, id, config) {
  if (id) {
    return del(`/${resource}/${id}`, null, config);
  }
  return del(`/${resource}`, null, config);
}

export async function postApi(resource, data, config) {
  return post(`/${resource}`, data, config);
}

export async function putApi(resource, id, data, config) {
  if (id) {
    return put(`/${resource}/${id}`, data, config);
  }
  return put(`/${resource}`, data, config);
}

export async function patchApi(resource, id, data, config) {
  if (id) {
    return patch(`/${resource}/${id}`, data, config);
  }
  return patch(`/${resource}`, data, config);
}
