import { createDraftSafeSelector } from '@reduxjs/toolkit';

const getRawCentreDetailsBasicInfo = (state) => state.centreDetails.basicInfo;
const getRawCentreDetailsRatings = (state) => state.centreDetails.ratings;
const getRawCentreDetailsServices = (state) => state.centreDetails.services;
const getRawCentreReviewsSummaries = (state) =>
  state.centreDetails.reviewsSummaries;
const getRawCentreReviews = (state) => state.centreDetails.reviews;
const getRawCentreApplicableButtons = (state) =>
  state.centreDetails.applicableButtons;
const getRawCentreConnectButtons = (state) =>
  state.centreDetails.connectButtons;

const getRawCentreStaffProfilesCarousel = (state) =>
  state.centreDetails.staffProfilesCarousel;

const getRawCentreStaffProfiles = (state) => state.centreDetails.staffProfiles;

const getRawCentreStaffProfileDetail = (state) =>
  state.centreDetails.staffProfileDetail;

const getRawCentreProgramLeanings = (state) => state.centreDetails.programs;

const getRawCentreProgramCarousel = (state) =>
  state.centreDetails.programCarousel;

const getRawCentreProgramDetail = (state) => state.centreDetails.programDetail;

export const getCentreDetailsBasicInfoSelector = createDraftSafeSelector(
  [getRawCentreDetailsBasicInfo],
  (data) => data,
);

export const getCentreDetailsRatingsSelector = createDraftSafeSelector(
  [getRawCentreDetailsRatings],
  (data) => data,
);

export const getCentreDetailsServicesSelector = createDraftSafeSelector(
  [getRawCentreDetailsServices],
  (data) => data,
);

export const getCentreReviewsSummariesSelector = createDraftSafeSelector(
  [getRawCentreReviewsSummaries],
  (data) => data,
);

export const getCentreReviewsSelector = createDraftSafeSelector(
  [getRawCentreReviews],
  (data) => data,
);

export const getCentreApplicableButtonsSelector = createDraftSafeSelector(
  [getRawCentreApplicableButtons],
  (data) => data,
);

export const getCentreConnectButtonsSelector = createDraftSafeSelector(
  [getRawCentreConnectButtons],
  (data) => data,
);

export const getCentreStaffProfileCarouselSelector = createDraftSafeSelector(
  [getRawCentreStaffProfilesCarousel],
  ({ data, loading }) => ({ data, loading }),
);

export const getCentreStaffProfilesSelector = createDraftSafeSelector(
  [getRawCentreStaffProfiles],
  ({ data, loading, total, page }) => ({ data, loading, total, page }),
);

export const getCentreStaffProfileDetailSelector = createDraftSafeSelector(
  [getRawCentreStaffProfileDetail],
  ({ data, loading }) => ({ data, loading }),
);

export const getCentreProgramLearningsSelector = createDraftSafeSelector(
  [getRawCentreProgramLeanings],
  ({ data, loading, total, page }) => ({ data, loading, total, page }),
);

export const getCentreProgramCarouselSelector = createDraftSafeSelector(
  [getRawCentreProgramCarousel],
  ({ data, loading }) => ({ data, loading }),
);

export const getCentreProgramDetailSelector = createDraftSafeSelector(
  [getRawCentreProgramDetail],
  ({ data, loading }) => ({ data, loading }),
);
