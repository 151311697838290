import { refreshTokenApi } from 'api/auth';
import { getRefreshToken } from 'utils';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const logoutAction = () => {
  typeof window !== 'undefined' &&
    window.dispatchEvent(new Event('refresh-token-fail'));
};

export const handleExpiredAndRefreshToken = async ({
  request,
  originalRequest,
}) => {
  try {
    if (!getRefreshToken()) {
      await logoutAction();
      return false;
    }

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({ resolve, reject });
      })
        .then((token) => {
          // eslint-disable-next-line no-param-reassign
          originalRequest.headers.authorization = `Bearer ${token}`;
          // eslint-disable-next-line no-param-reassign
          request.defaults.headers.common.authorization = `Bearer ${token}`;
          return request(originalRequest);
        })
        .catch((err) => Promise.reject(err));
    }

    isRefreshing = true;
    const response = await refreshTokenApi();
    isRefreshing = false;

    if (response?.token) {
      // eslint-disable-next-line no-param-reassign
      originalRequest.headers.authorization = `Bearer ${response.token}`;
      // eslint-disable-next-line no-param-reassign
      request.defaults.headers.common.authorization = `Bearer ${response.token}`;
      processQueue(null, response.token);
      return request(originalRequest);
    }

    processQueue({}, null);
    await logoutAction();
    return false;
  } catch (error) {
    return Promise.reject(error);
  }
};
