import { getCurrentUserSelector } from '@redux/auth/selectors';
import { UserCircleIcon } from '@uikit/commons/SVGIcons';
import ProfileInfoForm from 'components/applyApplication/ProfileInfoForm';
import { useSelector } from 'react-redux';
import ContentSteps from './ContentSteps';

const Step1 = () => {
  const currentUser = useSelector(getCurrentUserSelector);

  return (
    <ContentSteps
      icon={<UserCircleIcon />}
      title="applyApplications.step1.title"
      required
    >
      <ProfileInfoForm isParentInfo initialValues={currentUser} />
    </ContentSteps>
  );
};

export default Step1;
