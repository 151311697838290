import styled from 'styled-components';

const LoadingStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1999;

  .loading-image-wrapper {
    padding: 20px;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
    background: #fff;
  }
  img {
    width: 70px;
    height: 70px;
    object-fit: cover;
  }
  .loading-player {
    border-radius: 20px;
    width: 96px;
    height: 96px;
    background: #fff;
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.5));
  }
`;

export default LoadingStyles;
