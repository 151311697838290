import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { richWebConfigVer2 } from 'utils';

export const jobMatchesApi = rtkCreator(
  'jobMatches',
  'job-seekers/screens/posts/suggestion',
  richWebConfigVer2,
);

export const { useGetAllJobMatchesQuery } = jobMatchesApi;
