import { useState } from 'react';

export const useMultipleSelect = (defaultValues = []) => {
  const [selectedValues, setSelectedValues] = useState(defaultValues);

  function handleSelect(value) {
    if (selectedValues.includes(value)) {
      setSelectedValues(selectedValues.filter((item) => item !== value));
    } else {
      setSelectedValues([...selectedValues, value]);
    }
  }

  const resetSelectBySpecificValues = (values = []) => {
    setSelectedValues(values);
  };

  return {
    selectedValues,
    handleSelect,
    resetSelectBySpecificValues,
  };
};
