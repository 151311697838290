import styled from 'styled-components';

export const DotCircleContainer = styled.div`
  display: inline-flex;
  margin-left: ${({ spaceSize }) => spaceSize || 8}px;
  margin-right: ${({ spaceSize }) => spaceSize || 8}px;
  align-items: ${({ position }) =>
    position === 'middle' ? 'center' : 'flex-end'};
`;

export const Circle = styled.div`
  width: ${({ size }) => size || 4}px;
  height: ${({ size }) => size || 4}px;
  border-radius: 50%;
  background-color: ${({ color }) => color || 'var(--color-grey-l2)'};
`;
