import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { formatDateOfBirthSubmit } from 'utils/dataUtils';
import { updateCurrentUser } from '@redux/auth/actions';
import { setDataApply } from '@redux/applyApplicationStep/slice';

const Step2Footer = ({ form, onNextStep }) => {
  const { t } = useTranslation();
  const loading = useSelector((state) => state.auth.loadingUser);
  const dispatch = useDispatch();

  const onSkip = () => {
    dispatch(
      setDataApply({
        additionalParentInfo: undefined,
      }),
    );
    onNextStep();
  };

  const onSubmit = () => {
    form
      .validateFields()
      .then(async (values) => {
        const dataSubmit = {
          additionalParentInfo: {
            ...values,
            dateOfBirth: formatDateOfBirthSubmit(values?.dateOfBirth),
          },
        };
        await dispatch(
          updateCurrentUser({
            data: dataSubmit,
            options: {
              isShowSuccess: false,
            },
          }),
        );
        onNextStep();
        dispatch(setDataApply(dataSubmit));
      })
      .catch(() => null);
  };

  return (
    <div className="flex-center-between">
      <Button onClick={onSkip} className="btn-line-primary">
        {t('button.skip')}
      </Button>
      <Button type="primary" onClick={onSubmit} loading={loading}>
        {t('applyApplications.nextStep')}
      </Button>
    </div>
  );
};

Step2Footer.propTypes = {
  form: PropTypes.object,
  onNextStep: PropTypes.func,
};

export default Step2Footer;
