import styled from 'styled-components';

export const FooterStyles = styled.div`
  padding: 32px 0 60px 0;
  border-top: 1px solid var(--border-color-light);
  .mainFooter {
    background: var(--bg-footer);
    color: var(--text-footer);
    transition: all 0.5s linear;
  }

  .ant-layout-footer {
    padding: 0 !important;
  }

  .footer-logo {
    height: 35px;
  }

  .footer-divider {
    background: rgba(255, 255, 255, 0.1);
    margin-bottom: 0px;
    margin-top: 110px;
  }

  a {
    color: var(--text-footer);
    font-size: 14px;
  }

  a:hover,
  a:focus {
    color: var(--text-footer) !important;
  }

  .footer-social-icon {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #39a4fc;
    border-radius: 50%;
    .anticon {
      color: #39a4fc;
      font-size: 14px;
    }

    &:hover {
      background: #39a4fc;
      .anticon {
        color: #fff;
        font-size: 14px;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .footer-menu-col {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  @media only screen and (max-width: 768px) {
    .mainFooter {
      .col-footer-left {
        text-align: center;
        img {
          margin: auto;
        }
      }
    }
  }
  @media only screen and (max-width: 576px) {
    .ant-layout-footer {
      margin-bottom: 30px;
    }
  }
`;
