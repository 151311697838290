import { createSlice } from '@reduxjs/toolkit';
import { INITIAL_STATE_CUSTOM } from '@redux/crudCreator/utils';
import { getCentreServicesSummaries } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { reducer } = createSlice({
  name: 'centreServices',
  initialState,
  extraReducers: {
    [getCentreServicesSummaries.fulfilled]: (state, { payload }) => {
      state.summaries = payload;
    },
  },
});

export default reducer;
