import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { setModalConfig } from '@redux/config/actions';
import { setCurrentLocation } from '@redux/config/slice';
import { LOCATION_DEFAULT } from 'configs/constants';
import axios from 'axios';
import { trackingEvent } from '@redux/eventTracking/actions';
import { EVENT_TRACKING } from 'configs/eventTracking';
import NextImage from '@uikit/commons/NextImage';
import RequestLocationModalStyles from './styles';

const RequestLocationModal = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const visible = useSelector(
    (state) => state.config.modals?.requestLocationModal,
  );

  const onCancel = () => {
    dispatch(setModalConfig({ requestLocationModal: false }));
  };

  const onCancelRequestLocation = () => {
    onCancel();
    dispatch(setCurrentLocation(LOCATION_DEFAULT));
  };

  const onAllowRequestLocation = () => {
    onCancel();
    navigator.geolocation.getCurrentPosition(
      (position) => {
        axios(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.NEXT_PUBLIC_KEY_GOOGLE_MAP}`,
        ).then((res) => {
          dispatch(
            setCurrentLocation({
              formattedAddress: res.data?.results?.[0]?.formatted_address,
              geoLocation: {
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
              },
            }),
          );
          dispatch(
            trackingEvent({
              eventName: EVENT_TRACKING.locationAccepted,
              eventData: {
                geolocation: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                },
              },
            }),
          );
        });
      },
      () => {
        dispatch(setCurrentLocation(LOCATION_DEFAULT));
      },
    );
  };

  return (
    <RequestLocationModalStyles
      footer={null}
      visible={visible}
      onCancel={onCancel}
      width={500}
    >
      <NextImage
        className="request-location__img"
        src="/images/request-location.png"
        alt="kindicare"
        width={1200}
        height={714}
      />
      <div className="request-location__content">
        <div className="request-location__title">
          {t('requestLocation.title')}
        </div>

        <div className="request-location__desc">
          {t('requestLocation.description')}
        </div>

        <div className="request-location__group-btn">
          <Button
            size="large"
            className="btn-line-primary"
            onClick={onCancelRequestLocation}
          >
            {t('button.cancel')}
          </Button>
          <Button size="large" type="primary" onClick={onAllowRequestLocation}>
            {t('button.allow')}
          </Button>
        </div>
      </div>
    </RequestLocationModalStyles>
  );
};

export default RequestLocationModal;
