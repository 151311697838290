import styled from 'styled-components';

export const RequiredDaysStyles = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 600;
  .ant-space {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .avatar-selected {
    background: var(--primary);
    color: #fff;
  }
  .avatar-none-select {
    background: #fff;
    color: var(--primary);
    border: 1px solid var(--primary);
  }
  .ant-avatar-string {
    font-size: 12px;
  }

  &.required-day-disabled {
    .ant-space-item {
      cursor: not-allowed;
    }
    .ant-avatar {
      pointer-events: none;
    }
  }
  @media only screen and (max-width: 576px) {
    .ant-space {
      gap: 4px !important;
    }

    .ant-avatar {
      width: 28px !important;
      height: 28px !important;
      line-height: 28px !important;
      display: flex;
      align-items: center;
    }
    .ant-avatar-string {
      font-size: 11px !important;
    }
  }
`;
