import { createSlice } from '@reduxjs/toolkit';
import {
  getStatisticsCentres,
  getStatisticsCentreServices,
  getStatisticsRatings,
} from './actions';

export const initialState = {
  ratings: {},
  centres: {},
  centreServices: {},
};

const { reducer } = createSlice({
  name: 'statistics',
  initialState,
  reducers: {},
  extraReducers: {
    [getStatisticsRatings.pending]: (state) => {
      state.ratings.loading = true;
      state.ratings.data = null;
    },
    [getStatisticsRatings.fulfilled]: (state, { payload }) => {
      state.ratings.data = payload;
      state.ratings.loading = false;
    },
    [getStatisticsRatings.rejected]: (state) => {
      state.ratings.loading = false;
    },

    [getStatisticsCentres.pending]: (state) => {
      state.centres.loading = true;
      state.ratings.data = null;
    },
    [getStatisticsCentres.fulfilled]: (state, { payload }) => {
      state.centres.data = payload;
      state.centres.loading = false;
    },
    [getStatisticsCentres.rejected]: (state) => {
      state.centres.loading = false;
    },

    [getStatisticsCentreServices.pending]: (state) => {
      state.centreServices.loading = true;
      state.ratings.data = null;
    },
    [getStatisticsCentreServices.fulfilled]: (state, { payload }) => {
      state.centreServices.data = payload;
      state.centreServices.loading = false;
    },
    [getStatisticsCentreServices.rejected]: (state) => {
      state.centreServices.loading = false;
    },
  },
});

export default reducer;
