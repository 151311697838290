import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { REQUEST_TYPE } from '@redux/crudCreator/dataProvider';
import { richWebConfig } from 'utils';

export const centresFeaturedApi = rtkCreator(
  'centresFeatured',
  'centres/marketings',
  richWebConfig,
  (builder) => ({
    getAllCentresFeaturedRandom: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_ALL,
        customApiResource: 'centres/marketings/random',
      }),
    }),
    getAllCentresFeaturedComparison: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_ALL,
        customApiResource: 'centres/marketings/comparison',
      }),
    }),
  }),
);

export const {
  useGetAllCentresFeaturedQuery,
  useGetAllCentresFeaturedRandomQuery,
  useGetAllCentresFeaturedComparisonQuery,
} = centresFeaturedApi;
