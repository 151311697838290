import { APPLY_STATUS } from 'components/jobs/constants';
import { DEFAULT_LIMIT } from 'configs/constants';
import {
  JOB_REQUIREMENT_TYPES,
  JOB_SUGGESTION_TYPES,
  SEARCH_BY_KEYWORD,
} from 'configs/localData';
import { compact, last, isEmpty, omit, upperCase } from 'lodash-es';
import { getQueryParamsFromUrl, isMobile } from 'utils/tools';
import qs from 'qs';
import {
  CARD_TYPES,
  CAROUSEL_TYPES,
  CAROUSELS_VIEW_ALL,
  LARGE_CARD_CAROUSEL_SETTINGS,
  MEDIUM_CARD_CAROUSEL_SETTINGS,
  SMALL_CARD_CAROUSEL_SETTINGS,
  JOB_CATEGORIES,
  FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH,
  EMPTY_SLUG_CHARACTER,
  IGNORE_JOB_SEARCH_PARAMS,
} from './constants';

export const getCarouselConfig = (section) => {
  const carouselType =
    section?.content?.items?.[0]?._typename || section?._typename;

  switch (carouselType) {
    case CARD_TYPES.JOB_CARD:
      return {
        carouselType: CAROUSEL_TYPES.JOB_CAROUSEL,
        settings: LARGE_CARD_CAROUSEL_SETTINGS,
      };
    case CARD_TYPES.FEATURED_BUSINESS_CARD:
      return {
        carouselType: CAROUSEL_TYPES.FEATURED_BUSINESS_CAROUSEL,
        settings: SMALL_CARD_CAROUSEL_SETTINGS,
      };
    case CARD_TYPES.ARTICLE_CARD:
      return {
        carouselType: CAROUSEL_TYPES.ARTICLE_CAROUSEL,
        settings: LARGE_CARD_CAROUSEL_SETTINGS,
      };
    case CARD_TYPES.JOB_MATCH_CARD:
      return {
        carouselType: CAROUSEL_TYPES.JOB_MATCH_CAROUSEL,
        settings: SMALL_CARD_CAROUSEL_SETTINGS,
      };

    case CARD_TYPES.ORGANIZATION_DISCOUNT_CARD:
      return {
        carouselType: CAROUSEL_TYPES.ORGANIZATION_DISCOUNT_CARD_CAROUSEL,
        settings: MEDIUM_CARD_CAROUSEL_SETTINGS,
      };
    case CARD_TYPES.ADS_CARD:
      return {
        carouselType: CAROUSEL_TYPES.ADS_CAROUSEL,
        settings: LARGE_CARD_CAROUSEL_SETTINGS,
      };
    default:
      return {};
  }
};

export const getUrlPathByTitleCarousel = (titleCarousel, viewAllParams) => {
  const {
    JOB_MATCH_SUGGESTIONS,
    EARLY_LEARNING_JOBS_NEARBY,
    NEWEST_EARLY_LEARNING_JOBS,
    FEATURED_EARLY_LEARNING_JOBS,
    GREAT_OFFERS,
    EDUCATOR_AND_STAFF_DISCOUNT,
    FEATURED_BRANDS,
    FEATURED_COMPANIES,
    CAREER_ADVICE_AND_RESOURCES,
    SCHOLARSHIPS_AND_INCENTIVES,
    HINTS_TIPS_STORIES,
    EDUCATOR_STORIES,
    FAVORITE_JOBS,
    RECENTLY_VIEWED,
  } = CAROUSELS_VIEW_ALL;

  const greatOffersUrlPath = `${GREAT_OFFERS.URL_PATH}?${qs.stringify(
    {
      adsIds: viewAllParams?.adsIds,
      sessionId: viewAllParams?.sessionId,
      userId: viewAllParams?.userId,
      userPreviousStep: viewAllParams?.userPreviousStep,
    },
    { indices: false },
  )}`;

  let urlPath = FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH;

  switch (titleCarousel) {
    case JOB_MATCH_SUGGESTIONS.TITLE:
      urlPath = JOB_MATCH_SUGGESTIONS.URL_PATH;
      break;
    case EARLY_LEARNING_JOBS_NEARBY.TITLE:
      urlPath = EARLY_LEARNING_JOBS_NEARBY.URL_PATH;
      break;
    case NEWEST_EARLY_LEARNING_JOBS.TITLE:
      urlPath = NEWEST_EARLY_LEARNING_JOBS.URL_PATH;
      break;
    case FEATURED_EARLY_LEARNING_JOBS.TITLE:
      urlPath = FEATURED_EARLY_LEARNING_JOBS.URL_PATH;
      break;
    case GREAT_OFFERS.TITLE:
      urlPath = greatOffersUrlPath;
      break;
    case EDUCATOR_AND_STAFF_DISCOUNT.TITLE:
      urlPath = EDUCATOR_AND_STAFF_DISCOUNT.URL_PATH;
      break;
    case FEATURED_BRANDS.TITLE:
      urlPath = FEATURED_BRANDS.URL_PATH;
      break;
    case FEATURED_COMPANIES.TITLE:
      urlPath = FEATURED_COMPANIES.URL_PATH;
      break;
    case CAREER_ADVICE_AND_RESOURCES.TITLE:
      urlPath = CAREER_ADVICE_AND_RESOURCES.URL_PATH;
      break;
    case SCHOLARSHIPS_AND_INCENTIVES.TITLE:
      urlPath = SCHOLARSHIPS_AND_INCENTIVES.URL_PATH;
      break;
    case HINTS_TIPS_STORIES.TITLE:
      urlPath = HINTS_TIPS_STORIES.URL_PATH;
      break;
    case EDUCATOR_STORIES.TITLE:
      urlPath = EDUCATOR_STORIES.URL_PATH;
      break;
    case FAVORITE_JOBS.TITLE:
      urlPath = FAVORITE_JOBS.URL_PATH;
      break;
    case RECENTLY_VIEWED.TITLE:
      urlPath = RECENTLY_VIEWED.URL_PATH;
      break;
    default:
  }
  return urlPath;
};

export const checkJobCentreLevel = (organization) => !!organization?.centre;

export const checkJobCompanyLevel = (organization) => !!organization?.company;

export const getJobCategoryByPathname = (pathname) => {
  const categoryUrl = pathname.split('/')?.[1];

  return JOB_CATEGORIES.find((item) => item.url === categoryUrl);
};

export const getIdFromSlug = (slug) => {
  if (!slug || slug === EMPTY_SLUG_CHARACTER) {
    return null;
  }
  return last(slug.split('-'));
};

export const transformJobSearchQuery = ({ searchType, query = {} }) => {
  switch (searchType) {
    case JOB_SUGGESTION_TYPES.JOBS_TITLE:
      return {
        jobTitle: query.title,
      };

    case JOB_SUGGESTION_TYPES.JOB_ROLES:
      return {
        jobRoles: [getIdFromSlug(query.jobRole)],
      };

    case JOB_SUGGESTION_TYPES.LOCATIONS:
      return {
        location: {
          state: query.state,
          postCode: query.postcode,
          suburb: upperCase(query.suburb),
        },
      };

    case JOB_SUGGESTION_TYPES.POSTCODE:
      return {
        postCode: query.postcode,
      };

    case JOB_SUGGESTION_TYPES.COMPANIES:
      return {
        companies: [getIdFromSlug(query.company)],
      };

    case JOB_SUGGESTION_TYPES.BRANDS:
      return {
        brands: [getIdFromSlug(query.brand)],
      };

    case JOB_SUGGESTION_TYPES.CENTRES:
      return {
        companies: compact([getIdFromSlug(query.company)]),
        organizations: compact([getIdFromSlug(query.centre)]),
      };

    case SEARCH_BY_KEYWORD:
      return {
        keySearch: query.q,
      };

    default:
      return null;
  }
};

export const formatGetJobsData = ({
  query = {},
  searchType,
  orderByDefault,
  location,
  isMapView,
}) => {
  const parsedQuery = getQueryParamsFromUrl(query);
  const searchQuery = transformJobSearchQuery({ searchType, query });

  const filterParams = {
    ...parsedQuery?.filter,
    ...parsedQuery?.searchFilter,
    ...searchQuery,
  };

  const queryParams = {
    size: query.size || DEFAULT_LIMIT,
    page: query.page || 1,
    location,
    ...omit(parsedQuery, IGNORE_JOB_SEARCH_PARAMS),
    filter: isEmpty(filterParams) ? null : filterParams,
    sorts: [query.sorts || orderByDefault],
  };

  if (isMapView) {
    delete queryParams.page;
    delete queryParams.size;
    queryParams.onlyMarker = true;
  }

  return queryParams;
};

export const directToMobileScreen = (pathname) => {
  if (isMobile()) {
    return `/m${pathname}`;
  }
  return pathname;
};

export const generateJobRequirementKey = (type, key) => `${type}_${key}`;

export const getFirstJobRequirementAllowEdit = ({
  isShowEditProfileTooltip,
  mandatoryRequirements,
  preferredRequirements,
  floatingFooterComponents,
} = {}) => {
  let isEligibleShowTooltip = true;
  let firstRequirementAllowEdit = null;
  const applyButton = floatingFooterComponents?.[0]?.text;

  if (
    !isShowEditProfileTooltip ||
    applyButton === APPLY_STATUS.oneClickApply ||
    applyButton === APPLY_STATUS.applied
  ) {
    isEligibleShowTooltip = false;
  }

  const mandatorySectionShowTooltip = Object.entries(
    mandatoryRequirements || {},
  )?.find(([, value]) => value?.isAllowEdit);

  const preferedSectionShowTooltip = Object.entries(
    preferredRequirements || {},
  )?.find(([, value]) => value?.isAllowEdit);

  if (mandatorySectionShowTooltip) {
    firstRequirementAllowEdit = generateJobRequirementKey(
      JOB_REQUIREMENT_TYPES.mandatory,
      mandatorySectionShowTooltip?.[0],
    );
  } else if (preferedSectionShowTooltip) {
    firstRequirementAllowEdit = generateJobRequirementKey(
      JOB_REQUIREMENT_TYPES.preferred,
      preferedSectionShowTooltip?.[0],
    );
  }

  return {
    firstRequirementAllowEdit,
    firstRequirementShowTooltip: isEligibleShowTooltip
      ? firstRequirementAllowEdit
      : null,
  };
};
