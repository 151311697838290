import ModalCustom from '@uikit/commons/ModalCustom';
import { Button, ModalProps } from 'antd';
import { useTranslation } from 'react-i18next';
import { TagStyle } from '@uikit/headers/DefaultHeader/styles';
import {
  BabyPrimaryFillIcon,
  BriefcaseFillIcon,
  ShopIcon,
} from '@uikit/commons/SVGIcons';
import { LOGIN_BY_ROLE } from 'configs/constants';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import React from 'react';
import { setModalConfig } from '@redux/config/actions';
import { setLoginByRole } from '@redux/auth/slice';

interface Props extends ModalProps {
  isMobile?: boolean;
  handleCancelDrawer?: () => void;
  setIsOpenLoginOptions?: (isOpenLoginOptions: boolean) => void;
}

const LoginOptionsModal = ({
  isMobile = false,
  handleCancelDrawer,
  setIsOpenLoginOptions,
  ...props
}: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClickLogin = (loginByRole) => {
    setIsOpenLoginOptions(false);
    dispatch(
      setModalConfig({
        loginModal: true,
      }),
    );
    dispatch(setLoginByRole(loginByRole));

    isMobile && handleCancelDrawer();
  };

  return (
    <ModalCustomStyled {...props} className="flex-center">
      <Button
        onClick={() => handleClickLogin(LOGIN_BY_ROLE.parent)}
        size="large"
        icon={<BabyPrimaryFillIcon />}
      >
        <span>{t('menu.asAParent')}</span>
      </Button>
      <Button size="large" icon={<ShopIcon />}>
        <a href={`${process.env.NEXT_PUBLIC_CRM_WEB_URL}/login`}>
          {t('menu.asAChildcareProvider')}
        </a>
      </Button>
      <Button
        size="large"
        icon={<BriefcaseFillIcon />}
        onClick={() => handleClickLogin(LOGIN_BY_ROLE.jobSeeker)}
      >
        {t('menu.asAJobSeeker')}
        <TagStyle className="ml-8 rounded-8 border-none size-10 fw-700 pt-2 py-6 pb-4">
          {t('common.new')}!
        </TagStyle>
      </Button>
    </ModalCustomStyled>
  );
};

export default LoginOptionsModal;

const ModalCustomStyled = styled(ModalCustom)`
  .ant-modal-content {
    border-radius: 18px;
  }

  .ant-btn {
    width: 100%;
    text-align: left;
    margin-bottom: 16px;
    font-size: 14px;
    font-weight: 700;
    border: 1px solid var(--border-color-light);
    position: relative;
    display: flex;
    align-items: center;
    padding: 30px 20px;

    ::after {
      content: '\u2303';
      position: absolute;
      right: 15px;
      transform: rotate(90deg) translate(0, 50%);
      display: inline-block;
      color: var(--text-gray-white);
      font-size: 20px;
      font-weight: normal;
    }

    a {
      margin-left: 8px;
    }
  }

  .ant-modal-header .ant-modal-title {
    font-size: 18px;
  }

  .ant-modal-header {
    border-radius: 18px;
  }
`;
