import { rtkCreator } from '@redux/crudCreator/baseRTK';
import { REQUEST_TYPE } from '@redux/crudCreator/dataProvider';
import { richWebConfig } from 'utils';

export const centresContactedApi = rtkCreator(
  'centresContacted',
  'centres/contacted',
  richWebConfig,
  (builder) => ({
    getAllCentresContactedComparison: builder.query({
      query: (payload = { data: {}, options: {} }) => ({
        ...payload,
        method: 'GET',
        type: REQUEST_TYPE.GET_ALL,
        customApiResource: 'centres/contacted/comparison',
      }),
    }),
  }),
);

export const {
  useGetAllCentresContactedQuery,
  useGetAllCentresContactedComparisonQuery,
} = centresContactedApi;
