import { css, CSSObject, FlattenSimpleInterpolation } from 'styled-components';

export interface Breakpoints {
  [key: string]: number;
}

export const breakpoints: Breakpoints = {
  xs: 576,
  sm: 768,
  md: 992,
  lg: 1200,
  xl: 1400,
  fhd: 1920,
  uhd: 2048,
  qhd: 3840,
};

export const respondTo: {
  [key in keyof Breakpoints]: (
    ...args: CSSObject[]
  ) => FlattenSimpleInterpolation;
} = Object.keys(breakpoints).reduce((accumulator, label) => {
  const result = { ...accumulator };
  result[label as keyof Breakpoints] = (...args: CSSObject[]) => css`
    @media (max-width: ${`${breakpoints[label]}px`}) {
      ${css(...args)};
    }
  `;
  return result;
}, {} as { [key in keyof Breakpoints]: (...args: CSSObject[]) => FlattenSimpleInterpolation });
