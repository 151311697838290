import { notification } from 'antd';
import { i18n } from 'next-i18next';

interface ApiWrapperOptions {
  isShowLoading?: boolean;
  isShowSuccess?: boolean;
  isCheckError?: boolean;
  successDescription?: string;
}

export async function apiWrapper(
  options: ApiWrapperOptions = {
    isShowLoading: true,
    isShowSuccess: false,
    isCheckError: false,
  },
  apiFunction,
  ...payload
) {
  try {
    const response = await apiFunction(...payload);
    if (options.isShowSuccess) {
      process.browser &&
        notification.success({
          message: i18n.t('success.title'),
          description:
            options.successDescription ||
            response?.data?.message ||
            i18n.t('success.description'),
        });
    }
    return response;
  } catch (error) {
    process.browser && notification.destroy();
    !options?.isCheckError &&
      process.browser &&
      error?.statusCode !== 401 &&
      notification.error({
        message: 'Oops!',
        description: error.message || error || 'some thing wrong!',
      });
    throw error;
  }
}
