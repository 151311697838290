import {
  getAllCustomFail,
  getAllCustomPending,
  INITIAL_STATE_CUSTOM,
  resetResourceDataCustom,
} from '@redux/crudCreator/utils';
import { createSlice } from '@reduxjs/toolkit';
import { unionBy, pick } from 'lodash-es';
import { getAllCentres } from './actions';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
  selectedCentres: [],
  totalSelectedCentres: 0,
  summary: null,
  error: null,
  centreCity: {},
};

const { actions, reducer } = createSlice({
  name: 'centres',
  initialState,
  reducers: {
    resetResourceFilterCentres: resetResourceDataCustom,
  },
  extraReducers: {
    [String(getAllCentres.pending)]: getAllCustomPending,
    [String(getAllCentres.fulfilled)]: (state, { payload: { data } }) => {
      state.data = unionBy([...state.data, ...(data.results || [])], 'id');
      state.summary = pick(data?.centreServicesSummaries?.cost, ['min', 'max']);
      if (data?.centreCity) {
        state.centreCity = data.centreCity;
      }
      state.resourceData.total = data.total || 0;
      state.resourceData.numberOfPages = data.numberOfPages;

      state.loading = false;
      state.error = null;
    },
    [String(getAllCentres.rejected)]: getAllCustomFail,
  },
});

export const { resetResourceFilterCentres } = actions;

export default reducer;
