import React, { useState } from 'react';
import { Avatar, Rate } from 'antd';
import { GoogleLogoIcon, KindiCareLogoIcon } from '@uikit/commons/SVGIcons';
import { formatDate, roundHalf } from 'utils/textUtils';
import DotCircle from '@uikit/commons/DotCircle';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import ToggleParagraph from '@uikit/commons/ToggleParagraph';
import { ReviewsItem } from 'types/parentReview';
import RepliesItem from './RepliesItem';
import { ReviewItemStyle } from './styles';

interface Props {
  item: ReviewsItem;
  isShowFullReview?: boolean;
  centreName?: string;
}

const ReviewItem = ({ item, isShowFullReview = true, centreName }: Props) => {
  const { t } = useTranslation();
  const avatarDefault = item?.authorName?.charAt(0).toUpperCase();

  const [isShowReplyReview, setIsShowReplyReview] = useState(false);

  const handleShowReply = () => {
    setIsShowReplyReview(!isShowReplyReview);
  };

  return (
    <ReviewItemStyle>
      <div className="d-flex items-center">
        <a href={item?.authorUrl?.url} target="_blank" rel="noreferrer">
          <Avatar src={item?.avatar?.url} size={48} alt="author-img">
            {avatarDefault}
          </Avatar>
        </a>
        <div className="ml-15 w-full">
          <div className="flex-center-between w-full">
            <a
              href={item?.authorUrl?.url}
              target="_blank"
              rel="noreferrer"
              className="size-14 fw-700 lh-24 ellipsis-1-t"
            >
              {item?.authorName}
            </a>
            <div className="size-20">
              {item?.isInternalRating ? (
                <KindiCareLogoIcon />
              ) : (
                <GoogleLogoIcon />
              )}
            </div>
          </div>

          <div className="d-flex items-center">
            <Rate allowHalf disabled value={roundHalf(item?.rating)} />
            <DotCircle color="var(--text-gray-neutral-l400)" size={2} />
            <span className="size-12 fw-700 text-grey-l1">
              {formatDate(item?.reviewedAt, 'DD MMM YYYY')}
            </span>
          </div>
        </div>
      </div>
      <div>
        {isShowFullReview ? (
          <>
            <div className="mt-8">{item?.comment ?? ''}</div>
            {!isEmpty(item?.replies) && (
              <>
                <div
                  className="d-flex items-center text-pink-primary mt-12"
                  onClick={handleShowReply}
                  role="presentation"
                >
                  {isShowReplyReview ? <CaretUpFilled /> : <CaretDownFilled />}
                  <div className="fw-bold">{`${item?.totalReplies} ${
                    Number(item?.totalReplies) === 1
                      ? t('text.reply')
                      : t('text.replies')
                  }`}</div>
                </div>
                {isShowReplyReview && (
                  <div className="border-left-light">
                    <div className="ml-16">
                      {item?.replies?.map((replyItem) => (
                        <RepliesItem
                          replyItem={replyItem}
                          centreName={centreName}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <ToggleParagraph
              content={item?.comment ?? ''}
              className="text-black-l8"
              rows={3}
            />
          </>
        )}
      </div>
    </ReviewItemStyle>
  );
};

export default ReviewItem;
