import { forwardRef, useEffect, useState } from 'react';
import { Button, Checkbox, Divider, Form, Input } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '@redux/auth/actions';
import ModalCustom from '@uikit/commons/ModalCustom';
import { setModalConfig } from '@redux/config/actions';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { getDeviceId } from 'utils/tools';
import { getUrlAfterLogin } from 'utils/dataUtils';
import AuthActions from '@uikit/modals/AuthActions';
import FormInput from '@uikit/form/FormInput';
import AuthModalStyles from '@uikit/modals/AuthModal/styles';

const LoginModal = forwardRef(() => {
  const { push, asPath } = useRouter();
  const loginByRole = useSelector((state) => state.auth.loginByRole);

  const modalVisible = useSelector((state) => state.config.modals);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const { fetchedUserMe } = useSelector((state) => state.configLocal);
  const [isFinished, setIsFinished] = useState(false);

  const dispatch = useDispatch();

  const onCancel = () => {
    dispatch(setModalConfig({ loginModal: false }));
    form.resetFields();
  };

  const onFinish = async (values) => {
    setLoading(true);
    const response = await dispatch(
      login({ ...values, deviceId: getDeviceId() }),
    );
    setLoading(false);

    if (response?.payload?.data?.id) {
      onCancel();
      setIsFinished(true);
    }
  };

  useEffect(() => {
    if (fetchedUserMe && isFinished) {
      const redirectUrl = getUrlAfterLogin(loginByRole, asPath);
      setIsFinished(false);
      if (redirectUrl) {
        push(redirectUrl);
      }
    }
  }, [fetchedUserMe, isFinished, push, loginByRole, asPath]);

  const registerNavigate = () => {
    onCancel();
    dispatch(setModalConfig({ signUpModal: true }));
  };

  const forgotPasswordNavigate = () => {
    onCancel();
    push('/forgot-password');
  };

  return (
    <ModalCustom
      visible={modalVisible.loginModal}
      onCancel={onCancel}
      footer={null}
    >
      <AuthModalStyles>
        <div className="auth__title">{t('auth.login')}</div>
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <FormInput
            name="email"
            label="input.email.label"
            placeholder="input.email.placeholder"
            required
            messageRequire="input.email.validateMsg.required"
            rules={[
              {
                type: 'email',
                message: t('input.email.validateMsg.invalid'),
              },
            ]}
          />
          <FormInput
            name="password"
            label="input.password.label"
            placeholder="input.password.placeholder"
            required
            messageRequire="input.password.validateMsg.required"
            ContentComponent={Input.Password}
          />
          <div className="flex-center-between size-l mt-20">
            <Checkbox
              defaultChecked
              className="auth__remember-me size-l checkbox-18"
            >
              {t('auth.rememberMe')}
            </Checkbox>
            <div
              className="pointer forgot-password text-highlight"
              onClick={forgotPasswordNavigate}
              role="presentation"
            >
              {t('auth.forgotPassword')}
              {'?'}
            </div>
          </div>

          <Button
            className="submit-btn"
            type="primary"
            htmlType="submit"
            loading={loading}
            size="large"
          >
            {t('button.login')}
          </Button>
          <Divider className="size-18">{t('common.or')}</Divider>

          <AuthActions onCancel={onCancel} />
          <div className="auth__question">
            {`${t('auth.questionRegister')} `}
            <b role="presentation" onClick={registerNavigate}>
              {t('auth.signUpNow')}
            </b>
          </div>
        </Form>
      </AuthModalStyles>
    </ModalCustom>
  );
});

export default LoginModal;
