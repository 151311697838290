import {
  getAllCustomFail,
  getAllCustomPending,
  getAllCustomSuccess,
  INITIAL_STATE_CUSTOM,
} from '@redux/crudCreator/utils';
import { createSlice } from '@reduxjs/toolkit';
import { getMessagesByInboxId, sendMessage } from './action';

export const initialState = {
  ...INITIAL_STATE_CUSTOM,
};

const { reducer } = createSlice({
  name: 'messages',
  initialState,
  reducers: {},
  extraReducers: {
    [getMessagesByInboxId.pending]: getAllCustomPending,
    [getMessagesByInboxId.fulfilled]: getAllCustomSuccess,
    [getMessagesByInboxId.rejected]: getAllCustomFail,

    [sendMessage.fulfilled]: (state, { payload }) => {
      state.data = [payload, ...state.data];
    },
  },
});

export default reducer;
