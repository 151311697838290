import { createDraftSafeSelector } from '@reduxjs/toolkit';

export class CRUDSelectorsCustom {
  resource: any;

  constructor(resource) {
    this.resource = resource;
  }

  getRestData = (state) => state[this.resource];

  getDataArr = createDraftSafeSelector([this.getRestData], (resources) => {
    const { data } = resources;
    return data;
  });

  getResourceData = createDraftSafeSelector([this.getRestData], (resources) => {
    const { resourceData } = resources;
    return resourceData;
  });

  getSummary = createDraftSafeSelector([this.getRestData], (resources) => {
    const { summary } = resources;
    return summary;
  });

  enabledLoadMore = createDraftSafeSelector(
    [this.getResourceData],
    (resources) => {
      const { offset, limit, loading, numberOfPages } = resources;
      return !loading && offset / limit + 1 < numberOfPages;
    },
  );

  getLoading = createDraftSafeSelector(
    [this.getRestData],
    (resources = { loading: false }) => {
      const { loading } = resources;
      return loading;
    },
  );

  getFilters = createDraftSafeSelector([this.getRestData], (resources) => {
    const { limit, offset, filter, total, orderBy, q, outsideFilter } =
      resources;
    return { limit, offset, filter, count: total, orderBy, q, outsideFilter };
  });
}

export const crudSelectorsCustom = (resource) =>
  new CRUDSelectorsCustom(resource);
