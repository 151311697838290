import styled, { css } from 'styled-components';

export const FeatureTitleIconStyles = styled.div`
  display: flex;
  align-items: center;
  font-size: 24px;

  .feature-title-icon {
    &__icon {
      color: var(--primary);
      margin-right: 16px;
      padding: 14px;
      border-radius: 50%;
      background: ${({ backgroundColor }) =>
        backgroundColor || 'var(--bg-pastel-pink)'};
    }
    &__name {
      font-weight: bold;
      line-height: 32px;
    }
  }

  @media only screen and (max-width: 576px) {
    font-size: 18px;
    line-height: 28px;
  }

  ${({ isShowEmbed }) =>
    isShowEmbed &&
    css`
  .feature-title-icon {
    &__icon {
      margin-right: 12px;
      padding: 10px;
      font-size:14px;
    }
    &__name {
      line-height: 22px;
      font-size: 17px;
    }
  `}
`;
