import styled from 'styled-components';

export const ApplicationStepsStyles = styled.div`
  width: 100%;

  .ant-steps {
    max-width: 560px;
    margin: 0 auto;

    .ant-steps-item-tail::after {
      height: 2px;
    }

    .ant-steps-item-process
      > .ant-steps-item-container
      > .ant-steps-item-tail::before {
      display: inline-block;
      width: 35%;
      height: 2px;
      background: var(--primary);
      border-radius: 1px;
      transition: background 0.3s;
      position: absolute;
      content: '';
    }
    .ant-steps-item-wait {
      .ant-steps-item-icon {
        background: #cbd2d9;
        border: none;
      }
      .ant-steps-icon {
        color: #fff;
        font-weight: 400;
      }

      .ant-steps-item-title {
        color: #cbd2d9;
        font-weight: 400;
      }
    }
  }

  .steps-content {
    width: 100%;
    padding-top: 40px;
  }

  @media only screen and (max-width: 576px) {
    .ant-steps {
      flex-direction: row;
    }

    .ant-steps-item-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .ant-steps-item-title {
      font-size: 14px;
    }

    .ant-steps-item-tail::after {
      display: none;
    }

    .steps-content {
      padding-top: 10px;
    }

    .step-3-wrapper {
      .form-list__list-item {
        padding: 0 !important;
        margin-bottom: 5px;
      }

      .child-info__name-wrapper span {
        font-size: 16px;
      }
    }
  }
`;
