import { apiWrapper } from '@redux/reduxUtils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getJobApplicationCardInfoApi } from 'api/jobApplication';

export const MODEL_NAME = 'jobApplication';

export const getJobApplicationCardInfo = createAsyncThunk(
  `${MODEL_NAME}/getJobApplicationCardInfo`,
  async (payload = {}, thunkAPI) => {
    try {
      const response = await apiWrapper(
        {},
        getJobApplicationCardInfoApi,
        payload,
      );
      return response;
    } catch (error) {
      return thunkAPI.rejectWithValue({ id: payload, error });
    }
  },
);
