import { List, Rate, Skeleton, Row, Col, Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getCentreReviewsSelector } from '@redux/centreDetails/selectors';
import { getCentreDetailsReviews } from '@redux/centreDetails/actions';
import { roundHalf } from 'utils/textUtils';
import { formatRatingNumber } from 'utils/tools';
import RequiredAuthButton from 'components/rest/RequiredAuthButton';
import { PencilOutlineIcon } from '@uikit/commons/SVGIcons';
import { useRouter } from 'next/router';
import useWindowSize from 'hooks/useWindowSize';
import { useMultipleSelect } from 'hooks/useMultipleSelect';
import { isEmpty } from 'lodash-es';
import ReviewItem from '@uikit/items/ReviewItem';
import styled from 'styled-components';
import { ReviewSummaries, ReviewsItem } from 'types/parentReview';
import WriteReviewModal from './WriteReviewModal';
import ParentReviews from './mobile/ParentReviews';
import FilterReviews from './ParentReviews/FilterReviews';
import ViewAllReviews from './mobile/ParentReviews/ViewAllReviews';
import { renderEmptyReviewDescription } from './utils';

export const ReviewSectionStyles = styled.div`
  .rating-summary-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 30px;
    padding: 18px 25px;
    background: rgba(255, 240, 251, 0.4);
    border: 1px solid var(--light-primary);
    border-radius: 12px;
  }

  .review-title {
    margin-bottom: 0;
  }

  .rating-summary {
    color: var(--primary);
  }

  .ant-radio-button-wrapper {
    border-radius: 8px !important;
    font-size: 14px;
  }

  .ant-radio-button-wrapper-checked {
    background-color: var(--bg-pastel-pink) !important;
  }

  .ant-rate-star {
    font-size: 18px;
  }

  .ant-rate {
    display: flex;
    align-items: center;
  }

  .ant-empty {
    margin: 0;
  }

  .btn-write-review {
    color: var(--text-secondary);
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    border-radius: 100px;
    border-color: var(--border-color-light);
    padding: 10px 16px;
    display: flex;
    align-items: center;
  }

  .centre__review-pagination {
    .ant-list-pagination {
      margin-top: 6px;
    }

    .ant-pagination {
      text-align: center;
    }

    .ant-pagination-item {
      border: none;
      min-width: 36px;
      height: 36px;
      line-height: 36px;

      &-active {
        border-radius: 50%;
        background-color: var(--primary);
        a {
          color: #fff !important;
        }
      }

      a {
        color: var(--primary);
        font-size: 16px;
      }

      &-link {
        color: var(--primary);
        border: none;
        font-size: 14px;
      }
    }

    .ant-pagination-disabled {
      display: none;
    }
  }

  .empty-reviews {
    img {
      max-width: 100px;
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 32px;
      text-align: center;
      letter-spacing: 0.02em;
      color: var(--text-secondary);
      max-width: 550px;
    }
  }

  @media only screen and (max-width: 1300px) {
    .rating-summary-wrapper {
      flex-direction: column;
      padding: 10px 12px 20px;
    }
    .ant-radio-group {
      margin-left: 0;
      padding: 0;
    }
  }
`;

interface Props {
  centreId?: string;
  reviewSummaries?: ReviewSummaries;
  reviewRef: any;
  isHasHeader?: boolean;
  title?: string;
  centreName?: string;
  isJobSeeker?: boolean;
}

const ReviewSection = ({
  centreId,
  reviewSummaries,
  reviewRef,
  isHasHeader = true,
  title = 'centres.detail.parentReviews',
  centreName,
  isJobSeeker = false,
}: Props) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [filter, setFilter] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showViewAllReviewModal, setShowViewAllReviewModal] = useState(false);

  const { t } = useTranslation();
  const { data, total, loading } = useSelector(getCentreReviewsSelector);
  const { isMobile } = useWindowSize();

  const { handleSelect, selectedValues, resetSelectBySpecificValues } =
    useMultipleSelect();

  const handleFilterChange = (rating) => {
    setPage(1);
    handleSelect(rating);
  };

  const checkIsSelected = (value) => selectedValues.includes(value);

  const emptyDescriptionValue = renderEmptyReviewDescription(
    selectedValues,
    isJobSeeker,
  );

  const summaries = [5, 4, 3, 2, 1].map((rating) => ({
    rating,
    count:
      reviewSummaries?.statistics?.find((item) => item.rating === rating)
        ?.count || 0,
  }));

  const onPageChange = (pageNumber) => {
    setPage(pageNumber);
    reviewRef?.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    dispatch(
      getCentreDetailsReviews({
        id: centreId,
        params: {
          offset: (page - 1) * limit,
          limit,
          filter: JSON.stringify(
            isEmpty(selectedValues) ? undefined : { rating: selectedValues },
          ),
          orderBy: '-createdAt',
        },
      }),
    );
  }, [dispatch, centreId, page, limit, filter, selectedValues]);

  useEffect(() => {
    const handleRouteChange = () => {
      setFilter(0);
      setPage(1);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router]);

  const handleShowReview = () => {
    setShowReviewModal(true);
  };

  const handleShowViewAllReview = (id) => {
    setShowViewAllReviewModal(true);
    dispatch(
      getCentreDetailsReviews({
        id: centreId,
        params: {
          offset: 0,
          limit: 20,
          filter: JSON.stringify(
            isEmpty(selectedValues) ? undefined : { rating: selectedValues },
          ),
          orderBy: '-createdAt',
        },
      }),
    );
    if (id) {
      setTimeout(() => {
        const el = document.getElementById(`${id}`);
        el?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        });
      }, 500);
    }
  };

  const onCloseViewAllModal = () => {
    setPage(1);
    setLimit(10);
    setShowViewAllReviewModal(false);
    resetSelectBySpecificValues();
  };

  return (
    <ReviewSectionStyles ref={reviewRef}>
      {isHasHeader && (
        <div className="flex-center-between">
          <h2 className="centre-section-title review-title">{t(title)}</h2>
          {isMobile ? (
            !isEmpty(data) && (
              <div
                className="fw-700 text-pink-primary"
                onClick={() => handleShowViewAllReview(null)}
                role="presentation"
              >
                {t('button.viewAll')}
              </div>
            )
          ) : (
            <RequiredAuthButton
              onClick={handleShowReview}
              icon={<PencilOutlineIcon />}
              className="btn-write-review"
            >
              {t('button.writeReview')}
            </RequiredAuthButton>
          )}
        </div>
      )}
      {isMobile ? (
        <>
          <ParentReviews
            summaries={summaries}
            reviewSummaries={reviewSummaries}
            data={data}
            handleShowViewAllReview={handleShowViewAllReview}
            handleShowReview={handleShowReview}
            isJobSeeker={isJobSeeker}
          />
        </>
      ) : (
        <>
          {(!isEmpty(data) || !isEmpty(selectedValues)) && (
            <Row gutter={[24, 12]} className="rating-summary-wrapper">
              <Col>
                <div className="rating-summary text-center">
                  <span className="fw-700 size-28 rating-star">
                    {formatRatingNumber(reviewSummaries?.avgStars)}
                  </span>
                  <span className="ml-5">/ 5</span>
                </div>
                <Rate
                  allowHalf
                  disabled
                  value={roundHalf(reviewSummaries?.avgStars)}
                />
              </Col>
              <Col>
                <FilterReviews
                  summaries={summaries}
                  handleFilterChange={handleFilterChange}
                  checkIsSelected={checkIsSelected}
                />
              </Col>
            </Row>
          )}
          <div className="mt-30">
            <List
              grid={{ gutter: 16, column: 1 }}
              itemLayout="horizontal"
              className="centre__review-pagination"
              dataSource={data || []}
              locale={{
                emptyText: (
                  <Empty
                    image="/images/empty-review.png"
                    description={t(emptyDescriptionValue)}
                    className="text-grey-l1 size-16 flex-center mt-20 flex-column"
                  />
                ),
              }}
              pagination={{
                total,
                current: page,
                onChange: onPageChange,
                showSizeChanger: false,
                hideOnSinglePage: true,
              }}
              renderItem={(item: ReviewsItem) => (
                <List.Item>
                  <Skeleton active avatar loading={loading}>
                    <ReviewItem item={item} centreName={centreName} />
                  </Skeleton>
                </List.Item>
              )}
            />
          </div>
        </>
      )}
      <WriteReviewModal
        visible={showReviewModal}
        onCancel={() => setShowReviewModal(false)}
      />
      <ViewAllReviews
        visible={showViewAllReviewModal}
        onCancel={onCloseViewAllModal}
        summaries={summaries}
        reviewSummaries={reviewSummaries}
        handleFilterChange={handleFilterChange}
        checkIsSelected={checkIsSelected}
        page={page}
        setPage={setPage}
        handleShowReview={handleShowReview}
        setLimit={setLimit}
        centreName={centreName}
        selectedValues={selectedValues}
        isJobSeeker={isJobSeeker}
      />
    </ReviewSectionStyles>
  );
};

export default ReviewSection;
