import { isEmpty } from 'lodash-es';

export const renderEmptyReviewDescription = (selectedValues, isJobSeeker) => {
  if (isEmpty(selectedValues)) {
    if (isJobSeeker) {
      return 'reviews.emptyReviewJobSeeker';
    }
    return 'reviews.emptyReview';
  }
  return 'reviews.emptyFilterReview';
};
