import { setFetchedConfig } from '@redux/configLocal/slice';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getConfigApi } from 'api/config';

export const getConfig = createAsyncThunk(
  'config/getConfig',
  async (_, thunkAPI) => {
    try {
      const data = await getConfigApi();
      thunkAPI.dispatch(setFetchedConfig(true));
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const setModalConfig = createAsyncThunk<
  { loginModal: boolean },
  { loginModal: boolean }
>('config/setModalConfig', async (payload, thunkAPI) => {
  try {
    return payload;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});
