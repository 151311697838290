import { createGlobalStyle } from 'styled-components';
import { CSS_COMMON, CSS_RESET } from '@uikit/css';

const GlobalStyle = createGlobalStyle`
  ${CSS_RESET}
  ${CSS_COMMON}
  html {
    scroll-behavior: smooth;
  }

  body {
    font-family: var(--font-family);
    margin: 0;
    padding: 0;
    background-color: #fff;
    scroll-behavior: smooth;
  }

  /* styles wrapper container */
  .wrapper-container {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1220px;
    position: relative;
    width: 90%;
  }

  .container {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1220px;
    position: relative;
    padding: 0 24px 0 24px !important;
  }

  .wrapper-container-large {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1600px;
    position: relative;
    width: 90%;
  }

  .wrapper-container-search-filter {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1240px;
    position: relative;
    width: 90%;
  }

  .wrapper-container-small {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1012px;
    position: relative;
    width: 90%;
  }

  .wrapper-container-centres {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1060px;
    position: relative;
    width: 90%;
  }

  .wrapper-container-make-enquiry {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1077px;
    position: relative;
    width: 90%;
  }

  .content-wrapper-container {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1500px;
    position: relative;
    width: 90%;
  }

  .detail-wrapper-container {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 994px;
    position: relative;
    width: 90%;
  }

  .margin-section {
    margin: 18px 0 70px;
  }

  .landing-padding {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .landing-divider {
    margin: 40px 0 !important;
  }

  .private-container-padding {
    padding-top: 32px;
    padding-bottom: 60px;
  }

  /** ---------------- */

  .d-desktop {
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }

  .d-mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .d-desktop-flex-center-between {
    display: flex;
    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  .d-desktop-auto {
    @media (min-width: 768px) {
      width: auto;
      height: auto;
    }
  }

  .radio-group {
    width: 100%;

    .radio {
      display: flex;
      align-items: center;
      flex-flow: row-reverse;
      justify-content: space-between;
    }
  }

  .note {
    font-size: 12px;
    color: var(--text-note);
  }

  .description {
    font-size: 14px;
    color: var(--text-description);
  }

  .text-green {
    color: var(--text-green);
  }

  .text-pastel-pink-l200 {
    color: var(--color-pastel-pink-l200);
  }

  .text-gray-neutral {
    color: var(--color-gray-neutral);
  }

  .text-secondary {
    color: var(--text-secondary);
  }

  .text-gray {
    color: var(--text-note);
  }

  .text-primary {
    color: var(--text-primary);
  }

  .text-highlight {
    color: var(--primary);
  }

  .text-highlight-secondary {
    color: var(--secondary);
  }

  .text-red {
    color: var(--color-red);
  }

  .text-white {
    color: var(--text-white);
  }

  .text-pastel-pink {
    color: var(--color-pastel-pink);
  }

  .text-pastel-pink-200 {
    color: var(--text-pastel-pink-200);
  }

  .text-light-blue {
    color: #5487F5;
  }

  .text-gray-neutral {
    color: var(--color-gray-neutral)
  }

  .text-gray-white {
    color: var(--text-gray-white);
  }

  .text-information-default {
    color: var(--text-information-default)
  }

  .text-red {
    color: 'red'
  }

  .text-gray-l2 {
    color: var(--color-grey-l2);
  }

  .text-pastel-green {
    color: var(--color-pastel-green);
  }

  .line-through {
    text-decoration: line-through;
  }

  .sub-title {
    font-weight: 400;
  }

  .text-subsidy {
    color: #32A4FC;
  }

  .text-green {
    color: var(--bg-green);
  }

  .text-grey-l1 {
    color: var(--text-grey-l1);
  }

  .text-black-l8 {
    color: var(--text-black-l8);
  }

  .text-green-teal-900 {
    color: var(--text-green-teal-900);
  }

  .bg-container {
    background: var(--bg-container);
  }

  .bg-green {
    background: var(--bg-green);
  }

  .bg-pastel-blue {
    background: var(--bg-pastel-blue);
  }

  .bg-alice-blue {
    background: var(--bg-alice-blue)
  }

  .bg-secondary {
    background: var(--secondary);
  }

  .bg-error {
    color: var(--bg-error);
  }

  .border-green {
    border-color: var(--bg-green);
  }

  .section-title {
    font-size: var(--font-size-m);
    text-transform: uppercase;
    font-weight: bold;
  }

  .title-20 {
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
  }

  .label {
    background-color: var(--bg-light);
    border-radius: 10px;
    text-align: center;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px 20px;
  }

  .overlay-popover-marker {
    .ant-popover-inner {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
  }

  /* Style for slide expand full height */
  .slide-h-full {
    .slick-track {
      display: flex !important;
    }

    .slick-slide {
      height: inherit !important;

      > div {
        height: 100%;

        > div {
          height: 100%;
        }
      }
    }
  }

  /* START Fix input autofill background */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px var(--bg-input) inset !important;
  }

  .ant-input-affix-wrapper:hover input, .ant-input-affix-wrapper-focused input {
    border: none !important;
    border-radius: 0 !important;
  }

  .ant-input-affix-wrapper > input.ant-input {
    background: transparent;
  }

  /* END Fix input autofill background */

  .sticky {
    position: sticky;
    top: 0;
  }

  .shadow {
    box-shadow: var(--shadow);
  }

  .shadow-sm {
    box-shadow: 0px 0px 1px rgba(12, 26, 75, 0.24), 0px 3px 8px -1px rgba(50, 50, 71, 0.05);
  }

  .light-bg {
    background: var(--bg-light);
  }

  .dark-bg {
    background: var(--bg-black);
  }

  /** START custom scrollbar only hover */

  .custom-scrollbar {

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: transparent !important;
    }

    ::-webkit-scrollbar-thumb {
      background: transparent !important;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: var(--scrollbar-thumb) !important;
      }
    }
  }

  /** END custom scrollbar only hover */

  .following-btn {
    background: #32C12F !important;
    color: #fff !important;
    border-color: #32C12F !important;
  }

  .snap-scroll-container {
    overflow-y: scroll;
    max-height: 100vh;
    scroll-snap-type: y proximity;
  }

  .snap-scroll-child {
    height: 90vh;
    scroll-snap-align: start;
  }

  .snap-scroll-child-end {
    height: calc(100vh + 64px);
    scroll-snap-align: end;
  }

  .snap-scroll-child-banner {
    height: 90vh;
    scroll-snap-align: start;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .snap-scroll-child-center {
    height: 90vh;
    scroll-snap-align: end;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }

  .scroll-wrapper {
    height: 100vh;
    line-height: 1.6;
  }

  .scroll-snap-container {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: y mandatory;
  }

  .section-scrolling {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    scroll-snap-align: center;
  }

  .scroll-snap-start {
    scroll-snap-align: start;
  }

  .tag-category {
    background: #F6F6F6;
    border-radius: 5px;
    color: #000000;
    padding: 2px 8px;
    white-space: nowrap;
    cursor: pointer;
  }

  .wrapper-relative {
    flex-grow: 1;
    position: relative;
  }

  .wrapper-absolute {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
  }

  /* START STYLES BUTTON */

  .btn-gray {
    background: var(--bg-gray) !important;
    border: 1px solid var(--bg-gray) !important;
  }

  .btn-light-f5 {
    background: #F5F5F5 !important;
    border-color: #F5F5F5 !important;
  }

  .btn-light {
    background: #F9F9F9 !important;
    border-color: #F9F9F9 !important;
  }

  .btn-outline-primary {
    border-color: var(--primary) !important;
  }

  .btn-black {
    background: var(--bg-black) !important;
    border: 1px solid var(--bg-black) !important;
    color: #fff;

    &:hover, &:focus {
      background: var(--bg-black) !important;
      border: 1px solid var(--bg-black) !important;
      color: #fff;
      opacity: 0.8;
    }
  }

  .btn-second-primary {
    background: var(--secondary);
    border: none;
    color: #fff;

    &:hover, &:focus {
      background: var(--secondary);
      color: #fff;
      opacity: 0.9;
    }
  }

  .btn-line-primary {
    border-color: var(--primary);
    color: var(--primary);
  }

  .btn-second-primary-fill {
    background: var(--secondary) 20;
    border-color: transparent;
    color: var(--secondary);

    &:hover, &:focus {
      border-color: var(--primary);
    }
  }

  .btn-primary-fill {
    background: var(--primary) 20;
    border-color: transparent;
    color: var(--primary);

    &:hover, &:focus {
      border-color: var(--primary);
    }
  }

  .btn-info-fill {
    background: var(--color-info) 20;
    border-color: transparent;
    color: var(--color-info);
  }

  .btn-transparent {
    background: transparent;
    border: none;

    &:hover, &:focus {
      background: transparent;
    }
  }

  .btn-text-primary {
    color: var(--primary);

    &:hover, &:focus {
      color: var(--primary);
    }
  }

  .btn-transparent-primary {
    background: transparent;
    border: none;
    color: var(--primary);

    &:hover, &:focus {
      background: transparent;
    }
  }

  .btn-primary-custom {
    padding: 11.4px 30px;
    font-size: 16px;

    &:hover {
      background-color: #fff;
      color: var(--primary);
    }
  }

  /* END STYLES BUTTON */

  .link-custom-primary {
    color: var(--primary) !important;

    &:hover,
    &:focus {
      color: var(--primary) !important;
    }
  }

  .box-wrapper {
    border-radius: 8px;
    background: #fff;
    padding: 20px;
  }

  /* Checkbox */
  .checkbox-20 {
    .ant-checkbox-inner {
      width: 20px !important;
      height: 20px !important;

      &::after {
        left: 26.5% !important;
      }
    }
  }

  .checkbox-18 {
    .ant-checkbox-inner {
      width: 18px !important;
      height: 18px !important;

      &::after {
        left: 24.5% !important;
      }
    }
  }

  .checkbox-14 {
    .ant-checkbox-inner {
      width: 14px !important;
      height: 14px !important;

      &::after {
        left: 20.5% !important;
      }
    }
  }

  /* Radio */
  .radio-size-20 {
    .ant-radio-inner {
      width: 20px !important;
      height: 20px !important;
    }

    .ant-radio-inner::after {
      width: 20px !important;
      height: 20px !important;
      margin-top: -10px !important;
      margin-left: -10px !important;
    }
  }

  .ratio-16-9 {
    padding-top: 56.25%;
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .ratio-7-5 {
    overflow: hidden;
    position: relative;
    width: 100%;
    padding-top: 72.5%;
  }

  .ratio-2-1 {
    padding-top: 50%;
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .ratio-1-1 {
    padding-top: 100%;
    position: relative;
    width: 100%;
    overflow: hidden;
  }

  .aspect-ratio-1-1 {
    padding-top: 100%;
    position: relative;
    aspect-ratio: 1/1;
    overflow: hidden;
  }

  .reset-padding-overlay .ant-popover-inner-content {
    padding: 0 !important;
  }

  .border-bottom-section {
    border-bottom: 1px solid var(--border-color-light);
  }

  .success-modal-confirm {
    .ant-modal-confirm .ant-modal-body {
      padding: 24px !important;
    }
  }
`;

export default GlobalStyle;
