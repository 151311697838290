import styled from 'styled-components';

export const CentreInfoStyles = styled.div`
  position: relative;
  height: 100%;

  .centre-info {
    &__image {
      object-fit: cover;
      width: 100%;
    }
    &__rating {
      position: absolute;
      left: 20px;
      top: 20px;
    }
    &__NQSRType-img {
      width: 96px;
      height: 48px;
      object-fit: contain;
      background: #ffffff;
      border-radius: 6px;
      position: absolute;
    }
  }
  .box-centre-info {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  &.centre-info-simple {
    .centre-info__image {
      height: 100%;
      border-radius: 20px 0px 0px 20px;
    }
    .centre-info__NQSRType-img {
      right: 20px;
      top: 20px;
    }
    .box-centre-info {
      left: 20px;
    }
  }

  &.centre-info-complex {
    .centre-info__image {
      height: 280px;
      border-radius: 20px 20px 0px 0px;
    }
    .centre-info__NQSRType-img {
      left: 20px;
      bottom: 20px;
    }
  }
`;

export const SimpleEnquiryFormStyles = styled.div`
  padding: 32px;
  height: 100%;
  display: flex;
  flex-direction: column;

  font-size: 16px;
  min-height: 700px;

  .simple-enquiry {
    &__title {
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 20px;
    }
    &__submit-btn {
      margin-top: 12px;
    }
  }

  .ant-form-item {
    margin-bottom: 20px !important;
  }

  .ant-form {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ant-form-item-label > label {
    font-weight: bold;
  }

  .ant-form label {
    font-size: 16px !important;
  }

  textarea {
    background: #f7f8f9 !important;
    font-size: 16px;
    padding: 16px;
  }

  @media only screen and (max-width: 576px) {
    .simple-enquiry {
      &__title {
        font-size: 22px;
      }
    }
  }
`;

export const ComplexEnquiryFormStyles = styled.div`
  padding: 64px;
  font-size: 16px;
  .ant-form label {
    font-size: 16px;
  }
  .complex-enquiry {
    &__title {
      font-weight: bold;
      font-size: 32px;
      line-height: 40px;
      text-align: center;
      margin-bottom: 20px;
    }
    &__submit-btn {
      margin-top: 60px;
      width: 367px;
      max-width: 100%;
    }
    &__general-info {
      padding: 10px 64px 20px 64px;
    }
  }

  .ant-form-item-label > label {
    font-size: 16px !important;
    margin-bottom: 5px;
  }

  @media only screen and (max-width: 576px) {
    padding: 20px;
    .complex-enquiry {
      &__title {
        font-size: 22px;
      }
      &__general-info {
        padding: 10px 20px 20px 20px !important;
      }
    }
  }
`;
