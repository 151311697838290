import { richWebConfig } from 'utils';
import request, { stringifyObjectWithBrackets } from 'utils/request';
import { ResourceDataCentreList } from 'types/centres';

export async function getAllCentresApi(data: string) {
  return request.get(`/centres${data || ''}`, richWebConfig);
}

export async function deleteFavoriteCentresApi(id: string) {
  return request.delete(`/centres/${id}/favorite`);
}

export async function addFavoriteCentresApi(id: string) {
  return request.post(`/centres/${id}/favorite`);
}

export async function getFavoriteListApi(params: ResourceDataCentreList) {
  return request.get(
    `/centres/favorite?${stringifyObjectWithBrackets(params)}`,
  );
}
