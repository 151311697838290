/* eslint-disable no-underscore-dangle */
import { crudSelectorsCustom } from '@redux/crudCreator/selectors';
import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { MODEL_NAME } from './action';

export const inboxesSelectors = crudSelectorsCustom(MODEL_NAME);

const getRawTotalUnread = (state) => state.inboxes.totalUnread;

const getRawRehydratedPersistInboxes = (state) =>
  state.inboxes?._persist?.rehydrated;

export const getRehydratedPersistInboxes = createDraftSafeSelector(
  [getRawRehydratedPersistInboxes],
  (data) => data,
);

export const getTotalUnread = createDraftSafeSelector(
  [getRawTotalUnread],
  (data) => data,
);
