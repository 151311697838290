import FormDatePicker from '@uikit/form/FormDatePicker';
import FormGoogleAutocomplete from '@uikit/form/FormGoogleAutocomplete';
import FormInput from '@uikit/form/FormInput';
import FormSelect from '@uikit/form/FormSelect';
import { Checkbox, Col, Form, Row } from 'antd';
import PropTypes from 'prop-types';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isValidAddress } from 'utils/dataUtils';
import {
  getFirstOrLastNameRules,
  getPhoneNumberRules,
  validateRegex,
} from 'utils/validate';
import { pick } from 'lodash-es';
import { getCurrentUserSelector } from '@redux/auth/selectors';
import { RestInputContext } from '@uikit/form/RestInputContext';
import styled from 'styled-components';
import dayjs from 'utils/dayUtils';

const ProfileInfoForm = ({ initialValues, isParentInfo }) => {
  const { form } = useContext(RestInputContext);

  const { t } = useTranslation();
  const [isSameAddressParent, setIsSameAddressParent] = useState(false);

  const currentUser = useSelector(getCurrentUserSelector);
  const stateOfAustralia = useSelector(
    (state) => state.config?.data?.stateOfAustralia,
  );

  const disabledDate = (current) => {
    if (!current) return false;
    const today = dayjs();
    if (dayjs(current).isAfter(today, 'day')) return true;
    return false;
  };

  const onSelectAddress = ({ addressObj }) => {
    form.setFieldsValue(addressObj);
    if (isValidAddress(addressObj)) {
      form.validateFields(['address']);
    }
  };

  const handleCheckboxSameAddressParent = (e) => {
    setIsSameAddressParent(e.target.checked);

    const addressFields = ['address', 'suburb', 'state', 'postCode', 'country'];

    const pickAddress = (data) =>
      form.setFieldsValue(pick(data, addressFields));

    if (e.target.checked) {
      pickAddress(currentUser);
    } else {
      form.resetFields(addressFields);
      pickAddress(initialValues);
    }
  };

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...initialValues,
      dateOfBirth: initialValues?.dateOfBirth
        ? dayjs(initialValues?.dateOfBirth)
        : null,
    });
  }, [initialValues, form]);

  return (
    <ProfileInfoFormStyles>
      <Row gutter={[32]}>
        <Col xl={12} md={12} xs={24}>
          <FormInput
            required
            name="firstName"
            label="input.firstName.label"
            messageRequire="input.firstName.validateMsg.required"
            placeholder="input.firstName.placeholder"
            rules={getFirstOrLastNameRules('firstName')}
          />
        </Col>
        <Col xl={12} md={12} xs={24}>
          <FormInput
            required
            name="lastName"
            label="input.lastName.label"
            messageRequire="input.lastName.validateMsg.required"
            placeholder="input.lastName.placeholder"
            rules={getFirstOrLastNameRules('lastName')}
          />
        </Col>
      </Row>

      <FormInput
        name="centrelinkCRN"
        label="input.centrelinkCRN.label"
        placeholder="input.centrelinkCRN.placeholder"
        rules={[
          {
            pattern: validateRegex.centreLinkCRN,
            message: t('input.centrelinkCRN.validateMsg.invalid'),
          },
        ]}
      />

      <Row gutter={32}>
        <Col xl={12} md={12} xs={24}>
          <FormDatePicker
            name="dateOfBirth"
            label="input.dateOfBirth.label"
            placeholder="DD-MM-YYYY"
            disabledDate={disabledDate}
          />
        </Col>
        <Col xl={12} md={12} xs={24}>
          <FormInput
            name="phoneNumber"
            label="input.phoneNumber.label"
            placeholder="input.phoneNumber.placeholder"
            rules={getPhoneNumberRules()}
          />
        </Col>
      </Row>

      <FormInput
        name="email"
        label="input.email.label"
        placeholder="input.email.placeholder"
        rules={[
          {
            type: 'email',
            message: t('input.email.validateMsg.invalid'),
          },
        ]}
        disabled={isParentInfo}
        required={isParentInfo}
      />

      {!isParentInfo && (
        <Form.Item>
          <Checkbox onChange={handleCheckboxSameAddressParent}>
            {t('applyApplications.sameAddressLabel')}
          </Checkbox>
        </Form.Item>
      )}

      <FormGoogleAutocomplete
        name="address"
        label="input.address.label"
        placeholder="input.address.placeholder"
        onSelectAddress={onSelectAddress}
        getPopupContainer={(trigger) => trigger.parentNode}
        {...(isParentInfo && {
          required: true,
          messageRequire: 'input.address.validateMsg.required',
        })}
        disabled={isSameAddressParent}
      />

      <Row gutter={32}>
        <Col xl={12} md={12} xs={24}>
          <FormInput
            name="suburb"
            label="input.suburb.label"
            placeholder="input.suburb.label"
            {...(isParentInfo && {
              required: true,
              messageRequire: 'input.suburb.validateMsg.required',
            })}
            disabled={isSameAddressParent}
          />
        </Col>
        <Col xl={12} md={12} xs={24}>
          <FormSelect
            name="state"
            label="input.state.label"
            placeholder="input.state.placeholder"
            resourceData={stateOfAustralia}
            getPopupContainer={(trigger) => trigger.parentNode}
            {...(isParentInfo && {
              required: true,
              messageRequire: 'input.state.validateMsg.required',
            })}
            disabled={isSameAddressParent}
          />
        </Col>
      </Row>
      <Row gutter={32}>
        <Col xl={12} md={12} xs={24}>
          <FormInput
            name="postCode"
            label="input.postCode.label"
            placeholder="input.postCode.placeholder"
            rules={[
              {
                pattern: validateRegex.postCode,
                message: t('input.postCode.validateMsg.invalid'),
              },
            ]}
            {...(isParentInfo && {
              required: true,
              messageRequire: 'input.postCode.validateMsg.required',
            })}
            disabled={isSameAddressParent}
          />
        </Col>
        <Col xl={12} md={12} xs={24}>
          <FormSelect
            name="country"
            label="input.country.label"
            placeholder="input.country.placeholder"
            resourceData={[{ value: 'Australia' }]}
            valueProp="value"
            titleProp="value"
            getPopupContainer={(trigger) => trigger.parentNode}
            {...(isParentInfo && {
              required: true,
              messageRequire: 'input.country.validateMsg.required',
            })}
            disabled={isSameAddressParent}
          />
        </Col>
      </Row>
    </ProfileInfoFormStyles>
  );
};

ProfileInfoForm.propTypes = {
  onCancel: PropTypes.func,
  initialValues: PropTypes.object,
  title: PropTypes.string,
  onFinish: PropTypes.func,
  isParentInfo: PropTypes.bool,
  form: PropTypes.object,
};

const ProfileInfoFormStyles = styled.div`
  @media only screen and (max-width: 576px) {
    .ant-form-item-label label,
    .ant-checkbox-wrapper {
      font-size: 13px;
    }
  }
`;

export default ProfileInfoForm;
