import request from 'utils/request';
import { richWebConfigVer2 } from 'utils';

export async function getMyJobsWithViewedTabApi(params) {
  return request.get(`/job-seekers/screens/my-jobs/tabs/viewed`, {
    params,
    ...richWebConfigVer2,
  });
}

export async function getMyJobsWithFavoritesTabApi(params) {
  return request.get(`/job-seekers/screens/my-jobs/tabs/favorites`, {
    params,
    ...richWebConfigVer2,
  });
}

export async function getMyJobsWithAppliedTabApi(params) {
  return request.get(`/job-seekers/screens/my-jobs/tabs/applied`, {
    params,
    ...richWebConfigVer2,
  });
}
