/* eslint-disable no-param-reassign */
import dayjs from 'utils/dayUtils';
import { compact, forEach, isEmpty } from 'lodash-es';
import { i18n } from 'next-i18next';
import { CHILDCARE_PROVIDER_PATH, LOGIN_BY_ROLE } from 'configs/constants';
import { FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH } from 'components/forJobSeekers/constants';
import { directToMobileScreen } from 'components/forJobSeekers/utils';
import { capitalize, getImageUrl } from './tools';
import { validateRegex } from './validate';

export const getFeaturedImage = (images) => {
  const featuredItem = images?.find((item) => item?.featured);
  if (featuredItem?.type === 'video' || !featuredItem?.url) {
    return images?.find((image) => image?.type !== 'video')?.url;
  }

  return featuredItem?.url;
};

export const getFeaturedImageDefault = (images) =>
  getImageUrl({ src: getFeaturedImage(images) });

export const getDisplayAddress = (item, hasCountry = false) =>
  compact([
    item?.address,
    item?.suburb,
    item?.state,
    item?.postCode,
    ...(hasCountry ? [item?.country] : []),
  ]).join(', ');

export const getThumbnail = (data) =>
  data?.gallery?.filter((e) => e?.type === 'EXTERIOR')[0]?.asset_path ||
  data?.galleries?.[0]?.asset_path ||
  '/images/default-thumnail.png';

export const getDailyWorkLabel = (data) => {
  const diff = dayjs().diff(dayjs(data.createdAt), 'day');
  if (!diff) return 'daysOfWeek.today';
  if (diff === 1) return 'daysOfWeek.yesterday';
  return dayjs(data.createdAt).fromNow();
};

export const compactSuburbDisplay = (item) =>
  compact([capitalize(item.city), item.state, item.postCode]).join(', ');

export const getClassificationCentre = (kindiCareRating) => {
  if (!kindiCareRating) {
    return i18n.t('classification.notRated');
  }
  if (kindiCareRating >= 9) {
    return i18n.t('classification.excellent');
  }
  if (kindiCareRating >= 8.5) {
    return i18n.t('classification.veryGood');
  }
  if (kindiCareRating >= 7.5) {
    return i18n.t('classification.good');
  }
  if (kindiCareRating >= 5) {
    return i18n.t('classification.fair');
  }
  return i18n.t('classification.poor');
};

export const groupBy = (arr = [], fn) =>
  arr
    .map(typeof fn === 'function' ? fn : (val) => val[fn])
    .reduce((acc, val, i) => {
      acc[val] = (acc[val] || []).concat(arr[i]);
      return acc;
    }, {});

export const formatDateOfBirthSubmit = (date) =>
  date ? dayjs(date).format('DD-MM-YYYY') : null;

export const formatContentMultiBreakline = (content) =>
  content ? content?.replace(/\n+/g, '\n') : null;

export const getUrlIfyMessage = (string) =>
  string?.replace(
    validateRegex.url,
    (url) => `<a class="detect-link" href="${url}" target="_blank">${url}</a>`,
  );
export const getFullNameUser = (user) =>
  `${user?.firstName || ''} ${user?.lastName || ''}`;

export const getDefaultDisplayOpenHours = (centre) => {
  const hours = [
    ...centre?.displayOpenTime,
    ...centre?.displayMorningSchoolTermTime?.map((time) => `Morning: ${time}`),
    ...centre?.displayAfternoonSchoolTermTime?.map(
      (time) => `Afternoon: ${time}`,
    ),
    ...centre?.displayHolidayTime?.map((time) => `Holiday: ${time}`),
  ];

  return hours?.[0];
};

export const splitAddressFromGoogleMap = (addressArr) => {
  const addressObj = {};

  forEach(addressArr, (itemAddress) => {
    switch (itemAddress?.types?.[0]) {
      case 'street_number': {
        addressObj.address = `${itemAddress.long_name}${
          addressObj.address ? ` ${addressObj.address}` : ''
        }`;
        break;
      }

      case 'route': {
        addressObj.address = `${
          addressObj.address ? `${addressObj.address} ` : ''
        }${itemAddress.short_name}`;
        break;
      }

      case 'postal_code': {
        addressObj.postCode = `${itemAddress.long_name}`;
        break;
      }

      case 'locality':
        addressObj.suburb = itemAddress.long_name;
        break;

      case 'administrative_area_level_1':
        addressObj.state = itemAddress.short_name;
        break;

      case 'country':
        addressObj.country = itemAddress.long_name;
        break;

      default:
        break;
    }
  });
  return addressObj;
};

export const isValidAddress = (addressObj) =>
  addressObj?.address &&
  addressObj?.state &&
  addressObj?.postCode &&
  addressObj?.suburb;

export const correctURL = (string) => {
  if (isEmpty(string) || string?.length === 0) {
    return null;
  }
  let url = string;
  if (!string.match('^(https?:\\/\\/)') || string.startsWith('www.')) {
    url = `https://${url}`;
  }
  return url;
};

export const getTextValueMoney = (centreValueForMoney) =>
  `${centreValueForMoney || i18n.t('kindicareService.notAssessed')} ${i18n.t(
    'centreService.valueMoneyForArea',
  )}`;

export const getCustomizeLinkBtn = (btnApps, key) =>
  btnApps?.centreAppSettings?.find((setting) => setting?.key === key)?.value;

export const getTextContentFromHTML = (htmlString) => {
  const text = htmlString.replace(/<[^>]+>/g, '');
  const decodeHTMLEntities = text.replace(/&#(\d+);/g, (match, dec) =>
    String.fromCharCode(dec),
  );
  return decodeHTMLEntities;
};

export const getUrlAfterLogin = (loginByRole, asPath) => {
  const validPaths = [
    '/',
    CHILDCARE_PROVIDER_PATH,
    directToMobileScreen(FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH),
  ];
  let redirectUrl = null;

  if (validPaths.some((path) => path === asPath)) {
    if (
      loginByRole === LOGIN_BY_ROLE.jobSeeker &&
      asPath !== FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH
    ) {
      redirectUrl = directToMobileScreen(FOR_JOB_SEEKERS_HOMEPAGE_URL_PATH);
    }
    if (loginByRole === LOGIN_BY_ROLE.parent && asPath !== '/') {
      redirectUrl = '/';
    }
  }
  return redirectUrl;
};

export const generateJobDescriptionInHTML = ({ aboutTheJob, benefits }) => {
  const benefitList = benefits?.length
    ? `<ul>${benefits.map((benefit) => `<li>${benefit}</li>`).join('')}</ul>`
    : '';

  return `${aboutTheJob ?? ''} ${benefitList}`;
};
