import styled from 'styled-components';

const FormInputStyles = styled.div`
  .combine-input-label {
    position: relative;
    .ant-form-item-label {
      padding: 12px 0 0 12px !important;
      position: absolute;
      top: 0;
      z-index: 2;
      line-height: 16px !important;
      > label {
        color: #92929d;
        font-size: 12px;
      }
    }
    .ant-input-number,
    .ant-input-affix-wrapper:hover,
    .ant-input-affix-wrapper:focus,
    .ant-input-affix-wrapper:active,
    .input-affix-wrapper-focused,
    .ant-input-affix-wrapper,
    .ant-input:hover,
    .ant-input:focus,
    .ant-input:active,
    .ant-input {
      box-shadow: none;
    }

    .ant-input-number-affix-wrapper {
      width: 100%;
      .ant-input-number {
        border-radius: inherit !important;
      }
      .ant-input-number,
      .ant-input-number:hover {
        border-color: transparent !important;
      }
    }
    .ant-input,
    .ant-input-number-input {
      min-height: 68px !important;
      padding-top: 16px !important;
    }
    .ant-input-number-prefix {
      padding-top: 16px !important;
    }
  }
`;

export default FormInputStyles;
