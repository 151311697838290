import styled, { css } from 'styled-components';
import { Layout } from 'antd';

export const PublicLayoutStyles = styled(Layout)`
  min-height: 100vh;
  .ant-layout-sider {
    background-color: white;
  }
  .ant-layout-content {
    width: 100%;
  }

  ${({ hasJobSidebar }) =>
    hasJobSidebar &&
    css`
      .layout-content-style {
        margin-left: 80px;
      }
      @media only screen and (min-width: 1500px) {
        .layout-content-style {
          margin-left: auto;
        }
      }
    `}
`;

export const HeaderStyle = styled.div`
  ${({ hasParentSidebar }) =>
    hasParentSidebar &&
    css`
      @media only screen and (min-width: 576px) and (max-width: 1400px) {
        padding-left: var(--pl-60);
      }
    `}
`;
